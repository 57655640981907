import React, { useMemo } from 'react'

import { WorkflowProgressCard } from '@components/cards/workflow-progress-card.component'
import { useAppState } from '@src/app.state'
import { useLocation } from 'react-router-dom'

export function WorkflowDashboardCard({ activity }) {
  const orgs = useAppState(state => state.orgs)
  const user = useAppState(state => state.user)
  const location = useLocation()

  const belongsToOrg = useMemo(
    () => orgs.find(org => org.id === activity.orgId) || {},
    [orgs, activity],
  )

  return (
    <WorkflowProgressCard
      activity={activity}
      userId={user.id}
      subTitle={belongsToOrg.name}
      href={`/${activity.orgId}/home/${user.id}/${activity.id}`}
      linkState={{ from: location.pathname }}
    />
  )
}
