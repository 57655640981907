import { DateTime } from 'luxon'

import { useAppState } from '@src/app.state'
import { functions } from '@src/firebase-app'
import { updateAnalyticsUserName } from '@src/pages/workflow/settings/analytics.resource'
import { toastService } from '@utils/toast.service'
import { httpsCallable } from 'firebase/functions'

export const makeCancelable = promise => {
  let __hasCancelled = false
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (__hasCancelled ? reject({ isCancelled: true }) : resolve(val)),
      err => (__hasCancelled ? reject({ isCancelled: true }) : reject(err)),
    )
  })
  return {
    promise: wrappedPromise,
    cancel: () => (__hasCancelled = true),
  }
}

export const getUserFullName = user => {
  if (!user) return ''
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`
  }
  if (user.userFirstName && user.userLastName) {
    return `${user.userFirstName} ${user.userLastName}`
  }
  //Display name is for Oauth users without first and last names separate
  return (
    user.displayName ||
    user.userDisplayName ||
    user.email ||
    user.userEmail ||
    ''
  )
}

export const deriveUserNameFromDisplayName = displayName => {
  if (!displayName) return ['', '']
  const split = displayName.split(' ')
  if (split.length === 2) {
    return [split[0], split[1]]
  } else if (split.length > 2) {
    return [split.splice(0, 1)[0], split.join(' ')]
  }

  return [displayName, '']
}

export function normalizePercentage(number) {
  const newNumber = Math.ceil(number)
  return newNumber < 0 ? 0 : newNumber > 100 ? 100 : newNumber
}

export function getTimeAgo(dateString) {
  const now = DateTime.local().toUTC()
  const past = DateTime.fromISO(dateString).toUTC()

  const { seconds } = now.diff(past, 'seconds')
  const { minutes } = now.diff(past, 'minutes')
  const { hours } = now.diff(past, 'hours')
  const { days } = now.diff(past, 'days')
  const { weeks } = now.diff(past, 'weeks')
  const { months } = now.diff(past, 'months')

  if (seconds < 60) {
    return `a few seconds ago`
  } else if (minutes < 60) {
    return `${Math.floor(minutes)} minute${pluralize(minutes)} ago`
  } else if (hours < 24) {
    return `${Math.floor(hours)} hour${pluralize(hours)} ago`
  } else if (weeks < 1) {
    return `${Math.floor(days)} day${pluralize(days)} ago`
  } else if (months < 1) {
    return `${Math.floor(weeks)} week${pluralize(weeks)} ago`
  } else {
    return `${Math.floor(months)} month${pluralize(months)} ago`
  }
}

export function getDaysUntil(date) {
  const today = getTodayDate()
  return Math.floor(date.endOf('day').diff(today, ['days']).values.days)
}

export function pluralize(value) {
  return Math.floor(value) > 1 ? 's' : ''
}

export function uniqueIdentifierFromDate(date) {
  if (!date) return ''
  return `${new Date(date).getTime()}`.slice(-5)
}

export function getTodayDate() {
  const systemDate = useAppState.getState().systemDate
  if (systemDate) {
    return DateTime.fromISO(systemDate)
  }

  return DateTime.local()
}

export function orgIsPaid(paidThroughDate) {
  if (!paidThroughDate) return false
  const { date, timeZone } = paidThroughDate
  const currentZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const today = getTodayDate()
  const convertedDate = DateTime.fromISO(date, { zone: timeZone }).setZone(
    currentZone,
  )
  return convertedDate > today
}

export async function updateUserName({ firstName, lastName, userId }) {
  if (!firstName || !lastName || !userId) {
    throw new Error('updateUserName: Missing required parameters')
  }
  try {
    const updateUserNameFunction = httpsCallable(
      functions,
      'userFunctions-updateUserName',
    )
    const namePayload = {
      userId,
      newFirstName: firstName,
      newLastName: lastName,
    }

    //Cloud function handles firestore updates so that
    //permissions don't need to be considered
    await updateUserNameFunction(namePayload)

    //Update workflow analytics
    await updateAnalyticsUserName({ firstName, lastName, userId })
  } catch (err) {
    console.log(err)
    toastService.error(
      'Unable to save profile information at this time. Please try again.',
    )
  }
}
