import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { k, useCss } from 'kremling'
import { isEmpty, sortBy } from 'lodash'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { DatePicker } from '@components/date-picker.component'
import { EmptyState } from '@components/empty-state.component'
import { Loader } from '@components/loader.component'
import { Button } from '@components/mantine/button.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Modal } from '@components/modal.component'
import { Required } from '@components/required.component'
import { SelectSingle } from '@components/select-single.component'
import { useLoad } from '@hooks/use-load.hook'
import { useProcessWorkflowEndDate } from '@hooks/use-process-workflow-end-date.hook'
import { useAppState } from '@src/app.state'
import { db, functions } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { toastService } from '@utils/toast.service'

export function SelfAddWorkflowModal({ onResolve, onReject, template, orgId }) {
  const scope = useCss(css)
  const user = useAppState(state => state.user)
  const orgs = useAppState(state => state.orgs)
  const [saving, setSaving] = useState(false)
  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [selectedName, setSelectedName] = useState(template?.name || '')
  const [selectedTemplate, setSelectedTemplate] = useState(template || null)
  const [selectedTemplateSearch, setSelectedTemplateSearch] = useState('')
  const [processedEndDate, setSelectedEndDate, endDateIsGenerated, ruleMeta] =
    useProcessWorkflowEndDate(selectedTemplate, selectedStartDate)
  const [selectedOrg, setSelectedOrg] = useState(
    orgId ? orgs.find(org => org.id === orgId) : null,
  )
  const isAdmin = ['owner', 'manager', 'editor'].includes(
    user.orgRoles[selectedOrg?.id],
  )

  const [
    workflowTemplates,
    _setWorkflowTemplates,
    { loading: workflowTemplatesLoading },
  ] = useLoad(
    [],
    async () => {
      if (!isAdmin || !selectedOrg) return Promise.resolve([])
      const q = query(
        collection(db, dbNames.workflowTemplates),
        where('orgId', '==', selectedOrg.id),
        where('assistant', '==', false),
      )
      const templates = await getDocs(q).then(q =>
        q.docs.map(d => ({ ...d.data(), id: d.id })),
      )
      return sortBy(templates, 'name')
    },
    [selectedOrg],
  )

  const [
    publishedTemplates,
    _setPublishedTemplates,
    { loading: publishedTemplatesLoading },
  ] = useLoad(
    [],
    () => {
      if (!selectedOrg) return Promise.resolve([])
      const q = query(
        collection(db, dbNames.publishedTemplates),
        orderBy('name'),
        where('orgId', '==', selectedOrg.id),
        where('publishedLocations', 'array-contains', 'workspace'),
      )
      return getDocs(q).then(q => q.docs.map(d => ({ ...d.data(), id: d.id })))
    },
    [selectedOrg],
  )

  // useEffect(() => {
  //   if (!selectedTemplate) return
  //   const { section, meta, endDate } = buildRuleMeta(
  //     selectedTemplate.sections,
  //     selectedStartDate,
  //   )
  //   if (selectedStartDate && section) {
  //     if (meta === metas.RECURRENCE_AND_REPEAT) {
  //       setSelectedEndDate(endDate)
  //     }
  //   }
  // }, [selectedStartDate])

  async function build() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const startDate = selectedStartDate.toISO({ includeOffset: false })
    const endDate = processedEndDate.toISO({ includeOffset: false })
    const data = {
      startDate: {
        date: startDate,
        timeZone,
      },
      endDate: {
        date: endDate,
        timeZone,
      },
      userTimeZone: timeZone,
      description: '',
      selectedTemplate,
      ruleMeta,
      selectedName,
      orgId,
      today: getTodayDate().toISO(),
    }
    setSaving(true)
    try {
      const userIds = [user.id]
      const res = await httpsCallable(
        functions,
        'activityFunctions-createActivity',
      )({
        ...data,
        userIds,
      })
      onResolve({ newActivityId: res.data })
    } catch (err) {
      console.log(err)
      toastService.error('There was a problem assigning the activity')
      setSaving(false)
    }
  }

  // async function build() {
  //   debugger
  //   const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  //   const startDate = selectedStartDate.toISO()
  //   const endDate = selectedEndDate.toISO()
  //   const createActivity = httpsCallable(
  //     functions,
  //     'activityFunctions-createActivity',
  //   )
  //   const data = {
  //     startDate: {
  //       date: startDate,
  //       timeZone,
  //     },
  //     endDate: {
  //       date: endDate,
  //       timeZone,
  //     },
  //     userTimeZone: timeZone,
  //     userIds: [user.id],
  //     selectedTemplate,
  //     ruleMeta,
  //     selectedName,
  //     orgId: selectedOrg.id,
  //     today: getTodayDate().toISO(),
  //   }
  //   setSaving(true)
  //   const newActivityId = await createActivity(data)
  //
  //   onResolve({ newActivityId })
  // }

  function renderTemplateSelection() {
    if (isAdmin) {
      if (!isEmpty(publishedTemplates)) {
        return (
          <SelectSingle
            triggerIsBlock
            fixedContent
            contentWidth="block"
            data={[
              {
                id: 'published-templates',
                icon: 'globe-stand',
                name: 'Published',
                data: publishedTemplates,
              },
              {
                id: 'templates',
                icon: 'ballot',
                name: 'Unpublished',
                data: workflowTemplates,
              },
            ]}
            isGroupData
            onChange={t => {
              if (selectedTemplate) {
                setSelectedStartDate(null)
                setSelectedEndDate(null)
              }
              if (!selectedName || selectedName === selectedTemplate?.name) {
                setSelectedName(t.name)
              }
              setSelectedTemplate(t)
            }}
            searchOnChange={setSelectedTemplateSearch}
            searchValue={selectedTemplateSearch}
            searchFilter
            value={selectedTemplate}
          />
        )
      }

      return (
        <SelectSingle
          triggerIsBlock
          fixedContent
          contentWidth="block"
          data={workflowTemplates}
          onChange={t => {
            if (selectedTemplate) {
              setSelectedStartDate(null)
              setSelectedEndDate(null)
            }
            if (!selectedName || selectedName === selectedTemplate?.name) {
              setSelectedName(t.name)
            }
            setSelectedTemplate(t)
          }}
          searchOnChange={setSelectedTemplateSearch}
          searchValue={selectedTemplateSearch}
          searchFilter
          value={selectedTemplate}
        />
      )
    }

    return (
      <SelectSingle
        triggerIsBlock
        contentWidth="block"
        data={publishedTemplates}
        onChange={t => {
          if (selectedTemplate) {
            setSelectedStartDate(null)
            setSelectedEndDate(null)
          }
          if (!selectedName || selectedName === selectedTemplate?.name) {
            setSelectedName(t.name)
          }
          setSelectedTemplate(t)
        }}
        searchOnChange={setSelectedTemplateSearch}
        searchValue={selectedTemplateSearch}
        searchFilter
        value={selectedTemplate}
      />
    )
  }

  function renderInfoOverlay() {
    if (!!selectedOrg) {
      if (!orgId) {
        if (
          (!workflowTemplatesLoading || !publishedTemplatesLoading) &&
          isEmpty(workflowTemplates)
        ) {
          if (isAdmin) {
            return (
              <Modal.Overlay>
                <EmptyState
                  title="Before you can start a workflow, you need to create a template."
                  subtitle={
                    <span>
                      Create a template on the{' '}
                      <Link
                        onClick={onReject}
                        to={`/${selectedOrg.id}/library`}
                      >
                        Templates
                      </Link>{' '}
                      to get started!
                    </span>
                  }
                  action={() => setSelectedOrg(null)}
                  actionText="Change workspace"
                />
              </Modal.Overlay>
            )
          } else {
            return (
              <Modal.Overlay>
                <EmptyState
                  title="Your workspace doesn't have any templates yet."
                  subtitle="Contact your workspace's Owner to get more information"
                  action={() => setSelectedOrg(null)}
                  actionText="Change workspace"
                />
              </Modal.Overlay>
            )
          }
        }
      }
      if (isAdmin) {
        if (!workflowTemplatesLoading && isEmpty(workflowTemplates)) {
          return (
            <Modal.Overlay>
              <EmptyState
                title="Before you can start a workflow, you need to create a template."
                subtitle={
                  <span>
                    Create a template on the{' '}
                    <Link
                      onClick={onReject}
                      to="/templates"
                    >
                      Templates
                    </Link>{' '}
                    to get started!
                  </span>
                }
                action={() => onReject()}
                actionText="Close"
              />
            </Modal.Overlay>
          )
        }
      } else {
        if (!publishedTemplatesLoading && isEmpty(workflowTemplates)) {
          return (
            <Modal.Overlay>
              <EmptyState
                title="Your workspace doesn't have any templates yet."
                subtitle="Contact your workspace's Owner to get more information"
                action={() => onReject()}
                actionText="Close"
              />
            </Modal.Overlay>
          )
        }
      }

      return null
    }
  }

  function disableSubmit() {
    return (
      !selectedOrg ||
      !selectedTemplate ||
      !selectedStartDate ||
      !processedEndDate ||
      !selectedName
    )
  }

  return (
    <>
      <Modal.Header>Start activity</Modal.Header>
      <Modal.Body {...scope}>
        {!orgId && (
          <div className="row break-1">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="workflow-select">
                  Workspace <Required />
                </label>
                <SelectSingle
                  triggerIsBlock
                  contentWidth="block"
                  data={orgs}
                  onChange={setSelectedOrg}
                  value={selectedOrg}
                  fixedContent
                />
              </div>
            </div>
          </div>
        )}
        {!template && (
          <div className="row break-1">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="workflow-select">
                  Template <Required />
                </label>
                {renderTemplateSelection()}
              </div>
            </div>
          </div>
        )}
        <div className="row break-1">
          <div className="col-6">
            <div className="form-group">
              <label>
                Start date <Required />
              </label>
              <DatePicker
                placeholder="Select date"
                fixedContent
                onChange={setSelectedStartDate}
                value={selectedStartDate}
                disabled={!selectedTemplate}
                triggerIsBlock
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              {endDateIsGenerated ? (
                <label>Generated end date</label>
              ) : (
                <label>
                  End date <Required />
                </label>
              )}
              <DatePicker
                placeholder={
                  endDateIsGenerated ? 'Select a start date' : 'Select date'
                }
                onChange={setSelectedEndDate}
                value={processedEndDate}
                triggerIsBlock
                fixedContent
                disabled={
                  !selectedStartDate || endDateIsGenerated || !selectedTemplate
                }
                minDate={selectedStartDate?.plus({ days: 1 }).set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>
            Name <Required />
          </label>
          <TextInput
            value={selectedName}
            onChange={setSelectedName}
          />
        </div>
        {endDateIsGenerated && (
          <div className="form-group">
            <strong>Note:</strong> This template contains section rules that
            automatically determine the end date based off of the start date.
          </div>
        )}
      </Modal.Body>
      <Modal.Footer {...scope}>
        <Button
          variant="tertiary"
          onClick={onReject}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={build}
          loading={saving}
          disabled={disableSubmit()}
        >
          Start activity
        </Button>
      </Modal.Footer>
      {saving && (
        <Modal.Overlay>
          <Loader />
        </Modal.Overlay>
      )}
      {renderInfoOverlay()}
    </>
  )
}

const css = k`

`
