import { FocusPlugin } from '@components/rich-text/plugins/focus-plugin'
import { UploadOptions } from '@hooks/use-file-upload.hook'
import { $generateHtmlFromNodes } from '@lexical/html'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { a, k, useCss } from 'kremling'
import { EditorState, LexicalEditor } from 'lexical'
import React, { useState } from 'react'
import { ImageNode } from './nodes/image-node'
import { AutoLinkPlugin } from './plugins/auto-link-plugin'
import { DragDropPaste } from './plugins/drag-drop-past-plugin'
import { FloatingLinkEditorPlugin } from './plugins/floating-link-editor-plugin'
import { ImagePlugin } from './plugins/image-plugin'
import { InitialValuePlugin } from './plugins/initial-value-plugin'
import { LinkPlugin } from './plugins/link-plugin'
import { ToolbarPlugin } from './plugins/toolbar-plugin'

type RichTextProps = {
  onChange: (content: string) => void
  placeholder?: string
  id?: string
  initialValue?: string
  disabled?: boolean
  uploadOptions?: UploadOptions
  className?: string
  onFocus?: () => void
  onBlur?: () => void
  error?: string
  editorRef?: React.Ref<LexicalEditor>
}

export const RichText = (props: RichTextProps) => {
  const {
    onChange,
    id,
    placeholder,
    disabled,
    uploadOptions,
    initialValue,
    className,
    onFocus = () => {},
    onBlur = () => {},
    error,
    editorRef,
  } = props
  const [isLinkEditMode, setIsLinkEditMode] = useState(false)
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null)
  const scope = useCss(newCss)

  const initialConfig = {
    onError(err: Error) {
      console.log(err)
    },
    theme: {
      paragraph: 'editor-paragraph',
      list: {
        nested: {
          listitem: 'editor-nested-listitem',
        },
        ol: 'editor-list-ol',
        ul: 'editor-list-ul',
        listitem: 'editor-listItem',
        listitemChecked: 'editor-listItemChecked',
        listitemUnchecked: 'editor-listItemUnchecked',
      },
      text: {
        bold: 'editor-text-bold',
        italic: 'editor-text-italic',
        underline: 'editor-text-underline',
        strikethrough: 'editor-text-strikethrough',
        underlineStrikethrough: 'editor-text-underlineStrikethrough',
        code: 'editor-text-code',
      },
      image: 'editor-image',
    },
    nodes: [ImageNode, ListNode, ListItemNode, LinkNode, AutoLinkNode],
    namespace: id || 'rt-editor',
    editable: !disabled,
  }

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem)
    }
  }

  function contentHasImages(htmlString: string): boolean {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')
    return !!doc.querySelector('img')
  }

  function handleChange(_editorState: EditorState, editor: LexicalEditor) {
    editor.update(() => {
      const htmlString = $generateHtmlFromNodes(editor, null)
      if (htmlString === initialValue) return
      const hasImage = contentHasImages(htmlString)
      const textContent = editor.getRootElement()?.textContent
      if (textContent === '' && !hasImage) {
        onChange('')
      } else {
        onChange(htmlString)
      }
    })
  }

  return (
    <div
      {...scope}
      className={a('editor-wrapper', className)}
    >
      <LexicalComposer initialConfig={initialConfig}>
        <div className="editor-outer">
          <ToolbarPlugin
            uploadOptions={uploadOptions}
            disabled={disabled}
            setIsLinkEditMode={setIsLinkEditMode}
          />
          <div className="editor-inner">
            <RichTextPlugin
              contentEditable={
                <div ref={onRef}>
                  <ContentEditable
                    className="editor-container"
                    tabIndex={0}
                  />
                </div>
              }
              placeholder={<Placeholder text={placeholder} />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <OnChangePlugin
              onChange={handleChange}
              ignoreSelectionChange
            />
            <HistoryPlugin />
            <ImagePlugin />
            <EditorRefPlugin editorRef={editorRef} />
            <ListPlugin />
            {floatingAnchorElem && (
              <FloatingLinkEditorPlugin
                anchorElem={floatingAnchorElem}
                isLinkEditMode={isLinkEditMode}
                setIsLinkEditMode={setIsLinkEditMode}
              />
            )}
            <DragDropPaste uploadOptions={uploadOptions} />
            <InitialValuePlugin initialValue={initialValue} />
            <LinkPlugin />
            <AutoLinkPlugin />
            <FocusPlugin
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </div>
        </div>
      </LexicalComposer>
      {error && <div className="mt-1 text-xl text-danger">{error}</div>}
    </div>
  )
}

function Placeholder({ text = 'Write here...' }: { text?: string }) {
  return (
    <div className="placeholder select-none">
      <p>{text}</p>
    </div>
  )
}

const newCss = k`

`
