import * as RadixSeparator from '@radix-ui/react-separator'
import React from 'react'

type SeparatorProps = {
  orientation?: 'horizontal' | 'vertical'
  className?: string
}
export function Separator({
  orientation = 'horizontal',
  className,
}: SeparatorProps) {
  return (
    <RadixSeparator.Root
      orientation={orientation}
      className={`bg-grey-400 data-[orientation=horizontal]:h-px data-[orientation=horizontal]:w-full data-[orientation=vertical]:w-px data-[orientation=vertical]:h-full ${className ? className : ''}`}
      decorative
    />
  )
}
