import {
  Button as MantineButton,
  ButtonProps as MantineButtonProps,
} from '@mantine/core'
import React, { forwardRef } from 'react'

//Currently does not support size prop
type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'tertiary-dark' | 'input'
  children: React.ReactNode
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  MantineButtonProps

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = 'tertiary', size, children, ...btnProps }, ref) => {
    const getVariantClass = () => {
      if (variant === 'primary') return '--primary'
      if (variant === 'secondary') return '--secondary'
      if (variant === 'tertiary') return '--tertiary'
      if (variant === 'tertiary-dark') return '--tertiary-dark'
      if (variant === 'input') return '--input'
      if (variant === 'trigger') return '--trigger'
      return ''
    }

    const getSizeClass = () => {
      if (size === 'xs') return '--very-small'
      if (size === 'sm') return '--small'
      if (size === 'lg') return '--large'
      return ''
    }

    return (
      <MantineButton
        {...btnProps}
        ref={ref}
        classNames={{
          root: `${getVariantClass()} ${getSizeClass()}`,
        }}
        loaderProps={{
          color: 'var(--color-primary)',
          type: 'dots',
          size: 'md',
        }}
        size={size}
        radius={4}
      >
        {children}
      </MantineButton>
    )
  },
)
