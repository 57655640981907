import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { k, useCss } from 'kremling'
import { trim } from 'lodash'
import randomColor from 'randomcolor'
import React from 'react'

import { InputField } from '@components/input'
import { Button } from '@components/mantine/button.component'
import { Modal } from '@components/modal.component'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { useForm } from 'react-hook-form'

export function AddOrgModal({ onResolve, onReject }) {
  const user = useAppState(state => state.user)
  const scoped = useCss(css)
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm({
    defaultValues: {
      orgName: '',
    },
  })

  async function handleAddOrg({ orgName }) {
    const createdDate = getTodayDate().toISO()

    const orgRef = await addDoc(collection(db, dbNames.organizations), {
      name: trim(orgName),
      imageUrl: '',
      isPublic: false,
      createdDate,
      isLocked: false,
      userLimit: 5,
      themeColor: randomColor({
        luminosity: 'light',
        hue: 'random',
      }),
    })

    await setDoc(
      doc(db, dbNames.organizationProfiles, `${orgRef.id}_${user.id}`),
      {
        orgId: orgRef.id,
        userId: user.id,
        mentorWorkflowIds: [],
        role: 'owner',
        createdDate,
      },
    )

    await updateDoc(doc(db, dbNames.users, user.id), {
      [`orgRoles.${orgRef.id}`]: 'owner',
      orgOrderIds: arrayUnion(orgRef.id),
    })

    const newOrg = await getDoc(orgRef)

    onResolve(newOrg)
  }

  return (
    <Modal
      {...scoped}
      className="p-6"
    >
      <Modal.Header>Create Workspace</Modal.Header>
      <form onSubmit={handleSubmit(handleAddOrg)}>
        <Modal.Body>
          <InputField
            autoFocus
            control={control}
            fieldName="orgName"
            label="Name"
            required
            className="mt-5"
            placeholder="Workspace name"
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="actions">
            <Button
              variant="secondary"
              onClick={onReject}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="ml-2"
              variant="primary"
              disabled={!isValid}
              loading={isSubmitting}
            >
              Create
            </Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

const css = k`
  .actions {
    display: flex;
    justify-content: flex-end;
  }
`
