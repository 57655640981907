import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import React, { useEffect } from 'react'

export const EditorCapturePlugin = React.forwardRef(
  (props: any, ref: any): null => {
    const [editor] = useLexicalComposerContext()
    useEffect(() => {
      if (!ref) return
      ref.current = editor
      return () => {
        ref.current = null
      }
    }, [editor, ref])

    return null
  },
)
