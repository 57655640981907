import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { normalizePercentage } from '@utils/helpers'
import {
  collection,
  DocumentData,
  getDocs,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  where,
} from 'firebase/firestore'
import {
  difference,
  differenceBy,
  find,
  forEach,
  fromPairs,
  intersectionBy,
  isEmpty,
  isEqual,
  keyBy,
  omit,
} from 'lodash'
import wretch from 'wretch'

export async function compareSections(
  sections: ActivitySection[],
  previousSections: ActivitySection[],
) {
  const { oldModifiedFields, newModifiedFields } = getFieldsToCompare(
    previousSections,
    sections,
  )

  if (isEmpty(oldModifiedFields) || isEmpty(newModifiedFields)) {
    return {}
  }

  try {
    const messages = [
      {
        role: 'system',
        content: `
              You will act as a detail-oriented evaluator. You will be provided with two data mappings with this format:

              {
                [sectionId]: Field[]
              }

              Your job will be to determine if any of the fields within the arrays of fields should have their version number incremented.
              A version increment means that the field has changed in a way that makes a user's existing response to the field incompatible with the new field.

              Cases for a version update:
              All fields:
               - If the text for the title has changed substantially
               - If the text for the description has changed substantially
              TEXT fields:
               - If the textType for a text field changed
               - If the data label for a number field changed substantially
              CHECKBOX, RADIO, SELECT fields:
               - If the choices list changed substantially for radio, checkbox, or select
               - If a choice was added or removed
              LINK or YOUTUBE fields:
               - If the link changed for a link field
               - If the link changed for a youtube field

               Cases that don't warrant a version update:
               - Typo fixes
               - Minor text changes
               - Reordering of choices
               - Reordering of fields
               - Reordering of sections
               - New sections being added

               Your response should be in valid JSON format.

               Here is the format you should pattern your response after:
               {
                  [sectionId]: fieldId[]
               }

              And here is an example of a valid response based on an example input:
              Old fields input:
              {
                "section1": [
                  {
                    "id": "field1",
                    "label": "How are you doing?"
                  }
                ]
              }

              New fields input:
              {
                "section1": [
                  {
                    "id": "field1",
                    "label": "What did you do today?"
                  }
                ]
              }

              Your response:
              {
                "section1": ["field1"]
              }
          `,
      },
      {
        role: 'user',
        content: `
            Here are the old fields: ${JSON.stringify(oldModifiedFields)}
            Here are the new fields: ${JSON.stringify(newModifiedFields)}
          `,
      },
    ]
    const res: { role: string; content: string }[] = await wretch(
      `${process.env.REST_URL_PREFIX}/allFunctions-sendPrompt`,
    )
      .post({
        messages,
        parameters: {
          temperature: 0.4,
          top_p: 0.9,
          presence_penalty: 0.5,
          frequency_penalty: 0.5,
        },
      })
      .json()
    const lastMessage = res.at(-1)
    return JSON.parse(lastMessage.content)
  } catch (err) {
    console.log('Error comparing', err)
  }
}

export function calculateIterationCompletion(
  newRequiredFields: string[],
  completedFieldIds: string[],
  oldFields: Field[],
) {
  if (newRequiredFields.length === 0) return 100

  const completedRequiredFields = newRequiredFields.filter(fieldId => {
    const field = oldFields.find(f => f.id === fieldId)
    if (!field) return false
    return completedFieldIds.includes(`${field.version}_${fieldId}`)
  }).length

  const newPercent = (completedRequiredFields / newRequiredFields.length) * 100

  return normalizePercentage(newPercent)
}

export function getFieldsToCompare(
  oldSections: ActivitySection[],
  newSections: ActivitySection[],
) {
  const keysToOmit = ['version', 'lastEditedDate']
  const oldSectionsMap = keyBy(oldSections, 'id')
  const newSectionsMap = keyBy(newSections, 'id')
  const oldModifiedFields: { [key: string]: Field[] } = {}
  const newModifiedFields: { [key: string]: Field[] } = {}

  forEach(newSectionsMap, (newSection, sectionId) => {
    const oldSection = oldSectionsMap[sectionId]

    const intersectingFieldsOld = intersectionBy(
      oldSection.fields,
      newSection.fields,
      'id',
    )

    const intersectingFieldsNew = intersectionBy(
      newSection.fields,
      oldSection.fields,
      'id',
    )

    if (oldSection) {
      const modifiedOldFields: Field[] = []
      const modifiedNewFields: Field[] = []

      forEach(intersectingFieldsNew, newField => {
        const oldField = find(intersectingFieldsOld, { id: newField.id })

        if (!isEqual(omit(newField, keysToOmit), omit(oldField, keysToOmit))) {
          if (oldField) {
            modifiedOldFields.push(oldField)
          }
          modifiedNewFields.push(newField)
        }
      })

      if (modifiedOldFields.length > 0) {
        oldModifiedFields[sectionId] = modifiedOldFields
      }
      if (modifiedNewFields.length > 0) {
        newModifiedFields[sectionId] = modifiedNewFields
      }
    }
  })

  return {
    oldModifiedFields,
    newModifiedFields,
  }
}

export function getConstraintDifferences(
  oldSections: ActivitySection[],
  newSections: ActivitySection[],
) {
  const oldSectionsMap = keyBy(oldSections, 'id')
  const newSectionsMap = keyBy(newSections, 'id')

  const sectionIds: { [key: string]: string[] } = {}

  forEach(newSectionsMap, (newSection, sectionId) => {
    const oldSection = oldSectionsMap[sectionId]
    if (oldSection) {
      if (!isEqual(newSection.constraints, oldSection.constraints)) {
        sectionIds[sectionId] = difference(
          newSection.constraints.required,
          oldSection.constraints.required,
        )

        sectionIds[sectionId] = sectionIds[sectionId].concat(
          difference(
            oldSection.constraints.required,
            newSection.constraints.required,
          ),
        )
      }
    }
  })

  return sectionIds
}

export const sanitizeSections = (
  sections: ActivitySection[],
  fieldsToOmit: string[],
) => {
  return sections.map(section => {
    const sanitizedFields = section.fields.map(field =>
      omit(field, fieldsToOmit),
    )
    return { ...section, fields: sanitizedFields }
  })
}

export function getAddedSections(
  newSections: ActivitySection[],
  oldSections: ActivitySection[],
) {
  const addedSections = differenceBy(newSections, oldSections, 'id')
  const removedSections = differenceBy(oldSections, newSections, 'id')
  const sameSections = intersectionBy(newSections, oldSections, 'id')
  const sameSectionsOld = intersectionBy(oldSections, newSections, 'id')

  return {
    addedSections,
    removedSections,
    sameSections,
    sameSectionsOld,
  }
}

export function compareFieldIds(
  sections: ActivitySection[],
  previousSections: ActivitySection[],
) {
  const previousFieldsMap = fromPairs(
    previousSections.map(section => [section.id, section.fields]),
  )

  const newFieldsMap = fromPairs(
    sections.map(section => [section.id, section.fields]),
  )

  const addedFields: { [key: string]: Field[] } = {}
  const removedFields: { [key: string]: Field[] } = {}

  forEach(newFieldsMap, (newFields, sectionId) => {
    const previousField = previousFieldsMap[sectionId] || []
    const added = differenceBy(newFields, previousField, 'id')
    if (added.length) {
      addedFields[sectionId] = added
    }
  })

  forEach(previousFieldsMap, (previousFields, sectionId) => {
    const newFields = newFieldsMap[sectionId] || []
    const removed = differenceBy(previousFields, newFields, 'id')
    if (removed.length) {
      removedFields[sectionId] = removed
    }
  })

  return {
    addedFields,
    removedFields,
  }
}

export async function formatActivityData(
  shouldFormat: boolean,
  activitiesSnap: QuerySnapshot<DocumentData, DocumentData>,
  sectionIds: string[],
) {
  if (!shouldFormat || isEmpty(sectionIds))
    return { activityUsersMap: {}, progressSectionsMap: {} }
  const activityUsersMap: { [key: string]: QueryDocumentSnapshot[] } = {}
  const progressSectionsMap: { [key: string]: ProgressSection[] } = {}
  for (const doc of activitiesSnap.docs) {
    const activityUsersSnap = await getDocs(
      query(
        collection(db, dbNames.activityUsers),
        where('activityId', '==', doc.id),
      ),
    )
    activityUsersMap[doc.id] = activityUsersSnap.docs

    const progressSectionsSnap = await getDocs(
      query(
        collection(db, dbNames.progressSections),
        where('activityId', '==', doc.id),
        where('sectionId', 'in', sectionIds),
      ),
    )
    progressSectionsMap[doc.id] = progressSectionsSnap.docs.map(
      p => ({ id: p.id, ...p.data() }) as ProgressSection,
    )
  }

  return { activityUsersMap, progressSectionsMap }
}
