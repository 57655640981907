export const stateFields = {
  audienceDemographics: {
    type: 'longText',
    label: 'Audience demographics',
    placeholder: 'Type here...',
  },
  desiredOutcome: {
    type: 'longText',
    label: 'Desired outcome',
    placeholder: 'Type here...',
  },
  audienceState: {
    type: 'longText',
    label: 'Current audience state',
    placeholder: 'Type here...',
  },
}
