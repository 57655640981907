import { DocumentReference, Transaction } from '@firebase/firestore'
import { getCompletedFieldIds } from '@src/pages/workflow/client/fields.utils'
import { getTodayDate } from '@utils/helpers'
import userAnalytics from '@utils/sendUserAnalytics'
import { isEmpty } from 'lodash'
import wretch from 'wretch'
import {
  calcIterationCompletionPercentage,
  calcSectionCompletionPercentage,
  extractFieldValue,
  getActivityCompletionPercentage,
  getTotalIterationCount,
} from '../activity-utils'

export const saveIteration = (
  transaction: Transaction,
  iterationDocRef: DocumentReference,
  iteration: SectionIteration,
): void => {
  transaction.update(iterationDocRef, {
    fieldResponses: iteration.fieldResponses,
    completedDate: iteration.completedDate,
  })
}

export const saveProgressSection = (
  transaction: Transaction,
  progressSectionDocRef: DocumentReference,
  progressSection: ProgressSection,
) => {
  transaction.update(progressSectionDocRef, {
    completedPercentage: progressSection.completedPercentage,
    completedIterationMap: progressSection.completedIterationMap,
    lastEditedDate: progressSection.lastEditedDate,
    completedFieldsMap: progressSection.completedFieldsMap,
    completedDate: progressSection.completedDate,
  })
}

export const saveActivityUser = (
  transaction: Transaction,
  activityUserDocRef: DocumentReference,
  newActivityUser: ActivityUser,
) => {
  transaction.update(activityUserDocRef, {
    completedPercentage: newActivityUser.completedPercentage,
    completedDate: newActivityUser.completedDate,
  })
}

export const updateIteration = ({
  iteration,
  responses,
  forceComplete,
  section,
  today,
}: {
  iteration: SectionIteration
  responses: FieldResponses
  forceComplete: boolean
  section: ActivitySection
  today: string
}) => {
  const newFieldResponses = {
    ...iteration.fieldResponses,
    ...responses,
  } as FieldResponses

  let iterationCompletedProgress
  if (forceComplete) {
    iterationCompletedProgress = 100
  } else {
    iterationCompletedProgress = calcIterationCompletionPercentage(
      section,
      newFieldResponses,
    )
  }

  const newIteration = {
    ...iteration,
    fieldResponses: newFieldResponses,
  }

  if (iterationCompletedProgress === 100 && !iteration.completedDate) {
    newIteration.completedDate = today
  }

  return { newIteration, iterationCompletedProgress }
}

export const updateProgressSection = ({
  progressSection,
  section,
  iteration,
  activity,
  responses,
  iterationCompletedProgress,
  today,
}: {
  progressSection: ProgressSection
  section: ActivitySection
  iteration: SectionIteration
  iterationCompletedProgress: number
  activity: Activity
  responses: FieldResponses
  today: string
}) => {
  const totalIterationCount = getTotalIterationCount(
    section.constraints,
    activity.startDate,
    activity.endDate,
  )
  const newCompletedIterationMap = {
    ...progressSection.completedIterationMap,
    [iteration.id]: iterationCompletedProgress,
  }
  const sectionCompletedProgress = calcSectionCompletionPercentage(
    newCompletedIterationMap,
    totalIterationCount,
  )

  const completedFieldIds = getCompletedFieldIds({
    ...iteration.fieldResponses,
    ...responses,
  })

  const newCompletedFieldsMap = {
    ...progressSection.completedFieldsMap,
    [iteration.id]: completedFieldIds,
  }

  const newProgressSection = {
    ...progressSection,
    lastEditedDate: today,
    completedFieldsMap: newCompletedFieldsMap,
    completedPercentage: sectionCompletedProgress,
    completedIterationMap: newCompletedIterationMap,
  }

  if (sectionCompletedProgress === 100) {
    newProgressSection.completedDate = today

    if (!activity.isSequential) {
      wretch(
        `${process.env.USER_ANALYTICS_URL}/api/section/log-end/${activity.id}/${progressSection.userId}/${progressSection.sectionId}`,
      ).post()
    }
  }

  return { newProgressSection, sectionCompletedProgress }
}

export const updateActivityUser = ({
  activityUser,
  progressSections,
  activity,
  sections,
}: {
  activityUser: ActivityUser
  progressSections: ProgressSection[]
  activity: Activity
  sections: ActivitySection[]
}) => {
  const activityCompletedProgress = getActivityCompletionPercentage(
    progressSections,
    activity,
    sections,
  )
  const newActivityUser = {
    ...activityUser,
    completedPercentage: activityCompletedProgress,
    completedDate:
      activityCompletedProgress === 100 ? getTodayDate().toISO() : null,
  }

  if (activityCompletedProgress === 100) {
    //Parallel activities are completed when activity progress is 100%. Sequential activities are completed when user marks last section complete
    if (!activity.isSequential) {
      wretch(
        `${process.env.USER_ANALYTICS_URL}/api/activity/log-end/${activity.id}/${activityUser.userId}`,
      ).post()
    }
  }

  return { newActivityUser, activityCompletedProgress }
}

export const sendAnalyticsEvents = async ({
  responses,
  section,
  iteration,
  activity,
  user,
  sectionCompletedProgress,
  iterationCompletedProgress,
  activityCompletedProgress,
  today,
}: {
  responses: FieldResponses
  section: ActivitySection
  iteration: SectionIteration
  activity: Activity
  user: User
  sectionCompletedProgress: number
  iterationCompletedProgress: number
  activityCompletedProgress: number
  today: string
}) => {
  if (isEmpty(responses)) return
  for (const [fieldId, value] of Object.entries(responses)) {
    const field: Field = section.fields.find(
      f => `${f.version}_${f.id}` === fieldId,
    )

    const { stringValue, arrayValue, actionCompleted, unitValue } =
      extractFieldValue(field, value)

    userAnalytics.registerEvent({
      value: stringValue,
      choices: arrayValue,
      fieldType: field.type,
      version: field.version,
      unit: unitValue,
      textType: field.textType,
      user,
      activity,
      section,
      iteration,
      originTemplateId: activity.templateId,
      fieldId: field.id,
      groupId: activity.id,
      createdDate: today,
      sectionCompletedPercentage: sectionCompletedProgress,
      iterationCompletedPercentage: iterationCompletedProgress,
      activityCompletedPercentage: activityCompletedProgress,
      orgId: activity.orgId,
      actionCompleted,
    })
  }

  await userAnalytics.sendEvents()
}
