import React from 'react'
import { bool, number, oneOfType, string } from 'prop-types'

export function ItemBase({ isSelected, id, children, ...rest }) {
  return (
    <div
      data-selected={isSelected}
      data-id={id}
      {...rest}
    >
      {children}
    </div>
  )
}

ItemBase.propTypes = {
  isSelected: bool,
  id: oneOfType([string, number]),
}
