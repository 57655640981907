import React from 'react'
import Tippy, { TippyProps } from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'

interface TooltipProps extends TippyProps {
  disabled?: boolean
  maxWidth?: number
}

export function Tooltip({
  children,
  placement = 'auto',
  disabled,
  maxWidth = null,
  ...tippyProps
}: TooltipProps) {
  // For some reason applying the disabled prop on Tippy, it says it's not a valid prop.
  // The documentation lists it as a prop for Tippy/React, but I don't see it in the tippy.js documentation.
  if (disabled) return children

  return (
    <Tippy
      duration={200}
      placement={placement}
      appendTo={() => document.body}
      boundary="window"
      maxWidth={maxWidth}
      {...tippyProps}
    >
      <div tabIndex={0}>{children}</div>
    </Tippy>
  )
}
