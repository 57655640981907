import React from 'react'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { useCss, k } from 'kremling'
import { DateTime } from 'luxon'
import {
  collection,
  where,
  orderBy,
  query,
  getDocs,
  updateDoc,
  doc,
  increment,
} from 'firebase/firestore'

import { Well } from '@components/well.component'
import { useLoad } from '@hooks/use-load.hook'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { Icon } from '@components/icon.component'
import { MarketplaceTemplateCard } from '@src/pages/marketplace/marketplace-template-card.component'
import { searchTypesense } from '@typesense-config'

export function AdoptedTemplatesList({ searchVal = '', className }) {
  const { orgId } = useParams()
  const scoped = useCss(css)

  const [adoptedTemplates, _setAdoptedTemplates, adoptedTemplatesOpts] =
    useLoad(
      [],
      () => {
        const q = query(
          collection(db, dbNames.adoptedMarketplaceTemplates),
          orderBy('name'),
          where('orgId', '==', orgId),
        )
        return getDocs(q).then(q =>
          q.docs.map(d => ({ ...d.data(), id: d.id })),
        )
      },
      [],
    )

  const [searchTemplates, _setSearchTemplates, _searchTemplatesOpts] = useLoad(
    [],
    async () => {
      if (isEmpty(adoptedTemplates)) return Promise.resolve([])
      const searchRequest = {
        q: searchVal,
        query_by: 'name, description',
        filter_by: `id: [${adoptedTemplates.map(
          ({ marketplaceTemplateId }) => marketplaceTemplateId,
        )}]`,
      }
      return searchTypesense('marketplaceTemplates', searchRequest).then(
        ({ hits }) => {
          return hits.map(({ document }) => {
            const { viewsCount, adoptionsCount, name, description } =
              adoptedTemplates.find(
                ({ marketplaceTemplateId }) =>
                  marketplaceTemplateId === document.id,
              )
            const lastPublishedDate = DateTime.fromSeconds(
              document.lastPublishedDate,
            ).toISO()
            const createdDate = DateTime.fromSeconds(
              document.createdDate,
            ).toISO()
            return {
              ...document,
              viewsCount,
              adoptionsCount,
              name,
              description,
              lastPublishedDate,
              createdDate,
            }
          })
        },
      )
    },
    [adoptedTemplates, searchVal],
  )

  function renderAdoptedTemplates() {
    return searchTemplates.map(template => (
      <MarketplaceTemplateCard
        key={template.id}
        href={`marketplace/t/${template.id}`}
        template={template}
        onView={() => {
          updateDoc(
            doc(
              db,
              dbNames.adoptedMarketplaceTemplates,
              `${orgId}_${template.id}`,
            ),
            {
              viewsCount: increment(1),
            },
          )
        }}
      />
    ))
  }

  if (adoptedTemplatesOpts.loading || isEmpty(adoptedTemplates)) return null

  return (
    <Well className={className}>
      <Well.Header>
        <Icon
          className="mr-3"
          name="globe"
        />
        From Marketplace
      </Well.Header>
      <Well.Body>
        <div
          {...scoped}
          className="marketplace-well"
        >
          {renderAdoptedTemplates()}
        </div>
      </Well.Body>
    </Well>
  )
}

const css = k`
  .marketplace-well {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
  }
`
