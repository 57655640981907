import { a, k, useCss } from 'kremling'
import { partition } from 'lodash'
import { arrayOf, bool, func, number, oneOf, string } from 'prop-types'
import React, { forwardRef, useRef } from 'react'

import { toastService } from '@utils/toast.service'
import { Icon } from './icon.component'
import { Button } from './mantine/button.component'

export const FileUpload = forwardRef(
  (
    {
      className,
      uploadTrigger,
      onBlur,
      onFocus,
      error,
      readOnly,
      megabyteLimit = 2,
      onChange = () => {},
      disableFileTypes = [],
      multiple = false,
      btnVariant = 'primary',
    },
    ref,
  ) => {
    const scope = useCss(css)
    const hiddenFileInput = useRef(null)

    const disableImages = !disableFileTypes.includes('image')
    const disablePdfs = !disableFileTypes.includes('pdf')

    const fileTypesToAccept = [
      disableImages && 'image/jpeg',
      disableImages && 'image/png',
      disablePdfs && 'application/pdf',
    ].filter(type => type)

    function handleClick() {
      hiddenFileInput.current.click()
    }

    function handleChange(e) {
      const files = [...e.currentTarget.files]
      const oneMegaByte = 1048576
      const [tooLarge, justRight] = partition(files, file => {
        return file.size > oneMegaByte * megabyteLimit
      })

      if (tooLarge.length) {
        toastService.error(
          `One or more of the files you are uploading is too large`,
        )
      }

      onChange({
        files: justRight,
        tooLarge,
      })
    }

    return (
      <>
        {uploadTrigger ? (
          uploadTrigger({ handleClick })
        ) : (
          <div
            {...scope}
            className={a('', className)}
          >
            <div className="upload-btn-container">
              <Button
                ref={ref}
                variant={btnVariant}
                onClick={handleClick}
                disabled={readOnly}
                onBlur={onBlur}
                onFocus={onFocus}
                leftSection={
                  <Icon
                    size={14}
                    name="file-upload"
                  />
                }
              >
                Choose file{multiple ? 's' : ''}
              </Button>
              <div className="upload-limit">({megabyteLimit}MB limit)</div>
            </div>
            {error && <div className="mt-1 text-xl text-danger">{error}</div>}
          </div>
        )}
        <input
          accept={fileTypesToAccept.join(',')}
          type="file"
          hidden
          disabled={readOnly}
          ref={hiddenFileInput}
          onChange={handleChange}
          multiple={multiple}
        />
      </>
    )
  },
)

FileUpload.propTypes = {
  megabyteLimit: number,
  onChange: func,
  multiple: bool,
  btnType: string,
  disableFileTypes: arrayOf(oneOf(['pdf', 'image'])),
}

const css = k`
  .upload-btn-container {
    display: flex;
    align-items: center;
  }

  .upload-limit {
    margin-left: .8rem;
    font-weight: 500;
  }
`
