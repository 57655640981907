import { doc, getDoc } from 'firebase/firestore'
import { k, useCss } from 'kremling'
import qs from 'query-string'
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import { Card } from '@components/card.component'
import { Loader } from '@components/loader.component'
import { Logo } from '@components/logo.component'
import { TextDivider } from '@components/text-divider.component'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { OauthList } from './auth/oauth/oauth-list.component'

export function InvitationLogin() {
  const scope = useCss(css)
  const isAuthenticated = useAppState(state => state.isAuthenticated)
  const [invitedUser, setInvitedUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const queryId = qs.parse(location.search).id

  useEffect(() => {
    setLoading(true)
    if (!queryId) return
    getDoc(doc(db, dbNames.users, queryId)).then(u => {
      setInvitedUser(u.data())
      setLoading(false)
    })
  }, [])

  if (!queryId) {
    return <Navigate to="/sign-in" />
  }

  if (isAuthenticated) {
    return <Navigate to={'/'} />
  }

  return (
    <div
      className="login-wrapper"
      {...scope}
    >
      <Card className="login-card">
        {loading ? (
          <div className="load-section">
            <Loader />
          </div>
        ) : (
          <div className="verify-content">
            <div className="mt-5">
              <Logo />
            </div>
            <div className="mt-5">Welcome, {invitedUser?.firstName}!</div>
            <div className="mb-16">
              To access your account, please sign in with one of the options
              below using the same account where you received your iAct
              invitation.
            </div>
            <TextDivider className="mt-5 mt-5">OR</TextDivider>
            <OauthList />
          </div>
        )}
      </Card>
    </div>
  )
}

const css = k`
  .login-wrapper {
    background: $color-grey-25;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-card {
    width: 40rem;
    padding: 3rem;
  }

  .login-card > .load-section {
    height: 23rem;
  }

  .load-section {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
