import { a, k, useCss } from 'kremling'
import { isEqual, isNumber } from 'lodash'
import {
  bool,
  func,
  number,
  object,
  oneOf,
  oneOfType,
  string,
} from 'prop-types'
import React, {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'

function _Dropdown(props, dropdownRef) {
  const {
    allowContentClicks,
    className,
    content, // remove
    contentHeight = 'auto',
    contentWidth = 'sm',
    cover,
    disabled,
    fixedContent,
    inline,
    position = 'bottom',
    onClose,
    onOpen,
    renderContent,
    renderTrigger,
    triggerIsBlock,
    defaultOpenState,
  } = props
  const dropdownEl = useRef()
  const triggerEl = useRef()
  const contentEl = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [openAbove, setOpenAbove] = useState(false)
  const [horizontalAbove, setHorizontalAbove] = useState(false)
  const [positionStyles, setPositionStyles] = useState({})
  const scope = useCss(css)
  const [direction, lean] = position.split('-')

  useEffect(() => {
    return () => {
      document.removeEventListener('click', documentClickHandler)
    }
  }, [])

  useEffect(() => {
    if (disabled && isOpen) close()
  }, [disabled])

  useEffect(() => {
    if (defaultOpenState) {
      onOpenDropdown()
    }
  }, [])

  useLayoutEffect(() => {
    if (isOpen) {
      if (contentEl?.current) contentEl.current.focus()
      const viewHeight = window.innerHeight
      const rect = contentEl.current.getBoundingClientRect()
      setOpenAbove(
        (rect.top + rect.height > viewHeight && direction === 'bottom') ||
          direction === 'top',
      )
      setHorizontalAbove(
        rect.top + rect.height > viewHeight &&
          ['right', 'left'].includes(direction),
      )
    }
  }, [isOpen, direction])

  useLayoutEffect(() => {
    if (
      fixedContent &&
      dropdownEl &&
      dropdownEl.current &&
      contentEl &&
      contentEl.current
    ) {
      const dropdownRect = dropdownEl.current.getBoundingClientRect()
      const contentRect = contentEl.current.getBoundingClientRect()
      const viewWidth = window.innerWidth
      let newStyles = {}

      // horizontal fixations, brush up on your ClientRect before proceeding
      if (['right', 'left'].includes(direction)) {
        if (direction === 'left') {
          newStyles.right = cover
            ? viewWidth - dropdownRect.right
            : viewWidth - dropdownRect.left
        } else if (direction === 'right') {
          newStyles.left = cover ? dropdownRect.left : dropdownRect.right
        }
        if (lean === 'top') {
          newStyles.bottom = window.innerHeight - dropdownRect.bottom
        } else newStyles.top = dropdownRect.top
      } else {
        // vertical fixations
        if (direction === 'top' || openAbove) {
          newStyles.top = cover
            ? dropdownRect.top + dropdownRect.height - contentRect.height
            : dropdownRect.top - contentRect.height
        } else {
          // bottom
          newStyles.top = cover
            ? dropdownRect.top
            : dropdownRect.top + dropdownRect.height
        }
        if (lean === 'left') {
          newStyles.right = viewWidth - dropdownRect.right
        } else newStyles.left = dropdownRect.left
      }
      if (contentWidth === 'block') {
        newStyles.width = dropdownRect.width
      }
      if (contentWidth === 'auto') {
        newStyles.width = 'auto'
      }
      if (!isEqual(newStyles, positionStyles)) {
        setPositionStyles(newStyles)
      }
    }
  })

  function doesContain(parent, child) {
    if (!child || !child.classList) return false
    if (child.classList.contains('dropdown-content')) return true
    if (!child.parentNode && child.nodeName !== '#document') return true
    return doesContain(parent, child.parentNode)
  }

  function documentClickHandler(e) {
    if (!dropdownEl || !dropdownEl.current || !triggerEl.current) return
    const contains =
      dropdownEl.current.contains(e.target) ||
      doesContain(dropdownEl.current, e.target)
    const isTrigger = triggerEl.current.contains(e.target)
    if (isTrigger) return
    if ((contains && !allowContentClicks) || !contains) {
      onCloseDropdown()
    }
  }

  function toggle(e) {
    if (isOpen) {
      onCloseDropdown(e)
    } else if (!isOpen) {
      onOpenDropdown(e)
    }
  }

  function onOpenDropdown(e) {
    if (e) e.stopPropagation()
    if (disabled) return false
    setIsOpen(true)
    document.addEventListener('click', documentClickHandler)
    if (onOpen) onOpen(e)
  }

  function onCloseDropdown(e) {
    if (e) e.stopPropagation()
    setIsOpen(false)
    setOpenAbove(false)
    setHorizontalAbove(false)
    document.removeEventListener('click', documentClickHandler)
    if (onClose) onClose(e)
  }

  if (dropdownRef) {
    const current = {
      open: onOpenDropdown,
      close: onCloseDropdown,
      toggle,
      isOpen,
    }
    if (typeof dropdownRef === 'function') {
      dropdownRef({ current })
    } else {
      dropdownRef.current = current
    }
  }

  const setWidth = isNumber(contentWidth)
    ? { width: `${contentWidth / 10}rem` }
    : {}

  const buildContent = () => (
    <div
      {...scope}
      ref={contentEl}
      onClick={e => e.stopPropagation()}
      className={a('dropdown-content')
        .m('dropdown-content--fixed', fixedContent)
        .m('dropdown-content--hide', !isOpen)
        .m('dropdown-content--above', !fixedContent && openAbove)
        .m(
          'dropdown-content--horizontalAbove',
          !fixedContent && horizontalAbove,
        )
        .m('dropdown-content--right', !fixedContent && direction === 'right')
        .m('dropdown-content--bottom', !fixedContent && direction === 'bottom')
        .m('dropdown-content--left', !fixedContent && direction === 'left')
        .m('dropdown-content--cover', !fixedContent && cover)
        .m('dropdown-content--lean-top', lean === 'top')
        .m('dropdown-content--lean-right', lean === 'right')
        .m('dropdown-content--lean-bottom', lean === 'bottom')
        .m('dropdown-content--lean-left', lean === 'left')
        .m('dropdown-content--md', contentWidth === 'md')
        .m('dropdown-content--lg', contentWidth === 'lg')
        .m('dropdown-content--block', contentWidth === 'block')}
      style={{
        maxHeight: contentHeight,
        ...setWidth,
        ...positionStyles,
      }}
    >
      {(renderContent || content)({ isOpen, close: onCloseDropdown })}
    </div>
  )

  return (
    <div
      {...scope}
      className={a(`dropdown`)
        .a(className)
        .m('dropdown--block', triggerIsBlock)
        .m('dropdown--inline', inline)}
      ref={dropdownEl}
    >
      <span
        ref={triggerEl}
        className={a('dropdown-trigger').m(
          'dropdown-trigger--block',
          triggerIsBlock,
        )}
      >
        {renderTrigger({
          isOpen,
          toggle,
          open: onOpenDropdown,
          close: onCloseDropdown,
        })}
      </span>
      {fixedContent
        ? createPortal(buildContent(), document.body)
        : buildContent()}
    </div>
  )
}

export const Dropdown = forwardRef(_Dropdown)

export const dropdownPropTypes = {
  allowContentClicks: bool,
  className: oneOfType([object, string]),
  contentHeight: oneOfType([string, number]),
  contentWidth: oneOfType([number, oneOf(['sm', 'md', 'lg', 'block'])]),
  position: oneOf([
    'top-left',
    'top',
    'top-right',
    'right-top',
    'right',
    'right-bottom',
    'bottom-right',
    'bottom',
    'bottom-left',
    'left-bottom',
    'left',
    'left-top',
  ]),
  cover: bool,
  disabled: bool,
  fixedContent: bool,
  inline: bool,
  renderTrigger: func,
  renderContent: func,
  triggerIsBlock: bool,
  defaultOpenState: bool,
}

_Dropdown.propTypes = dropdownPropTypes

const css = k`
  .dropdown {
    position: relative;
    display: inline-flex;
  }

  .dropdown.dropdown--inline {
    display: inline-block;
  }

  .dropdown.dropdown--block {
    display: block;
  }

  .dropdown-trigger {
    display: inline-flex;
  }

  .dropdown-trigger--block {
    display: block;
  }

  .dropdown-content {
    position: absolute;
    z-index: 99;
    background-color: #fff;
    border-radius: $base-border-radius;
    box-shadow: $box-shadow-2;
    width: 18rem;
    cursor: default;
    overflow: auto;
  }

  .dropdown-content--hide {
    display: none;
  }

  .dropdown-content--fixed {
    position: fixed;
    z-index: 10000;
  }

  .dropdown-content--horizontalAbove {
    bottom: 0;
    top: auto;
  }

  .dropdown-content--right {
    right: auto;
    left: 100%;
  }

  .dropdown-content--right:not(.dropdown-content--horizontalAbove):not(.dropdown-content--lean-top),
  .dropdown-content--left:not(.dropdown-content--horizontalAbove):not(.dropdown-content--lean-top) {
    top: 0;
  }

  .dropdown-content--bottom {
    top: 100%;
  }

  .dropdown-content--left {
    left: auto;
    right: 100%;
  }

  .dropdown-content--cover.dropdown-content--above {
    top: auto;
    bottom: 0;
  }

  .dropdown-content--cover.dropdown-content--right {
    left: 0;
  }

  .dropdown-content--cover.dropdown-content--bottom {
    top: 0;
  }

  .dropdown-content--cover.dropdown-content--left {
    right: 0;
  }

  .dropdown-content--lean-top:not(.dropdown-content--fixed) {
    bottom: 0;
  }

  .dropdown-content--lean-right:not(.dropdown-content--fixed) {
    left: 0;
  }

  .dropdown-content--lean-bottom:not(.dropdown-content--fixed):not(.dropdown-content--horizontalAbove) {
    top: 0;
  }

  .dropdown-content--lean-left:not(.dropdown-content--fixed) {
    right: 0;
  }

  .dropdown-content--md {
    width: 28rem;
  }

  .dropdown-content--lg {
    width: 40rem;
  }

  .dropdown-content--block {
    width: 100%;
  }

  .dropdown-content--above {
    top: auto;
    bottom: 100%;
  }
`
