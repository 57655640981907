import { BadgeWrapper } from '@components/badge.component'
import { Icon } from '@components/icon.component'
import { Button } from '@components/mantine/button.component'
import { SavingIndicator } from '@components/saving-indicator.component'
import { useAppState } from '@src/app.state'
import { db, isDevelopment, isStage } from '@src/firebase-app'
import { NavLink } from '@src/navigation/nav-link.component'
import { AuthModal } from '@src/pages/account/auth/auth-modal.component'
import { SectionListItem } from '@src/pages/workflow/client/workflow-refactor/section-list-item.component'
import { SequentialSectionItem } from '@src/pages/workflow/client/workflow-refactor/sequential-section-item.component'
import { useActivityStore } from '@src/pages/workflow/client/workflow-refactor/state/use-activity-state'
import { dbNames } from '@utils/constants'
import { getTodayDate, getUserFullName } from '@utils/helpers'
import { modalService } from '@utils/modal.service'
import { doc, increment, updateDoc } from 'firebase/firestore'
import { AnimatePresence, motion } from 'framer-motion'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import wretch from 'wretch'
import { FeedbackModal } from './feedback-modal.component'
import { useNavigationEffect } from './hooks/use-navigation-effect.hook'
import { IterationNavigation } from './iteration-navigation'
import { resetAllStores } from './state/create'

type Props = {
  headless?: boolean
}

export function Activity({ headless }: Props) {
  const { activityId, userId, sectionId, editToken, orgId } = useParams()
  const user = useAppState(state => state.user)
  const navigate = useNavigate()
  const activity = useActivityStore.use.activity()
  const sections = useActivityStore.use.sections()
  const progressSections = useActivityStore.use.progressSections()
  const activityUser = useActivityStore.use.activityUser()
  const activitySaving = useActivityStore.use.activitySaving()
  const navigationOpen = useActivityStore.use.navigationOpen()
  const progressSection = progressSections.find(
    (p: ProgressSection) => p.sectionId === sectionId,
  )

  const fetchActivity = useActivityStore.use.fetchActivity()
  const fetchSections = useActivityStore.use.fetchSections()
  const fetchActivityUser = useActivityStore.use.fetchActivityUser()
  const fetchProgressSections = useActivityStore.use.fetchProgressSections()
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const convertedStartDate = DateTime.fromISO(activity?.startDate.date, {
    zone: activity?.startDate.timeZone,
  }).setZone(timeZone)

  useEffect(() => {
    if (!orgId) return
    if (!activityId || !user?.id || !activityUser) return
    if (user.id !== activityUser?.userId) return
    wretch(
      `${process.env.USER_ANALYTICS_URL}/api/activity/log-start/${activityId}`,
    ).post({
      user,
      orgId,
    })
  }, [activityId, user?.id, orgId, activityUser])

  const activityHasStarted = useMemo(() => {
    if (isEmpty(activity)) return false
    const today = getTodayDate()
    return convertedStartDate <= today
  }, [activity])

  const {
    direction,
    goToNextIteration,
    goToPreviousIteration,
    paginate,
    iterations,
    exitActivity,
    navItems,
  } = useNavigationEffect()

  const readOnly = useMemo(() => {
    if (isEmpty(activityUser)) return
    const isOwnWorkflow = activityUser.userId === user?.id
    return !isOwnWorkflow || !activityHasStarted
  }, [activityUser, user, activityHasStarted])

  useEffect(() => {
    if (!activityId || !userId) return
    fetchActivity(activityId)
    fetchActivityUser(activityId, userId)

    return () => {
      useActivityStore.getState().progressSectionsObs?.()
      useActivityStore.getState().sectionsObs?.()
    }
  }, [activityId, userId])

  useEffect(() => {
    if (!activity) return

    fetchSections(activity)
  }, [activity?.templateId, activity?.version, userId, activityId])

  useEffect(() => {
    if (isEmpty(sections)) return
    fetchProgressSections(sections, userId)
  }, [sections, userId])

  useEffect(() => {
    return () => resetAllStores()
  }, [])

  async function handleAuthModal() {
    try {
      const { type, user: authUser } = await modalService.render(AuthModal, {
        guestUser: user,
        editToken,
        activityId,
        orgId: activity.orgId,
      })
      switch (type) {
        case 'register':
          navigate(`/${activity.orgId}/home/${authUser.id}/${activity.id}`)
          break
      }
    } catch (err) {
      console.log(err)
    }
  }

  function renderSections() {
    return sections.map((section, index) => {
      if (activity.isSequential) {
        return (
          <SequentialSectionItem
            section={section}
            key={section.id}
            index={index}
          />
        )
      }
      return (
        <SectionListItem
          section={section}
          key={section.id}
        />
      )
    })
  }
  const section = sections.find(s => s.id === sectionId)

  function openFeedbackModal(isLast: boolean) {
    modalService.render(FeedbackModal, {
      activityId: activity.id,
      userId: user.id,
      section,
      isLast,
    })
  }

  if (isEmpty(activity) || isEmpty(sections) || isEmpty(progressSections))
    return null

  return (
    <div className="flex-1 h-full p-4 media2:p-2 flex gap-2 media2:bg-grey-25">
      <div className="flex-1 flex w-full gap-2">
        <div className="w-[30rem] hidden media2:flex flex-col h-full gap-2">
          <div className="flex gap-2">
            {!headless && (
              <button
                className="shadow-sm shrink-0 rounded-md border border-grey-100 bg-white flex transition-colors items-center w-20 justify-center text-grey-500 hover:bg-grey-50"
                onClick={() => exitActivity()}
              >
                <Icon name="angle-left" />
              </button>
            )}
            <div className="shadow-sm rounded-md bg-white border border-grey-100 h-24 flex flex-1 items-center px-4 justify-between flex-ellipsis">
              <div>
                <h1 className="text-2xl font-semibold mb-0 truncate">
                  {activity?.name}
                </h1>
                {!headless && (
                  <div className="text-lg">{getUserFullName(activityUser)}</div>
                )}
              </div>
              <SavingIndicator saving={activitySaving} />
            </div>
          </div>
          <div className="shadow-sm rounded-md bg-white border border-grey-100 text-grey-500 relative">
            {navItems.map((item, i) => {
              return headless && user?.isGuest ? (
                <div
                  key={i}
                  className="block px-6 py-4 border-b border-grey-100 text-grey-700 hover:text-grey-500 last:border-b-0"
                >
                  {item.label}
                </div>
              ) : (
                <BadgeWrapper
                  key={i}
                  count={item.notifications}
                  showBadge={!!item.notifications}
                >
                  <NavLink
                    activeClassName="bg-active-25 text-primary font-bold hover:bg-active-50 hover:text-primary"
                    className="block px-6 py-4 border-b border-grey-100 hover:bg-active-50 text-grey-700 hover:text-grey-500 last:border-b-0"
                    to={item.to}
                  >
                    <div className="flex items-center mr-1">
                      <Icon
                        className="mr-2"
                        name={item.icon}
                      />
                      {item.label}
                    </div>
                  </NavLink>
                </BadgeWrapper>
              )
            })}
            {headless && user?.isGuest && (
              <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center backdrop-blur-sm">
                <Button
                  variant="primary"
                  onClick={handleAuthModal}
                  className="flex items-center"
                >
                  <Icon
                    name="lock"
                    className="mr-2"
                    size={14}
                  />
                  Unlock features
                </Button>
              </div>
            )}
          </div>
          <div className="shadow-sm flex-1 border border-grey-100 rounded-md bg-white overflow-hidden">
            {renderSections()}
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-2">
          <div className="flex media2:hidden gap-2">
            {!headless && (
              <button
                className="shadow-sm shrink-0 rounded-tl-3xl rounded-bl-lg border border-grey-100 bg-white flex transition-colors items-center w-20 justify-center text-grey-500 hover:bg-grey-50"
                onClick={() => exitActivity()}
              >
                <Icon name="angle-left" />
              </button>
            )}
            <button
              onClick={() =>
                useActivityStore.setState({ navigationOpen: true })
              }
              className="flex bg-white h-20 py-4 px-4 border border-grey-100 rounded-tr-3xl rounded-br-lg items-center w-full justify-between"
            >
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center">
                  <Icon
                    name="ellipsis-v"
                    className="mr-2 text-grey-200"
                  />
                  <div className="text-left">
                    <div className="text-2xl font-semibold">
                      {section?.name || 'Untitled section'}
                    </div>
                  </div>
                </div>
                <div>
                  <SavingIndicator saving={activitySaving} />
                </div>
              </div>
            </button>
          </div>
          {sectionId && activity.endDate ? (
            <>
              <IterationNavigation
                goToNextIteration={goToNextIteration}
                goToPreviousIteration={goToPreviousIteration}
                iterations={iterations}
              />
              <div className="relative mx-auto w-full flex-1 flex justify-center py-4">
                <Outlet context={{ direction, paginate, readOnly }} />
              </div>
            </>
          ) : (
            <div className="media2:max-w-[70rem] media2:min-w-[30rem] h-full w-full mx-auto flex flex-col relative">
              <div className="flex-1 overflow-y-auto">
                <Outlet context={{ readOnly, activity }} />
              </div>
              <AnimatePresence>
                {progressSection?.completedPercentage === 100 &&
                  !progressSection?.isSubmitted &&
                  activityUser?.userId === user?.id && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { delay: 0.2 } }}
                      exit={{
                        opacity: 0,
                        transition: { delay: 0 },
                      }}
                      transition={{
                        duration: 0.2,
                        ease: 'easeOut',
                      }}
                      className="w-full max-w-[70rem] bg-white p-4 flex items-center shadow-md rounded-lg justify-center gap-4 border border-grey-100 absolute bottom-0"
                    >
                      <div className="hidden media2:block text-2xl font-bold">
                        Great job! You finished the section
                      </div>
                      <Button
                        variant="primary"
                        onClick={async () => {
                          const today = getTodayDate()
                          const currentDoc = doc(
                            db,
                            dbNames.progressSections,
                            progressSection.id,
                          )
                          const currentIndex = sections.findIndex(
                            section => section.id === sectionId,
                          )
                          const nextIndex = currentIndex + 1
                          const isLastSection = nextIndex === sections.length

                          await updateDoc(currentDoc, {
                            isSubmitted: true,
                            completedDate: today.toISO(),
                          })

                          wretch(
                            `${process.env.USER_ANALYTICS_URL}/api/section/log-end/${activity.id}/${progressSection.userId}/${progressSection.sectionId}`,
                          ).post()

                          const activityUserDocRef = doc(
                            db,
                            dbNames.activityUsers,
                            `${activity.id}_${user.id}`,
                          )

                          if (
                            isLastSection ||
                            activityUser.completedPercentage === 100
                          ) {
                            wretch(
                              `${process.env.USER_ANALYTICS_URL}/api/activity/log-end/${activity.id}/${user.id}`,
                            ).post()

                            useActivityStore.setState({
                              navigationOpen: true,
                            })

                            await updateDoc(activityUserDocRef, {
                              unlockDate: null,
                              activeSectionIndex: increment(1),
                            })

                            return openFeedbackModal(true)
                          }

                          if (
                            activityUser.activeSectionIndex - 1 <
                              sections.length &&
                            activityUser.activeSectionIndex === currentIndex
                          ) {
                            const unlockDate =
                              isDevelopment || isStage
                                ? today.plus({ minutes: 1 }).toMillis()
                                : today
                                    .plus({ day: 1 })
                                    .startOf('day')
                                    .toMillis()

                            await updateDoc(activityUserDocRef, {
                              unlockDate,
                            })

                            useActivityStore.setState(state => ({
                              activityUser: {
                                ...state.activityUser,
                                unlockDate,
                              },
                            }))
                          }

                          useActivityStore.setState({
                            navigationOpen: true,
                          })

                          if (!activityUser.noFeedbackUntilEnd) {
                            openFeedbackModal(false)
                          }
                        }}
                      >
                        Mark section complete
                      </Button>
                    </motion.div>
                  )}
              </AnimatePresence>
            </div>
          )}
        </div>
      </div>
      <div className="media2:hidden block">
        <AnimatePresence initial={false}>
          {navigationOpen && (
            <motion.div
              className="absolute w-full h-full bg-white bottom-0 left-0 right-0 p-2 shadow-md z-10"
              initial={{ y: '100%' }}
              animate={{ y: 0 }}
              exit={{ y: '100%' }}
              transition={{ duration: 0.1, ease: 'easeOut' }}
            >
              <div className="flex mb-4 gap-2 h-30">
                <div className="rounded-md p-2 flex-1">
                  <div>
                    <h1 className="text-4xl font-semibold mb-0 truncate">
                      {activity?.name}
                    </h1>
                    {!headless && (
                      <div className="text-lg">
                        {getUserFullName(activityUser)}
                      </div>
                    )}
                  </div>
                </div>
                <button
                  onClick={() =>
                    useActivityStore.setState({ navigationOpen: false })
                  }
                  className="flex shadow-sm shrink-0 items-center justify-center w-20 bg-white border border-grey-100 rounded-md"
                >
                  <Icon name="times" />
                </button>
              </div>
              <div className="mb-4 border border-grey-100 rounded-md">
                {navItems.map((item, i) => {
                  return headless && user?.isGuest ? (
                    <div
                      key={i}
                      className="block px-6 py-4 border-b border-grey-100 text-grey-700 hover:text-grey-500"
                    >
                      {item.label}
                    </div>
                  ) : (
                    <BadgeWrapper
                      key={i}
                      count={item.notifications}
                      showBadge={!!item.notifications}
                    >
                      <NavLink
                        activeClassName="bg-active-25 text-primary font-bold hover:bg-active-50 hover:text-primary"
                        className="block px-6 py-4 border-b border-grey-100 hover:bg-active-50 text-grey-700 hover:text-grey-500 last:border-b-0"
                        to={item.to}
                        onClick={() =>
                          useActivityStore.setState({ navigationOpen: false })
                        }
                      >
                        <div className="flex items-center mr-1">
                          <Icon
                            className="mr-2"
                            name={item.icon}
                          />
                          {item.label}
                        </div>
                      </NavLink>
                    </BadgeWrapper>
                  )
                })}
                {headless && user?.isGuest && (
                  <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center backdrop-blur-sm">
                    <Button
                      variant="primary"
                      onClick={handleAuthModal}
                      className="flex items-center"
                    >
                      <Icon
                        name="lock"
                        className="mr-2"
                        size={14}
                      />
                      Unlock features
                    </Button>
                  </div>
                )}
              </div>
              <div className="border border-grey-100 rounded-md">
                {renderSections()}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}
