import React, { useEffect } from 'react'
import { bool, oneOf, string } from 'prop-types'
import { useCss, a, m } from 'kremling'

import css from './loader.kremling.scss'

export function Loader({
  size = 'md',
  overlay,
  className,
  inline,
  ariaLabel = 'Loading',
  loadingText = '',
}) {
  const scope = useCss(css)

  useEffect(() => {
    if (overlay) document.addEventListener('keydown', keypressHandler)
    else document.removeEventListener('keydown', keypressHandler)
    return () => document.removeEventListener('keydown', keypressHandler)
  }, [overlay])

  let width, height
  switch (size) {
    case 'lg':
      width = 80
      height = 60
      break
    case 'md':
      width = 60
      height = 45
      break
    case 'sm':
      width = 30
      height = 22.5
      break
  }
  return (
    <div
      {...scope}
      className={m('loader-overlay', overlay)
        .m('loader__inline', inline)
        .a(className)}
      aria-label={ariaLabel}
    >
      <div
        className={a('loader')
          .m('loader--md', size === 'md')
          .m('loader-xs', size === 'xs')}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="5 0 80 60"
        >
          <path
            id="wave"
            stroke="#2252C5"
            d="M 0 37.5 c 7.684299348848887 0 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15 s 7.172012725592294 15 15 15 s 7.172012725592294 -15 15 -15"
            fill="none"
            strokeWidth="6"
            strokeLinecap="round"
          />
        </svg>
        <div className="loader-text">{loadingText}</div>
      </div>
    </div>
  )
}

function keypressHandler(e) {
  if (e.key === 'Tab') e.preventDefault()
}

Loader.propTypes = {
  size: oneOf(['sm', 'md', 'lg']),
  inline: bool,
  ariaLabel: string,
  className: string,
  overlay: bool,
}
