import { useAppState } from '@src/app.state'
import { db, storage } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { toastService } from '@utils/toast.service'
import { addDoc, collection } from 'firebase/firestore'
import {
  StorageError,
  StorageReference,
  UploadTask,
  UploadTaskSnapshot,
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'
import { useState } from 'react'
import { ulid } from 'ulid'

interface UploadOptions {
  user?: { id: string }
  orgId?: string
  uploadToPath?: string
  fileUploadPath?: string
  onUpload?: (uploadedFile: UploadedFileMetadata) => void
  onComplete?: (uploadedFiles: UploadedFileMetadata[]) => void
  mode?: 'w' | 'a' // write or append
  uploadToType?: 'workflow' | 'rich-text'
}

interface UploadedFileMetadata {
  name: string
  type: string
  url: string
  path: string
  uploadedDate: string
  userId: string
  orgId: string
  uploadedToType?: string
  uploadToPath?: string
  fileId?: string
}

interface FileWithUID {
  file: File
  uid: string
  preview?: string
}

export const useUpload = (options: UploadOptions) => {
  const { mode = 'a' } = options
  const [registeredFiles, setRegisteredFiles] = useState<FileWithUID[]>([])
  const [progress, setProgress] = useState<Record<string, number>>({})
  const [uploading, setUploading] = useState(false)

  function registerFiles(filesToRegister: File[]) {
    if (filesToRegister) {
      const readFiles = Array.from(filesToRegister).map(
        (file): Promise<FileWithUID> => {
          return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.onloadend = function (e) {
              const result = e.target?.result
              if (typeof result === 'string') {
                resolve({
                  file,
                  preview: result,
                  uid: ulid(),
                })
              } else {
                reject(new Error('Could not read file'))
              }
            }
            fileReader.onerror = function (e) {
              reject(
                new Error('FileReader error: ' + fileReader.error?.message),
              )
            }
            fileReader.readAsDataURL(file)
          })
        },
      )

      Promise.all(readFiles)
        .then((completedFiles: FileWithUID[]) => {
          if (mode === 'a') {
            setRegisteredFiles(prevState => [...prevState, ...completedFiles])
          } else if (mode === 'w') {
            setRegisteredFiles(completedFiles)
          }
        })
        .catch(error => {
          console.error('Error reading files:', error)
        })
    }
  }

  async function onFileUploadSuccess(
    { file }: FileWithUID,
    fileRef: any,
    options: UploadOptions,
  ): Promise<UploadedFileMetadata> {
    const { orgId, uploadToType, onUpload } = options
    try {
      const metadata = await getMetadata(fileRef)
      const url = await getDownloadURL(fileRef)
      const uploadFileDetails: UploadedFileMetadata = {
        name: file.name,
        type: file.type,
        url,
        path: metadata.fullPath,
        uploadedDate: getTodayDate().toISO(),
        userId: options?.user?.id || useAppState.getState().user?.id,
        orgId: orgId || options?.orgId || null,
        uploadedToType: uploadToType,
        uploadToPath: options?.uploadToPath || '',
      }
      const dbFileRef = await addDoc(
        collection(db, dbNames.files),
        uploadFileDetails,
      )
      uploadFileDetails.fileId = dbFileRef.id
      onUpload?.(uploadFileDetails)
      return uploadFileDetails
    } catch (err) {
      console.error('Error in onFileUploadSuccess:', err)
      toastService.error('Error uploading file. Please try again')
      throw err
    }
  }

  function createUploadTask({ file, uid }: FileWithUID) {
    const fileRef = ref(storage, `files/${uid}/${file.name}`)
    return {
      task: uploadBytesResumable(fileRef, file),
      fileRef,
    }
  }

  async function executeUploadTask({
    task,
    fileRef,
    fileData,
    options,
  }: {
    task: UploadTask
    fileRef: StorageReference
    fileData: FileWithUID
    options: UploadOptions
  }): Promise<UploadedFileMetadata> {
    return new Promise((resolve, reject) => {
      task.on(
        'state_changed',
        (snapshot: UploadTaskSnapshot) => {
          const progressPercentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          setProgress(prevState => ({
            ...prevState,
            [fileData.uid]: progressPercentage,
          }))
        },
        (err: StorageError) => reject(err),
        () => resolve(onFileUploadSuccess(fileData, fileRef, options)),
      )
    })
  }

  async function doUpload(uploadOptions: UploadOptions = {}) {
    setUploading(true)
    setProgress({})
    try {
      const uploadedFiles: UploadedFileMetadata[] = []
      for (const file of registeredFiles) {
        const { task, fileRef } = createUploadTask(file)
        const uploadedFile = await executeUploadTask({
          task,
          fileRef,
          fileData: file,
          options: { ...options, ...uploadOptions },
        })
        uploadedFiles.push(uploadedFile)
      }
      toastService.info(`Uploaded ${uploadedFiles.length} files`)
      options?.onComplete?.(uploadedFiles)
      setUploading(false)
      return uploadedFiles
    } catch (err) {
      setUploading(false)
      toastService.error('Error uploading file(s). Please try again')
      throw err
    }
  }

  function deregisterFiles(fileIds: string[]) {
    const list = registeredFiles.filter(file => !fileIds.includes(file.uid))
    setRegisteredFiles(list)
    return list
  }

  function deregisterAllFiles() {
    setRegisteredFiles([])
  }

  return {
    registerFiles,
    deregisterFiles,
    deregisterAllFiles,
    registeredFiles,
    doUpload,
    uploading,
    progress,
  }
}
