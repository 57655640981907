import { buildRuleMeta, metas } from '@src/pages/workflow/workflow-utils'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'

export function useProcessWorkflowEndDate(template, sections, startDate) {
  const [processedEndDate, setProcessedEndDate] = useState(null)
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  const [ruleMeta, setRuleMeta] = useState(null)
  const endDateIsGenerated = ruleMeta?.meta === metas.RECURRENCE_AND_REPEAT

  useEffect(() => {
    if (template && !isEmpty(template)) {
      const { section, meta, endDate } = buildRuleMeta(sections, startDate)
      if (endDate) {
        setProcessedEndDate(endDate)
      } else {
        setProcessedEndDate(selectedEndDate)
      }
      setRuleMeta({ section, meta })
    }
  }, [template, startDate, selectedEndDate])

  return [processedEndDate, setSelectedEndDate, endDateIsGenerated, ruleMeta]
}
