import { useEffect, useState } from 'react'
import { useIsMounted } from './use-is-mounted.hook'
import { toastService } from '@utils/toast.service'

export const useLoad = (initialData, promise, dependencyList = []) => {
  const [tick, setTick] = useState(0)
  const [firstLoad, setFirstLoad] = useState(false)
  const [data, setData] = useState(initialData)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const isMounted = useIsMounted()
  useEffect(() => {
    promise()
      .then(data => {
        if (isMounted.current) setData(data)
      })
      .catch(e => {
        toastService.error(e.message)
        if (isMounted.current) setError(e)
      })
      .finally(() => {
        if (isMounted.current) setLoading(false)
        if (isMounted.current && !firstLoad) setFirstLoad(true)
      })
  }, [tick, ...dependencyList])
  const reload = () => setTick(tick + 1)
  return [
    data,
    setData,
    {
      error,
      firstLoad,
      loading,
      reload,
    },
  ]
}
