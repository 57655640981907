import React from 'react'
import { object } from 'prop-types'
import { k, useCss } from 'kremling'
import { FieldHeaderContent } from './field-header-content.component'

export function WorkflowDescription({ field, section }) {
  const scope = useCss(css)

  return (
    <div {...scope}>
      <FieldHeaderContent
        field={field}
        section={section}
      />
    </div>
  )
}

WorkflowDescription.propTypes = {
  field: object,
}

const css = k`
  .description-container {
    line-height: 2rem;
  }
  
  .description-container img {
    max-width: 100%;
    border-radius: $base-border-radius;
  }
`
