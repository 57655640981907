import { ActionIcon } from '@components/mantine/action-icon.component'
import { Button } from '@components/mantine/button.component'
import { TextDivider } from '@components/text-divider.component'
import { Tooltip } from '@components/tooltip.component'
import { Well } from '@components/well.component'
import { ChangeConfirmationModal } from '@src/pages/workflow-template-list/template-generator/change-confirmation.modal'
import { ChoiceItemList } from '@src/pages/workflow-template-list/template-generator/choice-item-list.component'
import { modalService } from '@utils/modal.service'
import { isEmpty } from 'lodash'
import React, { useRef } from 'react'

export function ExperienceIdeasState({
  state,
  send,
  updateSelectedItems,
  handleReport,
}) {
  const { selectedItems, lists, rawResponses } = state.context

  const initialSelected = useRef(selectedItems.experienceIdea)
  const selectedChanged =
    initialSelected.current !== selectedItems.experienceIdea

  function onSelectExperienceIdea(idea) {
    const { context } = send({ type: 'experienceIdeaSelected', idea })
    updateSelectedItems('experienceIdea', context.selectedItems.experienceIdea)
  }

  function openChangeDialog(idea) {
    modalService
      .render(ChangeConfirmationModal)
      .then(() => onSelectExperienceIdea(idea))
  }

  const nextListIsEmpty = isEmpty(lists.sections)

  return (
    <Well>
      <Well.Header
        renderActions={() => (
          <Tooltip content="Report an issue">
            <ActionIcon
              size="sm"
              icon="flag"
              onClick={() => {
                handleReport(state.value, rawResponses, lists.experienceIdeas)
              }}
            />
          </Tooltip>
        )}
      >
        Step 2: Experience Ideas
      </Well.Header>
      <Well.Body>
        <div className="row break-1">
          <div className="col-10">
            <div className="explain-text">
              Please select the experience idea that you feel best addresses
              your audience's needs. We will use your selection as the
              foundation to develop a series of activities and interactions that
              will create a comprehensive and engaging experience.
            </div>
          </div>
        </div>
        <TextDivider className="mt-5 mb-5" />

        <ChoiceItemList
          type="radio"
          list={lists.experienceIdeas}
          selectedItems={
            selectedItems.experienceIdea ? [selectedItems.experienceIdea] : []
          }
          onSelect={nextListIsEmpty ? onSelectExperienceIdea : openChangeDialog}
          onAddChoice={choice => {
            send('addExperienceIdea', { choice })
          }}
          onEditChoice={(value, oldValue, index) => {
            send('editExperienceIdea', { value, oldValue, index })
          }}
          addChoiceActionText="Add idea"
          className="mt-5"
        />
        {(lists.experienceIdeas || []).length < 15 && (
          <Button
            loading={state.matches('experienceIdeas.loadingMoreIdeas')}
            variant="secondary"
            className="mt-5"
            onClick={() => {
              send('loadMore')
            }}
          >
            Generate more ideas
          </Button>
        )}

        <TextDivider className="mt-5 mb-5" />
        <div className="stage__actions">
          <Button
            variant="secondary"
            onClick={() => {
              send('back')
            }}
          >
            Back
          </Button>
          <Button
            loading={state.matches('experienceIdeas.loading')}
            disabled={!selectedItems.experienceIdea}
            variant="primary"
            onClick={() => {
              selectedChanged || nextListIsEmpty
                ? send('generate')
                : send('next')
            }}
          >
            Next: Activities
          </Button>
        </div>
      </Well.Body>
    </Well>
  )
}
