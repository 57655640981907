import React from 'react'

import {
  PinInput as MantinePinInput,
  PinInputProps as MantinePinInputProps,
} from '@mantine/core'

type PinInputProps = {} & MantinePinInputProps

export function PinInput({ inputMode = 'text', ...inputProps }: PinInputProps) {
  return (
    <MantinePinInput
      unstyled
      inputMode={inputMode}
      defaultValue=""
      {...inputProps}
    />
  )
}
