import React from 'react'
import { useCss, k, a } from 'kremling'

type LayoutHeader = {
  title: string | JSX.Element
  search?: JSX.Element
  actions?: JSX.Element[] | JSX.Element
  filters?: JSX.Element[]
  className?: string
}

export function LayoutHeader({
  title,
  search,
  actions,
  filters,
  className = '',
}: LayoutHeader) {
  const scope = useCss(css)
  const titleIsString = typeof title === 'string'
  return (
    <div
      {...scope}
      className={a('layout-header').m(className, !!className)}
    >
      {titleIsString ? <h1 className="title">{title}</h1> : <div>{title}</div>}
      <div className="filters">{filters}</div>
      <div className="actions">
        {search && <span className="actions__search">{search}</span>}
        <span className="actions__buttons">{actions}</span>
      </div>
    </div>
  )
}

LayoutHeader.propTypes = {}

const css = k`
  .layout-header {
    padding-bottom: 1.6rem;

    .title {
      width: 100%;
      font-size: 2.4rem;
      font-weight: 500;
      margin-bottom: .8rem;
    }
    
    .title__container {
      font-size: 2.4rem;
      font-weight: 500;
      margin-bottom: .8rem;
      display: flex;
      align-items: center;
    }

    .filters {
      margin-bottom: 1.6rem;
    }

    .actions {
      display: flex;
      align-items: center;

      .actions__buttons {
        flex-shrink: 0;

        > * {
          margin-left: .8rem;
        }
      }

      .actions__search {
        flex-grow: 1;
      }
    }
  }

  @media (min-width: $media4-width) {
    .layout-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        width: auto;
        margin-bottom: 0;
      }

      .filters {
        margin-bottom: 0;
        margin-right: 1.6rem;
      }
    }

    .actions__search {
      max-width: 30rem;
    }
  }
`
