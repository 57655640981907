import * as RadixSwitch from '@radix-ui/react-switch'
import React from 'react'

type SwitchProps = {
  onChange: (data: boolean) => void
  onBlur?: () => void
  value: boolean
  id?: string
  className?: string
}

export function Switch({
  onChange,
  onBlur,
  value,
  id,
  className,
}: SwitchProps) {
  return (
    <RadixSwitch.Root
      onCheckedChange={onChange}
      onBlur={onBlur}
      checked={value}
      id={id}
      className={`data-[state=checked]:bg-primary active:data-[state=checked]:bg-active-150 w-16 rounded-full bg-grey-200 p-px transition focus:outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary active:bg-grey-100 ${className || ''}`}
    >
      <RadixSwitch.Thumb className="data-[state=checked]:translate-x-[18px] block h-8 w-8 rounded-full bg-white shadow-sm transition" />
    </RadixSwitch.Root>
  )
}
