import React, { FC } from 'react'
import { useCss, k, a } from 'kremling'

// Define the type for the TextDivider component's props
interface TextDividerProps {
  children?: React.ReactNode
  className?: string
}

export const TextDivider: FC<TextDividerProps> = ({ children, className }) => {
  const scope = useCss(css)
  return (
    <div
      className={a('or-divider').a(className)}
      {...scope}
    >
      <div className="divider__line" />
      {children && <span className="or-divider__inner">{children}</span>}
      <div className="divider__line" />
    </div>
  )
}

const css = k`
  .or-divider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .divider__line {
    border-bottom: solid 1px $color-grey-75;
    flex: 1;
  }
  
  .or-divider__inner {
    user-select: none;
    line-height: initial;
    font-weight: 500;
    padding: 0 .8rem;
    color: $color-grey-500;
  }
`
