import React from 'react'
import { Navigate, Outlet, useParams, useSearchParams } from 'react-router-dom'

import { userIsGuest } from '@components/select/common/utils'
import { useAppState } from '@src/app.state'

export const ProtectedRoute = ({ blockedRoles, children }) => {
  const user = useAppState(state => state.user)
  const isAuthenticated = useAppState(state => state.isAuthenticated)
  const appLoading = useAppState(state => state.appLoading)
  const { orgId } = useParams()
  const [searchParams] = useSearchParams()
  const hasToken = searchParams.get('token') || searchParams.get('editToken')

  if (!isAuthenticated && !hasToken && !appLoading)
    return <Navigate to="/sign-in" />

  if (!user?.orgRoles) return

  let hasBlockedRole = false
  if (blockedRoles?.length) {
    hasBlockedRole = blockedRoles.includes(user?.orgRoles[orgId])
  }
  if (user?.isAnonymous && !hasToken) return <Navigate to="/sign-in" />
  if (userIsGuest(user)) return children ? children : <Outlet />
  if (!user?.isSuperAdmin && hasBlockedRole)
    return <Navigate to={`/${orgId}`} />

  return children ? children : <Outlet />
}
