import * as RadixCheckbox from '@radix-ui/react-checkbox'
import React, { useState } from 'react'
import { RRule, Weekday } from 'rrule'

const weekdays: { value: Weekday; label: string }[] = [
  { value: RRule.SU, label: 'Sunday' },
  { value: RRule.MO, label: 'Monday' },
  { value: RRule.TU, label: 'Tuesday' },
  { value: RRule.WE, label: 'Wednesday' },
  { value: RRule.TH, label: 'Thursday' },
  { value: RRule.FR, label: 'Friday' },
  { value: RRule.SA, label: 'Saturday' },
]

type Props = {
  days?: Weekday[]
  onChange: (days: Weekday[]) => void
  onBlur?: () => void
}

export function WeekdayCheckboxes({ days = [], onChange, onBlur }: Props) {
  const [selectedDays, setSelectedDays] = useState(days)

  function onDayClick(day: Weekday) {
    const index = selectedDays.indexOf(day)
    if (index === -1) {
      const newDays = [...selectedDays, day]
      setSelectedDays(newDays)
      onChange(newDays)
    } else {
      const newDays = selectedDays.filter(d => d !== day)
      setSelectedDays(newDays)
      onChange(newDays)
    }
  }

  return (
    <fieldset>
      <legend>Repeat on</legend>
      <div className="flex items-center gap-2">
        {weekdays.map(day => {
          const selected = selectedDays.includes(day.value)
          return (
            <div key={`${day.value}`}>
              <label
                className="sr-only"
                htmlFor={`weekday-${day.value}`}
              >
                {day.label}
              </label>
              <RadixCheckbox.Root
                onBlur={onBlur}
                id={`weekday-${day.value}`}
                checked={selected}
                name="weekday-checkboxes"
                onCheckedChange={() => onDayClick(day.value)}
                className={`flex items-center justify-center text-2xl font-semibold w-14 h-14 data-[state=checked]:bg-primary data-[state=checked]:text-white bg-active-75 rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:border-transparent`}
              >
                {day.value.toString().at(0)}
              </RadixCheckbox.Root>
            </div>
          )
        })}
      </div>
    </fieldset>
  )
}
