import { useState, useEffect } from 'react'

type Breakpoint =
  | 'media1Width'
  | 'media2Width'
  | 'media3Width'
  | 'media4Width'
  | 'media5Width'
  | 'media6Width'
  | 'media7Width'
  | 'media8Width'

const breakpoints = {
  media1Width: '540px',
  media2Width: '768px',
  media3Width: '896px',
  media4Width: '1024px',
  media5Width: '1152px',
  media6Width: '1280px',
  media7Width: '1408px',
  media8Width: '1536px',
}

type MediaFeature = 'min-width' | 'max-width'

/**
 * Custom hook that returns whether the given media query matches the current screen size.
 *
 * @returns A boolean indicating if the media query matches.
 * @param breakpoint
 * @param mediaFeature
 */
export function useMediaQuery(
  breakpoint: Breakpoint,
  mediaFeature: MediaFeature = 'min-width',
): boolean {
  const query = `(${mediaFeature}: ${breakpoints[breakpoint]})`
  const [matches, setMatches] = useState<boolean>(
    window.matchMedia(query).matches,
  )

  useEffect(() => {
    const mediaQuery = window.matchMedia(query)

    function handleWidthChange(event: MediaQueryListEvent): void {
      setMatches(event.matches)
    }

    mediaQuery.addEventListener('change', handleWidthChange)
    // Initial check
    handleWidthChange({ matches: mediaQuery.matches } as MediaQueryListEvent)

    // Cleanup
    return () => {
      mediaQuery.removeEventListener('change', handleWidthChange)
    }
  }, [breakpoint])

  return matches
}
