import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import { k, useCss } from 'kremling'
import { isEmpty, sortBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { MentorInvitationCard } from '@components/cards/mentor-invitation-card.component'
import { EmptyState } from '@components/empty-state.component'
import { LayoutHeader } from '@components/layout.component'
import { Loader } from '@components/loader.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Tabs } from '@components/tabs.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
// import { StartWorkflowModal } from '@src/pages/mentoring/start-workflow-modal.component'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
// import { modalService } from '@utils/modal.service'
import { toastService } from '@utils/toast.service'
import { ActivityCard } from './activity-card.component'
import { useActivitiesLoad } from './use-activities-load.hook'

export function Mentoring() {
  const scoped = useCss(css)
  const user = useAppState(state => state.user)
  const orgs = useAppState(state => state.orgs)
  const { orgId } = useParams()
  const selectedOrg = orgs.find(org => org.id === orgId)
  const [search, setSearch] = useState('')
  const [selectedTab, setSelectedTab] = useState('active')
  const canAssignWorkflows = selectedOrg
    ? ['owner', 'manager'].includes(user.orgRoles[orgId])
    : false

  const [orgProfile, , orgProfileOpts] = useLoad(
    {},
    () => {
      const d = doc(db, dbNames.organizationProfiles, `${orgId}_${user.id}`)
      return getDoc(d).then(doc => ({ id: doc.id, ...doc.data() }))
    },
    [orgId, user.id],
  )

  const [menteeUsers, _setMenteeUsers] = useLoad(
    [],
    () => {
      if (canAssignWorkflows) return Promise.resolve([])
      const workflowMentorsQuery = query(
        collection(db, dbNames.activityMentors),
        orderBy('activityEndDate.date'),
        where('userId', '==', user.id),
        where('activityEndDate.date', '>', getTodayDate().toISO()),
      )
      return getDocs(workflowMentorsQuery).then(snap =>
        snap.docs.map(doc => ({ id: doc.id, ...doc.data() })),
      )
    },
    [user.id],
  )

  const [activities, _setActivities, activitiesOpts] = useActivitiesLoad(
    selectedOrg,
    canAssignWorkflows,
    selectedTab,
    menteeUsers,
  )

  const [courses, _setCourses, coursesOpts] = useLoad(
    [],
    async () => {
      if (!user) return Promise.resolve([])
      const q = query(
        collection(db, dbNames.activities),
        where('orgId', '==', orgId),
        where('completedDate', '==', null),
        where('endDate', '==', null),
      )

      return getDocs(q).then(snap =>
        snap.docs.map(doc => ({ ...doc.data(), id: doc.id })),
      )
    },
    [orgId, selectedTab],
  )

  const [mentorInvitations, _setMentorInvitations, mentorInvitationsOpts] =
    useLoad(
      [],
      () => {
        if (isEmpty(selectedOrg)) return new Promise(resolve => resolve([]))
        const q = query(
          collection(db, dbNames.invitations),
          where('recipientId', '==', user.id),
          where('recipientOrgId', '==', selectedOrg.id),
          where('type', '==', 'peerMentor'),
        )
        return getDocs(q).then(q => {
          return q.docs
            .map(d => ({ id: d.id, ...d.data() }))
            .filter(invitation => invitation.status !== 'declined')
        })
      },
      [orgs],
    )

  const renderWorkflows = useMemo(() => {
    if (activitiesOpts.loading || coursesOpts.loading) return <Loader />
    const filteredActivities = sortBy(
      [...activities, ...courses],
      a => new Date(a.startDate.date),
    ).filter(activity => {
      return search
        ? activity.name.toLowerCase().includes(search.toLowerCase())
        : true
    })

    if (filteredActivities.length) {
      return filteredActivities.map(activity => (
        <ActivityCard
          key={activity.id}
          activity={activity}
          orgProfile={orgProfile}
          userIds={menteeUsers.map(({ inviterId }) => inviterId)}
        />
      ))
    } else {
      if (search) {
        return <EmptyState title="No search results" />
      }
      if (selectedTab === 'completed') {
        return (
          <EmptyState
            title="No completed workflows."
            subtitle="None of the workflows you are mentoring have finished."
          />
        )
      } else {
        return canAssignWorkflows ? (
          <EmptyState
            title="No active workflows."
            subtitle="Get started by assigning a new workflow."
            // action={openCreateWorkflowModal}
            // actionText="Start activity"
          />
        ) : (
          <EmptyState
            title="No workflows."
            subtitle="You are currently not assigned to mentor any workflows."
          />
        )
      }
    }
  }, [activities, search, orgProfile, menteeUsers, selectedTab, courses])

  async function handleDecline(invitation) {
    const belongsToOrg = orgs.find(
      org => invitation.inviterOrgId === org.id,
    ) || { id: invitation.recipientId }
    try {
      await updateDoc(doc(db, dbNames.invitations, invitation.id), {
        status: 'declined',
      })

      await updateDoc(
        doc(
          db,
          dbNames.organizationProfiles,
          `${belongsToOrg.id}_${invitation.recipientId}`,
        ),
        {
          mentorWorkflowIds: arrayRemove(invitation.itemId),
        },
      )
      mentorInvitationsOpts.reload()
    } catch (err) {
      console.log(err)
      toastService.error(
        'An error occurred while declining. Please try again later.',
      )
    }
  }

  async function handleAccept(invitation) {
    const belongsToOrg = orgs.find(
      org => invitation.inviterOrgId === org.id,
    ) || { id: invitation.recipientId }
    try {
      const activity = await getDoc(
        doc(db, dbNames.activities, invitation.itemId),
      )
      await setDoc(
        doc(
          db,
          dbNames.activityMentors,
          `${invitation.itemId}_${invitation.recipientId}`,
        ),
        {
          activityId: invitation.itemId,
          activityEndDate: activity.get('endDate'),
          inviterId: invitation.invitationEvents[0].inviterId,
          userId: invitation.recipientId,
          activityOrgId: invitation.inviterOrgId,
          mentorOrgId: belongsToOrg.id,
          deactivated: false,
        },
      )

      await updateDoc(
        doc(
          db,
          dbNames.organizationProfiles,
          `${belongsToOrg.id}_${invitation.recipientId}`,
        ),
        {
          mentorWorkflowIds: arrayUnion(invitation.itemId),
        },
      )

      await deleteDoc(doc(db, dbNames.invitations, invitation.id))
      mentorInvitationsOpts.reload()
    } catch (err) {
      console.log(err)
      toastService.error(
        'An error occurred while accepting. Please try again later.',
      )
    }
  }

  function renderInvitations() {
    return mentorInvitations.map(mentorInvitation => (
      <MentorInvitationCard
        key={mentorInvitation.id}
        invitation={mentorInvitation}
        handleDecline={handleDecline}
        handleAccept={handleAccept}
      />
    ))
  }

  // async function openCreateWorkflowModal() {
  //   try {
  //     await modalService.render(StartWorkflowModal, {
  //       title: 'Assign Workflow',
  //       orgId,
  //     })
  //     activitiesOpts.reload()
  //   } catch {
  //     // do nothing
  //   }
  // }

  const loading =
    activitiesOpts.loading ||
    mentorInvitationsOpts.loading ||
    orgProfileOpts.loading

  return (
    <div
      {...scoped}
      className="iact-container --sm"
    >
      <LayoutHeader
        className="pt-8"
        title={
          <Tabs
            value={selectedTab}
            onChange={setSelectedTab}
          >
            <Tabs.Button id="active">Mentoring</Tabs.Button>
            <Tabs.Button id="completed">Completed</Tabs.Button>
          </Tabs>
        }
        search={
          <TextInput
            defaultValue={search}
            onChange={setSearch}
            placeholder="Search workflows..."
            icon="search"
            allowClear
          />
        }
        // actions={
        //   canAssignWorkflows && (
        //     <Button
        //       variant="secondary"
        //       onClick={openCreateWorkflowModal}
        //     >
        //       Start activity
        //     </Button>
        //   )
        // }
      />
      {loading ? (
        <div className="pt-8">
          <Loader />
        </div>
      ) : (
        <>
          {!isEmpty(mentorInvitations) && (
            <div className="invitations-container">
              <div className="invitations-title">Mentoring invitations</div>
              {renderInvitations()}
            </div>
          )}
          <div className="workflows-list">{renderWorkflows}</div>
        </>
      )}
    </div>
  )
}

const css = k`
  .mentoring-tabs {
    margin-top: 32px;
    display: flex;
    justify-content: center;
  }

  .invitations-container {
    padding: 2rem;
    background: $color-grey-75;
    border-radius: $base-border-radius;
    margin-bottom: 2rem;

    .invitations-title {
      font-size: 2rem;
      font-weight: 400;
      margin-bottom: .8rem;
    }
  }
`
