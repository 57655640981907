import React, { forwardRef, useMemo } from 'react'
import { Control, UseControllerProps, useController } from 'react-hook-form'

import { TextInput } from '@components/mantine/text-input.component'
import { TextInputProps } from '@mantine/core'
import { mergeRefs } from '@utils/merge-refs'
import { validateEmail } from '@utils/validate-email'

type InputFieldProps = TextInputProps &
  Pick<UseControllerProps, 'rules'> & {
    control: Control<any>
    fieldName: string
    required?: boolean
    passwordLength?: number
  }

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  function InputField(props, ref) {
    const {
      fieldName,
      control,
      required,
      rules = {},
      type,
      minLength,
      ...restOfProps
    } = props

    const mergedRules = useMemo(() => {
      const mRules: InputFieldProps['rules'] = {}
      if (required) {
        mRules.required = 'Field is required'
      }
      if (type === 'password' && minLength) {
        mRules.validate = v =>
          v.length >= minLength ||
          `Password must have at least ${minLength} characters`
      }
      if (type === 'email') {
        mRules.validate = v => validateEmail(v) || 'Email must be valid'
      }
      return { ...mRules, ...rules }
    }, [JSON.stringify(rules), required, type, minLength])

    const {
      field: { value, onChange, onBlur, ref: fieldRef },
      fieldState: { error },
    } = useController({ name: fieldName, control, rules: mergedRules })

    return (
      <TextInput
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error?.message}
        ref={mergeRefs(ref, fieldRef)}
        type={type}
        {...restOfProps}
      />
    )
  },
)
