import {
  Checkbox as MantineCheckbox,
  CheckboxProps as MantineCheckboxProps,
} from '@mantine/core'
import React, { forwardRef } from 'react'

type CheckboxProps = {} & MantineCheckboxProps

type CheckboxType = React.ForwardRefExoticComponent<
  React.PropsWithoutRef<MantineCheckboxProps> &
  React.RefAttributes<HTMLInputElement>
> & {
  Group?: typeof MantineCheckbox.Group
}

export const Checkbox: CheckboxType = forwardRef<
  HTMLInputElement,
  MantineCheckboxProps
>(({ ...checkboxProps }: CheckboxProps, ref) => {
  return (
    <div>
      <MantineCheckbox
        ref={ref}
        icon={EmptyIcon}
        styles={{
          input: {
            borderWidth: 2,
          },
        }}
        size="md"
        {...checkboxProps}
      />
    </div>
  )
})

const EmptyIcon = (): null => null

Checkbox.Group = MantineCheckbox.Group
