import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { AnimatePresence, motion, useAnimationControls } from 'framer-motion'
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

type DropdownProps = {
  children: ReactNode
  open?: boolean
  setOpen?: Dispatch<SetStateAction<boolean>>
}

type TriggerProps = {
  children: ReactNode
  className?: string
  onBlur?: () => void
}

type ContentProps = {
  children: ReactNode
  align?: 'start' | 'end' | 'center'
  container?: HTMLElement
}

type ListItemProps = {
  children: ReactNode
  onSelect?: (value: string) => void
  className?: string
  disabled?: boolean
  value?: string
}

type DropdownContext = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

type DropdownContentContext = {
  close: () => void
}

const DropdownContext = createContext<DropdownContext>({
  open: false,
  setOpen: () => {},
})

export function Dropdown({ children, setOpen, open }: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <DropdownContext.Provider
      value={{ open: open || isOpen, setOpen: setOpen || setIsOpen }}
    >
      <DropdownMenu.Root
        open={open || isOpen}
        onOpenChange={setOpen || setIsOpen}
      >
        {children}
      </DropdownMenu.Root>
    </DropdownContext.Provider>
  )
}

function Trigger({ children, className, onBlur }: TriggerProps) {
  return (
    <DropdownMenu.Trigger
      className={className}
      onBlur={onBlur}
    >
      {children}
    </DropdownMenu.Trigger>
  )
}

const DropdownContentContext = createContext<DropdownContentContext>({
  close: () => {},
})

function Content({ children, align, container }: ContentProps) {
  const { open, setOpen } = useContext(DropdownContext)
  const controls = useAnimationControls()

  useEffect(() => {
    if (open) {
      controls.start('open')
    }
  }, [controls, open])

  async function close() {
    await controls.start('closed')
    setOpen(false)
  }

  return (
    <DropdownContentContext.Provider value={{ close }}>
      <AnimatePresence>
        {open && (
          <DropdownMenu.Portal
            forceMount
            container={container}
          >
            <DropdownMenu.Content
              asChild
              align={align}
              className="z-[10000]"
            >
              <motion.div
                initial="closed"
                animate={controls}
                exit="closed"
                variants={{
                  open: {
                    opacity: 1,
                    transition: { ease: 'easeOut', duration: 0.1 },
                  },
                  closed: {
                    opacity: 0,
                    transition: { ease: 'easeIn', duration: 0.1 },
                  },
                }}
              >
                {children}
              </motion.div>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        )}
      </AnimatePresence>
    </DropdownContentContext.Provider>
  )
}

function ListItem({
  children,
  className,
  onSelect,
  disabled,
  value = '',
}: ListItemProps) {
  const { close } = useContext(DropdownContentContext)
  return (
    <DropdownMenu.Item
      asChild
      disabled={disabled}
      className={className}
      onSelect={() => {
        onSelect?.(value)
        close()
      }}
    >
      {children}
    </DropdownMenu.Item>
  )
}

function Separator() {
  return <DropdownMenu.Separator className="border-b border-grey-100" />
}

Dropdown.Trigger = Trigger
Dropdown.Content = Content
Dropdown.ListItem = ListItem
Dropdown.Separator = Separator
