import { InputField } from '@components/input'
import { Button } from '@components/mantine/button.component'
import { Modal } from '@components/modal.component'
import { RichText } from '@components/rich-text/rich-text.component'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { FIELD_CONSTANTS } from '@src/pages/workflow/fields.helper'
import { YouTubeEmbed } from '@src/pages/workflow/template/fields/youtube-embed.component'
import { dbNames } from '@utils/constants'
import { doc, updateDoc } from 'firebase/firestore'
import { cloneDeep } from 'lodash'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

type EditFieldModalProps = {
  onResolve: () => void
  onReject: () => void
  field: Field
  section: ActivitySection
  orgId: string
}

export function EditFieldModal({
  onResolve,
  onReject,
  field,
  section,
  orgId,
}: EditFieldModalProps) {
  const user = useAppState(state => state.user)
  const [description, setDescription] = useState(
    field.description || field.value || '',
  )
  const [fieldLink, setFieldLink] = useState(field.link || '')

  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      label: field.label,
    },
  })

  async function onSave({ label }: { label: string }) {
    const d = doc(db, dbNames.activitySections, section.id)
    const _fields = cloneDeep(section.fields)
    const fieldIndex = _fields.findIndex(f => f.id === field.id)

    let newField
    if (field.type === FIELD_CONSTANTS.YOUTUBE) {
      newField = {
        ...field,
        label,
        link: fieldLink,
        description,
      }
    } else if (field.type === FIELD_CONSTANTS.DESCRIPTION) {
      newField = {
        ...field,
        label,
        value: description,
      }
    } else {
      newField = {
        ...field,
        label,
        description,
      }
    }

    _fields.splice(fieldIndex, 1, newField)

    await updateDoc(d, {
      fields: _fields,
    })
    onResolve()
  }

  function isValid() {
    if (!formState.isValid) {
      return false
    }

    if (field.type === FIELD_CONSTANTS.YOUTUBE) {
      return fieldLink && fieldLink.length > 0
    }

    return true
  }

  return (
    <>
      <Modal.Header>Edit field</Modal.Header>
      <form onSubmit={handleSubmit(onSave)}>
        <Modal.Body>
          <div className="row break-1">
            <div className="col-12">
              <div className="form-group">
                <InputField
                  fieldName="label"
                  control={control}
                  required
                  autoFocus
                />
              </div>
            </div>
          </div>
          <div className="row break-1">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="field-desc">Describe this field</label>
                <RichText
                  id="field-desc"
                  onChange={setDescription}
                  initialValue={description}
                  placeholder="Type here..."
                  uploadOptions={{
                    user: user as User,
                    orgId,
                  }}
                />
              </div>
            </div>
          </div>
          {field.type === FIELD_CONSTANTS.YOUTUBE && (
            <div className="row break-1">
              <div className="col-12">
                <YouTubeEmbed
                  fieldLink={field.link}
                  onChange={setFieldLink}
                />
              </div>
            </div>
          )}
          <div className="p-2">
            Note: These changes will only apply to the current activity and will
            not be applied to the template. All participants of this activity
            will see these changes.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onReject}>Cancel</Button>
          <Button
            disabled={!isValid()}
            variant="primary"
            type="submit"
          >
            Save changes
          </Button>
        </Modal.Footer>
      </form>
    </>
  )
}
