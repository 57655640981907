import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { Radio } from '@components/mantine/radio.component'
import { Radio as MantineRadio } from '@mantine/core'
import { FieldHeaderContent } from '@src/pages/workflow/client/fields/field-header-content.component'
import { AssistedResponseModal } from '@src/pages/workflow/client/workflow-refactor/assisted-response-modal.component'
import { modalService } from '@utils/modal.service'
import { k, useCss } from 'kremling'
import { bool, func, object, string } from 'prop-types'
import React from 'react'
import { useController } from 'react-hook-form'

export function WorkflowRadio({
  field,
  readOnly,
  isRequired,
  section,
  control,
}) {
  const scope = useCss(css)

  const {
    field: { onChange, value, ...fieldProps },
    fieldState: { error },
  } = useController({
    name: `${field.version}_${field.id}`,
    disabled: readOnly,
    control,
    rules: {
      required: isRequired && 'This field is required',
    },
  })

  function createMarkup() {
    return { __html: field.description }
  }

  if (!field.choices.filter(({ label }) => label).length) {
    return (
      <div
        {...scope}
        className="radio-container"
      >
        <div className="field-label">
          {field.label}
          {isRequired && <span className="aria-invisible"> Required</span>}
        </div>
        <div
          className="field-description"
          dangerouslySetInnerHTML={createMarkup()}
        />
        <div className="field-warning">
          <Icon
            name="circle-exclamation"
            className="mr-2"
          />{' '}
          No Radio choices have been added. This field and message will not
          display to users.
        </div>
      </div>
    )
  }

  return (
    <div
      {...scope}
      className="radio-container"
    >
      <FieldHeaderContent
        field={field}
        isRequired={isRequired}
        section={section}
        actions={
          <>
            <ActionIcon
              size="sm"
              onClick={() => {
                modalService
                  .render(AssistedResponseModal, { field, section })
                  .then(({ response }) => {
                    const choice = field.choices.find(
                      ({ label }) => label === response,
                    )
                    onChange(choice.id)
                  })
              }}
              icon="wand-magic-sparkles"
            />
          </>
        }
      />
      <MantineRadio.Group
        name={field.id}
        onChange={onChange}
        value={value}
        error={error?.message}
      >
        {field.choices.map(choice => (
          <Radio
            key={choice.id}
            value={choice.id}
            className="my-1"
            label={choice.label}
            {...fieldProps}
          />
        ))}
      </MantineRadio.Group>
    </div>
  )
}

WorkflowRadio.propTypes = {
  field: object,
  onChange: func,
  fieldVal: string,
  readOnly: bool,
}

const css = k`
  .radio-container {
    display: flex;
    flex-direction: column;
  }

  .field-warning {
    color: $color-danger;
    display: flex;
    align-items: center;
  }
`
