import { cloneDeep } from 'lodash'
import { useState } from 'react'
import { shareSettings } from './share-utils'

function setSelectedFromFlatList(
  values: string[],
  settings: ShareSetting[],
): ShareSetting[] {
  return settings.map((setting: ShareSetting) => {
    setting.options = setting.options.map((option: ShareOption) => {
      option.selected = values.includes(option.value)
      if (option.settingGroups) {
        option.settingGroups = setSelectedFromFlatList(
          values,
          option.settingGroups,
        )
      }
      return option
    })
    return setting
  })
}

function getFlatListFromSelected(settings: ShareSetting[]): string[] {
  let values: string[] = []
  settings.forEach((setting: ShareSetting) => {
    setting.options.forEach((option: ShareOption) => {
      if (option.selected) {
        values.push(option.value)
      }
      if (option.settingGroups) {
        values = values.concat(getFlatListFromSelected(option.settingGroups))
      }
    })
  })
  return values
}

export function useShareSettings(
  defaultValues: string[],
  onChange: (flatSettings: string[]) => void,
) {
  const [settings, setSettings] = useState(
    setSelectedFromFlatList(defaultValues, shareSettings),
  )

  function updateSelectedOption(path: string[], selectedValue: boolean) {
    const newSettings = cloneDeep(settings)

    const update = (settingsCopy: ShareSetting[], pathIdx: number) => {
      if (pathIdx >= path.length) {
        return
      }

      for (const setting of settingsCopy) {
        if (setting.options) {
          for (const option of setting.options) {
            if (option.value === path[pathIdx]) {
              if (pathIdx === path.length - 1) {
                if (setting.type === 'radio') {
                  // If the setting type is 'radio', set all other options to false
                  setting.options.forEach(opt => (opt.selected = false))
                }
                option.selected = selectedValue
                break
              } else if (option.settingGroups) {
                update(option.settingGroups, pathIdx + 1)
              }
            }
          }
        }
      }
    }

    update(newSettings, 0)
    setSettings(newSettings)
    onChange(getFlatListFromSelected(newSettings))
  }

  return {
    settings,
    updateSelectedOption,
  }
}
