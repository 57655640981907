export function parseArray(response = '') {
  const noTrailingCommas = removeTrailingCommas(response)
  const start = noTrailingCommas.indexOf('[')
  const end = noTrailingCommas.lastIndexOf(']')
  const actionsString = response.slice(start, end + 1)
  return JSON.parse(actionsString)
}

export function removeArrayFromString(string) {
  const start = string.indexOf('[')
  const end = string.lastIndexOf(']')
  const arr = string.slice(start, end + 1)
  const noArray = string.replace(arr, '')
  return noArray.replaceAll('\n', '')
}

function removeTrailingCommas(str) {
  const regex = /,\s*([\]}])/gm
  return str.replace(regex, '$1')
}

export function listToArray(list) {
  // Use a regular expression to match the numbered list
  const listRegex = /(\d+\.\s*[^0-9\n]+)+/g
  let listMatch = list.match(listRegex)

  if (!listMatch) {
    return []
  }

  // Join the matched list items into a single string
  const listStr = listMatch.join(' ')

  // Use a regular expression to match the numbered list items
  const itemRegex = /\d+\.\s*([^0-9\n]+)/g
  let itemMatch
  const items = []

  // Iterate over the matches and extract the list items without the numbers
  while ((itemMatch = itemRegex.exec(listStr)) !== null) {
    const newItem = itemMatch[1].replace('\n', '')
    items.push(newItem)
  }

  return items
}

export function parseNumberedList(inputString) {
  const regex = /\d+\.\s*(.*?)(?=\s*\d+\.|\s*$)/g
  const listItems = []

  for (const match of inputString.matchAll(regex)) {
    listItems.push(match[1].trim())
  }

  return listItems
}

export function arrayToBulletedList(arr) {
  return arr.map(item => `- ${item}`).join('\n')
}

export function generateNumberedListFromArray(array) {
  return array
    .map((item, index) => {
      return `${index + 1}. ${item}`
    })
    .join('\n')
}
