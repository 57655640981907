import React from 'react'

import { Button } from '@components/mantine/button.component'

export function Footer({ close }) {
  return (
    <Button
      variant="secondary"
      onClick={close}
    >
      Done
    </Button>
  )
}

Footer.propTypes = {}
