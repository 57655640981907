import Typesense from 'typesense'

export const typesenseClient = new Typesense.Client({
  nodes: [
    {
      host: process.env.TYPESENSE_CLUSTER_ROUTE,
      port: '443',
      protocol: 'https',
    },
  ],
  apiKey: process.env.TYPESENSE_SEARCH_KEY,
  connectionTimeoutSeconds: 2,
})

const isEmulator = process.env.NODE_ENV !== 'production'
const isStage = process.env.ENVIRONMENT === 'staging'

//This list is a copy of a list in functions/config.js
const typesenseNames = {
  //[firebaseCollection]: [typesenseCollection],
  users: getTypeSenseCollectionName('users'),
  marketplaceTemplates: getTypeSenseCollectionName('marketplaceTemplates'),
  tags: getTypeSenseCollectionName('tags'),
}

//This is a copy of a function in functions/config.js
export function getTypeSenseCollectionName(name) {
  if (isEmulator) {
    return `${name}-emulator`
  } else if (isStage) {
    return `${name}-stage`
  }
  return name
}

export const searchTypesense = (collectionName, searchParameters) => {
  const typesenseCollection = typesenseNames[collectionName]
  return typesenseClient
    .collections(typesenseCollection)
    .documents()
    .search(searchParameters)
}

export const multiSearchTypesense = (searchRequests, commonSearchParams) => {
  return typesenseClient.multiSearch.perform(searchRequests, commonSearchParams)
}
