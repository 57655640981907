import { httpsCallable } from 'firebase/functions'
import { k, useCss } from 'kremling'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { DatePicker } from '@components/date-picker.component'
import { Loader } from '@components/loader.component'
import { Button } from '@components/mantine/button.component'
import { Checkbox } from '@components/mantine/checkbox.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Modal } from '@components/modal.component'
import { Required } from '@components/required.component'
import { RichText } from '@components/rich-text/rich-text.component'
import { useAppState } from '@src/app.state'
import { functions } from '@src/firebase-app'
import { getTodayDate } from '@utils/helpers'
import { toastService } from '@utils/toast.service'
import { DateTime } from 'luxon'

export function StartCourseModal({
  onResolve,
  onReject,
  orgId,
  template,
  sections,
  addCreator = false,
}) {
  const user = useAppState(state => state.user)
  const [saving, setSaving] = useState(false)
  const [selectedName, setSelectedName] = useState(template?.name || '')
  const [selectedStartDate, setSelectedStartDate] = useState(
    DateTime.now().startOf('day'),
  )
  const [autoAddCreator, setAutoAddCreator] = useState(addCreator)
  const [description, setDescription] = useState('')
  const [funnelId, setFunnelId] = useState('')
  const scope = useCss(css)

  const templateHasEmptySection = useMemo(() => {
    return sections.some(section => {
      return !section.fields.length
    })
  }, [template])

  async function build() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const startDate = selectedStartDate.toISO({ includeOffset: false })

    const data = {
      startDate: {
        date: startDate,
        timeZone,
      },
      endDate: null,
      userTimeZone: timeZone,
      selectedTemplate: { ...template, sections },
      ruleMeta: { meta: 'NO_RULES' },
      selectedName,
      orgId,
      description,
      today: getTodayDate().toISO(),
      funnelId,
    }
    setSaving(true)

    try {
      const userIds = autoAddCreator ? [user.id] : []
      return await httpsCallable(
        functions,
        'activityFunctions-createActivity',
      )({
        ...data,
        userIds,
      })
    } catch (err) {
      toastService.error('There was a problem assigning the activity')
      setSaving(false)
    }
  }

  async function buildAndClose() {
    await build()
    onResolve()
  }

  // async function buildAndRedirect() {
  //   const { data: activityId } = await build()
  //   onResolve()
  //   navigate(`/${orgId}/mentoring/${activityId}/settings/sharing`)
  // }

  function disableSave() {
    return !selectedStartDate || saving
  }

  return (
    <>
      <Modal.Header>Create course</Modal.Header>
      <Modal.Body {...scope}>
        <div className="row break-1">
          <div className="col-12">
            <div className="form-group">
              <label>
                Activity name <Required />
              </label>
              <TextInput
                value={selectedName}
                onChange={setSelectedName}
                disabled={!template}
                placeholder="Workflow name"
              />
            </div>
          </div>
        </div>
        <div className="row break-1">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="activity-description">Description</label>
              <RichText
                onChange={setDescription}
                initialValue={description}
                id="activity-description"
                placeholder="Type here..."
              />
            </div>
          </div>
        </div>
        {template && (
          <div className="row break-1">
            <div className="col-6">
              <div className="form-group">
                <label>
                  Start date <Required />
                </label>
                <DatePicker
                  placeholder="Select date"
                  onChange={setSelectedStartDate}
                  value={selectedStartDate}
                  fixedContent
                  triggerIsBlock
                />
              </div>
            </div>
          </div>
        )}
        <div className="row break-1">
          <div className="col-6">
            <div className="form-group">
              <label>Funnel ID</label>
              <TextInput
                value={funnelId}
                onChange={setFunnelId}
                placeholder="Type here..."
              />
            </div>
          </div>
        </div>
        <div className="row break-1">
          <div className="col-6">
            <div className="form-group">
              <div className="creator-checkbox">
                <Checkbox
                  checked={autoAddCreator}
                  onChange={e => setAutoAddCreator(e.target.checked)}
                  label="Add me as a participant"
                />
              </div>
            </div>
          </div>
        </div>
        {templateHasEmptySection && (
          <div className="form-group">
            <strong>Warning:</strong> This template contains at least one empty
            section. Empty sections will be marked as complete.{' '}
            <Link
              onClick={() => onReject()}
              to={`templates/${template.id}`}
            >
              Go to template
            </Link>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer {...scope}>
        <Button
          variant="tertiary"
          onClick={onReject}
        >
          Cancel
        </Button>
        {/*<Button*/}
        {/*  variant="primary"*/}
        {/*  onClick={buildAndRedirect}*/}
        {/*  disabled={disableSave()}*/}
        {/*>*/}
        {/*  Create & invite*/}
        {/*</Button>*/}
        <Button
          variant="primary"
          onClick={buildAndClose}
          disabled={disableSave()}
        >
          Create
        </Button>
      </Modal.Footer>
      {saving && (
        <Modal.Overlay>
          <Loader />
          <div>Creating activity...</div>
        </Modal.Overlay>
      )}
    </>
  )
}

const css = k`
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#fff, .9);
    border-radius: $base-border-radius;
    flex-direction: column;
  }

  .loading-progress {
    width: 20rem;
    padding: 1.6rem;
  }

  .creator-checkbox {
    display: flex;
    align-items: center;
  }
`
