import {
  array,
  bool,
  func,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types'
import React, { useMemo } from 'react'

import { dropdownPropTypes } from '@components/dropdown.component'
import { Footer } from './common/footer.component'
import { Group } from './common/group.component'
import { buildData, buildGroupData, filterAlpha } from './common/utils'
import { ItemMulti } from './items/item-multi.component'
import { ItemSingle } from './items/item-single.component'
import { SelectInner } from './select-inner.component'
import { TriggerMultiInput } from './triggers/trigger-multi-input.component'
import { TriggerMulti } from './triggers/trigger-multi.component'
import { TriggerPills } from './triggers/trigger-pills.component'
import { TriggerSingle } from './triggers/trigger-single.component'

export const Select = props => {
  const {
    data: _data,
    isGroupData,
    searchFilter,
    searchValue,
    transformData,
    value: rawValue,
    ...rest
  } = props
  const isSearch = !!(searchFilter && searchValue)
  const { isMulti, valueTransformed } = useMemo(() => {
    const isMulti = Array.isArray(rawValue)
    return {
      isMulti,
      valueTransformed: isMulti
        ? transformData
          ? rawValue.map(transformData)
          : rawValue
        : rawValue && (transformData ? transformData(rawValue) : rawValue),
    }
  }, [rawValue])

  const { data, dataMap } = useMemo(() => {
    return isGroupData
      ? buildGroupData(_data, transformData, searchFilter)
      : buildData(_data, transformData, searchFilter)
  }, [_data, searchValue, transformData, searchFilter])

  return (
    <SelectInner
      data={data}
      dataMap={dataMap}
      isGroupData={!!isGroupData}
      isMulti={isMulti}
      isSearch={isSearch}
      searchFilter={searchFilter}
      searchValue={searchValue}
      transformData={transformData}
      value={rawValue}
      valueTransformed={valueTransformed}
      {...rest}
    />
  )
}

Select.propTypes = {
  // Dropdown props
  allowContentClicks: dropdownPropTypes.allowContentClicks,
  contentHeight: dropdownPropTypes.contentHeight,
  contentWidth: dropdownPropTypes.contentWidth,
  cover: dropdownPropTypes.cover,
  fixedContent: dropdownPropTypes.fixedContent,
  position: dropdownPropTypes.position,
  triggerIsBlock: dropdownPropTypes.triggerIsBlock,
  defaultOpenState: dropdownPropTypes.defaultOpenState,

  // Select props
  className: oneOfType([object, string]),
  data: array,
  disabled: bool,
  insertSearch: bool,
  isGroupData: bool,
  loading: bool,
  onChange: func,
  onClose: func,
  onOpen: func,
  placeholder: string,
  renderFooter: func,
  renderGroup: func,
  renderItem: func,
  renderTrigger: func,
  searchFilter: oneOfType([func, bool]),
  searchOnChange: func,
  searchValue: string,
  showResultsInContent: bool,
  style: object,
  transformData: func,
  value: oneOfType([array, object, string, number]),
}

Select.defaultProps = {
  allowContentClicks: true,
  contentWidth: 'lg',
  position: 'bottom',
  renderGroup: Group,
}

// triggers
Select.TriggerMulti = TriggerMulti
Select.TriggerMultiInput = TriggerMultiInput
Select.TriggerSingle = TriggerSingle
Select.TriggerPills = TriggerPills

// items
Select.ItemMulti = ItemMulti
Select.ItemSingle = ItemSingle

// group
Select.Group = Group

// footer
Select.Footer = Footer

// utils
Select.filterAlpha = filterAlpha
