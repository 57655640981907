import { k, useCss } from 'kremling'
import { orderBy } from 'lodash'
import React, { createContext, useContext, useMemo } from 'react'

import { Dropdown } from '@components/dropdown.component'
import { EmptyState } from '@components/empty-state.component'
import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { Pill } from '@components/pill/pill.component'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { sumValues } from '@src/pages/workflow/settings/progress.helpers'

const LeaderboardContext = createContext({})

const defaultProcessMethods = {
  average({ values }) {
    const value = (sumValues(values) / values.length).toFixed(2)
    return { value }
  },
  sum({ values }) {
    const value = sumValues(values)
    return { value }
  },
  maxValue({ values }) {
    const value = Math.max(...values.map(({ value }) => value))
    return { value }
  },
  minValue({ values }) {
    const value = Math.min(...values.map(({ value }) => value))
    return { value }
  },
  latestValue({ values }) {
    return values.at(-1)
  },
  iterationsCompleted: ({ iterationscompletedcount }) => {
    const value = Number(iterationscompletedcount)
    return { value }
  },
  percentChange({ values }) {
    const first = Number(values.at(0).value)
    const last = Number(values.at(-1).value)
    const value = ((first - last) / first) * 100
    return { value: Math.abs(value), unit: '%' }
  },
}

export function WorkflowLeaderboard({
  children,
  workflowChallenge,
  processValue = response => ({ value: Number(response.value) }),
  responses,
  processMethods,
  displayGoal = goal => goal,
  displayValue = value => value.value,
  selectedUnit,
}) {
  const scoped = useCss(css)
  const goal =
    workflowChallenge.method === 'percentChange'
      ? `${workflowChallenge.goal}%`
      : displayGoal(workflowChallenge.goal)

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: workflowChallenge.id,
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    position: 'relative',
    zIndex: isDragging ? 1 : undefined,
  }

  function formatResponses() {
    const { method, sortOrder } = workflowChallenge
    const calculatedResponses = responses
      .map(response => {
        const methods = processMethods
          ? processMethods(defaultProcessMethods)
          : defaultProcessMethods
        let calculated = methods[method](response)
        calculated = processValue
          ? processValue(calculated || response.calculated)
          : calculated
        return { ...response, calculated }
      })
      .filter(({ calculated }) => {
        const { value } = processValue({
          value: workflowChallenge.goal,
          unit: workflowChallenge.goalUnit,
        })
        if (sortOrder === 'asc') {
          return calculated.value <= value
        }
        return calculated.value >= value
      })

    return orderBy(
      calculatedResponses,
      calculatedResponse => calculatedResponse.calculated,
      sortOrder,
    )
  }

  const formattedResponses = useMemo(formatResponses, [
    responses,
    workflowChallenge,
    selectedUnit,
  ])

  return (
    <LeaderboardContext.Provider
      value={{
        displayValue,
        workflowChallenge,
        responses,
        formattedResponses,
        goal,
      }}
    >
      <div
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        style={style}
        {...scoped}
        className="workflow-challenge__item"
      >
        {children}
      </div>
    </LeaderboardContext.Provider>
  )
}

function Header({ renderPills, onEdit, onDelete, canEdit, participantsCount }) {
  const { workflowChallenge, goal, formattedResponses } =
    useContext(LeaderboardContext)

  return (
    <div className="challenge__header">
      <Icon
        name="grip-dots-vertical"
        className="rotate-90 absolute left-1/2 -translate-x-1/2 top-0"
      />
      <div className="challenge-header__top">
        <div className="challenge-title">
          <Icon
            name="flag-checkered"
            className="mr-2"
          />
          {workflowChallenge.title}
        </div>
        {canEdit && (
          <div className="challenge-item__actions">
            <Dropdown
              fixedContent
              position="bottom-left"
              renderTrigger={({ toggle }) => (
                <ActionIcon
                  variant="tertiary"
                  icon="ellipsis-v"
                  onClick={toggle}
                  size="sm"
                />
              )}
              renderContent={({ close }) => (
                <div className="select-list">
                  <button
                    onClick={() => {
                      onEdit(workflowChallenge)
                      close()
                    }}
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      onDelete(workflowChallenge.id)
                      close()
                    }}
                  >
                    Delete
                  </button>
                </div>
              )}
            />
          </div>
        )}
      </div>
      <div className="challenge-pills">
        <Pill
          split
          title={
            <Icon
              name="flag-checkered"
              size={12}
            />
          }
          value={goal}
          pillType="primary"
        />
        {renderPills && (
          <div className="extra-pills">{renderPills({ goal })}</div>
        )}
        <Pill className="ml-1">
          {formattedResponses.length} of {participantsCount} users
        </Pill>
        {workflowChallenge.method === 'average' && (
          <Pill className="ml-1">Average</Pill>
        )}
        {workflowChallenge.method === 'sum' && (
          <Pill className="ml-1">Total</Pill>
        )}
      </div>
    </div>
  )
}

function Body() {
  const { formattedResponses, displayValue, workflowChallenge } =
    useContext(LeaderboardContext)

  return (
    <div className="challenge__body">
      {formattedResponses.length > 0 ? (
        formattedResponses.map(
          ({ user_id, first_name, last_name, calculated }) => {
            return (
              <div
                className="challenge-user"
                key={user_id}
              >
                <div className="user-name">
                  {first_name} {last_name}
                </div>
                <Pill pillType="primary">
                  {workflowChallenge.method === 'percentChange'
                    ? `${calculated.value.toFixed(2)}%`
                    : displayValue(calculated)}
                </Pill>
              </div>
            )
          },
        )
      ) : (
        <EmptyState
          title="No users have met this goal yet."
          height={10}
        />
      )}
    </div>
  )
}

WorkflowLeaderboard.Header = Header
WorkflowLeaderboard.Body = Body

const css = k`
  .workflow-challenge__item {
    margin-bottom: 16px;
    border: solid 1px $color-grey-100;
    border-radius: $base-border-radius;
    display: flex;
    flex-direction: column;
    max-height: 300px;
  }

  .challenge__header {
    position: relative;
    flex-shrink: 0;
    padding: 12px;
    background: var(--color-active-50);
  }

  .challenge__body {
    background: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .challenge-header__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  .extra-pills {
    margin-left: 4px;
    display: flex-inline;
    align-items: center;
  }

  .distance-goal {
    display: flex;
  }

  .challenge-item__actions {
    display: flex;
  }

  .challenge-title {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.8rem;
  }

  .challenge-pills {
    display: flex;
    align-items: center;
  }

  .challenge__body {
    overflow-y: auto;
    border-top: solid 1px $color-grey-100;
  }

  .challenge-user {
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .challenge-user:not(:last-child) {
    border-bottom: solid 1px $color-grey-100;
  }

  .challenge-user .user-name {
    font-weight: 500;
  }
`
