import { doc, increment, setDoc, updateDoc } from 'firebase/firestore'
import React, { useState } from 'react'

import { Button } from '@components/mantine/button.component'
import { Modal } from '@components/modal.component'
import { Required } from '@components/required.component'
import { SelectSingle } from '@components/select-single.component'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { toastService } from '@utils/toast.service'

export function AdoptTemplateModal({ onReject, onResolve, template }) {
  const [importing, setImporting] = useState(false)
  const user = useAppState(state => state.user)
  const orgs = useAppState(state => state.orgs)
  const adminOrgs = orgs.filter(({ id }) =>
    ['owner', 'manager', 'editor'].includes(user.orgRoles[id]),
  )
  const [selectedOrg, setSelectedOrg] = useState(adminOrgs[0])

  async function handleImport() {
    setImporting(true)
    const today = getTodayDate().toISO()
    const importTemplate = {
      name: template.name,
      description: template.description,
      orgId: selectedOrg.id,
      marketplaceTemplateId: template.id,
      createdDate: today,
      authorOrgId: template.orgId,
    }

    try {
      await setDoc(
        doc(
          db,
          dbNames.adoptedMarketplaceTemplates,
          `${selectedOrg.id}_${template.id}`,
        ),
        importTemplate,
      )
      await updateDoc(doc(db, dbNames.marketplaceTemplates, template.id), {
        adoptionsCount: increment(1),
      })
      toastService.info(`Template imported to ${selectedOrg.name} successfully`)
      onResolve()
    } catch (err) {
      console.log(err)
      setImporting(false)
      toastService.error(
        `There was a problem importing this template. Please try again another time`,
      )
    }
  }

  return (
    <>
      <Modal.Header>Import to workspace</Modal.Header>
      <Modal.Body>
        <div className="row break-1">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="select-workspace">
                Select an import destination <Required />
              </label>
              <SelectSingle
                id="select-workspace"
                data={adminOrgs}
                placeholder="Select workspace"
                triggerIsBlock
                onChange={setSelectedOrg}
                value={selectedOrg}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="tertiary"
          onClick={onReject}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleImport}
          loading={importing}
        >
          Import
        </Button>
      </Modal.Footer>
    </>
  )
}
