import { useAppState } from '@src/app.state'
import { linkWithPopup, signInWithPopup, UserCredential } from 'firebase/auth'
import { k, useCss } from 'kremling'
import React, { SyntheticEvent } from 'react'

import { auth, functions, googleAuthProvider } from '@src/firebase-app'
import { httpsCallable } from 'firebase/functions'
import { AuthButton } from './google-auth.component'

type OauthProps = {
  registerType?: 'register' | 'link-account' | 'invitation' | 'purchase'
  handleClick?: () => void
  activityId?: string
  orgId?: string
  onComplete?: (userCredential: UserCredential) => void
}

export function OauthList({
  registerType,
  handleClick = () => {},
  activityId,
  orgId,
  onComplete,
}: OauthProps) {
  const styles = useCss(css)

  async function handleGoogleAuth(e: SyntheticEvent) {
    e.preventDefault()
    handleClick()
    try {
      if (registerType === 'link-account') {
        const { user } = await linkWithPopup(
          auth.currentUser,
          googleAuthProvider,
        )
        const { data: updatedUser } = await httpsCallable(
          functions,
          'activityFunctions-reconcileGuestUserData',
        )({
          activityId,
          userId: user.uid,
          orgId,
        })
        useAppState.setState({ user: updatedUser, appLoading: false })
        //TODO - update this
        // navigate('/dashboard')
      } else {
        const userCredential = await signInWithPopup(auth, googleAuthProvider)
        onComplete?.(userCredential)
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div
      {...styles}
      className="oauth-buttons"
    >
      {registerType === 'invitation' && (
        <div className="mt-3">
          Please register using the same email address with which you were
          invited.
        </div>
      )}
      {registerType === 'purchase' && (
        <div className="mt-3">
          Please register using the same email address with which you made your
          purchase.
        </div>
      )}
      <AuthButton
        color="#de5246"
        icon="google"
        handleAuth={handleGoogleAuth}
        isRegister={
          registerType === 'register' || registerType === 'link-account'
        }
      />
    </div>
  )
}

const css = k`

`
