import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { Card } from '@components/card.component'
import { InputField } from '@components/input'
import { Loader } from '@components/loader.component'
import { Logo } from '@components/logo.component'
import { Button } from '@components/mantine/button.component'
import { auth } from '@src/firebase-app'
import { toastService } from '@utils/toast.service'
import { Link } from 'react-router-dom'

export function CreateNewPassword() {
  const navigate = useNavigate()
  const [verifyingOobCode, setVerifyingOobCode] = useState(true)
  const [oobCodeIsValid, setOobCodeIsValid] = useState(false)
  const { oobCode } = useParams()
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      password: '',
      verifyPassword: '',
    },
  })

  useEffect(() => {
    verifyPasswordResetCode(auth, oobCode)
      .then(() => {
        setVerifyingOobCode(false)
        setOobCodeIsValid(true)
      })
      .catch(() => {
        setVerifyingOobCode(false)
      })
  }, [])

  async function updatePassword(data) {
    try {
      await confirmPasswordReset(auth, oobCode, data.password)
      toastService.info('Password successfully reset')
      navigate('/sign-in')
      reset()
    } catch {
      toastService.error('An error occurred while resetting your password')
    }
  }

  function renderContent() {
    if (verifyingOobCode) {
      return (
        <div className="loader">
          <Loader />
        </div>
      )
    } else {
      if (oobCodeIsValid) {
        return (
          <form onSubmit={handleSubmit(updatePassword)}>
            <Logo className="mb-6" />
            <h3 className="text-2xl font-bold">Create new password</h3>
            <InputField
              label="New password (at least 6 characters)"
              className="mt-16"
              type="password"
              control={control}
              fieldName="password"
              minLength={6}
              required
              withAsterisk={false}
            />
            <div className="has-warning mt-4">{errors.password?.message}</div>
            <InputField
              label="Verify password"
              className="mt-16"
              type="password"
              control={control}
              fieldName="verifyPassword"
              minLength={6}
              required
              withAsterisk={false}
            />
            <Button
              type="submit"
              variant="primary"
              className="mt-16"
              fullWidth
              disabled={isSubmitting}
            >
              Update password
            </Button>
          </form>
        )
      } else {
        return (
          <div className="invalid-message">
            <Logo className="mb-6" />
            <div className="mb-6">This password reset link has expired.</div>
            <div>
              <Link to="/sign-in">Sign in</Link>
            </div>
          </div>
        )
      }
    }
  }

  return (
    <div className="card-wrapper">
      <Card className="card-container">{renderContent()}</Card>
    </div>
  )
}
