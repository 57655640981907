import ls from 'local-storage'
import React, { useEffect, useRef, useState } from 'react'

import { Icon } from '@components/icon.component'
import { InputField } from '@components/input'
import { Loader } from '@components/loader.component'
import { Button } from '@components/mantine/button.component'
import { Modal } from '@components/modal.component'
import { Pill } from '@components/pill/pill.component'
import { db, isDevelopment } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { deriveUserNameFromDisplayName, getTodayDate } from '@utils/helpers'
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  runTransaction,
} from 'firebase/firestore'
// import { httpsCallable } from 'firebase/functions'
import { useAppState } from '@src/app.state'
import { AnimatePresence, motion } from 'framer-motion'
import { isEmpty, trim } from 'lodash'
import randomColor from 'randomcolor'
import { useForm } from 'react-hook-form'

export function ProfileInfoModal({ onResolve }) {
  const user = useAppState(state => state.user)
  const [_firstName, _lastName] = deriveUserNameFromDisplayName(
    user.displayName,
  )
  const newOrgId = useRef(null)
  const [updating, setUpdating] = useState(false)
  const realUserIsIn = (user.firstName && user.lastName) || user.displayName
  // const [newUser, setNewUser] = useState(null)

  const {
    handleSubmit,
    control,
    formState: { isValid },
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: user.firstName || _firstName,
      lastName: user.lastName || _lastName,
      workspaceName: '',
    },
  })

  useEffect(() => {
    if (realUserIsIn) {
      const [firstName, lastName] = deriveUserNameFromDisplayName(
        user.displayName,
      )
      if (user.displayName) {
        setValue('firstName', firstName, { shouldValidate: true })
        setValue('lastName', lastName, { shouldValidate: true })
      } else {
        setValue('firstName', user.firstName, { shouldValidate: true })
        setValue('lastName', user.lastName, { shouldValidate: true })
      }
    }
  }, [user.firstName, user.lastName])

  const watchFirstName = watch('firstName')
  const watchLastName = watch('lastName')
  const watchWorkspaceName = watch('workspaceName')

  async function updateUser({ firstName, lastName, workspaceName }) {
    setUpdating(true)
    const userRef = doc(db, dbNames.users, user.id)
    const today = getTodayDate().toISO()

    await runTransaction(db, async transaction => {
      // Creating organization
      if (workspaceName) {
        const orgRef = doc(collection(db, dbNames.organizations))
        transaction.set(orgRef, {
          name: trim(workspaceName),
          timezone: '',
          imageUrl: '',
          isPublic: false,
          createdDate: today,
          isLocked: false,
          themeColor: randomColor({
            luminosity: 'light',
            hue: 'random',
          }),
        })

        // Creating organization profile
        transaction.set(
          doc(db, dbNames.organizationProfiles, `${orgRef.id}_${user.id}`),
          {
            orgId: orgRef.id,
            userId: user.id,
            mentorWorkflowIds: [],
            role: 'owner',
            createdDate: today,
          },
        )

        // Updating user document
        transaction.update(userRef, {
          firstName: trim(firstName),
          lastName: trim(lastName),
          isFirstLogin: false,
          orgOrderIds: arrayUnion(orgRef.id),
          [`orgRoles.${orgRef.id}`]: 'owner',
        })

        newOrgId.current = orgRef.id
      } else {
        // Updating user document
        const userSnap = await getDoc(userRef)

        const userPayload = {
          ...userSnap.data(),
          firstName: trim(firstName),
          lastName: trim(lastName),
          isFirstLogin: false,
        }

        if (isEmpty(userSnap.data().orgOrderIds)) {
          userPayload.orgOrderIds = []
        }

        if (isEmpty(userSnap.data().orgRoles)) {
          userPayload.orgRoles = {}
        }

        transaction.update(userRef, userPayload)
      }
    })

    // await httpsCallable(
    //   functions,
    //   'userFunctions-updateUserName',
    // )({
    //   userId: user.id,
    //   newFirstName: trim(firstName),
    //   newLastName: trim(lastName),
    // })

    // After transaction
    const _newUser = await getDoc(userRef)
    const orgs = useAppState.getState().orgs
    const path = isEmpty(orgs) ? '/welcome' : `/${orgs[0].id}`
    onResolve({
      ..._newUser.data(),
      newUser: { isFirstLogin: false },
      // path: `/${orgs[0].id}`,
      path,
    })
    // setNewUser(_newUser.data())
    // setNewUser({ ..._newUser.data(), id: _newUser.id })
  }

  // function endDialog(path) {
  //   onResolve({ newUser, path })
  // }

  // if (newUser) {
  //   return (
  //     <Modal.Body>
  //       <div className="py-4">
  //         <div className="text-primary text-center font-bold text-4xl mb-8">
  //           Welcome, {newUser.firstName} {newUser.lastName}
  //         </div>
  //         <div className="mb-4 text-center font-semibold text-3xl">
  //           Where would you like to go next?
  //         </div>
  //         <div className="flex h-40 gap-4">
  //           <button
  //             //TODO - update this
  //             onClick={() => endDialog('/dashboard')}
  //             className="flex flex-1 bg-active-50 rounded-lg p-4 justify-center items-center"
  //           >
  //             <div className="text-3xl font-semibold">Dashboard</div>
  //           </button>
  //           <button
  //             onClick={() =>
  //               endDialog(`/${newOrgId.current}/library/templates`)
  //             }
  //             className="flex flex-1 bg-active-50 rounded-lg justify-center items-center p-4 flex-col"
  //           >
  //             <div className="text-3xl font-semibold">Templates</div>
  //           </button>
  //         </div>
  //       </div>
  //     </Modal.Body>
  //   )
  // }

  return (
    <>
      {(updating || !realUserIsIn) && <Loader overlay />}
      <Modal.Header>Welcome to iAct!</Modal.Header>
      <form onSubmit={handleSubmit(updateUser)}>
        <Modal.Body>
          <div className="mb-2 mt-5">
            <Pill
              pillType={watchFirstName && watchLastName ? '' : 'primary'}
              className="mr-2"
            >
              {watchFirstName && watchLastName ? (
                <Icon
                  name="check"
                  size={13}
                />
              ) : (
                'Step 1'
              )}
            </Pill>
            Please update your personal information
          </div>
          <div className="row break-1">
            <div className="col-12">
              <div className="form-group">
                <InputField
                  fieldName="firstName"
                  control={control}
                  label="First name"
                  required
                  withAsterisk
                />
              </div>
            </div>
          </div>
          <div className="row break-1">
            <div className="col-12">
              <div className="form-group">
                <InputField
                  fieldName="lastName"
                  control={control}
                  label="Last name"
                  required
                  withAsterisk
                />
              </div>
            </div>
          </div>
          {(ls.get('iact-register-feature') || isDevelopment) &&
            useAppState.getState().orgs.length === 0 && (
              <>
                <div className="mb-2 mt-5">
                  <Pill
                    pillType={watchWorkspaceName ? '' : 'primary'}
                    className="mr-2"
                  >
                    <motion.div id="workspace-name-pill">
                      {watchWorkspaceName ? (
                        <Icon
                          name="check"
                          size={13}
                        />
                      ) : (
                        'Step 2'
                      )}
                    </motion.div>
                  </Pill>
                  Choose a name for your first workspace
                </div>
                <div className="row break-1">
                  <div className="col-12">
                    <div className="form-group">
                      <InputField
                        fieldName="workspaceName"
                        control={control}
                        label="Workspace name"
                        placeholder="e.g. My Company, My Team, etc."
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-end">
          {
            <AnimatePresence>
              {isValid && (
                <motion.div
                  initial={{ opacity: 0, y: '-1vh' }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: '1vh' }}
                  transition={{ duration: 0.2, ease: 'easeOut' }}
                >
                  You're all set!
                </motion.div>
              )}
            </AnimatePresence>
          }
          <Button
            type="submit"
            variant="primary"
            disabled={!isValid || updating}
          >
            Submit
          </Button>
        </Modal.Footer>
      </form>
    </>
  )
}
