import React, { useMemo } from 'react'
//import { useCss, k } from 'kremling'
import { doc, getDoc } from 'firebase/firestore'
import { DateTime } from 'luxon'

import { CardListItem } from '@components/card-list-item.component'
//import { Icon } from '@components/icon.component'
import { useLoad } from '@hooks/use-load.hook'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate, getUserFullName } from '@utils/helpers'
// import { useWorkflowProgress } from '@hooks/use-workflow-settings.hook'
import { Icon } from '@components/icon.component'
import { WorkflowProgressBar } from '@components/workflow-progress-bar.component'
import { useAppState } from '@src/app.state'

//TODO We'll use the ProgressStatusPill once we figure out the logic around the statuses (i.e. Behind, On Track, Done)

export function MentoringStatusCard({ mentorActivity }) {
  const me = useAppState(state => state.user)
  const orgs = useAppState(state => state.orgs)
  const systemDate = useAppState(state => state.systemDate)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const today = useMemo(() => getTodayDate(), [systemDate])

  const belongsToOrg = useMemo(
    () =>
      orgs.find(org => org.id === mentorActivity.activityOrgId) ||
      orgs.find(org => org.id === me.id),
    [orgs, mentorActivity, me],
  )

  const [activity] = useLoad(
    {},
    () => {
      return getDoc(
        doc(db, dbNames.activities, mentorActivity.activityId),
      ).then(userRef => ({ id: userRef.id, ...userRef.data() }))
    },
    [],
  )

  const [activityUser] = useLoad(
    {},
    () => {
      return getDoc(
        doc(
          db,
          dbNames.activityUsers,
          `${mentorActivity.activityId}_${mentorActivity.inviterId}`,
        ),
      ).then(userRef => ({ id: userRef.id, ...userRef.data() }))
    },
    [],
  )

  const convertedStartDate = useMemo(
    () =>
      DateTime.fromISO(activity.startDate?.date, {
        zone: activity.startDate?.timeZone,
      }).setZone(timeZone),
    [activity.startDate],
  )
  const convertedEndDate = useMemo(() => {
    return activity.endDate
      ? DateTime.fromISO(activity.endDate?.date, {
          zone: activity.endDate?.timeZone,
        }).setZone(timeZone)
      : null
  }, [activity.endDate])

  const isBeforeStartDate =
    convertedStartDate.toUTC().toISO() > today.toUTC().toISO()
  const isPastEndDate = convertedEndDate
    ? convertedEndDate.plus({ days: 1 }).toUTC().toISO() <=
      today.toUTC().toISO()
    : null

  return (
    <CardListItem
      className="space-y-4"
      cardTitle={getUserFullName({
        firstName: activityUser.userFirstName,
        lastName: activityUser.userLastName,
      })}
      subTitle={activity.name}
      href={`/${belongsToOrg.id}/mentoring/${activityUser.userId}/${activity.id}`}
      icon="user"
      renderRightContent={() => {
        if (isBeforeStartDate) {
          return `Begins ${convertedStartDate.toFormat('LLL dd')}`
        }

        if (isPastEndDate) {
          return (
            <div className="completed-container">
              <span>{convertedEndDate.toFormat('LLL dd')}</span>
              <Icon
                className="ml-3"
                name="check-circle-regular"
                fill="#42c873"
                size={16}
              />
            </div>
          )
        }

        return (
          <div className="client-workflow-progress media-show-1">
            <WorkflowProgressBar
              activity={activity}
              activityUser={activityUser}
            />
          </div>
        )
      }}
    />
  )
}

// function ProgressStatusPill({status}) {
//   const scope = useCss(css)
//
//   let label, colorClass
//   switch (status) {
//     case 'completed':
//       label = 'Done'
//       colorClass = 'completed-pill'
//       break
//     case 'overdue':
//       label = 'Behind'
//       colorClass = 'overdue-pill'
//       break
//     case 'inprogress':
//       label = 'On track'
//       colorClass = 'inprogress-pill'
//       break
//     default: break
//   }
//
//   return(
//     <div {...scope} className={`pill-container ${colorClass}`}>
//       <Icon name="circle-waveform-lines" className="mr-12"/>
//       <div className="pill-label">{label}</div>
//     </div>
//   )
// }

// const css = k`
//   .pill-container {
//     display: flex;
//     align-items: center;
//     background: $color-grey-50;
//     padding: .6rem 1rem;
//     line-height: 1.4rem;
//     border-radius: 100vh;
//   }
//
//   .completed-pill > svg {
//     color: $color-success;
//   }
//
//   .overdue-pill > svg {
//     color: $color-danger;
//   }
//
//   .inprogress-pill > svg {
//     color: $color-grey-200;
//   }
// `
