import { doc, increment, updateDoc } from 'firebase/firestore'
import { k, useCss } from 'kremling'
import { isEmpty } from 'lodash'
import React from 'react'

import { EmptyState } from '@components/empty-state.component'
import { Button } from '@components/mantine/button.component'
import { SelectSingle } from '@components/select-single.component'
import { db } from '@src/firebase-app'
import { MarketplaceTemplateCard } from '@src/pages/marketplace/marketplace-template-card.component'
import { dbNames } from '@utils/constants'

export function TemplateSearchList({
  templates,
  searchVal,
  selectedCategory,
  onCategoryChange,
  categories,
  selectedSort,
  onSortChange,
  sortOptions,
}) {
  const scoped = useCss(css)

  return (
    <div {...scoped}>
      <div className="filter-options">
        <div className="categories__wrapper">
          <Button
            className="mr-2"
            onClick={() => onCategoryChange('all')}
            variant={selectedCategory === 'all' ? 'primary' : 'secondary'}
            aria-label={`View templates from all categories`}
          >
            All
          </Button>
          {categories.map(({ id, name }) => (
            <Button
              className="mr-2"
              key={id}
              onClick={() => onCategoryChange(id)}
              variant={selectedCategory === id ? 'primary' : 'secondary'}
              aria-label={`View templates from the ${name} category`}
            >
              {name}
            </Button>
          ))}
        </div>
        <div className="col-3">
          <div className="form-group">
            <SelectSingle
              onChange={onSortChange}
              data={sortOptions}
              value={selectedSort}
              contentWidth="sm"
              position="bottom-left"
            />
          </div>
        </div>
      </div>
      <div>
        {isEmpty(templates) && searchVal && (
          <EmptyState
            title="No search results"
            subtitle={`"${searchVal}" yielded no results.`}
          />
        )}
        {!isEmpty(templates) && (
          <div className="filter-items__wrapper">
            {templates.map(template => (
              <MarketplaceTemplateCard
                key={template.id}
                template={template}
                onView={() => {
                  updateDoc(
                    doc(db, dbNames.marketplaceTemplates, template.id),
                    {
                      viewsCount: increment(1),
                    },
                  )
                }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

const css = k`
  .well__title {
    overflow: hidden;
  }

  .filter-options {
    display: flex;
  }

  .categories__wrapper::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }

  .categories__wrapper {
    overflow-x: auto;
    padding: 4px;
    margin: -4px;
    display: flex;
    position: relative;
    width: 100%;
    margin-right: 12px;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }

  .filter-items__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .category-fade {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100px;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));
    display: block;
  }

  @media (min-width: $media1-width) {
    .filter-items__wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (min-width: $media1-width) {
    .filter-items__wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (min-width: $media2-width) {
    .filter-items__wrapper {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media (min-width: $media4-width) {
    .filter-items__wrapper {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
`
