import { Card } from '@components/card.component'
import { useActivityStore } from '@src/pages/workflow/client/workflow-refactor/state/use-activity-state'
import { getTodayDate } from '@utils/helpers'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import React, { useMemo } from 'react'

export function ActivityDashboard() {
  const activity = useActivityStore(state => state.activity)

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const convertedStartDate = DateTime.fromISO(activity?.startDate.date, {
    zone: activity?.startDate.timeZone,
  }).setZone(timeZone)

  const activityHasStarted = useMemo(() => {
    if (isEmpty(activity)) return false
    const today = getTodayDate()
    return convertedStartDate <= today
  }, [activity])

  if (!activityHasStarted) {
    return (
      <div className="flex h-full items-center">
        <Card className="w-full h-[30rem]">
          <div className="flex items-center justify-center flex-col h-full">
            <h1 className="bg-gradient-to-r from-primary to-stats-two text-5xl font-bold mb-10 text-transparent bg-clip-text">
              This activity has not started yet
            </h1>
            <p className="text-3xl font-semibold mb-5">It will start on:</p>
            <p className="text-4xl font-bold">
              {convertedStartDate.toLocaleString(DateTime.DATETIME_FULL)}
            </p>
          </div>
        </Card>
      </div>
    )
  }

  return null
}
