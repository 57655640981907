import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { k, useCss } from 'kremling'
import { chunk, flatten, isEmpty } from 'lodash'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Card } from '@components/card.component'
import { Icon } from '@components/icon.component'
import { Loader } from '@components/loader.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { Pill } from '@components/pill/pill.component'
import { Select } from '@components/select.component'
import TagList from '@components/tag-list.component'
import { Tooltip } from '@components/tooltip.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db, functions } from '@src/firebase-app'
import { searchTypesense } from '@src/typesense.config'
import { dbNames } from '@utils/constants'
import { getTodayDate, getUserFullName } from '@utils/helpers'
import { toastService } from '@utils/toast.service'
import { getOrgRoles } from './user.utils'

export function EditUser() {
  const scope = useCss(css)
  const [saving, setSaving] = useState(false)
  const me = useAppState(state => state.user)
  const isFreeTier = useAppState(state => state.isFreeTier)
  const { orgId, userId } = useParams()
  const navigate = useNavigate()
  const [suggestions, setSuggestions] = useState([])

  const [activeWorkflows, _setActiveWorkflows, activeWorkflowsOpts] = useLoad(
    [],
    async () => {
      const q = query(
        collection(db, dbNames.activityUsers),
        where('userId', '==', userId),
        where('endDate.date', '>', getTodayDate().toISO()),
      )

      const activityUserInstances = await getDocs(q)
      const activityIds = activityUserInstances.docs.map(doc =>
        doc.get('activityId'),
      )
      const chunks = chunk(activityIds, 10)
      const promises = []

      chunks.forEach(chunk => {
        const activityQuery = query(
          collection(db, dbNames.activities),
          where(documentId(), 'in', chunk),
        )
        promises.push(
          getDocs(activityQuery).then(snap =>
            snap.docs.map(doc => ({ ...doc.data(), id: doc.id })),
          ),
        )
      })

      return Promise.all(promises).then(flatten)
    },
    [],
  )

  const [orgProfile, setOrgProfile] = useLoad(
    {},
    () => {
      const d = doc(db, dbNames.organizationProfiles, `${orgId}_${userId}`)
      return getDoc(d).then(doc => ({ id: doc.id, ...doc.data() }))
    },
    [orgId, userId],
  )

  const [user] = useLoad(
    null,
    () => {
      const d = doc(db, dbNames.users, userId)
      return getDoc(d).then(doc => ({ id: doc.id, ...doc.data() }))
    },
    [userId],
  )

  async function handleChangeOrgRole(newOrgRole) {
    setSaving(true)
    try {
      const updateUserOrgRole = httpsCallable(
        functions,
        'activityFunctions-updateUserOrgRole',
      )

      await updateUserOrgRole({
        userId,
        newOrgRole: newOrgRole.value,
        orgId,
      })

      setSaving(false)
      toastService.info('Role successfully updated')
    } catch (err) {
      setSaving(false)
      toastService.error(err.message)
    }
  }

  // async function deactivateUser() {
  //   setSaving(true)
  //   try {
  //     const d = doc(db, dbNames.organizationProfiles, `${orgId}_${userId}`)
  //     await updateDoc(d, { deactivated: true })
  //
  //     toastService.info('User successfully deactivated')
  //     orgProfileOpts.reload()
  //     setSaving(false)
  //   } catch (err) {
  //     setSaving(false)
  //     toastService.error(err.message)
  //   }
  // }
  //
  // async function reactivateUser() {
  //   if (process.env.NODE_ENV === 'production') return
  //   setSaving(true)
  //   try {
  //     const d = doc(db, dbNames.organizationProfiles, `${orgId}_${userId}`)
  //     await updateDoc(d, { deactivated: false })
  //
  //     toastService.info('User successfully reactivated')
  //     orgProfileOpts.reload()
  //     setSaving(false)
  //   } catch (err) {
  //     setSaving(false)
  //     toastService.error(err.message)
  //   }
  // }

  //TODO
  // - Save new tag in subcollection
  // - Suggestion list for already created tags
  // - save tags in typesense
  // - when a tag is removed from an org profile...also remove from activityUser

  const isMyProfile = userId === me.id
  const userOrgRole = user?.orgRoles[orgId]
  const myOrgRole = me.orgRoles[orgId]
  const displayLoader = activeWorkflowsOpts.loading || !userOrgRole
  const lackPermissions = userOrgRole === 'owner' && myOrgRole !== 'owner'
  const disableRoleChange = isMyProfile || lackPermissions || isFreeTier
  return (
    <div
      {...scope}
      className="--xs iact-container pt-8"
    >
      <div className="user__actions">
        <ActionIcon
          size="sm"
          icon="angle-left"
          onClick={() => navigate(-1)}
        />
      </div>
      <div className="pt-8 flex flex-col gap-8">
        <Card>
          {displayLoader ? (
            <Loader />
          ) : (
            <div className="p-6">
              <div className="user__header">
                <div className="user-header__top">
                  <div className="title">
                    <div className="user-avatar">
                      {saving ? (
                        <Loader size="sm" />
                      ) : (
                        <Icon
                          name="user-circle"
                          size={40}
                        />
                      )}
                    </div>
                    <div className="user__info">
                      <div className="user__name">
                        {getUserFullName(user)}{' '}
                        {orgProfile.deactivated && (
                          <Pill pillType="danger">DEACTIVATED</Pill>
                        )}
                      </div>
                      <div>{user.email}</div>
                    </div>
                  </div>
                  {!orgProfile?.deactivated && (
                    <div className="user__role">
                      <Tooltip
                        interactive={isFreeTier}
                        content={
                          isFreeTier ? (
                            <div>
                              <p>
                                Adjusting user roles in a Free Tier workspace is
                                restricted.
                              </p>
                              <p>
                                For more information about upgrading click the
                                link below.
                              </p>
                              <p>
                                <a
                                  className="btn --primary mt-3"
                                  href="https://iact.com/go-pro/"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  iact.com
                                </a>
                              </p>
                            </div>
                          ) : (
                            'Only another Owner can modify this role'
                          )
                        }
                        placement="bottom"
                        disabled={!disableRoleChange}
                        maxWidth={250}
                      >
                        <Select
                          id="org-role"
                          isDisabled={disableRoleChange}
                          options={getOrgRoles(me.orgRoles[orgId])}
                          onChange={handleChangeOrgRole}
                          defaultValue={{
                            value: userOrgRole,
                            label: `${userOrgRole?.[0].toUpperCase()}${userOrgRole.slice(
                              1,
                            )}`,
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>

                <div className="user-header__bottom">
                  <div className="tag-list__container">
                    <TagList
                      disabled={orgProfile?.deactivated}
                      suggestions={suggestions}
                      assignedTags={orgProfile.tags}
                      onInputChange={text => {
                        if (!text) return setSuggestions([])
                        const searchParams = {
                          q: text,
                          query_by: 'label',
                        }
                        searchTypesense('tags', searchParams).then(
                          ({ hits }) => {
                            setSuggestions(
                              hits.map(({ document }) => document.label),
                            )
                          },
                        )
                      }}
                      onSave={async tags => {
                        const d = doc(
                          db,
                          dbNames.organizationProfiles,
                          `${orgId}_${userId}`,
                        )
                        const oldTags = orgProfile.tags
                        setOrgProfile({ ...orgProfile, tags })

                        try {
                          await updateDoc(d, { tags })
                          const batch = writeBatch(db)

                          tags.forEach(tag => {
                            batch.set(
                              doc(
                                db,
                                dbNames.organizations,
                                orgId,
                                dbNames.tags,
                                tag,
                              ),
                              {
                                createdDate: getTodayDate().toISO(),
                              },
                            )
                          })

                          await batch.commit()
                        } catch (err) {
                          console.log(err)
                          setOrgProfile({ ...orgProfile, tags: oldTags })
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="user-content">
                <div className="active-workflows">
                  <div className="list__header">Active workflows</div>
                  {isEmpty(activeWorkflows) ? (
                    <div>This user is not assigned any workflows</div>
                  ) : (
                    activeWorkflows.map(workflow => (
                      <Card
                        href={`/${orgId}/mentoring/${userId}/${workflow.id}`}
                        key={workflow.id}
                        className="active-workflow-card"
                      >
                        <span className="list-item__header">
                          {workflow.name}
                        </span>
                      </Card>
                    ))
                  )}
                </div>
              </div>
            </div>
          )}
        </Card>
        {/*{orgProfile && me?.id !== user?.id && (*/}
        {/*  <Card className="p-6">*/}
        {/*    <Button*/}
        {/*      disabled={orgProfile?.deactivated}*/}
        {/*      variant="secondary"*/}
        {/*      onClick={deactivateUser}*/}
        {/*    >*/}
        {/*      Remove user from workspace*/}
        {/*    </Button>*/}
        {/*    {orgProfile?.deactivated &&*/}
        {/*      process.env.NODE_ENV !== 'production' && (*/}
        {/*        <Button*/}
        {/*          className="ml-4"*/}
        {/*          variant="secondary"*/}
        {/*          onClick={reactivateUser}*/}
        {/*        >*/}
        {/*          Reactivate user*/}
        {/*        </Button>*/}
        {/*      )}*/}
        {/*  </Card>*/}
        {/*)}*/}
      </div>
    </div>
  )
}

EditUser.propTypes = {}

const css = k`
  .user__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.6rem;
  }
  .user__header {
    margin-bottom: 3.2rem;
  }

  .user-header__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tag-list__container {
    margin-top: 12px;
  }

  .title {
    display: flex;
    align-items: center;
    flex: 3;
  }

  .user-avatar {
    min-width: 4rem;
  }

  .user__role {
    flex: 2;
  }

  .user__info {
    margin-left: 1.6rem;
  }

  .user__name {
    font-size: 1.8rem;
    font-weight: 500;
  }

  .active-workflow-card {
    padding: 1rem;
    margin-bottom: 1.2rem;
  }

  .list__header {
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 1.2rem;
  }

  .list-item__header {
    font-size: 1.4rem;
    font-weight: 500;
  }
`
