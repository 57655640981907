import React from 'react'
import { Icon } from '@components/icon.component'
import { useCss, k } from 'kremling'

export function AuthButton({ handleAuth, icon, isRegister, color }) {
  const styles = useCss(css)
  return (
    <button
      {...styles}
      style={{ backgroundColor: color }}
      className="oauth-btn google-oauth"
      onClick={handleAuth}
    >
      <div className="oauth-icon-container">
        <Icon
          className="oauth-icon"
          name={icon}
        />
      </div>
      {isRegister ? 'Register ' : 'Sign in '}
      with Google
    </button>
  )
}

const css = k`
  .oauth-btn {
    background: white;
    width: 100%;
    border: none;
    display: flex;
    align-items: center;
    padding: 0;
    border-radius: $base-border-radius;
    height: 4rem;
    font-weight: bold;

    .oauth-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      border-right: solid .2rem white;
      margin-right: 1rem;
      padding: 0 1rem;

    }

    .oauth-icon {
      color: white;
    }

    &.google-oauth {
      color: white;
    }
  }
`
