import { Button } from '@components/mantine/button.component'
import { Modal } from '@components/modal.component'
import { SelectSingle } from '@components/select-single.component'
import { useLoad } from '@hooks/use-load.hook'
import { db, functions } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate, getUserFullName } from '@utils/helpers'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import React, { useState } from 'react'

type ModalProps = {
  onReject: () => void
  onResolve: (data?: any) => void
  orgId: string
  participants: ActivityUser[]
  activity: Activity
}

export function AddParticipantsModal({
  onReject,
  onResolve,
  orgId,
  activity,
}: ModalProps) {
  const [isGroup, setIsGroup] = useState<boolean>(false)
  const [selectedEntity, setSelectedEntity] = useState(null)
  const [searchUser, setSearchUser] = useState('')
  const [saving, setSaving] = useState(false)

  const [users] = useLoad(
    [],
    () => {
      const q = query(
        collection(db, dbNames.users),
        where(`orgRoles.${orgId}`, '!=', 'deactivated'),
        orderBy(`orgRoles.${orgId}`),
      )
      return getDocs(q).then(snap =>
        snap.docs.map(d => {
          const user = { id: d.id, ...d.data() }
          return {
            ...user,
            name: getUserFullName(user),
          }
        }),
      )
    },
    [],
  )

  const [groups] = useLoad([], () => {
    const q = query(collection(db, dbNames.groups), where('orgId', '==', orgId))
    return getDocs(q).then(snap =>
      snap.docs.map(d => ({ ...d.data(), id: d.id })),
    )
  })

  async function onAdd() {
    const today = getTodayDate().toISO()
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    let userIds: string[] = []
    if (isGroup) {
      userIds = selectedEntity.userIds
    } else {
      userIds.push(selectedEntity.id)
    }

    try {
      setSaving(true)
      await httpsCallable(
        functions,
        'userFunctions-addUsersToActivity',
      )({
        activityId: activity.id,
        activityName: activity.name,
        userIds,
        startDate: activity.startDate,
        endDate: activity.endDate,
        templateId: activity.templateId,
        version: activity.version,
        today,
        groupId: isGroup ? selectedEntity.id : null,
        orgId,
        userTimeZone: timeZone,
      })
      onResolve()
    } catch (err) {
      setSaving(false)
    }
  }

  return (
    <>
      <Modal.Header>Add participants</Modal.Header>
      <Modal.Body>
        <div className="row break-1">
          <div className="col-12">
            <div className="form-group">
              <SelectSingle
                // @ts-ignore
                triggerIsBlock
                contentWidth="block"
                placeholder="Select Assignee"
                isGroupData
                className="mt-3"
                data={[
                  {
                    id: 'groups',
                    icon: 'users',
                    name: 'Groups',
                    data: groups,
                  },
                  {
                    id: 'users',
                    icon: 'user',
                    name: 'Users',
                    data: users,
                  },
                ]}
                onChange={(userOrGroup: any) => {
                  setIsGroup(!!userOrGroup?.userIds)
                  setSelectedEntity(userOrGroup)
                }}
                searchOnChange={setSearchUser}
                fixedContent
                searchValue={searchUser}
                searchFilter
                value={selectedEntity}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onReject}>Cancel</Button>
        <Button
          loading={saving}
          variant="primary"
          onClick={onAdd}
        >
          Add users
        </Button>
      </Modal.Footer>
    </>
  )
}
