import { uniq } from 'lodash'
import { assign, createMachine } from 'xstate'

function addListItem(lists, listName, item) {
  const list = lists[listName]
  return {
    ...lists,
    [listName]: [...list, item],
  }
}

export function selectListItem(lists, listName, item) {
  const list = lists[listName] || []
  if (list.includes(item)) {
    return {
      ...lists,
      [listName]: list.filter(i => i !== item),
    }
  } else {
    return {
      ...lists,
      [listName]: [...list, item],
    }
  }
}

function editListItem(lists, listName, value, index) {
  const list = lists[listName]
  return {
    ...lists,
    [listName]: list.map((item, i) => {
      if (i === index) {
        return value
      }
      return item
    }),
  }
}

export const templateStateMachine = createMachine({
  predictableActionArguments: true,
  id: 'flow',
  context: {
    fields: {
      // audienceDemographics: 'students',
      // outcomeDescription: 'happier',
      // audienceState: 'unhappy',
    },
    rawResponses: {
      researchInsights: [],
      researchChallenges: [],
      experienceIdeas: [],
      sections: [],
      activities: [],
    },
    lists: {
      researchInsights: [
        // 'insight one'
      ],
      researchChallenges: [
        // 'challenge one'
      ],
      experienceIdeas: [
        // 'game of chess',
        // 'scavenger hunt',
        // 'cartwheel competition'
      ],
      sections: [
        // 'scavenger hunt',
        // 'cartwheel competition'
      ],
      activities: [
        // {
        //   action: 'scavenger hunt',
        //   id: '123',
        //   validationFields: [
        //     {
        //       "fieldType": "Text",
        //       "textType": "number",
        //       "fieldLabel": "Duration (in minutes)",
        //       "fieldDescription": null,
        //       "choices": null
        //     },
        //   ]
        // }
      ],
    },
    selectedItems: {
      researchInsights: [],
      researchChallenges: [],
      experienceIdea: null,
      sections: [],
      validationFields: {},
    },
  },
  initial: 'bootstrap',
  on: {
    updateField: {
      actions: assign({
        fields: (context, event) => {
          return {
            ...context.fields,
            [event.fieldId]: event.value,
          }
        },
      }),
    },
  },
  states: {
    bootstrap: {
      invoke: {
        id: 'bootstrap',
        src: 'bootstrapData',
        onDone: [
          {
            target: 'empathize',
            cond: 'noEmpathyInsights',
            actions: 'bootstrapAssign',
          },
          {
            target: 'empathizeInsights',
            cond: 'noExperienceIdeas',
            actions: 'bootstrapAssign',
          },
          {
            target: 'experienceIdeas',
            cond: 'noSections',
            actions: 'bootstrapAssign',
          },
          {
            target: 'touchpoints',
            cond: 'noActivities',
            actions: 'bootstrapAssign',
          },
          { target: 'measureAndTrack', actions: 'bootstrapAssign' },
        ],
      },
    },
    empathize: {
      initial: 'idle',
      on: {
        next: 'empathizeInsights',
      },
      states: {
        idle: {
          on: {
            submit: {
              target: 'loading',
            },
          },
        },
        loading: {
          invoke: {
            id: 'fetch-ideas',
            src: 'getResearchIdeas',
            onDone: {
              target: '#flow.empathizeInsights',
              actions: assign({
                lists: ({ lists, selectedItems }, event) => {
                  const { insights, challenges } = event.data.ideas
                  return {
                    ...lists,
                    researchInsights: [
                      ...(selectedItems.researchInsights || []),
                      ...insights,
                    ],
                    researchChallenges: [
                      ...(selectedItems.researchChallenges || []),
                      ...challenges,
                    ],
                  }
                },
                rawResponses: (context, event) => {
                  const { rawResponses } = context
                  const { rawInsights, rawChallenges } = event.data
                  return {
                    ...rawResponses,
                    researchInsights: [
                      ...rawResponses.researchInsights,
                      rawInsights,
                    ],
                    researchChallenges: [
                      ...rawResponses.researchChallenges,
                      rawChallenges,
                    ],
                  }
                },
                fields: (context, event) => {
                  const {
                    audienceDemographics,
                    outcomeDescription,
                    audienceState,
                  } = event.data
                  return {
                    ...context.fields,
                    audienceDemographics,
                    outcomeDescription,
                    audienceState,
                  }
                },
              }),
            },
            onError: {
              target: 'failed',
              // actions: (context, event) => {
              //   debugger
              // },
            },
          },
        },
        failed: {
          on: {
            retry: 'loading',
          },
        },
      },
    },
    empathizeInsights: {
      initial: 'idle',
      on: {
        back: 'empathize',
        next: 'experienceIdeas',
      },
      states: {
        idle: {
          on: {
            challengeSelected: {
              actions: assign({
                selectedItems: ({ selectedItems }, { challenge }) => {
                  return selectListItem(
                    selectedItems,
                    'researchChallenges',
                    challenge,
                  )
                },
              }),
            },
            insightSelected: {
              actions: assign({
                selectedItems: ({ selectedItems }, { insight }) => {
                  return selectListItem(
                    selectedItems,
                    'researchInsights',
                    insight,
                  )
                },
              }),
            },
            addChallenge: {
              actions: assign({
                lists: ({ lists }, { choice }) => {
                  return addListItem(lists, 'researchChallenges', choice)
                },
                selectedItems: ({ selectedItems }, { choice }) => {
                  return addListItem(
                    selectedItems,
                    'researchChallenges',
                    choice,
                  )
                },
              }),
            },
            addInsight: {
              actions: assign({
                lists: (context, { choice }) => {
                  return addListItem(context.lists, 'researchInsights', choice)
                },
                selectedItems: ({ selectedItems }, { choice }) => {
                  return addListItem(selectedItems, 'researchInsights', choice)
                },
              }),
            },
            editInsight: {
              actions: assign({
                lists: ({ lists }, { value, index }) => {
                  return editListItem(lists, 'researchInsights', value, index)
                },
                selectedItems: ({ selectedItems }, { value, index }) => {
                  return editListItem(
                    selectedItems,
                    'researchInsights',
                    value,
                    index,
                  )
                },
              }),
            },
            editChallenge: {
              actions: assign({
                lists: ({ lists }, { value, index }) => {
                  return editListItem(lists, 'researchChallenges', value, index)
                },
                selectedItems: ({ selectedItems }, { value, index }) => {
                  return editListItem(
                    selectedItems,
                    'researchChallenges',
                    value,
                    index,
                  )
                },
              }),
            },
            generate: {
              target: 'loading',
            },
          },
        },
        loading: {
          invoke: {
            id: 'fetch-experience-ideas',
            src: 'getExperienceIdeas',
            onDone: {
              target: '#flow.experienceIdeas',
              actions: assign({
                lists: ({ lists, selectedItems }, event) => {
                  return {
                    ...lists,
                    experienceIdeas: uniq([
                      selectedItems.experienceIdea,
                      ...event.data.experienceIdeas,
                    ]).filter(Boolean),
                  }
                },
                rawResponses: (context, event) => {
                  const { rawResponses } = context
                  const { rawExperienceIdea } = event.data
                  return {
                    ...rawResponses,
                    experienceIdeas: [
                      ...rawResponses.experienceIdeas,
                      rawExperienceIdea,
                    ],
                  }
                },
              }),
            },
            onError: {
              target: 'failed',
              // actions: (context, event) => {
              //   debugger
              // }
            },
          },
        },
        failed: {
          on: {
            retry: 'loading',
          },
        },
      },
    },
    // ideation: {
    //   initial: 'idle',
    //   states: {
    //     idle: {
    //       on: {
    //         generate: {
    //           target: 'loading',
    //         }
    //       }
    //     },
    //     loading: {
    //       invoke: {
    //         id: 'fetch-experience-ideas',
    //         src: 'getExperienceIdeas',
    //         onDone: {
    //           target: '#flow.experienceIdeas',
    //           actions: assign({
    //             experienceIdeas: (_, event) => event.data.experienceIdeas,
    //             messageHistory: (_, event) => event.data.messageHistory
    //           })
    //         },
    //         onError: {
    //           target: 'failed',
    //           actions: (context, event) => {
    //             debugger
    //           }
    //         }
    //       }
    //     },
    //     failed: {
    //       on: {
    //         retry: 'loading'
    //       }
    //     }
    //   }
    // },
    experienceIdeas: {
      initial: 'idle',
      on: {
        back: 'empathizeInsights',
        next: 'touchpoints',
      },
      states: {
        idle: {
          on: {
            loadMore: {
              target: 'loadingMoreIdeas',
            },
            experienceIdeaSelected: {
              actions: assign({
                selectedItems: ({ selectedItems }, event) => ({
                  ...selectedItems,
                  experienceIdea: event.idea,
                }),
              }),
            },
            addExperienceIdea: {
              actions: assign({
                lists: ({ lists }, event) => {
                  return addListItem(lists, 'experienceIdeas', event.choice)
                },
                selectedItems: ({ selectedItems }, event) => ({
                  ...selectedItems,
                  experienceIdea: event.choice,
                }),
              }),
            },
            editExperienceIdea: {
              actions: assign({
                experienceIdeas: ({ lists }, { value, index }) => {
                  return editListItem(lists, 'experienceIdeas', value, index)
                },
              }),
            },
            generate: 'loading',
          },
        },
        loadingMoreIdeas: {
          invoke: {
            id: 'fetch-experience-ideas',
            src: 'loadMoreExperienceIdeas',
            onDone: {
              target: 'idle',
              actions: assign({
                lists: ({ lists }, event) => {
                  return {
                    ...lists,
                    experienceIdeas: [
                      ...lists.experienceIdeas,
                      ...event.data.experienceIdeas,
                    ],
                  }
                },
                rawResponses: (context, event) => {
                  const { rawResponses } = context
                  const { rawExperienceIdea } = event.data
                  return {
                    ...rawResponses,
                    experienceIdeas: [
                      ...rawResponses.experienceIdeas,
                      rawExperienceIdea,
                    ],
                  }
                },
              }),
            },
          },
        },
        loading: {
          invoke: {
            id: 'fetch-sections',
            src: 'getSections',
            onDone: {
              target: '#flow.touchpoints',
              actions: assign({
                lists: ({ lists }, event) => {
                  return {
                    ...lists,
                    activities: [],
                    sections: event.data.sections,
                  }
                },
                rawResponses: (context, event) => {
                  const { rawResponses } = context
                  const { rawSections } = event.data
                  return {
                    ...rawResponses,
                    sections: [...rawResponses.sections, rawSections],
                  }
                },
                selectedItems: ({ selectedItems }) => {
                  return {
                    ...selectedItems,
                    sections: [],
                    validationFields: {},
                  }
                },
              }),
            },
            onError: {
              target: 'failed',
              // actions: (context, event) => {
              //   debugger
              // }
            },
          },
        },
        failed: {
          on: {
            retry: 'loading',
          },
        },
      },
    },
    touchpoints: {
      initial: 'idle',
      on: {
        back: 'experienceIdeas',
        next: 'measureAndTrack',
      },
      states: {
        idle: {
          on: {
            generate: {
              target: 'loading',
            },
            addSection: {
              actions: assign({
                lists: ({ lists }, { choice }) => {
                  return addListItem(lists, 'sections', choice)
                },
                selectedItems: ({ selectedItems }, { choice }) => {
                  return addListItem(selectedItems, 'sections', choice)
                },
              }),
            },
            sectionSelected: {
              actions: assign({
                selectedItems: ({ selectedItems }, { section }) => {
                  const list = selectedItems.sections || []
                  if (
                    list
                      .map(({ description }) => description)
                      .includes(section.description)
                  ) {
                    const filtered = list.filter(i => {
                      return i.description !== section.description
                    })
                    return {
                      ...selectedItems,
                      sections: filtered,
                    }
                  } else {
                    return {
                      ...selectedItems,
                      sections: [...selectedItems.sections, section],
                    }
                  }
                },
              }),
            },
          },
        },
        loading: {
          invoke: {
            id: 'fetch-actions',
            src: 'getActivities',
            onDone: {
              target: '#flow.measureAndTrack',
              actions: assign({
                lists: ({ lists }, { data }) => ({
                  ...lists,
                  activities: data.activities,
                }),
                rawResponses: (context, event) => {
                  const { rawResponses } = context
                  const { rawActivities } = event.data
                  return {
                    ...rawResponses,
                    activities: rawActivities,
                  }
                },
                selectedItems: ({ selectedItems }, { data }) => {
                  return {
                    ...selectedItems,
                    //Auto-select all the activities
                    validationFields: data.activities.reduce(
                      (accum, { id, validationFields }) => ({
                        ...accum,
                        [id]: validationFields,
                      }),
                      {},
                    ),
                  }
                },
              }),
            },
            onError: {
              target: 'idle',
              // actions: (context, event) => {
              //   debugger
              // },
            },
          },
        },
      },
    },
    measureAndTrack: {
      on: {
        back: {
          target: '#flow.touchpoints',
        },
        fieldSelected: {
          actions: assign({
            selectedItems: (context, event) => {
              const { selectedField, activityId } = event.selection
              const { selectedItems } = context
              const validationFields = selectedItems.validationFields || []
              if (!validationFields[activityId]) {
                return {
                  ...selectedItems,
                  validationFields: {
                    ...validationFields,
                    [activityId]: [selectedField],
                  },
                }
              } else {
                const labels = validationFields[activityId].map(
                  ({ fieldLabel }) => fieldLabel,
                )
                if (labels.includes(selectedField.fieldLabel)) {
                  return {
                    ...selectedItems,
                    validationFields: {
                      ...validationFields,
                      [activityId]: validationFields[activityId].filter(
                        ({ fieldLabel }) =>
                          fieldLabel !== selectedField.fieldLabel,
                      ),
                    },
                  }
                } else {
                  return {
                    ...selectedItems,
                    validationFields: {
                      ...validationFields,
                      [activityId]: [
                        ...validationFields[activityId],
                        selectedField,
                      ],
                    },
                  }
                }
              }
            },
          }),
        },
        next: 'creation',
      },
    },
    creation: {
      entry: ['addTemplate'],
    },
  },
})
