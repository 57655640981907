import { ActionIcon } from '@components/mantine/action-icon.component'
import { TextInput } from '@components/mantine/text-input.component'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { generateEmbedLink, getVideoIdFromURL } from '../../fields.helper'

interface YouTubeEmbedProps {
  fieldLink: string
  onChange: (value: string) => void
}

export const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({
  fieldLink,
  onChange,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors },
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      fieldLink,
    },
  })
  const fieldLinkValue = watch('fieldLink')

  const embedUrl = useMemo(
    () => generateEmbedLink(getVideoIdFromURL(fieldLinkValue)) || '',
    [fieldLinkValue],
  )

  useEffect(() => {
    if (isValid) {
      addEmbedVideo({ fieldLink: fieldLinkValue })
    }
  }, [fieldLinkValue, isValid])

  function addEmbedVideo({ fieldLink }: { fieldLink: string }) {
    const videoId = getVideoIdFromURL(fieldLink)
    if (videoId) {
      onChange(fieldLink)
    }
  }

  const { onChange: onInputChange, ...inputProps } = register('fieldLink', {
    validate: v => !!getVideoIdFromURL(v) || 'Invalid YouTube link',
  })

  function removeEmbedVideo() {
    onChange('')
    setValue('fieldLink', '')
  }

  return (
    <div className="form-group">
      {!embedUrl ? (
        <form onSubmit={handleSubmit(addEmbedVideo)}>
          <div className="form-group">
            <div className="w-full flex items-end">
              <TextInput
                {...inputProps}
                autoComplete="off"
                error={errors.fieldLink?.message}
                onChange={(_, e) => onInputChange(e)}
                className="flex-1"
                label="Paste a YouTube link here"
                placeholder="e.g. https://youtube.com..."
              />
            </div>
          </div>
        </form>
      ) : (
        <div className="form-group relative flex items-center justify-center">
          <div className="absolute -top-4 -right-4 bg-white rounded-md shadow-sm">
            <ActionIcon
              variant="secondary"
              icon="times"
              size="sm"
              onClick={removeEmbedVideo}
            />
          </div>
          <iframe
            width="640"
            height="360"
            src={embedUrl}
            style={{ border: 'none' }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      )}
    </div>
  )
}
