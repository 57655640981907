import React from 'react'
import { useCss, k, a } from 'kremling'

import logoPrimary from '@assets/logo-primary.svg'
import logoWhite from '@assets/logo-white.svg'

export function Logo({ className = '', light = false }) {
  const scope = useCss(css)
  const logo = light ? logoWhite : logoPrimary
  return (
    <div
      {...scope}
      aria-label="iAct"
      className={a('logo').a(className)}
      dangerouslySetInnerHTML={{ __html: logo }}
    />
  )
}

const css = k`
  .logo {
    width: 5.4rem;
  }
`
