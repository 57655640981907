import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
import React, { useState } from 'react'
import {
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

import { EmptyState } from '@components/empty-state.component'
import { LayoutHeader } from '@components/layout.component'
import { Button } from '@components/mantine/button.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Tabs } from '@components/tabs.component'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { TemplateGenerator } from '@src/pages/workflow-template-list/template-generator/template-generator.component'
import { WorkflowTemplate } from '@src/pages/workflow/template/workflow-template.component'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { toastService } from '@utils/toast.service'
import { ArchivedTemplatesList } from './lists/archived-templates-list.component'
import { EditingTemplatesList } from './lists/editing-templates-list.component'
import { PublishedTemplatesList } from './lists/published-templates-list.component'

export function AdminLibrary() {
  const [searchVal, setSearchVal] = useState('')
  const user = useAppState(state => state.user)
  const location = useLocation()
  const { orgId } = useParams()
  const navigate = useNavigate()
  const isTemplateScreen = location.pathname.includes('/t/')
  const isPublishedList = location.pathname.includes('published')
  const isArchivedList = location.pathname.includes('archived')
  const templateGeneratorEnabled = process.env.OPENAI_TEMPLATES

  async function addTemplate() {
    const date = getTodayDate().toISO()

    if (templateGeneratorEnabled == true) {
      try {
        const templateSnapshot = await addDoc(
          collection(db, dbNames.workflowTemplates),
          {
            name: '',
            sections: [],
            orgId,
            assistant: true,
            currentVersionEditorIds: [user.id],
            editorIds: [user.id],
            authorId: user.id,
            createdDate: date,
            lastEditedDate: date,
            lastEditedById: user.id,
            archived: false,
          },
        )

        const d = doc(db, dbNames.assistantConversations, templateSnapshot.id)
        await setDoc(d, {
          orgId,
          createdDate: date,
          fields: {},
          selectedItems: {},
          lists: {},
          rawResponses: {},
        })
        navigate(`templates/t/template-generator/${templateSnapshot.id}`)
      } catch (err) {
        toastService.error(
          'There was an error creating a new template. Please try again.',
        )
      }
    } else {
      const date = getTodayDate().toISO()
      addDoc(collection(db, dbNames.workflowTemplates), {
        name: '',
        orgId,
        version: 0,
        isSequential: true,
        currentVersionEditorIds: [user.id],
        editorIds: [user.id],
        authorId: user.id,
        createdDate: date,
        lastEditedDate: date,
        lastEditedById: user.id,
        archived: false,
      })
        .then(template => {
          navigate(`templates/t/${template.id}`)
        })
        .catch(e => toastService.error(e.message))
    }
  }

  const routes = (
    <Routes>
      <Route
        path="editing"
        element={
          <EditingTemplatesList
            searchVal={searchVal}
            renderEmptyState={() => (
              <EmptyState
                title="No templates"
                subtitle="Create a template to get started"
                action={addTemplate}
                actionText="New template"
              />
            )}
          />
        }
      />
      <Route
        path="archived"
        element={
          <ArchivedTemplatesList
            searchVal={searchVal}
            renderEmptyState={() => (
              <EmptyState title="No archived templates" />
            )}
          />
        }
      />
      <Route
        path="templates/t/:templateId"
        element={<WorkflowTemplate />}
      />
      <Route
        path="archived/t/:templateId"
        element={<WorkflowTemplate />}
      />
      <Route
        path="published/t/:templateId"
        element={<WorkflowTemplate type="preview" />}
      />
      <Route
        path="published/marketplace/t/:templateId"
        element={<WorkflowTemplate type="adopted-marketplace-preview" />}
      />
      {templateGeneratorEnabled && (
        <Route
          path="templates/t/template-generator/:assistantId"
          element={<TemplateGenerator />}
        />
      )}
      <Route
        path="published"
        element={
          <PublishedTemplatesList
            searchVal={searchVal}
            renderEmptyState={() => (
              <EmptyState
                title="No published templates"
                subtitle={
                  <div>
                    To publish, select or create a template
                    <Link
                      className="btn --secondary ml-2 mr-2"
                      to={`/${orgId}/library/templates`}
                    >
                      Here
                    </Link>
                    and click "Publish template"
                  </div>
                }
              />
            )}
          />
        }
      />
      <Route
        path=""
        element={<Navigate to="templates" />}
      />
    </Routes>
  )

  if (isTemplateScreen) {
    return routes
  }

  return (
    <div className="iact-container --sm">
      <LayoutHeader
        className="pt-8"
        title={
          <Tabs>
            <Tabs.Link to="./templates">Templates</Tabs.Link>
            <Tabs.Link to="./published">Published</Tabs.Link>
            <Tabs.Link to="./archived">Archived</Tabs.Link>
          </Tabs>
        }
        search={
          <TextInput
            defaultValue={searchVal}
            onChange={setSearchVal}
            placeholder="Search Templates..."
            icon="search"
            allowClear
          />
        }
        actions={
          !isPublishedList &&
          !isArchivedList && (
            <Button
              variant="secondary"
              onClick={addTemplate}
            >
              New course
            </Button>
          )
        }
      />
      <Outlet
        searchVal={searchVal}
        context={{ addTemplate }}
      />
    </div>
  )
}
