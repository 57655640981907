import { Button } from '@components/mantine/button.component'
import { Modal } from '@components/modal.component'
import { Well } from '@components/well.component'
import React from 'react'

export function ActivityDescriptionModal({ onReject, activity }) {
  return (
    <>
      <Modal.Header>{activity.name}</Modal.Header>
      <Modal.Body>
        <Well className="p-3">
          <div dangerouslySetInnerHTML={{ __html: activity.description }} />
        </Well>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={onReject}
        >
          Get started!
        </Button>
      </Modal.Footer>
    </>
  )
}
