import { array, bool, func, object, oneOfType, string } from 'prop-types'
import React, { forwardRef } from 'react'

import { dropdownPropTypes } from '@components/dropdown.component'
import { defaultSearchFilter, filterAlpha } from './select/common/utils'
import { Select } from './select/select.component'

export const SelectSingle = forwardRef((props, ref) => {
  const {
    className = '',
    clearable,
    contentWidth,
    data,
    disabled,
    fixedContent,
    isGroupData,
    loading,
    onChange,
    onBlur = () => {},
    onClose,
    onOpen,
    placeholder = 'Select',
    position,
    renderFooter,
    renderTrigger,
    searchFilter,
    searchOnChange,
    searchValue,
    style = {},
    title,
    transformData,
    triggerIsBlock,
    value,
  } = props

  return (
    <Select
      className={className}
      clearable={clearable}
      contentWidth={contentWidth}
      data={data}
      disabled={disabled}
      fixedContent={fixedContent}
      insertSearch={!!searchOnChange}
      isGroupData={isGroupData}
      position={position}
      loading={loading}
      onChange={onChange}
      onClose={onClose}
      onOpen={onOpen}
      renderFooter={renderFooter}
      renderGroup={Select.Group}
      renderItem={Select.ItemSingle}
      renderTrigger={
        renderTrigger ||
        (triggerProps => (
          <Select.TriggerSingle
            ref={ref}
            {...triggerProps}
            triggerIsBlock={triggerIsBlock}
            placeholder={placeholder}
            onBlur={onBlur}
            title={title}
          />
        ))
      }
      searchFilter={defaultSearchFilter(searchFilter, searchValue)}
      searchOnChange={searchOnChange}
      searchValue={searchValue}
      style={style}
      transformData={transformData}
      triggerIsBlock={triggerIsBlock}
      value={value}
    />
  )
})

SelectSingle.propTypes = {
  clearable: bool,
  contentWidth: dropdownPropTypes.contentWidth,
  data: array,
  disabled: bool,
  fixedContent: dropdownPropTypes.fixedContent,
  isGroupData: bool,
  loading: bool,
  onChange: func.isRequired,
  onBlur: func,
  onClose: func,
  onOpen: func,
  placeholder: string,
  position: dropdownPropTypes.position,
  renderFooter: Select.propTypes.renderFooter,
  renderTrigger: Select.propTypes.renderTrigger,
  searchFilter: Select.propTypes.searchFilter,
  searchOnChange: func,
  searchValue: string,
  title: string,
  transformData: func,
  triggerIsBlock: bool,
  value: oneOfType([array, object, string]),
}

// utils
SelectSingle.filterAlpha = filterAlpha
