import { Dropdown } from '@components/dropdown.component'
import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { Button } from '@components/mantine/button.component'
import { Checkbox } from '@components/mantine/checkbox.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Pill } from '@components/pill/pill.component'
import { WorkflowProgressBar } from '@components/workflow-progress-bar.component'
import { useMediaQuery } from '@hooks/use-media-query.hook'
import { functions } from '@src/firebase-app'
import {
  ColumnDef,
  RowSelectionState,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { toastService } from '@utils/toast.service'
import { httpsCallable } from 'firebase/functions'
import { a, k, useCss } from 'kremling'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import React, { useMemo, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

type ParticipantsTableProps = {
  data: any[]
  activity: Activity
}

type Participant = {
  activity_id: string
  user_id: string
  first_name: string
  last_name: string
  last_edit_date: string
  activity_completed_percentage: string
  status: 'active' | 'inactive' | 'not-started'
  email: string
  momentum: 'up' | 'down' | 'static'
}

const columnHelper = createColumnHelper<Participant>()

export function ParticipantsTable({ data, activity }: ParticipantsTableProps) {
  const scope = useCss(css)
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const [sorting, setSorting] = useState<SortingState>()
  const [sendingNudge, setSendingNudge] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const { orgId } = useParams()
  const isDesktop = useMediaQuery('media3Width')
  const location = useLocation()

  const columns = useMemo<ColumnDef<Participant>[]>(
    () => [
      {
        id: 'select',
        enableSorting: false,
        enableGlobalFilter: false,
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      columnHelper.accessor(row => `${row.first_name} ${row.last_name}`, {
        id: 'participant',
        header: 'Participant',
        cell: ({ getValue, row }) => (
          <div>
            <Link
              to={`/${orgId}/mentoring/${row.original.user_id}/${row.original.activity_id}`}
              className="participant-name"
              state={{ from: location.pathname }}
            >
              {getValue()}
            </Link>
            <div className="participant-email">{row.original.email}</div>
          </div>
        ),
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: ({ row, getValue }) => (
          <div className="status-cell">
            <div>
              {getValue() === 'active' ? (
                <Pill pillType="primary">Active</Pill>
              ) : getValue() === 'not-started' ? (
                <Pill pillType="danger">Not started</Pill>
              ) : (
                <Pill pillType="warning">Inactive</Pill>
              )}
            </div>
            <div>
              {DateTime.fromISO(row.original.last_edit_date).toRelative()}
            </div>
          </div>
        ),
      }),
      // columnHelper.accessor('momentum', {
      //   header: 'Momentum',
      //   cell: ({getValue}) => (
      //     getValue() === 'up'
      //       ? <Pill pillType="primary">Increase</Pill>
      //       : getValue() === 'down'
      //         ? <Pill pillType="danger">Decrease</Pill>
      //         : <Pill pillType="warning">Static</Pill>
      //   )
      // }),
      columnHelper.accessor('activity_completed_percentage', {
        header: 'Progress',
        enableGlobalFilter: false,
        cell: ({ getValue }) => (
          <div style={{ width: 127 }}>
            <WorkflowProgressBar
              size="sm"
              activity={activity}
              activityUser={{ completedPercentage: getValue() }}
            />
          </div>
        ),
      }),
      {
        id: 'actions',
        enableGlobalFilter: false,
        header: () => (
          //hiding this for now because we don't need it, but if I remove it, it messes up the column sizing
          <div style={{ visibility: 'hidden' }}>
            <Dropdown
              position="bottom-left"
              renderTrigger={({ toggle }: { toggle: () => void }) => (
                <ActionIcon
                  size="sm"
                  onClick={toggle}
                  icon="ellipsis-v"
                />
              )}
              renderContent={({ close }: { close: () => void }) => (
                <div className="select-list">
                  <button
                    onClick={() => {
                      //send nudges to everyone
                      close()
                    }}
                  >
                    Nudge all
                  </button>
                </div>
              )}
            />
          </div>
        ),
        cell: ({ row }) => (
          <Dropdown
            position="bottom-left"
            renderTrigger={({ toggle }: { toggle: () => void }) => (
              <ActionIcon
                size="sm"
                onClick={toggle}
                icon="ellipsis-v"
              />
            )}
            renderContent={({ close }: { close: () => void }) => (
              <div className="select-list">
                <button
                  onClick={() => {
                    sendNudge([row])
                    close()
                  }}
                >
                  Nudge {row.original.first_name}
                </button>
              </div>
            )}
          />
        ),
      },
    ],
    [],
  )

  const mobileColumns = useMemo<ColumnDef<Participant>[]>(
    () => [
      {
        id: 'select',
        enableSorting: false,
        enableGlobalFilter: false,
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      columnHelper.accessor(row => `${row.first_name} ${row.last_name}`, {
        id: 'participant',
        header: 'Participant',
        cell: ({ getValue, row }) => (
          <div>
            <Link
              to={`/${orgId}/mentoring/${row.original.user_id}/${row.original.activity_id}`}
              className="participant-name"
            >
              {getValue()}
            </Link>
            <div className="participant-email">{row.original.email}</div>
          </div>
        ),
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        cell: ({ row, getValue }) => (
          <div className="status-cell">
            <div>
              {getValue() === 'active' ? (
                <Pill pillType="primary">Active</Pill>
              ) : getValue() === 'not-started' ? (
                <Pill pillType="danger">Not started</Pill>
              ) : (
                <Pill pillType="warning">Inactive</Pill>
              )}
            </div>
            <div className="media-show-1">
              {DateTime.fromISO(row.original.last_edit_date).toRelative({
                style: 'short',
              })}
            </div>
          </div>
        ),
      }),
      // columnHelper.accessor('momentum', {
      //   header: 'Momentum',
      //   cell: ({getValue}) => (
      //     getValue() === 'up'
      //       ? <Pill pillType="primary">Increase</Pill>
      //       : getValue() === 'down'
      //         ? <Pill pillType="danger">Decrease</Pill>
      //         : <Pill pillType="warning">Static</Pill>
      //   )
      // }),
      // columnHelper.accessor('activity_completed_percentage',  {
      //   header: 'Progress',
      //   enableGlobalFilter: false,
      //   cell: ({getValue}) => (
      //     <div style={{width: 127}}>
      //       <WorkflowProgressBar
      //         size="sm"
      //         activity={activity}
      //         activityUser={{completedPercentage: getValue()}}
      //       />
      //     </div>
      //   )
      // }),
      {
        id: 'actions',
        enableGlobalFilter: false,
        header: () => (
          //hiding this for now because we don't need it, but if I remove it, it messes up the column sizing
          <div style={{ visibility: 'hidden' }}>
            <Dropdown
              position="bottom-left"
              renderTrigger={({ toggle }: { toggle: () => void }) => (
                <ActionIcon
                  size="sm"
                  onClick={toggle}
                  icon="ellipsis-v"
                />
              )}
              renderContent={({ close }: { close: () => void }) => (
                <div className="select-list">
                  <button
                    onClick={() => {
                      //send nudges to everyone
                      close()
                    }}
                  >
                    Nudge all
                  </button>
                </div>
              )}
            />
          </div>
        ),
        cell: ({ row }) => (
          <Dropdown
            position="bottom-left"
            renderTrigger={({ toggle }: { toggle: () => void }) => (
              <ActionIcon
                size="sm"
                onClick={toggle}
                icon="ellipsis-v"
              />
            )}
            renderContent={({ close }: { close: () => void }) => (
              <div className="select-list">
                <button
                  onClick={() => {
                    sendNudge([row])
                    close()
                  }}
                >
                  Nudge {row.original.first_name}
                </button>
              </div>
            )}
          />
        ),
      },
    ],
    [],
  )

  const table = useReactTable({
    data,
    columns: isDesktop ? columns : mobileColumns,
    state: {
      rowSelection,
      sorting,
      globalFilter: search,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setSearch,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  function sendNudge(rows: { original: Participant }[]) {
    if (isEmpty(rows)) return
    setSendingNudge(true)
    const usersData = rows.reduce((accum, row) => {
      const { user_id, first_name, last_name } = row.original
      return [
        ...accum,
        {
          id: user_id,
          firstName: first_name,
          lastName: last_name,
          redirectPath: `${orgId}/mentoring/${user_id}/${activity.id}`,
          email: row.original.email,
        },
      ]
    }, [])
    httpsCallable(
      functions,
      'activityFunctions-sendNudge',
    )({
      usersData,
      activityName: activity.name,
    }).then(() => {
      setSendingNudge(false)
      toastService.info('Nudge sent')
    })
  }

  const rowSelectionValues = Object.values(rowSelection)

  return (
    <div {...scope}>
      <div className="actions__container">
        <div className="selected-actions">
          {!isEmpty(rowSelectionValues) && (
            <Button
              loading={sendingNudge}
              variant="secondary"
              onClick={() => {
                const { rows } = table.getSelectedRowModel()
                sendNudge(rows)
              }}
            >
              Nudge ({rowSelectionValues.length})
            </Button>
          )}
        </div>
        <div className="filter-actions">
          {showSearch ? (
            <TextInput
              icon="search"
              autoFocus
              onChange={setSearch}
              alwaysShowCancel
              onCancel={() => {
                setShowSearch(false)
                setSearch('')
              }}
              allowClear
            />
          ) : (
            <ActionIcon
              icon="search"
              size="sm"
              onClick={() => setShowSearch(true)}
            />
          )}
        </div>
      </div>
      <div
        className="participant-table__table"
        role="table"
      >
        <div
          className="participant-table__thead"
          role="rowgroup"
        >
          {table.getHeaderGroups().map(headerGroup => (
            <div
              className="participant-table__tr"
              role="row"
              key={headerGroup.id}
            >
              {headerGroup.headers.map(header => {
                return (
                  <div
                    role="columnheader"
                    key={header.id}
                    className={a('participant-table__th').m(
                      '--sortable',
                      header.column.getCanSort(),
                    )}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    {
                      {
                        asc: (
                          <Icon
                            name="caret-up"
                            size={14}
                          />
                        ),
                        desc: (
                          <Icon
                            name="caret-down"
                            size={14}
                          />
                        ),
                      }[header.column.getIsSorted() as string]
                    }
                  </div>
                )
              })}
            </div>
          ))}
        </div>
        <div
          role="rowgroup"
          className="participant-table__tbody"
        >
          {table.getRowModel().rows.map(row => (
            <div
              role="row"
              key={row.id}
              className={a('participant-table__tr').m(
                '--selected',
                row.getIsSelected(),
              )}
            >
              {row.getVisibleCells().map((cell, i) => {
                const rightBorder = i === 1
                return (
                  <div
                    className={a('participant-table__td').m(
                      '--borderRight',
                      rightBorder,
                    )}
                    key={cell.id}
                    role="cell"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                )
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const css = k`
  .actions__container {
    display: flex;
    justify-content: space-between;
    margin: 0 .8rem;
    min-height: 3.6rem;
  }

  .filter-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .participant-table__table {
    width: 100%;
  }

  .participant-table__th {
    padding: .8rem;
    padding-bottom: .4rem;
    justify-content: space-between;
    font-weight: 700;
    font-size: 1.2rem;

    &.--sortable {
      cursor: pointer;
    }
  }

  .participant-table__tr {
    display: flex;
  }

  .participant-table__td {
    padding: .8rem;

    &.--borderRight {
      border-right: solid 1px $color-grey-50;
    }
  }

  .participant-table__th,
  .participant-table__td {
    display: flex;
    align-items: center;
    border-bottom: solid 1px $color-grey-50;
    flex: 10;

    &:nth-child(2) {
      &, & > * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:first-child {
      justify-content: center;
    }

    &:first-child,
    &:last-child {
      flex: 1;
      border-bottom: none;
      flex-shrink: 0;
      font-size: inherit;
    }
  }

  .participant-name {
    font-weight: 700;
  }

  .participant-email {
    font-size: 1.2rem;
  }

  .status-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`
