import convert from 'convert-units'
import { ulid } from 'ulid'

//Field constants
const TEXT = 'Text'
const DESCRIPTION = 'Description'
const DATE = 'Date'
const RADIO = 'Radio'
const CHECKBOX = 'Checkbox'
const YOUTUBE = 'YouTube'
const LINK = 'Link'
const FILE_UPLOAD = 'File Upload'
const SELECT = 'Select Box'

export const FIELD_CONSTANTS = {
  TEXT,
  DESCRIPTION,
  DATE,
  RADIO,
  CHECKBOX,
  YOUTUBE,
  LINK,
  FILE_UPLOAD,
  SELECT,
}

export type FieldConst = {
  name: string
  icon: string
  isCompletable: boolean
  default?: '' | any[] | boolean
}

export const fieldsList: FieldConst[] = [
  { name: TEXT, icon: 'text', isCompletable: true, default: '' },
  { name: DESCRIPTION, icon: 'align-left', isCompletable: false },
  { name: DATE, icon: 'calendar-alt', isCompletable: true, default: null },
  { name: RADIO, icon: 'dot-circle', isCompletable: true, default: '' },
  { name: CHECKBOX, icon: 'check-square', isCompletable: true, default: [] },
  { name: YOUTUBE, icon: 'youtube', isCompletable: true, default: false },
  { name: LINK, icon: 'link', isCompletable: true, default: false },
  { name: FILE_UPLOAD, icon: 'file-upload', isCompletable: true, default: [] },
  { name: SELECT, icon: 'dropdown', isCompletable: true, default: [] },
]

export const nonCompletableFields: string[] = fieldsList
  .filter(({ isCompletable }) => Boolean(!isCompletable))
  .map(({ name }) => name)

export const completableFields: FieldConst[] = fieldsList.filter(
  ({ isCompletable }) => Boolean(isCompletable),
)

//Text type constants
const SHORT = 'short'
const LONG = 'long'
const NUMBER = 'number'
const DISTANCE = 'distance'
const DURATION = 'duration'
const PERCENTAGE = 'percentage'

export const TEXT_TYPE_CONSTANTS = {
  SHORT,
  LONG,
  NUMBER,
  DURATION,
  DISTANCE,
  PERCENTAGE,
}

export const textTypeOptions = [
  { label: 'Short text (single line)', value: SHORT },
  { label: 'Long text (multiline)', value: LONG },
  { label: 'Number', value: NUMBER },
  { label: 'Distance', value: DISTANCE },
  { label: 'Duration', value: DURATION },
  { label: 'Percentage', value: PERCENTAGE },
]

export function isNumericField(field: Field) {
  switch (field.textType) {
    case NUMBER:
    case DURATION:
    case DISTANCE:
    case PERCENTAGE:
      return true
    default:
      return false
  }
}

//Length units
export const lengthOptions = convert()
  .list('length')
  .filter(lengthUnit => {
    return lengthUnit.abbr !== 'ft-us'
  })
  .map(lengthUnit => ({
    name: lengthUnit.plural,
    id: lengthUnit.abbr,
  }))

export function generateNewFieldData(field: FieldConst, version: number) {
  const id = ulid()
  const basicField = {
    id,
    type: field.name,
    label: '',
    description: '',
    version,
  }

  switch (field.name) {
    case TEXT:
      return {
        ...basicField,
        textType: 'short',
        placeholder: '',
      }
    case DESCRIPTION:
      return {
        id,
        type: field.name,
        value: '',
        label: '',
      }
    case RADIO:
      return {
        ...basicField,
        choices: [{ label: '', id: ulid() }],
      }
    case CHECKBOX:
      return {
        ...basicField,
        choices: [{ label: '', id: ulid() }],
      }
    case DATE:
      return {
        ...basicField,
      }
    case YOUTUBE:
      return {
        ...basicField,
        link: '',
        played: false,
      }
    case LINK:
      return {
        ...basicField,
        link: '',
      }
    case FILE_UPLOAD:
      return {
        ...basicField,
      }
    case SELECT:
      return {
        ...basicField,
        choices: [{ label: '', id: ulid() }],
        multiselect: false,
      }
  }
}

type OpenAIField = {
  fieldType: string
  textType?: string
  fieldLabel: string
  fieldDescription?: string
  choices?: { label: string }[]
}

export function generateNewFieldFromOpenai({
  fieldType,
  textType,
  fieldLabel,
  fieldDescription = '',
  choices,
}: OpenAIField) {
  const id = ulid()
  const basicField = {
    id,
    type: fieldType,
    label: fieldLabel,
    description: fieldDescription,
  }

  switch (fieldType) {
    case TEXT:
      return {
        ...basicField,
        textType,
        //TODO need to handle placeholder text
        placeholder: '',
      }
    case RADIO:
      return {
        ...basicField,
        choices: choices.map(({ label }) => ({ label, id: ulid() })),
      }
    case CHECKBOX:
      return {
        ...basicField,
        choices: choices.map(({ label }) => ({ label, id: ulid() })),
      }
    case DATE:
      return { ...basicField }
    case FILE_UPLOAD:
      return { ...basicField }
    case SELECT:
      //TODO need to handle multiselect
      return {
        ...basicField,
        choices: choices.map(({ label }) => ({ label, id: ulid() })),
        multiselect: false,
      }
  }
}

export function getVideoIdFromURL(url: string) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)

  return match && match[2].length === 11 ? match[2] : null
}

export function generateEmbedLink(videoId: string) {
  if (!videoId) return
  return `https://www.youtube.com/embed/${videoId}?rel=0`
}
