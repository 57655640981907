import { a, k, useCss } from 'kremling'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import React, { forwardRef } from 'react'
import Calendar from 'react-calendar'

import { Dropdown } from '@components/dropdown.component'
import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { Button } from '@components/mantine/button.component'
import styles from '@components/select/select.kremling.scss'

export const DatePicker = forwardRef((props, ref) => {
  const {
    contentWidth,
    onClear,
    onReset,
    disabled,
    minDate,
    maxDate,
    onChange,
    placeholder,
    triggerIsBlock,
    selectRange,
    onFocus,
    onBlur,
    value,
    fixedContent,
    error,
    ...rest
  } = props
  const triggerScope = useCss(styles)
  const scope = useCss(css)

  function renderLabel() {
    if (!value) return placeholder
    if (selectRange) {
      if (isEmpty(value.filter(Boolean))) return placeholder
      const [start, end] = value
      return `${start.toFormat('DD')} – ${end.toFormat('DD')}`
    }
    return value.toFormat('DD')
  }

  function handleReset() {
    onReset()
  }

  return (
    <>
      <Dropdown
        contentWidth={contentWidth || 300}
        ref={ref}
        allowContentClicks
        disabled={disabled}
        fixedContent={fixedContent}
        renderTrigger={({ toggle, close }) => (
          <div
            {...scope}
            className={a('date-parent').m('date-parent--block', triggerIsBlock)}
          >
            <button
              {...triggerScope}
              className={a('btn trigger-single')
                .m('trigger-single--placeholder', !value)
                .m('w-full block', triggerIsBlock)}
              onClick={e => {
                e.preventDefault()
                toggle()
              }}
              ref={ref}
              disabled={disabled}
              onBlur={onBlur}
              onFocus={onFocus}
            >
              {renderLabel()}
              {!value && !!onClear && (
                <Icon
                  name="caret-down"
                  size={14}
                />
              )}
            </button>
            {!!value && !!onClear && (
              <ActionIcon
                className="date-clear"
                icon="times"
                size="sm"
                disabled={disabled}
                onClick={() => {
                  onClear()
                  close()
                }}
              />
            )}
          </div>
        )}
        renderContent={({ close }) => (
          <div {...scope}>
            <Calendar
              value={
                selectRange
                  ? value?.map?.(date => (date ? date.toJSDate() : null)) ||
                    null
                  : value?.toJSDate?.() || null
              }
              minDate={minDate?.toJSDate()}
              maxDate={maxDate?.toJSDate()}
              selectRange={selectRange}
              onChange={v => {
                if (selectRange) {
                  onChange(v.map(date => DateTime.fromJSDate(date)))
                } else {
                  onChange(DateTime.fromJSDate(v))
                  close()
                }
              }}
              {...rest}
            />
            {selectRange && (
              <div className="range-actions">
                {onReset && (
                  <Button
                    variant="secondary"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                )}
                <Button
                  className="ml-2"
                  variant="primary"
                  onClick={close}
                >
                  Done
                </Button>
              </div>
            )}
          </div>
        )}
        triggerIsBlock={triggerIsBlock}
      />
      {error && <div className="mt-1 text-xl text-danger">{error}</div>}
    </>
  )
})

const css = k`
  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    padding: .8rem;
    color: black;

    &--doubleView {
      width: 700px;

      .react-calendar__viewContainer {
        display: flex;
        margin: -.5em;

        > * {
          width: 50%;
          margin: .5rem;
        }
      }
    }

    &, & *, & *:before, & *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    button {
      margin: 0;
      border: 0;
      outline: none;
      // border-radius: $base-border-radius;
      z-index: 0;

      &.react-calendar__tile--active {

      }

      &:enabled {
        &:hover {
          cursor: pointer;
        }
      }
      &:focus {
        box-shadow: $focus-box-shadow;
        z-index: 1;
      }
    }

    &__navigation {
      height: 44px;
      margin-bottom: 1em;
      display: flex;

      button {
        min-width: 38px;
        background: none;

        &:enabled {
          &:hover {
            background-color: $color-active-100;
          }
        }

        &[disabled] {
          cursor: default;
        }
      }
    }

    &__month-view {
      &__weekdays {
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: .75em;

        &__weekday {
          padding: .5em;
        }
      }

      &__weekNumbers {
        font-weight: bold;

        .react-calendar__tile {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: .75em;
          padding: calc(.75em / .75) calc(.5em / .75);
        }
      }

      &__days {
        &__day {
          &--weekend {
          }

          &--neighboringMonth {
            //color: rgb(117, 117, 117)
          }
        }
      }
    }

    &__year-view,
    &__decade-view,
    &__century-view {
      .react-calendar__tile {
        padding: 2em .5em;
      }
    }

    &__tile {
      max-width: 100%;
      text-align: center;
      padding: .75em .5em;
      background: none;

      &:disabled {
        cursor: default;
      }

      &:enabled {
        &:hover {
          background-color: $color-active-100;
        }
      }

      &--now {
        font-weight: 800;
        background-color: $color-grey-50;
      }

      &--hasActive {
        background: $color-primary;
        color: #fff;

        &:enabled {
          &:hover {
            background: darken($color-primary, 10%);
          }
        }
      }

      &--active {
        background: $color-primary;
        color: white;

        &:enabled {
          &:hover {
            background: darken($color-primary, 10%);
          }
        }
      }
    }

    &--selectRange {
      .react-calendar__tile {
        &--hover {
          background-color: $color-active-100;
        }
      }
    }
  }

  .date-parent {
    position: relative;
    display: inline-flex;
    justify-content: space-between;

    &.date-parent--block {
      display: block;
    }
  }

  .date-clear {
    position: absolute;
    top: .3rem;
    right: .3rem;
    border-radius: .6rem;
  }

  .range-actions {
    display: flex;
    justify-content: flex-end;
    padding: 8px;
  }
`
