import {
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import React, { useEffect, useState } from 'react'

import { Button } from '@components/mantine/button.component'
import { Modal } from '@components/modal.component'
import { Required } from '@components/required.component'
import { SelectSingle } from '@components/select-single.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import {
  getTodayDate,
  getUserFullName,
  uniqueIdentifierFromDate,
} from '@utils/helpers'
import { toastService } from '@utils/toast.service'

export function ShareTemplateModal({ onReject, onResolve, template }) {
  const user = useAppState(state => state.user)
  const [selectedOrg, setSelectedOrg] = useState(null)
  const [newAuthor, setNewAuthor] = useState(null)
  const [sharing, setSharing] = useState(false)

  const [orgs, _setOrgs, orgOpts] = useLoad(
    [],
    () => {
      if (!user) return new Promise(resolve => resolve([]))
      const q = query(collection(db, dbNames.organizations), orderBy('name'))
      return getDocs(q).then(q => q.docs.map(d => ({ ...d.data(), id: d.id })))
    },
    [user],
  )

  const [owners, _setOwners, ownersOpts] = useLoad(
    [],
    () => {
      if (!selectedOrg) return new Promise(resolve => resolve([]))
      if (selectedOrg.id === 'super') {
        const q = query(
          collection(db, dbNames.users),
          where(`isSuperAdmin`, '==', true),
        )
        getDocs(q).then(q => q.docs.map(d => ({ ...d.data(), id: d.id })))
      }
      const q = query(
        collection(db, dbNames.users),
        where(`orgRoles.${selectedOrg.id}`, '==', 'owner'),
      )
      return getDocs(q).then(q => q.docs.map(d => ({ ...d.data(), id: d.id })))
    },
    [selectedOrg],
  )

  useEffect(() => {
    if (!owners.length) return
    if (owners.length === 1) {
      setNewAuthor(owners[0])
    }
  }, [owners])

  async function handleShare() {
    const { id, ...rest } = template
    const newTemplate = {
      ...rest,
      editorIds: [],
      authorId: newAuthor.id,
      orgId: selectedOrg.id,
      lastEditedById: '',
      lastEditedDate: '',
      lastPublishedDate: '',
      sharedFromTemplateId: id,
      sharedFromOrgId: template.orgId,
      createdDate: getTodayDate().toISO(),
    }

    setSharing(true)
    try {
      await addDoc(collection(db, dbNames.workflowTemplates), newTemplate)
      onResolve()
    } catch (err) {
      setSharing(false)
      toastService.error('Error sharing template')
    }
  }

  function handleOrgSelect(org) {
    setSelectedOrg(org)
    setNewAuthor(null)
  }

  return (
    <Modal onClose={onReject}>
      <Modal.Header>Send a copy</Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="select-group">
            Select group <Required />
          </label>
          <SelectSingle
            onChange={handleOrgSelect}
            data={[{ name: 'Super Users', id: 'super' }, ...orgs]}
            id="select-group"
            value={selectedOrg}
            triggerIsBlock
            loading={orgOpts.loading}
            transformData={({ id, name, ...rest }) => ({
              ...rest,
              id,
              name: `${name} #${uniqueIdentifierFromDate(rest.createdDate)}`,
            })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="assign-author">
            Assign new author <Required />
          </label>
          <SelectSingle
            onChange={setNewAuthor}
            data={
              !!owners.find(owner => owner.id === template.authorId)
                ? owners
                : [
                    {
                      firstName: 'Original',
                      lastName: 'author',
                      id: template.authorId,
                    },
                    ...owners,
                  ]
            }
            value={newAuthor}
            disabled={!selectedOrg}
            id="assign-author"
            triggerIsBlock
            loading={ownersOpts.loading}
            transformData={({ id, ...rest }) => ({
              id,
              name: getUserFullName(rest),
            })}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onReject}>Cancel</Button>
        <Button
          onClick={handleShare}
          variant="primary"
          disabled={!selectedOrg || !newAuthor}
          loading={sharing}
        >
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
