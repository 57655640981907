import { Icon } from '@components/icon.component'
import {
  ActionIcon as MantineActionIcon,
  ActionIconProps as MantineActionIconProps,
} from '@mantine/core'
import React, { forwardRef } from 'react'

//Currently does not support size prop
type ButtonProps = {
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'tertiary-dark'
    | 'danger'
    | 'input'
  icon: Icons
  size: 'xs' | 'sm' | 'lg'
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  iconClass?: string
} & MantineActionIconProps

export const ActionIcon = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = 'tertiary', iconClass = '', size, icon, ...btnProps }, ref) => {
    const getVariantClass = () => {
      if (variant === 'primary') return '--primary'
      if (variant === 'secondary') return '--secondary'
      if (variant === 'tertiary') return '--tertiary'
      if (variant === 'danger') return '--danger'
      if (variant === 'tertiary-dark') return '--tertiary-dark'
      if (variant === 'input') return '--input'
    }

    const getSizeClass = () => {
      if (size === 'xs') return '--very-small'
      if (size === 'sm') return '--small'
      if (size === 'lg') return '--large'
    }

    return (
      <MantineActionIcon
        {...btnProps}
        ref={ref}
        classNames={{
          root: `${getVariantClass()} ${getSizeClass()}`,
        }}
        loaderProps={{
          color: 'var(--color-primary)',
          type: 'dots',
          size,
        }}
        size={size}
        radius={4}
      >
        <Icon
          name={icon}
          className={iconClass}
          size={size === 'sm' ? 18 : size === 'xs' ? 14 : 22}
        />
      </MantineActionIcon>
    )
  },
)
