import React from 'react'
import { useParams } from 'react-router-dom'

import { CardListItem } from '@components/card-list-item.component'
import { Pill } from '@components/pill/pill.component'
import { useAppState } from '@src/app.state'
import { getUserFullName } from '@utils/helpers'

export function UserCard({ user }) {
  const me = useAppState(state => state.user)
  const { orgId } = useParams()

  return (
    <CardListItem
      className={`${user.orgRoles[orgId] === 'deactivated' ? 'opacity-50' : ''} mb-4`}
      href={user.id}
      icon="user-circle"
      iconClass={
        user.orgRoles[orgId] === 'deactivated'
          ? 'text-gray-500'
          : 'text-primary'
      }
      cardTitle={
        <>
          {getUserFullName(user)}
          {user.id === me.id && <Pill className="ml-2">You</Pill>}
        </>
      }
      renderRightContent={() => (
        <span className="label-pill">{user.orgRoles[orgId]}</span>
      )}
    />
  )
}
