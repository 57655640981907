import React from 'react'
import { useCss, k, a } from 'kremling'
import { Link } from 'react-router-dom'
import { isNil } from 'lodash'

import { Card } from '@components/card.component'
import { Icon } from '@components/icon.component'
import { Tooltip } from '@components/tooltip.component'

export function MarketplaceTemplateCard({
  template,
  href,
  onView = () => {},
  className,
}) {
  const scoped = useCss(css)

  return (
    <Card
      className={a('template__card').a(className)}
      {...scoped}
    >
      <div className="template__top">
        <div className="template__title">{template.name}</div>
        <div className="template__description">{template.description}</div>
      </div>
      <div className="template__bottom">
        <div className="template__stats">
          {!isNil(template.sectionsCount) && (
            <TemplateStat
              icon="list-radio"
              text={template.sectionsCount}
              tooltip={`${template.sectionsCount}
                  section${
                    template.sectionsCount > 1 || template.sectionsCount === 0
                      ? 's'
                      : ''
                  }`}
            />
          )}
          {template.recurrence && (
            <TemplateStat
              icon="repeat"
              tooltip="Has recurrence"
            />
          )}
        </div>
        <Link
          onClick={onView}
          className="btn --tertiary --small"
          to={href || `/marketplace/templates/${template.id}`}
        >
          View
        </Link>
      </div>
    </Card>
  )
}

function TemplateStat({ text = '', icon, tooltip }) {
  return (
    <div className="space-x-3">
      <Tooltip
        content={tooltip}
        placement="bottom"
      >
        <div className="template-stat__info">
          <Icon
            className="stat-icon"
            name={icon}
            size={18}
          />
          {!isNil(text) && <div className="ml-1">{text}</div>}
        </div>
      </Tooltip>
    </div>
  )
}

const css = k`
  .template__card {
    min-height: 95px;
    display: flex;
    flex-direction: column;
  }

  .template__top {
    padding: 8px;
    min-height: 80px;
    flex: 1;
  }

  .template__title {
    font-weight: 700;
    font-size: 1.6rem;
    color: black;
  }

  .template__description {
    font-size: 1.2rem;
  }

  .template__bottom {
    padding: 8px;
    background: rgba($color-primary, .05);
    display: flex;
    justify-content: space-between;
  }

  .template__stats {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .template__stat {
    margin-right: 12px;
    user-select: none;
  }

  .template-stat__info {
    display: flex;
    align-items: center;
    color: black;
    font-weight: 700;
    line-height: 18px;
  }

  .stat-icon {
    color: $color-grey-600;
  }
`
