import { Button } from '@components/mantine/button.component'
import { auth } from '@src/firebase-app'
import { getErrorMessage } from '@utils/form.utils'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

type ReAuthFormProps = {
  setReauthenticated: (data: any) => void
  setReauth: (email: string, password: string) => Promise<void>
}

export function ReAuthForm({ setReauth }: ReAuthFormProps) {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      password: '',
    },
  })

  function reAuthenticateUser({ password }: { password: string }) {
    const email = auth.currentUser.email
    setReauth(email, password)
  }

  return (
    <form onSubmit={handleSubmit(reAuthenticateUser)}>
      <div className="password-label">
        <label htmlFor="password">Password</label>
        <Link to="/reset-password">Forgot password?</Link>
      </div>
      <input
        id="password"
        type="password"
        className="form-input"
        {...register('password', {
          minLength: {
            value: 6,
            message: 'Password must have at least 6 characters',
          },
        })}
      />
      <div className="mt-4 has-warning">{getErrorMessage(errors.password)}</div>
      <Button
        type="submit"
        variant="secondary"
        className="mt-5"
        loading={isSubmitting}
      >
        Submit
      </Button>
    </form>
  )
}
