import { InputField } from '@components/input'
import { Loader } from '@components/loader.component'
import { Button } from '@components/mantine/button.component'
import { PinInput } from '@components/mantine/pin-input.component'
import { Modal } from '@components/modal.component'
import { TextDivider } from '@components/text-divider.component'
import { useAppState } from '@src/app.state'
import { functions } from '@src/firebase-app'
import { OauthList } from '@src/pages/account/auth/oauth/oauth-list.component'
import { ResetPasswordForm } from '@src/pages/account/auth/reset-password-form.component'
import { toastService } from '@utils/toast.service'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'
import { k, useCss } from 'kremling'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RegisterPassword } from '../registration/register-password.component'
import { SignInPassword } from './sign-in-password.component'

type ModalProps = {
  onReject: () => void
  onResolve: (options: { type: string; user: unknown }) => void
  allowGuest?: boolean
  allowLogin?: boolean
  guestUser?: User
  editToken?: string
  activityId?: string
  orgId?: string
}

type FormType =
  | 'register'
  | 'sign-in'
  | 'change-password'
  | 'email-code'
  | 'link-account'
type ErrorState = 'failed-email-code'

export function AuthModal({
  onReject,
  onResolve,
  allowGuest,
  allowLogin,
  guestUser,
  editToken,
  activityId,
  orgId,
}: ModalProps) {
  const [formType, setFormType] = useState<FormType>(
    guestUser ? 'link-account' : 'register',
  )
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  const [errorState, setErrorState] = useState<ErrorState>(null)
  const scope = useCss(css)
  const authType = useRef(null)

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      guestEmail: '',
    },
  })

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), user => {
      if (!user || !!guestUser) return setLoading(false)
      onResolve({
        type: authType.current,
        user,
      })
    })
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    if (!code) return
    if (code.length === 6) {
      const guestEmail = getValues('guestEmail')
      setLoading(true)
      httpsCallable(
        functions,
        'allFunctions-verifyEmailVerificationCode',
      )({
        email: guestEmail,
        code,
      })
        .then(({ data }: { data: { status: string } }) => {
          if (data.status === 'success') {
            onResolve({ type: 'guest', user: { email: guestEmail } })
          } else if (data.status === 'failed') {
            setErrorState('failed-email-code')
            setLoading(false)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [code])

  function goToSignIn() {
    setFormType('sign-in')
  }

  function goToRegister() {
    setFormType('register')
  }

  function goToForgotPassword() {
    setFormType('change-password')
  }

  function goToEmailCode() {
    setFormType('email-code')
  }

  function handleSendCode(guestEmail: string) {
    httpsCallable(
      functions,
      'allFunctions-sendEmailVerificationCode',
    )({
      email: guestEmail,
    })
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        goToSignIn()
        toastService.error('Email already registered. Please sign in.')
      })
  }

  function submitGuestRegister({ guestEmail }: { guestEmail: string }) {
    handleSendCode(guestEmail)
    goToEmailCode()
  }

  function renderRegisterForm() {
    return (
      <>
        {allowGuest && (
          <>
            <form onSubmit={handleSubmit(submitGuestRegister)}>
              <div className="row break-1">
                <div className="col-12">
                  <div className="form-group">
                    <div className="form-title">Participate as guest</div>
                    <InputField
                      fieldName="guestEmail"
                      control={control}
                      type="email"
                      label="Email"
                      required
                      placeholder="Guest email address"
                    />
                  </div>
                </div>
              </div>
              <Button
                disabled={!isValid}
                loading={isSubmitting}
                variant="primary"
                type="submit"
                fullWidth
              >
                Join as guest
              </Button>
            </form>
            <TextDivider className="mb-5 mt-5">OR</TextDivider>
          </>
        )}
        <div>
          <div className="form-title">Create an account</div>
          <RegisterPassword
            handleClick={() => {
              setLoading(true)
              authType.current = 'register'
            }}
          />
        </div>
        <TextDivider className="mt-5">OR</TextDivider>
        <div className="mt-5">
          <OauthList
            registerType="register"
            handleClick={() => {
              setLoading(true)
              authType.current = 'register'
            }}
          />
        </div>
        {allowLogin && (
          <div
            className="mt-5"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button onClick={goToSignIn}>Already have an account?</Button>
          </div>
        )}
      </>
    )
  }

  function renderSignInForm() {
    if (!allowLogin) return
    return (
      <>
        <SignInPassword
          onForgot={goToForgotPassword}
          handleClick={() => {
            setLoading(true)
            authType.current = 'login'
          }}
        />
        <TextDivider className="mt-5">OR</TextDivider>
        <div className="mt-5">
          <OauthList
            handleClick={() => {
              setLoading(true)
              authType.current = 'login'
            }}
          />
        </div>
        <div
          className="mt-5"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button onClick={goToRegister}>Create an account</Button>
        </div>
      </>
    )
  }

  function renderChangePasswordForm() {
    return (
      <>
        <ResetPasswordForm onPasswordReset={goToSignIn} />
        <div
          className="mt-5"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button onClick={goToSignIn}>Sign in</Button>
        </div>
      </>
    )
  }

  function handleCodeChange(value: string) {
    setCode(value)
    setErrorState(null)
  }

  function renderEmailCodeForm() {
    return (
      <div>
        <div className="form-title">Email Verification</div>
        <p>Please enter the code you received by email:</p>
        <PinInput
          value={code}
          onChange={handleCodeChange}
          size="lg"
          length={6}
        />
        {errorState === 'failed-email-code' && (
          <div className="error-text mt-3">
            This code is either invalid or it has expired. Please try again.
          </div>
        )}
      </div>
    )
  }

  function renderLinkAccountForm() {
    return (
      <>
        <div>
          <div className="form-title">Create account</div>
          <RegisterPassword
            linkAccountToken={editToken}
            email={guestUser.email}
            onLinkAccount={(data: any) => {
              const { user } = data
              httpsCallable(
                functions,
                'activityFunctions-reconcileGuestUserData',
              )({
                userId: user.uid,
                activityId,
                orgId,
              }).then((res: { data: User }) => {
                useAppState.setState({ user: res.data, appLoading: false })
                //TODO - update this
                // navigate('/dashboard')
              })
            }}
            handleClick={() => {
              setLoading(true)
              authType.current = 'link-account'
            }}
          />
        </div>
        <TextDivider className="mt-5">OR</TextDivider>
        <div className="mt-5">
          <OauthList
            activityId={activityId}
            orgId={orgId}
            registerType="link-account"
            handleClick={() => {
              setLoading(true)
              authType.current = 'register'
            }}
          />
        </div>
      </>
    )
  }

  function renderContent() {
    switch (formType) {
      case 'register':
        return renderRegisterForm()
      case 'sign-in':
        return renderSignInForm()
      case 'change-password':
        return renderChangePasswordForm()
      case 'email-code':
        return renderEmailCodeForm()
      case 'link-account':
        return renderLinkAccountForm()
    }
  }

  return (
    <Modal onClose={onReject}>
      <Modal.Body>
        <div
          {...scope}
          className="p-8"
        >
          {loading && (
            <div>
              <Loader overlay />
            </div>
          )}
          {renderContent()}
        </div>
      </Modal.Body>
    </Modal>
  )
}

const css = k`
  .form-title {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1.2rem;
  }

  .error-text {
    color: $color-danger;
  }
`
