import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { Pill } from '@components/pill/pill.component'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { NavLink } from '@src/navigation/nav-link.component'
import { CompletedBadge } from '@src/pages/workflow/client/workflow-refactor/completed-badge.component'
import { useCountdown } from '@src/pages/workflow/client/workflow-refactor/hooks/use-countdown.hook'
import { LockedSectionInfoModal } from '@src/pages/workflow/client/workflow-refactor/locked-section-info-modal.component'
import { useActivityStore } from '@src/pages/workflow/client/workflow-refactor/state/use-activity-state'
import { dbNames } from '@utils/constants'
import { modalService } from '@utils/modal.service'
import { toastService } from '@utils/toast.service'
import { doc, updateDoc } from 'firebase/firestore'
import { a } from 'kremling'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

type Props = {
  section: ActivitySection
  index: number
}

export function SequentialSectionItem({ section, index }: Props) {
  const { iterationId, sectionId } = useParams()
  const user = useAppState(state => state.user)
  const progressSections = useActivityStore(state => state.progressSections)
  const activityUser = useActivityStore(state => state.activityUser)
  const progressSection = progressSections.find(
    (p: ProgressSection) => p.sectionId === section.id,
  )

  const convertedUnlockDate =
    activityUser?.unlockDate && activityUser?.activeSectionIndex + 1 === index
      ? DateTime.fromMillis(activityUser?.unlockDate)
      : null

  const countdownTimer = useCountdown(convertedUnlockDate)
  const progress = progressSection?.completedPercentage || 0
  const isSelected = section.id === sectionId
  const isDisabled =
    activityUser.activeSectionIndex < index &&
    progressSection.completedPercentage !== 100

  useEffect(() => {
    //if countdown timer reaches 0, increment activeSectionIndex and set unlockDate to null
    if (!convertedUnlockDate) return
    if (countdownTimer && countdownTimer.toMillis() === 0) {
      const newActiveIndex = activityUser.activeSectionIndex + 1

      updateDoc(doc(db, dbNames.activityUsers, `${activityUser.id}`), {
        activeSectionIndex: newActiveIndex,
        unlockDate: null,
      })

      useActivityStore.setState(state => {
        const updatedActivityUser = {
          ...state.activityUser,
          activeSectionIndex: newActiveIndex,
          unlockDate: null as number | null,
        }
        return {
          activityUser: updatedActivityUser,
        }
      })
    }
  }, [countdownTimer, progressSection])

  const showCountdown = useMemo(() => {
    //return true if the section is locked and an unlock date is set on the activityUser and the date is in the future
    return (
      isDisabled &&
      activityUser.unlockDate &&
      countdownTimer &&
      countdownTimer.toMillis() > 0
    )
  }, [countdownTimer, activityUser])

  async function openLockedInfoModal() {
    await modalService.render(LockedSectionInfoModal, {
      unlockDate: convertedUnlockDate,
    })
  }

  return (
    <NavLink
      activeClassName="text-primary font-bold hover:bg-active-50 hover:text-primary"
      onClick={() => {
        if (isDisabled && showCountdown) {
          return openLockedInfoModal()
        }
        useActivityStore.setState({ navigationOpen: false })
      }}
      className={a(
        'px-6 relative h-16 flex items-center justify-between border-b border-grey-100 hover:bg-active-50 text-grey-700 hover:text-grey-500',
      ).m(
        'opacity-60 hover:bg-white cursor-default hover:text-grey-600',
        isDisabled,
      )}
      to={
        section.id === sectionId ? `${section.id}/${iterationId}` : section.id
      }
      disabled={isDisabled}
      key={section.id}
    >
      <div className="w-full">
        <div
          className="absolute left-0 bottom-0 top-0 bg-grey-50 transition-all"
          style={{
            width: `${progress}%`,
            backgroundColor: isSelected ? 'var(--color-active-150)' : '',
          }}
        />
        <div className="relative flex items-center justify-between">
          {isSelected ? (
            <Pill pillType="primary">
              {section.name ? section.name : 'Untitled section'}
            </Pill>
          ) : section.name ? (
            section.name
          ) : (
            'Untitled section'
          )}
          <div className="flex items-center gap-3">
            {isDisabled && (
              <Icon
                name="lock"
                size={14}
              />
            )}
            {showCountdown && user.isSuperAdmin && (
              <div className="flex items-center">
                <div className="text-2xl font-bold">
                  {countdownTimer.toFormat('hh:mm:ss')}
                </div>
                <ActionIcon
                  onClick={e => {
                    e.stopPropagation()
                    const d = doc(
                      db,
                      dbNames.activityUsers,
                      `${activityUser.id}`,
                    )
                    updateDoc(d, {
                      unlockDate: null,
                      activeSectionIndex: activityUser.activeSectionIndex + 1,
                    }).then(() => {
                      toastService.info('Section unlocked')
                    })
                  }}
                  icon="lock"
                  size="sm"
                />
              </div>
            )}
            {showCountdown && !user.isSuperAdmin && (
              <div className="text-2xl font-bold">
                {countdownTimer.toFormat('hh:mm:ss')}
              </div>
            )}
          </div>
        </div>
      </div>
      <CompletedBadge
        className="relative"
        showBadge={progressSection?.isSubmitted}
      />
    </NavLink>
  )
}
