import { EditorCapturePlugin } from '@components/rich-text/plugins/editor-capture-plugin'
import { InitialValuePlugin } from '@components/rich-text/plugins/initial-value-plugin'
import { $generateHtmlFromNodes } from '@lexical/html'
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { EditorState, LexicalEditor } from 'lexical'
import React, { forwardRef } from 'react'
import { CommandsPlugin } from '../plugins/commands-plugin'
import { SubmitPlugin } from '../plugins/submit-plugin'

interface SimpleEditorProps {
  onChange: (newValue: string) => void
  initialValue?: string
  placeholder?: string
  label?: string
  onKeyDown?: (e: KeyboardEvent) => void
  onSubmit?: (value: string) => void
  ref: any
  id?: string
}

export const SimpleEditor = forwardRef(function (
  {
    onChange,
    onSubmit,
    initialValue = '',
    placeholder = '',
    onKeyDown,
    id = 'simple-editor',
  }: SimpleEditorProps,
  ref,
) {
  const editorConfig = {
    namespace: 'simple-editor',
    onError: (err: any) => {
      console.log(err)
    },
  }

  function handleSave(_editorState: EditorState, editor: LexicalEditor) {
    editor.update(() => {
      const htmlString = $generateHtmlFromNodes(editor, null)
      const textContent = editor.getRootElement()?.textContent
      if (textContent === '') {
        onChange('')
      } else {
        onChange(htmlString)
      }
    })
  }

  return (
    <LexicalComposer initialConfig={editorConfig}>
      {/*<PlainTextPlugin*/}
      {/*  contentEditable={*/}
      {/*    <ContentEditable className="simple-editor-container form-input form-input-textarea" />*/}
      {/*  }*/}
      {/*  placeholder={<Placeholder text={placeholder} />}*/}
      {/*  ErrorBoundary={LexicalErrorBoundary}*/}
      {/*/>*/}
      <div className="relative w-full">
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              id={id}
              className="simple-editor-container form-input form-input-textarea"
              tabIndex={0}
            />
          }
          placeholder={<Placeholder text={placeholder} />}
          ErrorBoundary={LexicalErrorBoundary}
        />
      </div>
      <OnChangePlugin
        onChange={handleSave}
        ignoreSelectionChange
      />
      <CommandsPlugin onKeyDown={onKeyDown} />
      <ClearEditorPlugin />
      <HistoryPlugin />
      <SubmitPlugin onSubmit={onSubmit} />
      <InitialValuePlugin initialValue={initialValue} />
      <EditorCapturePlugin ref={ref} />
    </LexicalComposer>
  )
})

function Placeholder({ text = 'Type here...' }: { text?: string }) {
  return (
    <div className="placeholder">
      <p>{text}</p>
    </div>
  )
}
