import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { k, useCss } from 'kremling'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Card } from '@components/card.component'
import { DatePicker } from '@components/date-picker.component'
import { Icon } from '@components/icon.component'
import { Loader } from '@components/loader.component'
import { Button } from '@components/mantine/button.component'
import { useLoad } from '@hooks/use-load.hook'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { uniqueIdentifierFromDate } from '@utils/helpers'
import { modalService } from '@utils/modal.service'
import { toastService } from '@utils/toast.service'
import { ShareTemplateModal } from './share-template-modal.component'

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
export function OrgDetailPage() {
  const [paidThroughDate, setPaidThroughDate] = useState(null)
  const { workspaceId } = useParams()
  const navigate = useNavigate()
  const scoped = useCss(css)

  const [org, _setOrg, orgOpts] = useLoad(
    {},
    () => {
      return getDoc(doc(db, dbNames.organizations, workspaceId)).then(d => ({
        ...d.data(),
        id: d.id,
      }))
    },
    [],
  )

  const [templates, _setTemplates, templateOpts] = useLoad(
    [],
    () => {
      const q = query(
        collection(db, dbNames.workflowTemplates),
        where('orgId', '==', workspaceId),
        where('assistant', '==', false),
      )
      return getDocs(q).then(q => q.docs.map(d => ({ id: d.id, ...d.data() })))
    },
    [],
  )

  useEffect(() => {
    if (!org) return
    if (org.paidThroughDate) {
      const { date, timeZone: fromTimezone } = org.paidThroughDate
      const convertedDate = DateTime.fromISO(date, {
        zone: fromTimezone,
      }).setZone(timeZone)
      setPaidThroughDate(convertedDate)
    }
  }, [org])

  const orgNameId = useMemo(
    () => uniqueIdentifierFromDate(org.createdDate),
    [org.createdDate],
  )

  function handleUpdatePaidThroughDate(date) {
    setPaidThroughDate(date)

    updateDoc(doc(db, dbNames.organizations, workspaceId), {
      'paidThroughDate.date': date.toISO({ includeOffset: false }),
      'paidThroughDate.timeZone': timeZone,
    })
  }

  function handleShare(template) {
    modalService.render(ShareTemplateModal, { template }).then(() => {
      toastService.info('Successfully shared template')
    })
  }

  if (orgOpts.loading || templateOpts.loading) {
    return (
      <div>
        <Loader center />
      </div>
    )
  }

  return (
    <div
      className="mt-24"
      {...scoped}
    >
      <Button
        className="mb-6"
        onClick={() => navigate(-1)}
      >
        <Icon name="angle-left" /> Back
      </Button>
      <Card className="card-item">
        <div className="card-title">
          {org.name} #<span>{orgNameId}</span>
        </div>
        <div>
          <div className="list-title">Templates List</div>
          {isEmpty(templates) ? (
            <div>No templates in this org</div>
          ) : (
            templates.map(template => (
              <Card
                href={`/${workspaceId}/templates/${template.id}`}
                className="template-item"
                key={template.id}
              >
                <div className="list-item-title">{template.name}</div>
                <div>
                  <Button
                    variant="secondary"
                    onClick={e => {
                      e.preventDefault()
                      handleShare(template)
                    }}
                  >
                    Send a copy
                  </Button>
                </div>
              </Card>
            ))
          )}
        </div>
      </Card>
      <Card className="card-item">
        <div className="list-title">
          Paid through:{' '}
          {paidThroughDate
            ? paidThroughDate.toLocaleString(DateTime.DATE_MED)
            : 'Free tier'}
        </div>
        <DatePicker
          value={paidThroughDate}
          placeholder="Select a date"
          onChange={handleUpdatePaidThroughDate}
        />
      </Card>
    </div>
  )
}

const css = k`
  .card-item {
    padding: 1.6rem;
    margin-bottom: 16px;
  }

  .card-title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1.6rem;
  }

  .list-title {
    margin-bottom: 8px;
    font-size: 1.4;
    font-weight: 500;
  }

  .list-item-title {
    font-weight: 500;
  }

  .template-item {
    padding: 0 .8rem;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .template-item:not(:last-child) {
    margin-bottom: .8rem;
  }
`
