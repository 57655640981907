export const getNavItems = function (
  activity: Activity,
  user: User,
  activityUser: ActivityUser,
  notifications: Notification[],
) {
  return [
    // {
    //   label: 'Leaderboards',
    //   to: 'leaderboards',
    //   isHidden: activity?.userIds.length === 0,
    //   icon: 'flag-checkered'
    // },
    {
      label: 'Messaging',
      to: 'messaging',
      isHidden: activity?.userIds.length === 0,
      icon: 'comments',
      notifications: notifications.filter(
        (n: Notification) =>
          n.type === 'newWorkflowMessage' &&
          n.activityId === activity?.id &&
          n.viewed === false,
      ).length,
    },
    // {
    //   label: 'Mentors',
    //   to: 'mentors',
    //   isHidden: user?.id !== activityUser?.userId,
    //   icon: 'users'
    // },
  ]
}
