import { k, useCss } from 'kremling'
import { DateTime } from 'luxon'
import React, { useMemo } from 'react'

import { CardListItem } from '@components/card-list-item.component'
import { getDaysUntil } from '@utils/helpers'

export function UpcomingActivityCard({ upcomingActivity, href }) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const convertedStartDate = useMemo(
    () =>
      DateTime.fromISO(upcomingActivity.startDate.date, {
        zone: upcomingActivity.startDate.timeZone,
      }).setZone(timeZone),
    [upcomingActivity.startDate],
  )
  const daysUntilStart = useMemo(
    () => getDaysUntil(convertedStartDate),
    [convertedStartDate],
  )
  const scoped = useCss(css)

  return (
    <CardListItem
      {...scoped}
      icon="alarm-clock"
      href={href}
      cardTitle={upcomingActivity.name}
      renderRightContent={() => (
        <div className="days-left__container">
          <div className="days-left-number">{daysUntilStart}</div>
          <div>Day{daysUntilStart > 1 ? 's' : ''}</div>
        </div>
      )}
    />
  )
}

const css = k`
  .days-left__container {
    display: flex;
    align-items: center;
  }

  .days-left-number {
    font-size: 3.5rem;
    line-height: 2.5rem;
    margin-right: 8px;
    font-weight: bold;
  }
`
