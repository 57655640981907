import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Icon } from '@components/icon.component'

type Props = {
  showBadge: boolean
  className?: string
  size?: number
}

export function CompletedBadge({ showBadge, className, size=18 }: Props) {
  return (
    <AnimatePresence initial={false}>
      {showBadge &&
        <motion.div
          key="badge"
          initial={{ x: -3, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: 'easeOut' }}
          className={`bg-white rounded-full shadow-sm ${className}`}
        >
          <Icon name='check-circle' className="text-success" size={size} />
        </motion.div>
      }
    </AnimatePresence>
  )
}