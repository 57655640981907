import React from 'react'
import { useCss, k } from 'kremling'

export function Required() {
  const scope = useCss(css)
  return (
    <span
      {...scope}
      className="label-required"
    >
      *
    </span>
  )
}

Required.propTypes = {}

const css = k`
  .label-required {
    color: $color-danger;
  }
`
