import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { k, m, useCss } from 'kremling'
import React from 'react'

import { CardListItem } from '@components/card-list-item.component'
import { Button } from '@components/mantine/button.component'
import { useLoad } from '@hooks/use-load.hook'
import { db } from '@src/firebase-app'
import { InvitationDetailsModal } from '@src/pages/dashboard/invitation-details-modal.component'
import { dbNames } from '@utils/constants'
import { modalService } from '@utils/modal.service'

const invitationTitles = {
  org: 'Workspace invite',
  linkInvite: 'Open invite',
  peerMentor: 'Mentoring invite',
}
export function InvitationCard({ invitation }) {
  const scoped = useCss(css)
  const isLinkInvite = invitation.type === 'linkInvite'

  const [org] = useLoad(
    {},
    () => {
      return getDoc(
        doc(db, dbNames.organizations, invitation.inviterOrgId),
      ).then(d => ({ ...d.data(), id: d.id }))
    },
    [],
  )

  async function openInvitationDetailsModal() {
    if (!isLinkInvite) {
      const d = doc(db, dbNames.invitations, invitation.id)
      updateDoc(d, { viewed: true })
    }
    await modalService.render(
      InvitationDetailsModal,
      { invitation, org },
      { hideClose: true },
    )
  }

  return (
    <div
      {...scoped}
      className="invitation__container space-y-5"
    >
      <CardListItem
        className={m('!bg-active-25', !invitation.dirty)}
        cardTitle={invitationTitles[invitation.type]}
        subTitle={
          <>
            You have been invited to join{' '}
            <span className="text-2xl font-semibold text-primary ml-1">
              {org.name}
            </span>
          </>
        }
        renderRightContent={() => (
          <div style={{ width: '100%' }}>
            <Button
              variant="secondary"
              onClick={openInvitationDetailsModal}
            >
              View
            </Button>
          </div>
        )}
      />
    </div>
  )
}

const css = k`
  .invitation__container {
    position: relative;
  }

  .new-badge {
    top: -6px;
    left: -6px;
  }
`
