import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useState } from 'react'

import { Modal } from '@components/modal.component'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

export const useModalState = create(
  subscribeWithSelector(() => ({
    modal: null,
  })),
)

export function ModalEntry() {
  const [modal, setModal] = useState(null)
  useEffect(() => {
    return useModalState.subscribe(
      state => state.modal,
      state => {
        setModal(state)
      },
    )
  }, [])

  return (
    <AnimatePresence>
      {!!modal && (
        <Modal
          onClose={modal.onReject}
          hideClose={modal.config?.hideClose}
          {...modal.config}
        >
          <modal.Component
            {...modal.data}
            onResolve={modal.onResolve}
            onReject={modal.onReject}
          />
        </Modal>
      )}
    </AnimatePresence>
  )
}

class ModalService {
  __close = () => {
    useModalState.setState({ modal: null })
  }
  render(Component, data = {}, config = {}) {
    let onResolve, onReject
    const promise = new Promise(resolve => {
      onResolve = data => {
        resolve({ resolveType: 'resolve', ...data })
        this.__close()
      }
      onReject = () => {
        resolve({ resolveType: 'reject' })
        this.__close()
      }
    })
    useModalState.setState({
      modal: {
        Component,
        onReject,
        onResolve,
        data,
        config,
      },
    })
    return promise
  }
}

export const modalService = new ModalService()
