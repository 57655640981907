import { a } from 'kremling'
import React, {
  ChangeEvent,
  ComponentProps,
  forwardRef,
  ReactNode,
} from 'react'

import { ActionIcon } from '@components/mantine/action-icon.component'
import { Required } from '@components/required.component'
import { Icon } from '../icon.component'

export type InputProps = ComponentProps<'input'> & {
  className?: string
  error?: string | boolean
  hideRequiredAsterisk?: boolean
  icon?: string
  iconAriaLabel?: string
  id?: string
  inline?: boolean
  label?: ReactNode | string
  onBlur?: (value: string, event: ChangeEvent<HTMLInputElement>) => void
  onChange?: (value: string, event: ChangeEvent<HTMLInputElement>) => void
  onInputChange?: (value: string, event: ChangeEvent<HTMLInputElement>) => void
  onCancel?: (value?: string) => void
  placeholder?: string
  require?: boolean
  small?: boolean
  style?: React.CSSProperties
  type?: string
  value?: string
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  function InputComponent(props, ref) {
    const {
      className,
      error,
      hideRequiredAsterisk,
      id,
      icon,
      iconAriaLabel,
      inline,
      label,
      onCancel,
      onChange,
      onInputChange,
      onBlur,
      small,
      type = 'text',
      value,
      maxLength,
      required,
      ...inputProps
    } = props

    return (
      <div
        className={a(className)
          .m('form-input-container--icon', icon)
          .m('form-input-container--inline', inline)
          .m('form-input-container--error', !!error)}
      >
        {label &&
          (maxLength ? (
            <label
              className="form-input--label"
              htmlFor={id}
            >
              <span>
                {label} {required && !hideRequiredAsterisk && <Required />}
              </span>
              <span>
                {value.length}/{maxLength}
              </span>
            </label>
          ) : (
            <label
              className="form-input--label"
              htmlFor={id}
            >
              <span>
                {label} {required && !hideRequiredAsterisk && <Required />}
              </span>
            </label>
          ))}
        <div className="form-input-container">
          <input
            {...inputProps}
            className={a('form-input')
              .m('form-input--clear', onCancel)
              .m('form-input--small', small)
              .m('form-input--inline', inline)}
            id={id}
            onChange={e => {
              const value = e.target.value
              onChange && onChange(value, e)
              onInputChange && onInputChange(value, e)
            }}
            onBlur={e => onBlur && onBlur(e.target.value, e)}
            maxLength={maxLength}
            ref={ref}
            type={type}
            value={value}
          />
          {icon && (
            <Icon
              name={icon}
              className="form-input__search-icon"
              size={16}
              aria-label={iconAriaLabel}
            />
          )}
          {onCancel && (
            <ActionIcon
              variant="tertiary"
              size="sm"
              onClick={() => onCancel(value)}
              className="form-input__clear-button"
              icon="times"
              aria-label="cancel"
            />
          )}
        </div>
        {typeof error === 'string' && (
          <div className="form-input__error">{error}</div>
        )}
      </div>
    )
  },
)
