import { ActionIcon } from '@components/mantine/action-icon.component'
import { Button } from '@components/mantine/button.component'
import { TextDivider } from '@components/text-divider.component'
import { Tooltip } from '@components/tooltip.component'
import { Well } from '@components/well.component'
import { ChoiceItemList } from '@src/pages/workflow-template-list/template-generator/choice-item-list.component'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'

export function MeasureAndTrackState({
  state,
  send,
  updateSelectedItems,
  handleReport,
}) {
  const { selectedItems, lists, rawResponses } = state.context

  useEffect(() => {
    if (!selectedItems.validationFields) return
    updateSelectedItems('validationFields', selectedItems.validationFields)
  }, [selectedItems.validationFields])

  function disableNext() {
    let selectedFields = []
    for (const id in selectedItems.validationFields) {
      selectedFields = selectedFields.concat(selectedItems.validationFields[id])
    }
    return isEmpty(selectedFields)
  }

  function onSelectField(field, activityId) {
    send({
      type: 'fieldSelected',
      selection: { selectedField: field, activityId },
    })
  }

  return (
    <Well>
      <Well.Header
        renderActions={() => (
          <Tooltip content="Report an issue">
            <ActionIcon
              size="sm"
              icon="flag"
              onClick={() => {
                handleReport(
                  state.value,
                  rawResponses,
                  lists.activities.reduce(
                    (accum, activity) => [
                      ...accum,
                      ...activity.validationFields.map(
                        ({ fieldLabel }) => fieldLabel,
                      ),
                    ],
                    [],
                  ),
                )
              }}
            />
          </Tooltip>
        )}
      >
        Step 4: Touchpoints
      </Well.Header>
      <Well.Body>
        <div className="row break-1">
          <div className="col-10">
            <div className="explain-text">
              Review the suggested ways to measure and track the results of your
              selected touchpoints. Choose the methods that resonate with your
              goals and will help you optimize your participants' interactions.
              After selecting your preferred tracking items, move forward to
              finalize your experience design and bring it to life.
            </div>
          </div>
        </div>
        <TextDivider className="mt-5 mb-5" />
        <div>
          {lists.activities.map(({ action, validationFields, id }) => {
            return (
              <div
                key={id}
                className="mb-5"
              >
                <div style={{ fontWeight: 500 }}>{action}</div>
                <div>
                  <ChoiceItemList
                    className="mt-3"
                    list={validationFields}
                    selectedItems={
                      selectedItems.validationFields
                        ? selectedItems.validationFields[id] || []
                        : []
                    }
                    getSelectedMatchValue={({ fieldLabel, fieldType }) =>
                      `${fieldLabel} (${fieldType})`
                    }
                    getIdentifier={({ fieldLabel }) => fieldLabel}
                    onSelect={field => {
                      onSelectField(field, id)
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>
        <TextDivider className="mt-5 mb-5" />
        <div className="stage__actions">
          <Button
            variant="secondary"
            onClick={() => {
              send('back')
            }}
          >
            Back
          </Button>
          <Button
            loading={state.matches('validation.loading')}
            disabled={disableNext()}
            variant="primary"
            onClick={() => send('next')}
          >
            Add to template
          </Button>
        </div>
      </Well.Body>
    </Well>
  )
}
