import { Icon } from '@components/icon.component'
import { Loader } from '@components/loader.component'
import { Button } from '@components/mantine/button.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Modal } from '@components/modal.component'
import { Checkbox } from '@components/radix/checkbox'
import { RadioGroup } from '@components/radix/radio-group'
import { useActivityStore } from '@src/pages/workflow/client/workflow-refactor/state/use-activity-state'
import { useAssistedResponsePrompts } from '@src/pages/workflow/client/workflow-refactor/use-assisted-response-prompts'
import {
  FIELD_CONSTANTS,
  TEXT_TYPE_CONSTANTS,
} from '@src/pages/workflow/fields.helper'
import { isArray, isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import wretch from 'wretch'

const url = `${process.env.REST_URL_PREFIX}/allFunctions-sendPrompt`

type ConvoPart = {
  role: 'assistant' | 'user' | 'system'
  content: string
}

type DistanceResponse = {
  value: number
  unit: string
}

type FinalResponseContent = string | string[] | DistanceResponse | number

type FinalResponse = {
  type: 'RESPONSE'
  content: FinalResponseContent
}

export function AssistedResponseModal({
  onResolve,
  onReject,
  field,
}: {
  onResolve: (response: { response: FinalResponseContent }) => void
  onReject: () => void
  field: Field
}) {
  const activity = useActivityStore(state => state.activity)

  const prompt = useAssistedResponsePrompts({ field })

  const initialQuestions: ConvoPart[] = [
    {
      role: 'system',
      content: prompt,
    },
    {
      role: 'user',
      content: `
        Here is the initial question: ${field.label}
        And here is the name of the activity: ${activity.name}
        Here is the field label: ${field.label}
        ${field.description ? `Here is the field description: ${field.description}. You should heavily rely on this description for better context of how to come up with questions to ask the user.` : ''}
        Here is the field description: ${field.description}
        ${field.type === FIELD_CONSTANTS.TEXT ? `Here is the type of text field: ${field.textType}` : ''}
        ${field.type === FIELD_CONSTANTS.SELECT && field.multiselect ? `This is a multiselect field` : ''}
        ${!isEmpty(field.choices) ? `Here are the choices: ${field.choices.map(({ label }) => label).join(', ')}` : ''}
      `,
    },
  ]

  const [responses, setResponses] = useState<{
    [key: number]: string | string[]
  }>({})
  const [finalResponse, setFinalResponse] = useState<FinalResponse>(null)
  const [loading, setLoading] = useState(false)
  const [questions, setQuestions] = useState<ConvoPart[]>(initialQuestions)

  const assistantQuestions = questions.filter(
    message => message.role === 'assistant',
  )

  useEffect(() => {
    if (isEmpty(assistantQuestions)) {
      getQuestion(questions)
    }
  }, [assistantQuestions, questions])

  function getQuestion(messages: ConvoPart[]) {
    setLoading(true)
    wretch(url)
      .post({ messages })
      .json(json => json)
      .then(
        res => {
          if (JSON.parse(res.at(-1).content).type === 'RESPONSE') {
            setFinalResponse(JSON.parse(res.at(-1).content))
          }
          setQuestions(res)
          setLoading(false)
        },
        err => {
          console.log(err)
        },
      )
  }

  function sendResponse(value: string | string[]) {
    const newQuestions = [...questions]
    newQuestions.push({
      role: 'user',
      content: `${value}`,
    })
    getQuestion(newQuestions)
  }

  function resetEverything() {
    setResponses({})
    setFinalResponse(null)
    setQuestions(initialQuestions)
  }

  function renderResponseContent() {
    if (isArray(finalResponse.content)) {
      return (
        <div className="space-y-3">
          {field.choices.map(
            (option: { id: string; label: string }, i: number) => (
              <Checkbox
                checked={(finalResponse.content as string).includes(
                  option.label,
                )}
                disabled={true}
                key={i}
                label={option.label}
              />
            ),
          )}
        </div>
      )
    } else if (field.type === FIELD_CONSTANTS.TEXT) {
      if (field.textType === TEXT_TYPE_CONSTANTS.DURATION) {
        return (
          <div className="text-2xl font-semibold">
            {(finalResponse.content as DistanceResponse).value}{' '}
            {(finalResponse.content as DistanceResponse).unit}
          </div>
        )
      }

      if (field.textType === TEXT_TYPE_CONSTANTS.PERCENTAGE) {
        return (
          <div className="text-2xl font-semibold">
            {finalResponse.content as string}%
          </div>
        )
      }

      if (field.textType === TEXT_TYPE_CONSTANTS.DURATION) {
        return (
          <div className="text-2xl font-semibold">
            {DateTime.fromMillis(finalResponse.content as number).toFormat(
              'hh:mm:ss',
            )}
          </div>
        )
      }
    }

    return <div>{finalResponse.content as string}</div>
  }

  return (
    <>
      <Modal.Header>
        <Icon name="wand-magic-sparkles" /> Help me answer
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-4">
          {!!finalResponse ? (
            <div className="space-y-3">
              <div className="text-2xl font-semibold">
                Would you like to use this response?
              </div>
              <div className="border border-grey-75 rounded-lg p-4">
                {renderResponseContent()}
              </div>
            </div>
          ) : (
            assistantQuestions.map((message, index) => {
              const parsed = JSON.parse(message.content)
              const canUpdate = index === assistantQuestions.length - 1
              const disabled = !canUpdate || loading
              let questionContent

              if (loading && assistantQuestions.length - 1 === index) {
                questionContent = <div>Loading...</div>
              }

              if (parsed.type === 'TEXT') {
                questionContent = (
                  <>
                    <TextInput
                      autoFocus={canUpdate}
                      disabled={disabled}
                      value={(responses[index] as string) || ''}
                      onChange={value => {
                        setResponses({ ...responses, [index]: value })
                      }}
                    />
                  </>
                )
              }

              if (parsed.type === 'CHECKBOX') {
                questionContent = (
                  <>
                    {parsed.options.map((option: string, i: number) => {
                      const isChecked =
                        responses[index] && responses[index].includes(option)
                      return (
                        <Checkbox
                          key={i}
                          disabled={disabled}
                          label={option}
                          checked={isChecked}
                          onCheckedChange={checked => {
                            const newResponses = [...(responses[index] || [])]
                            if (checked) {
                              newResponses.push(option)
                            } else {
                              newResponses.splice(
                                newResponses.indexOf(option),
                                1,
                              )
                            }
                            setResponses({
                              ...responses,
                              [index]: newResponses,
                            })
                          }}
                        />
                      )
                    })}
                  </>
                )
              }

              if (parsed.type === 'RADIO') {
                questionContent = (
                  <>
                    <RadioGroup
                      disabled={disabled}
                      value={(responses[index] as string) || ''}
                      onValueChange={value => {
                        setResponses({ ...responses, [index]: value })
                      }}
                    >
                      <div className="space-y-3">
                        {parsed.options.map((option: string, i: number) => (
                          <RadioGroup.Item
                            key={i}
                            value={option}
                            label={option}
                          />
                        ))}
                      </div>
                    </RadioGroup>
                  </>
                )
              }

              return (
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    sendResponse(responses[index])
                  }}
                  key={index}
                  className="border border-grey-75 rounded-lg p-4 space-y-3"
                >
                  <div className="text-2xl font-semibold">{parsed.content}</div>
                  {questionContent}
                  {index === assistantQuestions.length - 1 && (
                    <Button
                      variant="primary"
                      type="submit"
                    >
                      Submit answer
                    </Button>
                  )}
                </form>
              )
            })
          )}
          {loading && (
            <div className="border border-grey-75 rounded-lg p-4">
              <Loader size="md" />
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex items-center justify-between">
          <Button
            variant="secondary"
            onClick={resetEverything}
          >
            Retry
          </Button>
          <div className="flex items-center gap-2">
            <Button
              variant="secondary"
              onClick={() => {
                resetEverything()
                onReject()
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!finalResponse}
              variant="primary"
              onClick={() => {
                console.log(finalResponse)
                onResolve({ response: finalResponse.content })
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  )
}
