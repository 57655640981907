import { deleteDoc, doc, getDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { k, useCss } from 'kremling'
import { isEmpty } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { CardListItem } from '@components/card-list-item.component'
import { Icon } from '@components/icon.component'
import { Pill } from '@components/pill/pill.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db, functions } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTimeAgo } from '@utils/helpers'
import { toastService } from '@utils/toast.service'

export const UserInvitationCard = ({ invitation }) => {
  const scoped = useCss(css)
  const [working, setWorking] = useState(false)
  const orgs = useAppState(state => state.orgs)
  const { orgId } = useParams()
  const selectedOrg = orgs.find(org => orgId === org.id)

  const [user] = useLoad(
    {},
    () => {
      if (isEmpty(invitation)) return new Promise(resolve => resolve({}))
      const d = doc(db, dbNames.users, invitation.recipientId)
      return getDoc(d).then(q => q.data())
    },
    [invitation],
  )

  const invitedTimeAgo = useMemo(
    () => getTimeAgo(invitation.invitationEvents.at(-1).invitedDate),
    [invitation],
  )

  function handleRemoveInvitation() {
    setWorking(true)
    const d = doc(db, dbNames.invitations, invitation.id)
    deleteDoc(d).then(() => {
      toastService.info('Invitation successfully removed.')
      setWorking(false)
    })
  }

  function handleResendInvitation() {
    const inviteUser = httpsCallable(
      functions,
      'userFunctions-resendInviteUser',
    )
    setWorking(true)
    inviteUser({
      org: selectedOrg,
      email: user ? user.email : invitation.recipientId,
      invitationId: invitation.id,
    }).then(() => {
      toastService.info('Invitation successfully sent')
      setWorking(false)
    })
  }

  return (
    <CardListItem
      {...scoped}
      cardTitle={
        <>
          {user ? user.email : invitation.recipientId}
          <Pill
            pillType={invitation.status === 'pending' ? 'warning' : 'default'}
            className="ml-2"
          >
            {invitation.status}
          </Pill>
        </>
      }
      loading={working}
      icon="user-circle"
      subTitle={`Sent ${invitedTimeAgo}`}
      renderActionsContent={() => (
        <div className="select-list">
          <button
            onClick={() => {
              handleResendInvitation()
              close()
            }}
          >
            <Icon
              name="repeat"
              className="mr-2"
            />
            Resend invitation
          </button>
          <button
            onClick={() => {
              handleRemoveInvitation()
              close()
            }}
          >
            <Icon
              name="trash"
              className="mr-2"
            />
            Delete invitation
          </button>
        </div>
      )}
    />
  )
}

const css = k`
  .status-pill {
    margin-right: .8rem;
    font-size: 1.2rem;
    margin-left: .8rem;
    display: inline-block;
    background: $color-grey-75;
    padding: .2rem .8rem;
    border-radius: 1rem;
    text-transform: capitalize;
  }
`
