import { k, useCss } from 'kremling'
import { array, bool, func, object } from 'prop-types'
import React from 'react'

import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { SelectMulti } from '@components/select-multi.component'
import { SelectSingle } from '@components/select-single.component'
import { FieldHeaderContent } from '@src/pages/workflow/client/fields/field-header-content.component'
import { AssistedResponseModal } from '@src/pages/workflow/client/workflow-refactor/assisted-response-modal.component'
import { modalService } from '@utils/modal.service'
import { useController } from 'react-hook-form'

export function WorkflowSelect({
  field,
  isRequired,
  section,
  control,
  readOnly,
}) {
  const scope = useCss(css)

  const {
    field: { onChange, value, disabled, ...fieldProps },
    fieldState: { error },
  } = useController({
    disabled: readOnly,
    name: `${field.version}_${field.id}`,
    control,
    rules: {
      required: isRequired && 'This field is required',
    },
  })

  function createMarkup() {
    return { __html: field.description }
  }

  function handleSelectMulti(selections) {
    if (disabled) return
    onChange(selections.map(selection => selection.id))
  }

  function handleSelectSingle(selection) {
    if (disabled) return
    onChange(selection ? [selection.id] : [])
  }

  if (!field.choices.filter(({ label }) => label).length) {
    return (
      <div
        {...scope}
        className="select-container"
      >
        <div className="field-label">
          {field.label}
          {isRequired && <span className="aria-invisible"> Required</span>}
        </div>
        <div
          className="field-description"
          dangerouslySetInnerHTML={createMarkup()}
        />
        <div className="field-warning">
          <Icon
            name="circle-exclamation"
            className="mr-2"
          />{' '}
          No Select Box choices have been added. This field and message will not
          display to users.
        </div>
      </div>
    )
  }

  return (
    <div
      {...scope}
      className="select-container"
    >
      <FieldHeaderContent
        field={field}
        isRequired={isRequired}
        section={section}
        actions={
          <>
            <ActionIcon
              size="sm"
              onClick={() => {
                modalService
                  .render(AssistedResponseModal, { field, section })
                  .then(({ response }) => {
                    const responses = field.choices.reduce(
                      (acc, { id, label }) => {
                        const has = response.includes(label)
                        if (has) return [...acc, id]
                        return acc
                      },
                      [],
                    )
                    onChange(responses)
                  })
              }}
              icon="wand-magic-sparkles"
            />
          </>
        }
      />
      {field.multiselect ? (
        <>
          <SelectMulti
            data={field.choices}
            onChange={handleSelectMulti}
            fixedContent
            value={field.choices.filter(({ id }) => value.includes(id))}
            transformData={({ id, label }) => ({ id, name: label })}
            triggerIsBlock
            {...fieldProps}
          />
          {error && (
            <div className="mt-1 text-xl text-danger">{error.message}</div>
          )}
        </>
      ) : (
        <>
          <SelectSingle
            clearable
            data={field.choices}
            value={field.choices.find(({ id }) => value.includes(id))}
            onChange={handleSelectSingle}
            fixedContent
            transformData={({ id, label }) => ({ id, name: label })}
            triggerIsBlock
            {...fieldProps}
          />
          {error && (
            <div className="mt-1 text-xl text-danger">{error.message}</div>
          )}
        </>
      )}
    </div>
  )
}

WorkflowSelect.propTypes = {
  field: object,
  onChange: func,
  fieldVal: array,
  readyOnly: bool,
}

const css = k`
  .select-container {
    display: flex;
    flex-direction: column;
  }

  .checkbox {
    display: flex;
    align-items: center;
    margin-bottom: .8rem;

    &:last-child {
      margin-bottom: 0;
    }

    label {
      margin-bottom: 0;
      margin-left: .8rem;
    }
  }

  .field-warning {
    color: $color-danger;
    display: flex;
    align-items: center;
  }
`
