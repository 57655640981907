import { k, useCss } from 'kremling'
import React, { SyntheticEvent } from 'react'

import { Button } from '@components/mantine/button.component'

type EmptyStateProps = {
  title: string
  subtitle?: string | JSX.Element
  actionText?: string | JSX.Element
  action?: (e: SyntheticEvent) => void | null
  height?: number
}

export function EmptyState({
  title = 'This list is empty',
  subtitle,
  actionText,
  action,
  height,
}: EmptyStateProps) {
  const scoped = useCss(css)
  return (
    <div
      {...scoped}
      className="wrapper"
      style={{ minHeight: height ? `${height}rem` : '30rem' }}
    >
      <h3 className="text-2xl font-bold">{title}</h3>
      {subtitle && <div className="subtitle">{subtitle}</div>}
      {action && actionText && (
        <Button
          variant="secondary"
          onClick={action}
        >
          {actionText}
        </Button>
      )}
    </div>
  )
}

const css = k`
  .wrapper {
    width: 100%;
    max-width: 50rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;

    .title {
      margin-bottom: 2rem;
    }

    .subtitle {
      margin-bottom: 2rem;
    }
  }
`
