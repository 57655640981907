import { Icon } from '@components/icon.component'
import { a } from 'kremling'
import React, { forwardRef } from 'react'

const TriggerSingleInner = (
  {
    isDisabled,
    onBlur,
    onFocus,
    placeholder,
    title,
    toggle,
    triggerIsBlock,
    value,
  },
  ref,
) => {
  return (
    <button
      className={a('btn trigger-single')
        .m('trigger-single--placeholder', !value)
        .m('w-full block', triggerIsBlock)}
      onClick={e => {
        e.preventDefault()
        toggle()
      }}
      ref={ref}
      disabled={isDisabled}
      onBlur={onBlur}
      onFocus={onFocus}
      title={title}
    >
      {value ? value.name : placeholder}
      <Icon
        name="caret-down"
        size={14}
      />
    </button>
  )
}

export const TriggerSingle = forwardRef(TriggerSingleInner)
