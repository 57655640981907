import { createUserWithEmailAndPassword } from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'
import { useCss } from 'kremling'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Navigate, useParams } from 'react-router-dom'

import { Card } from '@components/card.component'
import { InputField } from '@components/input'
import { Loader } from '@components/loader.component'
import { Logo } from '@components/logo.component'
import { Button } from '@components/mantine/button.component'
import { TextDivider } from '@components/text-divider.component'
import { useAppState } from '@src/app.state'
import { auth, functions } from '@src/firebase-app'
import { LegalReference } from '@src/pages/account/legal-reference.component'
import { toastService } from '@utils/toast.service'
import { OauthList } from '../auth/oauth/oauth-list.component'
import css from './../account.kremling.scss'

//TODO need to figure out the requirements for this page

export function InviteRegister() {
  const scope = useCss(css)
  const [validationData, setValidationData] = useState(null)
  const [registering, setRegistering] = useState(false)
  const [loading, setLoading] = useState(true)
  const isAuthenticated = useAppState(state => state.isAuthenticated)
  const { activityId } = useParams()

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      verifyPassword: '',
    },
  })

  useEffect(() => {
    if (isAuthenticated) return
    ;(async function () {
      try {
        if (validationData) return
        const validate = httpsCallable(
          functions,
          'allFunctions-validateUserInviteRegistration',
        )
        const { data } = await validate({ inviteId: activityId })
        setValidationData(data)
        setLoading(false)
        setValue('email', data.invitationData.recipientId, {
          shouldDirty: false,
        })
      } catch (err) {
        toastService.error(err.message)
        setLoading(false)
      }
    })()
  }, [])

  async function handleRegisterPassword({ password, email }) {
    setRegistering(true)
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      )
      const createUser = httpsCallable(functions, 'userFunctions-createUser')
      await createUser({
        uid: user.uid,
        displayName: user.displayName,
        email,
      })
    } catch (err) {
      console.log(err)
      setRegistering(false)
      toastService.error(
        'An error occurred when registering your email and password. Please try again',
      )
    }
  }

  function renderRegistrationForm() {
    const isOrgInvite = validationData.invitationData.type === 'org'
    return (
      <>
        <form onSubmit={handleSubmit(handleRegisterPassword)}>
          <div className="row break-1">
            <div className="col-12">
              <div className="form-group">
                <InputField
                  control={control}
                  fieldName="email"
                  label="Email"
                  placeholder="Email"
                  type="email"
                  disabled={isOrgInvite}
                />
              </div>
            </div>
          </div>
          <div className="row break-1">
            <div className="col-12">
              <div className="form-group">
                <InputField
                  control={control}
                  fieldName="password"
                  label="Password (at least 6 characters)"
                  type="password"
                  minLength={6}
                />
              </div>
            </div>
          </div>
          <div className="row break-1">
            <div className="col-12">
              <div className="form-group">
                <InputField
                  control={control}
                  fieldName="verifyPassword"
                  label="Verify password"
                  type="password"
                  minLength={6}
                />
              </div>
            </div>
          </div>
          <Button
            type="submit"
            variant="primary"
            className="mt-5"
            loading={registering}
            fullWidth
          >
            Register
          </Button>
        </form>
        <div>
          <TextDivider className="mt-5 mt-5">OR</TextDivider>
          <OauthList
            registerType={
              validationData.invitationData.type === 'org'
                ? 'invitation'
                : 'register'
            }
            yarn
            run
            dev
          />
        </div>
      </>
    )
  }

  function renderInvitationContent() {
    if (!validationData) return
    switch (validationData.status) {
      case 'expired':
        return (
          <div>
            <p>The enrollment period for this activity has ended.</p>
          </div>
        )
      case 'duplicate':
        return (
          <div>
            <p>You have already joined this activity.</p>
          </div>
        )
      case 'valid':
        return (
          <>
            <h3 className="text-2xl font-bold">Create an account</h3>
            {renderRegistrationForm()}
          </>
        )
      case 'invalid':
        return (
          <div>
            <p>The invitation could not be found or has expired.</p>
          </div>
        )
    }
  }

  if (
    isAuthenticated &&
    validationData?.status !== 'invalid' &&
    validationData?.status !== 'expired'
  ) {
    if (!validationData) return null
    return <Navigate to="/" />
  }

  return (
    <div
      className="account-wrapper"
      {...scope}
    >
      <Card className="account-card">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Logo className="mb-6" />
            {renderInvitationContent()}
          </>
        )}
      </Card>
      <LegalReference />
    </div>
  )
}
