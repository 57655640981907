import { db } from '@src/firebase-app'
import {
  calcSectionCompletionPercentage,
  getActivityCompletionPercentage,
  getTotalIterationCount,
} from '@src/pages/workflow/client/workflow-refactor/activity-utils'
import { StartCourseModal } from '@src/pages/workflow/template/start-course-modal.component'
import { VerifyFieldVersionBumpModal } from '@src/pages/workflow/template/verify-field-version-bump.modal'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { modalService } from '@utils/modal.service'
import { toastService } from '@utils/toast.service'
import {
  collection,
  doc,
  getDocs,
  increment,
  query,
  runTransaction,
  where,
} from 'firebase/firestore'
import { intersection, isEmpty, isEqual } from 'lodash'
import { useMemo, useState } from 'react'
import { ulid } from 'ulid'
import {
  calculateIterationCompletion,
  compareFieldIds,
  compareSections,
  formatActivityData,
  getAddedSections,
  getConstraintDifferences,
  sanitizeSections,
} from './publish-utils'

export function useActivityPublisher({
  orgId,
  template,
  sections,
  previousSections,
  onCreateActivity = () => {},
  onPublishTemplate = () => {},
}: {
  orgId: string
  template: Template
  sections: ActivitySection[]
  previousSections: ActivitySection[]
  onCreateActivity?: () => void
  onPublishTemplate?: (newSections?: ActivitySection[]) => void
}) {
  const [publishing, setPublishing] = useState(false)

  const fieldsToDisregard = ['version']

  const noChangesMade = useMemo(() => {
    if (isEmpty(previousSections) || isEmpty(sections) || !template) return true

    const sanitizedPreviousSections = sanitizeSections(
      previousSections,
      fieldsToDisregard,
    )
    const sanitizedSections = sanitizeSections(sections, fieldsToDisregard)

    return isEqual(sanitizedPreviousSections, sanitizedSections)
  }, [sections, previousSections, template])

  if (!orgId) {
    throw new Error('orgId is required')
  }

  if (!template) {
    return {}
  }

  const sectionsRef = doc(
    db,
    `${dbNames.workflowTemplates}/${template.id}/sections/${template.version}`,
  )

  async function publishActivity() {
    try {
      if (template.version === 0) {
        await createActivity()
        onPublishTemplate()
      } else {
        const newSections = await updateActivity()
        if (newSections) {
          onPublishTemplate(newSections as ActivitySection[])
        }
      }
    } catch (err) {
      // Do nothing on error
    }
  }

  async function createActivity() {
    setPublishing(true)
    try {
      await modalService.render(StartCourseModal, {
        orgId,
        template,
        sections,
      })
      onCreateActivity()
      setPublishing(false)
    } catch (err) {
      console.log(err)
    }
  }

  async function updateActivity() {
    setPublishing(true)
    let newSections = sections

    const { addedSections, sameSections, removedSections, sameSectionsOld } =
      getAddedSections(sections, previousSections)

    const { addedFields, removedFields } = compareFieldIds(
      sameSections,
      sameSectionsOld,
    )

    let versionBumpFields = await compareSections(sameSections, sameSectionsOld)
    let fieldsNeedVersionBump = !isEmpty(versionBumpFields)

    const sectionsWithRequirementChanges = getConstraintDifferences(
      sameSections,
      sameSectionsOld,
    )

    const activitiesSnap = await getDocs(
      query(
        collection(db, dbNames.activities),
        where('templateId', '==', template.id),
      ),
    )

    if (fieldsNeedVersionBump) {
      const { resolveType, selectedVersionBumpFields } =
        await modalService.render(VerifyFieldVersionBumpModal, {
          versionBumpFields,
          oldSections: sameSectionsOld,
          sections,
        })

      if (resolveType === 'reject') {
        setPublishing(false)
        return
      }

      versionBumpFields = Object.keys(selectedVersionBumpFields).reduce(
        (accum, sectionId) => {
          if (selectedVersionBumpFields[sectionId].length === 0) {
            return accum
          }

          return {
            ...accum,
            [sectionId]: selectedVersionBumpFields[sectionId],
          }
        },
        [],
      )
      fieldsNeedVersionBump = !isEmpty(versionBumpFields)
    }

    const fieldsWereAddedOrRemoved = !isEmpty(
      Object.values({
        ...addedFields,
        ...removedFields,
      }).reduce((accum: Field[], fields: Field[]) => [...accum, ...fields], []),
    )

    const { activityUsersMap, progressSectionsMap } = await formatActivityData(
      fieldsNeedVersionBump ||
        fieldsWereAddedOrRemoved ||
        !isEmpty(addedSections) ||
        !isEmpty(removedSections) ||
        !isEmpty(sectionsWithRequirementChanges),
      activitiesSnap,
      sections.map(section => section.id),
    )

    try {
      await runTransaction(db, async transaction => {
        for (const activityDoc of activitiesSnap.docs) {
          const activity = activityDoc.data()

          transaction.update(activityDoc.ref, {
            version: increment(1),
          })

          if (addedSections.length > 0 || removedSections.length > 0) {
            for (const activityUserDoc of activityUsersMap[activityDoc.id]) {
              const { userId, activeSectionIndex } = activityUserDoc.data()
              let incrementCount = 0

              for (const addedSection of addedSections) {
                const progressSectionId = `${activityDoc.id}_${userId}_${addedSection.id}`

                const addedSectionIndex = sections.findIndex(
                  section => addedSection.id === section.id,
                )

                if (addedSectionIndex <= activeSectionIndex) {
                  incrementCount++
                }

                const timeZone =
                  Intl.DateTimeFormat().resolvedOptions().timeZone
                const iterationId = ulid()
                const progressSectionDocRef = doc(
                  db,
                  dbNames.progressSections,
                  progressSectionId,
                )
                const iterationDocRef = doc(
                  db,
                  dbNames.sectionIterations,
                  iterationId,
                )

                const progressSectionPayload: Omit<ProgressSection, 'id'> = {
                  userId,
                  sectionId: addedSection.id,
                  activityId: activityDoc.id,
                  lastEditedDate: null,
                  completedDate: null,
                  completedFieldsMap: {},
                  completedPercentage: 0,
                  completedIterationMap: {},
                  iterationOrderIds: [iterationId],
                  isSubmitted: false,
                }

                transaction.set(progressSectionDocRef, progressSectionPayload)

                transaction.set(iterationDocRef, {
                  activityId: activityDoc.id,
                  completedDate: null,
                  createdDate: getTodayDate().toISO(),
                  startDate: {
                    date: getTodayDate().toISO({ includeOffset: false }),
                    timeZone,
                  },
                  sectionId: addedSection.id,
                  iterationId,
                  userId,
                  fieldResponses: {},
                })

                progressSectionsMap[activityDoc.id].push({
                  ...progressSectionPayload,
                  id: progressSectionId,
                })
              }

              for (const removedSection of removedSections) {
                progressSectionsMap[activityDoc.id] = progressSectionsMap[
                  activityDoc.id
                ].filter(
                  progressSection =>
                    progressSection.sectionId !== removedSection.id,
                )
              }

              transaction.update(activityUserDoc.ref, {
                unlockDate: null,
              })
            }
          }

          if (
            !isEmpty(versionBumpFields) ||
            !isEmpty(addedFields) ||
            !isEmpty(removedFields) ||
            !isEmpty(sectionsWithRequirementChanges)
          ) {
            const affectedProgressSections = progressSectionsMap[
              activityDoc.id
            ].filter(progressSection => {
              const sectionIdsAffected = [
                ...Object.keys(versionBumpFields),
                ...Object.keys(addedFields),
                ...Object.keys(removedFields),
                ...Object.keys(sectionsWithRequirementChanges),
              ]
              return sectionIdsAffected.includes(progressSection.sectionId)
            })

            affectedProgressSections.forEach(progressSection => {
              const { sectionId, completedFieldsMap } = progressSection
              if (
                isEmpty(addedFields[sectionId]) &&
                isEmpty(removedFields[sectionId]) &&
                isEmpty(versionBumpFields[sectionId]) &&
                isEmpty(sectionsWithRequirementChanges[sectionId])
              ) {
                return
              }

              const section = sections.find(
                section => section.id === progressSection.sectionId,
              )

              const newRequiredFields = section.fields
                .filter(field =>
                  section.constraints.required.includes(field.id),
                )
                .map(field => field.id)

              const completedFieldIds =
                Object.keys(completedFieldsMap).length > 0
                  ? completedFieldsMap[
                      Object.keys(completedFieldsMap)[0]
                    ].filter(fieldId => {
                      return !versionBumpFields[sectionId]?.includes(
                        fieldId.split('_')[1],
                      )
                    })
                  : []

              const _completedIterationMap: Record<string, number> = {}
              Object.keys(progressSection.completedIterationMap).forEach(
                iterationId => {
                  _completedIterationMap[iterationId] =
                    calculateIterationCompletion(
                      newRequiredFields,
                      completedFieldIds,
                      section.fields,
                    )
                },
              )

              const progressSectionRef = doc(
                db,
                dbNames.progressSections,
                progressSection.id,
              )

              const totalIterationCount = getTotalIterationCount(
                section.constraints,
                activity.startDate,
                activity.endDate,
              )
              const newSectionPercent = calcSectionCompletionPercentage(
                _completedIterationMap,
                totalIterationCount,
              )

              const fieldsThatAffectSubmittedStatus = [
                ...(addedFields[sectionId] || []).map(f => f.id),
                ...(versionBumpFields[sectionId] || []),
              ]

              const progressSectionPayload = {
                completedIterationMap: _completedIterationMap,
                completedPercentage: newSectionPercent,
                completedDate:
                  newSectionPercent === 100 ? getTodayDate().toISO() : null,
              } as Partial<ProgressSection>

              const isSubmittedShouldBeUpdated =
                intersection(fieldsThatAffectSubmittedStatus, newRequiredFields)
                  .length > 0
              const newFieldsContainsRequiredFields =
                (
                  fieldsThatAffectSubmittedStatus.filter(fieldId => {
                    return newRequiredFields.includes(fieldId)
                  }) || []
                ).length > 0

              if (isSubmittedShouldBeUpdated) {
                progressSectionPayload.isSubmitted =
                  !newFieldsContainsRequiredFields
              }

              transaction.update(progressSectionRef, progressSectionPayload)

              const progressSectionIndex = progressSectionsMap[
                activityDoc.id
              ].findIndex(s => s.id === progressSection.id)

              progressSectionsMap[activityDoc.id].splice(
                progressSectionIndex,
                1,
                {
                  ...progressSection,
                  ...progressSectionPayload,
                },
              )
            })
          }

          activityUsersMap[activityDoc.id]?.forEach(activityUserDoc => {
            const userId = activityUserDoc.get('userId')

            const newActivityCompletedPercentage =
              getActivityCompletionPercentage(
                progressSectionsMap[activityDoc.id].filter(
                  p => p.userId === userId,
                ),
                { id: activityDoc.id, ...activityDoc.data() } as Activity,
                sections,
              )
            transaction.update(activityUserDoc.ref, {
              completedPercentage: newActivityCompletedPercentage,
            })
          })
        }

        if (!isEmpty(versionBumpFields)) {
          newSections = sections.map(section => {
            const newFields = section.fields.map(field => {
              if (versionBumpFields[section.id]?.includes(field.id)) {
                return {
                  ...field,
                  version: template.version,
                }
              }
              return field
            })

            return {
              ...section,
              fields: newFields,
            }
          })

          transaction.update(sectionsRef, {
            sections: newSections,
          })
        }
      })

      setPublishing(false)
      toastService.info('Successfully published template changes')
      return newSections
    } catch (err) {
      setPublishing(false)
      console.log(err)
      return toastService.error(
        'An error occurred while publishing the template changes',
      )
    }
  }

  return {
    publishActivity,
    publishing,
    noChangesMade,
  }
}
