import { motion } from 'framer-motion'
import { a, k, useCss } from 'kremling'
import { bool, func, number, oneOfType, string } from 'prop-types'
import React, { createContext, useContext, useEffect, useState } from 'react'

import { NavLink } from '@src/navigation/nav-link.component'

const TabContext = createContext({})

export function Tabs(props) {
  const { value, block, children, className, onChange } = props
  const scope = useCss(css)

  return (
    <TabContext.Provider value={{ onChange, value, block }}>
      <div
        {...scope}
        className={a('tabs rounded-full').m('tabs--block', block).a(className)}
      >
        {children}
      </div>
    </TabContext.Provider>
  )
}

Tabs.propTypes = {
  value: oneOfType([string, number]),
  block: bool,
  border: bool,
  onChange: func,
}

/** Tab button **/
Tabs.Button = function TabButton({ children, id, ...rest }) {
  const { onChange, value, block } = useContext(TabContext)
  const isActive = id && value === id

  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    setIsFirstRender(false)
  }, [])

  return (
    <button
      className={a('tab')
        .m('font-bold text-white', isActive)
        .m('tab-block', block)}
      onClick={() => onChange(id)}
      {...rest}
    >
      {isActive && !isFirstRender && (
        <motion.span
          layoutId="bubble"
          className="absolute inset-0 z-10 bg-primary mix-blend-difference"
          initial={false}
          style={{ borderRadius: 9999 }}
          transition={{ type: 'spring', bounce: 0.2, duration: 0.5 }}
        />
      )}
      <span className="z-20">{children}</span>
    </button>
  )
}

/** Link Tab **/
Tabs.Link = function LinkTab({ children, to, ...rest }) {
  const { block } = useContext(TabContext)
  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    setIsFirstRender(false)
  }, [])

  return (
    <NavLink
      className={a('tab').m('tab-block', block)}
      activeClassName="font-bold text-white"
      to={to}
      {...rest}
    >
      {({ isActive }) => (
        <>
          {isActive && !isFirstRender && (
            <motion.span
              initial={false}
              layoutId="bubble"
              className="absolute inset-0 z-10 bg-primary"
              style={{ borderRadius: 9999 }}
              transition={{ type: 'spring', bounce: 0.2, duration: 0.5 }}
            />
          )}
          <span className="z-20">{children}</span>
        </>
      )}
    </NavLink>
  )
}

const css = k`
  .tabs {
    display: inline-flex;
    flex-wrap: wrap;
    background-color: rgba($color-primary, .1);
    border-radius: 2rem;
  }

  .tab {
    flex: 1;
    padding: 0 1.6rem;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    position: relative;
    height: 4rem;
    border-radius: 2rem;
    transition:
      background-color $form-transition-duration ease,
      color $form-transition-duration ease,
      box-shadow $form-transition-duration ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
  }

  .tab:focus {
    z-index: 1;
    box-shadow: none;
    outline: none;
  }

  .tab:focus-visible {
    box-shadow: $focus-box-shadow;
    z-index: 1;
    outline: none;
  }

  .tab:hover {
    background-color: rgba($color-primary, .15);
    text-decoration: none;
  }

  button.tab {
    border: none;
    background: transparent;
  }

  .active-tab::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: var(--cps-color-primary-text);
    height: .3rem;
    background-color: var(--cps-color-primary);
  }

  .tabs--block  {
    width: 100%;
  }

  .tabs-children--padding {
    padding-left: .8rem;
    padding-right: .8rem;
  }

  .tab-block {
    flex-grow: 1;
  }
`
