import { useAppState } from '@src/app.state'
import { Fields } from '@src/pages/workflow/client/workflow-refactor/fields.component'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import wretch from 'wretch'
import { useActivityStore } from './state/use-activity-state'

export type OutletProps = {
  direction: number
  paginate: (direction: number) => void
  readOnly: boolean
}

export function Section() {
  const { iterationId, sectionId, userId, activityId, orgId } = useParams()
  const sections = useActivityStore.use.sections()
  const fetchIterations = useActivityStore.use.fetchIterations()
  const iterations = useActivityStore.use.iterations()
  const progressSections = useActivityStore.use.progressSections()
  const section = sections.find(s => s.id === sectionId)
  const user = useAppState(state => state.user)

  useEffect(() => {
    fetchIterations(sectionId, userId, activityId)
    return () => {
      useActivityStore.setState({ iterations: [] })
    }
  }, [sectionId, userId, activityId])

  useEffect(() => {
    if (!orgId) return
    wretch(
      `${process.env.USER_ANALYTICS_URL}/api/section/log-start/${activityId}/${sectionId}`,
    ).post({
      user,
      orgId,
    })
  }, [activityId, userId, sectionId, orgId])

  const iteration = useMemo(() => {
    if (isEmpty(iterations)) return {} as SectionIteration
    return iterations.find(i => i.id === iterationId)
  }, [iterations, iterationId])

  if (isEmpty(sections) || isEmpty(iterations) || isEmpty(progressSections))
    return null

  return (
    <div className="w-full media2:max-w-[70rem]">
      <Fields
        key={iterationId}
        iteration={iteration}
        section={section}
      />
    </div>
  )
}
