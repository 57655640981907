import {
  addDoc,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { k, useCss } from 'kremling'
import { sortBy } from 'lodash'
import React, { useState } from 'react'

import { Loader } from '@components/loader.component'
import { Button } from '@components/mantine/button.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Modal } from '@components/modal.component'
import { Required } from '@components/required.component'
import { SelectMulti } from '@components/select-multi.component'
import { useLoad } from '@hooks/use-load.hook'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { toastService } from '@utils/toast.service'

export function AddGroupModal({
  onResolve,
  onReject,
  group,
  orgId,
  ...groupData
}) {
  const isEdit = !!group
  const [name, setName] = useState(isEdit ? group.name : '')
  const [selectedUsers, setSelectedUsers] = useState(
    isEdit ? groupData.groupUsers : [],
  )
  const [searchVal, setSearchVal] = useState('')
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const scope = useCss(css)
  const groupRel = collection(db, dbNames.groups)

  const [users, _setUsers, _usersOpts] = useLoad(
    [],
    () => {
      setLoading(true)
      const q = query(
        collection(db, dbNames.users),
        where(`orgRoles.${orgId}`, '!=', 'deactivated'),
        orderBy(`orgRoles.${orgId}`),
      )
      return getDocs(q)
        .then(q => q.docs.map(u => ({ ...u.data(), id: u.id })))
        .then(users => {
          setLoading(false)
          return sortBy(users, ['lastName'])
        })
    },
    [],
  )

  function addGroup() {
    setLoading(true)
    addDoc(groupRel, {
      name,
      orgId,
      userIds: selectedUsers.map(u => u.id),
    }).then(() => {
      onResolve()
    })
  }

  async function editGroup() {
    setSaving(true)
    try {
      await updateDoc(doc(db, dbNames.groups, group.id), {
        name,
        userIds: selectedUsers.map(u => u.id),
      })
      onResolve()
    } catch (e) {
      onReject()
      toastService.error(e.message)
    }
  }

  function verifyField() {
    if (loading) return true
    return !name || !selectedUsers.length
  }

  return (
    <>
      <Modal.Header>{isEdit ? 'Edit' : 'Add'} Group</Modal.Header>
      <Modal.Body {...scope}>
        <div className="row break-1">
          <div className="col-12">
            <div className="form-group group-info">
              <TextInput
                value={name}
                onChange={setName}
                required
                label="Group name"
              />
            </div>
          </div>
        </div>
        <div className="row break-1">
          <div className="col-12">
            <div className="form-group">
              <label>
                Select users <Required />
              </label>
              <SelectMulti
                data={users.filter(user =>
                  `${user.firstName} ${user.lastName}`
                    .toLowerCase()
                    .includes(searchVal.toLowerCase()),
                )}
                insertSearch
                triggerIsBlock
                onChange={setSelectedUsers}
                searchOnChange={setSearchVal}
                searchValue={searchVal}
                value={selectedUsers}
                fixedContent
                transformData={item => ({
                  ...item,
                  name: `${item.firstName} ${item.lastName}`,
                })}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer {...scope}>
        <Button
          variant="tertiary"
          onClick={onReject}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={isEdit ? editGroup : addGroup}
          disabled={verifyField()}
        >
          Submit
        </Button>
      </Modal.Footer>
      {loading && <Loader overlay />}
      {saving && (
        <div className="loading-overlay">
          <Loader />
        </div>
      )}
    </>
  )
}

AddGroupModal.propTypes = {}

const css = k`
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#fff, .9);
    border-radius: $base-border-radius;
    flex-direction: column;
  }

  .loading-progress {
    width: 20rem;
    padding: 1.6rem;
  }

  .group-info {
    display: flex;
    flex-direction: column;
  }

  .mentor-toggle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .toggle-btn {
    display: flex;
    align-items: center;
  }
`
