import { useAppState } from '@src/app.state'
import { NavLink } from '@src/navigation/nav-link.component'
import { getPrimaryNavList } from '@src/navigation/nav-list'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export function WorkspaceNav() {
  const user = useAppState(state => state.user)
  const orgs = useAppState(state => state.orgs)
  const { orgId } = useParams()
  const isAdmin = ['owner', 'manager', 'editor'].includes(user.orgRoles[orgId])

  const selectedOrg = useMemo(
    () => orgs.find(org => org.id === orgId),
    [orgs, orgId],
  )

  const primaryNavList = getPrimaryNavList(selectedOrg, user)

  if (!isAdmin) return null

  return (
    <div className="hidden justify-center items-center media4:flex h-[6rem]">
      <div className="flex gap-4">
        {orgId &&
          primaryNavList.map(({ path, label }) => (
            <NavLink
              key={label}
              className="btn flex items-center justify-center"
              inactiveClassName="--tertiary"
              activeClassName="--primary"
              to={path}
            >
              {label}
            </NavLink>
          ))}
      </div>
    </div>
  )
}
