import { a } from 'kremling'
import React, { useMemo } from 'react'

import { Checkbox } from '@components/mantine/checkbox.component'
import { useHighlighter } from '@hooks/use-highlighter.hook'
import { ItemBase } from './item-base'

export function ItemMulti({ isSelected, item, value, onChange, searchValue }) {
  const isChecked = useMemo(() => {
    return value.findIndex(v => v.id === item.id) > -1
  }, [value, item])

  const isDisabled = item.disabled && !isChecked
  const onCheckBoxChange = () => {
    if (!isDisabled) {
      onChange(item, true)
    }
  }

  const name = useHighlighter(item.name, searchValue)
  const subName = useHighlighter(item.subName, searchValue)

  return (
    <ItemBase
      id={item.id}
      isSelected={isSelected}
      className={a('item-multi')
        .m('item-multi--selected', isSelected)
        .m('item-multi--disabled', isDisabled)}
      onClick={onCheckBoxChange}
    >
      <div className="item-multi__display-content">
        <div className="item-multi__display">{name}</div>
        {item.subName && (
          <div className="item-multi__display-sub">{subName}</div>
        )}
      </div>
      <Checkbox
        tabIndex="-1"
        checked={isChecked}
        disabled={isDisabled}
        readOnly
      />
    </ItemBase>
  )
}
