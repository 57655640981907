import { httpsCallable } from 'firebase/functions'
import { property, trim, uniqBy } from 'lodash'
import React, { useState } from 'react'

import { Icon } from '@components/icon.component'
import { Loader } from '@components/loader.component'
import { Button } from '@components/mantine/button.component'
import { Modal } from '@components/modal.component'
import { useAppState } from '@src/app.state'
import { functions } from '@src/firebase-app'
import { AddUserInputRow } from '@src/pages/admin/users/add-user-input-row.component'
import { getTodayDate } from '@utils/helpers'
import { toastService } from '@utils/toast.service'
import { validateEmail } from '@utils/validate-email'

const defaultInviteRow = { email: '', orgRole: 'member' }
export function AddUserModal({
  onResolve,
  onReject,
  orgId,
  isFreeTier,
  currentUsers,
}) {
  const [inviteUsers, setInviteUsers] = useState([{ ...defaultInviteRow }])
  const [loading, setLoading] = useState(false)
  const orgs = useAppState(state => state.orgs)
  const selectedOrg = orgs.find(org => org.id === orgId)

  async function addUser() {
    setLoading(true)
    const inviteUser = httpsCallable(functions, 'userFunctions-inviteUser')
    try {
      await inviteUser({
        org: selectedOrg,
        inviteUsers,
        currentUserCount: currentUsers.length,
        today: getTodayDate().toISO(),
      })
      toastService.info('User invite sent')
      onResolve({})
    } catch (e) {
      setLoading(false)
      toastService.error(e.message)
    }
  }

  function updateInvite({ email, orgRole }, index) {
    const trimmedEmail = trim(email)
    const newList = inviteUsers.map((invite, i) => {
      if (index === i) return { email: trimmedEmail, orgRole }
      return invite
    })
    setInviteUsers(newList)
  }

  function addNewInviteRow() {
    const uniqueInvites = uniqBy(
      [...inviteUsers, { ...defaultInviteRow }],
      property('email'),
    )
    setInviteUsers(uniqueInvites)
  }

  function removeInvite(index) {
    const _inviteUsers = [...inviteUsers]
    _inviteUsers.splice(index, 1)
    setInviteUsers(_inviteUsers)
  }

  function disableSubmit() {
    if (loading) return true

    //all invites should have a valid email address
    const invalidEmails = inviteUsers.filter(
      ({ email }) => !validateEmail(email),
    )
    return invalidEmails.length > 0
  }

  return (
    <>
      <Modal.Header>Invite users</Modal.Header>
      <Modal.Body>
        {inviteUsers.map((invite, index, arr) => (
          <AddUserInputRow
            key={index}
            isFreeTier={isFreeTier}
            invite={invite}
            updateInvite={updateInvite}
            isOnlyRow={arr.length === 1}
            removeInvite={removeInvite}
            addNewInviteRow={addNewInviteRow}
            index={index}
            orgId={orgId}
          />
        ))}
        {!isFreeTier && (
          <Button
            variant="secondary"
            onClick={addNewInviteRow}
          >
            <Icon
              name="plus"
              size={15}
            />{' '}
            Add another
          </Button>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="tertiary"
          onClick={onReject}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={addUser}
          disabled={disableSubmit()}
        >
          Submit
        </Button>
      </Modal.Footer>
      {loading && <Loader overlay />}
    </>
  )
}

AddUserModal.propTypes = {}
