import { deleteDoc, doc } from 'firebase/firestore'
import { deleteObject, ref } from 'firebase/storage'
import { k, useCss } from 'kremling'
import { isEmpty } from 'lodash'
import { bool, func, object, string } from 'prop-types'
import React, { useEffect } from 'react'

import { Card } from '@components/card.component'
import { FileUpload } from '@components/file-upload.component'
import { Icon } from '@components/icon.component'
import { Button } from '@components/mantine/button.component'
import { useUpload } from '@hooks/use-upload.hook'
import { useAppState } from '@src/app.state'
import { db, storage } from '@src/firebase-app'
import { FieldHeaderContent } from '@src/pages/workflow/client/fields/field-header-content.component'
import { dbNames } from '@utils/constants'
import { toastService } from '@utils/toast.service'
import { useController } from 'react-hook-form'
import { fileIsImage } from '../fields.utils'

export function WorkflowFileUpload({
  field,
  readOnly,
  workflow = {},
  section,
  isRequired,
  suspendUpload,
  iterationId,
  control,
  uploadRef,
}) {
  const scope = useCss(css)
  const user = useAppState(state => state.user)

  const {
    field: { onChange, ...fieldProps },
    fieldState: { error },
  } = useController({
    name: `${field.version}_${field.id}`,
    control,
    rules: {
      validate: v => {
        if (isEmpty(v)) {
          return 'This field is required'
        }
      },
    },
  })

  const {
    doUpload,
    uploading,
    registeredFiles,
    registerFiles,
    deregisterFiles,
    deregisterAllFiles,
  } = useUpload({
    user,
    uploadToType: 'workflow',
    uploadToPath: `${dbNames.activities}/${workflow.id}/${dbNames.activitySections}/${section.id}/${dbNames.sectionIterations}/${iterationId}/fields/${field.id}`,
  })

  useEffect(() => {
    if (!uploadRef) return
    uploadRef.current = {
      doUpload,
    }
  }, [registeredFiles, uploadRef])

  function handleFileSelection({ files }) {
    if (readOnly) return
    if (suspendUpload) {
      registerFiles(files)
      onChange(files)
    } else {
      registerFiles(files)
    }
  }

  async function handleFileUpload() {
    const result = await doUpload()
    deregisterAllFiles()
    const newUploaded = [...fieldProps.value, ...result].filter(
      f => !(f instanceof File),
    )
    onChange(newUploaded)
  }

  async function handleDeleteFile(path, fileId) {
    const fileRef = ref(storage, path)
    const updatedFileList = fieldProps.value.filter(
      uploadedFile => uploadedFile.path !== path,
    )

    try {
      await deleteObject(fileRef)
      await deleteDoc(doc(db, dbNames.files, fileId))
    } catch (err) {
      await deleteDoc(doc(db, dbNames.files, fileId))
    }

    onChange(updatedFileList)
  }

  function handleRemoveFromUpload(fileUIDs) {
    const newFiles = deregisterFiles(fileUIDs)
    onChange(newFiles)
  }

  function handleCancelUpload() {
    deregisterAllFiles()
  }

  function displayUpload() {
    const filesRegistered = !isEmpty(registeredFiles)
    if (suspendUpload) return false
    return filesRegistered && !uploading
  }

  return (
    <div {...scope}>
      <FieldHeaderContent
        field={field}
        isRequired={isRequired}
        section={section}
      />
      <div className="files-wrapper">
        {!isEmpty(registeredFiles) && (
          <div className="chosen-files-container mb-5">
            {registeredFiles.map(({ preview, file, uid }, index) => {
              if (!file) return null
              if (file.type.includes('image')) {
                return (
                  <div
                    key={index}
                    className="chosen-file"
                  >
                    <img
                      src={preview}
                      alt="image"
                      width="100"
                    />
                    {!uploading && (
                      <button
                        className="delete-file__btn"
                        onClick={e => {
                          e.preventDefault()
                          handleRemoveFromUpload([uid])
                        }}
                      >
                        <Icon
                          name="circle-xmark"
                          size={18}
                        />
                      </button>
                    )}
                  </div>
                )
              } else {
                return (
                  <div
                    key={index}
                    className="chosen-file"
                  >
                    <div className="file">{file.name}</div>
                    {!uploading && (
                      <button
                        className="delete-file__btn --dark"
                        onClick={e => {
                          e.preventDefault()
                          handleRemoveFromUpload([uid])
                        }}
                      >
                        <Icon
                          name="circle-xmark"
                          size={18}
                        />
                      </button>
                    )}
                  </div>
                )
              }
            })}
          </div>
        )}
        <div className="upload-actions">
          <FileUpload
            btnVariant="secondary"
            readOnly={readOnly || uploading}
            onChange={handleFileSelection}
            multiple
            error={error?.message}
            {...fieldProps}
          />
          {displayUpload() && (
            <div className="mt-5">
              <Button
                className="mr-2"
                onClick={handleCancelUpload}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleFileUpload}
              >
                Upload
              </Button>
            </div>
          )}
        </div>
        {!isEmpty(fieldProps.value) && !suspendUpload && (
          <div className="uploaded-files-wrapper mt-5">
            {fieldProps.value.map(({ path, name, url, type, fileId }) => {
              if (!type) return null
              if (fileIsImage({ type })) {
                return (
                  <a
                    key={`${path}_${fileId}`}
                    className="link-container"
                    href={url}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img
                      className="uploaded-image"
                      src={url}
                      alt={name}
                    />
                    {!readOnly && (
                      <button
                        className="delete-file__btn"
                        onClick={e => {
                          e.preventDefault()
                          handleDeleteFile(path, fileId).then(() => {
                            toastService.info('Successfully deleted file.')
                          })
                        }}
                      >
                        <Icon
                          name="circle-xmark"
                          size={18}
                        />
                      </button>
                    )}
                  </a>
                )
              }
              return (
                <a
                  key={path}
                  className="link-container"
                  href={url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Card className="uploaded-file">
                    {name}
                    {!readOnly && (
                      <button
                        className="delete-file__btn --dark"
                        onClick={e => {
                          e.preventDefault()
                          handleDeleteFile(path, fileId).then(() => {
                            toastService.info('Successfully deleted file.')
                          })
                        }}
                      >
                        <Icon
                          name="circle-xmark"
                          size={18}
                        />
                      </button>
                    )}
                  </Card>
                </a>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

WorkflowFileUpload.propTypes = {
  field: object,
  onChange: func,
  value: string,
  readOnly: bool,
}

const css = k`

  .files-wrapper {
    display: flex;
    flex-direction: column;
  }

  .chosen-files-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 10rem);
    grid-auto-rows: 10rem;
    gap: .8rem;
  }

  .chosen-file {
    position: relative;
    border-radius: $base-border-radius;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: $base-border-radius;
      box-shadow: $box-shadow-1;
    }

    .file {
      display: flex;
      align-items: center;
      text-align: center;
      height: 100%;
      width: 100%;
      border-radius: $base-border-radius;
      box-shadow: $box-shadow-1;
    }
  }

  .uploaded-files-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, 10rem);
    grid-auto-rows: 10rem;
    gap: .8rem;
  }

  .files-title {
    margin-bottom: 1.2rem;
    font-size: 1.6rem;
    font-weight: 500;
  }

  .uploaded-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: $base-border-radius;
    transition: background-color .2s ease;

    &:hover {
      background-color: $color-grey-25;
    }
  }

  .uploaded-file {
    height: 100%;
    width: 100%;
    border-radius: $base-border-radius;
    display: flex;
    align-items: center;
    text-align: center;
    transition: background-color .2s ease;

    &:hover {
      background-color: $color-grey-25;
    }
  }

  .delete-file-btn {
    background-color: #fff !important;
    position: absolute;
    top: -1rem;
    right: -1rem;
    border-radius: 2.6rem !important;
    height: 2.6rem !important;
    width: 2.6rem !important;

    &:hover {
      background-color: $color-grey-200 !important;
      backdrop-filter: none;
    }
  }

  .delete-file__btn {
    all: unset;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100vh;
    height: 2rem;
    width: 2rem;
    position: absolute;
    top: .4rem;
    right: .4rem;
    color: #fff;
    cursor: pointer;

    &.--dark {
      color: $color-grey-700;
    }
  }

  .link-container {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: $base-border-radius;
  }

  .link-container:hover {
    text-decoration: none;
  }

  .link-card {
    padding: 0 1rem;
    height: 5rem;
    color: $base-font-color;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .link-card:hover {
    background: $color-grey-25;
  }

  .link-card.has-clicked {
    background: $color-grey-25;
  }

  .upload-actions {
    display: flex;
    justify-content: space-between;
  }

  .field-info {
    display: flex;
    align-items: center;
  }

  .completed-icon {
    color: $color-primary;
  }

  .progress-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: 20rem;
    width: 20rem;

    .progress {
      position: absolute;
      height: 100%;
      left: 0;
      background-color: $color-primary;
      transition: width .5s ease
    }
  }


`
