import { a } from 'kremling'
import { array } from 'prop-types'
import React, { useCallback, useRef, useState } from 'react'

import { TextInput } from '@components/mantine/text-input.component'

export const TriggerMultiInput = props => {
  const {
    isDisabled,
    isOpen,
    open,
    placeholder,
    renderPill,
    searchOnChange,
    searchValue,
    value = [],
  } = props
  const inputEl = useRef(null)
  const [isFocused, setIsFocused] = useState(false)

  const onFocus = useCallback(() => {
    setIsFocused(true)
    inputEl.current.setSelectionRange(10000, 10000)
  }, [])

  const onBlur = useCallback(() => {
    setTimeout(() => {
      if (isOpen) {
        inputEl.current.focus()
      } else {
        setIsFocused(false)
      }
    }, 0)
  }, [setIsFocused, isOpen])

  return (
    <div
      className={a('select-component-input__trigger')
        .m('select-component-input__trigger--focused', isFocused)
        .m('select-component-input__trigger--disabled', isDisabled)}
      onClick={() => {
        if (!isFocused) inputEl.current.focus()
        if (!isOpen) {
          open()
        }
      }}
    >
      <div className="select-component-input__input">
        <TextInput
          icon="search"
          ref={inputEl}
          disabled={isDisabled}
          type="text"
          autoComplete="off"
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={searchOnChange}
          value={searchValue}
        />
      </div>
      {renderPill && !!value.length && (
        <div className="select-component-input__selected">
          <div className="cp-ellipsis">
            {value.length ? renderPill(value) : null}
          </div>
        </div>
      )}
    </div>
  )
}

TriggerMultiInput.propTypes = {
  value: array,
}
