import { Dropdown } from '@components/dropdown.component'
import { Icon } from '@components/icon.component'
import { UploadOptions } from '@hooks/use-file-upload.hook'
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import { sanitizeUrl } from '@utils/validate-url'
import { a, k, useCss } from 'kremling'
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
} from 'lexical'
import React, { Dispatch, useCallback, useEffect, useState } from 'react'
import { getSelectedNode } from '../utils/get-selected-node'
import { InsertImageDialog } from './image-plugin'

type ToolbarPluginProps = {
  uploadOptions: UploadOptions
  disabled: boolean
  setIsLinkEditMode: Dispatch<boolean>
}

export const ToolbarPlugin = ({
  uploadOptions,
  disabled,
  setIsLinkEditMode,
}: ToolbarPluginProps) => {
  const scope = useCss(css)
  const [editor] = useLexicalComposerContext()
  const [isBold, setIsBold] = useState<boolean>(false)
  const [isItalic, setIsItalic] = useState<boolean>(false)
  const [isUnderline, setIsUnderline] = useState<boolean>(false)
  const [isStrikeThrough, setIsStrikeThrough] = useState<boolean>(false)
  const [isCode, setIsCode] = useState<boolean>(false)
  const [blockType, setBlockType] = useState(null)
  const [isLink, setIsLink] = useState(false)

  // const formatBulletList = (e: MouseEvent<HTMLButtonElement>) => {
  //   e.preventDefault()
  //   console.log('BULLET LIST:', blockType)
  //   if (blockType !== 'ul') {
  //     editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
  //   } else {
  //     editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
  //   }
  // }
  //
  // const formatNumberedList = (e: MouseEvent<HTMLButtonElement>) => {
  //   e.preventDefault()
  //   console.log('NUMBER LIST:', blockType)
  //   if (blockType !== 'ol') {
  //     editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
  //   } else {
  //     editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
  //   }
  // }

  const insertLink = useCallback(() => {
    if (!isLink) {
      setIsLinkEditMode(true)
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'))
    } else {
      setIsLinkEditMode(false)
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [editor, isLink, setIsLinkEditMode])

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      const listNode = $getNearestNodeOfType(anchorNode, ListNode)

      if (listNode) {
        const listType = listNode.getListType()
        setBlockType(
          listType === 'number' ? 'ol' : listType === 'bullet' ? 'ul' : null,
        )
      } else {
        setBlockType(null)
      }

      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow()
      const elementKey = element.getKey()
      const elementDOM = editor.getElementByKey(elementKey)
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode)
          const type = parentList ? parentList.getTag() : element.getTag()
          setBlockType(type)
        }
      }
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
      setIsStrikeThrough(selection.hasFormat('strikethrough'))
      setIsCode(selection.hasFormat('code'))

      // Update links
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }
    }
  }, [editor])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()
        })
      }),
    )
  }, [editor, updateToolbar])

  const toggleList = useCallback(
    (listType: 'number' | 'bullet') => {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          const anchorNode = selection.anchor.getNode()
          const parentNode = $getNearestNodeOfType(anchorNode, ListNode)
          if (parentNode && parentNode.getListType() === listType) {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
          } else {
            const command =
              listType === 'number'
                ? INSERT_ORDERED_LIST_COMMAND
                : INSERT_UNORDERED_LIST_COMMAND
            editor.dispatchCommand(command, undefined)
          }
        }
      })
    },
    [editor],
  )

  return (
    <div
      {...scope}
      className="toolbar"
    >
      <button
        disabled={disabled}
        className={a('toolbar-item').m('--active', isBold)}
        onClick={e => {
          e.preventDefault()
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
        }}
        aria-label="Format Bold"
        tabIndex={1}
      >
        <Icon
          name="bold"
          size={14}
        />
      </button>
      <button
        disabled={disabled}
        className={a('toolbar-item').m('--active', isItalic)}
        onClick={e => {
          e.preventDefault()
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
        }}
        aria-label="Format Italic"
        tabIndex={1}
      >
        <Icon
          name="italic"
          size={14}
        />
      </button>
      <button
        disabled={disabled}
        className={a('toolbar-item').m('--active', isUnderline)}
        onClick={e => {
          e.preventDefault()
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
        }}
        aria-label="Format Underline"
        tabIndex={1}
      >
        <Icon
          name="underline"
          size={14}
        />
      </button>
      <button
        disabled={disabled}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
        }}
        className={a('toolbar-item').m('--active', isStrikeThrough)}
        aria-label="Format Strikethrough"
        tabIndex={1}
      >
        <Icon
          name="strikethrough"
          size={14}
        />
      </button>
      <button
        disabled={disabled}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code')
        }}
        className={a('toolbar-item').m('--active', isCode)}
        aria-label="Insert Code"
        tabIndex={1}
      >
        <Icon
          name="code-simple"
          size={14}
        />
      </button>
      <button
        disabled={disabled}
        onClick={() => toggleList('bullet')}
        className={a('toolbar-item').m('--active', blockType === 'ul')}
        aria-label="Insert bullet list"
        tabIndex={1}
      >
        <Icon
          name="list"
          size={14}
        />
      </button>
      <button
        disabled={disabled}
        onClick={() => toggleList('number')}
        className={a('toolbar-item').m('--active', blockType === 'ol')}
        aria-label="Insert number list"
        tabIndex={1}
      >
        <Icon
          name="list-ol"
          size={14}
        />
      </button>
      <button
        disabled={disabled}
        onClick={insertLink}
        className={a('toolbar-item').m('--active', isLink)}
        aria-label="Insert link"
        tabIndex={1}
      >
        <Icon
          name="link"
          size={14}
        />
      </button>
      <Divider />

      <button
        disabled={disabled}
        onClick={e => {
          e.preventDefault()
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')
        }}
        className="toolbar-item spaced"
        aria-label="Left Align"
        tabIndex={1}
      >
        <Icon
          name="align-left-solid"
          size={14}
        />
      </button>
      <button
        disabled={disabled}
        onClick={e => {
          e.preventDefault()
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')
        }}
        className="toolbar-item spaced"
        aria-label="Center Align"
        tabIndex={1}
      >
        <Icon
          name="align-center-solid"
          size={14}
        />
      </button>
      <button
        disabled={disabled}
        onClick={e => {
          e.preventDefault()
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')
        }}
        className="toolbar-item spaced"
        aria-label="Right Align"
        tabIndex={1}
      >
        <Icon
          name="align-right-solid"
          size={14}
        />
      </button>
      <button
        disabled={disabled}
        onClick={e => {
          e.preventDefault()
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')
        }}
        className="toolbar-item"
        aria-label="Justify Align"
        tabIndex={1}
      >
        <Icon
          name="align-justify-solid"
          size={14}
        />
      </button>

      {uploadOptions && (
        <>
          <Divider />

          <Dropdown
            fixedContent
            position="bottom-left"
            renderTrigger={({ toggle }: { toggle: () => void }) => (
              <button
                onClick={toggle}
                className={'toolbar-item'}
                aria-label="Insert Image"
                tabIndex={1}
              >
                <Icon
                  name="image"
                  size={14}
                />
              </button>
            )}
            renderContent={({ close }: { close: () => void }) => (
              <div className="p-2">
                <InsertImageDialog
                  activeEditor={editor}
                  uploadOptions={uploadOptions}
                  close={close}
                />
              </div>
            )}
          />
        </>
      )}
    </div>
  )
}

function Divider() {
  return <div className="divider" />
}

const css = k`
  .toolbar {
    display: flex;
    gap: .4rem;
    background: #fff;
    padding: 4px;
    border-top-left-radius: $base-border-radius;
    border-top-right-radius: $base-border-radius;
    vertical-align: middle;
    border: 1px solid $color-grey-100;
    overflow-x: auto;
  }

  .toolbar .divider {
    width: 1px;
    background-color: $color-grey-75;
    margin: 0 4px;
  }

  .toolbar button.toolbar-item {
    border: 0;
    display: flex;
    background: none;
    border-radius: $base-border-radius;
    padding: 8px;
    cursor: pointer;
    vertical-align: middle;
  }

  .toolbar button.toolbar-item:hover,
  .toolbar button.toolbar-item:focus {
    background: $color-grey-75;
    outline: none;
  }

  .toolbar button.toolbar-item.--active {
    background: $color-grey-500;
    color: #fff;
  }

  .toolbar button.toolbar-item.--active:hover {
    background: $color-grey-500;
  }
`
