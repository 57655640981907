import { Card } from '@components/card.component'
import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { deleteDoc, doc } from 'firebase/firestore'
import React from 'react'

type Props = {
  notification: Notification
}

export function ActivityJoinedNotificationItem({ notification }: Props) {
  const { activityOrgId, activityId, activityName, recipientId } = notification

  return (
    <Card
      className="decoration-0 p-4 flex justify-between mb-2"
      href={`/${activityOrgId}/home/${recipientId}/${activityId}`}
    >
      <div className="flex justify-between">
        <div>
          <div className="flex items-center mb-4 text-2xl font-semibold">
            <Icon
              className="mr-4"
              name="alarm-clock"
              size={20}
            />
            You joined an activity called{' '}
            <span className="font-semibold text-primary text-3xl mx-2">
              {activityName}
            </span>
          </div>
        </div>
        <ActionIcon
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            const d = doc(db, dbNames.notifications, notification.id)
            deleteDoc(d)
          }}
          icon="times"
          size="sm"
        />
      </div>
    </Card>
  )
}
