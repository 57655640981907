import { RRule } from 'rrule'
import { generateNewFieldFromOpenai } from '@src/pages/workflow/fields.helper'
import { ulid } from 'ulid'

export function generateSections(sections) {
  return sections.reduce((accum, idea) => {
    let options = { count: 0 }
    if (idea.recurrence) {
      options = RRule.parseString(idea.recurrence)
    }

    const fields = idea.validationFields.reduce((accum, field) => {
      const newField = generateNewFieldFromOpenai(field)
      if (newField) {
        return [...accum, newField]
      }
    }, [])

    const constraints = {
      recurrence: idea.recurrence || null,
      repeat: options.count,
      required: 'all',
    }

    const newSection = {
      name: idea.action,
      id: ulid(),
      constraints,
      fields,
    }
    return [...accum, newSection]
  }, [])
}

export function parseBulletedList(str) {
  // Regular expression to match bullet points (•, *, or -) followed by a space
  const bulletPointRegex = /[\u2022*-]\s+(?!\s)/g

  // Find all bullet points in the string
  const bulletPoints = [...str.matchAll(bulletPointRegex)]

  // Initialize an empty array to store the list sets
  const listSets = []

  // Iterate through the bullet points
  for (let i = 0; i < bulletPoints.length; i++) {
    // Check if the current bullet point is the start of a new list
    const isNewList =
      i === 0 || bulletPoints[i].index > bulletPoints[i - 1].index + 1

    // If it's the start of a new list, add a new sub-array to the listSets array
    if (isNewList) {
      listSets.push([])
    }

    // Extract the list item between the current bullet point and the next one (or the end of the string)
    const item = str
      .slice(
        bulletPoints[i].index + bulletPoints[i][0].length,
        bulletPoints[i + 1]?.index || str.length,
      )
      .trim()

    // Add the item to the last sub-array in the listSets array
    listSets[listSets.length - 1].push(item)
  }

  return listSets
}
