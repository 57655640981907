import { isStage } from '@src/firebase-app'
import { httpsCallable } from 'firebase/functions'
import { k, useCss } from 'kremling'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import TimePicker from 'react-time-picker'

import { DatePicker } from '@components/date-picker.component'
import { Button } from '@components/mantine/button.component'
import { useAppState } from '@src/app.state'
import { functions, isDevelopment } from '@src/firebase-app'
import { getTodayDate } from '@utils/helpers'
import { toastService } from '@utils/toast.service'

export function TestingTools() {
  const scoped = useCss(css)
  const [runningScheduledItems, setRunningScheduledItems] = useState(false)
  const systemDate = useAppState(state => state.systemDate)
  const currentDate = systemDate ? DateTime.fromISO(systemDate) : getTodayDate()
  const currentTime = currentDate.toJSDate()
  const [wipingActivities, setWipingActivities] = useState(false)
  const orgs = useAppState(state => state.orgs)

  async function runScheduledItems() {
    setRunningScheduledItems(true)
    const scheduleTaskRunnerTest = httpsCallable(
      functions,
      'allFunctions-scheduleTaskRunnerTest',
    )
    try {
      await scheduleTaskRunnerTest({ systemDate: currentDate.toISO() })
      setRunningScheduledItems(false)
    } catch (err) {
      setRunningScheduledItems(false)
      toastService.error('Scheduled items failed')
    }
  }

  async function wipeActivities() {
    setWipingActivities(true)

    try {
      await httpsCallable(
        functions,
        'utility-wipeActivities',
      )({
        orgIds: orgs.map(org => org.id),
      })
      toastService.info('Activities wiped')
    } catch (err) {
      toastService.error('Failed to wipe activities')
      setWipingActivities(false)
      console.log(err)
    }

    setWipingActivities(false)
  }

  const buildVersion = process.env.BUILD_VERSION
  const buildDate = process.env.BUILD_DATE

  return (
    <div
      {...scoped}
      className="tools-overlay"
    >
      <div className="flex gap-10">
        <div className="tools-container">
          <div className="tool-item">
            <div className="text-2xl font-semibold">
              Build branch: {buildVersion.split('-')[0]}
            </div>
            <div className="text-2xl font-semibold">
              Version: {buildVersion.split('-')[1]}
            </div>
            <div className="text-2xl font-semibold">
              Built {DateTime.fromISO(buildDate).toRelative()}
            </div>
          </div>
          <div className="tool-item">
            <label>System Date:</label>
            <DatePicker
              triggerIsBlock
              value={currentDate}
              onChange={date => {
                useAppState.setState({ systemDate: date.toISO() })
              }}
            />
          </div>
          <div className="tool-item">
            <TimePicker
              onChange={newTime => {
                const [hour, minute] = newTime.split(':')
                const dateWithTime = currentDate.set({
                  hour: Number(hour),
                  minute: Number(minute),
                })
                useAppState.setState({ systemDate: dateWithTime.toISO() })
              }}
              value={currentTime}
              disableClock
              clearIcon={null}
              className="time-picker"
            />
          </div>
          <div className="tool-item">
            <label>Run Scheduled Items:</label>
            <Button
              loading={runningScheduledItems}
              variant="primary"
              onClick={runScheduledItems}
            >
              Run
            </Button>
          </div>
        </div>
        <div className="tools-container">
          {(isDevelopment || isStage) && (
            <div className="tool-item">
              <Button
                variant="primary"
                onClick={wipeActivities}
                loading={wipingActivities}
              >
                Wipe activities
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const css = k`
  .tools-overlay {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 30rem;
    background: $color-grey-800;
    z-index: 10000;
    color: white;
  }

  .tools-container {
    padding: 2.4rem;
  }

  .tool-item {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
    margin-bottom: .8rem;
  }

  .react-time-picker {
    background: white;
    height: 3.6rem;
    color: black;
    border-radius: $base-border-radius;
    padding: 0 1.2rem;
    display: flex;
    align-items: center;

    .react-time-picker__wrapper {
      border: none;
    }

    input {
      width: auto !important;
    }
  }
`
