import { k, useCss } from 'kremling'
import React, { useEffect, useRef, useState, useTransition } from 'react'
import { ulid } from 'ulid'

import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { Button } from '@components/mantine/button.component'
import { TextInput } from '@components/mantine/text-input.component'
import { closestCenter, DndContext, useDroppable } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useDebounce } from '@hooks/use-debounce.hook'

export function ChoiceBuilder({ onChange, field, title }) {
  const [choices, setChoices] = useState(field.choices)
  const scope = useCss(css)
  const inputRefs = useRef({})
  const updateDebounce = useDebounce(newChoices => onChange(newChoices), 500)
  const prevChoices = usePrevious(choices)
  const [_isPending, startTransition] = useTransition()

  useEffect(() => {
    if (!prevChoices) return
    if (prevChoices.length < choices.length) {
      const lastChoice = choices.at(-1)
      inputRefs.current[lastChoice.id].focus()
    }
  }, [choices.length])

  function handleUpdate(val, index) {
    const _choices = window.structuredClone(choices)
    _choices[index].label = val
    setChoices(_choices)
    startTransition(() => {
      updateDebounce(_choices)
    })
  }

  function addChoice() {
    const id = ulid()
    const _choices = [...choices]
    _choices.push({ label: '', id })
    setChoices(_choices)
  }

  function removeChoice(choiceId) {
    const _choices = window.structuredClone(choices)
    const index = _choices.findIndex(choice => choice.id === choiceId)
    const previousInList = _choices[index - 1]
    if (_choices.length === 1) {
      _choices.splice(index, 1, { id: ulid(), label: '' })
    } else {
      _choices.splice(index, 1)
      inputRefs.current[previousInList.id]?.focus()
    }
    setChoices(_choices)
    startTransition(() => {
      updateDebounce(_choices)
      delete inputRefs.current[choiceId]
    })
  }

  function onDragEnd({ active, over }) {
    if (active.id !== over.id) {
      const activeIndex = choices.findIndex(choice => choice.id === active.id)
      const overIndex = choices.findIndex(choice => choice.id === over.id)

      const newChoices = arrayMove(choices, activeIndex, overIndex)

      setChoices(newChoices)
      onChange(newChoices)
    }
  }

  const { setNodeRef: setDroppableRef } = useDroppable({
    id: `${field.id}-choices-droppable`,
  })

  return (
    <>
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={onDragEnd}
      >
        <div
          {...scope}
          ref={setDroppableRef}
        >
          <label>{title}</label>
          <SortableContext
            items={choices.map(({ id }) => id)}
            strategy={verticalListSortingStrategy}
          >
            {choices.map((choice, index) => {
              return (
                <Choice
                  choice={choice}
                  key={choice.id}
                  addChoice={addChoice}
                  prevChoices={prevChoices}
                  removeChoice={removeChoice}
                  handleUpdate={handleUpdate}
                  setInputRefs={el => {
                    inputRefs.current[choice.id] = el
                  }}
                  index={index}
                />
              )
            })}
          </SortableContext>
        </div>
      </DndContext>
      <Button
        onClick={addChoice}
        variant="secondary"
      >
        + Add choice
      </Button>
    </>
  )
}

function Choice({
  choice,
  addChoice,
  prevChoices,
  removeChoice,
  handleUpdate,
  setInputRefs,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    index,
  } = useSortable({
    id: choice.id,
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    position: 'relative',
    zIndex: isDragging ? 1 : undefined,
  }

  function handleKeyDown(e) {
    if (e.repeat) return
    if (e.key === 'Enter') {
      addChoice()
    }
  }

  function handleKeyUp(e, index) {
    if (e.keyCode == 8) {
      const previousState = prevChoices[index]
      if (previousState?.label === '') {
        removeChoice(previousState.id)
      }
    }
  }

  return (
    <div
      key={choice.id}
      style={style}
      ref={setNodeRef}
      className="choice-item"
    >
      <div tabIndex="-1">
        <button
          {...listeners}
          {...attributes}
          className="drag-icon"
        >
          <Icon name="grip-dots-vertical" />
        </button>
      </div>
      <TextInput
        placeholder="Choice label"
        value={choice.label}
        ref={setInputRefs}
        onChange={val => handleUpdate(val, index)}
        onKeyDown={handleKeyDown}
        onKeyUp={e => handleKeyUp(e, index)}
      />
      <ActionIcon
        size="sm"
        className="ml-1"
        onClick={() => removeChoice(choice.id)}
        icon="trash"
      />
    </div>
  )
}

const css = k`
  .choice-item {
    display: flex;
    align-items: center;
    margin-bottom: .8rem;
    cursor: default;
  }

  .drag-icon {
    margin-right: 1.2rem;
    user-select: none;
  }
`

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}
