import { k, useCss } from 'kremling'
import React from 'react'

import { TextInput } from '@components/mantine/text-input.component'
import { Switch } from '@components/radix/switch'
import { RichText } from '@components/rich-text/rich-text.component'

export function FileUpload({
  field,
  onChange,
  isRequired,
  updateRequiredToggle,
}) {
  const scope = useCss(css)

  function updateFieldLabel(value) {
    if (value === field.label) return
    const updatedField = {
      ...field,
      label: value,
    }
    onChange(updatedField, true)
  }

  function updateFieldDescription(value) {
    const updatedField = {
      ...field,
      description: value,
    }
    onChange(updatedField)
  }

  return (
    <div
      {...scope}
      className="space-y-6"
    >
      <div className="flex items-center">
        <Switch
          id={`required-switch-${field.id}`}
          onChange={value => updateRequiredToggle(value, field.id)}
          className="mr-2"
          value={isRequired}
        />
        <label htmlFor={`required-switch-${field.id}`}>Required</label>
      </div>
      <div className="form-group">
        <TextInput
          label="Name the field"
          defaultValue={field.label}
          onChange={updateFieldLabel}
          placeholder="e.g. Upload a file"
          autoFocus
        />
      </div>
      <div className="form-group">
        <label htmlFor="text-field-desc">Describe this field</label>
        <RichText
          id="text-field-desc"
          onChange={updateFieldDescription}
          initialValue={field ? field.description : null}
          placeholder="Type here..."
        />
      </div>
    </div>
  )
}

FileUpload.propTypes = {}

const css = k`

`
