import {
  ActionIcon,
  Button,
  Checkbox,
  Input,
  NumberInput,
  PillsInput,
  PillsInputField,
  PinInput,
  Radio,
  TextInput,
  createTheme,
} from '@mantine/core'

export const theme = createTheme({
  components: {
    Button: Button.extend({
      classNames: {
        root: 'btn data-[block=true]:w-full',
      },
    }),
    ActionIcon: ActionIcon.extend({
      classNames: {
        root: 'btn --icon',
      },
    }),
    TextInput: TextInput.extend({
      classNames: {
        input: 'form-input text-xl !pr-12 data-[error=true]:shadow-danger',
      },
    }),
    Input: Input.extend({
      classNames: {
        wrapper: 'flex relative data-[size=lg]:h-14 data-[size=sm]:h-9',
        section: `
          absolute
          data-[position=right]:right-1
          data-[position=right]:flex
          data-[position=right]:items-center
          data-[position=right]:justify-center
          data-[position=left]:left-0
          data-[position=left]:flex
          data-[position=left]:items-center
          data-[position=left]:justify-center
          h-full
          w-12
        `,
      },
    }),
    InputWrapper: Input.Wrapper.extend({
      classNames: {
        error: 'text-danger text-xl mt-1',
        required: 'text-danger',
      },
    }),
    NumberInput: NumberInput.extend({
      classNames: {
        wrapper: 'data-[size=xl]:h-14',
        input: 'form-input text-xl data-[error=true]:shadow-danger',
        section: 'data-[position=right]:!right-0',
      },
    }),
    PillsInput: PillsInput.extend({
      classNames: {
        input: 'form-input text-xl !pr-12 data-[error=true]:shadow-danger',
      },
    }),
    PillsInputField: PillsInputField.extend({
      classNames: {
        field: '!h-full w-full bg-danger',
      },
    }),
    PinInput: PinInput.extend({
      classNames: {
        root: 'flex gap-2',
        input: 'form-input text-4xl text-center',
      },
    }),
    Checkbox: Checkbox.extend({
      classNames: {
        label: 'text-xl',
        body: 'items-center',
        input: 'h-6 w-6 rounded-sm',
        inner: 'h-auto w-auto',
      },
    }),
    CheckboxGroup: Checkbox.Group.extend({
      classNames: {
        error: '!text-danger !text-xl mt-1',
      },
    }),
    Radio: Radio.extend({
      classNames: {
        label: 'text-xl',
        body: 'items-center',
        radio: 'h-7 w-7',
        inner: 'h-auto w-auto',
      },
    }),
    RadioGroup: Radio.Group.extend({
      classNames: {
        error: '!text-danger !text-xl mt-1',
      },
    }),
  },
})
