import { Button } from '@components/mantine/button.component'
import { k, useCss } from 'kremling'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { Tags } from './tags.component'

interface TagListProps {
  assignedTags: string[]
  suggestions?: string[]
  onTagsChange?: (tags: string[]) => void
  onSave: (tags: string[]) => void
  onInputChange: (text: string) => void
  disabled?: boolean
}

const TagList: React.FC<TagListProps> = ({
  assignedTags,
  suggestions,
  onSave,
  onInputChange,
  disabled,
}) => {
  const [editing, setEditing] = useState<boolean>(false)
  const [tags, setTags] = useState(assignedTags)
  const scope = useCss(css)

  const handleEditButtonClick = () => {
    setEditing(!editing)
  }

  const handleTagsChange = (newTags: string[]) => {
    setTags(newTags)
  }

  return (
    <div
      {...scope}
      className="tag-list__container"
    >
      {editing ? (
        <Tags
          suggestions={suggestions}
          onTagsChange={handleTagsChange}
          onInputChange={onInputChange}
          tags={tags}
        />
      ) : (
        !isEmpty(assignedTags) && (
          <div className="tag-list">
            {assignedTags.map((tag, index) => (
              <div
                key={index}
                className="tag"
              >
                {tag}
              </div>
            ))}
          </div>
        )
      )}
      {editing ? (
        <>
          <Button
            size="sm"
            className="ml-2"
            variant="primary"
            onClick={() => {
              onSave(tags)
              setEditing(false)
            }}
          >
            Save
          </Button>
          <Button
            size="sm"
            variant="tertiary"
            className="ml-2"
            onClick={() => {
              setEditing(false)
              setTags(assignedTags)
            }}
          >
            Cancel
          </Button>
        </>
      ) : (
        <Button
          variant="secondary"
          size="sm"
          onClick={handleEditButtonClick}
          disabled={disabled}
        >
          Edit tags
        </Button>
      )}
    </div>
  )
}

export default TagList

const css = k`
  .tag-list__container {
    display: flex;
    align-items: center;
  }

  .tag-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: 8px;
  }

  .tag {
    display: flex;
    align-items: center;
    background-color: $color-primary;
    color: white;
    padding: 6px 5px;
    margin: 2px;
    border-radius: 3px;
    max-height: 24px;
    line-height: 1.2rem;
  }
`
