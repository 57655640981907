import { Card } from '@components/card.component'
import { CopyTextBar } from '@components/copy-text-bar.component'
import { DatePicker } from '@components/date-picker.component'
import { EmptyState } from '@components/empty-state.component'
import { Icon } from '@components/icon.component'
import { Button } from '@components/mantine/button.component'
import { Pill } from '@components/pill/pill.component'
import { Switch } from '@components/radix/switch'
import { Required } from '@components/required.component'
import { TextDivider } from '@components/text-divider.component'
import { Well } from '@components/well.component'
import { useDebounce } from '@hooks/use-debounce.hook'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import ShareSettings from '@src/pages/workflow/settings/activity-settings/share-settings.component'
import { ANYONE_WORKSPACE } from '@src/pages/workflow/settings/activity-settings/share-utils'
import { dbNames } from '@utils/constants'
import { getTodayDate, getUserFullName } from '@utils/helpers'
import { modalService } from '@utils/modal.service'
import { toastService } from '@utils/toast.service'
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { k, useCss } from 'kremling'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import QRCode from 'qrcode'
import React, { useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { AddParticipantsModal } from './add-participants-modal.component'

type OutletProps = {
  activity: Activity
  participants: ActivityUser[]
}

export function ActivityParticipants() {
  const { activity, participants } = useOutletContext<OutletProps>()
  const scope = useCss(css)
  const { orgId } = useParams()
  const systemDate = useAppState(state => state.systemDate)
  const [selectedShareSetting, setSelectedShareSetting] =
    useState<InviteAccesType>(null)
  const today = systemDate
    ? DateTime.fromISO(systemDate).startOf('day')
    : getTodayDate().startOf('day')
  const [showEnrollment, setShowEnrollment] = useState(false)

  const [linkInvite, setLinkInvite] = useLoad(
    null,
    () => {
      if (!activity.id) return Promise.resolve(null)
      const q = query(
        collection(db, dbNames.invitations),
        where('itemId', '==', activity.id),
      )
      return getDocs(q).then(q => {
        const doc = q.docs[0]
        const docData = { ...doc.data(), id: doc.id } as LinkInvitation
        setSelectedShareSetting(docData.accessType)
        setShowEnrollment(docData.expirationDate !== null)
        return docData
      })
    },
    [activity?.id],
  )

  async function updateInvitation(
    payload: Partial<LinkInvitation>,
  ): Promise<void> {
    if (useAppState.getState().isFreeTier) return
    const d = doc(db, dbNames.invitations, linkInvite.id)
    const newLinkInvite = { ...linkInvite, ...payload }
    setLinkInvite(newLinkInvite)
    await updateDoc(d, newLinkInvite)
  }
  const updateInvitationDebounced = useDebounce(updateInvitation, 1000)

  function updateShareStatus(sharing: boolean, accessType?: InviteAccesType) {
    if (useAppState.getState().isFreeTier) return
    if (sharing) {
      if (accessType) {
        updateInvitationDebounced({ accessType })
      } else {
        updateInvitationDebounced({
          accessType: [ANYONE_WORKSPACE],
        })
      }
    } else {
      updateInvitationDebounced({ accessType: null })
    }
  }

  function handleUpdateEnrollmentEndDate(newDate: DateTime) {
    if (newDate === null) {
      updateInvitationDebounced({ expirationDate: null })
      return
    }

    const date = newDate.toISO({ includeOffset: false })
    const timeZone = newDate.zoneName
    const newEnrollmentDate: FutureDate = { date, timeZone }
    setLinkInvite({ ...linkInvite, expirationDate: newEnrollmentDate })
    updateInvitationDebounced({ expirationDate: newEnrollmentDate })
  }

  async function handleAddParticipants() {
    try {
      await modalService.render(AddParticipantsModal, {
        orgId,
        participants,
        activity,
      })
      //TODO this should reload here
      //participantsOpts.reload()
    } catch (err) {
      toastService.error('Failed to add participants. Please try again.')
    }
  }

  function generateQRCode() {
    const options = {
      errorCorrectionLevel: 'H',
      margin: 2,
      width: 400,
    }
    // @ts-expect-error - types are wrong
    QRCode.toDataURL(shareLink, options, (err: unknown, dataUrl: string) => {
      if (err)
        return toastService.error('There was an error generating your QR Code')
      const fileName = `qrcode_${activity.name
        .toLowerCase()
        .split(' ')
        .join('_')}`
      download(dataUrl, fileName)
    })
  }

  function download(dataurl: string, filename: string) {
    const link = document.createElement('a')
    link.href = dataurl
    link.download = filename
    link.click()
  }

  const shareLink = `${window.location.origin}/activity/${linkInvite?.linkId}`

  return (
    <div
      {...scope}
      className="pt-8"
    >
      <div className="row break-2">
        <div className="col-8">
          <div className="flex flex-col gap-4 mb-10">
            <div className="section__header">
              <div className="section__title">
                <Icon
                  name="lock-keyhole"
                  className="mr-4"
                />
                Access
              </div>
            </div>
            <div className="relative">
              {useAppState.getState().isFreeTier && (
                <div className="absolute top-0 bottom-0 left-0 right-0 bg-white/30 z-10 backdrop-blur-md flex items-center justify-center rounded-lg">
                  <Card>
                    <div className="p-8 flex justify-center items-center flex-col">
                      <Icon
                        className="text-primary mb-4"
                        name="lock"
                        size={30}
                      />
                      <div className="text-2xl font-semibold">
                        Link sharing is a paid feature
                      </div>
                      <div>
                        Click{' '}
                        <a
                          href="www.iact.com"
                          target="_blank"
                        >
                          here
                        </a>{' '}
                        to learn more!
                      </div>
                    </div>
                  </Card>
                </div>
              )}
              <Well className="flat-well mt-5">
                <Well.Header>
                  <div className="toggle__header">
                    <Switch
                      id="enrollment-window-toggle"
                      onChange={newToggle => {
                        setShowEnrollment(newToggle)
                        if (!!linkInvite.expirationDate) {
                          handleUpdateEnrollmentEndDate(null)
                        }
                      }}
                      className="mr-2"
                      value={showEnrollment}
                    />
                    <label htmlFor="enrollment-window-toggle">
                      Enrollment window
                    </label>
                  </div>
                </Well.Header>
                {showEnrollment && (
                  <Well.Body>
                    <div className="mb-16">
                      Set date after which participants can no longer enroll in
                      this activity.
                    </div>
                    <div className="row break-2">
                      <div className="col-4">
                        <label>
                          Enrollment close date <Required />
                        </label>
                        <DatePicker
                          // @ts-expect-error - types are wrong
                          triggerIsBlock
                          placeholder="Select date"
                          onChange={handleUpdateEnrollmentEndDate}
                          minDate={today.plus({ day: 1 })}
                          maxDate={
                            activity.endDate
                              ? DateTime.fromISO(activity.endDate.date, {
                                  zone: activity.endDate.timeZone,
                                })
                              : null
                          }
                          value={
                            linkInvite?.expirationDate
                              ? DateTime.fromISO(
                                  linkInvite.expirationDate.date,
                                  {
                                    zone: linkInvite.expirationDate.timeZone,
                                  },
                                )
                              : null
                          }
                        />
                      </div>
                    </div>
                  </Well.Body>
                )}
              </Well>
              <Well className="flat-well">
                <Well.Header>
                  <div className="toggle__header">
                    <Switch
                      onChange={newToggle => {
                        if (newToggle) {
                          setSelectedShareSetting([ANYONE_WORKSPACE])
                          updateShareStatus(true)
                        } else {
                          setSelectedShareSetting(null)
                          updateShareStatus(false, null)
                        }
                      }}
                      value={!!selectedShareSetting}
                      id="sharing-options-toggle"
                      className="mr-2"
                    />
                    <label htmlFor="sharing-options-toggle">Link sharing</label>
                  </div>
                </Well.Header>
                {!!selectedShareSetting && (
                  <Well.Body>
                    <ShareSettings
                      selectedValue={selectedShareSetting}
                      onChange={data => {
                        setSelectedShareSetting(data)
                        updateShareStatus(true, data)
                      }}
                    />
                    <TextDivider className="mb-4 mt-4" />
                    <div className="link-sharing__footer">
                      <CopyTextBar
                        className="mr-2"
                        shareText={shareLink}
                      />
                      <Button
                        onClick={generateQRCode}
                        variant="secondary"
                        leftSection={
                          <Icon
                            size={15}
                            name="qrcode"
                          />
                        }
                      >
                        Download QR code
                      </Button>
                    </div>
                  </Well.Body>
                )}
              </Well>
            </div>
          </div>
          <div className="mb-3">
            <div className="section__header">
              <div className="section__title">
                <Icon
                  name="users"
                  className="mr-4"
                />
                Participants
                <Pill className="ml-2">
                  {participants.filter(p => !p.deactivated).length} Active
                </Pill>
                <Pill className="ml-2">
                  {participants.filter(p => p.deactivated).length} Deactivated
                </Pill>
              </div>
              <Button
                variant="secondary"
                onClick={handleAddParticipants}
                leftSection={
                  <Icon
                    size={14}
                    name="plus"
                  />
                }
              >
                Add participants
              </Button>
            </div>
            <div>
              {isEmpty(participants) ? (
                <EmptyState
                  title="No participants"
                  subtitle="Participants will appear here once they have been added."
                  action={handleAddParticipants}
                  actionText={
                    <>
                      <Icon
                        size={14}
                        name="plus"
                      />{' '}
                      Add participants
                    </>
                  }
                />
              ) : (
                participants.map((activityUser: ActivityUser) => (
                  <div
                    key={activityUser.id}
                    className="participant__item"
                  >
                    <div className="participant__icon">
                      <Icon name="user-circle" />
                    </div>
                    <div className="participant_info">
                      <div>
                        <div className="participant__name">
                          {getUserFullName(activityUser)}{' '}
                          {activityUser.deactivated && (
                            <Pill
                              pillType="danger"
                              className="ml-4"
                            >
                              DEACTIVATED
                            </Pill>
                          )}
                        </div>
                        <div>{activityUser.userEmail}</div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const css = k`
  .toggle__header {
    display: flex;
    align-items: center;

    label {
      margin-bottom: 0;
    }
  }

  .section__header {
    display: flex;
    align-items: center;
    margin-bottom: 1.6rem;
    justify-content: space-between;
  }

  .section__title {
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  .share-option__item {
    display: flex;
    flex-direction: column;
    padding: .4rem;
  }

  .share-option__item.selected > .share-option__label {
    color: $color-primary;
    font-weight: 500;
  }

  .share-option__item.selected > .share-option__label .share-option__icon {
    background-color: $color-active-75;
    outline: solid 2px $color-primary;
  }

  .share-option__label {
    display: flex;
    align-items: center;
    flex: 1;

    label {
      margin-bottom: 0;
      flex: 1;
    }
  }

  .share-option__icon {
    padding: 8px;
    border-radius: 4px;
    margin-right: 8px;
  }

  .flat-well {
    box-shadow: none !important;
  }

  .participant__item {
    width: 100%;
    display: flex;
    margin-bottom: 12px;
  }

  .participant__icon {
    padding-right: 20px;
    padding-top: 4px;
  }

  .participant_info {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  .participant__name {
    font-weight: 700;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
  }

  .link-sharing__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
