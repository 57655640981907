import { a, k, useCss } from 'kremling'
import React, { ReactNode } from 'react'

export type PillType =
  | 'primary'
  | 'light'
  | 'tertiary'
  | 'warning'
  | 'danger'
  | ''

type PillProps = {
  children?: ReactNode
  className?: string
  pillType?: PillType
  split?: boolean
  title?: string
  value?: string
  width?: number
  height?: number
  centerContent?: boolean
}

export function Pill(props: PillProps) {
  const {
    children,
    className = '',
    pillType,
    split,
    title,
    value,
    width,
    height,
    centerContent,
  } = props
  const scoped = useCss(css)

  let colorClass
  switch (pillType) {
    case 'primary':
      colorClass = 'primary'
      break
    case 'light':
      colorClass = 'light'
      break
    case 'tertiary':
      colorClass = 'tertiary'
      break
    case 'warning':
      colorClass = 'warning'
      break
    case 'danger':
      colorClass = 'danger'
      break
    default:
      colorClass = 'default'
  }

  if (split) {
    return (
      <div
        {...scoped}
        className={a('pill__outer split')
          .a(colorClass)
          .m(className, !!className)}
        style={{
          width: width || null,
          height: height || null,
          justifyContent: centerContent ? 'center' : null,
        }}
      >
        <div className="pill__title">{title}</div>
        <div
          style={{ height: height || null }}
          className="pill__value"
        >
          {value}
        </div>
      </div>
    )
  }

  return (
    <div
      {...scoped}
      style={{
        width: width || null,
        height: height || null,
        justifyContent: centerContent ? 'center' : null,
      }}
      className={a('pill__outer').a(colorClass).m(className, !!className)}
    >
      {children}
    </div>
  )
}

const css = k`
  .pill__outer {
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    border-radius: 100vh;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: initial;
  }

  .pill__outer {
    padding: 3px 8px;
  }

  .pill__title,
  .pill__value {
    padding: 2px 6px;
  }

  .pill__outer.split {
    padding: 0;
    border: solid 1px;
  }

  .pill__title,
  .pill__value {
    display: flex;
    align-items: center;
  }

  .pill__value {
    background: white;
    color: $color-grey-400;
  }

  .default {
    background: $color-grey-400;
    color: white;
  }

  .light {
    background: $color-grey-50;
  }

  .primary {
    background: $color-primary;
    color: white;
  }

  .warning {
    background: $color-warning;
  }

  .danger {
    background: $color-danger;
    color: white;

    .pill__value {
      color: $color-danger;
    }
  }

  .pill__outer.default {
    border-color: $color-grey-400;
  }

  .pill__outer.light {
    border-color: $color-grey-50;
  }

  .pill__outer.warning {
    border-color: $color-warning;
  }

  .pill__outer.danger {
    border-color: $color-danger;
  }

  .pill__outer.primary {
    border-color: $color-primary;
  }

  .pill__outer.tertiary {
    background: $color-grey-100;
  }

  .pill__outer.primary .pill__value {
    color: $color-primary;
  }
`
