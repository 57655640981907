import { a, k, useCss } from 'kremling'
import React, { ReactNode } from 'react'

import { Card, CardProps } from '@components/card.component'
import { Dropdown } from '@components/dropdown.component'
import { Icon } from '@components/icon.component'
import { Loader } from '@components/loader.component'
import { ActionIcon } from '@components/mantine/action-icon.component'

type CardListItemProps = Partial<CardProps> & {
  className?: string
  cardTitle: ReactNode
  subTitle?: ReactNode
  cardHeight?: string | number
  renderActionsContent?: () => ReactNode
  renderRightContent?: () => ReactNode
  renderBottomContent?: () => ReactNode
  icon?: string
  iconClass?: string
  loading?: boolean
  inner?: boolean
}

export function CardListItem({
  className = '',
  cardTitle,
  subTitle = '',
  cardHeight,
  renderActionsContent,
  renderRightContent,
  renderBottomContent,
  icon,
  iconClass,
  loading,
  inner,
  ...cardProps
}: CardListItemProps) {
  const scope = useCss(css)

  return (
    <div
      {...scope}
      style={{ height: cardHeight || 'auto' }}
      className={a('card__wrapper').m('card__inner', inner)}
    >
      <Card
        to={''}
        {...cardProps}
        className={a('card__interior', className).m(
          'card-interior__inner',
          inner,
        )}
      >
        {loading ? (
          <div className="card__loader">
            <Loader size="sm" />
          </div>
        ) : (
          <div className="card__content">
            <div className="card__top">
              <div className="card__header flex-ellipsis">
                {icon && (
                  <Icon
                    name={icon}
                    className={a('mr-4 card__icon', iconClass)}
                  />
                )}
                <div className="card__info">
                  <div className="card__title truncate">{cardTitle}</div>
                  <div className="card__subtitle truncate">{subTitle}</div>
                </div>
              </div>
              {renderRightContent && (
                <div className="card__center">{renderRightContent()}</div>
              )}
              {renderActionsContent && (
                <div className="card__actions">
                  <Dropdown
                    fixedContent
                    position="bottom-left"
                    renderTrigger={({ toggle }: { toggle: () => void }) => (
                      <ActionIcon
                        size="sm"
                        variant="tertiary"
                        icon="ellipsis-v"
                        onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                          toggle()
                        }}
                      />
                    )}
                    renderContent={renderActionsContent}
                  />
                </div>
              )}
            </div>
            {renderBottomContent && (
              <div className="card__bottom">{renderBottomContent()}</div>
            )}
          </div>
        )}
      </Card>
    </div>
  )
}

const css = k`
  .card__wrapper {
    align-items: center;
  }

  .card__wrapper.card__inner {
    margin-bottom: 0;
    background-color: #fff;

    a {
      box-shadow: none;
      border-radius: 0;
      text-decoration: none;
      color: $color-grey-900;
      transition: box-shadow $form-transition-duration ease;

      &:hover {
        background-color: rgba($color-primary, .05);
      }
      &:focus, &:active {
        outline: none;
      }
      &:focus {
        box-shadow: $focus-box-shadow-inset;
      }
    }
  }

  .card__interior {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem 1.6rem;
    font-weight: 500;
    flex-basis: 100%;
  }

  .card__interior {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem 1.6rem;
    font-weight: 500;
  }

  .card__content,
  .card__bottom {
    width: 100%
  }

  .card__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card__bottom {
    margin-top: .8rem;
  }

  .card__header {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }

  // .card__icon {
  //   color: $color-grey-400;
  // }

  .card__info {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .card__title {
    font-size: 1.6rem;
    font-weight: 600;
  }

  .card__subtitle {
    font-size: 1.2rem;
    font-weight: 400;
  }

  .card__center {
    display: flex;
    justify-content: flex-end;
  }

  .card__actions {
    margin-left: 8px;
  }

  .card__loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
