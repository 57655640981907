export function getOrgRoles(role) {
  const isOwner = role === 'owner'

  const memberRole = {
    label: 'Member',
    value: 'member',
    description: 'Track and encourage Workflow activity',
  }

  const mentorRole = {
    label: 'Mentor',
    value: 'mentor',
    description: 'Specialists assigned by an org',
  }

  const editorRole = {
    label: 'Editor',
    value: 'editor',
    description: 'Manage templates',
  }

  const managerRole = {
    label: 'Manager',
    value: 'manager',
    description: 'Manage people and groups and assign workflows',
  }

  const ownerRole = {
    label: 'Owner',
    value: 'owner',
    description: 'Manage the organization',
  }

  return [
    memberRole,
    mentorRole,
    editorRole,
    isOwner && managerRole,
    isOwner && ownerRole,
  ].filter(role => role)
}
