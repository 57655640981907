import React from 'react'
import { useParams } from 'react-router-dom'

import { useAppState } from '@src/app.state'
import { AdminLibrary } from '@src/pages/library/workspace/admin-library.component'
import { WorkspaceLibrary } from '@src/pages/library/workspace/workspace-library.component'

export function Library() {
  const user = useAppState(state => state.user)
  const { orgId } = useParams()

  if (!user.orgRoles) {
    return null
  }

  const isAdmin = ['owner', 'manager', 'editor'].includes(user.orgRoles[orgId])
  if (isAdmin) {
    return <AdminLibrary />
  }

  return <WorkspaceLibrary />
}
