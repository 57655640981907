import { ActionIcon } from '@components/mantine/action-icon.component'
import { Button } from '@components/mantine/button.component'
import { TextDivider } from '@components/text-divider.component'
import { Tooltip } from '@components/tooltip.component'
import { Well } from '@components/well.component'
import { ChoiceItemList } from '@src/pages/workflow-template-list/template-generator/choice-item-list.component'
import { isEmpty } from 'lodash'
import React, { useRef } from 'react'

export function TouchpointsState({
  state,
  send,
  updateSelectedItems,
  handleReport,
}) {
  const { selectedItems, lists, rawResponses } = state.context

  const initialSelected = useRef(selectedItems.sections)
  const selectedChanged = initialSelected.current !== selectedItems.sections

  function onSelectSection(section) {
    const { context } = send({ type: 'sectionSelected', section })
    updateSelectedItems('sections', context.selectedItems.sections)
  }

  return (
    <Well>
      <Well.Header
        renderActions={() => (
          <Tooltip content="Report an issue">
            <ActionIcon
              size="sm"
              icon="flag"
              onClick={() => {
                handleReport(state.value, rawResponses, lists.experienceIdeas)
              }}
            />
          </Tooltip>
        )}
      >
        Step 3: Activity Ideas
      </Well.Header>
      <Well.Body>
        <div className="row break-1">
          <div className="col-10">
            <div className="explain-text">
              Consider the list of activities below. Select the ones you'd like
              to include in your experience, and leave the rest unselected. Once
              you've made your choices, proceed to the next screen to explore
              measurement and tracking options.
            </div>
          </div>
        </div>
        <TextDivider className="mt-5 mb-5" />

        <ChoiceItemList
          className="mt-3"
          list={lists.sections}
          selectedItems={selectedItems.sections || []}
          onSelect={onSelectSection}
          getIdentifier={item => item.description}
          onAddChoice={choice => {
            send('addSection', { choice })
          }}
        />

        <TextDivider className="mt-5 mb-5" />
        <div className="stage__actions">
          <Button
            variant="secondary"
            onClick={() => {
              send('back')
            }}
          >
            Back
          </Button>
          <Button
            loading={state.matches('touchpoints.loading')}
            disabled={isEmpty(selectedItems.sections)}
            variant="primary"
            onClick={() =>
              selectedChanged || isEmpty(state.context.lists.activities)
                ? send('generate')
                : send('next')
            }
          >
            Next: Touchpoints
          </Button>
        </div>
      </Well.Body>
    </Well>
  )
}
