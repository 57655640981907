import { Button } from '@components/mantine/button.component'
import { TextDivider } from '@components/text-divider.component'
import { Well } from '@components/well.component'
import React, { FC, useRef } from 'react'
import { useGetStateField } from './use-get-state-field.hook'

interface EmpathizeStateProps {
  send: (event: string) => void
  state: { context: any; matches: (state: string) => boolean }
  updateField: (fieldId: string, value: string) => void
}

export const EmpathizeState: FC<EmpathizeStateProps> = ({
  send,
  state,
  updateField,
}) => {
  const { fields } = state.context
  const { audienceDemographics, audienceState, outcomeDescription } = fields

  const oldAudienceDemographics = useRef(audienceDemographics)
  const oldAudienceState = useRef(audienceState)
  const oldOutcomeDescription = useRef(outcomeDescription)

  const hasChanged =
    oldAudienceDemographics.current !== audienceDemographics ||
    oldAudienceState.current !== audienceState ||
    oldOutcomeDescription.current !== outcomeDescription

  const audienceDemographicsField = useGetStateField(
    'audienceDemographics',
    audienceDemographics,
    (value: string) => {
      handleUpdate('audienceDemographics', value)
    },
  )
  const audienceStateField = useGetStateField(
    'audienceState',
    audienceState,
    (value: string) => {
      handleUpdate('audienceState', value)
    },
  )
  const outcomeDescriptionField = useGetStateField(
    'desiredOutcome',
    outcomeDescription,
    (value: string) => {
      handleUpdate('outcomeDescription', value)
    },
  )

  function handleSubmit() {
    if (hasChanged) return send('submit')
    send('next')
  }

  function handleUpdate(fieldId: string, value: string) {
    updateField(fieldId, value)
  }

  function disableSubmit() {
    const { audienceDemographics, audienceState, outcomeDescription } =
      state.context.fields
    return !(audienceDemographics && audienceState && outcomeDescription)
  }

  return (
    <div>
      <div className="pb-3 pt-3">
        Use the assistant below to generate ideas for your activity.
      </div>
      <Well>
        <Well.Header>Step 1: Empathy</Well.Header>
        <Well.Body>
          <div className="row break-1">
            <div className="col-10">
              <div className="explain-text">
                Enter the information below to generate a list of empathetic
                insights and challenges related to your audience and the desired
                outcome they are seeking.
              </div>
            </div>
          </div>
          <TextDivider className="mt-6 mb-6" />
          <div className="row break-1">
            <div className="col-6">
              <div className="form-group">{audienceDemographicsField}</div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <div className="explain-text field-info">
                  e.g., including age range, gender, ethnicity, education level,
                  and socioeconomic status
                </div>
              </div>
            </div>
          </div>
          <TextDivider className="mb-6" />
          <div className="row break-1">
            <div className="col-6">
              <div className="form-group">{outcomeDescriptionField}</div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <div className="explain-text field-info">
                  Describe in as much detail as possible the ideal result you'd
                  like your audience to achieve
                </div>
              </div>
            </div>
          </div>
          <TextDivider className="mb-6" />
          <div className="row break-1">
            <div className="col-6">
              <div className="form-group">{audienceStateField}</div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <div className="explain-text field-info">
                  Describe your audience's current state as it relates to the
                  desired outcome
                </div>
              </div>
            </div>
          </div>
          <TextDivider className="mb-6" />
          <div className="stage__actions">
            <Button
              loading={state.matches('empathize.loading')}
              variant="primary"
              onClick={handleSubmit}
              disabled={disableSubmit()}
            >
              Next: Empathize Insights
            </Button>
          </div>
        </Well.Body>
      </Well>
    </div>
  )
}
