import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useLayoutEffect } from 'react'

type Props = {
  onKeyDown?: (e: KeyboardEvent) => void
}

export function CommandsPlugin({ onKeyDown }: Props): null {
  const [editor] = useLexicalComposerContext()

  useLayoutEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      onKeyDown?.(event)
    }

    return editor.registerRootListener(
      (
        rootElement: null | HTMLElement,
        prevRootElement: null | HTMLElement,
      ) => {
        if (prevRootElement !== null) {
          prevRootElement.removeEventListener('keydown', handleKeyDown)
        }
        if (rootElement !== null) {
          rootElement.addEventListener('keydown', handleKeyDown)
        }
      },
    )
  }, [editor, onKeyDown])

  return null
}
