import { Scoped, a } from 'kremling'
import { array, bool, object, oneOfType } from 'prop-types'
import React, { Component, createRef } from 'react'

import { Dropdown } from '@components/dropdown.component'
import { Loader } from '@components/loader.component'
import { TextInput } from '@components/mantine/text-input.component'
import {
  clearSelectionId,
  getItemElements,
  scrollToElement,
} from './common/utils'
import { CpSelectContent } from './select-content.component'
import styles from './select.kremling.scss'

export class SelectInner extends Component {
  static propTypes = {
    isGroupData: bool.isRequired,
    isMulti: bool.isRequired,
    isSearch: bool.isRequired,
    data: array.isRequired,
    dataMap: object.isRequired,
    valueTransformed: oneOfType([array, object]),
    clearable: bool,
  }

  dropdownEl = createRef()
  searchEl = createRef()
  selectEl = createRef()
  scrollEl = createRef()
  triggerRef = createRef()

  state = {
    selectedId: null,
    isOpen: false,
  }

  onOpen = () => {
    const { insertSearch, onOpen } = this.props
    if (insertSearch && this.searchEl && this.searchEl.current) {
      setTimeout(() => {
        if (this.searchEl.current) this.searchEl.current.focus()
      }, 0)
    }
    this.setState({ isOpen: true })
    onOpen && onOpen()
  }

  onClose = () => {
    const { searchOnChange, onClose } = this.props
    this.setState({ isOpen: false, selectedId: null })
    if (searchOnChange) searchOnChange('')
    onClose && onClose()
  }

  onKeyDown = e => {
    const { isOpen, selectedId } = this.state
    if (!this.dropdownEl.current) return
    if (isOpen) {
      if (e.key === 'Escape' || e.key === 'Tab') {
        e.preventDefault()
        this.close()
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault()
        this.onArrowUp()
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault()
        this.onArrowDown()
      }
      if (e.key === 'Enter') {
        e.preventDefault()
        if (selectedId === clearSelectionId) {
          return this.globalOnChange({ id: clearSelectionId })
        }
        let item = this.getItem(selectedId)
        if (!item) return this.close()
        return this.globalOnChange(item)
      }
    } else {
      if (e.key === 'ArrowDown' || e.key.length === 1) {
        this.dropdownEl.current.open()
      }
    }
  }

  onArrowUp = () => {
    const nodes = getItemElements(this.scrollEl)
    if (!nodes || !nodes.length) return
    let node
    const currentIndex = nodes.findIndex(
      node => node.dataset.selected === 'true',
    )
    if (currentIndex === -1 || currentIndex === 0) {
      node = nodes[this.itemLength() - 1]
    } else {
      node = nodes[currentIndex - 1]
    }
    this.setState({ selectedId: node.dataset.id }, () => {
      scrollToElement(node)
    })
  }

  onArrowDown = () => {
    const nodes = getItemElements(this.scrollEl)
    if (!nodes || !nodes.length) return
    let node
    const currentIndex = nodes.findIndex(
      node => node.dataset.selected === 'true',
    )
    if (currentIndex === -1 || currentIndex === this.itemLength() - 1) {
      node = nodes[0]
    } else {
      node = nodes[currentIndex + 1]
    }
    this.setState({ selectedId: node.dataset.id }, () => {
      scrollToElement(node)
    })
  }

  selectItem = selectedId => {
    this.setState({ selectedId })
  }

  getItem = id => {
    const { data, isGroupData, valueTransformed } = this.props
    return [
      ...(Array.isArray(valueTransformed) ? valueTransformed : []),
      ...(isGroupData
        ? data.reduce((acc, next) => [...acc, ...next.data], [])
        : data),
    ].find(item => item.id.toString() === id)
  }

  globalOnChange = itemTransformed => {
    const {
      onChange,
      transformData,
      value,
      valueTransformed,
      isMulti,
      dataMap,
    } = this.props
    let item
    if (itemTransformed.id === clearSelectionId) {
      item = null
    } else if (transformData) {
      item = dataMap[itemTransformed.id]
    } else {
      item = itemTransformed
    }
    if (!isMulti) {
      onChange(item)
      this.setState({ selectedId: null })
      this.scrollToTop()
      if (this.dropdownEl.current && itemTransformed.id !== clearSelectionId) {
        this.close()
      }
    } else {
      const valIndex = valueTransformed.findIndex(v => {
        return v.id === itemTransformed.id
      })
      if (valIndex === -1) {
        // don't allow disabled items to be added
        if (itemTransformed.disabled) return
        onChange([...value, item])
      } else {
        onChange([...value.slice(0, valIndex), ...value.slice(valIndex + 1)])
      }
    }
  }

  onSearch = value => {
    const { searchOnChange } = this.props
    this.setState({ selectedId: null })
    this.scrollToTop()
    if (searchOnChange) {
      searchOnChange(value)
    }
  }

  close = () => {
    const { searchOnChange, insertSearch } = this.props
    if (searchOnChange) {
      searchOnChange('')
    }
    if (this.dropdownEl?.current) {
      this.dropdownEl.current.close()
    }
    if (insertSearch && this.triggerRef?.current) {
      this.triggerRef.current.focus()
    }
  }

  itemLength = () => {
    const { value, data, isGroupData, isMulti, clearable } = this.props
    let length = 0
    if (isGroupData && data.length) {
      length = data.reduce((acc, next) => {
        return acc + next.data.length
      }, 0)
    } else if (data && data.length) {
      length = data.length
    } else if (Array.isArray(value)) {
      length = value.length
    }
    return length + (!isMulti && value && clearable ? 1 : 0)
  }

  scrollToTop = () => {
    if (this.scrollEl.current) {
      this.scrollEl.current.scrollTop = 0
    }
  }

  render() {
    const {
      allowContentClicks,
      className,
      contentWidth,
      cover,
      data,
      disabled,
      fixedContent,
      insertSearch,
      isGroupData,
      isMulti,
      loading,
      position,
      renderFooter,
      renderGroup,
      renderItem,
      showResultsInContent,
      searchOnChange,
      searchValue,
      style,
      renderTrigger,
      triggerIsBlock,
      valueTransformed,
      clearable,
      defaultOpenState,
    } = this.props
    const { selectedId } = this.state
    const RenderFooter = renderFooter
    return (
      <Scoped css={styles}>
        <div
          ref={this.selectEl}
          onKeyDown={this.onKeyDown}
          style={style}
          className={a('select-component')
            .a(className)
            .m('select-component--block', triggerIsBlock)}
        >
          <Dropdown
            allowContentClicks={allowContentClicks}
            contentWidth={contentWidth}
            defaultOpenState={defaultOpenState}
            cover={cover}
            disabled={disabled}
            fixedContent={fixedContent}
            onOpen={this.onOpen}
            onClose={this.onClose}
            position={position}
            ref={this.dropdownEl}
            renderTrigger={triggerProps =>
              renderTrigger({
                ...triggerProps,
                data,
                isDisabled: disabled,
                contentWidth,
                onChange: this.globalOnChange,
                ref: this.triggerRef,
                searchOnChange: this.onSearch,
                searchValue,
                triggerIsBlock,
                value: valueTransformed,
              })
            }
            renderContent={({ close }) => (
              <Scoped css={styles}>
                <div className="select-component__content">
                  {insertSearch && (
                    <div className="search">
                      <TextInput
                        icon="search"
                        ref={this.searchEl}
                        onChange={this.onSearch}
                        placeholder="Search"
                        autoFocus
                        value={searchValue}
                      />
                    </div>
                  )}
                  {loading ? (
                    <div className="select-component__loader">
                      <Loader center />
                    </div>
                  ) : (
                    <CpSelectContent
                      data={data}
                      disabled={disabled}
                      isGroupData={isGroupData}
                      isMulti={isMulti}
                      onChange={this.globalOnChange}
                      ref={this.scrollEl}
                      renderGroup={renderGroup}
                      renderItem={renderItem}
                      searchOnChange={searchOnChange}
                      searchValue={searchValue}
                      selectedId={selectedId}
                      selectItem={this.selectItem}
                      showResultsInContent={showResultsInContent}
                      valueTransformed={valueTransformed}
                      clearable={clearable}
                    />
                  )}
                  {!!renderFooter && (
                    <div className="select-component-footer">
                      <RenderFooter close={close} />
                    </div>
                  )}
                </div>
              </Scoped>
            )}
            triggerIsBlock={triggerIsBlock}
          />
        </div>
      </Scoped>
    )
  }
}
