import { Card } from '@components/card.component'
import { DatePicker } from '@components/date-picker.component'
import { Loader } from '@components/loader.component'
import { Button } from '@components/mantine/button.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Pill, PillType } from '@components/pill/pill.component'
import { RichText } from '@components/rich-text/rich-text.component'
import { useDebounce } from '@hooks/use-debounce.hook'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { metas } from '@src/pages/workflow/workflow-utils'
import { dbNames } from '@utils/constants'
import { collections } from '@utils/firebase.utils'
import { getTodayDate } from '@utils/helpers'
import {
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { k, useCss } from 'kremling'
import { isEmpty, trim } from 'lodash'
import { DateTime } from 'luxon'
import React, { useMemo, useState } from 'react'
import { useOutletContext } from 'react-router-dom'

type OutletProps = {
  activity: Activity
}

export function ActivityGeneralSettings() {
  const context = useOutletContext<OutletProps>()
  const scope = useCss(css)
  const [activity, setActivity] = useState<Activity>(context.activity)
  const systemDate = useAppState(state => state.systemDate)

  const [template] = useLoad(
    {},
    () => {
      if (isEmpty(activity)) return Promise.resolve({})
      const d = doc(db, dbNames.workflowTemplates, activity.templateId)
      return getDoc(d).then(snap => {
        if (snap.exists()) {
          return { ...snap.data(), id: snap.id }
        }
        return {}
      })
    },
    [activity.templateId],
  )

  const [scheduledTasks] = useLoad(
    [],
    () => {
      if (isEmpty(activity)) return Promise.resolve({})
      const q = query(
        collections.scheduledTasks,
        where('options.activityId', '==', activity.id),
        where('worker', '==', 'generateNextIteration'),
      )
      return getDocs(q).then(q => q.docs.map(u => ({ ...u.data(), id: u.id })))
    },
    [activity.id],
  )

  let lastScheduledIterationDate: DateTime = null
  if (!isEmpty(scheduledTasks)) {
    const maxDate = scheduledTasks.reduce(
      (max: number, current: ScheduledTask) => {
        return current.performAt > max ? current.performAt : max
      },
      0,
    )
    lastScheduledIterationDate = DateTime.fromMillis(maxDate)
  }
  const startDate = DateTime.fromISO(activity.startDate?.date, {
    zone: activity.startDate?.timeZone,
  })
  const endDate = DateTime.fromISO(activity.endDate?.date, {
    zone: activity.endDate?.timeZone,
  })
  const today = systemDate
    ? DateTime.fromISO(systemDate).startOf('day')
    : getTodayDate().startOf('day')
  const endDateIsAutoGenerated = activity.meta === metas.RECURRENCE_AND_REPEAT
  const startTodayDiff = startDate
    .startOf('day')
    .diff(today.startOf('day'), 'days').days

  const [pillType, activityStatus]: [PillType, string] = useMemo(() => {
    if (!startDate.isValid && !endDate.isValid) return ['primary', '']
    const startDateIsPast = today >= startDate.startOf('day')
    const endDateIsPast = today > endDate.endOf('day')

    if (
      DateTime.fromISO(activity.completedDate, {
        zone: activity.endDate?.timeZone,
      }) < endDate
    ) {
      return ['danger', 'Stopped']
    }

    if (endDateIsPast) {
      return ['primary', 'Ended']
    }

    if (!startDateIsPast) {
      return ['primary', 'Not started']
    }

    if (startDateIsPast && !endDateIsPast) {
      return ['primary', 'In Progress']
    }
  }, [startDate, endDate, activity.completedDate, activity?.endDate?.timeZone])

  function handleStopActivity(): void {
    const d = doc(db, dbNames.activities, activity.id)
    const completedDate: string = getTodayDate().toISO()
    updateDoc(d, { completedDate }).then(() => {
      setActivity(prevActivity => ({ ...prevActivity, completedDate }))
    })
  }

  function handleRestartActivity(): void {
    const d = doc(db, dbNames.activities, activity.id)
    const completedDate: null = null
    updateDoc(d, { completedDate }).then(() => {
      setActivity(prevActivity => ({ ...prevActivity, completedDate }))
    })
  }

  function handleUpdateName(name: string): void {
    setActivity(prevState => ({
      ...prevState,
      name,
    }))
    if (trim(name).length > 0) {
      updateActivityDebounced({ name })
    }
  }

  function handleUpdateStartDate(newDate: DateTime): void {
    const date = newDate.toISO()
    const timeZone = newDate.zoneName
    const newStartDate: FutureDate = { date, timeZone }
    if (endDateIsAutoGenerated) {
      const diff = newDate
        .startOf('day')
        .diff(startDate.startOf('day'), 'days').days
      const endDatePlusDiff = endDate.plus({ days: diff })
      const newEndDate: FutureDate = {
        date: endDatePlusDiff.endOf('day').toISO({ includeOffset: false }),
        timeZone: endDate.zoneName,
      }
      setActivity(prevState => ({
        ...prevState,
        startDate: newStartDate,
        endDate: newEndDate,
      }))
      updateActivityDebounced({ startDate: newStartDate, endDate: newEndDate })
    } else {
      setActivity(prevState => ({ ...prevState, startDate: newStartDate }))
      updateActivityDebounced({ startDate: newStartDate })
    }
  }

  async function updateInvitation(
    payload: Partial<LinkInvitation>,
  ): Promise<void> {
    const d = doc(db, dbNames.invitations, activity.id)
    await updateDoc(d, payload)
  }
  const updateInvitationDebounced = useDebounce(updateInvitation, 1000)

  function handleUpdateEndDate(newDate: DateTime): void {
    const date = newDate.toISO()
    const timeZone = newDate.zoneName
    const newEndDate: FutureDate = { date, timeZone }
    if (endDateIsAutoGenerated) {
      const diff = newDate
        .startOf('day')
        .diff(endDate.startOf('day'), 'days').days
      const startDatePlusDiff = startDate.plus({ days: diff })
      const newStartDate: FutureDate = {
        date: startDatePlusDiff.endOf('day').toISO({ includeOffset: false }),
        timeZone: endDate.zoneName,
      }
      setActivity(prevState => ({
        ...prevState,
        startDate: newStartDate,
        endDate: newEndDate,
      }))
      updateActivityDebounced({ startDate: newStartDate, endDate: newEndDate })
    } else {
      setActivity(prevState => ({ ...prevState, endDate: newEndDate }))
      updateActivityDebounced({ endDate: newEndDate })
      updateInvitationDebounced({ endDate: newEndDate })
    }
  }

  function handleUpdateFunnelId(funnelId: string): void {
    setActivity(prevState => ({ ...prevState, funnelId }))
    updateActivityDebounced({ funnelId })
  }

  function getEndDateMinDate() {
    //If the end date is auto generated, then the start date will also be changed when this is changed.
    //The start date has a minDate of being changed to no earlier than tomorrow, so we need to maintain that.

    if (endDateIsAutoGenerated) {
      return endDate.minus({ day: startTodayDiff - 1 })
    } else {
      if (lastScheduledIterationDate) {
        return lastScheduledIterationDate.minus({ day: 1 })
      }

      if (activityStatus === 'In Progress') {
        return today.plus({ day: 1 })
      } else {
        return startDate.plus({ day: 1 })
      }
    }
  }

  async function updateActivity(payload: Partial<Activity>): Promise<void> {
    const d = doc(db, dbNames.activities, activity.id)
    await updateDoc(d, { ...activity, ...payload })
  }

  const updateActivityDebounced = useDebounce(updateActivity, 1000)

  if (isEmpty(activity)) {
    return (
      <Card className="mb-6">
        <Loader />
      </Card>
    )
  }

  return (
    <div
      {...scope}
      className="pt-8"
    >
      <div className="row break-2">
        <div className="col-8">
          <div className="form-group">
            <TextInput
              label="Workflow name"
              value={activity.name}
              onChange={handleUpdateName}
              required
            />
          </div>
        </div>
      </div>
      <div className="row break-2">
        <div className="col-4">
          <div className="form-group">
            <TextInput
              label="Source template"
              value={template.name || ''}
              disabled
            />
          </div>
        </div>
      </div>
      <div className="row break-2">
        <div className="col-8">
          <div className="form-group">
            <label htmlFor="activity-description">Description</label>
            <RichText
              onChange={text => {
                updateActivity({ description: text })
              }}
              initialValue={activity.description || ''}
              id="activity-description"
              placeholder="Type here..."
            />
          </div>
        </div>
      </div>
      <div className="row break-2">
        <div className="col-3">
          <div className="form-group">
            <label htmlFor="">Start date</label>
            <DatePicker
              // @ts-ignore
              placeholder="Select date"
              minDate={today.plus({ day: 1 })}
              onChange={handleUpdateStartDate}
              value={DateTime.fromISO(activity.startDate?.date, {
                zone: activity.startDate?.timeZone,
              })}
              triggerIsBlock
              disabled={activityStatus !== 'Not started'}
            />
          </div>
        </div>
        {activity.endDate && (
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="">End date</label>
              <DatePicker
                // @ts-ignore
                placeholder="Select date"
                onChange={handleUpdateEndDate}
                minDate={getEndDateMinDate()}
                value={DateTime.fromISO(activity.endDate?.date, {
                  zone: activity.endDate?.timeZone,
                })}
                triggerIsBlock
                disabled={
                  activityStatus === 'Ended' ||
                  (activityStatus === 'In Progress' && endDateIsAutoGenerated)
                }
              />
            </div>
          </div>
        )}
        <div
          style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 4 }}
          className="col-4"
        >
          <div className="form-group">
            <Pill
              pillType={pillType}
              split
              title="Status"
              height={27}
              value={activityStatus}
            />
          </div>
        </div>
      </div>
      {endDate > today && (
        <div className="row break-1">
          {!!activity.completedDate ? (
            <Button
              onClick={handleRestartActivity}
              className="ml-2"
              variant="secondary"
            >
              Resume activity
            </Button>
          ) : (
            <Button
              onClick={handleStopActivity}
              className="ml-2"
              variant="secondary"
            >
              Stop activity
            </Button>
          )}
        </div>
      )}
      <div className="row break-2">
        <div className="col-4">
          <div className="form-group">
            <TextInput
              label="Funnel ID"
              value={activity.funnelId}
              onChange={handleUpdateFunnelId}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

//TODO need to set up updating the funnelId in the settings and then I need to continue setting up the cloud function code for the webhook

const css = k`
  .overview-card__body {
    padding: 1.2rem 2rem;
  }
`
