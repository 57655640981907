import { a } from 'kremling'
import { array } from 'prop-types'
import React, { useCallback, useRef, useState } from 'react'

import { Icon } from '@components/icon.component'

export const TriggerPills = props => {
  const {
    isDisabled,
    isOpen,
    onChange,
    open,
    placeholder,
    renderPill,
    searchOnChange,
    searchValue,
    value = [],
  } = props
  const inputEl = useRef(null)
  const [isFocused, setIsFocused] = useState(false)
  const [prevSearchValue, setPrevSearchValue] = useState(null)

  const onFocus = useCallback(() => {
    setIsFocused(true)
    inputEl.current.setSelectionRange(10000, 10000)
  }, [])

  const onBlur = useCallback(() => {
    setTimeout(() => {
      if (isOpen) {
        inputEl.current.focus()
      } else {
        setIsFocused(false)
      }
    }, 0)
  }, [setIsFocused, isOpen])

  const removeItem = useCallback(item => {
    onChange(item || null)
  }, [])

  const onKeyUp = useCallback(
    e => {
      const val = e.target.value
      if (
        e.key === 'Backspace' &&
        val === prevSearchValue &&
        !val &&
        value.length
      ) {
        const item = value.at(-1)
        searchOnChange(renderPill(item))
        removeItem(item)
      }
    },
    [prevSearchValue, value],
  )

  return (
    <div
      className={a('trigger-pills')
        .m('trigger-pills--focused', isFocused)
        .m('trigger-pills--disabled', isDisabled)}
      onClick={() => {
        if (!isFocused) inputEl.current.focus()
        if (!isOpen) {
          open()
        }
      }}
    >
      <div className="trigger-pills__icon">
        <Icon name="search" />
      </div>
      {value.map(item => (
        <div
          key={item.id}
          className="trigger-pills__pill"
        >
          <span>{renderPill(item)}</span>
          <button
            tabIndex={-1}
            onClick={() => removeItem(item)}
            disabled={isDisabled}
          >
            <Icon
              name="close-small"
              fill="var(--cps-color-pill-text)"
            />
          </button>
        </div>
      ))}
      <div className="trigger-pills__input">
        <input
          ref={inputEl}
          disabled={isDisabled}
          type="text"
          autoComplete="off"
          placeholder={!value.length ? placeholder : ''}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={e => searchOnChange(e.target.value)}
          value={searchValue}
          onKeyDown={e => setPrevSearchValue(e.target.value)}
          onKeyUp={onKeyUp}
        />
      </div>
    </div>
  )
}

TriggerPills.propTypes = {
  value: array,
}
