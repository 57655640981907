import { doc } from 'firebase/firestore'
import { k, useCss } from 'kremling'
import React from 'react'

import { Dropdown } from '@components/dropdown.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getUserFullName } from '@utils/helpers'
import { useDocument } from 'react-firebase-hooks/firestore'

export function MentorCard({ mentor, removeMentor, resendInvitation }) {
  const scoped = useCss(css)
  const { userId, status, inviterId, id } = mentor
  const me = useAppState(state => state.user)
  const [user = {}, isLoading] = useDocument(doc(db, dbNames.users, userId))

  if (isLoading) {
    return null
  }

  function renderActions() {
    if (me.id === inviterId || me.id === userId) {
      if (status === 'pending') {
        return (
          <Dropdown
            position="bottom-left"
            cover
            fixedContent
            renderTrigger={({ toggle }) => (
              <ActionIcon
                size="sm"
                icon="ellipsis-v"
                variant="tertiary"
                onClick={toggle}
                aria-label="Mentor options"
              />
            )}
            renderContent={({ close }) => (
              <div className="select-list">
                <button
                  onClick={() => {
                    removeMentor(id)
                    close()
                  }}
                >
                  Remove
                </button>
                {!!resendInvitation && (
                  <button
                    onClick={() => {
                      resendInvitation(
                        user.exists() ? user.get('email') : userId,
                      )
                      close()
                    }}
                  >
                    Resend invitation
                  </button>
                )}
              </div>
            )}
          />
        )
      }

      return (
        <ActionIcon
          size="sm"
          icon="times"
          variant="tertiary"
          onClick={() => {
            removeMentor(id)
          }}
          aria-label="Remove mentor"
        />
      )
    }
  }

  return (
    <div
      className="mentor-container"
      {...scoped}
    >
      <div className="mentor">
        <span className="email">
          {user.exists() ? getUserFullName(user.data()) : userId}
        </span>
        <span
          className="status"
          data-status={status}
        >
          {status}
        </span>
      </div>
      {renderActions()}
    </div>
  )
}

const css = k`
  .mentor-container, .mentor {
    display: flex;
  }

  .mentor {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .email, .status {
    display: inline-block;
  }

  .email {
    margin-right: 1rem;
  }

  .status {
    color: $color-grey-900;
    border-radius: $base-border-radius;
    padding: 2px 4px;
    font-size: 13px;
    font-weight: bold;
    margin-right: 1rem;
  }

  .status[data-status="pending"] {
    background-color: $color-warning;
  }

  .status[data-status="accepted"] {
    background-color: $color-success;
  }

  .status[data-status="rejected"] {
    background-color: $color-danger;
  }
`
