import { CourseProgressCard } from '@components/cards/course-progress-card.component'
import { UpcomingActivityCard } from '@components/cards/upcoming-activity-card.component'
import { WorkflowProgressCard } from '@components/cards/workflow-progress-card.component'
import { EmptyState } from '@components/empty-state.component'
import { Icon } from '@components/icon.component'
import { Separator } from '@components/radix/separator'
import { Well } from '@components/well.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import {
  flatten,
  isEmpty,
  isNil,
  orderBy as lodashOrderBy,
  partition,
  uniqBy,
} from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import wretch from 'wretch'

type Stats = {
  completed_activities: string
  completed_sections: string
  completed_iterations: string
  days_since_last_edit: string
}

export const WorkspaceHome = () => {
  const user = useAppState(state => state.user)
  const systemDate = useAppState(state => state.systemDate)
  const [selectedTab] = useState('active')
  const { orgId } = useParams()
  const today = useMemo(() => getTodayDate(), [systemDate])
  const [stats, setStats] = useState<Stats>(null)
  const isAdmin = ['owner', 'manager'].includes(user.orgRoles[orgId])

  useEffect(() => {
    wretch(`${process.env.USER_ANALYTICS_URL}/user/${user.id}/stats/${orgId}`)
      .get()
      .json(setStats)
      .catch(error => {
        console.error('Error fetching user stats:', error)
      })
  }, [user.id])

  const [[upcomingActivities, activities], _setActivities, activitiesOpts] =
    useLoad(
      [[], []],
      async () => {
        if ((user && user.disabled) || !user) return Promise.resolve([])
        let q
        if (selectedTab === 'active') {
          q = query(
            collection(db, dbNames.activities),
            orderBy('endDate.date'),
            where('userIds', 'array-contains', user.id),
            where('orgId', '==', orgId),
            where('completedDate', '==', null),
            where('endDate.date', '>=', today.toISO()),
          )

          return getDocs(q)
            .then(snap => snap.docs.map(doc => ({ ...doc.data(), id: doc.id })))
            .then(all =>
              partition(
                all,
                (activity: Activity) =>
                  activity.startDate.date >= today.toISO(),
              ),
            )
        } else if (selectedTab === 'completed') {
          const q1 = query(
            collection(db, dbNames.activities),
            where('userIds', 'array-contains', user.id),
            where('orgId', '==', orgId),
            where('endDate.date', '<', today.toISO()),
          )

          const q2 = query(
            collection(db, dbNames.activities),
            where('userIds', 'array-contains', user.id),
            where('orgId', '==', orgId),
            where('completedDate', '!=', null),
          )

          const promise1 = getDocs(q1).then(snap =>
            snap.docs.map(doc => ({ ...doc.data(), id: doc.id })),
          )

          const promise2 = getDocs(q2).then(snap =>
            snap.docs.map(doc => ({ ...doc.data(), id: doc.id })),
          )

          return Promise.all([promise1, promise2])
            .then(flatten)
            .then(all => uniqBy(all, ({ id }) => id))
            .then(all => lodashOrderBy(all, 'endDate.date'))
            .then(ordered => [[], ordered])
        }
      },
      [orgId, selectedTab],
    )

  const [[courses, upcomingCourses], _setCourses] = useLoad(
    [[], []],
    async () => {
      if ((user && user.disabled) || !user) return Promise.resolve([])
      const q = query(
        collection(db, dbNames.activities),
        where('userIds', 'array-contains', user.id),
        where('orgId', '==', orgId),
        where('completedDate', '==', null),
        where('endDate', '==', null),
      )

      return getDocs(q)
        .then(snap => snap.docs.map(doc => ({ ...doc.data(), id: doc.id })))
        .then(all => {
          return lodashOrderBy(all, 'startDate.date', 'desc')
        })
        .then(all =>
          partition(
            all,
            (activity: Activity) => activity.startDate.date <= today.toISO(),
          ),
        )
    },
    [orgId, selectedTab],
  )

  // const [followActivities] = useLoad(
  //   [],
  //   async () => {
  //     const workflowMentorsQuery = query(
  //       collection(db, dbNames.activityMentors),
  //       orderBy('activityEndDate.date'),
  //       where('userId', '==', user.id),
  //       where('activityEndDate.date', '>', getTodayDate().toISO()),
  //     )
  //
  //     const activityMentors = await getDocs(workflowMentorsQuery).then(snap =>
  //       snap.docs.map(doc => ({ id: doc.id, ...doc.data() })),
  //     )
  //
  //     if (!activityMentors.length) return Promise.resolve([])
  //
  //     const followActivitiesQuery = query(
  //       collection(db, dbNames.activities),
  //       orderBy('endDate.date'),
  //       where(
  //         'id',
  //         'in',
  //         activityMentors.map(({ activityId }: ActivityMentor) => activityId),
  //       ),
  //     )
  //
  //     return getDocs(followActivitiesQuery).then(snap =>
  //       snap.docs.map(doc => ({ id: doc.id, ...doc.data() })),
  //     )
  //   },
  //   [user.id],
  // )

  // async function openCreateWorkflowModal() {
  //   try {
  //     await modalService.render(StartWorkflowModal, { orgId, addCreator: true })
  //     toastService.info('Successfully started Workflow.')
  //     activitiesOpts.reload()
  //     coursesOpts.reload()
  //   } catch {} // eslint-disable-line no-empty
  // }

  function renderWorkflows() {
    const allActivities = [...activities, ...courses]

    if (allActivities.length) {
      return allActivities.map((activity: Activity) => {
        if (activity.endDate) {
          return (
            <WorkflowProgressCard
              key={activity.id}
              userId={user.id}
              activity={activity}
              href={`${user.id}/${activity.id}`}
              className="w-full"
            />
          )
        }
        return (
          <CourseProgressCard
            key={activity.id}
            userId={user.id}
            activity={activity}
            href={`${user.id}/${activity.id}`}
            className="w-full"
          />
        )
      })
    } else {
      return isAdmin ? (
        <EmptyState
          title="There are no activities assigned to you."
          subtitle="Get started by assigning yourself an activity."
        />
      ) : (
        <EmptyState title="There are no activities assigned to you." />
      )
    }
  }

  return (
    <div className="iact-container --sm space-y-20">
      <h1 className="sr-only">Home</h1>
      <div className="space-y-8">
        <h2 className="mb-0 text-3xl font-semibold flex items-center gap-3">
          <Icon
            name="gauge-high"
            size={24}
          />{' '}
          Progress
        </h2>
        <div className="space-y-4">
          <div className="flex gap-10">
            <div className="border border-grey-75 flex-1 max-w-[33%] rounded-lg px-3 py-5 space-y-4">
              <div className="space-y-4">
                <div className="px-2 text-grey-400">
                  <Icon
                    name="clipboard-check"
                    size={24}
                  />
                </div>
                <h2 className="flex flex-col text-2xl mb-0">
                  <span>Completed</span>
                  <span className="font-bold">Activities</span>
                </h2>
              </div>
              <Separator className="bg-grey-75" />
              <div className="text-5xl px-2 font-bold">
                {stats?.completed_activities}
              </div>
            </div>

            <div className="border border-grey-75 flex-1 max-w-[33%] rounded-lg px-3 py-5 space-y-4">
              <div className="space-y-4">
                <div className="px-2 text-grey-400">
                  <Icon
                    name="list-radio"
                    size={24}
                  />
                </div>
                <h2 className="flex flex-col text-2xl mb-0">
                  <span>Completed</span>
                  <span className="font-bold">Sections</span>
                </h2>
              </div>
              <Separator className="bg-grey-75" />
              <div className="text-5xl px-2 font-bold">
                {stats?.completed_sections}
              </div>
            </div>

            <div className="border border-grey-75 flex-1 max-w-[33%] rounded-lg px-3 py-5 space-y-4">
              <div className="space-y-4">
                <div className="px-2 text-grey-400">
                  <Icon
                    name="calendar-alt"
                    size={24}
                  />
                </div>
                <h2 className="flex flex-col text-2xl mb-0">
                  <span>Days since</span>
                  <span className="font-bold">participated</span>
                </h2>
              </div>
              <Separator className="bg-grey-75" />
              <div className="text-5xl px-2 font-bold">
                {!isNil(stats?.days_since_last_edit)
                  ? stats.days_since_last_edit
                  : 'N/A'}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="space-y-8">
        <div className="flex items-end justify-between">
          <h2 className="mb-0 text-3xl font-semibold flex items-center gap-3">
            <Icon
              name="clipboard-check"
              size={24}
            />{' '}
            Activities
          </h2>
          {/*{isAdmin && (*/}
          {/*  <Button*/}
          {/*    variant="secondary"*/}
          {/*    onClick={openCreateWorkflowModal}*/}
          {/*  >*/}
          {/*    Start activity*/}
          {/*  </Button>*/}
          {/*)}*/}
        </div>
        <div className="space-y-4 flex-2">
          {(!isEmpty(upcomingActivities) || !isEmpty(upcomingCourses)) &&
            !activitiesOpts.loading &&
            selectedTab === 'active' && (
              <Well>
                <Well.Header icon="calendar-alt">
                  <h2 className="mb-0">Upcoming</h2>
                </Well.Header>
                <Well.Body>
                  <div className="flex flex-col gap-4">
                    {[...upcomingActivities, ...upcomingCourses].map(
                      (upcomingActivity: Activity) => (
                        <UpcomingActivityCard
                          key={upcomingActivity.id}
                          href={`${user.id}/${upcomingActivity.id}`}
                          upcomingActivity={upcomingActivity}
                        />
                      ),
                    )}
                  </div>
                </Well.Body>
              </Well>
            )}
          <div>
            <div className="space-y-4">
              <div className="space-y-4 w-full">{renderWorkflows()}</div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="space-y-4">*/}
      {/*  <h2 className="mb-0 text-2xl font-semibold">Following</h2>*/}
      {/*  <Card className="p-8 gap-4">*/}
      {/*    {!isEmpty(followActivities) ? (*/}
      {/*      followActivities.map((mentorActivity: ActivityMentor) => (*/}
      {/*        <MentoringStatusCard*/}
      {/*          key={mentorActivity.id}*/}
      {/*          mentorActivity={mentorActivity}*/}
      {/*        />*/}
      {/*      ))*/}
      {/*    ) : (*/}
      {/*      <EmptyState*/}
      {/*        height={10}*/}
      {/*        title="You are not following anyone else's progress."*/}
      {/*      />*/}
      {/*    )}*/}
      {/*  </Card>*/}
      {/*</div>*/}
    </div>
  )
}
