import { every, has, includes } from 'lodash'

/*
This file shares functionality with the cloud function file called activity-access-type.js.
If you make a change here, you should make a corresponding change there.
* */

/*
DO NOT CHANGE THESE VALUES.
THEY ARE STORED IN THE DATABASE LIKE THIS.
IF YOU NEED TO CHANGE ONE, THEN PLAN ON MIGRATING THE DATA.
*/
export const ANYONE_LINK = 'anyone-link'
export const ANYONE_WORKSPACE = 'anyone-workspace'
export const AUTO_JOIN_WORKSPACE = 'auto-join-workspace'
export const ALLOW_ANONYMOUS = 'allow-anonymous'
export const LIMIT_LINK = 'limit-link'
export const PARTICIPANT_LIMITATIONS = 'participant-limitations'

export const shareSettings: ShareSetting[] = [
  {
    type: 'radio',
    id: LIMIT_LINK,
    options: [
      {
        label: 'Only workspace members can participate',
        icon: 'sitemap',
        value: ANYONE_WORKSPACE,
        selected: false,
        settingGroups: [],
      },
      {
        label: 'Anyone with the link can participate',
        icon: 'globe',
        value: ANYONE_LINK,
        selected: false,
        settingGroups: [
          {
            type: 'checkbox',
            id: PARTICIPANT_LIMITATIONS,
            options: [
              {
                label: 'Add participants to workspace automatically',
                value: AUTO_JOIN_WORKSPACE,
                selected: false,
                settingGroups: [],
              },
              {
                label: 'Allow anonymous participation',
                value: ALLOW_ANONYMOUS,
                selected: false,
                settingGroups: [],
              },
            ],
          },
        ],
      },
    ],
  },
]

export const activityAccess = (
  activitySettings: string[],
  featureName: string,
): boolean => {
  const featuresMap: { [key: string]: (settings: string[]) => boolean } = {
    'auto-join-workspace': autoJoinWorkspace,
    'allow-anonymous': allowAnonymous,
    'anyone-link': anyoneLink,
    'anyone-workspace': anyoneWorkspace,
  }

  if (has(featuresMap, featureName)) {
    return featuresMap[featureName](activitySettings)
  } else {
    throw new Error(`Feature "${featureName}" is not supported.`)
  }
}

function autoJoinWorkspace(settings: string[]): boolean {
  return every([ANYONE_LINK, AUTO_JOIN_WORKSPACE], feature =>
    includes(settings, feature),
  )
}

function allowAnonymous(settings: string[]): boolean {
  return every([ANYONE_LINK, ALLOW_ANONYMOUS], feature =>
    includes(settings, feature),
  )
}

function anyoneLink(settings: string[]): boolean {
  return includes(settings, ANYONE_LINK)
}

function anyoneWorkspace(settings: string[]): boolean {
  return includes(settings, ANYONE_WORKSPACE)
}
