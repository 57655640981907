import { k, useCss } from 'kremling'
import { bool, func, object, oneOfType, string } from 'prop-types'
import React, { useMemo, useRef } from 'react'

import { DurationInput } from '@components/duration-input.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { NumberInput } from '@components/mantine/number-input.component'
import { TextInput } from '@components/mantine/text-input.component'
import { RichText } from '@components/rich-text/rich-text.component'
import { SelectSingle } from '@components/select-single.component'
import { $generateNodesFromDOM } from '@lexical/html'
import { FieldHeaderContent } from '@src/pages/workflow/client/fields/field-header-content.component'
import { AssistedResponseModal } from '@src/pages/workflow/client/workflow-refactor/assisted-response-modal.component'
import {
  TEXT_TYPE_CONSTANTS,
  lengthOptions,
} from '@src/pages/workflow/fields.helper'
import { modalService } from '@utils/modal.service'
import { $getRoot } from 'lexical'
import { useController } from 'react-hook-form'

function getRules(field, isRequired) {
  const rules = {}
  if (field.textType === TEXT_TYPE_CONSTANTS.LONG) {
    rules.validate = value => {
      if (!value)
        return isRequired || field.isRequired ? 'This field is required' : false
    }
  } else {
    rules.required =
      isRequired || field.isRequired ? 'This field is required' : false
  }
  return rules
}

export function WorkflowTextInput({
  field,
  readOnly,
  isRequired,
  section,
  control,
}) {
  const scope = useCss(css)
  const editorRef = useRef()

  const {
    field: fieldProps,
    fieldState: { error },
  } = useController({
    disabled: readOnly,
    name: `${field.version}_${field.id}`,
    control,
    rules: getRules(field, isRequired),
  })

  const distanceUnit = useMemo(() => {
    if (!fieldProps.value?.unit) return lengthOptions.find(o => o.id === 'mi')
    return lengthOptions.find(option => option.id === fieldProps.value?.unit)
  }, [fieldProps.value?.unit])

  return (
    <div {...scope}>
      <FieldHeaderContent
        field={field}
        isRequired={isRequired}
        section={section}
        actions={
          <>
            <ActionIcon
              size="sm"
              onClick={() => {
                modalService
                  .render(AssistedResponseModal, { field, section })
                  .then(({ response }) => {
                    if (field.textType === TEXT_TYPE_CONSTANTS.LONG) {
                      const editor = editorRef.current
                      editor.update(() => {
                        const parser = new DOMParser()
                        const dom = parser.parseFromString(
                          `<p>${response}</p>`,
                          'text/html',
                        )
                        const nodes = $generateNodesFromDOM(editor, dom)

                        const root = $getRoot()
                        root.clear()
                        root.append(...nodes)
                      })
                    } else if (
                      field.textType === TEXT_TYPE_CONSTANTS.DISTANCE
                    ) {
                      console.log(response)
                      fieldProps.onChange(response)
                    } else {
                      fieldProps.onChange(response)
                    }
                  })
              }}
              icon="wand-magic-sparkles"
            />
          </>
        }
      />
      {field.textType === TEXT_TYPE_CONSTANTS.SHORT && (
        <TextInput
          {...fieldProps}
          autoComplete="off"
          disabled={readOnly}
          error={error?.message}
        />
      )}
      {field.textType === TEXT_TYPE_CONSTANTS.LONG && (
        <>
          <RichText
            {...fieldProps}
            id={`${field.id}-rich-text`}
            initialValue={fieldProps?.value}
            readonly={fieldProps.disabled}
            editorRef={editorRef}
            error={error?.message}
          />
        </>
      )}
      {field.textType === TEXT_TYPE_CONSTANTS.NUMBER && (
        <div className="input-row">
          <NumberInput
            {...fieldProps}
            value={Number(fieldProps?.value)}
            min={0}
            error={error?.message}
          />
          <span className="ml-2">{field.dataLabel}</span>
        </div>
      )}
      {field.textType === TEXT_TYPE_CONSTANTS.DISTANCE && (
        <div className="input-row">
          <NumberInput
            {...fieldProps}
            onChange={text => {
              const newDistance = {
                unit: distanceUnit.id,
                value: text,
              }
              fieldProps.onChange(newDistance)
            }}
            value={Number(fieldProps?.value?.value) || 0}
            min={0}
            placeholder="Distance"
            error={error?.message}
          />
          <div style={{ width: 150 }}>
            <SelectSingle
              className="ml-2"
              contentWidth="sm"
              fixedContent
              width={100}
              disabled={readOnly}
              onChange={({ id }) => {
                const newDistance = {
                  unit: id,
                  value: fieldProps?.value?.value,
                }
                fieldProps.onChange(newDistance)
              }}
              value={distanceUnit}
              data={lengthOptions}
            />
          </div>
        </div>
      )}
      {field.textType === TEXT_TYPE_CONSTANTS.PERCENTAGE && (
        <div className="input-row">
          <NumberInput
            {...fieldProps}
            value={Number(fieldProps?.value)}
            error={error?.message}
            min={0}
            max={100}
            suffix="%"
          />
        </div>
      )}
      {field.textType === TEXT_TYPE_CONSTANTS.DURATION && (
        <div className="input-row duration-input">
          <DurationInput
            {...fieldProps}
            value={fieldProps?.value || 0}
            error={error?.message}
          />
        </div>
      )}
    </div>
  )
}

WorkflowTextInput.propTypes = {
  field: object,
  onChange: func,
  value: oneOfType([string, object]),
  readOnly: bool,
}

const css = k`
  .input-row {
    display: flex;
    align-items: center;
  }

  .input-row .form-input-container {
    width: auto;
  }

  .duration-input {
    padding: 12px;
    background: $color-grey-25;
    border-radius: $base-border-radius;
  }
`
