import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { k, useCss } from 'kremling'
import { isEmpty, sortBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { DatePicker } from '@components/date-picker.component'
import { Loader } from '@components/loader.component'
import { Button } from '@components/mantine/button.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Modal } from '@components/modal.component'
import { Required } from '@components/required.component'
import { SelectSingle } from '@components/select-single.component'
import { useLoad } from '@hooks/use-load.hook'
import { useProcessWorkflowEndDate } from '@hooks/use-process-workflow-end-date.hook'
import { useAppState } from '@src/app.state'
import { db, functions } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate, getUserFullName } from '@utils/helpers'
import { toastService } from '@utils/toast.service'

export function AddWorkflowModal({ onResolve, onReject, orgId, template }) {
  const user = useAppState(state => state.user)
  const [saving, setSaving] = useState(false)
  const [isGroup, setIsGroup] = useState(false)
  const [selectedName, setSelectedName] = useState(template?.name || '')
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedTemplateSearch, setSelectedWorkflowTemplateSearch] =
    useState('')
  const [selectedTemplate, setSelectedTemplate] = useState(template || null)
  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [processedEndDate, setSelectedEndDate, endDateIsGenerated, ruleMeta] =
    useProcessWorkflowEndDate(selectedTemplate, selectedStartDate)
  const isAdmin = ['owner', 'manager'].includes(user.orgRoles[orgId])
  const scope = useCss(css)

  const [
    workflowTemplates,
    _setWorkflowTemplates,
    { loading: workflowTemplatesLoading },
  ] = useLoad(
    [],
    async () => {
      const q = query(
        collection(db, dbNames.workflowTemplates),
        where('orgId', '==', orgId),
        where('assistant', '==', false),
      )

      const templates = await getDocs(q).then(q =>
        q.docs.map(d => ({ ...d.data(), id: d.id })),
      )
      return sortBy(templates, 'name')
    },
    [],
  )

  const [
    publishedTemplates,
    _setPublishedTemplates,
    { loading: publishedTemplatesLoading },
  ] = useLoad(
    [],
    () => {
      if (!isAdmin) return Promise.resolve([])
      const q = query(
        collection(db, dbNames.publishedTemplates),
        where('orgId', '==', orgId),
        where('publishedLocations', 'array-contains', 'workspace'),
      )
      return getDocs(q).then(snap =>
        snap.docs.map(d => ({ ...d.data(), id: d.id })),
      )
    },
    [],
  )

  const [searchUser, setSearchUser] = useState('')
  const [users, _setUsers, { loading: usersLoading }] = useLoad(
    [],
    () => {
      const q = query(
        collection(db, dbNames.users),
        orderBy(`orgRoles.${orgId}`),
      )
      return getDocs(q)
        .then(snap =>
          snap.docs.map(d => {
            const user = { id: d.id, ...d.data() }
            return {
              ...user,
              name: getUserFullName(user),
            }
          }),
        )
        .then(users => users.filter(u => u.id !== user.id))
    },
    [],
  )
  const [groups, _setGroups, { loading: groupsLoading }] = useLoad([], () => {
    const q = query(collection(db, dbNames.groups), where('orgId', '==', orgId))
    return getDocs(q).then(snap =>
      snap.docs.map(d => ({ ...d.data(), id: d.id })),
    )
  })

  const templateHasEmptySection = useMemo(() => {
    if (!selectedTemplate) return
    const { sections } = selectedTemplate
    return sections.some(section => {
      return !section.fields.length
    })
  }, [selectedTemplate])

  async function build() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const startDate = selectedStartDate.toISO({ includeOffset: false })
    const endDate = processedEndDate.toISO({ includeOffset: false })
    const data = {
      startDate: {
        date: startDate,
        timeZone,
      },
      endDate: {
        date: endDate,
        timeZone,
      },
      userTimeZone: timeZone,
      selectedTemplate,
      ruleMeta,
      selectedName,
      orgId,
      today: getTodayDate().toISO(),
    }
    setSaving(true)
    try {
      const createActivity = httpsCallable(
        functions,
        'activityFunctions-createActivity',
      )
      const userIds = isGroup ? selectedUser.userIds : [selectedUser.id]

      await createActivity({ ...data, userIds })

      onResolve()
    } catch (err) {
      toastService.error('There was a problem assigning the activity')
      setSaving(false)
    }
  }

  function disableSave() {
    return (
      !selectedUser ||
      !selectedTemplate ||
      !selectedStartDate ||
      !processedEndDate ||
      saving
    )
  }

  const isLoading =
    saving ||
    workflowTemplatesLoading ||
    usersLoading ||
    groupsLoading ||
    publishedTemplatesLoading

  return (
    <>
      <Modal.Header>Assign Workflow</Modal.Header>
      <Modal.Body {...scope}>
        <div className="row break-1">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="workflow-select">
                Workflow Template <Required />
              </label>
              {isAdmin && !isEmpty(publishedTemplates) ? (
                <SelectSingle
                  triggerIsBlock
                  contentWidth="block"
                  fixedContent
                  data={[
                    {
                      id: 'published-templates',
                      icon: 'globe-stand',
                      name: 'Published',
                      data: publishedTemplates,
                    },
                    {
                      id: 'templates',
                      icon: 'ballot',
                      name: 'Unpublished',
                      data: workflowTemplates,
                    },
                  ]}
                  isGroupData
                  onChange={t => {
                    if (selectedTemplate) {
                      setSelectedStartDate(null)
                      setSelectedEndDate(null)
                    }
                    if (
                      !selectedName ||
                      selectedName === selectedTemplate?.name
                    ) {
                      setSelectedName(t.name)
                    }
                    setSelectedTemplate(t)
                  }}
                  searchOnChange={setSelectedWorkflowTemplateSearch}
                  searchValue={selectedTemplateSearch}
                  searchFilter
                  value={selectedTemplate}
                />
              ) : (
                <SelectSingle
                  triggerIsBlock
                  contentWidth="block"
                  data={workflowTemplates}
                  fixedContent
                  onChange={t => {
                    if (selectedTemplate) {
                      setSelectedStartDate(null)
                      setSelectedEndDate(null)
                    }
                    if (
                      !selectedName ||
                      selectedName === selectedTemplate?.name
                    ) {
                      setSelectedName(t.name)
                    }
                    setSelectedTemplate(t)
                  }}
                  searchOnChange={setSelectedWorkflowTemplateSearch}
                  searchValue={selectedTemplateSearch}
                  searchFilter
                  value={selectedTemplate}
                />
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>
                Name <Required />
              </label>
              <TextInput
                value={selectedName}
                onChange={setSelectedName}
                disabled={!selectedTemplate}
                placeholder="Workflow name"
              />
            </div>
          </div>
        </div>
        <div className="row break-1">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="user-select">
                Assign to <Required />
              </label>
              <SelectSingle
                triggerIsBlock
                contentWidth="block"
                placeholder="Select Assignee"
                isGroupData
                data={[
                  {
                    id: 'groups',
                    icon: 'users',
                    name: 'Groups',
                    data: groups,
                  },
                  {
                    id: 'users',
                    icon: 'user',
                    name: 'Users',
                    data: users,
                  },
                ]}
                onChange={userOrGroup => {
                  setIsGroup(!!userOrGroup?.userIds)
                  setSelectedUser(userOrGroup)
                }}
                searchOnChange={setSearchUser}
                fixedContent
                searchValue={searchUser}
                searchFilter
                value={selectedUser}
              />
            </div>
          </div>
        </div>
        <div className="row break-1">
          <div className="col-6">
            <div className="form-group">
              <label>
                Start date <Required />
              </label>
              <DatePicker
                placeholder="Select date"
                onChange={setSelectedStartDate}
                value={selectedStartDate}
                fixedContent
                triggerIsBlock
                disabled={!selectedTemplate}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              {endDateIsGenerated ? (
                <label>Generated end date</label>
              ) : (
                <label>
                  End date <Required />
                </label>
              )}
              <DatePicker
                placeholder={
                  endDateIsGenerated ? 'Select a start date' : 'Select date'
                }
                onChange={setSelectedEndDate}
                value={processedEndDate}
                triggerIsBlock
                fixedContent
                disabled={
                  !selectedStartDate || endDateIsGenerated || !selectedTemplate
                }
                minDate={selectedStartDate?.plus({ days: 1 }).set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })}
              />
            </div>
          </div>
        </div>
        {endDateIsGenerated && (
          <div className="form-group">
            <strong>Note:</strong> This template contains section rules that
            automatically determine the end date based off of the start date.
          </div>
        )}
        {templateHasEmptySection && (
          <div className="form-group">
            <strong>Warning:</strong> This template contains at least one empty
            section. Empty sections will be marked as complete.{' '}
            <Link
              onClick={() => onReject()}
              to={`templates/${selectedTemplate.id}`}
            >
              Go to template
            </Link>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer {...scope}>
        <Button
          variant="tertiary"
          onClick={onReject}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={build}
          disabled={disableSave()}
        >
          Assign
        </Button>
      </Modal.Footer>
      {isLoading && (
        <Modal.Overlay>
          <Loader />
          <div>Assigning activity...</div>
        </Modal.Overlay>
      )}
    </>
  )
}

AddWorkflowModal.propTypes = {}

const css = k`
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#fff, .9);
    border-radius: $base-border-radius;
    flex-direction: column;
  }

  .loading-progress {
    width: 20rem;
    padding: 1.6rem;
  }
`
