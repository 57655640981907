import { Icon } from '@components/icon.component'
import { Separator } from '@components/radix/separator'
import { useLoad } from '@hooks/use-load.hook'
import { analyticsUrl, db } from '@src/firebase-app'
import { SectionOverview } from '@src/pages/workflow/settings/activity-overview/section-overview.component'
import { dbNames } from '@utils/constants'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { a, k, useCss } from 'kremling'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import wretch from 'wretch'

type ActivityFieldsOverviewProps = {
  activity?: Activity
  participants?: ActivityUser[]
}

export function ActivityFieldsOverview({
  activity: propActivity,
}: ActivityFieldsOverviewProps) {
  const { activity: contextActivity, participants } =
    useOutletContext<ActivityFieldsOverviewProps>()
  const [selectedSection, setSelectedSection] = useState(null)
  const [ratings, setRatings] = useState(null)
  const scope = useCss(css)
  const activity = propActivity || contextActivity

  const [sections, _secSections, sectionsOpts] = useLoad(
    [],
    () => {
      if (!activity) return Promise.resolve([])
      const q = query(
        collection(db, dbNames.activitySections),
        where('activityId', '==', activity.id),
      )
      return getDocs(q).then(snap =>
        snap.docs.map(d => ({ ...d.data(), id: d.id })),
      )
    },
    [activity],
  ) as [ActivitySection[], () => void, { reload: () => void }]

  useEffect(() => {
    if (isEmpty(sections)) return
    setSelectedSection(sections[0])
  }, [sections])

  useEffect(() => {
    wretch(`${analyticsUrl}/api/activity/feedback/${activity.id}`)
      .get()
      .json()
      .then(data => {
        setRatings(data)
      })
  }, [])

  return (
    <div
      {...scope}
      className="pt-8 space-y-8"
    >
      <div>
        <h2 className="text-3xl font-semibold">Activity Feedback</h2>
        <div className="space-y-10">
          <div className="flex items-stretch gap-10">
            <div className="border border-grey-100 rounded-lg px-4 py-5 flex-1 max-w-[50%] space-y-4">
              <div className="flex flex-col text-3xl">
                <span>Average</span>{' '}
                <span className="font-semibold">Rating</span>
              </div>
              <Separator className="bg-grey-100" />
              <div className="p-2 text-5xl font-bold">
                {ratings?.averageRating
                  ? Math.floor(Number(ratings?.averageRating)) + '/5'
                  : 'N/A'}
              </div>
            </div>

            <div className="border border-grey-100 rounded-lg px-4 py-5 flex-1 max-w-[50%] flex flex-col justify-center gap-4">
              <div className="flex items-end justify-between">
                <div className="flex flex-col text-3xl">
                  <span>Highest</span>{' '}
                  <span className="font-semibold">Rating</span>
                </div>
                <div className="text-5xl font-bold">
                  {ratings?.highestRating}
                </div>
              </div>
              <Separator className="bg-grey-100" />
              <div className="flex items-end justify-between">
                <div className="flex flex-col text-3xl">
                  <span>Lowest</span>{' '}
                  <span className="font-semibold">Rating</span>
                </div>
                <div className="text-5xl font-bold">
                  {ratings?.lowestRating}
                </div>
              </div>
            </div>

            <div className="border border-grey-100 rounded-lg px-4 flex-1 max-w-[50%] py-3 flex items-stretch">
              <div className="flex-1 flex flex-col justify-center">
                <div className="flex items-end justify-between py-3 pr-3">
                  <div className="flex flex-col text-3xl">
                    <span>Users with</span>{' '}
                    <span className="font-semibold">Feedback</span>
                  </div>
                  <div className="text-5xl font-bold">
                    {ratings?.ratingUsersCount}
                  </div>
                </div>
                <Separator className="bg-grey-100" />
                <div className="flex items-end justify-between py-3 pr-3">
                  <div className="flex flex-col text-3xl">
                    <span>Total</span>{' '}
                    <span className="font-semibold">Users</span>
                  </div>
                  <div className="text-5xl font-bold">
                    {participants?.length}
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center border-l border-grey-100 pl-4">
                <div className="text-5xl font-bold">
                  {ratings
                    ? (Number(ratings.ratingUsersCount) / participants.length) *
                      100
                    : 0}
                  %
                </div>
              </div>
            </div>
          </div>
          {ratings?.feedbackSummary && (
            <div className="p-4 border border-grey-100 rounded-lg text-2xl">
              <h3 className="text-3xl font-bold flex items-center gap-3">
                <Icon name="wand-magic-sparkles" />
                Summary of Feedback
              </h3>
              <div>{ratings.feedbackSummary}</div>
            </div>
          )}
        </div>
      </div>
      <div>
        <h2 className="text-3xl font-semibold">Sections</h2>
        <div className="flex gap-8">
          <div className="flex-2 space-y-3">
            {sections.map(section => (
              <div
                className={a('section-item').m(
                  '--selected',
                  section.id === selectedSection?.id,
                )}
                key={section.id}
                onClick={() => setSelectedSection(section)}
              >
                {section.name || 'Untitled section'}
              </div>
            ))}
          </div>
          <SectionOverview
            selectedSection={selectedSection}
            activity={activity}
            onSectionChange={() => sectionsOpts.reload()}
            participants={participants}
          />
        </div>
      </div>
    </div>
  )
}

const css = k`
  .fields-wrapper {
    display: flex;
  }
  .sections-container {
    flex: 2;
    margin-right: 2rem;
  }

  .section-item {
    padding: .8rem;
    cursor: pointer;
    border-radius: $base-border-radius;
    outline: solid 1px $color-grey-100;

    &.--selected {
      background: $color-active-75;
    }
  }

  .fields-container {
    flex: 4;
  }
`
