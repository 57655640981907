import { DateTime } from 'luxon'
import { bool, func, object, string } from 'prop-types'
import React from 'react'

import { DatePicker } from '@components/date-picker.component'
import { FieldHeaderContent } from '@src/pages/workflow/client/fields/field-header-content.component'
import { useController } from 'react-hook-form'

export function WorkflowDate({
  field,
  readOnly,
  isRequired,
  section,
  control,
}) {
  const {
    field: { onChange, value, disabled, ...fieldProps },
    fieldState: { error },
  } = useController({
    disabled: readOnly,
    name: `${field.version}_${field.id}`,
    control,
    rules: {
      required: isRequired && 'This field is required',
    },
  })

  console.log(isRequired)

  function handleChange(date) {
    if (disabled) return
    onChange(date ? date.toISO() : null)
  }

  function handleClear() {
    if (disabled) return
    onChange(null)
  }

  return (
    <>
      <FieldHeaderContent
        field={field}
        isRequired={isRequired}
        section={section}
      />
      <DatePicker
        id={field.id}
        placeholder="Select date"
        value={value ? DateTime.fromISO(value) : null}
        onChange={handleChange}
        triggerIsBlock
        error={error?.message}
        onClear={handleClear}
        fixedContent
        {...fieldProps}
      />
    </>
  )
}

WorkflowDate.propTypes = {
  field: object,
  onChange: func,
  fieldVal: string,
  readOnly: bool,
}
