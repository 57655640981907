import { AnimatePresence, motion } from 'framer-motion'
import { a, k, m, useCss } from 'kremling'
import React, { useEffect, useState } from 'react'
import { ulid } from 'ulid'

import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { useIsMounted } from '@hooks/use-is-mounted.hook'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

export const useToastState = create(
  subscribeWithSelector(() => ({
    feeds: [],
  })),
)

class ToastService {
  __feed = (type, title, config = {}) => {
    const newKey = ulid()
    const buildConfig = {
      duration: 7000,
      ...config,
    }

    useToastState.setState(({ feeds }) => ({
      feeds: [
        ...feeds,
        {
          key: newKey,
          type,
          title,
          config: buildConfig,
          timeout: setTimeout(() => closeToast(newKey), buildConfig.duration),
        },
      ],
    }))
    setTimeout(() => {})
    return newKey
  }

  error = (title, config) => {
    console.error(title, config)
    const id = this.__feed('error', title, config)
    return () => closeToast(id)
  }
  info = (title, config) => {
    const id = this.__feed('info', title, config)
    return () => closeToast(id)
  }
}

export const toastService = new ToastService()

export function ToastEntry() {
  const [feeds, setFeeds] = useState([])
  const [refMap] = useState(() => new WeakMap())
  const isMounted = useIsMounted()
  const scope = useCss(css)
  useEffect(() => {
    return useToastState.subscribe(
      state => state.feeds,
      state => {
        if (isMounted.current) {
          setFeeds(state)
        }
      },
    )
  }, [])

  const itemVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
  }

  return (
    <div
      {...scope}
      className="feedback-container iact-container"
    >
      <AnimatePresence>
        {feeds.map(feed => (
          <motion.div
            key={feed.key}
            variants={itemVariants}
            exit={{ opacity: 0, y: 20 }}
            initial="initial"
            animate="animate"
            transition={{ duration: 0.3 }}
          >
            <div
              className={a('feedback').m(
                'feedback--error',
                feed.type === 'error',
              )}
              ref={ref => ref && refMap.set(feed, ref)}
            >
              {feed.type === 'error' && (
                <Icon
                  className="feedback-icon"
                  name="exclamation-circle"
                />
              )}
              <div>
                <div className={m('toast-title', feed.config.description)}>
                  {feed.title}
                </div>
                {feed.config.description && (
                  <div>{feed.config.description}</div>
                )}
              </div>
              <ActionIcon
                className="ml-3"
                icon="times"
                variant="tertiary-dark"
                size="sm"
                onClick={() => closeToast(feed.key)}
              />
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  )
}

function closeToast(key) {
  useToastState.setState(({ feeds }) => {
    const index = feeds.findIndex(feed => feed.key === key)
    return index > -1
      ? {
          feeds: [...feeds.slice(0, index), ...feeds.slice(index + 1)],
        }
      : {}
  })
}

const css = k`
  .feedback-container {
    position: fixed;
    bottom: 0;
    left: 50%;
    margin: 0 auto;
    z-index: 999999;
    transform: translateX(-50%);
  }

  .feedback {
    border-radius: $base-border-radius;
    background-color: $color-grey-900;
    color: #fff;
    padding: .8rem .8rem .8rem 1.6rem;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    margin-bottom: 8px;
  }

  .feedback-icon {
    margin-right: 1rem;
  }

  .toast-title {
    font-weight: 600;
  }

  .feedback--error {
    background-color: $color-danger;
  }
`
