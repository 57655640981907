import { isEmpty } from 'lodash'
import {
  arrayToBulletedList,
  generateNumberedListFromArray,
} from './assistant.helpers'

export const sectionsList = `
  Provide this as a numbered list.
`

export const sectionsAndFieldsFormat = `
  Format:
  Provide this list in JSON format like this:

  [
    {
      "action": string,
      "id": string,
      "validationFields": [...]
    }
  ]

  The \`action\` property should be the title of the overall activity. This should be a shorter version of the corresponding list item. For example if the list item says: "Get together for a group lunch and talk about your day.", the \`action\` value could read: "Group lunch"
  The \`id\` property should be a unique identifier made with the npm library called ULID.
  The \`validationFields\` property should be an array of objects, each containing information about the validating form fields I described previously.
  The \`validationFields\` array is required.
  The \`validationFields\` array should look like this:

  [{
    "fieldType": "Text" or "Date" or "Radio" or "Checkbox" or "File Upload" or "Select Box",
    "textType": "short" or "long" or "number" or "distance" or "duration" or "percentage" or null
    "fieldLabel": string,
    "fieldDescription": string,
    "choices": [{"label": string}] | null
  }]

  The \`fieldType\` property should be one of the provided options.
  The \`textType\` property should only be used if the \`fieldType\` is "Text".
  The \`textType\` value can be 1) "short" which is a normal input field, 2) "long" which is a rich text multiline input, 3) "number" which is a field that only accepts numbers 4) "distance" which is a number field that allows users to select a distance unit, 5) "duration" which allows users to input hours/minutes/seconds as numbers, and 6) "percentage" which allows users to input a percentage.
  The \`fieldLabel\` property should be any label that describes what part of the activity the field is intended to validate
  The \`fieldDescription\` property should be a short description of what the field is meant to validate along with any details for the user. This property is optional.
  The \`choices\` property should only be used for fields with the \`fieldType\` of \`Checkbox\`, \`Radio\`, or \`Select Box\`. All other fields will receive a null value for the choices

  Example:
  [
    {
      "action": "Complete a daily exercise routine",
      "id": "01BX5ZZKBKACTAV9WEVGEMMVRY",
      "validationFields": [
        {
          "fieldType": "Radio",
          "fieldLabel": "Did you complete the routine?",
          "fieldDescription": "Please select yes or no.",
          "choices": [
            {"label": "Yes"},
            {"label": "No"}
          ]
        },
        {
          "fieldType": "Text",
          "textType": "duration",
          "fieldLabel": "How many minutes did you exercise?",
          "fieldDescription": "Please enter the number of minutes you exercised.",
          "choices": null
        }
      ]
    },
    {
      "action": "Complete a daily workout",
      "id": "01BX5ZZKBKACTAV9WEVGEMMVRZ",
      "validationFields": [
        {
          "fieldType": "Text",
          "textType": "number",
          "fieldLabel": "Duration (in minutes)",
          "fieldDescription": null,
          "choices": null
        },
        {
          "fieldType": "Checkbox",
          "textType": null,
          "fieldLabel": "Type of workout",
          "fieldDescription": "Select one or more options",
          "choices": [
            {"label": "Cardio"},
            {"label": "Strength training"},
            {"label": "Flexibility/stretching"}
          ]
        }
      ]
    }
  ]

  Do not provide any other explanation text. Only provide the JSON object.
`

export function generateEmpathyInsightsPrompt(context) {
  const { outcomeDescription, audienceDemographics, audienceState } =
    context.fields

  return `
    You are working with a group described as follows: ${audienceDemographics}
    You would like to help your audience reach the following desired outcome: ${outcomeDescription}
    Here’s where your audience is right now as it relates to the desired outcome: ${audienceState}

    Please provide a JSON object with a single 'insights' property that contains an array of 5 key insights related to the demographic group. Each insight should be a string of two or three sentences.
    Please provide an array of 5 key insights as strings related to the demographic group.
    Refer to the demographic group by its name, not by its description throughout this list.
    The insights should include relevant demographic and psychographic attributes that can help an Experience Designer understand their audience better when creating an experience plan.
    Focus on the attributes that characterize this group, rather than on the challenges they face.
    Please maintain a neutral tone and formal style in your response.
    Do not use any qualifying terms like 'Finally' in the list items.
  `
}

export function generateEmpathyChallengesPrompt(context) {
  const { outcomeDescription, audienceDemographics, audienceState } =
    context.fields

  return `
    You are working with a group described as follows: ${audienceDemographics}
    You would like to help your audience reach the following desired outcome: ${outcomeDescription}
    Here’s where your audience is right now as it relates to the desired outcome: ${audienceState}

    Please provide a JSON object with a single 'challenges' property that contains an array of 5 key challenges as strings faced by the demographic group. Each challenge should be a string of two or three sentences.
    Refer to the demographic group by its name, not by its description throughout this list.
    The challenges should include problems, pain points, or obstacles that may arise from physical, emotional, or psychological barriers that prevent this group from fully engaging with or enjoying an experience.
    These challenges will help Experience Designers address the issues in their designs, ultimately improving the overall experience.
    Please maintain a neutral tone and formal style in your response.
    Do not use any qualifying terms like 'Finally' in the list items.
    These challenges should address different topics compared to the list of insights you provided earlier.
  `
}

export function generateExperienceIdeasPrompt(context) {
  const { outcomeDescription, audienceState } = context.fields
  const { researchInsights, researchChallenges } = context.selectedItems

  return `
    Continue as an expert at designing experiences and activities that help people make changes in their life.
    As a reminder, this is the desired outcome for the audience: ${outcomeDescription}.
    And this is the current state of the audience ${audienceState}

    Provide a JSON object with a single property called 'experiences' which contains an array of 5 experience ideas that can help the audience move from their current state to the desired state.
    Each experience in the array should be a string of two or three sentences.

    We will later request details about the activities that will comprise the experience, so please keep the experience suggestions at a high level.
    We are defining experience as a collection of activities that can be described in a few words to provide the general idea of what's being done to accomplish the desired outcome.

    When creating the ideas, heavily consider the following insights and/or challenges about the audience and ensure ideas factor them in:

    Insights:
    ${
      isEmpty(researchInsights)
        ? ''
        : `
      Insights:
      ${arrayToBulletedList(researchInsights)}
    `
    }

    ${
      isEmpty(researchChallenges)
        ? ''
        : `
      Challenges:
      ${arrayToBulletedList(researchChallenges)}
    `
    }
  `
}

export function generateTouchpointsPrompt(context) {
  const { outcomeDescription, audienceState } = context.fields

  const { experienceIdea, researchInsights, researchChallenges } =
    context.selectedItems

  return `
    Continue as an expert at designing experiences and activities that help people make changes in their life.
    Provide a JSON object with a single property called 'sections' which contains an array of 5 activity that could be completed by the audience during this experience: ${experienceIdea}

    Format example (as a typescript type):
    {
      sections: {
        title: 'Preparatory Step' | 'Main Activity' | 'Reflective Step',
        description: string,
      }[]
    }

    Do not consider any other of the experience ideas previously provided.
    The activities in this list should be unique to this experience idea, and no other.
    Do not consider tasks that the organizers of the experience would need to complete.
    Include preparatory steps, and reflective steps in addition to main activities.

    As a reminder, this is the desired outcome for the audience: ${outcomeDescription}.
    And this is the current state of the audience ${audienceState}

    When creating the ideas, heavily consider the following insights and challenges about the audience and ensure ideas factor them in:

    ${
      isEmpty(researchInsights)
        ? ''
        : `
      Insights:
      ${arrayToBulletedList(researchInsights)}
    `
    }

    ${
      isEmpty(researchChallenges)
        ? ''
        : `
      Challenges:
      ${arrayToBulletedList(researchChallenges)}
    `
    }
  `
}

export function generateActivitiesPrompt(context) {
  const { sections } = context.selectedItems
  const promptContent = `
    Lastly I'd like you to consider the activities listed below.
    For each activity, I want you to develop a list of a few form fields that could be used to validate a participant's experience, feelings, thoughts, and/or validation of whether or not they completed the activity.

    Activity list:
    ${arrayToBulletedList(sections)}
  `
  return `
    ${promptContent}
    ${sectionsAndFieldsFormat}
  `
}

const listOrder = [
  {
    name: 'researchInsights',
    generateContent: generateEmpathyInsightsPrompt,
    generateResponse: generateNumberedListFromArray,
  },
  {
    name: 'researchChallenges',
    generateContent: generateEmpathyChallengesPrompt,
    generateResponse: generateNumberedListFromArray,
  },
  {
    name: 'experienceIdeas',
    generateContent: generateExperienceIdeasPrompt,
    generateResponse: generateNumberedListFromArray,
  },
  {
    name: 'sections',
    generateContent: generateTouchpointsPrompt,
    generateResponse: generateNumberedListFromArray,
  },
]

export function generateMessageHistory(context) {
  const systemMessage = {
    role: 'system',
    content:
      'Pretend you are an expert at designing experiences and activities that help people make changes in their life. Please use valid json.',
  }
  const messages = [systemMessage]

  listOrder.forEach(({ name, generateContent, generateResponse }) => {
    const list = context.lists[name]
    if (isEmpty(list)) return

    const userMessage = {
      role: 'user',
      content: generateContent(context),
    }

    const assistantMessage = {
      role: 'assistant',
      content: generateResponse(list),
    }

    messages.push(userMessage, assistantMessage)
  })

  return messages
}
