import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import {
  MantineSize,
  TextInput as MantineTextInput,
  TextInputProps as MantineTextInputProps,
} from '@mantine/core'
import React, { ChangeEvent, forwardRef } from 'react'

type TextInputProps = {
  icon?: Icons
  allowClear?: boolean
  onChange?: (value: string, e?: ChangeEvent<HTMLInputElement>) => void
  onCancel?: () => void
  alwaysShowCancel?: boolean
  size?: string
} & Omit<MantineTextInputProps, 'onChange' | 'size'>

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      icon,
      allowClear,
      onCancel,
      alwaysShowCancel,
      onChange,
      defaultValue = '',
      size = 'lg' as MantineSize,
      ...inputProps
    },
    ref,
  ) => {
    const [value, setValue] = React.useState(defaultValue)

    function renderClearButton() {
      return (
        <ActionIcon
          variant="tertiary"
          icon="times"
          size="sm"
          iconClass="text-grey-500"
          onClick={() => {
            setValue('')
            onChange && onChange('')
            onCancel && onCancel()
          }}
        />
      )
    }

    return (
      <MantineTextInput
        unstyled
        size={size}
        ref={ref}
        onChange={e => {
          const value = e.target.value
          setValue(value)
          onChange && onChange(value, e)
        }}
        leftSection={
          icon ? (
            <Icon
              className="text-grey-500"
              name={icon}
              size={16}
            />
          ) : null
        }
        withAsterisk={inputProps.withAsterisk}
        value={value}
        rightSection={
          (allowClear && value) || alwaysShowCancel ? renderClearButton() : null
        }
        classNames={{
          ...inputProps.classNames,
          input: `${icon || inputProps.leftSection ? '!pl-12' : ''}`,
        }}
        {...inputProps}
      />
    )
  },
)
