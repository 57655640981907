import {
  FIELD_CONSTANTS,
  lengthOptions,
  TEXT_TYPE_CONSTANTS,
} from '@src/pages/workflow/fields.helper'
import { isEmpty } from 'lodash'

export function useAssistedResponsePrompts({ field }: { field: Field }) {
  const questionLimit = field.assistantLimit || 3

  const questionExamples = isEmpty(field.choices)
    ? `
    Questions You Ask:
    Ask subsequent questions one at a time, dynamically adapting based on my answers.
    Limit the questions to ${questionLimit}.
    Each question should include the form field type: RADIO, CHECKBOX, TEXT.
    Example:
    {
      "content": "What is your favorite color?",
      "type": "RADIO",
      "options": ["Red", "Blue", "Green"]
    }
  `
    : `
    Questions You Ask:
    Ask subsequent questions one at a time, dynamically adapting based on my answers.
    Limit the questions to ${questionLimit}.
    Each question should include the form field type: RADIO, CHECKBOX, TEXT.

    Do not use any of these choices as options in one of your questions: ${field.choices.map(({ label }) => label).join(', ')}.
    Your questions should be targeted at helping me understand which of the choices I should select, instead of prompting me to select one of the choices already presented to me.

    Example:
    choices: ["Red", "Blue", "Green"]
    {
      "content": "When do you like the colors the most?",
      "type": "RADIO",
      "options": ["Sunset", "Daytime", "Nighttime"]
    }

    Wrong example:
    choices: ["Red", "Blue", "Green"]
    {
      "content": "Which one of these colors is your favorite?",
      "type": "RADIO",
      "options": ["Red", "Blue", "Green"]
    }
  `

  const generalPromptIntro = `
    I am participating in a digital activity. I have a question from the activity that I need help answering.
    You will act as my assistant. Your objective is to ask me questions one at a time to help me answer the provided question.
    I will provide the initial question, and you will ask subsequent questions, one at a time. The questions should be dynamic.
    After the questions are all answered you should provide an ultimate response to the initial question based on my answers.
    Don't ask leading questions. Keep the questions open-ended and neutral.

    Key Aspects:
    - The type of form field I am responding to.
    - The question I am trying to answer from the activity.
    - The questions you ask me.
    - The response you generate for me.

    Details:
    Form Field Type:
    I am responding to a ${field.type} form field.

    Initial Question:
    I will provide the initial question or request from the activity.

    ${questionExamples}
  `

  const textFieldResponsePrompt = `
    The response should be written from my perspective and should be relatively short.

    Example response:
    {
      "content": "My favorite color is Red",
      "type": "RESPONSE"
    }
  `

  const longTextResponsePrompt = `
    The response should be written from my perspective and should be somewhat long-form text.

    Example response:
    {
      "content": "My favorite color is Red. But I don't mind other colors as well. Every since I can remember I have liked colors.",
      "type": "RESPONSE"
    }
  `

  const numberFieldResponsePrompt = `
    The response should be a numeric value without any other explanation.

    Example response:
    {
      "content": 7,
      "type": "RESPONSE"
    }
  `

  const distanceFieldResponsePrompt = `
    The response should be a numeric value with a unit of measurement.
    The unit of measurement should be one of the following: ${lengthOptions.map(({ id }) => id).join(', ')}

    Example response:
    {
      "content": {
        "value": 7,
        "unit": "m"
      },
      "type": "RESPONSE"
    }
  `

  const radioFieldResponsePrompt = `
    The response should be written from my perspective and should be relatively short.

    If the provided choices are ["Red", "Blue", "Green"], the response should be one of those choices.

    Example response:
    {
      "content": "Red",
      "type": "RESPONSE"
    }
  `

  const checkboxFieldResponsePrompt = `
    The response should be written from my perspective and should include all the choices I selected.

    If the provided choices are ["Red", "Blue", "Green"], the response should be one or more of those choices.

    Example response:
    {
      "content": ["Red", "Blue"],
      "type": "RESPONSE"
    }
  `

  const durationFieldResponsePrompt = `
    The response should be a numeric value in milliseconds.

    Example response:
    {
      "content": 300000,
      "type": "RESPONSE"
    }
  `

  let fieldResponsePrompt = ''

  switch (field.type) {
    case FIELD_CONSTANTS.TEXT:
      if (
        field.textType === TEXT_TYPE_CONSTANTS.NUMBER ||
        field.textType === TEXT_TYPE_CONSTANTS.PERCENTAGE
      ) {
        fieldResponsePrompt = numberFieldResponsePrompt
        break
      }

      if (field.textType === TEXT_TYPE_CONSTANTS.LONG) {
        fieldResponsePrompt = longTextResponsePrompt
        break
      }

      if (field.textType === TEXT_TYPE_CONSTANTS.DISTANCE) {
        fieldResponsePrompt = distanceFieldResponsePrompt
        break
      }

      if (field.textType === TEXT_TYPE_CONSTANTS.DURATION) {
        fieldResponsePrompt = durationFieldResponsePrompt
        break
      }

      fieldResponsePrompt = textFieldResponsePrompt
      break
    case FIELD_CONSTANTS.CHECKBOX:
      fieldResponsePrompt = checkboxFieldResponsePrompt
      break
    case FIELD_CONSTANTS.SELECT:
      if (field.multiselect) {
        fieldResponsePrompt = checkboxFieldResponsePrompt
        break
      }

      fieldResponsePrompt = radioFieldResponsePrompt
      break
    case FIELD_CONSTANTS.RADIO:
      fieldResponsePrompt = radioFieldResponsePrompt
      break
  }

  const generalPromptConclusion = `
    Guidelines:
    - Do not ask leading questions.
    - Keep questions open-ended and neutral.
    - Format responses in valid JSON.
    - Include the form field type: RADIO, CHECKBOX, TEXT.
    - Provide options for RADIO and CHECKBOX questions.
    - If the source question is complex, break it down into simpler questions.
    - Don't mention the initial question in your response.
  `

  return `
    ${generalPromptIntro}

    Final Response:
    Provide a final response to the initial question based on my answers.
    ${fieldResponsePrompt}

    ${generalPromptConclusion}
  `
}
