import {
  collection,
  DocumentData,
  CollectionReference,
} from 'firebase/firestore'

import { dbNames } from '@utils/constants'
import { db } from '@src/firebase-app'

const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(db, collectionName) as CollectionReference<T>
}

export const collections = {
  users: createCollection<User>(dbNames.users),
  // workflowTemplates: collection(db, dbNames.workflowTemplates),
  // sections: collection(db, dbNames.sections),
  // iterations: collection(db, dbNames.iterations),
  // groups: collection(db, dbNames.groups),
  // workflowMentors: collection(db, dbNames.workflowMentors),
  // pendingInvitations: collection(db, dbNames.pendingInvitations),
  // userCreationRequests: collection(db, dbNames.userCreationRequests),
  // files: collection(db, dbNames.files),
  organizations: createCollection<Organization>(dbNames.organizations),
  // organizationProfiles: collection(db, dbNames.organizationProfiles),
  linkInvitations: createCollection<LinkInvitation>(dbNames.invitations),
  peerMentorInvitations: createCollection<PeerMentorInvitation>(
    dbNames.invitations,
  ),
  workspaceInvitations: createCollection<WorkspaceInvitation>(
    dbNames.invitations,
  ),
  // publishedTemplates: collection(db, dbNames.publishedTemplates),
  // marketplaceTemplates: collection(db, dbNames.marketplaceTemplates),
  // adoptedMarketplaceTemplates: collection(db, dbNames.adoptedMarketplaceTemplates),
  // curatedTemplates: collection(db, dbNames.curatedTemplates),
  workflowChallenges: createCollection<WorkflowChallenge>(
    dbNames.activityChallenges,
  ),
  activityUsers: createCollection<ActivityUser>(dbNames.activityUsers),
  activities: createCollection<Activity>(dbNames.activities),
  activityMentors: createCollection<ActivityMentor>(dbNames.activityMentors),
  // analyticsErrors: collection(db, dbNames.analyticsErrors),
  scheduledTasks: createCollection<ScheduledTask>(dbNames.scheduledTasks),
  // messageRooms: collection(db, dbNames.messageRooms),
  // messageRoomUsers: collection(db, dbNames.messageRoomUsers),
  // messages: collection(db, dbNames.messages),
  // notifications: collection(db, dbNames.notifications),
  assistantConversations: createCollection<AssistantConversation>(
    dbNames.assistantConversations,
  ),
}
