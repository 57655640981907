import { Button } from '@components/mantine/button.component'
import { Modal } from '@components/modal.component'
import React from 'react'

type ChangeConfirmationModalProps = {
  onReject: () => void
  onResolve: () => void
}

export const ChangeConfirmationModal = ({
  onReject,
  onResolve,
}: ChangeConfirmationModalProps) => {
  return (
    <Modal onClose={onReject}>
      <Modal.Header>Are you sure?</Modal.Header>
      <Modal.Body>
        Changing the experience will reset the Touchpoints and Activities lists.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onReject}>Cancel</Button>
        <Button
          variant="primary"
          onClick={onResolve}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
