import { Dropdown } from '@components/radix/dropdown'
import { Icon } from '@src/components/icon.component'
import { getTodayDate } from '@utils/helpers'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import React, { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { RRule } from 'rrule'
import { calcIterationCompletionPercentage } from './activity-utils'
import { CompletedBadge } from './completed-badge.component'
import { useActivityStore } from './state/use-activity-state'

type Props = {
  goToNextIteration: () => void
  goToPreviousIteration: () => void
  iterations: SectionIteration[]
}

export const IterationNavigation = ({
  goToNextIteration,
  goToPreviousIteration,
  iterations,
}: Props) => {
  const { iterationId, sectionId } = useParams()
  const sections = useActivityStore.use.sections()
  const progressSections = useActivityStore.use.progressSections()
  const activity = useActivityStore.use.activity()
  const section = useMemo(
    () => sections.find(s => s.id === sectionId),
    [sectionId, sections],
  )
  const progressSection = useMemo(
    () => progressSections.find(s => s.id === sectionId),
    [sectionId, progressSections],
  )

  const iteration = iterations.find(i => i.id === iterationId)

  const iterationDateRanges = useMemo(() => {
    if (!iterations || iterations.length === 0 || !section || !activity)
      return { currentAndPast: [], nextFuture: [] }
    const today = getTodayDate()
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    let allIterationDates
    if (section.constraints.recurrence) {
      const rruleOptions = RRule.parseString(section.constraints.recurrence)

      rruleOptions.dtstart = DateTime.fromISO(activity.startDate.date, {
        zone: activity.startDate.timeZone,
      })
        .startOf('day')
        .toJSDate()
      rruleOptions.until = DateTime.fromISO(activity.endDate.date, {
        zone: activity.endDate.timeZone,
      })
        .endOf('day')
        .toJSDate()

      const rrule = new RRule(rruleOptions)

      allIterationDates = rrule.all().map((jsDate, i) => {
        const iterationId = iterations[i]?.id
        const date = DateTime.fromJSDate(jsDate).setZone(timeZone)

        const interval = RRule.parseString(
          section.constraints.recurrence,
        ).interval

        if (interval === 1) {
          const dateRangeString = date.toFormat('MMM d')
          return {
            dateRange: dateRangeString,
            date,
            iterationId,
            completedPercentage: iterationId
              ? calcIterationCompletionPercentage(
                  section,
                  iterations[i].fieldResponses || {},
                )
              : 0,
          }
        } else {
          const endDate = date.plus({ days: interval })
          const dateRangeString = `${date.toFormat('MMM d')} - ${endDate.toFormat('MMM d')}`

          return {
            dateRange: dateRangeString,
            date,
            iterationId,
            completedPercentage: iterationId
              ? calcIterationCompletionPercentage(
                  section,
                  iterations[i].fieldResponses || {},
                )
              : 0,
          }
        }
      })
    } else {
      allIterationDates = iterations.map(i => {
        const date = DateTime.fromISO(i.startDate.date, {
          zone: i.startDate.timeZone,
        })
        const endDate = DateTime.fromISO(activity.endDate.date, {
          zone: activity.endDate.timeZone,
        })
        const dateRangeString = `${date.toFormat('MMM d')} - ${endDate.toFormat('MMM d')}`
        return {
          dateRange: dateRangeString,
          date,
          iterationId: i.id,
          completedPercentage: calcIterationCompletionPercentage(
            section,
            i.fieldResponses || {},
          ),
        }
      })
    }

    const currentAndPast = allIterationDates.filter(i => i.date <= today)
    const nextFuture = allIterationDates.filter(i => i.date > today)

    return { currentAndPast, nextFuture }
  }, [section, iterations, progressSection, activity])

  const [previousIteration, nextIteration] = useMemo(() => {
    if (!iterationId) return [null, null]
    const iterationIndex = iterations.findIndex(i => i.id === iterationId)
    const next = iterations[iterationIndex + 1] || null
    const previous = iterations[iterationIndex - 1] || null
    return [previous, next]
  }, [iterations, iterationId])

  const isComplete = useMemo(
    () =>
      calcIterationCompletionPercentage(
        section,
        iteration?.fieldResponses || {},
      ) === 100,
    [section, iteration],
  )
  const allIterations = [
    ...iterationDateRanges.currentAndPast,
    ...iterationDateRanges.nextFuture,
  ]

  if (allIterations.length === 1) {
    return (
      <div className="flex flex-col items-center p-2 shadow-sm media2:max-w-[70rem] media2:min-w-[30rem] w-full bg-white border border-grey-100 rounded-md mx-auto justify-center">
        <div className="hidden media2:block text-2xl font-semibold">
          {section?.name || 'Untitled section'}
        </div>
        <div className="flex items-center relative">
          {iterationDateRanges.currentAndPast[0]?.dateRange}
          <CompletedBadge
            className="ml-2 absolute -right-10"
            showBadge={isComplete}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="flex items-center p-2 shadow-sm media2:max-w-[70rem] media2:min-w-[30rem] w-full bg-white border border-grey-100 rounded-md mx-auto justify-between">
      <button
        onClick={goToPreviousIteration}
        disabled={!previousIteration}
        className="flex items-center justify-center h-14 w-14 media2:h-20 media2:w-20 border border-grey-100 rounded-full disabled:opacity-50"
      >
        <Icon
          name="caret-left"
          size={14}
        />
      </button>
      <div className="text-center">
        <div className="media4:block hidden text-2xl font-semibold">
          {section?.name || 'Untitled section'}
        </div>
        <div className="flex items-center relative">
          <Dropdown>
            <Dropdown.Trigger className="py-2 px-4 border border-grey-100 rounded-full flex items-center focus:outline-none focus:bg-grey-50 select-none">
              {
                iterationDateRanges.currentAndPast.find(
                  i => i.iterationId === iterationId,
                )?.dateRange
              }
              <Icon
                name="caret-down"
                size={14}
                className="ml-2"
              />
            </Dropdown.Trigger>
            <Dropdown.Content>
              <div className="w-[30rem] bg-white rounded-md shadow-md flex flex-col overflow-hidden">
                {iterationDateRanges.currentAndPast.map((range, i) => (
                  <Dropdown.ListItem key={i}>
                    <Link
                      data-selected={range.iterationId === iterationId}
                      className="hover:bg-active-25 hover:outline-none opacity-90 focus:outline-none focus:bg-grey-50 data-[selected=true]:bg-grey-75 data-[selected=true]:opacity-100 data-[selected=true]:font-semibold flex items-center justify-between px-3 w-full h-14 text-grey-600"
                      to={`${sectionId}/${range.iterationId}`}
                    >
                      <div className="flex items-center">
                        <div
                          data-selected={range.iterationId === iterationId}
                          className="invisible w-2 h-2 mr-2 rounded-full bg-primary data-[selected=true]:visible"
                        />
                        {range.dateRange}
                      </div>
                      {range.completedPercentage === 100 ? (
                        <CompletedBadge
                          className="text-grey-200"
                          showBadge
                          size={14}
                        />
                      ) : (
                        `${range.completedPercentage || 0}%`
                      )}
                    </Link>
                  </Dropdown.ListItem>
                ))}
                <div className="hover:outline-none focus:outline-none focus:bg-grey-50 flex items-center justify-between  w-full text-grey-600 p-2">
                  <div className="flex items-center justify-between w-full bg-active-25 rounded-md px-3 h-14 font-semibold">
                    {!isEmpty(iterationDateRanges.nextFuture) ? (
                      <div className="w-full text-center">
                        Next: {iterationDateRanges.nextFuture[0].dateRange}
                      </div>
                    ) : (
                      <div className="w-full text-center">
                        Activity ends{' '}
                        {DateTime.fromISO(activity?.endDate.date).toFormat(
                          'MMM d',
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Dropdown.Content>
          </Dropdown>
          <CompletedBadge
            className="ml-2 absolute -right-10"
            showBadge={isComplete}
            size={16}
          />
        </div>
      </div>
      <button
        onClick={goToNextIteration}
        disabled={!nextIteration}
        className="flex items-center justify-center h-14 w-14 media2:h-20 media2:w-20 border border-grey-100 rounded-full disabled:opacity-50"
      >
        <Icon
          name="caret-right"
          size={14}
        />
      </button>
    </div>
  )
}
