import React from 'react'

import { Icon } from '@components/icon.component'
import { Button } from '@components/mantine/button.component'
import { NumberInput } from '@components/mantine/number-input.component'
import { Modal } from '@components/modal.component'
import { Dropdown } from '@components/radix/dropdown'
import { RadioGroup } from '@components/radix/radio-group'
import { Switch } from '@components/radix/switch'
import { Well } from '@components/well.component'
import { nonCompletableFields } from '@src/pages/workflow/fields.helper'
import { Controller, useForm } from 'react-hook-form'
import { Frequency, Options, RRule, Weekday, rrulestr } from 'rrule'
import { WeekdayCheckboxes } from './weekday-checkboxes.component'

const intervalUnitOptions = [
  { label: 'Day', value: RRule.DAILY },
  { label: 'Week', value: RRule.WEEKLY },
  { label: 'Month', value: RRule.MONTHLY },
]

const freqArray: Frequency[] = [
  RRule.YEARLY,
  RRule.MONTHLY,
  RRule.WEEKLY,
  RRule.DAILY,
  RRule.HOURLY,
  RRule.MINUTELY,
  RRule.SECONDLY,
]

type FormValues = {
  recurrenceEnabled: boolean
  requiredMode: RequiredMode
  anyRequiredCount: number
  intervalUnit: Frequency
  intervalValue: number
  daysOfWeek: Weekday[]
  untilUnit: 'never' | 'after'
  untilValue: number
}

export function SectionSettingsModal({
  onResolve,
  onReject,
  section,
  isSequential,
}: {
  onResolve: (constraints: SectionConstraints) => void
  onReject: () => void
  section?: ActivitySection
  isSequential: boolean
}) {
  const rruleOptions = section?.constraints.recurrence
    ? rrulestr(section?.constraints.recurrence || null)
    : null

  const [updating, setUpdating] = React.useState<boolean>(false)

  const { handleSubmit, control, watch, resetField } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      recurrenceEnabled: !!section?.constraints.recurrence || false,
      requiredMode: section?.constraints.requiredMode,
      anyRequiredCount: section?.constraints.anyRequiredCount || 1,
      intervalUnit: freqArray[rruleOptions?.origOptions.freq] || RRule.DAILY,
      intervalValue: rruleOptions?.origOptions.interval || 1,
      daysOfWeek: (rruleOptions?.origOptions.byweekday as Weekday[]) || [],
      untilUnit:
        section?.constraints.repeat || isSequential ? 'after' : 'never',
      untilValue: section?.constraints.repeat || 2,
    },
  })

  const intervalUnit = watch('intervalUnit')
  const untilUnit = watch('untilUnit')
  const untilValue = watch('untilValue')
  const intervalValue = watch('intervalValue')
  const recurrenceEnabled = watch('recurrenceEnabled')
  const requiredNumber = watch('anyRequiredCount')

  console.log(nonCompletableFields)
  console.log(section.fields)
  console.log(
    section.fields.filter(({ type }) => !nonCompletableFields.includes(type)),
  )

  function updateTemplate({ requiredMode, daysOfWeek }: FormValues) {
    setUpdating(true)
    const sectionConstraints = { repeat: untilValue } as SectionConstraints
    sectionConstraints.requiredMode = requiredMode

    if (requiredMode === 'all') {
      sectionConstraints.required = section.fields
        .filter(({ type }) => !nonCompletableFields.includes(type))
        .map(field => field.id)
      sectionConstraints.anyRequiredCount = 0
    } else if (requiredMode === 'any') {
      sectionConstraints.anyRequiredCount = requiredNumber
      sectionConstraints.required = []
    } else if (requiredMode === 'none') {
      sectionConstraints.required = []
      sectionConstraints.anyRequiredCount = 0
    }

    if (recurrenceEnabled) {
      const options: Partial<Options> = {
        freq: intervalUnit,
        interval: intervalValue,
        byweekday: daysOfWeek,
        wkst: RRule.SU,
        count: untilUnit === 'after' ? untilValue : null,
      }

      const rule = new RRule(options)

      sectionConstraints.recurrence = rule.toString()

      if (untilUnit === 'after') {
        sectionConstraints.repeat = untilValue
      } else {
        sectionConstraints.repeat = null
      }
    } else {
      sectionConstraints.recurrence = null
      sectionConstraints.repeat = null
    }

    onResolve(sectionConstraints)
  }

  return (
    <>
      <Modal.Header>Edit section settings</Modal.Header>
      <form onSubmit={handleSubmit(updateTemplate)}>
        <Modal.Body className="space-y-8">
          <div>
            <div>
              <Controller
                control={control}
                name="requiredMode"
                render={({ field }) => (
                  <RadioGroup
                    value={field.value}
                    onBlur={field.onBlur}
                    onValueChange={field.onChange}
                  >
                    <div className="flex flex-col gap-4">
                      <RadioGroup.Item
                        value="all"
                        label="All fields are required"
                      />
                      <RadioGroup.Item
                        value="any"
                        label={
                          <div className="flex items-center gap-3">
                            <span>Any</span>
                            <Controller
                              control={control}
                              name="anyRequiredCount"
                              render={({ field: numField }) => (
                                <NumberInput
                                  {...numField}
                                  autoComplete="off"
                                  disabled={field.value !== 'any'}
                                  className="w-32"
                                  min={1}
                                  max={section?.fields.length - 1}
                                />
                              )}
                            />
                            <span>
                              field
                              {Number(requiredNumber) > 1
                                ? 's are'
                                : ' is'}{' '}
                              required
                            </span>
                          </div>
                        }
                      />
                      <RadioGroup.Item
                        value="none"
                        label="None are required"
                      />
                    </div>
                  </RadioGroup>
                )}
              />
            </div>
          </div>
          <Well className="!shadow-none">
            <Well.Header>
              <div className="flex items-center gap-4">
                <Controller
                  control={control}
                  name="recurrenceEnabled"
                  render={({ field }) => (
                    <Switch
                      id="enable-recurrence-switch"
                      onChange={field.onChange}
                      value={field.value}
                      onBlur={field.onBlur}
                    />
                  )}
                />
                <label htmlFor="enable-recurrence-switch">
                  Enable recurrence
                </label>
              </div>
            </Well.Header>
            {recurrenceEnabled && (
              <Well.Body>
                <div className="space-y-8">
                  <div className="flex items-center gap-4">
                    <div>Repeat every</div>
                    <Controller
                      control={control}
                      name="intervalValue"
                      render={({ field }) => (
                        <NumberInput
                          {...field}
                          min={1}
                          step={1}
                          className="w-36"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="intervalUnit"
                      render={({ field }) => {
                        return (
                          <Dropdown>
                            <Dropdown.Trigger
                              onBlur={field.onBlur}
                              className="h-[3.6rem] px-4 rounded-md flex justify-between border border-grey-100 items-center bg-white select-none hover:bg-grey-25 transition-colors"
                            >
                              {intervalUnitOptions.find(
                                unit => unit.value === field.value,
                              )?.label + (Number(intervalValue) > 1 ? 's' : '')}
                              <Icon
                                name="caret-down"
                                size={14}
                                className="ml-4"
                              />
                            </Dropdown.Trigger>
                            <Dropdown.Content align="center">
                              <div className="rounded-md shadow-lg bg-white flex flex-col top-5 z-[10000]">
                                <div>
                                  <div className="flex flex-col">
                                    {intervalUnitOptions.map(unit => (
                                      <Dropdown.ListItem
                                        key={unit.value}
                                        value={`${unit.value}`}
                                        onSelect={() =>
                                          field.onChange(unit.value)
                                        }
                                        className="p-4 rounded-md hover:bg-grey-25 transition-colors cursor-pointer"
                                      >
                                        <div>
                                          {unit.label +
                                            (Number(intervalValue) > 1
                                              ? 's'
                                              : '')}
                                        </div>
                                      </Dropdown.ListItem>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </Dropdown.Content>
                          </Dropdown>
                        )
                      }}
                    />
                  </div>

                  {intervalUnit === RRule.WEEKLY && (
                    <Controller
                      control={control}
                      name="daysOfWeek"
                      render={({ field }) => (
                        <WeekdayCheckboxes
                          days={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                        />
                      )}
                    />
                  )}

                  <div className="space-y-4">
                    <div>
                      <label className="mb-2">Until</label>
                      {isSequential ? (
                        <div className="text-grey-600">
                          This section is part of a sequential template.
                          Sequential sections require a limit of occurrences.
                        </div>
                      ) : (
                        <Controller
                          control={control}
                          name="untilUnit"
                          render={({ field }) => (
                            <RadioGroup
                              value={field.value}
                              onBlur={field.onBlur}
                              onValueChange={value => {
                                field.onChange(value)
                                resetField('untilValue')
                              }}
                            >
                              <div className="flex items-center gap-10">
                                <RadioGroup.Item
                                  value="never"
                                  label="Never"
                                />
                                <RadioGroup.Item
                                  value="after"
                                  label="After"
                                />
                              </div>
                            </RadioGroup>
                          )}
                        />
                      )}
                    </div>
                    {untilUnit === 'after' && (
                      <div className="flex items-center gap-4">
                        <Controller
                          control={control}
                          name="untilValue"
                          render={({ field }) => (
                            <NumberInput
                              {...field}
                              min={2}
                              className="w-36"
                              id="until-value"
                            />
                          )}
                        />
                        <label htmlFor="until-value">Occurrences</label>
                      </div>
                    )}
                  </div>
                </div>
              </Well.Body>
            )}
          </Well>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-end">
          <Button
            type="button"
            variant="tertiary"
            onClick={onReject}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            loading={updating}
          >
            Save settings
          </Button>
        </Modal.Footer>
      </form>
    </>
  )
}
