import { createUserWithEmailAndPassword, signInWithCustomToken } from 'firebase/auth'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { InputField } from '@components/input'
import { Button } from '@components/mantine/button.component'
import { auth, functions } from '@src/firebase-app'
import { httpsCallable } from 'firebase/functions'
import { useParams } from 'react-router-dom'

type RegisterPasswordProps = {
  onRegister?: () => void
  handleClick?: () => void
  linkAccountToken?: string
  onLinkAccount?: (user: unknown) => void
  email?: string
}

interface JwtAuthVerificationParams {
  token: string;
}

interface JwtAuthVerificationResponse {
  customToken: string;
}

export function RegisterPassword({
  onRegister = () => {},
  handleClick = () => {},
  linkAccountToken = '',
  onLinkAccount,
  email,
}: RegisterPasswordProps) {
  const [warning, setWarning] = useState('')
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: { email: email || '', password: '', verifyPassword: '' },
  })

  async function handleCreateAccount(data: {
    email: string;
    password: string;
  }) {
    try {
      if (linkAccountToken) {
        const { data } = await httpsCallable<JwtAuthVerificationParams, JwtAuthVerificationResponse>(
          functions,
          'allFunctions-jwtAuthVerification',
        )({ token: linkAccountToken });

        await signInWithCustomToken(auth, data.customToken);

        await httpsCallable(
          functions,
          'userFunctions-forcePasswordChange',
        )({
          uid: auth.currentUser.uid,
        })
        onLinkAccount({ user: auth.currentUser });
      } else {
        await createUserWithEmailAndPassword(auth, data.email, data.password);
        onRegister();
      }
    } catch (err) {
      console.log(err);
      if (err.code === 'auth/email-already-in-use') {
        setWarning(err.message);
      }
    }
  }


  return (
    <div className="authentication-container">
      <div className="password-auth">
        <form onSubmit={handleSubmit(handleCreateAccount)}>
          <div className="row break-1">
            <div className="col-12">
              <div className="form-group">
                <InputField
                  disabled={!!email}
                  control={control}
                  fieldName="email"
                  type="email"
                  placeholder="Enter your email address"
                  label="Email"
                  required
                  withAsterisk={false}
                />
              </div>
            </div>
          </div>
          <div className="row break-1">
            <div className="col-12">
              <div className="form-group">
                <InputField
                  control={control}
                  fieldName="password"
                  type="password"
                  placeholder="Enter your password"
                  label="Password"
                  required
                  minLength={6}
                  withAsterisk={false}
                />
              </div>
            </div>
          </div>
          <div className="row break-1">
            <div className="col-12">
              <div className="form-group">
                <InputField
                  control={control}
                  fieldName="verifyPassword"
                  type="password"
                  placeholder="Verify your password"
                  label="Verify password"
                  required
                  minLength={6}
                  withAsterisk={false}
                />
              </div>
            </div>
          </div>
          <Button
            type="submit"
            onClick={handleClick}
            disabled={!isValid}
            variant="primary"
            fullWidth
          >
            {linkAccountToken ? 'Link account' : 'Create account'}
          </Button>
          {warning && <div className="mt-4 has-warning">{warning}</div>}
        </form>
      </div>
    </div>
  )
}
