import { isArray, isEmpty, isObject } from 'lodash'

export function calculateNumFieldsCompleted(fieldResponses) {
  if (!fieldResponses) return 0
  const completed = Object.keys(fieldResponses).filter(id => {
    const response = fieldResponses[id]
    if (isObject(response) || isArray(response)) {
      return !isEmpty(response)
    }
    return !!response
  })
  return completed.length
}

export function getCompletedFieldIds(fieldResponses) {
  if (!fieldResponses) return []

  return Object.keys(fieldResponses).filter(id => {
    const response = fieldResponses[id]
    if (isObject(response) || isArray(response)) {
      return !isEmpty(response)
    }
    return !!response
  })
}

export function fileIsImage(file) {
  return file.type.includes('image')
}
