import { InvitationCard } from '@components/cards/invitation-card.component'
import { Logo } from '@components/logo.component'
import { useAppState } from '@src/app.state'
import { k, useCss } from 'kremling'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const AppEmptyState = () => {
  const scope = useCss(css)
  const navigate = useNavigate()
  const orgs = useAppState(state => state.orgs)
  const invitations = useAppState(state => state.invitations)

  useEffect(() => {
    useAppState.setState({ appLoading: false })
  }, [])

  useEffect(() => {
    if (orgs.length > 0) {
      navigate(`/${orgs[0].id}`)
    }
  }, [orgs.length])

  return (
    <div
      {...scope}
      className="p-10 flex h-full w-full items-center justify-center relative"
    >
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-danger animated" />
      <div className="absolute top-0 left-0 right-0 bottom-0 opacity-60 animated-2" />

      <div className="w-full h-full max-h-[50rem] max-w-[50rem] bg-white shadow-md rounded-lg z-10">
        <div className="h-full flex items-center justify-center flex-col gap-14">
          <div className="text-5xl font-bold text-primary flex items-center gap-3">
            Welcome to <Logo />
          </div>
          <div className="space-y-6">
            {invitations.map(invitation => (
              <InvitationCard
                key={invitation.id}
                invitation={invitation}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const css = k`
  .animated {
    background: linear-gradient(130deg, var(--color-primary), var(--color-active-150));
    background-size: 200% 200%;

    -webkit-animation: Animation 15s ease infinite;
    -moz-animation: Animation 15s ease infinite;
    animation: Animation 15s ease infinite;
  }

  .animated-2 {
    background: linear-gradient(260deg, var(--color-stats-two), var(--color-active-150));
    background-size: 200% 200%;

    -webkit-animation: Animation 10s ease infinite;
    -moz-animation: Animation 10s ease infinite;
    animation: Animation 10s ease infinite;
  }

  @-webkit-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
  }
  @-moz-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
  }
  @keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
  }
`
