import { addDoc, collection, getDocs, query, where } from 'firebase/firestore'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { TemplateCard } from '@components/cards/template-card.component'
import { Loader } from '@components/loader.component'
import { Button } from '@components/mantine/button.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { toastService } from '@utils/toast.service'

export function SuperSettingsLibrary() {
  const user = useAppState(state => state.user)
  const navigate = useNavigate()

  const [templates, _setTemplates, templateOpts] = useLoad(
    [],
    () => {
      const q = query(
        collection(db, dbNames.workflowTemplates),
        where('orgId', '==', 'super'),
      )
      return getDocs(q).then(q => q.docs.map(d => ({ id: d.id, ...d.data() })))
    },
    [],
  )

  function addTemplate() {
    const date = getTodayDate().toISO()
    addDoc(collection(db, dbNames.workflowTemplates), {
      name: '',
      sections: [],
      orgId: 'super',
      currentVersionEditorIds: [user.id],
      editorIds: [user.id],
      authorId: user.id,
      createdDate: date,
      assistant: false,
      lastEditedDate: date,
      lastEditedById: user.id,
    })
      .then(template => {
        toastService.info('New workflow template created')
        navigate(`t/${template.id}`)
      })
      .catch(e => toastService.error(e.message))
  }

  function createDuplicateTemplate(template) {
    const date = getTodayDate().toISO()
    const duplicatedTemplatePayload = {
      name: `${template.name} (copy)`,
      sections: template.sections,
      orgId: template.orgId,
      createdDate: date,
      lastEditedDate: date,
      lastEditedById: user.id,
      currentVersionEditorIds: [user.id],
      editorIds: [user.id],
      authorId: user.id,
    }

    addDoc(
      collection(db, dbNames.workflowTemplates),
      duplicatedTemplatePayload,
    ).then(() => {
      toastService.info('Template duplicated successfully')
      templateOpts.reload()
    })
  }

  if (templateOpts.loading) {
    return <Loader />
  }

  return (
    <div>
      <Button
        onClick={addTemplate}
        className="mt-5"
        variant="primary"
      >
        Create new template
      </Button>
      {templates.map(template => (
        <TemplateCard
          createDuplicateTemplate={createDuplicateTemplate}
          template={template}
          key={template.id}
        >
          {template.name}
        </TemplateCard>
      ))}
    </div>
  )
}
