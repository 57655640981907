import { Icon } from '@components/icon.component'
import {
  NumberInput as MantineNumberInput,
  NumberInputProps as MantineNumberInputProps,
  NumberInputHandlers,
} from '@mantine/core'
import React, { forwardRef } from 'react'

type NumberInputProps = {
  disableWheel?: boolean
  hideControls?: boolean
  onFocus?: () => void
  selectOnFocus?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
} & MantineNumberInputProps

export const NumberInput = forwardRef<HTMLDivElement, NumberInputProps>(
  (props: NumberInputProps, ref) => {
    const {
      size = 'xl',
      hideControls,
      disableWheel,
      onFocus,
      selectOnFocus = true,
      ...inputProps
    } = props
    const handlersRef = React.useRef<NumberInputHandlers>(null)

    function incrementNumber() {
      handlersRef.current?.increment()
    }

    function decrementNumber() {
      handlersRef.current?.decrement()
    }

    return (
      <MantineNumberInput
        unstyled
        ref={ref}
        size={size}
        handlersRef={handlersRef}
        onFocus={e => {
          setTimeout(() => {
            //If there is a suffix, then mantine fires some code that moves the cursor in front of the suffix.
            //This timeout makes sure the target select runs after that
            selectOnFocus && e.target.select()
          }, 0)
          onFocus?.()
        }}
        hideControls
        onWheel={e => {
          if (disableWheel) return
          if (e.deltaY < 0) return incrementNumber()
          return decrementNumber()
        }}
        classNames={{
          ...inputProps.classNames,
          input: `${inputProps.leftSection ? '!pl-12' : ''} ${
            inputProps.rightSection ? '!pr-12' : ''
          }`,
        }}
        clampBehavior="strict"
        rightSection={
          !hideControls && (
            <div className="form-input__number-control">
              <button
                disabled={inputProps.disabled}
                onClick={e => {
                  e.preventDefault()
                  incrementNumber()
                }}
                aria-label="Increment number"
              >
                <Icon
                  name="caret-up"
                  size={13}
                />
              </button>
              <button
                disabled={inputProps.disabled}
                onClick={e => {
                  e.preventDefault()
                  decrementNumber()
                }}
                aria-label="Decrement number"
              >
                <Icon
                  name="caret-down"
                  size={13}
                />
              </button>
            </div>
          )
        }
        {...inputProps}
      />
    )
  },
)
