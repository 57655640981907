import { combine } from 'zustand/middleware'
import { create } from './create'
import { createSelectors } from './create-selectors'

import {
  fetchActivity,
  fetchActivityUser,
  fetchIterations,
  fetchProgressSections,
  fetchSections,
  saveActivity,
} from './state-api-fns'

interface ActivityState {
  activity: Activity | null
  sections: ActivitySection[]
  activityUser: ActivityUser
  progressSections: ProgressSection[]
  iterations: SectionIteration[]
  activitySaving: boolean
  navigationOpen: boolean
  fetchActivity: (activityId: string) => void
  fetchSections: (activity: Activity) => void
  fetchProgressSections: (sections: ActivitySection[], userId: string) => void
  fetchActivityUser: (activityId: string, userId: string) => void
  progressSectionsObs: any
  sectionsObs: any
  saveActivity: (data: {
    section: ActivitySection
    iteration: SectionIteration
    responses?: { [key: string]: FieldValue }
    forceComplete?: boolean
  }) => void
  fetchIterations: (
    sectionId: string,
    userId: string,
    activityId: string,
  ) => void
}

const useStoreBase = create<ActivityState>()(
  combine(
    {
      activity: null,
      activityUser: null,
      sections: [],
      progressSections: [],
      iterations: [],
      activitySaving: false,
      navigationOpen: false,
      progressSectionsObs: null,
      sectionsObs: null,
    },
    () => ({
      fetchActivity,
      fetchActivityUser,
      fetchSections,
      fetchProgressSections,
      fetchIterations,
      saveActivity,
    }),
  ),
)
export const useActivityStore = createSelectors(useStoreBase)
