import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { toastService } from '@utils/toast.service'
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  runTransaction,
  where,
} from 'firebase/firestore'
import { sortIterations, sortProgressSections } from '../activity-utils'
import {
  saveActivityUser,
  saveIteration,
  saveProgressSection,
  sendAnalyticsEvents,
  updateActivityUser,
  updateIteration,
  updateProgressSection,
} from './state-utils'
import { useActivityStore } from './use-activity-state'

export const fetchActivity = async (activityId: string) => {
  try {
    const docRef = doc(db, dbNames.activities, activityId)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      useActivityStore.setState({
        activity: { ...docSnap.data(), id: docSnap.id } as Activity,
      })
    }
  } catch (error) {
    console.error('Error fetching activity:', error)
  }
}

export const fetchActivityUser = async (activityId: string, userId: string) => {
  try {
    const q = query(
      collection(db, dbNames.activityUsers),
      where('activityId', '==', activityId),
      where('userId', '==', userId),
    )
    const activityUser = await getDocs(q).then(querySnapshot => {
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0]
        return { ...doc.data(), id: doc.id } as ActivityUser
      }
    })
    useActivityStore.setState({ activityUser })
  } catch (error) {
    console.error('Error fetching activity user:', error)
  }
}

export const fetchSections = async (activity: Activity) => {
  try {
    const d = query(
      collection(
        db,
        `${dbNames.workflowTemplates}/${activity.templateId}/${dbNames.templateSections}`,
      ),
      where(documentId(), '==', `${activity.version}`),
    )
    const sectionsObs = onSnapshot(d, snap => {
      if (!snap.empty) {
        const _sections = snap.docs[0].data().sections
        useActivityStore.setState(() => {
          return {
            sections: _sections,
          }
        })
        return _sections
      }
    })

    useActivityStore.setState({
      sectionsObs,
    })
  } catch (error) {
    console.error('Error fetching sections:', error)
  }
}

export const fetchProgressSections = async (
  sections: ActivitySection[],
  userId: string,
): Promise<any> => {
  try {
    const q = query(
      collection(db, dbNames.progressSections),
      where(
        'sectionId',
        'in',
        sections.map(s => s.id),
      ),
      where('userId', '==', userId),
    )

    const progressSectionsObs = onSnapshot(q, querySnapshot => {
      const _progressSections: ProgressSection[] = []
      querySnapshot.forEach(doc => {
        _progressSections.push({ ...doc.data(), id: doc.id } as ProgressSection)
      })

      useActivityStore.setState(state => {
        return {
          progressSections: sortProgressSections(
            _progressSections,
            state.sections.map(s => s.id),
          ),
        }
      })
    })

    useActivityStore.setState({
      progressSectionsObs,
    })
  } catch (error) {
    console.error('Error fetching progress sections:', error)
  }
}

export const fetchIterations = async (
  sectionId: string,
  userId: string,
  activityId: string,
) => {
  try {
    const q = query(
      collection(db, dbNames.sectionIterations),
      where('activityId', '==', activityId),
      where('sectionId', '==', sectionId),
      where('userId', '==', userId),
    )
    const iterations = await getDocs(q).then(querySnapshot => {
      const _iterations: SectionIteration[] = []
      querySnapshot.forEach(doc => {
        _iterations.push({
          id: doc.id,
          ...doc.data(),
        } as SectionIteration)
      })
      return _iterations
    })
    useActivityStore.setState(state => {
      const progressSection = state.progressSections.find(
        p => p.sectionId === sectionId,
      )
      const sortedIterations = sortIterations(
        iterations,
        progressSection.iterationOrderIds,
      )
      return {
        iterations: sortedIterations,
      }
    })
  } catch (error) {
    console.error('Error fetching iterations:', error)
  }
}

export const saveActivity = async ({
  section,
  iteration,
  responses,
  forceComplete,
}: {
  section: ActivitySection
  iteration: SectionIteration
  responses?: FieldResponses
  forceComplete?: boolean
}) => {
  const { activityUser, progressSections, iterations, activity, sections } =
    useActivityStore.getState()
  const today = getTodayDate().toISO()

  useActivityStore.setState({ activitySaving: true })

  const progressSection = progressSections.find(p => p.sectionId === section.id)

  const iterationDocRef = doc(db, dbNames.sectionIterations, iteration.id)
  const progressSectionDocRef = doc(
    db,
    dbNames.progressSections,
    progressSection.id,
  )
  const activityUserDocRef = doc(db, dbNames.activityUsers, activityUser.id)

  try {
    const { newIteration, iterationCompletedProgress } = updateIteration({
      iteration,
      responses,
      forceComplete,
      section,
      today,
    })

    const newIterations = iterations.map(i => {
      if (i.id === iteration.id) {
        return newIteration
      }
      return i
    })

    const { newProgressSection, sectionCompletedProgress } =
      updateProgressSection({
        progressSection,
        section,
        iteration: newIteration,
        activity,
        responses,
        iterationCompletedProgress,
        today,
      })

    const newProgressSections = progressSections.map(p => {
      if (p.id === progressSection.id) {
        return newProgressSection
      }
      return p
    })

    const { newActivityUser, activityCompletedProgress } = updateActivityUser({
      activityUser,
      progressSections: newProgressSections,
      activity,
      sections,
    })

    useActivityStore.setState({
      iterations: newIterations,
      progressSections: newProgressSections,
      activityUser: newActivityUser,
    })

    await runTransaction(db, async transaction => {
      saveIteration(transaction, iterationDocRef, newIteration)
      saveProgressSection(
        transaction,
        progressSectionDocRef,
        newProgressSection,
      )
      saveActivityUser(transaction, activityUserDocRef, newActivityUser)
    })

    await sendAnalyticsEvents({
      responses,
      section,
      iteration: newIteration,
      activity,
      user: useAppState.getState().user,
      sectionCompletedProgress,
      iterationCompletedProgress,
      activityCompletedProgress,
      today,
    })

    useActivityStore.setState({ activitySaving: false })
  } catch (error) {
    useActivityStore.setState({ activitySaving: false })
    console.log(error)
    toastService.info(
      'There was an error saving your response. Please try again.',
    )
  }
}
