import { k, useCss } from 'kremling'
import { DateTime } from 'luxon'
import React, { useMemo } from 'react'

import { CardProps } from '@components/card.component'
import { Icon } from '@components/icon.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { doc, getDoc } from 'firebase/firestore'
import { Link } from 'react-router-dom'

type Props = {
  activity: Activity
  userId: string
  subTitle?: string
  className?: string
  href: string
} & CardProps

export function CourseProgressCard({ activity, userId, href }: Props) {
  const scoped = useCss(css)
  const systemDate = useAppState(state => state.systemDate)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const today = useMemo(() => getTodayDate(), [systemDate])

  const [activityUser] = useLoad(
    {},
    () => {
      const d = doc(db, dbNames.activityUsers, `${activity.id}_${userId}`)
      return getDoc(d).then(snap => ({ ...snap.data(), id: snap.id }))
    },
    [activity.id, userId],
  )

  const convertedStartDate = useMemo(
    () =>
      DateTime.fromISO(activity.startDate.date, {
        zone: activity.startDate.timeZone,
      }).setZone(timeZone),
    [],
  )

  const isBeforeStartDate =
    convertedStartDate.toUTC().toISO() > today.toUTC().toISO()

  function renderStatus() {
    if (isBeforeStartDate) {
      return (
        <div className="text-white text-3xl">
          Starts on {convertedStartDate.toFormat('LLL dd')}
        </div>
      )
    }

    if (activityUser?.completedPercentage === 100) {
      return (
        <div className="text-white text-3xl">
          {!activityUser.completedDate
            ? 'Completed'
            : 'Completed on ' +
              DateTime.fromISO(activityUser.completedDate).toFormat('LLL dd')}
        </div>
      )
    }

    return <div className="text-white text-3xl">Current activity</div>
  }

  function renderUnlockDate() {
    if (!activityUser.unlockDate) return null
    const convertedUnlockDate = DateTime.fromMillis(activityUser.unlockDate)

    if (convertedUnlockDate.toUTC().toISO() > today.toUTC().toISO()) {
      return convertedUnlockDate.toFormat('LLL dd')
    }
    return null
  }

  const unlockDate = useMemo(renderUnlockDate, [activityUser])

  return (
    <div
      {...scoped}
      className="w-full rounded-lg overflow-hidden"
    >
      <div className="p-10 bg-gradient-to-r from-stats-three to-stats-four space-y-10">
        <div className="uppercase">{renderStatus()}</div>
        <div className="text-white text-3xl font-bold">{activity.name}</div>
      </div>
      <div className="bg-active-75 py-6 px-10 flex items-center justify-between">
        <div className="flex items-center gap-14">
          {isBeforeStartDate && (
            <div className="text-2xl">
              <div>Start Date</div>
              <div className="font-bold">
                {convertedStartDate.toFormat('LLL dd')}
              </div>
            </div>
          )}
          <div className="text-2xl">
            <div>Progress</div>
            <div className="font-bold">{activityUser.completedPercentage}%</div>
          </div>
          {unlockDate && (
            <div className="text-2xl">
              <div>Next section</div>
              <div className="flex items-center text-2xl font-bold gap-2">
                <span className="text-grey-400">
                  <Icon
                    name="lock"
                    size={15}
                  />
                </span>
                {unlockDate}
              </div>
            </div>
          )}
        </div>
        <div>
          <Link
            className="px-4 py-3 bg-primary text-white rounded-lg font-semibold text-2xl"
            to={href}
          >
            Open
          </Link>
        </div>
      </div>
    </div>
  )
}

const css = k`
  .workflow-card__inner {
    flex-basis: 100%;
  }

  .client-workflow-progress {
    display: flex;
    align-items: center;
    justify-content: stretch;
    min-width: 260px;
  }

  .progress-link {
    padding: 12px;
    color: $color-primary;
    margin-right: 8px;
    display: flex;
    align-items: center;
  }

  .completed-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`
