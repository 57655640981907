import { Button } from '@components/mantine/button.component'
import { auth } from '@src/firebase-app'
import { getErrorMessage } from '@utils/form.utils'
import { toastService } from '@utils/toast.service'
import { updatePassword } from 'firebase/auth'
import React from 'react'
import { useForm } from 'react-hook-form'

export function NewPasswordForm() {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      newPassword: '',
      verifyNewPassword: '',
    },
  })

  function handleUpdatePassword({ newPassword }: { newPassword: string }) {
    updatePassword(auth.currentUser, newPassword)
      .then(() => {
        toastService.info('Password successfully updated')
        reset()
      })
      .catch(() => {
        toastService.error(
          'A problem occurred when updating your password. Please try again.',
        )
      })
  }

  return (
    <form onSubmit={handleSubmit(handleUpdatePassword)}>
      <h3>Update Password</h3>
      <div className="row break-1">
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="newPassword">New password</label>
            <input
              type="password"
              className="form-input"
              id="newPassword"
              placeholder="Type here..."
              {...register('newPassword', {
                minLength: {
                  value: 6,
                  message: 'Password should be at least 6 characters',
                },
              })}
            />
            <div className="mt-4 has-warning">
              {getErrorMessage(errors.newPassword)}
            </div>
          </div>
        </div>
      </div>
      <div className="row break-1">
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="verifyNewPassword">Verify new password</label>
            <input
              type="password"
              id="verifyNewPassword"
              className="form-input"
              placeholder={'type here...'}
              {...register('verifyNewPassword', {
                minLength: {
                  value: 6,
                  message: 'Password should be at least 6 characters',
                },
              })}
            />
            <div className="mt-4 has-warning">
              {getErrorMessage(errors.verifyNewPassword)}
            </div>
          </div>
        </div>
      </div>
      <Button
        type="submit"
        variant="secondary"
        loading={isSubmitting}
        disabled={!!errors.newPassword || !!errors.verifyNewPassword}
      >
        Submit
      </Button>
    </form>
  )
}
