import { k, useCss } from 'kremling'
import React from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'

import { Tabs } from '@components/tabs.component'

export function Admin() {
  const scope = useCss(css)
  const location = useLocation()
  const params = useParams()
  const isTemplatePage =
    location.pathname.includes('templates') &&
    location.pathname.includes(params.templateId)

  return (
    <div
      {...scope}
      className="iact-container --sm"
    >
      {!isTemplatePage && (
        <div className="tabs-container">
          <Tabs>
            <Tabs.Link to="users">Users</Tabs.Link>
            <Tabs.Link to="groups">Groups</Tabs.Link>
            <Tabs.Link to="settings">Settings</Tabs.Link>
          </Tabs>
        </div>
      )}
      <Outlet />
    </div>
  )
}

const css = k`
  .tabs-container {
    padding-top: 3.2rem;
    display: flex;
    justify-content: center;
  }
`
