import { k, useCss } from 'kremling'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'

import { TextInput } from '@components/mantine/text-input.component'
import { categories } from '@src/pages/workflow/workflow-utils'
import { searchTypesense } from '@typesense-config'
import { TemplateSearchList } from './template-search-list.component'

const sortOptions = [
  {
    name: 'Most popular',
    id: 'popular',
    sortField: 'adoptionsCount',
    sortDirection: 'asc',
  },
  {
    name: 'By date (newest first)',
    id: 'newest',
    sortField: 'lastPublishedDate',
    sortDirection: 'desc',
  },
  {
    name: 'By date (oldest first)',
    id: 'oldest',
    sortField: 'lastPublishedDate',
    sortDirection: 'asc',
  },
]

export function Marketplace() {
  const scoped = useCss(css)
  const [searchVal, setSearchVal] = useState('')
  const [marketplaceTemplates, setMarketplaceTemplates] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('all')
  const [selectedSort, setSelectedSort] = useState(sortOptions[0])
  const [displayCategories, setDisplayCategories] = useState([])

  useEffect(() => {
    getMarketplaceTemplates()
  }, [selectedCategory, selectedSort])

  function handleSearch(text) {
    setSearchVal(text)
    const queryCategory =
      selectedCategory === 'all' ? '' : `&& categories:=${selectedCategory}`
    const searchParameters = {
      q: text,
      query_by: 'name,description',
      query_by_weights: '1,2',
      filter_by: `${queryCategory}`,
    }
    searchTypesense('marketplaceTemplates', searchParameters).then(
      ({ hits }) => {
        setMarketplaceTemplates(hits.map(hit => hit.document))
      },
    )
  }

  function getMarketplaceTemplates() {
    const queryCategory =
      selectedCategory === 'all' ? '' : `&& categories:=${selectedCategory}`
    const searchRequest = {
      q: '*',
      filter_by: `${queryCategory}`,
      facet_by: 'categories',
      sort_by: `${selectedSort.sortField}:${selectedSort.sortDirection}`,
    }
    searchTypesense('marketplaceTemplates', searchRequest).then(
      ({ hits, facet_counts }) => {
        if (isEmpty(displayCategories)) {
          const { counts } = facet_counts.find(
            ({ field_name }) => field_name === 'categories',
          )
          const _displayCategories = counts.map(({ value }) =>
            categories.find(({ id }) => id === value),
          )
          setDisplayCategories(_displayCategories)
        }
        setMarketplaceTemplates(hits.map(hit => hit.document))
      },
    )
  }

  // const [featuredTemplates, _setFeaturedTemplates, _featuredTemplatesOpts] = useLoad([], async () => {
  //   const featuredTemplateIds = (await getDoc(
  //     doc(db, dbNames.curatedTemplates, 'marketplace-featured')
  //   )).get('featuredList')
  //   if(!featuredTemplateIds) return Promise.resolve([])
  //   return await searchTypesense('marketplaceTemplates', {
  //     q: '*',
  //     filter_by: `id: [${featuredTemplateIds}]`,
  //   }).then(({hits}) => hits.map(({document}) => document))
  // }, [])

  return (
    <div
      className="marketplace-wrapper"
      {...scoped}
    >
      <div className="hero__container">
        <div className="--lg hero__content iact-container">
          <h1 className="title">Workflow Marketplace</h1>
          <p className="subtitle">
            Find the perfect workflow to help you level up
          </p>
          <div>
            <TextInput
              value={searchVal}
              onChange={handleSearch}
              placeholder="Search marketplace"
              icon="search"
              allowClear
            />
          </div>
        </div>
      </div>
      <div className="search__container iact-container --lg">
        {/*{*/}
        {/*  !isEmpty(featuredTemplates) &&*/}
        {/*    <Well className="mb-36">*/}
        {/*      <Well.Header>*/}
        {/*        <div className="featured__header">*/}
        {/*          <Icon name="gem" className="mr-12"/>*/}
        {/*          Featured workflows*/}
        {/*        </div>*/}
        {/*      </Well.Header>*/}
        {/*      <Well.Body>*/}
        {/*        <div className="featured__list">*/}
        {/*          {*/}
        {/*            featuredTemplates.map(featuredTemplate => (*/}
        {/*              <MarketplaceTemplateCard*/}
        {/*                className="featured-template__card"*/}
        {/*                key={featuredTemplate.id}*/}
        {/*                template={featuredTemplate}*/}
        {/*              />*/}
        {/*            ))*/}
        {/*          }*/}
        {/*        </div>*/}
        {/*      </Well.Body>*/}
        {/*    </Well>*/}
        {/*}*/}
        <TemplateSearchList
          searchVal={searchVal}
          templates={marketplaceTemplates}
          sortOptions={sortOptions}
          selectedCategory={selectedCategory}
          onCategoryChange={setSelectedCategory}
          categories={displayCategories}
          selectedSort={selectedSort}
          onSortChange={setSelectedSort}
        />
      </div>
    </div>
  )
}

const css = k`
  .hero__container {
    padding: 3rem 0;
    background: linear-gradient(94.42deg, #3151BE 24.59%, #BE51B2 111.8%);
  }

  .hero__content {
    color: #fff;
    padding-bottom: 0;
  }

  .hero__content.iact-container.--lg {
    padding-bottom: 0;
  }

  .title {
    line-height: 6rem;
    max-width: 380px;
    margin-bottom: 1.8rem;
    font-size: 4.9rem;
    font-weight: 700;
    display: flex;
    align-items: center;
  }

  .subtitle {
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 2.4rem;
  }

  .featured__header {
    color: $color-primary;
  }

  .featured__list {
    padding: 4px;
    margin: -4px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 12px;
    overflow: auto;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }

  .featured__list::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }

  .featured-template__card {
    min-width: 175px;
  }

  .search__container.iact-container.--lg {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`
