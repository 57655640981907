import { nonCompletableFields } from '@src/pages/workflow/fields.helper'
import { normalizePercentage } from '@utils/helpers'
import {
  isArray,
  isBoolean,
  isEmpty,
  isNull,
  isNumber,
  isObject,
  isString,
  isUndefined,
} from 'lodash'
import { useEffect, useState } from 'react'

type HookProps = {
  sections: unknown[]
  selectedSectionId: string
  responses: {
    [sectionId: string]: {
      [fieldId: string]: any
    }
  }
}

type CompletedMap = {
  [sectionId: string]: number
}

type ActivitySection = {
  sectionId: string
  fields: unknown[]
  constraints: SectionConstraints
}

const isValidResponse = (fieldResponse: { value: unknown; type: string }) => {
  const { value } = fieldResponse
  if (isNull(value) || isUndefined(value)) return false
  if (isArray(value) || isString(value) || isObject(value))
    return !isEmpty(value)
  return !!(isBoolean(value) || isNumber(value))
}

const removeNonEditableFields = (fieldResponse: {
  value: unknown
  type: string
}) => {
  return !nonCompletableFields.includes(fieldResponse.type)
}

function getTotalRequiredFieldsForSection(section: ActivitySection) {
  const { required, requiredMode } = section.constraints
  const editableFields = section.fields.filter(removeNonEditableFields)

  if (requiredMode === 'all') {
    return editableFields.length
  } else if (requiredMode === 'any') {
    return Number(required)
  } else if (requiredMode === 'custom') {
    return (required as string[]).length
  } else if (requiredMode === 'none') {
    return 0
  }
}

export function useSectionCompletedStatus({
  sections,
  responses,
  selectedSectionId,
}: HookProps) {
  const [completedMap, setCompletedMap] = useState({})

  useEffect(() => {
    const newCompletedMap = sections.reduce<CompletedMap>(
      (acc: CompletedMap, section: ActivitySection) => {
        const completedFields = Object.values(
          responses[section.sectionId] || {},
        ).filter(isValidResponse)

        const totalFields = getTotalRequiredFieldsForSection(section)
        const totalCompletedFields = completedFields.length
        const percentComplete = (totalCompletedFields / totalFields) * 100 || 0
        acc[section.sectionId] = normalizePercentage(percentComplete)
        return acc
      },
      {},
    )

    setCompletedMap(prevState => ({
      ...prevState,
      ...newCompletedMap,
    }))
  }, [sections, responses, selectedSectionId])

  return {
    completedMap,
  }
}
