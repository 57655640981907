import {
  addDoc,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore'
import { k, useCss } from 'kremling'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { TemplateCard } from '@components/cards/template-card.component'
import { EmptyState } from '@components/empty-state.component'
import { LayoutHeader } from '@components/layout.component'
import { Loader } from '@components/loader.component'
import { Button } from '@components/mantine/button.component'
import { TextInput } from '@components/mantine/text-input.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { toastService } from '@utils/toast.service'

export function WorkflowTemplates() {
  const scoped = useCss(css)
  const navigate = useNavigate()
  const [searchVal, setSearchVal] = useState('')
  const me = useAppState(state => state.user)
  const { orgId } = useParams()

  const [
    workflowTemplates,
    _setWorkflowTemplates,
    { loading, reload: reloadTemplates },
  ] = useLoad(
    [],
    () => {
      const q = query(orderBy('name'), where('orgId', '==', orgId))
      return getDocs(q).then(q => q.docs.map(d => ({ ...d.data(), id: d.id })))
    },
    [],
  )

  async function addTemplate() {
    const enableTemplateGenerator = process.env.OPENAI_TEMPLATES
    const date = getTodayDate().toISO()

    if (enableTemplateGenerator) {
      try {
        const templateSnapshot = await addDoc(
          collection(db, dbNames.workflowTemplates),
          {
            name: '',
            sections: [],
            orgId,
            assistant: true,
            currentVersionEditorIds: [me.id],
            editorIds: [me.id],
            authorId: me.id,
            createdDate: date,
            lastEditedDate: date,
            lastEditedById: me.id,
          },
        )

        const d = doc(db, dbNames.assistantConversations, templateSnapshot.id)
        await setDoc(d, {
          name,
          orgId,
          lastStage: 'empathize',
          createdDate: date,
          messageHistory: [],
          fields: {},
          selectedItems: {},
          lists: {},
          rawResponses: {},
        })

        navigate(`template-generator/${templateSnapshot.id}`)
      } catch (err) {
        toastService.error(
          'There was an error creating a new template. Please try again.',
        )
      }
    } else {
      const date = getTodayDate().toISO()
      addDoc(collection(db, dbNames.workflowTemplates), {
        name: '',
        sections: [],
        orgId,
        currentVersionEditorIds: [me.id],
        editorIds: [me.id],
        authorId: me.id,
        createdDate: date,
        lastEditedDate: date,
        lastEditedById: me.id,
      })
        .then(template => {
          toastService.info('New workflow template created')
          navigate(template.id)
        })
        .catch(e => toastService.error(e.message))
    }
  }

  function createDuplicateTemplate(template) {
    const date = getTodayDate().toISO()
    const duplicatedTemplatePayload = {
      name: `${template.name} (copy)`,
      sections: template.sections,
      orgId,
      createdDate: date,
      lastEditedDate: date,
      lastEditedById: me.id,
      currentVersionEditorIds: [me.id],
      editorIds: [me.id],
      authorId: me.id,
    }

    addDoc(
      collection(db, dbNames.workflowTemplates),
      duplicatedTemplatePayload,
    ).then(() => {
      toastService.info('Template duplicated successfully')
      reloadTemplates()
    })
  }

  function renderMyTemplates() {
    const filteredTemplates = workflowTemplates.filter(template => {
      return searchVal
        ? template.name.toLowerCase().includes(searchVal.toLowerCase())
        : true
    })

    if (filteredTemplates.length) {
      return filteredTemplates.map(template => (
        <TemplateCard
          template={template}
          createDuplicateTemplate={createDuplicateTemplate}
          key={template.id}
        />
      ))
    } else {
      if (searchVal) {
        return (
          <EmptyState
            action={addTemplate}
            actionText="Add Template"
            title="No search results"
            subtitle={`"${searchVal}" yielded no results. Can't find what you're looking for? Add a new template below.`}
          />
        )
      } else {
        return (
          <EmptyState
            action={addTemplate}
            actionText="New Template"
            title="No templates"
            subtitle={<span>Create new template to get started</span>}
          />
        )
      }
    }
  }

  if (loading)
    return (
      <div className="pt-8">
        <Loader />
      </div>
    )

  return (
    <div
      {...scoped}
      className="iact-container --sm"
    >
      <LayoutHeader
        className="pt-8"
        title="Templates"
        search={
          <TextInput
            defaultValue={searchVal}
            onChange={setSearchVal}
            placeholder="Search templates..."
            icon="search"
            allowClear
          />
        }
        actions={
          <Button
            variant="secondary"
            onClick={addTemplate}
          >
            New Template
          </Button>
        }
      />
      {renderMyTemplates()}
    </div>
  )
}

const css = k`
  .workflow-template-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem;
    margin-bottom: 1.6rem;
    font-weight: 500;
  }

  .template-name {
    display: flex;
    align-items: center;
  }
`
