import { logEvent as firebaseLogEvent, getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { GoogleAuthProvider, connectAuthEmulator, getAuth } from 'firebase/auth'
import firebase from 'firebase/compat'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import Analytics = firebase.analytics.Analytics

export const isDevelopment = process.env.NODE_ENV !== 'production'
export const isStage = process.env.FIREBASE_PROJECT_ID === 'iact-stage'
export const analyticsUrl = process.env.USER_ANALYTICS_URL

let firebaseConfig
if (isDevelopment) {
  firebaseConfig = {
    apiKey: 'fake-api-key',
    databaseURL: 'http://localhost:3004',
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    storageBucket: 'gs://default-bucket/',
    projectId: 'dev-local',
    appId: 'fake-id',
  }
} else {
  firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID,
  }
}

export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const functions = getFunctions(app)
export const storage = getStorage(app)
export const googleAuthProvider = new GoogleAuthProvider()
const analytics = (isDevelopment ? {} : getAnalytics(app)) as Analytics
export const logEvent = (event: any) => {
  if (!isDevelopment) {
    firebaseLogEvent(analytics, event)
  }
}

if (isDevelopment) {
  connectAuthEmulator(getAuth(), 'http://localhost:3002')
  connectFunctionsEmulator(getFunctions(), 'localhost', 3003)
  connectFirestoreEmulator(getFirestore(), 'localhost', 3004)
  connectStorageEmulator(getStorage(), 'localhost', 3006)
}
