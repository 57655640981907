import React, { forwardRef, Fragment } from 'react'

import { clearSelectionId } from './common/utils'
import { ItemClear } from './items/item-clear.component'

export const CpSelectContent = forwardRef(function CpSelectContent(props, ref) {
  const {
    data,
    disabled,
    isGroupData,
    isMulti,
    onChange,
    renderGroup,
    renderItem,
    searchOnChange,
    searchValue,
    selectedId,
    selectItem,
    showResultsInContent,
    valueTransformed,
    clearable,
  } = props

  function buildItem(item) {
    const Item = renderItem
    const itemProps = {
      disabled,
      isSelected: item.id.toString() === selectedId,
      item,
      key: item.id,
      onChange,
      searchOnChange,
      searchValue,
      selectItem,
      value: valueTransformed,
    }
    if (item.id === clearSelectionId) {
      return <ItemClear {...itemProps} />
    }
    return <Item {...itemProps} />
  }

  const hasData = !!data.length
  let hasValue, showEmptyState, showSelected, shouldShowClearRow
  if (isMulti) {
    hasValue = valueTransformed.length
    showEmptyState =
      !hasData &&
      (searchValue || !valueTransformed.length || !showResultsInContent)
    showSelected = !hasData && !!valueTransformed.length && showResultsInContent
  } else {
    hasValue = !!valueTransformed
    shouldShowClearRow = hasValue && clearable
    showEmptyState = !hasData && !shouldShowClearRow
  }
  return (
    <>
      {!showEmptyState ? (
        <div
          className="select-component__content-items"
          ref={ref}
        >
          {!!(hasValue || hasData) && (
            <>
              {shouldShowClearRow &&
                buildItem({ id: clearSelectionId, name: 'Clear selection' })}
              {showSelected &&
                valueTransformed.map(item => buildItem(item, data))}
              {isGroupData
                ? data.map(group => {
                    const RenderGroup = renderGroup
                    return (
                      <Fragment key={group.id}>
                        <RenderGroup
                          {...group}
                          count={group.data.length}
                        />
                        {group.data.map(buildItem)}
                      </Fragment>
                    )
                  })
                : data.map(buildItem)}
            </>
          )}
        </div>
      ) : (
        <div className="select-component__empty">
          <div className="select-component__empty-text">No Results</div>
          Please refine your search
        </div>
      )}
    </>
  )
})
