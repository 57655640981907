import { create } from 'zustand'

type MessageState = {
  addingNewRoom: boolean
  selectedRoom: MessageRoom
  messageRoomUsers: MessageRoomUser[]
  newRoomSelectedUsers: MessageRoomUser[]
  adminRooms: MessageRoom[]
}

export const useMessagingState = create<MessageState>()(() => ({
  addingNewRoom: false,
  selectedRoom: null,
  messageRoomUsers: [],
  newRoomSelectedUsers: [],
  adminRooms: [],
}))
