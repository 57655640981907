import { useActivityStore } from '@src/pages/workflow/client/workflow-refactor/state/use-activity-state'
import {
  FIELD_CONSTANTS,
  completableFields,
  nonCompletableFields,
} from '@src/pages/workflow/fields.helper'
import { normalizePercentage } from '@utils/helpers'
import { isBoolean, isEmpty, isEqual, isNil, isNumber, maxBy } from 'lodash'
import { DateTime } from 'luxon'
import { RRule } from 'rrule'

export function findLastEditedSection(
  sectionList: ProgressSection[],
): ProgressSection {
  return maxBy(sectionList, ({ lastEditedDate }) => {
    return lastEditedDate ? DateTime.fromISO(lastEditedDate).toMillis() : 0
  })
}

export function sortSections(
  sections: ActivitySection[],
  sectionOrderIds: string[],
): ActivitySection[] {
  if (sectionOrderIds.length && sections?.length) {
    return sectionOrderIds.map(sectionOrderId =>
      sections?.find(c => c.id === sectionOrderId),
    )
  }
  return sections
}

export function sortProgressSections(
  sections: ProgressSection[],
  sectionOrderIds: string[],
): ProgressSection[] {
  if (sectionOrderIds.length && sections?.length) {
    return sectionOrderIds
      .map(sectionOrderId =>
        sections?.find(c => c.sectionId === sectionOrderId),
      )
      .filter(Boolean)
  }
  return sections
}

export function sortIterations(
  iterations: SectionIteration[],
  iterationOrderIds: string[],
): SectionIteration[] {
  if (iterationOrderIds.length && iterations?.length) {
    return iterationOrderIds
      .map(id => iterations?.find(c => c.id === id))
      .filter(Boolean)
  }
  return iterations
}

export function getRequiredFieldsCount(section: ActivitySection) {
  const { requiredMode, required, anyRequiredCount } = section.constraints
  return requiredMode === 'any' ? anyRequiredCount : required.length
}

export function findNextIncompleteIteration(
  iterations: SectionIteration[],
  progressSection: ProgressSection,
): SectionIteration {
  if (!progressSection) return
  for (const iteration of iterations) {
    if (progressSection.completedIterationMap.hasOwnProperty(iteration.id)) {
      if (progressSection.completedIterationMap[iteration.id] < 100) {
        return iteration
      }
    } else {
      return iteration
    }
  }

  return null
}

export function determineNextIteration(
  iterations: SectionIteration[],
  sectionId: string,
) {
  if (iterations.length === 1) {
    return iterations[0]
  }

  const progressSection = useActivityStore
    .getState()
    .progressSections.find(s => s.id === sectionId)

  const nextPartiallyCompletedIteration = findNextIncompleteIteration(
    iterations,
    progressSection,
  )

  if (nextPartiallyCompletedIteration) {
    return nextPartiallyCompletedIteration
  }

  if (iterations.length > 0) {
    return iterations[iterations.length - 1]
  }

  return null
}

export function hasValue(value: unknown) {
  if (isBoolean(value)) return value
  if (isNumber(value)) return !isNil(value)
  return !isEmpty(value)
}

export const findChangedField = (
  currentValues: FieldResponses,
  previousValues: FieldResponses,
) => {
  for (const key in currentValues) {
    if (!isEqual(currentValues[key], previousValues[key])) {
      return key
    }
  }
  return null
}

export const getFieldDefaults = (
  section: ActivitySection,
  fieldResponses: FieldResponses,
) => {
  if (!section) return {}
  return section.fields.reduce((acc: FieldResponses, field) => {
    const responseFieldId = `${field.version}_${field.id}`
    if (fieldResponses[responseFieldId]) {
      acc[responseFieldId] = fieldResponses[responseFieldId]
      return acc
    }
    acc[responseFieldId] = completableFields.find(
      ({ name }) => name === field.type,
    )?.default
    return acc
  }, {})
}

export const calcIterationCompletionPercentage = (
  section: ActivitySection,
  fieldResponses: FieldResponses,
) => {
  const { requiredMode, required, anyRequiredCount } = section.constraints
  const fields = section.fields.filter(field => {
    return (
      nonCompletableFields.indexOf(field.type) === -1 &&
      required.includes(field.id)
    )
  })

  const fieldLength: number =
    requiredMode === 'any' ? anyRequiredCount : required.length

  return normalizePercentage(
    fields.reduce((total, field) => {
      const fieldResponse = fieldResponses[`${field.version}_${field.id}`]
      let fieldHasResponse = hasValue(fieldResponse)

      if (fieldHasResponse && Array.isArray(fieldResponse)) {
        fieldHasResponse = fieldResponse.length > 0
      }
      if (fieldLength) {
        return total + (fieldHasResponse ? 100 / fieldLength : 0)
      }
      return total + (fieldHasResponse ? 100 : 0)
    }, 0),
  )
}

export const calcSectionCompletionPercentage = (
  completedIterationMap: { [key: string]: number },
  totalIterationCount: number,
) => {
  let totalCompletion = 0

  for (const percentage of Object.values(completedIterationMap)) {
    totalCompletion += percentage
  }

  const averageCompletion = totalCompletion / totalIterationCount

  return normalizePercentage(averageCompletion)
}

export const getTotalIterationCount = (
  constraints: SectionConstraints,
  startDate: FutureDate,
  endDate: FutureDate,
): number => {
  if (!constraints) return 1
  let total = 1

  if (constraints.recurrence && startDate && endDate) {
    const options = RRule.parseString(constraints.recurrence)

    options.dtstart = DateTime.fromISO(startDate.date, {
      zone: startDate.timeZone,
    })
      .startOf('day')
      .toJSDate()
    options.until = DateTime.fromISO(endDate.date, {
      zone: endDate.timeZone,
    })
      .endOf('day')
      .toJSDate()

    const rule = new RRule(options)
    const allIterations = rule.all()

    total = allIterations.length
  }
  return total
}

export const getTotalActivityIterationsCount = (
  activity: Activity,
  sections: ActivitySection[],
): number => {
  return sections.reduce((accum, section) => {
    const { recurrence } = section.constraints
    if (recurrence) {
      const origOptions = RRule.fromString(recurrence).origOptions
      const newRule = new RRule({
        ...origOptions,
        dtstart: DateTime.fromISO(activity.startDate.date, {
          zone: activity.startDate.timeZone,
        }).toJSDate(),
        until: DateTime.fromISO(activity.endDate.date, {
          zone: activity.endDate.timeZone,
        }).toJSDate(),
      })
      return accum + newRule.all().length
    }
    return accum + 1
  }, 0)
}

export const getTotalIterationsCompletedCount = (
  progressSections: ProgressSection[],
): number => {
  return progressSections.reduce(
    (acc: number, nextSection: ProgressSection) => {
      return (
        acc +
        Object.values(nextSection.completedIterationMap).filter(
          value => value === 100,
        ).length
      )
    },
    0,
  )
}

export const getActivityCompletionPercentage = (
  progressSections: ProgressSection[],
  activity: Activity,
  activitySections: ActivitySection[],
): number => {
  const totalIterationsCompleted =
    getTotalIterationsCompletedCount(progressSections)
  const totalActivityIterationsCount = getTotalActivityIterationsCount(
    activity,
    activitySections,
  )
  const percentageCompleted =
    (totalIterationsCompleted / totalActivityIterationsCount) * 100

  return normalizePercentage(percentageCompleted)
}

type ExtractedFieldValues = {
  arrayValue?: string[]
  stringValue?: string | null
  actionCompleted?: boolean | null
  unitValue?: string | null
}

export function extractFieldValue(
  field: Field,
  value: FieldValue,
): ExtractedFieldValues {
  let arrayValue: string[] | undefined
  let stringValue: string | null = typeof value === 'string' ? value : null
  let actionCompleted: boolean | null = null
  let unitValue: string | null = null

  if (field.type === FIELD_CONSTANTS.FILE_UPLOAD) {
    stringValue = null
    actionCompleted = Array.isArray(value) && value.length > 0
  } else if (
    field.type === FIELD_CONSTANTS.LINK ||
    field.type === FIELD_CONSTANTS.YOUTUBE
  ) {
    stringValue = null
    actionCompleted = typeof value === 'boolean' ? value : null
  } else if (
    field.textType === 'distance' &&
    typeof value === 'object' &&
    value !== null &&
    'value' in value
  ) {
    stringValue = value.value
    unitValue = value.unit
  } else if (Array.isArray(value)) {
    arrayValue = value
    stringValue = null
  }

  return { arrayValue, stringValue, actionCompleted, unitValue }
}
