import { $generateHtmlFromNodes } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import dompurify from 'dompurify'
import {
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_HIGH,
  KEY_ENTER_COMMAND,
} from 'lexical'
import { useCallback, useEffect } from 'react'

type Props = {
  onSubmit: (value: string) => void
}

export const SubmitPlugin = ({ onSubmit }: Props): null => {
  const [editor] = useLexicalComposerContext()

  const handleSubmit = useCallback(() => {
    onSubmit(
      dompurify.sanitize($generateHtmlFromNodes(editor), {
        ALLOWED_ATTR: ['style'],
      }),
    )

    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
  }, [editor])

  const onEnter = useCallback(
    (event: KeyboardEvent) => {
      const { ctrlKey, metaKey } = event

      if (ctrlKey || metaKey) {
        event.preventDefault()
        handleSubmit()
      }
      return true
    },
    [editor],
  )

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(KEY_ENTER_COMMAND, onEnter, COMMAND_PRIORITY_HIGH),
    )
  }, [editor, onEnter])

  return null
}
