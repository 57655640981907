import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { Checkbox } from '@components/mantine/checkbox.component'
import { FieldHeaderContent } from '@src/pages/workflow/client/fields/field-header-content.component'
import { AssistedResponseModal } from '@src/pages/workflow/client/workflow-refactor/assisted-response-modal.component'
import { modalService } from '@utils/modal.service'
import { array, bool, func, object } from 'prop-types'
import React from 'react'
import { useController } from 'react-hook-form'

export function WorkflowCheckbox({
  field,
  readOnly,
  isRequired,
  control,
  section,
}) {
  const {
    field: { onChange, value, ...fieldProps },
    fieldState: { error },
  } = useController({
    disabled: readOnly,
    name: `${field.version}_${field.id}`,
    control,
    rules: {
      required: isRequired && 'This field is required',
    },
  })

  if (!field.choices.filter(({ label }) => label).length) {
    return (
      <div className="flex flex-column">
        <FieldHeaderContent
          field={field}
          isRequired={isRequired}
          section={section}
          actions={
            <>
              <ActionIcon
                size="sm"
                onClick={() => {
                  modalService
                    .render(AssistedResponseModal, { field, section })
                    .then(({ response }) => {
                      const responses = field.choices.reduce(
                        (acc, { id, label }) => {
                          const has = response.includes(label)
                          if (has) return [...acc, id]
                        },
                        [],
                      )
                      onChange(responses)
                    })
                }}
                icon="wand-magic-sparkles"
              />
            </>
          }
        />
        <div className="text-danger flex items-center">
          <Icon
            name="circle-exclamation"
            className="mr-2"
          />{' '}
          No Checkbox choices have been added. This field and message will not
          display to users.
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <FieldHeaderContent
        field={field}
        isRequired={isRequired}
        section={section}
        actions={
          <>
            <ActionIcon
              size="sm"
              onClick={() => {
                modalService
                  .render(AssistedResponseModal, { field, section })
                  .then(({ response }) => {
                    const responses = field.choices.reduce(
                      (acc, { id, label }) => {
                        const has = response.includes(label)
                        if (has) return [...acc, id]
                        return acc
                      },
                      [],
                    )
                    onChange(responses)
                  })
              }}
              icon="wand-magic-sparkles"
            />
          </>
        }
      />
      <Checkbox.Group
        value={value}
        onChange={onChange}
        required={isRequired}
        error={error?.message}
      >
        {field.choices.map(choice => (
          <Checkbox
            className="my-1"
            key={choice.id}
            value={choice.id}
            label={choice.label}
            {...fieldProps}
          />
        ))}
      </Checkbox.Group>
    </div>
  )
}

WorkflowCheckbox.propTypes = {
  field: object,
  onChange: func,
  fieldVal: array,
  readyOnly: bool,
}
