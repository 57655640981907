import { DatePicker } from '@components/date-picker.component'
import { DurationInput } from '@components/duration-input.component'
import { Button } from '@components/mantine/button.component'
import { NumberInput } from '@components/mantine/number-input.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Modal } from '@components/modal.component'
import { Required } from '@components/required.component'
import { SelectSingle } from '@components/select-single.component'
import { useLoad } from '@hooks/use-load.hook'
import { db } from '@src/firebase-app'
import {
  isNumericField,
  lengthOptions,
  TEXT_TYPE_CONSTANTS,
} from '@src/pages/workflow/fields.helper'
import { rruleGetBetween } from '@src/pages/workflow/workflow-utils'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { toastService } from '@utils/toast.service'
import {
  arrayUnion,
  collection,
  doc,
  DocumentData,
  getDocs,
  query,
  QuerySnapshot,
  runTransaction,
  updateDoc,
  where,
} from 'firebase/firestore'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { RRule } from 'rrule'

type Props = {
  onResolve: () => void
  onReject: () => void
  activity: Activity
  challenge: WorkflowChallenge
  orgId: string
}

type FormValues = {
  section: {
    fields: { label: string; id: string; textType: string; dataLabel: string }[]
    id?: string
    name?: string
    constraints?: SectionConstraints
  }
  method: {
    name: string
    id: string
    type: string
  }
  dateRange: DateTime[]
  challengeTitle: string
  challengeGoal: string
  selectedField: {
    label: string
    id: string
    textType: string
    dataLabel: string
  }
  methodSortOrder: {
    name: string
    id: string
  }
  goalUnit: {
    name: string
    id: string
  }
}

const methodOptions = [
  { name: 'Sum', id: 'sum', type: 'field' },
  { name: 'Average', id: 'average', type: 'field' },
  { name: 'Max value', id: 'maxValue', type: 'field' },
  { name: 'Min value', id: 'minValue', type: 'field' },
  { name: 'Latest value', id: 'latestValue', type: 'field' },
  { name: 'Percent change', id: 'percentChange', type: 'field' },
  { name: 'Iterations completed', id: 'iterationsCompleted', type: 'section' },
]

const methodOptionsGroups = [
  {
    name: 'Fields',
    id: 'fields',
    data: methodOptions.filter(({ type }) => type === 'field'),
  },
  {
    name: 'Sections',
    id: 'sections',
    data: methodOptions.filter(({ type }) => type === 'section'),
  },
]

const methodSortOrder = [
  { name: 'Greater than goal', id: 'desc' },
  { name: 'Less than goal', id: 'asc' },
]

export function ChallengeEditorModal({
  onResolve,
  onReject,
  activity,
  challenge,
  orgId,
}: Props) {
  const previousMethod = useRef(null)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const convertedWorkflowStartDate = DateTime.fromISO(activity.startDate.date, {
    zone: activity.startDate.timeZone,
  }).setZone(timeZone)
  const convertedWorkflowEndDate = DateTime.fromISO(activity.endDate.date, {
    zone: activity.endDate.timeZone,
  }).setZone(timeZone)

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid, isDirty, dirtyFields },
    watch,
    resetField,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      section: challenge?.section ? { ...challenge.section, fields: [] } : null,
      method: challenge
        ? methodOptions.find(({ id }) => id === challenge.method)
        : null,
      dateRange: [convertedWorkflowStartDate, convertedWorkflowEndDate],
      goalUnit: challenge
        ? lengthOptions.find(method => method.id === challenge.goalUnit)
        : null,
      challengeTitle: challenge?.title || '',
      challengeGoal: challenge?.goal || '',
      challengeGoalUnit: challenge
        ? lengthOptions.find(method => method.id === challenge.goalUnit)
        : lengthOptions[0],
      selectedField:
        challenge && challenge.field
          ? {
              label: challenge.field.fieldLabel,
              id: challenge.field.fieldId,
              textType: challenge.field.textType,
              dataLabel: challenge.field.dataLabel,
            }
          : null,
      methodSortOrder: challenge
        ? methodSortOrder.find(({ id }) => challenge.sortOrder === id)
        : methodSortOrder[0],
    },
  })

  const [section, method, selectedField, dateRange] = watch([
    'section',
    'method',
    'selectedField',
    'dateRange',
  ])

  const isDistanceField =
    selectedField?.textType === TEXT_TYPE_CONSTANTS.DISTANCE
  const isPercentField =
    selectedField?.textType === TEXT_TYPE_CONSTANTS.PERCENTAGE
  const isDurationField =
    selectedField?.textType === TEXT_TYPE_CONSTANTS.DURATION

  useEffect(() => {
    if (method && !previousMethod.current) {
      previousMethod.current = method
      return
    }
    if (dirtyFields.method && previousMethod.current.type !== method.type) {
      resetField('challengeTitle')
      resetField('challengeGoal')
      resetField('selectedField')
      resetField('methodSortOrder')
      resetField('goalUnit')
    }
  }, [dirtyFields.method, method, previousMethod.current, resetField])

  const iterationStartDates = useMemo(() => {
    if (!section?.constraints) return []
    const options = RRule.parseString(section.constraints.recurrence || '')
    options.dtstart = convertedWorkflowStartDate.toJSDate()
    options.until = convertedWorkflowEndDate.toJSDate()
    const rule = new RRule(options)
    const all = rule
      .all()
      .map(date =>
        DateTime.fromJSDate(date)
          .startOf('day')
          .toISO({ includeOffset: false }),
      )
    all.pop()
    return all
  }, [section])

  const [workflowSections] = useLoad(
    [],
    async () => {
      const q = query(
        collection(db, dbNames.activitySections),
        where('activityId', '==', activity.id),
      )
      const sections = await getDocs(q).then(
        (snap: QuerySnapshot<DocumentData>) =>
          snap.docs.map(doc => ({
            ...(doc.data() as ActivitySection),
            id: doc.id,
          })),
      )

      if (section) {
        const fullSection = sections.find(({ id }) => id === section.id)
        setValue('section', fullSection, { shouldDirty: false })
      }

      return sections
    },
    [],
  )

  function renderTypeSpecificFields() {
    const methodSortSelection = (
      <div className="form-group">
        <label htmlFor="goal-type-select">Target direction</label>
        <Controller
          rules={{ required: true }}
          render={({ field }) => (
            <SelectSingle
              {...field}
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              triggerIsBlock
              contentWidth="sm"
              data={methodSortOrder}
              disabled={!selectedField}
              fixedContent
            />
          )}
          name="methodSortOrder"
          control={control}
        />
      </div>
    )

    const goalInput = (
      <div className="form-group">
        <Controller
          rules={{ required: true }}
          render={({ field }) => (
            <NumberInput
              {...field}
              label="Goal"
              disabled={!selectedField}
              required
            />
          )}
          name="challengeGoal"
          control={control}
        />
      </div>
    )

    if (isDistanceField) {
      return (
        <>
          <div className="row break-1">
            <div className="col-6">{goalInput}</div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="goal-unit-select">Unit</label>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <SelectSingle
                      {...field}
                      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                      triggerIsBlock
                      contentWidth="sm"
                      data={lengthOptions}
                      placeholder="Select unit"
                      disabled={!selectedField}
                      fixedContent
                    />
                  )}
                  name="goalUnit"
                />
              </div>
            </div>
          </div>
          <div className="row break-1">
            <div className="col-12">{methodSortSelection}</div>
          </div>
        </>
      )
    }

    if (isDurationField) {
      return (
        <>
          <div className="row break-1">
            <div className="col-12">
              <div className="form-group">
                <Controller
                  rules={{ required: true }}
                  render={({ field }) => <DurationInput {...field} />}
                  name="challengeGoal"
                  control={control}
                />
              </div>
            </div>
          </div>
          <div className="row break-1">
            <div className="col-6">{methodSortSelection}</div>
          </div>
        </>
      )
    }

    if (isPercentField) {
      return (
        <div className="row break-1">
          <div className="col-6">
            <div className="form-group">
              <Controller
                rules={{ required: true }}
                render={({ ...field }) => (
                  <NumberInput
                    {...field}
                    min={0}
                    max={100}
                    label="Goal"
                    disabled={!selectedField}
                    required
                  />
                )}
                name="challengeGoal"
                control={control}
              />
            </div>
          </div>
          {method.id !== 'sum' && (
            <div className="col-6">{methodSortSelection}</div>
          )}
        </div>
      )
    }

    return (
      <div className="row break-1">
        <div className="col-6">{goalInput}</div>
        <div className="col-6">{methodSortSelection}</div>
      </div>
    )
  }

  function getIndexRangeFromDates(
    ruleString: string,
    from: DateTime,
    until: DateTime,
  ) {
    if (!ruleString) return [0, 0]
    const rule = RRule.fromString(ruleString)
    const all = rruleGetBetween(rule, from, until)
    const startIndex = all.findIndex(
      (date: DateTime) => date.toISO() >= dateRange[0].toISO(),
    )
    const endIndex = all.findLastIndex(
      (date: DateTime) => date.toISO() < dateRange[1].toISO(),
    )
    return [startIndex, endIndex]
  }

  async function handleCreateChallenge(submitValues: FormValues) {
    const [startIndex, endIndex] = getIndexRangeFromDates(
      section.constraints.recurrence,
      convertedWorkflowStartDate,
      convertedWorkflowEndDate,
    )

    const createChallengeObject = () => {
      const baseObject = {
        activityId: activity.id,
        title:
          submitValues.challengeTitle ||
          (method.type === 'field' ? selectedField.label : ''),
        section: {
          id: section.id,
          name: section.name,
        },
        startDate: {
          date: submitValues.dateRange[0].toISO({ includeOffset: false }),
          timeZone,
        },
        endDate: {
          date: submitValues.dateRange[1]
            .endOf('day')
            .toISO({ includeOffset: false }),
          timeZone,
        },
        iterationIndexStart: startIndex,
        iterationIndexEnd: endIndex,
        goal: submitValues.challengeGoal,
        method: submitValues.method?.id || '',
        createdDate: getTodayDate().toISO(),
        orgId,
      }

      if (submitValues.method.type === 'field') {
        return {
          ...baseObject,
          field: {
            fieldId: selectedField.id,
            fieldLabel: selectedField.label,
            dataLabel: selectedField.dataLabel || '',
            textType: selectedField.textType || '',
          },
          goalUnit: isDistanceField ? submitValues.goalUnit.id : '',
          sortOrder: submitValues.methodSortOrder.id,
        }
      }

      return baseObject
    }

    try {
      await runTransaction(db, async transaction => {
        const challengeDoc = doc(collection(db, dbNames.activityChallenges))
        transaction.set(challengeDoc, createChallengeObject())

        transaction.update(doc(db, dbNames.activities, activity.id), {
          challengeOrderIds: arrayUnion(challengeDoc.id),
        })
      })
      toastService.info('Successfully created challenge')
      onResolve()
    } catch (err) {
      console.log(err)
    }
  }

  async function handleUpdateChallenge(submitValues: FormValues) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const [startIndex, endIndex] = getIndexRangeFromDates(
      submitValues.section.constraints.recurrence,
      convertedWorkflowStartDate,
      convertedWorkflowEndDate,
    )

    const createUpdateObject = () => {
      const baseObject = {
        title: submitValues.challengeTitle,
        goal: submitValues.challengeGoal,
        method: submitValues.method.id,
        'section.id': submitValues.section.id,
        'section.name': submitValues.section.name,
      }

      if (submitValues.method.type === 'field') {
        return {
          ...baseObject,
          title:
            submitValues.challengeTitle || submitValues.selectedField.label,
          sortOrder: submitValues.methodSortOrder.id,
          method: submitValues.method.id,
          goalUnit: submitValues.goalUnit?.id || '',
          iterationIndexStart: startIndex,
          iterationIndexEnd: endIndex,
          'field.fieldId': selectedField.id,
          'field.fieldLabel': selectedField.label,
          'field.dataLabel': selectedField.dataLabel || '',
          'startDate.date': submitValues.dateRange[0].toISO({
            includeOffset: false,
          }),
          'endDate.date': submitValues.dateRange[1].toISO({
            includeOffset: false,
          }),
          'endDate.timeZone': timeZone,
          'startDate.timeZone': timeZone,
        }
      }

      return baseObject
    }

    try {
      await updateDoc(
        doc(db, dbNames.activityChallenges, challenge.id),
        createUpdateObject(),
      )
      toastService.info('Successfully updated challenge')
      onResolve()
    } catch (err) {
      console.log(err)
    }
  }

  function getDateRangeTileClass({ date, view }: { date: Date; view: string }) {
    if (view === 'month') {
      const match = iterationStartDates.find(iterationDate => {
        const isoDate = DateTime.fromJSDate(date)
          .setZone(timeZone)
          .toISO({ includeOffset: false })
        return iterationDate === isoDate
      })
      if (match) {
        return 'iteration-start-date-tile'
      }
    }
  }

  const enableSubmit = isValid && (!!challenge || isDirty)
  const submitFn = challenge ? handleUpdateChallenge : handleCreateChallenge

  return (
    <>
      <Modal.Header>
        {challenge ? 'Edit leaderboard' : 'Create leaderboard'}
      </Modal.Header>
      <form onSubmit={handleSubmit(submitFn)}>
        <Modal.Body>
          <div className="row break-1">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="selected-method">
                  Track target <Required />
                </label>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <SelectSingle
                      {...field}
                      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                      isGroupData
                      triggerIsBlock
                      data={methodOptionsGroups}
                      disabled={!!challenge}
                      placeholder="Select track target"
                      fixedContent
                      contentWidth="md"
                    />
                  )}
                  name="method"
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="selected-challenge-field">
                  Section <Required />
                </label>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <SelectSingle
                      {...field}
                      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                      triggerIsBlock
                      contentWidth="md"
                      position="bottom-right"
                      data={workflowSections}
                      placeholder="Select section"
                      fixedContent
                    />
                  )}
                  name="section"
                />
              </div>
            </div>
          </div>

          {section && (
            <>
              <div className="row break-1">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="date-range-start">
                      Challenge date range
                    </label>
                    <Controller
                      rules={{ required: true }}
                      render={({
                        field: { onChange: onDateRangeChange, ...rest },
                      }) => (
                        <DatePicker
                          {...rest}
                          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                          minDate={convertedWorkflowStartDate}
                          maxDate={convertedWorkflowEndDate}
                          contentWidth="md"
                          tileClassName={getDateRangeTileClass}
                          placeholder="Select date"
                          onChange={([start, end]: DateTime[]) => {
                            onDateRangeChange([start, end.endOf('day')])
                          }}
                          selectRange
                          triggerIsBlock
                          fixedContent
                          onReset={() => {
                            onDateRangeChange([
                              convertedWorkflowStartDate,
                              convertedWorkflowEndDate,
                            ])
                          }}
                        />
                      )}
                      name="dateRange"
                      control={control}
                    />
                  </div>
                </div>
              </div>

              {method?.type === 'section' && (
                <>
                  <div className="row break-1">
                    <div className="col-12">
                      <div className="form-group">
                        <Controller
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextInput
                              {...field}
                              placeholder="Choose a title"
                              required
                              label="Challenge title"
                            />
                          )}
                          name="challengeTitle"
                          control={control}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row break-1">
                    <div className="col-6">
                      <div className="form-group">
                        <Controller
                          rules={{ required: true }}
                          render={({ field }) => (
                            <NumberInput
                              {...field}
                              label="Goal"
                              required
                              placeholder="Enter goal"
                            />
                          )}
                          name="challengeGoal"
                          control={control}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {method?.type === 'field' && (
                <>
                  <div className="row break-1">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="selected-challenge-field">
                          Field (numeric fields only) <Required />
                        </label>
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <SelectSingle
                              {...field}
                              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                              triggerIsBlock
                              disabled={!section}
                              data={
                                section?.fields.filter(isNumericField) || []
                              }
                              transformData={({
                                label,
                                id,
                              }: {
                                label: string
                                id: string
                              }) => ({ name: label, id })}
                              placeholder="Select field"
                              fixedContent
                            />
                          )}
                          name="selectedField"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row break-1">
                    <div className="col-12">
                      <div className="form-group">
                        <Controller
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextInput
                              {...field}
                              placeholder="Choose a title"
                              label="Challenge title"
                              disabled={!selectedField}
                              required
                            />
                          )}
                          name="challengeTitle"
                          control={control}
                        />
                      </div>
                    </div>
                  </div>
                  {renderTypeSpecificFields()}
                </>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onReject}>Cancel</Button>
          <Button
            variant="primary"
            type="submit"
            disabled={!enableSubmit}
          >
            {challenge ? 'Edit challenge' : 'Create challenge'}
          </Button>
        </Modal.Footer>
      </form>
    </>
  )
}
