import { k, useCss } from 'kremling'
import { isNil } from 'lodash'
import React, { forwardRef, useMemo } from 'react'

import { Button } from '@components/mantine/button.component'
import { NumberInput } from '@components/mantine/number-input.component'

function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}

export function convertMsToTime(milliseconds) {
  if (isNil(milliseconds) || milliseconds === '') return ['', '', '']
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)
  seconds = seconds % 60
  minutes = minutes % 60
  return [padTo2Digits(hours), padTo2Digits(minutes), padTo2Digits(seconds)]
}

export const DurationInput = forwardRef((props, ref) => {
  const { onChange, value, error, ...inputProps } = props
  const scoped = useCss(css)
  const [hours, minutes, seconds] = useMemo(
    () => (value === 0 ? [0, 0, 0] : convertMsToTime(value)),
    [value],
  )

  function handleChange(newHours, newMinutes, newSeconds) {
    let _newHours = parseInt(newHours || 0, 10)
    let _newMinutes = parseInt(newMinutes || 0, 10)
    let _newSeconds = parseInt(newSeconds || 0, 10)
    if (_newHours < 0) _newHours = 0
    //Convert to milliseconds
    onChange((_newHours * 60 * 60 + _newMinutes * 60 + _newSeconds) * 1000)
  }

  return (
    <div>
      <div
        {...scoped}
        className="duration-input__container"
        ref={ref}
      >
        <NumberInput
          onChange={newHours => handleChange(newHours, minutes, seconds)}
          value={hours}
          placeholder="HH"
          label="Hours"
          min={0}
          autoComplete="off"
          hideControls
          disableWheel
          allowDecimal={false}
          allowLeadingZeros
          error={!!error}
          {...inputProps}
        />
        <div className="duration-input__divider media-show-1">:</div>
        <NumberInput
          onChange={newMinutes => handleChange(hours, newMinutes, seconds)}
          value={minutes}
          placeholder="MM"
          label="Minutes"
          min={0}
          autoComplete="off"
          hideControls
          disableWheel
          allowDecimal={false}
          allowLeadingZeros
          error={!!error}
          {...inputProps}
        />
        <div className="duration-input__divider media-show-1">:</div>

        <NumberInput
          onChange={newSeconds => handleChange(hours, minutes, newSeconds)}
          value={seconds}
          placeholder="SS"
          autoComplete="off"
          label="Seconds"
          min={0}
          hideControls
          disableWheel
          allowDecimal={false}
          allowLeadingZeros
          error={!!error}
          {...inputProps}
        />
        <Button
          className="ml-4"
          variant="secondary"
          disabled={isNil(value) || value === '' || inputProps.disabled}
          onClick={() => onChange(null)}
        >
          Clear
        </Button>
      </div>
      {error && <div className="mt-1 text-xl text-danger">{error}</div>}
    </div>
  )
})

const css = k`
  .duration-input__container {
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: space-between;
  }

  @media (max-width: $media1-width) {
    .duration-input__container {
      flex-direction: column;
      align-items: center;
    }

    .duration-input__container input:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .duration-input__divider {
    font-size: 2rem;
    font-weight: 500;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
  }
`
