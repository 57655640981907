import { TemplateCard } from '@components/cards/template-card.component'
import { EmptyState } from '@components/empty-state.component'
import { Loader } from '@components/loader.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { toastService } from '@utils/toast.service'
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import React from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

export function EditingTemplatesList({ searchVal }) {
  const user = useAppState(state => state.user)
  const { orgId } = useParams()
  const { addTemplate } = useOutletContext()

  const [templates, _setTemplates, { loading, reload: reloadTemplates }] =
    useLoad(
      [],
      () => {
        const q = query(
          collection(db, dbNames.workflowTemplates),
          orderBy('name'),
          where('orgId', '==', orgId),
          where('archived', '==', false),
        )
        return getDocs(q).then(q =>
          q.docs.map(d => ({ ...d.data(), id: d.id })),
        )
      },
      [],
    )

  async function createDuplicateTemplate(template) {
    const date = getTodayDate().toISO()

    try {
      const conversationAssistantSnap = await getDoc(
        doc(db, dbNames.assistantConversations, template.id),
      )
      const conversationAssistantData = conversationAssistantSnap.data()

      const duplicatedTemplatePayload = {
        name: `${template.name} (copy)`,
        sections: template.sections,
        orgId,
        createdDate: date,
        lastEditedDate: date,
        lastEditedById: user.id,
        assistant: template.assistant,
        currentVersionEditorIds: [user.id],
        editorIds: [user.id],
        authorId: user.id,
        archived: false,
      }

      const newTemplateRef = await addDoc(
        collection(db, dbNames.workflowTemplates),
        duplicatedTemplatePayload,
      )
      const newTemplateId = newTemplateRef.id

      await setDoc(doc(db, dbNames.assistantConversations, newTemplateId), {
        ...conversationAssistantData,
        createdDate: date,
      })

      toastService.info('Template duplicated successfully')
      reloadTemplates()
    } catch (err) {
      toastService.error('Creating a duplicate failed. Please try again.')
    }
  }

  async function archiveTemplate(template) {
    const templateDoc = doc(db, dbNames.workflowTemplates, template.id)
    await updateDoc(templateDoc, {
      archived: true,
      lastPublishedDate: null,
    })

    const q = query(
      collection(db, dbNames.publishedTemplates),
      where('publishedFromTemplateId', '==', template.id),
      where('publishedLocations', 'array-contains-any', [
        'workspace',
        'marketplace',
      ]),
    )

    const publishedTemplates = await getDocs(q)
    if (!publishedTemplates.empty) {
      const publishedTemplateDoc = doc(
        db,
        dbNames.publishedTemplates,
        publishedTemplates.docs[0].id,
      )
      await updateDoc(publishedTemplateDoc, {
        publishedLocations: [],
      })
    }

    reloadTemplates()
  }

  function renderTemplates() {
    const filteredTemplates = templates.filter(template => {
      return searchVal
        ? template.name.toLowerCase().includes(searchVal.toLowerCase())
        : true
    })

    if (filteredTemplates.length) {
      return filteredTemplates.map(template => (
        <TemplateCard
          template={template}
          createDuplicateTemplate={createDuplicateTemplate}
          archiveTemplate={archiveTemplate}
          key={template.id}
        />
      ))
    } else {
      if (searchVal) {
        return (
          <EmptyState
            title="No search results"
            subtitle={`"${searchVal}" yielded no results. Can't find what you're looking for? Add a new template below.`}
          />
        )
      } else {
        return (
          <EmptyState
            title="No templates"
            subtitle="Create a template to get started"
            action={addTemplate}
            actionText="New template"
          />
        )
      }
    }
  }

  if (loading)
    return (
      <div className="pt-8">
        <Loader />
      </div>
    )

  return <div className="space-y-4">{renderTemplates()}</div>
}
