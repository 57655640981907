import React, { useEffect, useRef, useState } from 'react'
import { useCss, k, a } from 'kremling'
import { bool } from 'prop-types'

import { Loader } from '@components/loader.component'
import { Icon } from '@components/icon.component'

export function SavingIndicator({ saving, className = '', ...rest }) {
  const [perceptionSave, setPerceptionSave] = useState(false)
  const perceptionRef = useRef(null)
  const scope = useCss(css)

  useEffect(() => {
    perceptionRef.current = setTimeout(() => {
      if (perceptionSave === true) {
        setPerceptionSave(false)
      }
    }, 1000)
    if (saving) {
      setPerceptionSave(true)
    }
    return () => {
      clearTimeout(perceptionRef.current)
      perceptionRef.current = null
    }
  }, [saving])

  const isSaving = perceptionSave || saving

  return (
    <div
      {...scope}
      className={a('saving-indicator').a(className)}
      {...rest}
    >
      <div className="media-show-2 inline-flex flex-shrink-0 items-center leading-1">
        <div className="saving-indicator__sign">
          {isSaving ? (
            <Loader
              size="sm"
              inline
            />
          ) : (
            <Icon
              name="cloud-check"
              size={16}
            />
          )}
        </div>
        <div className="saving-indicator__words">
          {isSaving ? 'Saving' : 'Saved'}
        </div>
      </div>
      <div className="media-hide-2">
        <div className="saving-indicator__sign">
          {isSaving ? (
            <Loader
              size="sm"
              inline
            />
          ) : (
            <Icon
              name="cloud-check"
              size={20}
            />

          )}
        </div>
      </div>
    </div>
  )
}

SavingIndicator.propTypes = {
  saving: bool,
}

const css = k`
  .saving-indicator {
    color: $color-primary;
    font-weight: 600;
    line-height: 1;
    font-size: 1.4rem;
  }

  .saving-indicator__words {
    width: 4.4rem;
  }

  .saving-indicator__sign {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
  }
`
