import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { k, useCss } from 'kremling'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore'
import { useParams } from 'react-router-dom'

import { Card } from '@components/card.component'
import { Icon } from '@components/icon.component'
import { Button } from '@components/mantine/button.component'
import { SelectSingle } from '@components/select-single.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db, functions } from '@src/firebase-app'
import { MentorCard } from '@src/pages/workflow/client/mentor-card.component'
import { useActivityStore } from '@src/pages/workflow/client/workflow-refactor/state/use-activity-state'
import { dbNames } from '@utils/constants'
import { getUserFullName } from '@utils/helpers'
import { toastService } from '@utils/toast.service'

const useMentors = activityId => {
  const [mentorsQuery, isLoading, error] = useCollection(
    query(
      collection(db, dbNames.activityMentors),
      where('activityId', '==', activityId),
    ),
  )

  const mentors = mentorsQuery?.docs?.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }))

  return {
    mentors,
    isLoading,
    error,
    deleteMentorById: async id => {
      await deleteDoc(doc(db, dbNames.activityMentors, id))
    },
  }
}

const useInvitations = activityId => {
  const [invitationsQuery, isLoading, error] = useCollection(
    query(
      collection(db, dbNames.invitations),
      where('itemId', '==', activityId),
      where('type', '==', 'peerMentor'),
    ),
  )

  const invitations = invitationsQuery?.docs?.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }))

  return {
    invitations,
    isLoading,
    error,
    deleteInvitationById: async id => {
      await deleteDoc(doc(db, dbNames.invitations, id))
    },
  }
}

export function WorkflowMentors({ toggleSections }) {
  const user = useAppState(state => state.user)
  const { orgId, activityId } = useParams()
  const [email, setEmail] = useState('')
  const [availableMentorSearch, setAvailableMentorSearch] = useState('')
  const [selectedAvailableMentor, setSelectedAvailableMentor] = useState(null)
  // const [isRequestLoading, setRequestLoading] = useState(false)
  const scoped = useCss(css)
  const activityUser = useActivityStore(state => state.activityUser)
  const {
    mentors,
    isLoading: isMentorsLoading,
    error,
    deleteMentorById,
  } = useMentors(activityId)
  const {
    invitations,
    isLoading: isInvitationsLoading,
    error: invitationsError,
    deleteInvitationById,
  } = useInvitations(activityId)

  const [
    availableMentors,
    _setAvailableMentors,
    { isLoading: isAvailableMentorsLoading },
  ] = useLoad(
    [],
    () => {
      if (!activityUser) return Promise.resolve([])
      const q = query(
        collection(db, dbNames.users),
        where(`orgRoles.${orgId}`, '==', 'mentor'),
      )
      return getDocs(q).then(q => {
        return q.docs
          .map(d => {
            const _user = { ...d.data(), id: d.id }
            return {
              ..._user,
              name: getUserFullName(_user),
            }
          })
          .filter(_user => {
            return _user.id !== activityUser.userId
          })
      })
    },
    [activityUser],
  )

  const [
    availableUsers,
    _setAvailableUsers,
    { isLoading: isAvailableUsersLoading },
  ] = useLoad(
    [],
    () => {
      if (!activityUser) return Promise.resolve([])
      const q = query(
        collection(db, dbNames.users),
        where(`orgRoles.${orgId}`, 'in', [
          'member',
          'owner',
          'manager',
          'editor',
        ]),
      )
      return getDocs(q).then(q => {
        return q.docs
          .map(d => {
            const _user = { ...d.data(), id: d.id }
            return {
              ..._user,
              name: getUserFullName(_user),
            }
          })
          .filter(_user => {
            return _user.id !== activityUser.userId
          })
      })
    },
    [activityUser],
  )

  const isLoading =
    isMentorsLoading ||
    isInvitationsLoading ||
    isAvailableMentorsLoading ||
    isAvailableUsersLoading

  useEffect(() => {
    const err = error || invitationsError
    if (err) {
      toastService.error(err.message)
    }
  }, [error])

  const removeMentor = async id => {
    try {
      await deleteMentorById(id)
    } catch (error) {
      toastService.error(error.message)
    }
  }

  const removeInvitation = async id => {
    try {
      await deleteInvitationById(id)
    } catch (error) {
      toastService.error(error.message)
    }
  }

  const addMentorByEmail = async ev => {
    ev.preventDefault()

    const userEmail =
      email.toLowerCase() || selectedAvailableMentor.email.toLowerCase()

    if (userEmail === user.email) {
      setEmail('')
      toastService.error('Please use an email address that is not your own.')
      return
    }

    if (userEmail && !isLoading) {
      const addMentor = httpsCallable(
        functions,
        'userFunctions-handleMentoringRequest',
      )
      try {
        await addMentor({
          email: userEmail,
          activityId,
          orgId,
        })
        setEmail('')
      } catch (e) {
        toastService.error(e.message)
      }
    }
  }

  const resendInvitation = async email => {
    try {
      const resendRequest = httpsCallable(
        functions,
        'userFunctions-handleMentoringRequest',
      )
      await resendRequest({
        email,
        activityId,
        orgId,
        retry: true,
      })
      toastService.info('Invite sent')
    } catch (error) {
      toastService.error(error.message)
    }
  }

  return (
    <div className="section-container">
      <div className="section-header media-hide-2">
        <Button
          variant="secondary"
          onClick={toggleSections}
          className="media-hide-3"
          leftSection={
            <Icon
              name="list-radio"
              size={14}
            />
          }
        >
          Sections
        </Button>
      </div>
      <div className="workflow-content__container">
        <Card
          {...scoped}
          className="mentors__container"
        >
          <h1 className="title">Mentors</h1>
          <section>
            <div className="form">
              <label htmlFor="mentor-email-select">Invite mentor</label>
              <SelectSingle
                id="mentor-email-select"
                triggerIsBlock
                contentWidth="block"
                placeholder="Select a mentor"
                className="select-single"
                data={[
                  {
                    id: 'mentors',
                    icon: 'users',
                    name: 'Mentors',
                    data: availableMentors,
                  },
                  {
                    id: 'users',
                    icon: 'users',
                    name: 'Users',
                    data: availableUsers,
                  },
                ]}
                isGroupData
                onChange={setSelectedAvailableMentor}
                searchOnChange={setAvailableMentorSearch}
                searchValue={availableMentorSearch}
                searchFilter
                value={selectedAvailableMentor}
              />
              <Button
                onClick={addMentorByEmail}
                variant="primary"
                className="mt-3"
                // aria-disabled={isRequestLoading}
                // loading={isRequestLoading}
              >
                Send invitation
              </Button>
            </div>
          </section>
          <section>
            <h2 className="font-bold">Invitations sent</h2>
            <div className="mentor-list">
              {!isMentorsLoading &&
                !error &&
                invitations?.map(invitation => (
                  <MentorCard
                    key={invitation.id}
                    mentor={{
                      userId: invitation.recipientId,
                      status: invitation.status,
                      inviterId: invitation.invitationEvents.at(-1).inviterId,
                      id: invitation.id,
                    }}
                    removeMentor={removeInvitation}
                    resendInvitation={resendInvitation}
                  />
                ))}
              {isEmpty(invitations) && <div>No pending invitations</div>}
            </div>
          </section>
          <section>
            <h2 className="font-bold">Current mentors</h2>
            <div className="mentor-list">
              {!isMentorsLoading &&
                !error &&
                mentors?.map(mentor => (
                  <MentorCard
                    key={mentor.id}
                    mentor={mentor}
                    removeMentor={removeMentor}
                  />
                ))}
              {isEmpty(mentors) && <div>No active mentors</div>}
            </div>
          </section>
        </Card>
      </div>
    </div>
  )
}

const css = k`
  .mentors__container {
    padding: 16px;
  }

  .title {
    width: 100%;
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: .8rem;
  }

  section:not(:last-child) {
    margin-bottom: 3rem;
  }

  .mentor-list {
    max-height: 200px;
    overflow-y: auto;
  }
`
