import { useCallback, useEffect, useRef, useState } from 'react'

export const useAudioPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [audioLoaded, setAudioLoaded] = useState(false)
  const audioRef = useRef(new Audio())

  const loadAudio = useCallback(async (audioUrl: string) => {
    try {
      audioRef.current.src = audioUrl
      await audioRef.current.load()
      setAudioLoaded(true)
      await audioRef.current.play()
      setIsPlaying(true)
    } catch (error) {
      console.error('Error loading audio:', error)
    }
  }, [])

  const playAudio = useCallback(() => {
    if (audioLoaded) {
      audioRef.current.play()
      setIsPlaying(true)
    }
  }, [audioLoaded])

  const pauseAudio = useCallback(() => {
    if (audioLoaded) {
      audioRef.current.pause()
      setIsPlaying(false)
    }
  }, [audioLoaded])

  useEffect(() => {
    const handleEnded = () => {
      setIsPlaying(false)
    }

    audioRef.current.addEventListener('ended', handleEnded)

    return () => {
      audioRef.current.removeEventListener('ended', handleEnded)
    }
  }, [])

  return {
    audioLoaded,
    isPlaying,
    playAudio,
    pauseAudio,
    loadAudio,
  }
}
