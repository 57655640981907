import { useEffect, useMemo, useRef } from 'react'
import { debounce } from 'lodash'

type DebounceCallback = (...args: unknown[]) => unknown

export const useDebounce = (
  callback: DebounceCallback,
  wait: number,
  dependencyList: unknown[] = [],
) => {
  const ref = useRef<DebounceCallback>()
  useEffect(() => {
    ref.current = callback
  }, [callback])
  return useMemo(
    () => debounce((...args: unknown[]) => ref.current?.(...args), wait),
    dependencyList,
  )
}
