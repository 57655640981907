import React from 'react'
import { useCss, k, a } from 'kremling'

import { useWorkflowProgress } from '@hooks/use-workflow-progress.hook'
import { Icon } from '@components/icon.component'

export function WorkflowProgressBar({
  activity,
  activityUser,
  href = '',
  size = '',
}) {
  const scope = useCss(css)
  const { startDate, endDate, progressStatus, dateProgress } =
    useWorkflowProgress(activity, activityUser.completedPercentage)
  const isSmall = size === 'sm'

  function normalizeProgress() {
    const { completedPercentage } = activityUser
    if (completedPercentage > 100) {
      return 100
    } else if (completedPercentage < 0 || !completedPercentage) {
      return 0
    }
    return completedPercentage
  }

  const progress = normalizeProgress()
  const progressBar = isSmall ? (
    <>
      <div className="track-container">
        <div
          className="range-track"
          role="progressbar"
          aria-valuetext={'time left'}
          aria-label={`activity ${activityUser.completedPercentage} percent complete`}
          aria-valuenow={progress}
        >
          <div
            className="progress-range"
            data-status={progressStatus}
            style={{ width: `${normalizeProgress()}%` }}
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="date">
        <span className="date-month">{startDate.toFormat('LLL')} </span>
        <span>{startDate.toFormat('d')}</span>
      </div>
      <div className="track-container">
        <div
          className="progress-now"
          style={{ left: `${dateProgress}%` }}
        >
          <div className="progress-date">Today</div>
        </div>
        <div
          className="range-track"
          role="progressbar"
          aria-valuetext={'time left'}
          aria-label={`activity ${activityUser.completedPercentage} percent complete`}
          aria-valuenow={progress}
        >
          <div
            className="progress-range"
            data-status={progressStatus}
            style={{ width: `${normalizeProgress()}%` }}
          />
        </div>
      </div>
      <div className="date">
        <span className="date-month">{endDate.toFormat('LLL')} </span>
        <span>{endDate.toFormat('d')}</span>
      </div>
      {href && (
        <div className="progress-navigation">
          <Icon name="angle-right" />
        </div>
      )}
    </>
  )

  return (
    <div
      {...scope}
      className={a('progress-wrapper').m('--small', isSmall)}
    >
      {progressBar}
    </div>
  )
}

const css = k`
  .progress-wrapper {
    background: $color-grey-25;
    padding: 1.2rem;
    border-radius: 50vh;
    display: flex;
    align-items: center;
    min-width: 260px;
    width: 100%;
    
    &.--small {
      min-width: auto;
      padding: .8rem;
    }
  }
  
  button.progress-wrapper {
    border: none;
    transition: background .2s ease;
    width: 100%;
  }
  
  button.progress-wrapper:hover {
    background: $color-grey-75;
  }
  
  .track-container {
    width: 100%;
    margin: 0 1.2rem;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .progress-wrapper.--small > .track-container {
    margin: 0;
  }
  
  .date {
    flex-shrink: 0;
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 600;
    
    .date-month {
      font-weight: 400;
    }
  }
  
  .progress-now {
    position: absolute;
    top: -1.6rem;
    text-align: center;
    font-size: 1rem;
    color: $color-grey-600;
    transform: translateX(-50%);
  
    .progress-date {
      white-space: nowrap;
    }
    
    &:after {
      content: "";
      height: 0.8rem;
      width: 0.3rem;
      background: $color-grey-800;
      position: absolute;
      border-radius: .2rem;
    }
  
    > .icon {
      margin-top: -1.2rem;
    }
  }
  
  .range-track {
    height: .5rem;
    width: 100%;
    border-radius: 100vh;
    overflow: hidden;
    background: $color-grey-200;
  
    .progress-range {
      height: 100%;
    }
  
    .progress-range:not([data-status="*"]) {
      background: $color-primary;
    }
  
    .progress-range[data-status="completed"] {
      background: $color-success;
    }
  
    .progress-range[data-status="overdue"] {
      background: $color-warning;
    }
  }
  
  .progress-navigation {
    border-left: solid 2px $color-grey-300;
    padding: 0 4px;
    margin-left: 8px;
    color: $color-grey-500;
  }
`
