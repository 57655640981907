import { doc, getDoc, setDoc } from 'firebase/firestore'
import { k, useCss } from 'kremling'
import { isEmpty, isEqual } from 'lodash'
import React, { useMemo, useState } from 'react'

import { Icon } from '@components/icon.component'
import { Button } from '@components/mantine/button.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Well } from '@components/well.component'
import {
  DndContext,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  restrictToHorizontalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers'
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useLoad } from '@hooks/use-load.hook'
import { db } from '@src/firebase-app'
import { MarketplaceTemplateCard } from '@src/pages/marketplace/marketplace-template-card.component'
import { getTypeSenseCollectionName, searchTypesense } from '@typesense-config'
import { dbNames } from '@utils/constants'

export function MarketplaceSettings() {
  const scoped = useCss(css)
  const [searchResults, setSearchResults] = useState([])
  const [editTemplates, setEditTemplates] = useState([])

  const [featuredTemplates, setFeaturedTemplates] = useLoad(
    [],
    async () => {
      const { editList, featuredList } = await getDoc(
        doc(db, dbNames.curatedTemplates, 'marketplace-featured'),
      ).then(q => {
        if (!q.exists()) return { editList: [], featuredList: [] }
        const data = q.data()
        return {
          editList: data.editList || [],
          featuredList: data.featuredList || [],
        }
      })

      const featuredSearchParams = { filter_by: `id: [${featuredList}]` }
      const editSearchParams = { filter_by: `id: [${editList}]` }
      const commonSearchParams = {
        q: '*',
        collection: getTypeSenseCollectionName('marketplaceTemplates'),
      }

      if (editList.length > 0) {
        await searchTypesense('marketplaceTemplates', {
          ...editSearchParams,
          ...commonSearchParams,
        }).then(res => {
          setEditTemplates(res.hits.map(({ document }) => document))
        })
      }

      if (featuredList.length > 0) {
        return await searchTypesense('marketplaceTemplates', {
          ...featuredSearchParams,
          ...commonSearchParams,
        }).then(({ hits }) => hits.map(({ document }) => document))
      } else {
        return Promise.resolve([])
      }
    },
    [],
  )

  const editListHasChanged = useMemo(
    () => !isEqual(editTemplates, featuredTemplates),
    [editTemplates, featuredTemplates],
  )

  function handleSearch(text) {
    if (!text) return setSearchResults([])
    const searchParams = {
      q: text,
      query_by: 'name, description',
    }
    searchTypesense('marketplaceTemplates', searchParams).then(({ hits }) => {
      setSearchResults(hits.map(({ document }) => document))
    })
  }

  function onDragEnd({ active, over }) {
    if (!over) return
    if (active.id !== over.id) {
      const _editTemplates = [...editTemplates]
      const activeIndex = _editTemplates.findIndex(({ id }) => id === active.id)
      const overIndex = _editTemplates.findIndex(({ id }) => id === over.id)
      const newList = arrayMove(_editTemplates, activeIndex, overIndex)

      setEditTemplates(newList)
      updateEditList(newList)
    }
  }

  function isFeatured(templateId) {
    return !!featuredTemplates.find(({ id }) => templateId === id)
  }

  function isInEditList(templateId) {
    return !!editTemplates.find(({ id }) => templateId === id)
  }

  async function addToEditList(template) {
    if (
      editTemplates.find(({ id }) => id === template.id) ||
      editTemplates.length === 4
    )
      return
    const _editTemplates = [...editTemplates]
    _editTemplates.push(template)
    setEditTemplates(_editTemplates)
    updateEditList(_editTemplates)
  }

  async function removeFromEditList(template, index) {
    const _editTemplates = [...editTemplates]
    _editTemplates.splice(index, 1)
    setEditTemplates(_editTemplates)
    updateEditList(_editTemplates)
  }

  function updateEditList(list) {
    setDoc(doc(db, dbNames.curatedTemplates, 'marketplace-featured'), {
      featuredList: featuredTemplates.map(({ id }) => id),
      editList: list.map(({ id }) => id),
    })
  }

  function updateFeaturedList() {
    setDoc(doc(db, dbNames.curatedTemplates, 'marketplace-featured'), {
      featuredList: editTemplates.map(({ id }) => id),
      editList: editTemplates.map(({ id }) => id),
    })
    setFeaturedTemplates(editTemplates)
  }

  function renderOverlayButton(template) {
    const featuredIndex = editTemplates.findIndex(
      ({ id }) => id === template.id,
    )
    const _isFeatured = isFeatured(template.id)
    const _isInEditList = isInEditList(template.id)
    if (_isFeatured && _isInEditList) {
      return (
        <button
          onClick={() => removeFromEditList(template, featuredIndex)}
          className="featured-btn featured"
        >
          <Icon
            name="star"
            size={16}
          />
        </button>
      )
    } else if (_isFeatured && !_isInEditList) {
      return (
        <button
          onClick={() => addToEditList(template)}
          className="featured-btn"
        >
          <Icon
            name="star-empty"
            size={16}
          />
        </button>
      )
    } else if (_isInEditList && !_isFeatured) {
      return (
        <button
          onClick={() => removeFromEditList(template, featuredIndex)}
          className="featured-btn in-edit"
        >
          <Icon
            name="pencil"
            size={16}
          />
        </button>
      )
    } else {
      return (
        <button
          onClick={() => addToEditList(template)}
          className="featured-btn"
        >
          <Icon
            name="plus"
            size={17}
          />
        </button>
      )
    }
  }

  const { setNodeRef } = useDroppable({})

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 15,
      },
    }),
  )

  return (
    <div {...scoped}>
      <DndContext
        onDragEnd={onDragEnd}
        sensors={sensors}
        modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
      >
        <Well>
          <Well.Body>
            <Well.Title
              className="mt-5 pt-4"
              icon="globe"
            >
              Current featured list
            </Well.Title>
            <div className="live-list__container mt-2">
              {isEmpty(featuredTemplates) ? (
                <div className="dropzone-empty">
                  No featured templates on display
                </div>
              ) : (
                <div className="template-list">
                  {featuredTemplates.map(featuredTemplate => (
                    <div
                      key={featuredTemplate.id}
                      className="m-2"
                    >
                      <MarketplaceTemplateCard template={featuredTemplate} />
                    </div>
                  ))}
                </div>
              )}
            </div>

            <Well.Title
              className="mt-5"
              icon="pencil"
            >
              <span>Edit list</span>
              <Button
                className="ml-3"
                variant="primary"
                disabled={
                  !editListHasChanged ||
                  editTemplates.length < 4 ||
                  editTemplates.length > 4
                }
                onClick={updateFeaturedList}
              >
                Publish changes
              </Button>
            </Well.Title>
            <div
              className="dropzone"
              ref={setNodeRef}
            >
              {isEmpty(editTemplates) ? (
                <div className="dropzone-empty">
                  Select featured templates from the list below
                </div>
              ) : (
                <div className="template-list">
                  <SortableContext
                    items={editTemplates.map(({ id }) => id)}
                    strategy={horizontalListSortingStrategy}
                  >
                    {editTemplates.map((featuredTemplate, index) => (
                      <EditListItem
                        template={featuredTemplate}
                        handleRemove={removeFromEditList}
                        index={index}
                        key={featuredTemplate.id}
                      />
                    ))}
                  </SortableContext>
                </div>
              )}
            </div>
            <div className="featured-search__container">
              <TextInput
                className="mt-5"
                onChange={handleSearch}
                placeholder="Search marketplace templates"
                icon="search"
                allowClear
              />
              {!isEmpty(searchResults) && (
                <div className="search-results__container">
                  {searchResults.map(searchResult => {
                    return (
                      <div
                        key={searchResult.id}
                        className="last-mr-0 featured-overlay mr-12"
                      >
                        <MarketplaceTemplateCard template={searchResult} />
                        {renderOverlayButton(searchResult)}
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </Well.Body>
        </Well>
      </DndContext>
    </div>
  )
}

const EditListItem = ({ template, handleRemove, index }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: template.id,
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    position: 'relative',
    zIndex: isDragging ? 1 : undefined,
  }

  return (
    <div
      style={style}
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      className="featured-overlay m-2"
    >
      <MarketplaceTemplateCard template={template} />
      <button
        onClick={() => handleRemove(template.id, index)}
        className="featured-btn"
      >
        <Icon
          name="times"
          size={16}
        />
      </button>
    </div>
  )
}

const css = k`
  .live-list__container {
    margin-bottom: 16px;
    height: 148px;
    border: solid 3px $color-grey-100;
    border-radius: $base-border-radius;
  }

  .dropzone {
    border: 3px dashed $color-grey-200;
    height: 148px;
    width: 100%;
    border-radius: $base-border-radius;
    margin-bottom: 16px;
    margin-top: 8px;
  }

  .template-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .dropzone-empty {
    height: 100%;
    color: $color-grey-400;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search-results__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 1.6rem;
  }

  .clone ~ div {
    display: none !important;
  }

  .featured-overlay {
    position: relative;
  }

  .featured-btn {
    all: unset;
    position: absolute;
    top: -5px;
    right: -5px;
    background: white;
    height: 29px;
    width: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: background .4s ease;
    box-shadow: 2px 3px 8px -3px #888888;
  }

  .featured-btn.featured {
    background: $color-primary;
    color: $color-warning;
  }

  .featured-btn:not(.featured):hover {
    background: $color-grey-50;
  }
`
