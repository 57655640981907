import convert from 'convert-units'

export function sumDistances(values, convertToUnit) {
  return values.reduce((accum, value) => {
    const num = Number(value.value)
    const convertedNum = convert(num).from(value.unit).to(convertToUnit)
    return accum + convertedNum
  }, 0)
}

export function sumValues(values) {
  return values.reduce((accum, value) => {
    return accum + Number(value.value)
  }, 0)
}
