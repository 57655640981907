import { collection, getDocs, orderBy, query } from 'firebase/firestore'
import { a, k, useCss } from 'kremling'
import { DateTime } from 'luxon'
import React from 'react'

import { Card } from '@components/card.component'
import { Icon } from '@components/icon.component'
import { Loader } from '@components/loader.component'
import { Tooltip } from '@components/tooltip.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate, uniqueIdentifierFromDate } from '@utils/helpers'

export function SuperSettingsOrgs() {
  const scope = useCss(css)
  const user = useAppState(state => state.user)
  const [orgs, _setOrgs, orgOpts] = useLoad(
    [],
    () => {
      if (!user) return new Promise(resolve => resolve([]))
      const q = query(collection(db, dbNames.organizations), orderBy('name'))
      return getDocs(q).then(q => q.docs.map(d => ({ ...d.data(), id: d.id })))
    },
    [user],
  )

  if (orgOpts.loading) return <Loader />
  return (
    <div {...scope}>
      <Card>
        {orgs.map(org => {
          let daysLeft = ''
          if (org.paidThroughDate) {
            const today = getTodayDate()
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            const convertedDate = DateTime.fromISO(org.paidThroughDate.date, {
              zone: org.paidThroughDate.timezone,
            }).setZone(timeZone)

            daysLeft = Math.floor(convertedDate.diff(today, ['days']).days)
          }
          return (
            <Card.Item
              href={`/super-settings/orgs/${org.id}`}
              className="org-wrapper"
              key={org.id}
              onClick={() => {}}
            >
              <div className="org-name">
                <div
                  className="avatar-circle"
                  style={{ background: org.themeColor }}
                />
                {org.name}{' '}
                <span className="org-id">
                  #{uniqueIdentifierFromDate(org.createdDate)}
                </span>
              </div>
              {daysLeft > 0 && (
                <Tooltip
                  content={`${daysLeft} day${daysLeft === 1 ? '' : 's'} left`}
                >
                  <div
                    className={a('org-status')
                      .m('expires-danger', daysLeft < 5)
                      .m('expires-warning', daysLeft < 15 && daysLeft >= 5)}
                  >
                    <Icon name="badge-dollar" />
                  </div>
                </Tooltip>
              )}
              {org.paidThroughDate && daysLeft <= 0 && (
                <div>
                  <Icon name="badge-dollar-light" />
                </div>
              )}
            </Card.Item>
          )
        })}
      </Card>
    </div>
  )
}

SuperSettingsOrgs.propTypes = {}

const css = k`
  .avatar-circle {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 2.5rem;
    background: $color-grey-100;
    margin-right: .8rem;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .org-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
    padding: 0 .8rem;
  }
  .org-name {
    font-size: 1.6rem;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .org-id {
    font-weight: 400;
    margin-left: 1.2rem;
    font-size: 1.4rem;
  }

  .org-status {
    color: $color-primary;
  }

  .expires-danger {
    color: $color-danger;
  }

  .expires-warning {
    color: $color-warning;
  }
`
