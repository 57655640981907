import { isEmpty } from 'lodash'

export const getPrimaryNavList = (selectedOrg, user) => {
  if (!selectedOrg || isEmpty(user) || !user.orgRoles) return []
  const role = user.orgRoles[selectedOrg.id]

  return [
    {
      path: 'home',
      label: 'Home',
      disableFor: [],
    },
    {
      path: 'mentoring',
      label: 'Mentoring',
      disableFor: ['member', 'mentor', 'editor'],
    },
    {
      path: 'library',
      label: 'Library',
      disableFor: ['member'],
    },
    {
      path: 'admin',
      label: 'Admin',
      disableFor: ['member', 'mentor', 'editor'],
    },
  ].filter(navItem => {
    if (!navItem.disableFor) return false

    return !navItem.disableFor.includes(role)
  })
}
