import React from 'react'
import { k, useCss } from 'kremling'

import { CardListItem } from '@components/card-list-item.component'
import { Pill } from '@components/pill/pill.component'

export function GroupCard({ group }) {
  const scope = useCss(css)

  return (
    <CardListItem
      {...scope}
      className="mt-5"
      href={group.id}
      icon="users"
      cardTitle={group.name}
      subTitle="User group"
      renderRightContent={() => (
        <Pill>
          {group.userIds.length}
          <span className="light-weight">
            Member{group.userIds.length > 1 ? 's' : ''}
          </span>
        </Pill>
      )}
    />
  )
}

const css = k`
  .light-weight {
    margin-left: .4rem;
    font-weight: 300;
  }
`
