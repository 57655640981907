import React from 'react'
import { useParams } from 'react-router-dom'
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'

import { useLoad } from '@hooks/use-load.hook'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { EmptyState } from '@components/empty-state.component'
import { Loader } from '@components/loader.component'
import { TemplateCard } from '@components/cards/template-card.component'

export function ArchivedTemplatesList({ searchVal }) {
  const { orgId } = useParams()

  const [templates, _setTemplates, templatesOpts] = useLoad(
    [],
    () => {
      const q = query(
        collection(db, dbNames.workflowTemplates),
        orderBy('name'),
        where('archived', '==', true),
        where('orgId', '==', orgId),
      )
      return getDocs(q).then(q => q.docs.map(d => ({ ...d.data(), id: d.id })))
    },
    [],
  )

  async function restoreTemplate(template) {
    const templateDoc = doc(db, dbNames.workflowTemplates, template.id)
    await updateDoc(templateDoc, {
      archived: false,
    })
    templatesOpts.reload()
  }

  function renderTemplates() {
    const filteredTemplates = templates.filter(template => {
      return searchVal
        ? template.name.toLowerCase().includes(searchVal.toLowerCase())
        : true
    })

    if (filteredTemplates.length) {
      return filteredTemplates.map(template => (
        <TemplateCard
          restoreTemplate={restoreTemplate}
          template={template}
          key={template.id}
        />
      ))
    } else {
      if (searchVal) {
        return (
          <EmptyState
            title="No search results"
            subtitle={`"${searchVal}" yielded no results.`}
          />
        )
      } else {
        return (
          <EmptyState title="No archived templates" />
        )
      }
    }
  }

  if (templatesOpts.loading)
    return (
      <div className="pt-8">
        <Loader />
      </div>
    )

  return <div>{renderTemplates()}</div>
}
