import React from 'react'
import { useCss, k, a } from 'kremling'
import { Tooltip } from '@components/tooltip.component'
import { toastService } from '@utils/toast.service'

export function CopyTextBar({ shareText, className = '' }) {
  const scope = useCss(css)

  async function copyToClipboard() {
    await navigator.clipboard.writeText(shareText)
    toastService.info('Copied to clipboard')
  }

  return (
    <div className={a('flex-ellipsis').m(className, !!className)}>
      <Tooltip content="Copy">
        <button
          {...scope}
          onClick={copyToClipboard}
          aria-label={`Click to copy share link ${shareText}`}
          className="share-link"
        >
          {shareText}
        </button>
      </Tooltip>
    </div>
  )
}

const css = k`
  .share-link {
    all: unset;
    width: 100%;
    box-sizing: border-box;
    padding: 4px 8px;
    background: $color-grey-75;
    border-radius: $base-border-radius;
    line-height: initial;
    cursor: pointer;
    transition: background .2s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .share-link:hover {
    background: $color-grey-100;
  }
`
