import React, { useEffect, useState } from 'react'
import convert from 'convert-units'

import { getChallengeResponsesForChallenge } from '@src/pages/workflow/settings/analytics.resource'
import { toastService } from '@utils/toast.service'
import { WorkflowLeaderboard } from '@src/pages/workflow/settings/challenge-items/workflow-leaderboard.component'
import { lengthOptions } from '@src/pages/workflow/fields.helper'
import { PillSelect } from '@components/pill/pill-select.component'
import { sumDistances } from '@src/pages/workflow/settings/progress.helpers'

export function WorkflowLeaderboardItemDistance({
  isAdmin,
  workflowChallenge,
  onEdit,
  onDelete,
  participantsCount,
}) {
  const [responses, setResponses] = useState([])
  const [selectedLengthUnit, setSelectedLengthUnit] = useState(
    lengthOptions.find(
      lengthOption => lengthOption.id === workflowChallenge.goalUnit,
    ),
  )

  useEffect(() => {
    getChallengeResponsesForChallenge(workflowChallenge)
      .then(setResponses)
      .catch(err => {
        console.log(err)
        toastService.error(
          'There was an error retrieving challenge data. Please try again later.',
        )
      })
  }, [])

  function convertDistance(response) {
    const convertedNum = convert(response.value)
      .from(response.unit)
      .to(selectedLengthUnit.id)
    return { value: Math.round(convertedNum * 10) / 10, unit: response.unit }
  }

  return (
    <WorkflowLeaderboard
      workflowChallenge={workflowChallenge}
      responses={responses}
      selectedUnit={selectedLengthUnit}
      processValue={convertDistance}
      processMethods={methods => {
        return {
          ...methods,
          sum({ values }) {
            return {
              value: sumDistances(values, selectedLengthUnit.id),
              unit: selectedLengthUnit.id,
            }
          },
          average({ values }) {
            return {
              value:
                sumDistances(values, selectedLengthUnit.id) / values.length,
              unit: selectedLengthUnit.id,
            }
          },
          maxValue({ values }) {
            const convertedValues = values.map(({ value, unit }) =>
              convert(Number(value)).from(unit).to('mm'),
            )
            const max = Math.max(...convertedValues)
            const maxIndex = convertedValues.indexOf(max)
            return values[maxIndex]
          },
          minValue({ values }) {
            const convertedValues = values.map(({ value, unit }) =>
              convert(Number(value)).from(unit).to('mm'),
            )
            const min = Math.min(...convertedValues)
            const minIndex = convertedValues.indexOf(min)
            return values[minIndex]
          },
        }
      }}
    >
      <WorkflowLeaderboard.Header
        canEdit={isAdmin}
        onEdit={onEdit}
        onDelete={onDelete}
        participantsCount={participantsCount}
        displayValue={value => `${value.value} ${selectedLengthUnit.id}`}
        renderPills={() => [
          <PillSelect
            key={0}
            className="ml-1"
            data={lengthOptions}
            value={selectedLengthUnit}
            onChange={setSelectedLengthUnit}
          />,
        ]}
      />
      <WorkflowLeaderboard.Body />
    </WorkflowLeaderboard>
  )
}
