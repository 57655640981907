import React from 'react'

import { TextInput } from '@components/mantine/text-input.component'
import { Switch } from '@components/radix/switch'
import { RichText } from '@components/rich-text/rich-text.component'
import { YouTubeEmbed } from './youtube-embed.component'

interface FieldYoutubeProps {
  field: Field
  onChange: (updatedField: Field, isLabel?: boolean) => void
  isRequired: boolean
  updateRequiredToggle: (value: boolean, fieldId: string) => void
}

export const FieldYoutube: React.FC<FieldYoutubeProps> = ({
  field,
  onChange,
  isRequired,
  updateRequiredToggle,
}) => {
  function updateFieldLabel(value: string) {
    if (value === field.label) return
    const updatedField = {
      ...field,
      label: value,
    }
    onChange(updatedField, true)
  }

  function updateFieldDescription(value: string) {
    const updatedField = {
      ...field,
      description: value,
    }
    onChange(updatedField)
  }

  function updateFieldLink(value: string) {
    const updatedField = {
      ...field,
      link: value,
    }
    onChange(updatedField)
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center">
        <Switch
          id={`required-switch-${field.id}`}
          onChange={value => updateRequiredToggle(value, field.id)}
          className="mr-2"
          value={isRequired}
        />
        <label htmlFor={`required-switch-${field.id}`}>Required</label>
      </div>
      <div className="row break-1">
        <div className="col-12">
          <div className="form-group">
            <TextInput
              label="Name the field"
              defaultValue={field.label}
              onChange={updateFieldLabel}
              placeholder="e.g. Watch this video"
              autoFocus
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="text-field-desc">Describe this field</label>
        <RichText
          id="text-field-desc"
          onChange={updateFieldDescription}
          initialValue={field.description || null}
          placeholder="Type here..."
        />
      </div>
      <YouTubeEmbed
        fieldLink={field.link}
        onChange={updateFieldLink}
      />
    </div>
  )
}
