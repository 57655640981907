import { InputField } from '@components/input'
import { Button } from '@components/mantine/button.component'
import { functions } from '@src/firebase-app'
import { httpsCallable } from 'firebase/functions'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

export function ResetPasswordForm({ onPasswordReset = () => {} }) {
  const [emailSent, setEmailSent] = useState('')
  const [sendingEmail, setSendingEmail] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: { email: '' },
  })

  function handleSendPasswordResetLink({ email }) {
    setSendingEmail(true)
    const sendPasswordResetEmail = httpsCallable(
      functions,
      'userFunctions-passwordReset',
    )
    sendPasswordResetEmail(email).then(({ data }) => {
      setEmailSent(data)
      setSendingEmail(false)
      reset()
      onPasswordReset()
    })
  }

  return (
    <form onSubmit={handleSubmit(handleSendPasswordResetLink)}>
      <h3 className="text-2xl font-bold">Request password reset</h3>
      <div className="row break-1">
        <div className="col-12">
          <div className="form-group">
            <InputField
              placeholder="Type here..."
              fieldName="email"
              type="email"
              control={control}
              label="Current email address"
            />
          </div>
        </div>
      </div>
      <Button
        type="submit"
        disabled={!isValid}
        variant="primary"
        loading={sendingEmail}
        fullWidth
      >
        Send request
      </Button>
      {emailSent && <div className="mt-4">{emailSent}</div>}
    </form>
  )
}
