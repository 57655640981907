import { useEffect, useRef } from 'react'

export function useCheckShouldSave({ isDirty }: { isDirty: boolean }) {
  const formIsTouched = useRef(false)

  useEffect(() => {
    if (isDirty) {
      formIsTouched.current = true
    }
  }, [isDirty])

  const shouldSave = () => {
    return formIsTouched.current
  }

  return {
    shouldSave,
  }
}
