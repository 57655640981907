import { FIELD_CONSTANTS } from '@src/pages/workflow/fields.helper'
import { DateTime } from 'luxon'
import { RRule } from 'rrule'

export const metas = {
  NO_RULES: 'NO_RULES',
  RECURRENCE_ONLY: 'RECURRENCE_ONLY',
  RECURRENCE_AND_REPEAT: 'RECURRENCE_AND_REPEAT',
}

/**
 * Build Rule Meta
 * ---------------
 * Figure out which section delegates the 'rules' of the workflow based off of of a 3 to 0 point score:
 * (2) recurrence & repeat - end date is generated
 * (1) recurrence only - section can be infinite if end date is not selected
 * (0) no rules
 * @param sections (array)
 * @param workflowStartDate (object)
 * @returns {{section, endDate, meta: (string)}}
 */
export function buildRuleMeta(sections, workflowStartDate) {
  const metaMap = [
    metas.NO_RULES,
    metas.RECURRENCE_ONLY,
    metas.RECURRENCE_AND_REPEAT,
  ]
  const { section, level, endDate } = sections.reduce(
    ({ level, section, ...rest }, nextSection) => {
      const { recurrence, repeat } = nextSection.constraints
      // (2) recurrence+repeat take precedence because the end date is generated and nothing can override it
      if (recurrence && repeat) {
        // only calculate end date if start date is available
        if (workflowStartDate) {
          const endDate = buildEndDate(
            nextSection.constraints,
            workflowStartDate,
          )
          let greaterSection = nextSection
          let greaterEndDate = endDate
          // more than one section exists with this rule
          if (level === 2) {
            const endDate2 = buildEndDate(
              section.constraints,
              workflowStartDate,
            )
            greaterSection = endDate < endDate2 ? section : nextSection
            greaterEndDate = endDate < endDate2 ? endDate2 : endDate
          }
          return { level: 2, section: greaterSection, endDate: greaterEndDate }
        }
        return { level: 2, section: nextSection, ...rest }
      }

      // (1) recurrence-only is next because the workflow can be infinite without an end date
      if (recurrence && level < 1) {
        return { level: 1, section: nextSection, ...rest }
      }

      // return last found level
      return { level, section, ...rest }
    },
    { level: 0 },
  )
  return { section, meta: metaMap[level], endDate }
}

export function ruleMeta(section) {
  if (!section) return metas.NO_RULES
  const { recurrence, repeat } = section.constraints
  if (recurrence && repeat) {
    return metas.RECURRENCE_AND_REPEAT
  } else if (recurrence) {
    return metas.RECURRENCE_ONLY
  }
  return metas.NO_RULES
}

/**
 * RRule get all
 * -------------
 * @param rule (object)
 * @returns (array)
 */
export function rruleGetAll(rule) {
  return rule.all().map(date => DateTime.fromJSDate(date))
}

/**
 * RRule get all between
 * -------------
 * @param rule (object)
 * @param start (object)
 * @param end (object)
 * @param inc (boolean)
 * @returns (array)
 */
export function rruleGetBetween(rule, start, end, inc = false) {
  return rule
    .between(start.toJSDate(), end.toJSDate(), inc)
    .map(date => DateTime.fromJSDate(date))
}

/**
 * Build end date
 * --------------
 * @param constraints
 * @param startDate
 * @returns {*}
 */
function buildEndDate(constraints, startDate) {
  const { recurrence } = constraints
  const origOptions = RRule.fromString(recurrence).origOptions
  const rule = new RRule({
    ...origOptions,
    dtstart: startDate.toJSDate(),
  })
  const occurrence = rruleGetAll(rule)
  return occurrence.at(-1).plus({ day: origOptions.interval })
}

/**
 * Sort sections by sectionOrderId
 * --------------
 * @param sections
 * @param activity
 * @returns (array)
 */
export function sortSections(sections, activity) {
  if (activity?.sectionOrder?.length && sections?.length) {
    return activity.sectionOrder.map(sectionOrderId =>
      sections?.find(c => c.sectionId === sectionOrderId),
    )
  }
  return sections
}

export const categories = [
  { name: 'Education', id: 'education' },
  { name: 'Fitness', id: 'fitness' },
  { name: 'Life Skills', id: 'life-skills' },
  { name: 'Mental Health', id: 'mental-health' },
  { name: 'Nutrition', id: 'nutrition' },
  { name: 'Onboarding', id: 'onboarding' },
  { name: 'Other', id: 'other' },
]

export function extractFieldValue(field, value) {
  let arrayValue
  let stringValue = value
  let actionCompleted = null
  if (field.type === FIELD_CONSTANTS.FILE_UPLOAD) {
    stringValue = null
    actionCompleted = value && value.length > 0
  } else if (
    field.type === FIELD_CONSTANTS.LINK ||
    field.type === FIELD_CONSTANTS.YOUTUBE
  ) {
    //Store values that are booleans in the actionCompleted property
    stringValue = null
    actionCompleted = value
  } else if (field.textType === 'distance') {
    //Distance is split up between its unit and the number value
    stringValue = value.value
  } else if (Array.isArray(value)) {
    //Multi-choice items will be stored as an array of choices
    arrayValue = value
    stringValue = null
  }

  return { arrayValue, stringValue, actionCompleted }
}
