import { doc, getDoc } from 'firebase/firestore'
import React, { useState } from 'react'

import { CardListItem } from '@components/card-list-item.component'
import { Button } from '@components/mantine/button.component'
import { useLoad } from '@hooks/use-load.hook'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getUserFullName } from '@utils/helpers'

export const MentorInvitationCard = ({
  invitation,
  handleDecline,
  handleAccept,
}) => {
  const [working, setWorking] = useState(false)

  const [activity = {}, _activity, activityOpts] = useLoad(
    {},
    () => {
      return getDoc(doc(db, dbNames.activities, invitation.itemId)).then(w => ({
        id: w.id,
        ...w.data(),
      }))
    },
    [],
  )

  const [activityUser = {}, _setActivityUser, activityUserOpts] = useLoad(
    {},
    () => {
      const inviterId = invitation.invitationEvents.at(-1).inviterId
      return getDoc(
        doc(db, dbNames.activityUsers, `${invitation.itemId}_${inviterId}`),
      ).then(wu => {
        return wu.data()
      })
    },
    [invitation],
  )

  function onDecline(e) {
    setWorking(true)
    handleDecline(invitation)
    e.preventDefault()
  }

  function onAccept(e) {
    setWorking(true)
    handleAccept(invitation)
    e.preventDefault()
  }

  const loading = activityUserOpts.loading || activityOpts.loading || working

  return (
    <CardListItem
      cardTitle={activity.name}
      subTitle={getUserFullName(activityUser)}
      icon="user-circle"
      loading={loading}
      renderRightContent={() => (
        <>
          <Button
            className="mr-2"
            variant="secondary"
            onClick={onDecline}
          >
            Decline
          </Button>
          <Button
            variant="primary"
            onClick={onAccept}
          >
            Accept
          </Button>
        </>
      )}
    />
  )
}
