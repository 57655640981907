import { useFirestoreQuery } from '@react-query-firebase/firestore'
import {
  arrayRemove,
  collection,
  doc,
  getCountFromServer,
  query,
  runTransaction,
  updateDoc,
  where,
} from 'firebase/firestore'
import { a, k, useCss } from 'kremling'
import { isEmpty } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { Card } from '@components/card.component'
import { EmptyState } from '@components/empty-state.component'
import { Icon } from '@components/icon.component'
import { LayoutHeader } from '@components/layout.component'
import { Button } from '@components/mantine/button.component'
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { ActivityChallenge } from '@src/pages/workflow/activity-challenge.component'
import { dbNames } from '@utils/constants'
import { collections } from '@utils/firebase.utils'
import { modalService } from '@utils/modal.service'
import { toastService } from '@utils/toast.service'
import { ChallengeEditorModal } from './challenge-editor-modal.component'

type OutletProps = {
  activity: Activity
}

type Props = {
  activity?: Activity
  renderHeader?: () => void
}
export function ActivityChallenges({
  renderHeader,
  activity: propActivity,
}: Props) {
  const { activity: outletActivity } = useOutletContext<OutletProps>()
  const { orgId } = useParams()
  const user = useAppState(state => state.user)
  const scoped = useCss(css)
  const isAdmin = ['manager', 'owner', 'mentor'].includes(user?.orgRoles[orgId])
  const [activity, setActivity] = useState<Activity>(
    propActivity || outletActivity,
  )

  const q = query(
    collection(db, dbNames.activityChallenges),
    where('activityId', '==', activity.id),
  )

  const { data: workflowChallenges } = useFirestoreQuery(
    activity.id,
    q,
    { subscribe: true },
    {
      select: snap => {
        return snap.docs.map(doc => ({ ...doc.data(), id: doc.id }))
      },
    },
  )

  const [participantsCount] = useLoad(
    0,
    () => {
      if (isEmpty(activity)) return Promise.resolve([])
      const q = query(
        collections.activityUsers,
        where('activityId', '==', activity.id),
      )
      return getCountFromServer(q).then(snap => snap.data().count)
    },
    [activity],
  )

  const sortedChallenges =
    activity.challengeOrderIds
      ?.map(id =>
        workflowChallenges?.find(
          (challenge: WorkflowChallenge) => challenge.id === id,
        ),
      )
      .filter(challenge => !!challenge) || workflowChallenges

  function openChallengeEditor(challenge?: object) {
    if (useAppState.getState().isFreeTier) return
    modalService.render(ChallengeEditorModal, {
      challenge,
      activity,
      orgId,
    })
  }

  async function deleteChallenge(challengeId: string) {
    if (useAppState.getState().isFreeTier) return
    try {
      await runTransaction(db, async transaction => {
        transaction.delete(doc(db, dbNames.activityChallenges, challengeId))
        transaction.update(doc(db, dbNames.activities, activity.id), {
          challengeOrderIds: arrayRemove(challengeId),
        })
      })
      toastService.info('Successfully deleted challenge')
    } catch (err) {
      toastService.error(
        'An error occurred when deleting the challenge. Please try again.',
      )
    }
  }

  function updateChallengeOrder(challengeIds: string[]) {
    const d = doc(db, dbNames.activities, activity.id)
    setActivity(
      prevState =>
        ({ ...prevState, challengeOrderIds: challengeIds }) as Activity,
    )
    updateDoc(d, {
      challengeOrderIds: challengeIds,
    })
  }

  function onDragEnd({ active, over }: DragEndEvent) {
    if (useAppState.getState().isFreeTier) return
    if (active.id !== over.id) {
      const _challengeOrderIds: string[] = activity?.challengeOrderIds
        ? [...activity.challengeOrderIds]
        : [...sortedChallenges.map((c: WorkflowChallenge) => c.id)]

      const activeIndex = _challengeOrderIds.findIndex(
        (id: string) => id === active.id,
      )
      const overIndex = _challengeOrderIds.findIndex(
        (id: string) => id === over.id,
      )

      const newOrder = arrayMove(_challengeOrderIds, activeIndex, overIndex)

      updateChallengeOrder(newOrder)
    }
  }

  const { setNodeRef } = useDroppable({
    id: 'workflow-challenges',
  })

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 15,
      },
    }),
  )

  return (
    <DndContext
      onDragEnd={onDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      sensors={sensors}
    >
      <div
        ref={setNodeRef}
        {...scoped}
        className={a('workflow-challenges__container relative p-12').m(
          'pt-8',
          renderHeader,
        )}
      >
        {useAppState.getState().isFreeTier && (
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-white/30 z-10 backdrop-blur-md flex items-center justify-center">
            <Card>
              <div className="p-8 flex justify-center items-center flex-col">
                <Icon
                  className="text-primary mb-4"
                  name="lock"
                  size={30}
                />
                <div className="text-2xl font-semibold">
                  Leaderboards is a paid feature
                </div>
                <div>
                  Click{' '}
                  <a
                    href="www.iact.com"
                    target="_blank"
                  >
                    here
                  </a>{' '}
                  to learn more!
                </div>
              </div>
            </Card>
          </div>
        )}
        {renderHeader && (
          <LayoutHeader
            title="Leaderboards"
            actions={
              isAdmin ? (
                <Button
                  className="add-challenge-btn"
                  variant="primary"
                  onClick={e => {
                    e.preventDefault()
                    openChallengeEditor()
                  }}
                >
                  <Icon
                    name="flag-checkered"
                    className="mr-2"
                  />
                  <span>Create leaderboard</span>
                </Button>
              ) : null
            }
          />
        )}
        {isAdmin && !renderHeader && (
          <Button
            className="add-challenge-btn mt-5"
            variant="primary"
            onClick={e => {
              e.preventDefault()
              openChallengeEditor()
            }}
          >
            <Icon
              name="flag-checkered"
              className="mr-2"
            />
            <span>Create leaderboard</span>
          </Button>
        )}
        {isEmpty(sortedChallenges) ? (
          <EmptyState
            title="No leaderboards created"
            subtitle="Add a leaderboard to view top achievers"
            action={
              isAdmin
                ? e => {
                    e.preventDefault()
                    openChallengeEditor()
                  }
                : null
            }
            actionText="Create leaderboard"
          />
        ) : (
          <SortableContext
            items={sortedChallenges.map(
              (challenge: WorkflowChallenge) => challenge.id,
            )}
          >
            {(sortedChallenges as WorkflowChallenge[]).map(
              activityChallenge => (
                <ActivityChallenge
                  key={activityChallenge.id}
                  activityChallenge={activityChallenge}
                  participantsCount={participantsCount}
                  openChallengeEditor={openChallengeEditor}
                  deleteChallenge={deleteChallenge}
                  isAdmin={isAdmin}
                />
              ),
            )}
          </SortableContext>
        )}
      </div>
    </DndContext>
  )
}

const css = k`
  @media(max-width: $media2-width) {
    .workflow-challenges__container {
      grid-template-columns: 1fr;
    }
  }
`
