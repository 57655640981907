import { motion } from 'framer-motion'
import { a, k, useCss } from 'kremling'
import React from 'react'

import { Card } from '@components/card.component'
import { ActionIcon } from '@components/mantine/action-icon.component'

export function Modal({ children, onClose, hideClose = false, size = 'md' }) {
  const scope = useCss(css)
  return (
    <div
      {...scope}
      className="modal-container"
    >
      <motion.div
        key="modal-backdrop"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2, ease: 'easeOut' }}
      >
        <div className="modal-backdrop" />
      </motion.div>
      <motion.div
        key="modal-container"
        initial={{ opacity: 0, y: '5vh' }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: '-5vh' }}
        transition={{ duration: 0.2, ease: 'easeOut' }}
      >
        <Card
          level={3}
          className={a('modal')
            .m('modal--sm', size === 'sm')
            .m('modal--md', size === 'md' || !size)
            .m('modal--lg', size === 'lg')}
        >
          {onClose && !hideClose && (
            <ActionIcon
              className="modal-close-button"
              size="sm"
              icon="times"
              variant="tertiary"
              onClick={onClose}
              aria-label="Close"
            />
          )}
          {children}
        </Card>
      </motion.div>
    </div>
  )
}

Modal.Header = ({ children, ...rest }) => (
  <div
    className="modal__header"
    {...rest}
  >
    <div className="modal__title">{children}</div>
  </div>
)

Modal.Body = ({ children, className = '', ...rest }) => (
  <div
    className={a('modal__body', className)}
    {...rest}
  >
    {children}
  </div>
)

Modal.Footer = ({ children, className = '', ...rest }) => (
  <div
    className={a('modal__footer', className)}
    {...rest}
  >
    {children}
  </div>
)

Modal.BalancedFooter = ({ children, ...rest }) => (
  <div
    className="modal__balanced_footer"
    {...rest}
  >
    {children}
  </div>
)

Modal.Overlay = ({ children, ...rest }) => (
  <div
    className="modal__overlay"
    {...rest}
  >
    {children}
  </div>
)

const css = k`
  .modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9000;
  }

  .modal-backdrop {
    background-color: rgba($color-grey-900, .2);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }

  .modal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    background-color: rgba(#fff, .9);
    border-radius: $base-border-radius;
    flex-direction: column;
    padding: 5rem;
  }

  .modal {
    position: relative;
    z-index: 1;
    border-radius: 0;
    max-height: calc(100vh - 1.6rem);
    display: flex;
    flex-direction: column;
  }

  .modal.modal--sm,
  .modal.modal--md,
  .modal.modal--lg {
    width: 100vw;
  }

  @media (min-width: $media1-width) {
    .modal {
      border-radius: $base-border-radius;
      max-height: calc(100vh - 3.2rem);
    }

    .modal.modal--sm {
      width: 34rem;
    }

    .modal.modal--md {
      width: 48rem;
    }

    .modal.modal--lg {
      width: 64rem;
    }
  }

  .modal__header {
    display: flex;
    align-items: center;
    min-height: 5rem;
    flex-shrink: 0;
    padding: 1.2rem 1.6rem;
  }

  .modal-close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .modal__title {
    font-size: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    gap: .8rem;
    align-items: center;
    font-weight: 500;
  }

  .modal__body {
    padding: .4rem 1.6rem;
    overflow-y: auto;
  }

  .modal__footer {
    text-align: right;
    padding: 1.6rem;
    flex-shrink: 0;

    > button {
      margin-left: .8rem;
    }
  }

  .modal__balanced_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem;
    flex-shrink: 0;

    > div button {
      margin-left: .8rem;
    }
  }
`
