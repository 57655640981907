import { EmptyState } from '@components/empty-state.component'
import { useDebounce } from '@hooks/use-debounce.hook'
import { AnimatePresence, motion } from 'framer-motion'
import React, { RefObject, useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useOutletContext } from 'react-router-dom'
import { nonCompletableFields } from '../../fields.helper'
import {
  findChangedField,
  getFieldDefaults,
  getRequiredFieldsCount,
  hasValue,
} from './activity-utils'
import { Field } from './field.component'
import { useCheckShouldSave } from './hooks/use-check-should-save.hook'
import { OutletProps } from './section.component'
import { useActivityStore } from './state/use-activity-state'

type Props = {
  section: ActivitySection
  iteration?: SectionIteration
  readOnly?: boolean
  uploadRef?: RefObject<any>
  headlessOnChange?: (responses: FieldResponses) => void
  suspendUpload?: boolean
}

export function Fields({
  section,
  iteration,
  readOnly: propsReadOnly,
  uploadRef,
  headlessOnChange,
  suspendUpload,
}: Props) {
  const saveActivity = useActivityStore.use.saveActivity()
  const outletContext = useOutletContext<OutletProps>()
  const readOnly = propsReadOnly || outletContext?.readOnly
  const [responses, setResponses] = React.useState<{
    [key: string]: FieldValue
  }>({})

  const fieldDefaults: FieldResponses = useMemo(() => {
    return getFieldDefaults(section, iteration?.fieldResponses || {})
  }, [section, iteration?.fieldResponses])

  const previousValuesRef = useRef(fieldDefaults)

  const {
    control,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: fieldDefaults,
    mode: readOnly ? 'onSubmit' : 'onTouched', //There is no submit, so validation will not occur if is readOnly
  })
  const values = watch()

  const { shouldSave } = useCheckShouldSave({ isDirty })

  useEffect(() => {
    if (Object.keys(responses).length > 0) {
      headlessOnChange ? onStore() : onSaveDebounce()
    }
  }, [responses])

  useEffect(() => {
    if (!shouldSave()) return
    const changedFieldId = findChangedField(values, previousValuesRef.current)

    if (changedFieldId) {
      setResponses(prevState => ({
        ...prevState,
        [changedFieldId]: values[changedFieldId],
      }))
    }

    previousValuesRef.current = { ...values }
  }, [values, previousValuesRef.current])

  useEffect(() => {
    if (!iteration || !section) return
    if (iteration.sectionId !== section.id) return
    if (readOnly) return
    const hasNoCompletableFields = !section?.fields?.some(
      f => !nonCompletableFields.includes(f.type),
    )
    const hasNoRequiredFields = getRequiredFieldsCount(section) === 0
    if (
      (hasNoCompletableFields || hasNoRequiredFields) &&
      !iteration?.completedDate
    ) {
      headlessOnChange
        ? onStore()
        : saveActivity({ section, iteration, forceComplete: true })
    }
  }, [section, iteration])

  const onSave = () => {
    if (readOnly) return
    saveActivity({
      section,
      iteration,
      responses,
      forceComplete: getRequiredFieldsCount(section) === 0,
    })
    setResponses({})
  }

  const onStore = () => {
    headlessOnChange(responses)
    setResponses({})
  }

  const onSaveDebounce = useDebounce(onSave, 1000)

  if (!section) return null

  if (section?.fields?.length) {
    return (
      <AnimatePresence>
        <form className="pb-4 [&_img]:my-4">
          {section?.fields.map((field, i) => {
            return (
              <motion.div
                key={field.id}
                className="bg-white border border-grey-100 shadow-sm rounded-md mb-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ delay: 0.01 * i }}
              >
                <Field
                  key={field.id}
                  field={field}
                  section={section}
                  control={control}
                  iteration={iteration}
                  hasValue={hasValue(values[`${field.version}_${field.id}`])}
                  readOnly={readOnly}
                  uploadRef={uploadRef}
                  suspendUpload={suspendUpload}
                />
              </motion.div>
            )
          })}
        </form>
      </AnimatePresence>
    )
  } else {
    return (
      <EmptyState
        title="There are no fields in this section."
        subtitle="Contact your an admin for more information."
      />
    )
  }
}
