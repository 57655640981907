import { Icon } from '@components/icon.component'
import { Button } from '@components/mantine/button.component'
import { Modal } from '@components/modal.component'
import { Checkbox } from '@components/radix/checkbox'
import React, { useState } from 'react'

type Props = {
  onReject: () => void
  onResolve: (data: {
    selectedVersionBumpFields: Record<string, string[]>
  }) => void
  versionBumpFields: Record<string, string[]>
  sections: ActivitySection[]
  oldSections: ActivitySection[]
}

export function VerifyFieldVersionBumpModal({
  onReject,
  onResolve,
  versionBumpFields,
  oldSections,
  sections,
}: Props) {
  const [selectedVersionBumpFields, setSelectedVersionBumpFields] =
    useState(versionBumpFields)

  function submitFieldsToBump() {
    onResolve({ selectedVersionBumpFields })
  }

  return (
    <>
      <Modal.Header>Import to workspace</Modal.Header>
      <Modal.Body>
        <div className="space-y-4">
          <div>
            The fields below have been updated such that a user's response would
            be irrelevant. Please review the changes and select the fields whose
            responses should be reset for all participants.
          </div>
          {Object.entries(versionBumpFields).map(
            ([sectionId, fieldIds], index) => {
              const section = sections.find(section => section.id === sectionId)
              const oldSection = oldSections.find(
                section => section.id === sectionId,
              )
              return (
                <div key={index}>
                  <h2 className="text-2xl font-semibold">{section?.name}</h2>
                  <div className="space-y-3">
                    {fieldIds.map((fieldId: string, fieldIndex: number) => {
                      const isSelected =
                        selectedVersionBumpFields[sectionId].includes(fieldId)
                      const field = section?.fields.find(
                        field => field.id === fieldId,
                      )
                      const oldField = oldSection?.fields.find(
                        field => field.id === fieldId,
                      )
                      return (
                        <div
                          key={fieldIndex}
                          className={`flex items-center justify-around rounded-md p-3 gap-4 ${isSelected ? 'bg-grey-25' : ''}`}
                        >
                          <Checkbox
                            onCheckedChange={() => {
                              setSelectedVersionBumpFields({
                                ...selectedVersionBumpFields,
                                [sectionId]: isSelected
                                  ? selectedVersionBumpFields[sectionId].filter(
                                      id => id !== fieldId,
                                    )
                                  : [
                                      ...selectedVersionBumpFields[sectionId],
                                      fieldId,
                                    ],
                              })
                            }}
                            checked={isSelected}
                          />

                          <div className="flex items-center gap-4">
                            <div
                              aria-label="Old field label"
                              className="p-3 bg-white border-2 border-grey-100 rounded-md font-semibold"
                            >
                              {oldField.label}
                            </div>
                            <div>
                              <Icon name="angle-right" />
                            </div>
                            <div
                              aria-label="New field label"
                              className="p-3 bg-active-100 rounded-md font-semibold"
                            >
                              {field.label}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            },
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="tertiary"
          onClick={onReject}
        >
          Cancel publish
        </Button>
        <Button
          variant="primary"
          onClick={submitFieldsToBump}
        >
          Proceed
        </Button>
      </Modal.Footer>
    </>
  )
}
