import { useAppState } from '@src/app.state'
import { getUserFullName } from '@utils/helpers'
import { a, k, useCss } from 'kremling'
import { DateTime } from 'luxon'
import React from 'react'

export function MessageItem({ message, user, isAnnouncements }) {
  const scoped = useCss(css)
  const me = useAppState(state => state.user)
  const isMine = message.userId === me.id
  const fullName = getUserFullName(user)
  const [firstName, lastName] = fullName.split(' ')

  function renderMessageTimeStamp() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const convertedDate = DateTime.fromISO(message.createdDate).setZone(
      timeZone,
    )
    const diff = convertedDate.startOf('day').diffNow('days')
    const days = diff.days * -1

    if (days < 1) {
      return `Today ${convertedDate.toFormat('hh:mm a')}`
    } else if (days >= 1 && days < 2) {
      return `Yesterday ${convertedDate.toFormat('hh:mm a')}`
    } else {
      return convertedDate.toFormat('LLL dd hh:mm a')
    }
  }

  return (
    <div
      {...scoped}
      className={a('message__container').m('mine', isMine)}
    >
      {!isMine && user && !isAnnouncements && (
        <div className="avatar__circle">
          {firstName[0]}
          {lastName[0]}
        </div>
      )}
      <div className="message__wrapper">
        <div
          key={message.id}
          className="message"
        >
          <div
            style={{ lineHeight: '18px' }}
            dangerouslySetInnerHTML={{ __html: message.text }}
          />
        </div>
        {isMine ? (
          <div className="message__subtext">
            <span>{renderMessageTimeStamp()}</span>
            <div className="divider" />
            <span className="user-name">You</span>
          </div>
        ) : (
          <div className="message__subtext">
            {!isAnnouncements && (
              <>
                <span className="user-name">{fullName || 'Admin'}</span>
                <div className="divider" />
              </>
            )}

            <span>{renderMessageTimeStamp()}</span>
          </div>
        )}
      </div>
    </div>
  )
}

const css = k`
  .message__container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;

    &.mine {
      justify-content: flex-end;
    }

    .message__wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 8px;
      width: 100%;
    }

    &.mine > .message__wrapper {
      align-items: flex-end;
      margin-left: 0;
    }

    .message {
      max-width: 50%;
      display: inline-block;
      background: $root-background-color;
      border-radius: $base-border-radius;
      padding: 8px 12px;
      border: solid 1px $color-grey-50;
      min-height: 3.4rem;
      min-width: 3.4rem;
      margin-bottom: 4px;
    }

    &.mine > .message__wrapper > .message {
      background: $color-active-150;
      border: none;
    }
  }

  .avatar__circle {
    height: 30px;
    width: 30px;
    padding: 2px;
    display: flex;
    line-height: 12px;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    background: $color-active-100;
    border: solid 2px #fff;
    font-weight: 500;
    margin-top: 4px;
    flex-shrink: 0;
  }

  .message__subtext {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
  }

  .user-name {
    font-weight: 500;
  }

  .divider {
    height: 6px;
    width: 1px;
    background: $color-grey-100;
    margin: 0 5px;
    border-radius: 1px;
  }
`
