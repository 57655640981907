import React, { useMemo } from 'react'

/**
 * Use Highlighter
 * @param {string} text
 * @param {string} search
 * @param {object} overrideStyle
 * @returns {function}
 */
export const useHighlighter = (
  text = '',
  search,
  overrideStyle = {
    color: 'var(--cp-color-app-primary)',
    backgroundColor: 'transparent',
  },
) => {
  return useMemo(() => {
    let sanitizedHighlight
    if (!search || search.trim().length === 0) {
      return <span>{text}</span>
    } else {
      sanitizedHighlight = search.toLowerCase().trim()
    }

    const stop = text.toLowerCase().indexOf(sanitizedHighlight)
    if (stop === -1) return <span>{text}</span>

    const bold = text.slice(stop, stop + sanitizedHighlight.length)
    const start = text.slice(0, stop)
    const end = text.slice(stop + sanitizedHighlight.length, text.length)

    return (
      <span>
        {start}
        <strong style={overrideStyle}>{bold}</strong>
        {end}
      </span>
    )
  }, [search])
}
