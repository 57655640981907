import { Icon } from '@components/icon.component'
import { Loader } from '@components/loader.component'
import { Button } from '@components/mantine/button.component'
import { Pill } from '@components/pill/pill.component'
import { useDebounce } from '@hooks/use-debounce.hook'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db, functions } from '@src/firebase-app'
import { NavLink } from '@src/navigation/nav-link.component'
import { AuthModal } from '@src/pages/account/auth/auth-modal.component'
import { Fields } from '@src/pages/workflow/client/workflow-refactor/fields.component'
import { useActivityStore } from '@src/pages/workflow/client/workflow-refactor/state/use-activity-state'
import {
  FIELD_CONSTANTS,
  nonCompletableFields,
} from '@src/pages/workflow/fields.helper'
import { useHeadlessState } from '@src/pages/workflow/headless/headless-activity.component'
import { useSectionCompletedStatus } from '@src/pages/workflow/headless/use-section-completed-status.hook'
import { activityAccess } from '@src/pages/workflow/settings/activity-settings/share-utils'
import { dbNames } from '@utils/constants'
import {
  getTodayDate,
  getUserFullName,
  normalizePercentage,
} from '@utils/helpers'
import { modalService } from '@utils/modal.service'
import sendUserAnalytics from '@utils/sendUserAnalytics'
import { toastService } from '@utils/toast.service'
import { doc, getDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { a, useCss } from 'kremling'
import { isEmpty, isUndefined } from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { RRule } from 'rrule'
import { ulid } from 'ulid'
import css from '../workflow.kremling.scss'
import { extractFieldValue, sortSections } from './../workflow-utils'
import { ActivityDescriptionModal } from './activity-description-modal.component'

export function WorkflowHeadless({ user, activityId }) {
  const scoped = useCss(css)
  const [showSections, setShowSections] = useState(true)
  const params = useParams()
  const [responses, setResponses] = useState({})
  const navigate = useNavigate()
  const [working, setWorking] = useState(false)
  const uploadRef = useRef(null)

  const [activity, _setActivity, activityOpts] = useLoad(
    {},
    () => {
      return getDoc(doc(db, dbNames.activities, activityId)).then(w => {
        return { ...w.data(), id: w.id }
      })
    },
    [activityId],
  )

  const [invitation] = useLoad(
    {},
    () => {
      return getDoc(doc(db, dbNames.invitations, activityId)).then(w => {
        return { ...w.data(), id: w.id }
      })
    },
    [activityId],
  )

  // const [sections, _setSections, sectionsOpts] = useLoad(
  //   [],
  //   async () => {
  //     const q = query(
  //       collection(db, dbNames.activitySections),
  //       where('activityId', '==', activityId),
  //     )
  //     return await getDocs(q).then(q =>
  //       q.docs.map(doc => ({ ...doc.data(), id: doc.id })),
  //     )
  //   },
  //   [activityId],
  // )

  useEffect(() => {
    if (!activity) return
    if (!activity.description) return
    modalService.render(ActivityDescriptionModal, { activity })
  }, [activity])

  const sortedSections = useMemo(
    () => sortSections(sections, activity),
    [sections, activity],
  )
  const completedDate = activity?.completedDate
    ? DateTime.fromISO(activity.completedDate)
    : null
  const selectedSection = useMemo(() => {
    const sectionId = params['*']
    if (sectionId) return sections.find(s => s.sectionId === sectionId)
    if (!isEmpty(sortedSections)) return sortedSections[0]
  }, [params, sections, sortedSections])

  const { completedMap } = useSectionCompletedStatus({
    sections,
    responses,
    selectedSectionId: params['*'],
  })

  useEffect(() => {
    if (isEmpty(sortedSections)) return
    useActivityStore.setState({ sections: sortedSections })
  }, [sortedSections])

  useEffect(() => {
    if (!selectedSection) return
    const { fields, constraints } = selectedSection
    const completedPercentage = completedMap[selectedSection.sectionId]

    if (completedPercentage < 100 || isUndefined(completedPercentage)) {
      const hasNoRequiredFields = !constraints.required
      const hasNoCompletableFields = isEmpty(
        fields.filter(({ type }) => !nonCompletableFields.includes(type)),
      )

      if (isEmpty(fields)) {
        return setResponses(prevState => {
          return {
            ...prevState,
            [selectedSection.sectionId]: {
              [ulid()]: { value: true, type: 'empty' },
            },
          }
        })
      }

      if (hasNoRequiredFields || hasNoCompletableFields) {
        setResponses(prevState => {
          return {
            ...prevState,
            [selectedSection.sectionId]: selectedSection.fields.reduce(
              (accum, field) => {
                return {
                  ...accum,
                  [field.id]: { value: true, type: field.type },
                }
              },
              {},
            ),
          }
        })
      }
    }
  }, [selectedSection])

  function toggleSections() {
    const mobileView = window.matchMedia('(max-width: 896px)').matches
    if (mobileView) {
      setShowSections(prevShowSections => !prevShowSections)
    }
  }

  function getSectionCompletedPercent(activitySections) {
    return activitySections.reduce((acc, section) => {
      if (section.constraints.recurrence) {
        const options = RRule.parseString(section.constraints.recurrence)
        options.dtstart = DateTime.fromISO(activity.startDate.date, {
          zone: activity.startDate.timeZone,
        })
          .startOf('day')
          .toJSDate()
        options.until = DateTime.fromISO(activity.endDate.date, {
          zone: activity.endDate.timeZone,
        })
          .endOf('day')
          .toJSDate()
        const rule = new RRule(options)
        const allIterations = rule.all()
        const totalIterationCount = allIterations.length
        acc[section.sectionId] = normalizePercentage(
          (1 / totalIterationCount) * 100,
        )

        return acc
      }
      acc[section.sectionId] = completedMap[section.sectionId]
      return acc
    }, {})
  }

  function getFieldsWithFileUpload(activitySections) {
    return activitySections.reduce((acc, section) => {
      section.fields.forEach(field => {
        if (field.type === FIELD_CONSTANTS.FILE_UPLOAD) {
          if (acc[section.sectionId]) {
            acc[section.sectionId].push(field.id)
          } else {
            acc[section.sectionId] = [field.id]
          }
        }
      })
      return acc
    }, {})
  }

  async function reconcileFileUploads(activitySections, data) {
    const fileUploadFields = getFieldsWithFileUpload(activitySections)

    if (!isEmpty(fileUploadFields)) {
      const filesToUploadMap = {}
      for (const sectionId of Object.keys(fileUploadFields)) {
        const fieldIds = fileUploadFields[sectionId]
        for (const fieldId of fieldIds) {
          const filesResponse = responses[sectionId][fieldId]

          if (filesResponse) {
            const iterationId = data.sectionsMap[sectionId].at(-1)
            const { activities, activitySections, sectionIterations } = dbNames
            const activityPath = `${activities}/${activityId}`
            const sectionPath = `${activitySections}/${sectionId}`
            const iterationPath = `${sectionIterations}/${iterationId}`
            const fieldIdPath = `fields/${fieldId}`
            const uploadToPath = `${activityPath}/${sectionPath}/${iterationPath}/${fieldIdPath}`
            const uploaded = await uploadRef.current?.doUpload({
              user: data.user,
              orgId: activity.orgId,
              uploadToPath,
            })

            if (!filesToUploadMap[sectionId]) {
              filesToUploadMap[sectionId] = {}
            }

            if (!filesToUploadMap[sectionId][fieldId]) {
              filesToUploadMap[sectionId][fieldId] = []
            }

            const fieldValue = filesToUploadMap[sectionId][fieldId]
            filesToUploadMap[sectionId][fieldId] = [...fieldValue, ...uploaded]
          }
        }
      }
      await httpsCallable(
        functions,
        'activityFunctions-reconcileUploadsToIterations',
      )(filesToUploadMap)
    }
  }

  // async function onRegisterGuest(email) {
  //   const completedIterationMap = getSectionCompletedPercent(sections)
  //   const _responses = convertResponsesToBeSaved(responses)
  //   useHeadlessState.setState({ loading: true })
  //   //TODO this needs to be converted to use template instead of sections
  //   try {
  //     const auth = getAuth()
  //     const { user } = await signInAnonymously(auth)
  //
  //     const payload = {
  //       email,
  //       activity,
  //       responses: _responses,
  //       sections,
  //       anonymousId: user.uid,
  //       emailVerified: true,
  //       userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  //       linkId: params.linkId,
  //       completedIterationMap,
  //     }
  //     const res = await httpsCallable(
  //       functions,
  //       'userFunctions-registerGuest',
  //     )(payload)
  //     await reconcileFileUploads(sections, res.data)
  //     if (res.data) {
  //       if (res.data.reAuthToken) {
  //         await signInWithCustomToken(auth, res.data.reAuthToken)
  //       }
  //       await sendAnalytics(res.data)
  //     }
  //     navigate(`/activity-register-success/${res.data.editToken}`, {
  //       state: { activityId: activity.id, userId: user.uid },
  //     })
  //   } catch (err) {
  //     if (err.code === 'functions/already-exists') {
  //       toastService.error(
  //         'This email is already registered. Please login to continue.',
  //       )
  //     } else {
  //       console.dir(err)
  //     }
  //   }
  // }

  async function sendAnalytics({
    sectionsMap,
    user,
    activityCompletedPercentage,
  }) {
    sections.forEach(section => {
      const iterationId = sectionsMap[section.sectionId].at(-1).id
      let firstName = '',
        lastName = ''
      const fullName = getUserFullName(user)
      if (fullName) {
        firstName = fullName.split(' ')[0] || ''
        lastName = fullName.split(' ')[1] || ''
      }
      section.fields.forEach(field => {
        sendUserAnalytics.updateEvent(`${section.sectionId}_${field.id}`, {
          iterationId,
          userId: user.id,
          firstName,
          lastName,
          activityCompletedPercentage,
        })
      })
    })
    await sendUserAnalytics.sendEvents()
  }

  function convertResponsesToBeSaved(fieldResponses) {
    return Object.entries(fieldResponses).reduce(
      (accum, [sectionId, fields]) => {
        return {
          ...accum,
          [sectionId]: Object.entries(fields).reduce(
            (fieldAccum, [fieldId, response]) => {
              if (response.type === FIELD_CONSTANTS.FILE_UPLOAD) {
                return {
                  ...fieldAccum,
                  //Don't save the placeholder values for files
                  [fieldId]: [],
                }
              }
              if (!nonCompletableFields.includes(response.type)) {
                return {
                  ...fieldAccum,
                  [fieldId]: response.value,
                }
              }
              return fieldAccum
            },
            {},
          ),
        }
      },
      {},
    )
  }

  async function onAuth(user) {
    useAppState.setState({ appLoading: true })
    const today = getTodayDate().toISO()
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const completedIterationMap = getSectionCompletedPercent(sections)
    const _responses = convertResponsesToBeSaved(responses)
    try {
      const res = await httpsCallable(
        functions,
        'userFunctions-addUsersToActivity',
      )({
        activityId: activity.id,
        userIds: [user.uid],
        sections,
        startDate: activity.startDate,
        endDate: activity.endDate,
        today,
        groupId: 'linkInvite',
        orgId: activity.orgId,
        userTimeZone,
        responses: _responses,
        completedIterationMap,
      })

      await reconcileFileUploads(sections, res.data)
      await sendAnalytics(res.data)
      navigate(`/${activity.orgId}/home/${user.uid}/${activity.id}`)
    } catch (err) {
      console.log(err)
    }
  }

  async function onRegisterAccount(user) {
    useHeadlessState.setState({ loading: true })
    const today = getTodayDate().toISO()
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const completedIterationMap = getSectionCompletedPercent(sections)
    const _responses = convertResponsesToBeSaved(responses)
    try {
      const res = await httpsCallable(
        functions,
        'userFunctions-addUsersToActivity',
      )({
        activityId: activity.id,
        userIds: [user.uid],
        sections,
        startDate: activity.startDate,
        endDate: activity.endDate,
        today,
        groupId: 'linkInvite',
        orgId: activity.orgId,
        userTimeZone,
        responses: _responses,
        completedIterationMap,
      })

      await reconcileFileUploads(sections, res.data)
      await sendAnalytics(res.data)

      useHeadlessState.setState({ loading: false })
      //TODO - update this
      navigate('/dashboard')
    } catch (err) {
      console.log(err)
    }
  }

  async function handleAccountModal() {
    if (!isEmpty(user)) {
      setWorking(true)
      const completedIterationMap = getSectionCompletedPercent(sections)
      const today = getTodayDate().toISO()
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const _responses = convertResponsesToBeSaved(responses)
      try {
        await httpsCallable(
          functions,
          'userFunctions-addUsersToActivity',
        )({
          activityId: activity.id,
          userIds: [user.id],
          sections,
          startDate: activity.startDate,
          endDate: activity.endDate,
          today,
          groupId: 'linkInvite',
          orgId: activity.orgId,
          userTimeZone,
          responses: _responses,
          completedIterationMap,
        })
        if (!user.isAnonymous) {
          navigate(`/${activity.orgId}/home/${user.id}/${activity.id}`)
        }
      } catch (err) {
        setWorking(false)
        toastService.error('An issue occurred while saving. Please try again.')
      }
    } else {
      const { type, user } = await modalService.render(AuthModal, {
        allowGuest: activityAccess(invitation.accessType, 'allow-anonymous'),
        allowLogin: true,
      })

      switch (type) {
        case 'register':
          await onRegisterAccount(user)
          break
        case 'login':
          await onAuth(user)
          break
        case 'guest':
        // await onRegisterGuest(user.email)
        // break
      }
    }
  }

  function handleUpdateFields(fieldsValues) {
    const _responses = { ...responses }
    if (!_responses[selectedSection.sectionId])
      _responses[selectedSection.sectionId] = {}

    for (const [fieldId, value] of Object.entries(fieldsValues)) {
      const field = selectedSection.fields.find(f => f.id === fieldId)

      _responses[selectedSection.sectionId][field.id] = {
        value,
        type: field.type,
      }

      const { stringValue, arrayValue, actionCompleted } = extractFieldValue(
        field,
        value,
      )
      const sectionCompletedPercentage = getSectionCompletedPercent([
        selectedSection,
      ])[selectedSection.sectionId]
      const eventId = `${selectedSection.sectionId}_${field.id}`
      const today = getTodayDate().toISO()

      const existingEvent = sendUserAnalytics.getEvents([eventId])

      if (!isEmpty(existingEvent)) {
        sendUserAnalytics.updateEvent(
          {
            value: stringValue,
            choices: arrayValue,
            unit: value?.unit,
            textType: field.textType,
            userId: user?.id,
            activity,
            sectionId: selectedSection,
            originTemplateId: activity.templateId,
            fieldId: field.id,
            groupId: activity.groupId,
            sectionCompletedPercentage,
            actionCompleted,
          },
          eventId,
        )
      } else {
        sendUserAnalytics.registerEvent(
          {
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            value: stringValue,
            choices: arrayValue,
            fieldType: field.type,
            unit: value?.unit,
            textType: field.textType,
            userId: user?.id,
            activity,
            sectionId: selectedSection,
            iterationCreatedDate: today,
            iterationCompletedPercentage: 100,
            originTemplateId: activity.templateId,
            fieldId: field.id,
            groupId: activity.groupId,
            orgId: activity.orgId,
            createdDate: today,
            sectionCompletedPercentage,
            actionCompleted,
          },
          eventId,
        )
      }
    }

    setResponses(_responses)
  }

  const handleUpdateFieldsDebounced = useDebounce(handleUpdateFields, 500)

  const touchedSectionsCount = !!Object.values(completedMap).some(
    val => val > 0,
  )

  const incompleteSectionsCount = !!Object.values(completedMap).filter(
    percent => percent < 100,
  ).length

  return (
    <div
      {...scoped}
      className="workflow-container"
    >
      <div className="workflow-header">
        <div className="header-section">
          <div className="workflow-header__info">
            <div>
              <div className="workflow-header__title ml-4">
                {activity.name || 'Untitled Workflow'}
              </div>
              {activity.completedDate && (
                <div className="workflow-completed-date">
                  Completed {completedDate.toLocaleString(DateTime.DATE_MED)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={a('workflow-content').m(
          'workflow-content--fields',
          selectedSection,
        )}
      >
        <div
          style={{ display: showSections ? 'block' : 'none' }}
          className="workflow-sections workflow-client"
        >
          <div className="workflow-nav__container">
            <div className="sections-list__container">
              <div className="sections-list__header">
                <div className="item-title__container">
                  <Icon
                    className="mr-2"
                    name="list-radio"
                  />
                  <span>Sections</span>
                </div>
              </div>
              <div className="sections-list">
                {!isEmpty(sortedSections) &&
                  sortedSections.map(section => {
                    return (
                      <NavLink
                        to={section.sectionId}
                        key={section.sectionId}
                        activeClassName="--active"
                        className="section-nav__item"
                        onClick={() => {
                          toggleSections()
                        }}
                      >
                        <div
                          className="flex-ellipsis mr-4"
                          style={{ display: 'flex' }}
                          title={section.name}
                        >
                          <div>{section.name || 'Untitled Section'}</div>
                          {!section.constraints.required && (
                            <span className="optional-section ml-2">
                              (Optional)
                            </span>
                          )}
                        </div>
                        <div className="section-nav__progress">
                          {completedMap[section.sectionId] === 100 ? (
                            <Icon
                              name="check"
                              fill="#42c873"
                              size={16}
                            />
                          ) : (
                            <Pill
                              pillType={
                                selectedSection.sectionId === section.sectionId
                                  ? 'primary'
                                  : ''
                              }
                            >
                              {`${completedMap[section.sectionId] || 0}%`}
                            </Pill>
                          )}
                        </div>
                      </NavLink>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
        {activityOpts.loading ||
        sectionsOpts.loading ||
        !selectedSection ||
        useHeadlessState.getState().loading ? (
          <Loader overlay />
        ) : (
          <Routes>
            <Route
              path=":sectionId"
              element={
                <div className="section-container">
                  <div className="section-header">
                    <div className="section-header__left">
                      <Button
                        variant="secondary"
                        onClick={toggleSections}
                        className="media-hide-3"
                      >
                        <Icon
                          name="list-radio"
                          size={14}
                        />
                      </Button>
                    </div>
                    <div className="section-actions client">
                      {
                        <Button
                          onClick={handleAccountModal}
                          variant="primary"
                          disabled={!touchedSectionsCount}
                          loading={working}
                        >
                          {incompleteSectionsCount > 0
                            ? 'Save progress'
                            : 'Submit'}
                        </Button>
                      }
                    </div>
                  </div>
                  <div className="flex justify-center pt-10 overflow-auto">
                    <div className="w-full max-w-[70rem]">
                      <Fields
                        section={selectedSection}
                        headless
                        uploadRef={uploadRef}
                        suspendUpload
                        headlessOnChange={handleUpdateFieldsDebounced}
                        iteration={{
                          fieldResponses: responses[selectedSection.sectionId]
                            ? Object.entries(
                                responses[selectedSection.sectionId],
                              ).reduce((accum, [key, response]) => {
                                if (
                                  !nonCompletableFields.includes(response.type)
                                ) {
                                  return {
                                    ...accum,
                                    [key]: response.value,
                                  }
                                }
                                return accum
                              }, {})
                            : {},
                        }}
                      />
                    </div>
                  </div>
                </div>
              }
            />
            <Route
              path=""
              element={<Navigate to={sortedSections[0].sectionId} />}
            />
          </Routes>
        )}
      </div>
    </div>
  )
}
