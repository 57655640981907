import { k, useCss } from 'kremling'
import React, { useState } from 'react'

import { Icon } from '@components/icon.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Switch } from '@components/radix/switch'
import { RichText } from '@components/rich-text/rich-text.component'
import { Select } from '@components/select.component'
import { Tooltip } from '@components/tooltip.component'
import { useAppState } from '@src/app.state'
import {
  TEXT_TYPE_CONSTANTS,
  textTypeOptions,
} from '@src/pages/workflow/fields.helper'
import { useParams } from 'react-router-dom'

export function FieldText({
  field,
  onChange,
  updateRequiredToggle,
  isRequired,
}) {
  const scope = useCss(css)
  const { orgId } = useParams()
  const textTypeOption = textTypeOptions.find(
    option => option.value === field.textType,
  )
  const [fieldType, setFieldType] = useState(
    textTypeOption || textTypeOptions[0],
  )
  const isSimpleNumber = fieldType.value === TEXT_TYPE_CONSTANTS.NUMBER

  function updateFieldLabel(value) {
    if (value === field.label) return
    const updatedField = {
      ...field,
      label: value,
    }
    onChange(updatedField, true)
  }

  function updateFieldDescription(value) {
    const updatedField = {
      ...field,
      description: value,
    }
    onChange(updatedField)
  }

  function updateTextType(type) {
    const updatedField = {
      ...field,
      textType: type.value,
      unit: '',
    }

    onChange(updatedField)
    setFieldType(type)
  }

  function updateDataLabel(value) {
    const updatedField = {
      ...field,
      dataLabel: value,
    }
    onChange(updatedField, true)
  }

  return (
    <div
      {...scope}
      className="space-y-6"
    >
      <div>
        <div className="flex items-center">
          <Switch
            id={`required-switch-${field.id}`}
            onChange={value => updateRequiredToggle(value, field.id)}
            className="mr-2"
            value={isRequired}
          />
          <label htmlFor={`required-switch-${field.id}`}>Required</label>
        </div>
      </div>
      <div className="form-group">
        <TextInput
          label="Name the field"
          defaultValue={field.label}
          onChange={updateFieldLabel}
          placeholder="e.g. How was your day?"
          autoFocus
        />
      </div>
      <div className="form-group">
        <label htmlFor="text-field-desc">Describe this field</label>
        <RichText
          id="text-field-desc"
          onChange={updateFieldDescription}
          initialValue={field ? field.description : null}
          placeholder="Type here..."
          uploadOptions={{
            user: useAppState.getState().user,
            orgId,
          }}
        />
      </div>
      <div className="form-group">
        <label htmlFor="text-type">Select a type of text</label>
        <Select
          id="text-type"
          options={textTypeOptions}
          onChange={updateTextType}
          defaultValue={textTypeOption}
          isSearchable={false}
        />
      </div>
      {fieldType.value === 'number' && (
        <div className="row break-1">
          <div className="col-6">
            <div
              className="form-group"
              style={{ opacity: !isSimpleNumber ? '.4' : '1' }}
            >
              <TextInput
                label={
                  <span className="data-label__label">
                    Data label (optional)
                    <Tooltip
                      content="If left blank, the field label will be used"
                      placement="bottom"
                      disabled={!isSimpleNumber}
                    >
                      <Icon
                        name="info"
                        size={15}
                        className="ml-2"
                      />
                    </Tooltip>
                  </span>
                }
                placeholder="e.g. Daily steps"
                defaultValue={field.dataLabel}
                onChange={updateDataLabel}
                disabled={!isSimpleNumber}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

FieldText.propTypes = {}

const css = k`
  .data-label__label {
    display: flex;
    align-items: center;
  }
`
