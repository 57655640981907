import { $generateNodesFromDOM } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getRoot } from 'lexical'
import { useEffect } from 'react'

type InitialValuePluginProps = {
  initialValue?: string
}

export function InitialValuePlugin({
  initialValue,
}: InitialValuePluginProps): null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (initialValue) {
      editor.update(() => {
        const parser = new DOMParser()
        const dom = parser.parseFromString(initialValue, 'text/html')
        const nodes = $generateNodesFromDOM(editor, dom)

        const root = $getRoot()
        root.clear()
        root.append(...nodes)
      })
    }
  }, [])

  return null
}
