import React from 'react'
import { SimpleEditor } from '@components/rich-text/simple-editor/simple-editor.component'
import { stateFields } from '@src/pages/workflow-template-list/template-generator/states/state-fields'

export function useGetStateField(fieldId, initialValue, action) {
  const field = stateFields[fieldId]
  switch (field.type) {
    case 'longText':
      return (
        <SimpleEditor
          initialValue={initialValue}
          placeholder={field.placeholder}
          label={field.label}
          onChange={action}
        />
      )
    default:
      return null
  }
}
