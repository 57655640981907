import React, { useState } from 'react'
import { useCss, k } from 'kremling'
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth'

import { Card } from '@components/card.component'
import { auth } from '@src/firebase-app'
import { toastService } from '@utils/toast.service'
import { ReAuthForm } from '@src/pages/account/profile/forms/re-auth-form'
import { NewEmailForm } from '@src/pages/account/profile/forms/new-email-form'
import { NewPasswordForm } from '@src/pages/account/profile/forms/new-password-form'

export function EditAccount({ handleUpdate }) {
  const scope = useCss(css)
  const [reauthenticated, setReauthenticated] = useState('')
  const [password, setPassword] = useState('')

  async function setReauth(email, password) {
    const credential = EmailAuthProvider.credential(email, password)
    try {
      await reauthenticateWithCredential(auth.currentUser, credential)
      setReauthenticated(true)
      setPassword(password)
    } catch {
      toastService.error('The password is not correct')
    }
  }

  return (
    <Card
      {...scope}
      className="account-card"
    >
      <div className="account-content">
        <div className="password-update mt-5">
          <NewPasswordForm />
        </div>
        <div className="email-update">
          <NewEmailForm
            handleUpdate={handleUpdate}
            setReauth={setReauth}
            password={password}
          />
        </div>
        {!reauthenticated && (
          <div className="password-overlay">
            <div className="overlay-content card">
              <h3 className="mt-5">Edit email & password</h3>
              <div className="mt-5 info-text">
                To edit your account information, please input your current
                password
              </div>
              <ReAuthForm
                setReauthenticated={setReauthenticated}
                setReauth={setReauth}
              />
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}

const css = k`
  .account-card {
    overflow: hidden;
    position: relative;
    padding: 2.4rem;
  }

  .overlay-content {
    padding: 2rem;
  }

  .password-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    padding: 8rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(240, 240, 240, 0.5);
    backdrop-filter: blur(8px);
  }

  .password-update {
    padding-bottom: 2.4rem;
    margin-bottom: 2.4rem;
    border-bottom: solid 1px $color-grey-100;
  }

  .password-label {
    display: flex;
    justify-content: space-between;
  }

  .password-label > a {
    flex-shrink: 0;
  }
`
