import { k, useCss } from 'kremling'
import { DateTime } from 'luxon'
import React, { useMemo } from 'react'

import { CardListItem } from '@components/card-list-item.component'
import { Icon } from '@components/icon.component'
import { WorkflowProgressBar } from '@components/workflow-progress-bar.component'
import { functions } from '@src/firebase-app'
import { getTodayDate, getUserFullName } from '@utils/helpers'
import { toastService } from '@utils/toast.service'
import { httpsCallable } from 'firebase/functions'
import { useParams } from 'react-router-dom'

export function WorkflowProgressCardInner({
  activity,
  activityUser,
  subTitle = '',
  ...cardProps
}) {
  const scoped = useCss(css)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const today = useMemo(() => getTodayDate(), [])
  const { orgId } = useParams()

  const convertedStartDate = useMemo(
    () =>
      DateTime.fromISO(activity.startDate.date, {
        zone: activity.startDate.timeZone,
      }).setZone(timeZone),
    [timeZone],
  )
  const convertedEndDate = useMemo(() => {
    return activity.endDate
      ? DateTime.fromISO(activity.endDate.date, {
          zone: activity.endDate.timeZone,
        }).setZone(timeZone)
      : null
  }, [timeZone])

  const isBeforeStartDate =
    convertedStartDate.toUTC().toISO() > today.toUTC().toISO()
  const isPastEndDate = convertedEndDate
    ? convertedEndDate.plus({ days: 1 }).toUTC().toISO() <=
      today.toUTC().toISO()
    : false

  return (
    <CardListItem
      {...cardProps}
      cardTitle={getUserFullName(activityUser)}
      subTitle={subTitle}
      icon="ballot"
      renderRightContent={() => {
        if (isBeforeStartDate) {
          return `Begins ${convertedStartDate.toFormat('LLL dd')}`
        }

        if (activity.isSequential) {
          return (
            <div className="text-4xl font-bold">
              {activityUser?.completedPercentage}%
            </div>
          )
        }

        if (isPastEndDate) {
          return (
            <div
              {...scoped}
              className="completed-container"
            >
              <span className="media-show-1">
                {convertedEndDate.toFormat('LLL dd')}
              </span>
              <Icon
                className="ml-3"
                name="check-circle-regular"
                fill="#42c873"
                size={16}
              />
            </div>
          )
        }

        return (
          <div
            {...scoped}
            className="client-workflow-progress media-show-1"
          >
            <WorkflowProgressBar
              activity={activity}
              activityUser={activityUser}
            />
          </div>
        )
      }}
      renderActionsContent={({ close }) => {
        return (
          <div className="select-list">
            <button
              onClick={() => {
                httpsCallable(
                  functions,
                  'activityFunctions-sendNudge',
                )({
                  usersData: [
                    {
                      id: activityUser.userId,
                      email: activityUser.userEmail,
                      firstName: activityUser.userFirstName,
                      lastName: activityUser.userLastName,
                      redirectPath: `${orgId}/mentoring/${activityUser.userId}/${activity.id}`,
                    },
                  ],
                  activityName: activity.name,
                }).then(() => {
                  toastService.info('Nudge sent')
                })
                close()
              }}
            >
              Send nudge
            </button>
          </div>
        )
      }}
    />
  )
}

const css = k`
  .client-workflow-progress {
    min-width: 260px;
  }

  .completed-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`
