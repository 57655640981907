import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { Button } from '@components/mantine/button.component'
import { useAppState } from '@src/app.state'
import { useMessagingState } from '@src/pages/messaging/messaging-store'
import { motion } from 'framer-motion'
import { a, k, useCss } from 'kremling'
import { DateTime } from 'luxon'
import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { renderRoomTitle } from './messaging.utils'

export function ChatRooms({
  className = '',
  rooms = [],
  onSelect,
  onStartRoom,
  participants = [],
  toggleRooms,
  hideHeader = false,
  onCreateAdminRoom,
}) {
  const scope = useCss(css)
  const user = useAppState(state => state.user)
  const { orgId } = useParams()
  const isAdmin = ['owner', 'manager', 'mentor'].includes(user?.orgRoles[orgId])
  const roomItemRefs = useRef({})
  const selectedRoom = useMessagingState(state => state.selectedRoom)
  const addingNewRoom = useMessagingState(state => state.addingNewRoom)

  useEffect(() => {
    if (!selectedRoom && !addingNewRoom) {
      useMessagingState.setState({ selectedRoom: rooms[0] })
    }
  }, [selectedRoom, rooms, addingNewRoom])

  function getLatestMessageTimeStamp(date) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const convertedDate = DateTime.fromISO(date).setZone(timeZone)
    const diff = convertedDate.diffNow('hours')
    const hours = diff.hours * -1

    if (hours < 0.5) {
      return 'Just now'
    }
    if (hours < 24) {
      return convertedDate.toFormat('hh:mm a')
    } else if (hours >= 24 && hours < 48) {
      return 'Yesterday'
    } else {
      return convertedDate.toFormat('LLL dd')
    }
  }

  function scrollToView(roomId) {
    const roomItem = roomItemRefs.current[roomId]
    if (!roomItem) return
    roomItem.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }

  const adminConversation = rooms.find(room => room.userIds.includes('admin'))
  const hasAdminConversation = !!adminConversation && !isAdmin

  return (
    <div
      {...scope}
      className={a('room-list__container').m(className, !!className)}
    >
      {!hideHeader && (
        <div className="room-list__header">
          <div className="search__input media-show-3"></div>
          <ActionIcon
            size="sm"
            className="media-hide-3"
            icon="angle-left"
            onClick={toggleRooms}
          />
          <ActionIcon
            size="sm"
            icon="plus"
            onClick={() => {
              scrollToView('new')
              onStartRoom()
              toggleRooms()
            }}
            className="ml-2 media-hide-3"
            variant="secondary"
          />
          {isAdmin ? (
            <Button
              onClick={() => {
                scrollToView('new')
                onStartRoom()
              }}
              className="ml-2 media-show-3"
              variant="secondary"
            >
              New
            </Button>
          ) : (
            <Button
              onClick={() => {
                scrollToView('new')
                if (hasAdminConversation) {
                  useMessagingState.setState({
                    selectedRoom: adminConversation,
                  })
                } else {
                  onCreateAdminRoom({
                    userId: user.id,
                    userFirstName: user.firstName,
                    userLastName: user.lastName,
                    userEmail: user.email,
                  })
                }
              }}
              className="ml-2 media-show-3"
              variant="secondary"
            >
              Contact admin
            </Button>
          )}
        </div>
      )}
      <div className="room-list__body">
        {addingNewRoom && (
          <div className={a('room__item last-mb-0').m('active', addingNewRoom)}>
            <div className="room__header">
              <div className="room__title flex-ellipsis">New conversation</div>
            </div>
          </div>
        )}
        {rooms.map(room => {
          const roomParticipants = participants.filter(
            ({ roomId }) => roomId === room.id,
          )
          return (
            <motion.button
              onClick={() => onSelect(room)}
              key={room?.id}
              layout="position"
              transition={{
                layout: { duration: 0.2 },
              }}
              className={a('room__item last-mb-0').m(
                'active',
                room.id === selectedRoom?.id,
              )}
            >
              <div className="room__header">
                {room.name === 'Announcements' ? (
                  <div className="room__title flex items-center gap-4 flex-ellipsis">
                    <Icon name="megaphone" /> Announcements
                  </div>
                ) : (
                  <div className="room__title flex-ellipsis">
                    {room.name ||
                      renderRoomTitle(room, roomParticipants, user.id, isAdmin)}
                  </div>
                )}
                {room.latestMessage && (
                  <div className="time-stamp">
                    {getLatestMessageTimeStamp(room.latestMessage.createdDate)}
                  </div>
                )}
              </div>
              {room.latestMessage && (
                <div
                  className="room__footer"
                  dangerouslySetInnerHTML={{ __html: room.latestMessage.text }}
                />
              )}
            </motion.button>
          )
        })}
      </div>
    </div>
  )
}

const css = k`
  .room-list__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .room-list__header {
    display: flex;
    margin-bottom: 1.2rem;
    padding: 0 1.2rem;
    justify-content: space-between;
  }

  .search__input {
    width: 100%;
  }

  .room-list__body {
    display: flex;
    flex-direction: column;
    flex: 1 1 1px;
    overflow-y: auto;
    align-items: center;
    padding: .2rem 1.2rem;
  }

  .room__item {
    all: unset;
    box-sizing: border-box;
    width: 100%;
    background: $color-grey-25;
    border-radius: $base-border-radius;
    padding: 8px;
    cursor: pointer;

    &.active {
      outline: solid 2px $color-primary;

      .room__title {
        color: $color-primary;
      }
    }
  }

  .room__item:not(:last-child) {
    margin-bottom: 12px;
  }

  .room__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .time-stamp {
    font-size: 1.1rem;
  }

  .room__title {
    font-size: 1.6rem;
    font-weight: 500;
    margin-right: 8px;
  }

  .room__footer {
    margin-top: 4px;
    font-weight: 300;
    max-height: 20px;
    overflow: hidden;
  }

  @media (min-width: $media3-width) {
    .room-list__header {
      padding: 0;
    }

    .room-list__body {
      padding: 2px;
    }
  }
`
