import { a, k, m, useCss } from 'kremling'
import { bool, number, string } from 'prop-types'
import React from 'react'

export const Badge = ({
  count,
  className,
  showBadge,
  size = 20,
  pulse = false,
}) => {
  const scope = useCss(badgeCss)

  if (!count && !showBadge) return null

  if (!count) {
    return (
      <div
        {...scope}
        className={m('badge-pulse', pulse).a('badge-small').a(className)}
      />
    )
  }

  const displayCount = count > 9 ? '9+' : count
  return (
    <div
      {...scope}
      className={m('badge-pulse', pulse).a('badge-large').a(className)}
      style={{ width: size, height: size }}
    >
      {displayCount}
    </div>
  )
}

export const SmallBadge = ({ className, showBadge }) => {
  const scope = useCss(badgeCss)

  if (!showBadge) return null

  return (
    <div
      {...scope}
      className={a('badge-small').a(className)}
    />
  )
}

Badge.propTypes = {
  className: string,
  count: number,
  showBadge: bool,
}

const badgeCss = k`
  .badge {
    position: absolute;
  }

  .badge-small {
    position: absolute;
    border-radius: 100vh;
    background: rgba($color-danger, 1);
    height: 12px;
    width: 12px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    top: 0;
    left: 0;
  }

  .badge-large {
    background: rgba($color-danger, 1);
    height: 20px;
    width: 20px;
    position: absolute;
    color: white;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    top: 0;
    right: 0;
  }

  .badge-pulse {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulseTheCircleRed 2s 3;
  }
`

export const BadgeWrapper = ({
  children,
  count,
  badgeClassName,
  className,
  showBadge,
}) => {
  const scope = useCss(badgeWrapperCss)

  if (!Number.isNaN(count) && count > 0) {
    return (
      <div
        {...scope}
        className={a('badge-wrapper').a(className)}
      >
        {children}
        <Badge
          count={count}
          className={badgeClassName}
          showBadge={showBadge}
        />
      </div>
    )
  }

  if (showBadge) {
    return (
      <div
        {...scope}
        className={a('badge-wrapper').a(className)}
      >
        {children}
        <SmallBadge
          showBadge={showBadge}
          className={badgeClassName}
        />
      </div>
    )
  }

  return children
}

BadgeWrapper.propTypes = {
  className: string,
  badgeClassName: string,
  count: number,
  showBadge: bool,
}

const badgeWrapperCss = k`
  .badge-wrapper {
    position: relative;
  }
`
