import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  KeyboardEvent,
} from 'react'
import { useCss, k } from 'kremling'
import { Icon } from '@components/icon.component'
import { useDebounce } from '@hooks/use-debounce.hook'
import { Card } from '@components/card.component'
import { isEmpty } from 'lodash'

export interface TagInputProps {
  suggestions?: string[]
  onTagsChange?: (tags: string[]) => void
  tags: string[]
  onInputChange: (text: string) => void
}

export const Tags: React.FC<TagInputProps> = ({
  tags = [],
  suggestions = [],
  onTagsChange,
  onInputChange,
}) => {
  const [inputValue, setInputValue] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)
  const suggestionRefs = useRef<HTMLButtonElement[]>([])
  const scope = useCss(css)

  const filteredSuggestions = useMemo(
    () =>
      suggestions.filter(
        suggestion =>
          !tags.includes(suggestion) &&
          suggestion.toLowerCase().startsWith(inputValue.toLowerCase()),
      ),
    [inputValue, tags, suggestions],
  )

  useEffect(() => {
    suggestionRefs.current = []
  }, [filteredSuggestions])

  useEffect(() => {
    onTagsChange && onTagsChange(tags)
  }, [tags, onTagsChange])

  const onInputChangeDebounce = useDebounce(onInputChange, 500, [])

  const addTag = (tag: string) => {
    if (!tags.includes(tag) && tag.trim() !== '') {
      onTagsChange([...tags, tag])
      setInputValue('')
    }
  }

  const removeTag = (index: number) => {
    onTagsChange([...tags.slice(0, index), ...tags.slice(index + 1)])
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    setInputValue(val)
    onInputChangeDebounce(val)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault()
      addTag(inputValue.trim())
    }
    // else if (e.key === 'ArrowDown') {
    //   e.preventDefault();
    //   if (filteredSuggestions.length > 0) {
    //     inputRef.current?.blur();
    //     suggestionRefs.current[0]?.focus();
    //   }
    // }
    else if (e.key === 'Backspace' && inputValue === '') {
      e.preventDefault()
      removeTag(tags.length - 1)
    }
  }

  // const handleSuggestionKeyDown = (
  //   e: KeyboardEvent<HTMLButtonElement>,
  //   index: number
  // ) => {
  //   if (e.key === 'ArrowDown') {
  //     e.preventDefault();
  //     debugger
  //     if (index + 1 < filteredSuggestions.length) {
  //       suggestionRefs.current[index + 1]?.focus();
  //     }
  //   } else if (e.key === 'ArrowUp') {
  //     e.preventDefault();
  //     if (index > 0) {
  //       suggestionRefs.current[index - 1]?.focus();
  //     } else {
  //       inputRef.current?.focus();
  //     }
  //   } else if (e.key === 'Enter') {
  //     e.preventDefault();
  //     handleClickSuggestion(filteredSuggestions[index]);
  //   }
  // };

  const handleClickSuggestion = (suggestion: string) => {
    addTag(suggestion)
    inputRef.current.focus()
  }

  return (
    <div
      {...scope}
      className="tag-input"
      onClick={() => inputRef.current?.focus()}
    >
      {tags.map((tag, index) => (
        <div
          key={index}
          className="tag"
        >
          {tag}
          <button
            onClick={() => removeTag(index)}
            className="remove-btn"
          >
            <Icon
              name="times"
              size={12}
            />
          </button>
        </div>
      ))}
      <input
        id="tag-input-field"
        autoFocus
        ref={inputRef}
        value={inputValue}
        onChange={handleInput}
        onKeyDown={handleKeyDown}
        placeholder="Type a tag and press Enter or ,"
        tabIndex={0}
        autoComplete="off"
      />
      {!isEmpty(filteredSuggestions) && (
        <Card
          className="suggestions"
          role="listbox"
          aria-label="Tag suggestions"
        >
          {filteredSuggestions.map((suggestion, index) => (
            <button
              key={index}
              className="suggestion"
              role="option"
              ref={element => {
                suggestionRefs.current.push(element)
              }}
              tabIndex={0}
              // onKeyDown={(e) => handleSuggestionKeyDown(e, index)}
              onClick={() => handleClickSuggestion(suggestion)}
            >
              {suggestion}
            </button>
          ))}
        </Card>
      )}
    </div>
  )
}

const css = k`
  .tag-input {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    min-height: 40px;
    width: 100%;
    position: relative;
  }
  
  .tag-input input {
    outline: none;
    border: none;
    flex-grow: 1;
  }
  
  .tag {
    display: flex;
    align-items: center;
    background-color: $color-primary;
    color: white;
    padding: 6px 5px;
    margin: 2px;
    border-radius: 3px;
    max-height: 24px;
    line-height: 1.2rem;
  }
  
  .remove-btn {
    all: unset;
    cursor: pointer;
    margin-left: 5px;
  }
  
  .suggestions {
    position: absolute;
    top: 100%;
    width: calc(100% - 12px);
    background: #fff;
    z-index: 100;
  }
  
  .suggestion {
    padding: 8px;
    display: block;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    transition: background .2s ease;
    width: 100%;
    text-align: left;
  }
  
  .suggestion:hover {
    background: $color-grey-100;
  }
  
  .suggestion:focus {
    background: $color-grey-100;
  }
`
