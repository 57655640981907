import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { toastService } from '@utils/toast.service'
import { useMachine } from '@xstate/react'
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { EmpathizeState } from './states/empathize.state.component'
import { templateStateMachine } from './template-state-machine-v2'
// import { IdeationState } from "./states/ideation-state.component";
import { LayoutHeader } from '@components/layout.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { Button } from '@components/mantine/button.component'
import { SavingIndicator } from '@components/saving-indicator.component'
import { Well } from '@components/well.component'
import { useDebounce } from '@hooks/use-debounce.hook'
import { functions } from '@src/firebase-app'
import { modalService } from '@utils/modal.service'
import { httpsCallable } from 'firebase/functions'
import { AnimatePresence, motion } from 'framer-motion'
import { useCss } from 'kremling'
import { isArray, isEmpty, isEqual, sortBy } from 'lodash'
import { assign } from 'xstate'
import { AssistantReportModal } from './assistant-report.modal'
import { EmpathyInsightsState } from './states/empathy-insights.state.component'
import { ExperienceIdeasState } from './states/experience-ideas.state.component'
import { MeasureAndTrackState } from './states/measure-and-track-state.component'
import { TouchpointsState } from './states/touchpoints-state.component'
import { generateSections } from './template-generator.helpers'
import css from './template-generator.kremling.scss'
import {
  getActivities,
  getExperienceIdeas,
  getResearchIdeas,
  getSections,
  loadMoreExperienceIdeas,
} from './template-generator.services'

export function TemplateGenerator() {
  const { orgId, assistantId } = useParams()
  const user = useAppState(state => state.user)
  const [saving, setSaving] = useState(false)
  const navigate = useNavigate()
  const scope = useCss(css)

  const [state, send] = useMachine(templateStateMachine, {
    actions: {
      addTemplate: assistantId ? editTemplate : addTemplate,
      bootstrapAssign: assign({
        lists: (_, event) => event.data.lists,
        fields: (_, event) => event.data.fields,
        messageHistory: (_, event) => event.data.messageHistory,
        selectedItems: (context, event) => {
          if (isEmpty(event.data.selectedItems)) {
            return context.selectedItems
          }
          return event.data.selectedItems
        },
        rawResponses: (context, event) => {
          if (isEmpty(event.data.rawResponses)) {
            return context.rawResponses
          }
          return event.data.rawResponses
        },
      }),
    },
    services: {
      bootstrapData: () => {
        return getDoc(
          doc(db, dbNames.assistantConversations, assistantId),
        ).then(w => ({ id: w.id, ...w.data() }))
      },
      getSections,
      getResearchIdeas,
      getActivities,
      getExperienceIdeas,
      loadMoreExperienceIdeas,
    },
    guards: {
      noEmpathyInsights: (_, event) => {
        return isEmpty([
          ...(event.data.lists.researchInsights || []),
          ...(event.data.lists.researchChallenges || []),
        ])
      },
      noExperienceIdeas: (_, event) => {
        return isEmpty(event.data.lists.experienceIdeas)
      },
      noSections: (_, event) => {
        return isEmpty(event.data.lists.sections)
      },
      noActivities: (_, event) => {
        return isEmpty(event.data.lists.activities)
      },
    },
  })

  const isBootstrapped = !state.matches('bootstrap')

  const initialLists = useRef(state.context.lists)
  const listsChanged =
    isBootstrapped && !isEqual(initialLists.current, state.context.lists)

  useEffect(() => {
    if (isBootstrapped) {
      initialLists.current = state.context.lists
    }
  }, [isBootstrapped])

  useEffect(() => {
    if (!isBootstrapped) return
    if (listsChanged) {
      setSaving(true)
      // send({type: 'updateLists', listId, list})
      updateDoc(doc(db, dbNames.assistantConversations, assistantId), {
        lists: state.context.lists,
        rawResponses: state.context.rawResponses,
      }).then(() => {
        setSaving(false)
      })
    }
  }, [state.context.lists, isBootstrapped, listsChanged])

  function addTemplate() {
    const date = getTodayDate().toISO()
    const chosenSections = []
    for (const sectionId in state.context.selectedValidationFields) {
      //get current section data
      //replace validationFields with the selected fields
      const section = state.context.activities.find(
        ({ id }) => sectionId === id,
      )
      chosenSections.push({
        ...section,
        validationFields: state.context.selectedValidationFields[sectionId],
      })
    }
    const sections = generateSections(chosenSections)
    addDoc(collection(db, dbNames.workflowTemplates), {
      name: '',
      sections,
      orgId,
      currentVersionEditorIds: [user.id],
      editorIds: [user.id],
      authorId: user.id,
      createdDate: date,
      lastEditedDate: date,
      assistant: false,
      lastEditedById: user.id,
      archived: false,
    })
      .then(template => {
        navigate(`/${orgId}/library/templates/t/${template.id}`)
      })
      .catch(e => toastService.error(e.message))
  }

  function editTemplate() {
    const date = getTodayDate().toISO()
    const { selectedItems, lists } = state.context
    const chosenSections = []
    for (const sectionId in selectedItems.validationFields) {
      //get current section data
      //replace validationFields with the selected fields
      const section = lists.activities.find(({ id }) => sectionId === id)
      chosenSections.push({
        ...section,
        validationFields: selectedItems.validationFields[sectionId],
      })
    }
    const sections = generateSections(chosenSections)
    updateDoc(doc(db, dbNames.workflowTemplates, assistantId), {
      sections,
      lastEditedDate: date,
      lastEditedById: user.id,
      assistant: false,
    })
      .then(() => {
        navigate(`/${orgId}/library/templates/t/${assistantId}`)
      })
      .catch(e => toastService.error(e.message))
  }

  function skipAssistant() {
    updateDoc(doc(db, dbNames.workflowTemplates, assistantId), {
      assistant: false,
    })
      .then(() => {
        navigate(`/${orgId}/library/templates/t/${assistantId}`)
      })
      .catch(e => toastService.error(e.message))
  }

  function updateField(fieldId, value) {
    saveFieldDebounced(fieldId, value)
  }

  function saveField(fieldId, value) {
    setSaving(true)
    send({ type: 'updateField', fieldId, value })

    updateDoc(doc(db, dbNames.assistantConversations, assistantId), {
      [`fields.${fieldId}`]: value,
    }).then(() => {
      setSaving(false)
    })
  }
  const saveFieldDebounced = useDebounce(saveField, 500)

  function updateSelectedItems(listId, selectedItems) {
    let changesMade = true
    if (isArray(selectedItems)) {
      const sortedOldList = sortBy(state.context.selectedItems[listId])
      const sortedNewList = sortBy(selectedItems)
      changesMade = !isEqual(sortedOldList, sortedNewList)
    }

    if (!changesMade) {
      return
    }

    setSaving(true)
    send({ type: 'updateSelectedItems', listId, selectedItems })
    updateDoc(doc(db, dbNames.assistantConversations, assistantId), {
      [`selectedItems.${listId}`]: selectedItems,
    }).then(() => {
      setSaving(false)
    })
  }

  async function handleReport(state, rawResponse, list) {
    try {
      const { issueDescription, issueItems } = await modalService.render(
        AssistantReportModal,
        { list },
      )
      await httpsCallable(
        functions,
        'allFunctions-sendIssueReport',
      )({
        type: 'assistant',
        orgId,
        rawResponse,
        issueItems,
        issueDescription,
        state,
        assistantId,
      })
      toastService.info('Issue report sent')
    } catch (err) {
      toastService.error(
        'There was an error sending the report. Please try again.',
      )
    }
  }

  const commonProps = {
    state,
    send,
    updateField,
    updateSelectedItems,
    handleReport,
  }

  return (
    <div
      {...scope}
      className="iact-container --md"
    >
      <LayoutHeader
        className="mt-5"
        title={
          <div className="header__container">
            <ActionIcon
              size="sm"
              icon="angle-left"
              variant="tertiary"
              className="mr-2"
              onClick={() => navigate(-1)}
            />
            <div>Template assistant</div>
            <SavingIndicator
              className="ml-2"
              saving={saving}
            />
          </div>
        }
        actions={
          <Button
            variant="tertiary"
            onClick={skipAssistant}
          >
            Skip assistant
          </Button>
        }
      />
      <div className="assistant-container">
        <AnimatePresence initial={false}>
          <motion.div
            key={Object.keys(state.value)[0]}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="stage-container"
            transition={{ duration: 0.2, ease: 'easeOut' }}
          >
            {state.matches('bootstrap') && <Well loader></Well>}
            {state.matches('empathize') && <EmpathizeState {...commonProps} />}
            {state.matches('empathizeInsights') && (
              <EmpathyInsightsState {...commonProps} />
            )}
            {/*{*/}
            {/*  state.matches('ideation') &&*/}
            {/*    <IdeationState {...commonProps}/>*/}
            {/*}*/}
            {state.matches('experienceIdeas') && (
              <ExperienceIdeasState {...commonProps} />
            )}
            {state.matches('touchpoints') && (
              <TouchpointsState {...commonProps} />
            )}
            {state.matches('measureAndTrack') && (
              <MeasureAndTrackState {...commonProps} />
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  )
}
