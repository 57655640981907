import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { DRAG_DROP_PASTE } from '@lexical/rich-text'
import { isMimeType, mediaFileReader } from '@lexical/utils'
import { COMMAND_PRIORITY_LOW } from 'lexical'
import { useEffect } from 'react'

import { INSERT_IMAGE_COMMAND } from './image-plugin'
import { UploadOptions, useFileUpload } from '@hooks/use-file-upload.hook'

const ACCEPTABLE_IMAGE_TYPES = [
  'image/',
  'image/heic',
  'image/heif',
  'image/gif',
  'image/webp',
]

export function DragDropPaste({
  uploadOptions,
}: {
  uploadOptions: UploadOptions
}): null {
  const [editor] = useLexicalComposerContext()

  const { doUpload } = useFileUpload({
    uploadToType: 'rich-text',
    onSingleFileSuccess: () => {},
  })

  useEffect(() => {
    return editor.registerCommand(
      DRAG_DROP_PASTE,
      files => {
        ;(async () => {
          const filesResult = await mediaFileReader(
            files,
            [ACCEPTABLE_IMAGE_TYPES].flatMap(x => x),
          )
          for (const { file } of filesResult) {
            const uploads = await doUpload({ file, ...uploadOptions })

            if (isMimeType(file, ACCEPTABLE_IMAGE_TYPES)) {
              editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
                altText: file.name,
                src: uploads[0].url,
              })
            }
          }
        })()
        return true
      },
      COMMAND_PRIORITY_LOW,
    )
  }, [editor])
  return null
}
