import React from 'react'

import { Icon } from '@components/icon.component'

export function Group({ name, icon }) {
  return (
    <div className="select-component__group">
      <div className="select-component__group-line" />
      <div className="select-component__group-content">
        {icon && (
          <Icon
            className="mr-2"
            name={icon}
            size={14}
          />
        )}
        {name}
      </div>
    </div>
  )
}

Group.propTypes = {}
