import { filter, includes, join, map } from 'lodash'

export function renderRoomTitle(room, participants, userId, isAdmin) {
  if (!room) return ''

  if (room.userIds.includes('admin') && !isAdmin) {
    console.log('IS NOT ADMIN')
    return 'Admin'
  }

  let inRoom = filter(participants, participant => {
    if (participant.userId === userId) return false
    if (participant.userId === 'admin') return false
    return includes(room.userIds, participant.userId)
  })

  let firstNames = map(inRoom, user => {
    if (user.deactivated) return `${user.userFirstName} (deactivated)`
    return `${user.userFirstName} ${user.userLastName[0]}`
  })

  return join(firstNames, ', ')
}
