import { BadgeWrapper } from '@components/badge.component'
import { EmptyState } from '@components/empty-state.component'
import { Icon } from '@components/icon.component'
import { Dropdown } from '@components/radix/dropdown'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { InvitationDetailsModal } from '@src/pages/dashboard/invitation-details-modal.component'
import { dbNames } from '@utils/constants'
import { modalService } from '@utils/modal.service'
import { doc, getDoc, updateDoc, writeBatch } from 'firebase/firestore'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

type NotificationContext = {
  open: boolean
  setOpen: (value: boolean) => void
}

const NotificationContext = createContext<NotificationContext>({
  open: false,
  setOpen: () => {},
})

export function NotificationsDropdown() {
  const [open, setOpen] = useState(false)
  const invitations = useAppState(state => state.invitations)
  const notifications = useAppState(state => state.notifications)

  const newNotifications = notifications.filter(
    notification => !notification.viewed,
  )

  useEffect(() => {
    if (!open) return
    if (isEmpty(newNotifications) && isEmpty(invitations)) return
    const batch = writeBatch(db)
    if (newNotifications.length > 0) {
      newNotifications.forEach(notification => {
        if (notification.viewed === false) {
          batch.update(doc(db, dbNames.notifications, notification.id), {
            viewed: true,
          })
        }
      })
    }

    const unseenInvitations = invitations.filter(
      invitation => !invitation.viewed,
    )
    if (unseenInvitations.length > 0) {
      unseenInvitations.forEach(invitation => {
        batch.update(doc(db, dbNames.invitations, invitation.id), {
          viewed: true,
        })
      })
    }
    batch.commit()
  }, [newNotifications, invitations, open])

  return (
    <NotificationContext.Provider value={{ open, setOpen }}>
      <Dropdown
        open={open}
        setOpen={setOpen}
      >
        <BadgeWrapper
          showBadge={
            [...newNotifications, ...invitations.filter(i => !i.viewed)]
              .length > 0
          }
        >
          <Dropdown.Trigger className="h-[4rem] w-[4rem] bg-white text-primary border border-grey-100 rounded-full flex items-center justify-center focus:outline-none focus:bg-grey-50 select-none">
            <Icon name="bell" />
          </Dropdown.Trigger>
        </BadgeWrapper>
        <Dropdown.Content align="end">
          <div className="bg-white w-screen media2:w-[40rem] max-h-[10 0rem] rounded-md shadow-md flex flex-col overflow-auto p-3">
            <div className="flex items-center justify-between p-3">
              <div className="text-3xl font-bold">Notifications</div>
            </div>
            {[...invitations, ...notifications].length === 0 ? (
              <EmptyState title={'Notifications empty'} />
            ) : (
              <>
                {invitations.map(invitation => (
                  <InvitationNotificationItem
                    key={invitation.id}
                    invitation={invitation}
                  />
                ))}
                <div className="space-y-2">
                  {notifications.map(notification => (
                    <Dropdown.ListItem key={notification.id}>
                      <>
                        {notification.type === 'activityJoined' && (
                          <ActivityJoinedNotificationItem
                            notification={notification}
                          />
                        )}
                        {notification.type === 'activityStarted' && (
                          <ActivityStartedNotificationItem
                            notification={notification}
                          />
                        )}
                        {notification.type === 'newIterationStarted' && (
                          <NewIterationStartedNotificationItem
                            notification={notification}
                          />
                        )}
                        {notification.type === 'newWorkflowMessage' && (
                          <NewWorkflowMessageNotificationItem
                            notification={notification}
                          />
                        )}
                        {/*{notification.type === 'deactivatedFromOrg' && (*/}
                        {/*  <DeactivatedFromOrgNotificationItem*/}
                        {/*    notification={notification}*/}
                        {/*  />*/}
                        {/*)}*/}
                        {/*{notification.type === 'reactivatedToOrg' && (*/}
                        {/*  <ReactivatedToOrgNotificationItem notification={notification} />*/}
                        {/*)}*/}
                      </>
                    </Dropdown.ListItem>
                  ))}
                </div>
              </>
            )}
          </div>
        </Dropdown.Content>
      </Dropdown>
    </NotificationContext.Provider>
  )
}

function InvitationNotificationItem({
  invitation,
}: {
  invitation: Invitation
}) {
  const isLinkInvite = invitation.type === 'linkInvite'

  const [org] = useLoad(
    {},
    () => {
      return getDoc(
        doc(db, dbNames.organizations, invitation.inviterOrgId),
      ).then(d => ({ ...d.data(), id: d.id }))
    },
    [],
  )

  async function openInvitationDetailsModal() {
    if (!isLinkInvite) {
      const d = doc(db, dbNames.invitations, invitation.id)
      updateDoc(d, { viewed: true })
    }
    await modalService.render(
      InvitationDetailsModal,
      { invitation, org },
      { hideClose: true },
    )
  }

  return (
    <NotificationWrapper
      onClick={() => {
        openInvitationDetailsModal()
      }}
      notification={invitation}
    >
      <div className="flex-shrink-0 text-grey-500">
        <Icon
          className="mr-4"
          name="alarm-clock"
        />
      </div>
      <div className="flex-1">
        <div className="text-2xl text-grey-700">
          You have been invited to join{' '}
          <span className="font-semibold text-primary">{org.name}</span>
        </div>
        <div className="text-xl font-semibold">
          {DateTime.fromJSDate(invitation.timestamp.toDate()).toRelative()}
        </div>
      </div>
    </NotificationWrapper>
  )
}

function NotificationWrapper({
  to,
  children,
  notification,
  onClick,
}: {
  to?: string
  onClick?: () => void
  children: React.ReactNode
  notification?: Notification | Invitation
}) {
  const { setOpen } = useContext(NotificationContext)
  if (to) {
    return (
      <Link
        to={to}
        className={`flex items-center space-x-4 hover:bg-grey-50 p-5 rounded-md ${!notification.dirty ? 'bg-active-75' : ''}`}
        onClick={() => {
          if (!notification.dirty) {
            const d = doc(db, dbNames.notifications, notification.id)
            updateDoc(d, { dirty: true })
          }
          setOpen(false)
        }}
      >
        {children}
      </Link>
    )
  }

  if (onClick) {
    return (
      <button
        className="flex items-center text-left space-x-4 hover:bg-grey-50 p-5 rounded-md"
        onClick={onClick}
      >
        {children}
      </button>
    )
  }

  return (
    <div className="flex items-center space-x-4 hover:bg-grey-50 p-5 rounded-md">
      {children}
    </div>
  )
}

function ActivityJoinedNotificationItem({
  notification,
}: {
  notification: Notification
}) {
  return (
    <NotificationWrapper
      to={`/${notification.activityOrgId}/home/${notification.recipientId}/${notification.activityId}`}
      notification={notification}
    >
      <div className="flex-shrink-0 text-grey-500">
        <Icon
          className="mr-4"
          name="alarm-clock"
        />
      </div>
      <div className="flex-1">
        <div className="text-2xl text-grey-700">
          You joined an activity called{' '}
          <span className="font-semibold text-primary">
            {notification.activityName}
          </span>
        </div>
        <div className="text-xl font-semibold">
          {DateTime.fromISO(notification.createdDate).toRelative()}
        </div>
      </div>
    </NotificationWrapper>
  )
}

function ActivityStartedNotificationItem({
  notification,
}: {
  notification: Notification
}) {
  return (
    <NotificationWrapper
      to={`/${notification.activityOrgId}/home/${notification.recipientId}/${notification.activityId}`}
      notification={notification}
    >
      <div className="flex-shrink-0 text-grey-500">
        <Icon
          className="mr-4"
          name="alarm-clock"
        />
      </div>
      <div className="flex-1">
        <div className="text-2xl text-grey-700">
          You have joined an activity called{' '}
          <span className="font-semibold text-primary">
            {notification.activityName}
          </span>
        </div>
        <div className="text-xl font-semibold text-primary">
          {DateTime.fromISO(notification.createdDate).toRelative()}
        </div>
      </div>
    </NotificationWrapper>
  )
}

// function DeactivatedFromOrgNotificationItem({
//   notification,
// }: {
//   notification: Notification
// }) {
//   return (
//     <div className="flex items-center space-x-4">
//       <div className="flex-shrink-0">
//         <Icon
//           name="user"
//           className="w-8 h-8 text-primary"
//         />
//       </div>
//       <div className="flex-1">
//         <div className="text-sm text-grey-700">
//           <span className="font-medium">John Doe</span> deactivated from your
//           organization
//         </div>
//         <div className="text-grey-500">2 hours ago</div>
//       </div>
//     </div>
//   )
// }

function NewIterationStartedNotificationItem({
  notification,
}: {
  notification: Notification
}) {
  return (
    <NotificationWrapper
      to={`/${notification.activityOrgId}/home/${notification.recipientId}/${notification.activityId}`}
      notification={notification}
    >
      <div className="flex-shrink-0 text-grey-500">
        <Icon
          className="mr-4"
          name="alarm-clock"
        />
      </div>
      <div className="flex-1">
        <div className="text-2xl text-grey-700">
          A new iteration for your activity called{' '}
          <span className="font-semibold text-primary">
            {notification.activityName}
          </span>
        </div>
        <div className="text-xl font-semibold">
          {DateTime.fromISO(notification.createdDate).toRelative()}
        </div>
      </div>
    </NotificationWrapper>
  )
}

// function ReactivatedToOrgNotificationItem({
//   notification,
// }: {
//   notification: Notification
// }) {
//   return (
//     <div className="flex items-center space-x-4">
//       <div className="flex-shrink-0">
//         <Icon
//           name="user"
//           className="w-8 h-8 text-primary"
//         />
//       </div>
//       <div className="flex-1">
//         <div className="text-sm text-grey-700">
//           <span className="font-medium">John Doe</span> reactivated to your
//           organization
//         </div>
//         <div className="text-grey-500">2 hours ago</div>
//       </div>
//     </div>
//   )
// }

function NewWorkflowMessageNotificationItem({
  notification,
}: {
  notification: Notification
}) {
  const user = useAppState(state => state.user)
  const isAdmin = ['owner', 'manager'].includes(
    user.orgRoles[notification.activityOrgId],
  )

  return (
    <NotificationWrapper
      to={
        isAdmin
          ? `/${notification.activityOrgId}/mentoring/${notification.activityId}/settings/messages`
          : `/${notification.activityOrgId}/home/${notification.recipientId}/${notification.activityId}/messaging`
      }
      notification={notification}
    >
      <div className="flex-shrink-0 text-grey-500">
        <Icon
          className="mr-4"
          name="envelope"
        />
      </div>
      <div className="flex-1">
        <div className="text-2xl text-grey-700">
          New message in your activity called{' '}
          <span className="font-semibold text-primary">
            {notification.activityName}
          </span>
        </div>
        <div className="text-xl font-semibold">
          {DateTime.fromISO(notification.createdDate).toRelative()}
        </div>
      </div>
    </NotificationWrapper>
  )
}
