import React from 'react'
import { a } from 'kremling'

import { Icon } from '@components/icon.component'
import { useHighlighter } from '@hooks/use-highlighter.hook'
import { ItemBase } from './item-base'

export function ItemSingle({ isSelected, value, item, onChange, searchValue }) {
  const isActive = !!(item && value && item.id === value.id)
  const onClick = () => {
    onChange(item)
  }

  const name = useHighlighter(item.name, searchValue)
  const subName = useHighlighter(item.subName, searchValue)

  return (
    <ItemBase
      id={item.id}
      isSelected={isSelected}
      className={a('item-single').m('item-single--selected', isSelected)}
      onClick={onClick}
    >
      <div className="item-single-content">
        <div className="item-single-content__name">{item ? name : '—'}</div>
        {item.subName && (
          <div className="item-single-content__sub-name">{subName}</div>
        )}
      </div>
      <div>
        {isActive && (
          <Icon
            name="check-circle"
            fill="#2252C5"
            size={16}
          />
        )}
      </div>
    </ItemBase>
  )
}
