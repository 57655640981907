import { ActionIcon } from '@components/mantine/action-icon.component'
import { Button } from '@components/mantine/button.component'
import { TextDivider } from '@components/text-divider.component'
import { Tooltip } from '@components/tooltip.component'
import { Well } from '@components/well.component'
import { ChoiceItemList } from '@src/pages/workflow-template-list/template-generator/choice-item-list.component'
import { isEmpty, isEqual, sortBy } from 'lodash'
import React, { useRef } from 'react'

export function EmpathyInsightsState({
  send,
  state,
  updateSelectedItems,
  handleReport,
}) {
  const { selectedItems, lists, rawResponses } = state.context

  const selected = [
    ...(selectedItems.researchInsights || []),
    ...(selectedItems.researchChallenges || []),
  ]

  const initialSelected = useRef(selected)

  const selectedChanged = !isEqual(
    sortBy(selected),
    sortBy(initialSelected.current),
  )

  function onSelectInsight(insight) {
    send({ type: 'insightSelected', insight })
    const { researchInsights = [] } = selectedItems
    if (researchInsights.includes(insight)) {
      updateSelectedItems(
        'researchInsights',
        researchInsights.filter(item => item !== insight),
      )
    } else {
      updateSelectedItems('researchInsights', [...researchInsights, insight])
    }
  }

  function onSelectChallenge(challenge) {
    send({ type: 'challengeSelected', challenge })
    const { researchChallenges = [] } = selectedItems
    if (researchChallenges.includes(challenge)) {
      updateSelectedItems(
        'researchChallenges',
        researchChallenges.filter(item => item !== challenge),
      )
    } else {
      updateSelectedItems('researchChallenges', [
        ...researchChallenges,
        challenge,
      ])
    }
  }

  return (
    <Well>
      <Well.Header
        renderActions={() => (
          <Tooltip content="Report an issue">
            <ActionIcon
              size="sm"
              icon="flag"
              onClick={() => {
                handleReport(state.value, rawResponses, [
                  ...state.context.lists.researchInsights,
                  ...state.context.lists.researchChallenges,
                ])
              }}
            />
          </Tooltip>
        )}
      >
        Step 1.1: Insights
      </Well.Header>
      <Well.Body>
        <div className="row break-1">
          <div className="col-10">
            <div className="explain-text">
              Select any empathetic insights and challenges that you think
              accurately represent your audience. We’ll include these as
              considerations to generating a list of potential experience ideas.
            </div>
          </div>
        </div>
        <TextDivider className="mt-5 mb-5" />
        {!isEmpty([
          ...(lists.researchInsights || []),
          ...(lists.researchChallenges || []),
        ]) && (
          <div>
            <ChoiceItemList
              title="Insights"
              list={lists.researchInsights}
              selectedItems={selectedItems.researchInsights || []}
              onSelect={onSelectInsight}
              onAddChoice={choice => {
                send('addInsight', { choice })
              }}
              onEditChoice={(value, oldValue, index) => {
                send('editInsight', { value, oldValue, index })
              }}
              addChoiceActionText="Add insight"
              className="mt-5"
            />
            <ChoiceItemList
              title="Challenges"
              list={lists.researchChallenges}
              selectedItems={selectedItems.researchChallenges || []}
              onSelect={onSelectChallenge}
              onAddChoice={choice => {
                send('addChallenge', { choice })
              }}
              onEditChoice={(value, oldValue, index) => {
                send('editChallenge', { value, oldValue, index })
              }}
              addChoiceActionText="Add challenge"
              className="mt-5"
            />
          </div>
        )}
        <TextDivider className="mt-5 mb-5" />
        <div className="stage__actions">
          <Button
            variant="secondary"
            onClick={() => {
              send('back')
            }}
          >
            Back
          </Button>
          <Button
            loading={state.matches('empathizeInsights.loading')}
            variant="primary"
            onClick={() =>
              selectedChanged || isEmpty(state.context.lists.experienceIdeas)
                ? send('generate')
                : send('next')
            }
            disabled={isEmpty(selected)}
          >
            Next: Experience Ideas
          </Button>
        </div>
      </Well.Body>
    </Well>
  )
}
