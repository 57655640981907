import { Button } from '@components/mantine/button.component'
import { auth, functions } from '@src/firebase-app'
import { getErrorMessage } from '@utils/form.utils'
import { toastService } from '@utils/toast.service'
import { validateEmail } from '@utils/validate-email'
import { httpsCallable } from 'firebase/functions'
import React from 'react'
import { useForm } from 'react-hook-form'

type NewEmailFormProps = {
  handleUpdate: (data: { email: string }) => void
  setReauth: (email: string, password: string) => Promise<void>
  password: string
}

export function NewEmailForm({
  handleUpdate,
  setReauth,
  password,
}: NewEmailFormProps) {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      newEmail: '',
    },
  })

  async function updateEmail({ newEmail }: { newEmail: string }) {
    try {
      const updateEmail = httpsCallable(functions, 'userFunctions-updateEmail')
      await updateEmail(newEmail)
      await setReauth(newEmail, password)
      handleUpdate({ email: newEmail })

      reset()
      toastService.info('Email successfully updated')
    } catch (err) {
      toastService.error(
        'A problem occurred when updating your email. Please try again.',
      )
    }
  }

  return (
    <form onSubmit={handleSubmit(updateEmail)}>
      <h3 className="mb-0">Update email</h3>
      <div className="mt-5">{auth.currentUser.email}</div>
      <div className="row break-1">
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="newEmail">New email</label>
            <input
              type="email"
              id="newEmail"
              className="form-input"
              placeholder={'type here...'}
              {...register('newEmail', {
                validate: value => {
                  if (!validateEmail(value)) {
                    return 'Email must be valid'
                  }
                },
              })}
            />
            <div className="mt-4 has-warning">
              {getErrorMessage(errors.newEmail)}
            </div>
          </div>
        </div>
      </div>
      <Button
        type="submit"
        variant="secondary"
        loading={isSubmitting}
        disabled={!!errors.newEmail}
      >
        Submit
      </Button>
    </form>
  )
}
