import {
  Radio as MantineRadio,
  RadioProps as MantineRadioProps,
} from '@mantine/core'
import React, { forwardRef } from 'react'

type RadioProps = {} & MantineRadioProps

export const Radio = forwardRef<HTMLInputElement, MantineRadioProps>(
  ({ ...radioProps }: RadioProps, ref) => {
    return (
      <MantineRadio
        ref={ref}
        styles={{
          radio: {
            borderWidth: 2,
          },
        }}
        size="md"
        {...radioProps}
      />
    )
  },
)
