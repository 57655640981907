import React from 'react'
import { m } from 'kremling'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

type BasicTableProps = {
  data: any[]
  columns: ColumnDef<any, any>[]
  hideHeader?: boolean
  classes?: {
    rowClass?: string
    dataCellClass?: string
    tableClass?: string
  }
}

export function BasicTable({
  data,
  columns,
  hideHeader,
  classes,
}: BasicTableProps) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <table className={m(classes.tableClass, !!classes?.tableClass)}>
      <thead className={m('sr-only', hideHeader)}>
        {table.getHeaderGroups().map(headerGroup => (
          <tr
            className={classes.rowClass}
            key={headerGroup.id}
          >
            {headerGroup.headers.map(header => {
              return (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              )
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr
            className={m(classes.rowClass, !!classes?.rowClass)}
            key={row.id}
          >
            {row.getVisibleCells().map(cell => {
              return (
                <td
                  key={cell.id}
                  className={m(classes.dataCellClass, !!classes?.dataCellClass)}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              )
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
