import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { CardListItem } from '@components/card-list-item.component'
import { AddWorkflowModal } from '@src/pages/mentoring/add-workflow-modal.component'
import { SelfAddWorkflowModal } from '@src/pages/workflow/self-add-workflow-modal.component'
import { modalService } from '@utils/modal.service'
import { toastService } from '@utils/toast.service'
import { DateTime } from 'luxon'

export function TemplateCard({
  template,
  createDuplicateTemplate,
  archiveTemplate,
  restoreTemplate,
}) {
  const { orgId } = useParams()
  const navigate = useNavigate()

  function onStartWorkflow() {
    modalService
      .render(SelfAddWorkflowModal, { template, orgId })
      .then(({ newActivityId }) => {
        navigate(`/${orgId}/home/${newActivityId}`)
        toastService.info('Successfully created workflow')
      })
  }

  function onAssignWorkflow() {
    modalService.render(AddWorkflowModal, { template, orgId }).then(() => {
      toastService.info('Successfully assigned workflow')
    })
  }

  function renderSubtitle() {
    if (template.assistant) {
      return `Created ${DateTime.fromISO(template.createdDate).toRelative()}`
    }

    return template.lastPublishedDate ? 'Published to library' : ''
  }

  return (
    <CardListItem
      className="mb-4"
      cardTitle={template.assistant ? 'Assistant' : template.name}
      icon={template.assistant ? 'wand-magic-sparkles' : 'ballot'}
      href={
        template.assistant
          ? `t/template-generator/${template.id}`
          : `t/${template.id}`
      }
      subTitle={renderSubtitle()}
      renderActionsContent={({ close }) => (
        <div className="select-list">
          {template.archived ? (
            <button
              onClick={e => {
                e.preventDefault()
                restoreTemplate(template)
                close()
              }}
            >
              Restore
            </button>
          ) : (
            <>
              <button
                onClick={e => {
                  e.preventDefault()
                  createDuplicateTemplate(template)
                  close()
                }}
              >
                Create duplicate
              </button>
              {!template.assistant && (
                <>
                  <button
                    onClick={e => {
                      e.preventDefault()
                      onStartWorkflow()
                      close()
                    }}
                  >
                    Start workflow
                  </button>
                  <button
                    onClick={e => {
                      e.preventDefault()
                      onAssignWorkflow()
                      close()
                    }}
                  >
                    Assign workflow
                  </button>
                </>
              )}
              <button
                onClick={e => {
                  e.preventDefault()
                  archiveTemplate(template)
                  close()
                }}
              >
                Archive
              </button>
            </>
          )}
        </div>
      )}
    />
  )
}
