import { collection, doc, query, where } from 'firebase/firestore'
import React from 'react'
import { Outlet, useParams } from 'react-router-dom'

import { Icon } from '@components/icon.component'
import { LayoutHeader } from '@components/layout.component'
import { Loader } from '@components/loader.component'
import { Tabs } from '@components/tabs.component'
import {
  useFirestoreDocument,
  useFirestoreQuery,
} from '@react-query-firebase/firestore'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { k, useCss } from 'kremling'

export function ActivitySettings() {
  const { activityId } = useParams()
  const scope = useCss(css)

  const activityUsersQuery = query(
    collection(db, dbNames.activityUsers),
    where('activityId', '==', activityId),
  )
  const { data: participants, isLoading: participantsLoading } =
    useFirestoreQuery(
      `activity-users-${activityId}`,
      activityUsersQuery,
      { subscribe: true },
      {
        select: snap => snap.docs.map(doc => ({ ...doc.data(), id: doc.id })),
      },
    )

  const activityRef = doc(db, dbNames.activities, activityId)
  const { data: activity, isLoading: activityLoading } = useFirestoreDocument(
    `activity-${activityId}`,
    activityRef,
    {
      subscribe: true,
    },
    {
      select: doc => ({ ...doc.data(), id: doc.id }),
    },
  )

  if (activityLoading || participantsLoading) {
    return <Loader />
  }

  //TODO
  // - What happens when a participant is removed?
  // - hook up share options to data
  // - set up invite input
  // - decide how the new invitation link flow should be handled

  return (
    <div
      {...scope}
      className="--lg iact-container"
    >
      <div className="page__title mt-16">
        <Icon
          className="page__icon"
          name="square-list"
        />
        <div className="ml-5">Settings</div>
      </div>
      <LayoutHeader title={activity.name} />
      <div className="tabs-container">
        <Tabs>
          <Tabs.Link to="progress">Progress</Tabs.Link>
          <Tabs.Link to="general-settings">General</Tabs.Link>
          <Tabs.Link to="sharing">Sharing</Tabs.Link>
          {/*<Tabs.Link to="leaderboards">Leaderboards</Tabs.Link>*/}
          <Tabs.Link to="messages">Messages</Tabs.Link>
          <Tabs.Link to="overview">Overview</Tabs.Link>
        </Tabs>
      </div>
      <Outlet context={{ activity, participants }} />
    </div>
  )
}

const css = k`
  .page__title {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-left: 0;
  }

  .page__icon {
    color: $color-grey-400;
  }

  .tabs-container {
    display: flex;
  }

  @media (min-width: $media7-width) {
    .page__title {
      margin-left: -4rem;
    }
  }

`
