import { a, k, useCss } from 'kremling'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { CardListItem } from '@components/card-list-item.component'
import { useAppState } from '@src/app.state'
import { AddWorkflowModal } from '@src/pages/mentoring/add-workflow-modal.component'
import { SelfAddWorkflowModal } from '@src/pages/workflow/self-add-workflow-modal.component'
import { modalService } from '@utils/modal.service'
import { toastService } from '@utils/toast.service'

export function LibraryTemplateCard({
  template,
  currentWorkflow,
  unpublishTemplate,
}) {
  const scope = useCss(css)
  const templateIsBeingUsedInWorkflow = !!currentWorkflow
  const { orgId } = useParams()
  const navigate = useNavigate()
  const user = useAppState(state => state.user)
  const isAdmin = ['owner', 'manager'].includes(user.orgRoles[orgId])

  function onStartWorkflow() {
    modalService
      .render(SelfAddWorkflowModal, { template, orgId })
      .then(({ newActivityId }) => {
        navigate(`/${orgId}/home/${newActivityId}`)
        toastService.info('Successfully created workflow')
      })
  }

  function onAssignWorkflow() {
    modalService.render(AddWorkflowModal, { template, orgId }).then(() => {
      toastService.info('Successfully assigned workflow')
    })
  }

  return (
    <CardListItem
      {...scope}
      className={a('space-y-4').m('is-used', templateIsBeingUsedInWorkflow)}
      href={
        templateIsBeingUsedInWorkflow
          ? `t/${currentWorkflow.id}`
          : `t/${template.id}`
      }
      cardTitle={template.name}
      subTitle={
        templateIsBeingUsedInWorkflow
          ? `Active workflow: ${currentWorkflow.name}`
          : ''
      }
      icon="globe-stand"
      renderActionsContent={({ close }) => (
        <div className="select-list">
          <button
            onClick={e => {
              e.preventDefault()
              onStartWorkflow()
              close()
            }}
          >
            Start workflow
          </button>
          {isAdmin && (
            <>
              <button
                onClick={e => {
                  e.preventDefault()
                  onAssignWorkflow()
                  close()
                }}
              >
                Assign workflow
              </button>
              <button
                onClick={e => {
                  e.preventDefault()
                  unpublishTemplate(template)
                  close()
                }}
              >
                Unpublish
              </button>
            </>
          )}
        </div>
      )}
    />
  )
}

const css = k`
  .is-used {
    background: $color-grey-25;
  }
`
