import React from 'react'
import { array, bool, string, func, any } from 'prop-types'
import { useCss, k } from 'kremling'
import ReactSelect, { components } from 'react-select'

import { Icon } from '@components/icon.component'

const DropdownIndicator = p => (
  <components.DropdownIndicator {...p}>
    <Icon
      name="caret-down"
      size={12}
    />
  </components.DropdownIndicator>
)

const Option = p => {
  if (p.data.description) {
    return (
      <components.Option {...p}>
        <div>{p.data.label}</div>
        <div className="option-description">{p.data.description}</div>
      </components.Option>
    )
  }

  return <components.Option {...p}>{p.data.label}</components.Option>
}

export const Select = props => {
  const customizeComponents = {
    DropdownIndicator,
    Option,
  }

  return (
    <div
      {...useCss(css)}
      className="react-select"
    >
      <ReactSelect
        classNamePrefix="rs"
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        components={customizeComponents}
        menuPortalTarget={document.body}
        {...props}
      />
    </div>
  )
}

Select.propTypes = {
  clearValue: func,
  defaultValue: any,
  emotion: any,
  getStyles: func,
  getValue: func,
  hasValue: bool,
  id: string,
  isMulti: bool,
  name: string,
  onChange: func,
  options: array,
  placeholder: string,
  selectOption: func,
  selectProps: any,
  setValue: func,
  value: any,
}

const css = k`
  .react-select {
    .rs__control {
      height: $form-element-height;
      min-height: $form-element-height;
      border: none;
      background-color: $color-grey-50;
      cursor: pointer;
      border-radius: $base-border-radius;
      transition: box-shadow $form-transition-duration ease;

      &:hover {
        background-color: $color-grey-75;
      }

      &.rs__control--is-focused {
        box-shadow: $focus-box-shadow;
      }

      &.rs__control--menu-is-open {
        background-color: #fff;
      }
    }

    .rs__value-container {
      height: 3.6rem;
      padding: 0 1.2rem;
    }

    .rs__menu {
      margin-bottom: 0;
      margin-top: 0;
      border-radius: $base-border-radius;
      box-shadow: $box-shadow-2;
    }

    .rs__option {
      cursor: pointer;

      &.rs__option--is-focused {
        background-color: $color-grey-50;
      }

      &.rs__option--is-selected {
        background-color: $color-primary;
      }

      &.rs__option--is-disabled {
        cursor: default;
      }
    }
  }
  
  .option-description {
    font-size: 1.2rem;
    padding-left: .4rem;
    font-weight: 200;
  }
`
