import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from 'lexical'
import { useLayoutEffect, useState } from 'react'

export function FocusPlugin({ onFocus, onBlur }: any): null {
  const [editor] = useLexicalComposerContext()

  const [hasFocus, setHasFocus] = useState(() => {
    return editor.getRootElement() === document.activeElement
  })

  useLayoutEffect(() => {
    setHasFocus(editor.getRootElement() === document.activeElement)
    return mergeRegister(
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          setHasFocus(true)
          onFocus()
          return true
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          setHasFocus(false)
          onBlur()
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
    )
  }, [editor])

  return null
}
