import { a, k, useCss } from 'kremling'
import { bool, func, object, string } from 'prop-types'
import React from 'react'

import { Card } from '@components/card.component'
import { Icon } from '@components/icon.component'
import { useController } from 'react-hook-form'

export function WorkflowLink({ field, readOnly, isRequired, control }) {
  const scope = useCss(css)
  const hasProtocol = linkHasProtocol(field.link)
  const url = hasProtocol ? field.link : `//${field.link}`
  const domain = new URL(hasProtocol ? url : `http:${url}`)

  const {
    field: { onChange, value, disabled },
    fieldState: { error },
  } = useController({
    name: `${field.version}_${field.id}`,
    disabled: readOnly,
    control,
    rules: {
      required: isRequired && 'This field is required',
    },
  })

  function linkHasProtocol(str) {
    const pattern = new RegExp('^(https?:\\/\\/)')
    return !!pattern.test(str)
  }

  function handleClick() {
    if (disabled) return
    onChange(true)
  }

  return (
    <>
      <a
        onClick={handleClick}
        onAuxClick={handleClick}
        className="link-container"
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        {...scope}
      >
        <Card className={a('link-card').m('has-clicked', value)}>
          <div className="field-info">
            {/* <img
              className="mr-3"
              src={`https://api.statvoo.com/favicon/?url=${domain.hostname}`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.remove()
              }}
              width="32"
              height="32"
              alt={`${domain.hostname} image`}
            /> */}
            <div>
              {field.label || domain.hostname}
              {isRequired && <span className="aria-invisible"> Required</span>}
            </div>
            {value && (
              <Icon
                name="check-circle"
                className="completed-icon ml-4"
              />
            )}
          </div>
          <Icon name="ext-link" />
        </Card>
      </a>
      {error?.message && (
        <div className="mt-1 text-xl text-danger">{error.message}</div>
      )}
    </>
  )
}

WorkflowLink.propTypes = {
  field: object,
  onChange: func,
  value: string,
  readOnly: bool,
}

const css = k`
  .link-container:hover {
    text-decoration: none;
  }

  .link-card {
    padding: 1.6rem;
    color: $base-font-color;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .link-card:hover {
    background: $color-grey-25;
  }

  .link-card.has-clicked {
    background: $color-grey-25;
  }

  .field-info {
    display: flex;
    align-items: center;
  }

  .completed-icon {
    color: $color-primary;
  }
`
