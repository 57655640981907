import { EmptyState } from '@components/empty-state.component'
import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Separator } from '@components/radix/separator'
import {
  DndContext,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { modalService } from '@utils/modal.service'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { RRule } from 'rrule'
import { Fields } from '../client/workflow-refactor/fields.component'
import {
  fieldsList,
  generateNewFieldData,
  nonCompletableFields,
} from '../fields.helper'
import { Field } from './field.component'
import { SectionSettingsModal } from './section-settings-modal.component'

function abbreviateDays(text) {
  const dayAbbreviations = {
    Monday: 'Mon',
    Tuesday: 'Tue',
    Wednesday: 'Wed',
    Thursday: 'Thu',
    Friday: 'Fri',
    Saturday: 'Sat',
    Sunday: 'Sun',
  }

  // Replace full day names with their abbreviations
  Object.entries(dayAbbreviations).forEach(([day, abbreviation]) => {
    const regex = new RegExp(day, 'g')
    text = text.replace(regex, abbreviation)
  })

  return text
}

function recurrenceRuleToTextWithoutCount(recurrenceRuleString) {
  const rule = RRule.fromString(recurrenceRuleString)
  const options = { ...rule.origOptions }
  delete options.count
  const modifiedRule = new RRule(options)
  return modifiedRule.toText()
}

//TODO Evaluate if a field change should trigger a version update
// All fields
//   - If the text for the title has changed substantially
//   - If the text for the description has changed substantially
// Text fields
//   - If the textType for a text field changed
//   - If the data label for a number field changed substantially
// Choices fields
//   - If the choices list changed substantially for radio, checkbox, or select
// Link fields
// - If the link changed for a link field
// - If the link changed for a youtube field

export function EditSection({
  updateSection,
  section,
  previewOnly,
  preview,
  template,
  onClose,
}) {
  const [newField, setNewField] = useState(null)
  const [editingSectionName, setEditingSectionName] = useState(false)
  const [newSectionName, setNewSectionName] = useState('')
  const fieldListId = 'field-list-dnd'

  const { setNodeRef } = useDroppable({
    id: fieldListId,
  })

  function addField(field) {
    const fields = [...section.fields]
    const newField = generateNewFieldData(field, template.version)
    fields.push(newField)

    const newSection = {
      ...section,
      fields,
    }

    if (
      section.constraints.requiredMode === 'all' &&
      !nonCompletableFields.includes(field.name)
    ) {
      newSection.constraints.required.push(newField.id)
    }

    updateSection(newSection)
    setNewField(newField.id)
  }

  function removeField(fieldId) {
    const fields = [...section.fields]
    const fieldIndex = fields.findIndex(field => fieldId === field.id)
    fields.splice(fieldIndex, 1)

    const required = section.constraints.required.filter(id => id !== fieldId)

    updateSection({
      ...section,
      constraints: {
        ...section.constraints,
        required,
      },
      fields,
    })
  }

  function updateField(updatedField, debounce = true) {
    const fieldIndex = section.fields.findIndex(
      field => updatedField.id === field.id,
    )
    const fields = [
      ...section.fields.slice(0, fieldIndex),
      updatedField,
      ...section.fields.slice(fieldIndex + 1),
    ]
    updateSection(
      {
        ...section,
        fields,
      },
      debounce,
    )
  }

  // async function shouldUpdateFieldVersion(updatedField, oldField, cb) {
  //   try {
  //     const messages = [
  //       {
  //         role: 'system',
  //         content: `
  //           You will act as a detail-oriented evaluator. I will provide you with two fields represented as javascript objects.
  //           Your job will be to determine if the two fields are different enough to warrant a version update.
  //           Cases for a version update:
  //           All fields:
  //            - If the text for the title has changed substantially
  //            - If the text for the description has changed substantially
  //           TEXT fields:
  //            - If the textType for a text field changed
  //            - If the data label for a number field changed substantially
  //           CHECKBOX, RADIO, SELECT fields:
  //            - If the choices list changed substantially for radio, checkbox, or select
  //            - If a choice was added or removed
  //           LINK or YOUTUBE fields:
  //            - If the link changed for a link field
  //            - If the link changed for a youtube field
  //
  //            Cases that don't warrant a version update:
  //            - Typo fixes
  //            - Minor text changes
  //            - Reordering of choices
  //
  //            Your response should be in valid JSON format.
  //
  //            Here is an example of a valid response:
  //            {
  //               shouldUpdate: true
  //            }
  //         `,
  //       },
  //       {
  //         role: 'user',
  //         content: `
  //           Here is the old field: ${JSON.stringify(oldField)}
  //           Here is the new field: ${JSON.stringify(updatedField)}
  //         `,
  //       },
  //     ]
  //     const res = await wretch(
  //       `${process.env.REST_URL_PREFIX}/allFunctions-sendPrompt`,
  //     )
  //       .post({ messages })
  //       .json()
  //     const lastMessage = res.at(-1)
  //     const { shouldUpdate } = JSON.parse(lastMessage.content)
  //     console.log(shouldUpdate)
  //     cb(shouldUpdate)
  //   } catch (err) {
  //     return console.error(err)
  //   }
  // }

  const recurrenceText = section.constraints.recurrence
    ? abbreviateDays(
        recurrenceRuleToTextWithoutCount(section.constraints.recurrence),
      )
    : 'No repeat'

  function getRequiredText() {
    const { requiredMode, anyRequiredCount } = section.constraints
    if (requiredMode === 'all') return 'All fields required'
    if (requiredMode === 'any')
      return `Complete ${anyRequiredCount} field${anyRequiredCount > 1 ? 's' : ''}`
    if (requiredMode === 'none') return 'No fields required'
    if (requiredMode === 'custom') return 'Custom required'
  }

  function getRepeatText() {
    if (section.constraints.repeat && section.constraints.recurrence) {
      return `${section.constraints.repeat} times`
    } else {
      return 'Until end date'
    }
  }

  function updateRequiredToggle(value, fieldId) {
    const { required } = section.constraints
    let newRequiredMode = 'custom'

    const newRequired = value
      ? [...required, fieldId]
      : required.filter(id => id !== fieldId)

    if (newRequired.length === section.fields.length) {
      newRequiredMode = 'all'
    }

    if (newRequired.length === 0) {
      newRequiredMode = 'none'
    }

    updateSection({
      ...section,
      constraints: {
        ...section.constraints,
        requiredMode: newRequiredMode,
        required: newRequired,
        anyRequiredCount: 0,
      },
    })
  }

  function getFieldIsRequired(field) {
    const { required, requiredMode } = section.constraints
    if (requiredMode === 'all') return required.includes(field.id)
    if (requiredMode === 'none') return false
    if (requiredMode === 'custom') return required.includes(field.id)
  }

  function renderFields() {
    if (section.fields.length) {
      return (
        <div
          ref={setNodeRef}
          className="template-content__container"
        >
          <div className="flex flex-col gap-2 mb-4">
            <div className="text-3xl font-bold">
              {editingSectionName ? (
                <div>
                  <form className="flex items-center gap-1">
                    <TextInput
                      autoFocus
                      onFocus={e => e.target.select()}
                      value={newSectionName}
                      onChange={text => setNewSectionName(text)}
                    />
                    <div className="space-y-1">
                      <ActionIcon
                        type="submit"
                        icon="check"
                        size="sm"
                        onClick={() => {
                          updateSection(
                            { ...section, name: newSectionName },
                            true,
                          )
                          setEditingSectionName(false)
                          setNewSectionName('')
                        }}
                      />
                      <ActionIcon
                        icon="times"
                        size="sm"
                        onClick={() => {
                          setEditingSectionName(false)
                          setNewSectionName('')
                        }}
                      />
                    </div>
                  </form>
                </div>
              ) : (
                <div className="flex items-center gap-2">
                  {section.name || 'Untitled section'}
                  <ActionIcon
                    icon="pencil"
                    size="sm"
                    onClick={() => {
                      setEditingSectionName(true)
                      setNewSectionName(section.name)
                    }}
                  />
                </div>
              )}
            </div>
            <button
              disabled={isEmpty(section.fields)}
              onClick={openSectionSettings}
              className="p-4 h-16 rounded-md bg-active-50 text-2xl font-semibold flex items-center justify-between hover:bg-active-100 transition-colors duration-200 ease-in-out"
            >
              <div className="flex items-center justify-between h-full w-full">
                <div className="flex items-center gap-2 h-full w-full">
                  <Icon
                    name="repeat"
                    size={18}
                  />
                  {recurrenceText && (
                    <div>
                      {recurrenceText.charAt(0).toUpperCase() +
                        recurrenceText.slice(1)}
                    </div>
                  )}
                  {section.constraints.recurrence && (
                    <>
                      <Separator orientation="vertical" />
                      <div>{getRepeatText()}</div>
                    </>
                  )}
                </div>
                <div className="flex items-center shrink-0 gap-2">
                  <Icon
                    name="asterisk"
                    size="18"
                  />
                  {getRequiredText()}
                </div>
              </div>
            </button>
          </div>
          <SortableContext
            items={section.fields.map(field => field.id)}
            strategy={verticalListSortingStrategy}
          >
            {section.fields.map(field => (
              <Field
                field={field}
                isNew={field.id === newField}
                removeField={removeField}
                updateField={updateField}
                updateRequiredToggle={updateRequiredToggle}
                key={field.id}
                isRequired={getFieldIsRequired(field)}
              />
            ))}
          </SortableContext>
        </div>
      )
    } else {
      return (
        <EmptyState
          title="There are no fields in this section."
          subtitle="To add a field, choose from the options in the toolbar below."
        />
      )
    }
  }

  function onDragEnd({ active, over }) {
    if (active.id !== over.id) {
      const oldIndex = section.fields.findIndex(f => f.id === active.id)
      const newIndex = section.fields.findIndex(f => f.id === over.id)
      const fields = arrayMove(section.fields, oldIndex, newIndex)

      updateSection({
        ...section,
        fields,
      })
    }
  }

  async function openSectionSettings() {
    const newConstraints = await modalService.render(SectionSettingsModal, {
      isSequential: template.isSequential,
      section,
    })
    updateSection(
      {
        ...section,
        constraints: newConstraints,
      },
      false,
    )
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 15,
      },
    }),
  )

  return (
    <div className="edit-section-container">
      <div className="pl-4 pt-4">
        <ActionIcon
          icon="list-radio"
          onClick={onClose}
          className="media4:hidden"
          size="md"
        />
      </div>
      {!preview && !previewOnly ? (
        <DndContext
          onDragEnd={onDragEnd}
          modifiers={[
            restrictToVerticalAxis,
            restrictToFirstScrollableAncestor,
          ]}
          sensors={sensors}
        >
          <div className="template-content__wrapper">
            {!preview && !previewOnly && (
              <>
                {renderFields()}
                <div>
                  <div className="field-toolbar">
                    {fieldsList.map(field => (
                      <div
                        onClick={() => addField(field)}
                        key={field.name}
                        className="field-toolbar__item"
                      >
                        <div>
                          <Icon
                            name={field.icon}
                            size={16}
                          />
                        </div>
                        <div>{field.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </DndContext>
      ) : (
        <div className="flex justify-center w-full pt-10 flex-1 overflow-auto">
          <div className="w-full max-w-[70rem]">
            <Fields
              key={section.id}
              section={section}
              readOnly={true}
            />
          </div>
        </div>
      )}
    </div>
  )
}

EditSection.propTypes = {}
