import { Button } from '@components/mantine/button.component'
import { Modal } from '@components/modal.component'
import { DateTime } from 'luxon'
import React from 'react'
import { useCountdown } from './hooks/use-countdown.hook'

export function LockedSectionInfoModal({
  unlockDate,
  onReject,
}: {
  unlockDate: DateTime
  onReject: () => void
}) {
  const countdown = useCountdown(unlockDate)
  return (
    <>
      <Modal.Header>
        <h2>Time for a break!</h2>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-12">
          <div className="space-y-4">
            <p>
              This section you've chosen is currently locked, but that's a good
              thing! Studies show taking breaks between learning sessions helps
              retain information for complex topics.
            </p>
            <p>Why not take a quick breather before diving in?</p>
          </div>
          <div className="inline-flex items-center rounded-lg overflow-hidden">
            <div className="p-4 bg-active-75 font-semibold">
              This section will open in
            </div>
            <div className="p-4 bg-stats-two text-white font-bold">
              {countdown.toFormat('hh:mm:ss')}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <form
          onSubmit={e => {
            e.preventDefault()
            onReject()
          }}
        >
          <Button
            autoFocus
            type="submit"
            variant="primary"
          >
            Return
          </Button>
        </form>
      </Modal.Footer>
    </>
  )
}
