import React from 'react'
import {
  collection,
  getCountFromServer,
  query,
  where,
} from 'firebase/firestore'
import { useCss, k } from 'kremling'

import { useLoad } from '@hooks/use-load.hook'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getUserFullName } from '@utils/helpers'
import { Pill } from '@components/pill/pill.component'
import { Icon } from '@components/icon.component'

export function WorkspaceInviteBody({ org, inviteUser }) {
  const scoped = useCss(css)
  const [usersCount] = useLoad(
    null,
    () => {
      const q = query(
        collection(db, dbNames.users),
        where('orgOrderIds', 'array-contains', org.id),
      )
      return getCountFromServer(q).then(snap => snap.data().count)
    },
    [org.id],
  )

  const [workflowsCount] = useLoad(
    null,
    () => {
      const q = query(
        collection(db, dbNames.activities),
        where('orgId', '==', org.id),
        where('completedDate', '!=', null),
      )
      return getCountFromServer(q).then(snap => snap.data().count)
    },
    [org.id],
  )

  return (
    <div
      {...scoped}
      className="invitation-details__body"
    >
      <div className="user__title">
        <div className="user-avatar">
          <Icon
            name="user"
            size={10}
          />
        </div>{' '}
        {getUserFullName(inviteUser)}
      </div>
      <div className="mt-5">Has invited you to join a workspace</div>
      <div className="description__container">
        <div className="workspace__title">{org.name}</div>
        <div>
          <Pill
            pillType="light"
            className="mr-2"
          >
            {usersCount} active user{usersCount === 1 ? '' : 's'}
          </Pill>
          <Pill pillType="light">
            {workflowsCount} active workflow{workflowsCount === 1 ? '' : 's'}
          </Pill>
        </div>
      </div>
    </div>
  )
}

const css = k`
  .workspace__title {
    margin-bottom: 12px;
    font-size: 2.5rem;
    font-weight: 500;
    color: $color-primary;
  }
`
