import React, { CSSProperties, SVGProps } from 'react'
import { k, useCss } from 'kremling'

export type IconProps = Omit<SVGProps<SVGElement>, 'ref'> & {
  name: Icons | string
  size?: number
  className?: string
  fill?: string
}

export function Icon(props: IconProps) {
  const { name, size, fill, className, style = {}, ...rest } = props
  const scoped = useCss(css)
  const sizes: CSSProperties = {}
  if (typeof size === 'number') {
    sizes.width = `${size / 10}rem`
    sizes.height = `${size / 10}rem`
  }

  return (
    <svg
      {...scoped}
      {...rest}
      className={`icon ${className || ''}`}
      style={{ fill, ...sizes, ...style }}
    >
      <use
        href={`#${name}`}
        xlinkHref={`#${name}`}
      />
    </svg>
  )
}

const css = k`
  .icon {
    width: 2rem;
    height: 2rem;
    fill: currentColor;
  }
`
