import ls from 'local-storage'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { Header } from '@components/navigation/header.component'
import { MobileWorkspaceNav } from '@components/navigation/mobile-workspace-nav.component'
import { ProtectedRoute } from '@src/navigation/route.helper'
import { CreateNewPassword } from '@src/pages/account/auth/create-new-password.component'
import { ResetPassword } from '@src/pages/account/auth/reset-password.component'
import { SignIn } from '@src/pages/account/auth/sign-in.component'
import { InvitationLogin } from '@src/pages/account/invitation-login.component'
import { Profile } from '@src/pages/account/profile/profile.component'
import { FunnelRegister } from '@src/pages/account/registration/funnel-register.component'
import { InviteRegister } from '@src/pages/account/registration/invite-register.component'
import { Register } from '@src/pages/account/registration/register.component'
import { Admin } from '@src/pages/admin/admin.component'
import { EditGroup } from '@src/pages/admin/groups/edit-group.component'
import { Groups } from '@src/pages/admin/groups/groups.component'
import { OrgSettings } from '@src/pages/admin/org-settings.component'
import { EditUser } from '@src/pages/admin/users/edit-user.component'
import { Users } from '@src/pages/admin/users/users.component'
import { AppEmptyState } from '@src/pages/app-empty-state/app-empty-state.component'
import { Dashboard } from '@src/pages/dashboard/dashboard.component'
import { Library } from '@src/pages/library/workspace/library.component'
import { ArchivedTemplatesList } from '@src/pages/library/workspace/lists/archived-templates-list.component'
import { EditingTemplatesList } from '@src/pages/library/workspace/lists/editing-templates-list.component'
import { PublishedTemplatesList } from '@src/pages/library/workspace/lists/published-templates-list.component'
import { Marketplace } from '@src/pages/marketplace/marketplace.component'
import { Mentoring } from '@src/pages/mentoring/mentoring.component'
import { WorkflowChatContainer } from '@src/pages/messaging/page/workflow-chat-container.component'
import { WorkspaceHome } from '@src/pages/my-work/workspace-home.component'
import { Notifications } from '@src/pages/notifications/notifications.component'
import { MarketplaceSettings } from '@src/pages/super-settings/marketplace-settings.component'
import { OrgDetailPage } from '@src/pages/super-settings/org-detail-page.component'
import { SuperSettingsLibrary } from '@src/pages/super-settings/super-settings-library.component'
import { SuperSettingsOrgs } from '@src/pages/super-settings/super-settings-orgs.component'
import { SuperSettingsSupers } from '@src/pages/super-settings/super-settings-supers.component'
import { SuperSettings } from '@src/pages/super-settings/super-settings.component'
import { TemplateGenerator } from '@src/pages/workflow-template-list/template-generator/template-generator.component'
import { WorkflowTemplates } from '@src/pages/workflow-template-list/workflow-templates.component'
import { WorkflowMentors } from '@src/pages/workflow/client/workflow-mentors.component'
import { ActivityDashboard } from '@src/pages/workflow/client/workflow-refactor/activity-dashboard.component'
import { Activity } from '@src/pages/workflow/client/workflow-refactor/activity.component'
import { Section } from '@src/pages/workflow/client/workflow-refactor/section.component'
import {
  ActivityRegisterSuccess,
  HeadlessActivity,
} from '@src/pages/workflow/headless/headless-activity.component'
import { ActivityChallenges } from '@src/pages/workflow/settings/activity-challenges.component'
import { ActivityGeneralSettings } from '@src/pages/workflow/settings/activity-general-settings.component'
import { ActivityFieldsOverview } from '@src/pages/workflow/settings/activity-overview/activity-fields-overview.component'
import { ActivityParticipants } from '@src/pages/workflow/settings/activity-participants.component'
import { ActivityProgress } from '@src/pages/workflow/settings/activity-progress.component'
import { ActivitySettings } from '@src/pages/workflow/settings/activity-settings.component'
import { WorkflowTemplate } from '@src/pages/workflow/template/workflow-template.component'

const marketPlaceIsEnabled = process.env.MARKETPLACE == 'true'
const enableMarketplace =
  ls.get('iact-marketplace-feature') || marketPlaceIsEnabled
const templateGeneratorEnabled = process.env.OPENAI_TEMPLATES
const isDevelopment = process.env.NODE_ENV !== 'production'
const enableRegister = ls.get('iact-register-feature') || isDevelopment

const activityChildren = [
  {
    path: ':sectionId',
    element: <Section />,
    children: [
      {
        path: ':iterationId',
        element: <Section />,
      },
    ],
  },
  {
    path: 'mentors',
    element: <WorkflowMentors />,
  },
  {
    path: 'messaging',
    element: <WorkflowChatContainer />,
  },
  {
    path: 'leaderboards',
    element: <ActivityChallenges renderHeader />,
  },
  {
    path: '',
    element: <ActivityDashboard />,
  },
]

const workspaceRoutes = [
  {
    path: 'home/:userId/:activityId',
    element: (
      <ProtectedRoute blockedRoles={[]}>
        <Activity />
      </ProtectedRoute>
    ),
    children: activityChildren,
  },
  {
    path: 'home',
    element: (
      <ProtectedRoute blockedRoles={[]}>
        <WorkspaceHome />
      </ProtectedRoute>
    ),
    children: activityChildren,
  },
  {
    path: 'mentoring',
    element: (
      <ProtectedRoute blockedRoles={[]}>
        <Mentoring />
      </ProtectedRoute>
    ),
  },
  {
    path: 'mentoring/:userId/:activityId',
    element: (
      <ProtectedRoute blockedRoles={[]}>
        <Activity />
      </ProtectedRoute>
    ),
    children: activityChildren,
  },
  {
    path: 'mentoring/:activityId/settings/*',
    element: (
      <ProtectedRoute blockedRoles={[]}>
        <ActivitySettings />
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'progress',
        element: <ActivityProgress />,
      },
      {
        path: 'general-settings',
        element: <ActivityGeneralSettings />,
      },
      {
        path: 'sharing',
        element: <ActivityParticipants />,
      },
      {
        path: 'leaderboards',
        element: <ActivityChallenges renderHeader />,
      },
      {
        path: 'messages',
        element: <WorkflowChatContainer />,
      },
      {
        path: 'overview',
        element: <ActivityFieldsOverview />,
      },
      {
        path: '',
        element: <Navigate to="progress" />,
      },
    ],
  },
  {
    path: 'library/*',
    element: (
      <ProtectedRoute blockedRoles={[]}>
        <Library />
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'templates',
        element: <EditingTemplatesList />,
      },
      {
        path: 'archived',
        element: <ArchivedTemplatesList />,
      },
      {
        path: 'templates/t/:templateId',
        element: <WorkflowTemplate />,
      },
      {
        path: 'archived/t/:templateId',
        element: <WorkflowTemplate />,
      },
      {
        path: 'published/t/:templateId',
        element: <WorkflowTemplate type="preview" />,
      },
      {
        path: 'published/marketplace/t/:templateId',
        element: <WorkflowTemplate type="adopted-marketplace-preview" />,
      },
      ...(templateGeneratorEnabled
        ? [
            {
              path: 'templates/t/template-generator/:assistantId',
              element: <TemplateGenerator />,
            },
          ]
        : []),
      {
        path: 'published',
        element: <PublishedTemplatesList />,
      },
      {
        path: '',
        element: <Navigate to="templates" />,
      },
    ],
  },
  {
    path: 'workspace-library/:templateId',
    element: (
      <ProtectedRoute blockedRoles={[]}>
        <WorkflowTemplate type="preview" />
      </ProtectedRoute>
    ),
  },
  {
    path: 'templates',
    element: (
      <ProtectedRoute blockedRoles={[]}>
        <WorkflowTemplates />
      </ProtectedRoute>
    ),
  },
  {
    path: 'templates/:templateId',
    element: (
      <ProtectedRoute blockedRoles={[]}>
        <WorkflowTemplate />
      </ProtectedRoute>
    ),
  },
  {
    path: 'previews/:templateId',
    element: (
      <ProtectedRoute blockedRoles={[]}>
        <WorkflowTemplate type="preview" />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin',
    element: (
      <ProtectedRoute blockedRoles={['member', 'mentor', 'editor']}>
        <Admin />
      </ProtectedRoute>
    ),
    children: [
      { path: 'users', element: <Users /> },
      { path: 'users/:userId', element: <EditUser /> },
      { path: 'groups', element: <Groups /> },
      { path: 'groups/:groupId', element: <EditGroup /> },
      { path: 'templates/:templateId', element: <WorkflowTemplate /> },
      { path: 'settings', element: <OrgSettings /> },
      { path: '', element: <Navigate to="users" /> },
    ],
  },
  { path: '', element: <Navigate to="home" /> },
]

const utilityRoutes = [
  {
    path: '/notifications',
    element: (
      <ProtectedRoute blockedRoles={[]}>
        <Notifications />
      </ProtectedRoute>
    ),
  },
  {
    path: '/profile',
    element: (
      <ProtectedRoute blockedRoles={[]}>
        <Profile />
      </ProtectedRoute>
    ),
  },
  {
    path: '/super-settings',
    element: (
      <ProtectedRoute>
        <SuperSettings />
      </ProtectedRoute>
    ),
    children: [
      { path: 'orgs', element: <SuperSettingsOrgs /> },
      { path: 'orgs/:workspaceId', element: <OrgDetailPage /> },
      { path: 'supers', element: <SuperSettingsSupers /> },
      { path: 'library', element: <SuperSettingsLibrary /> },
      { path: 'library/t/:templateId', element: <WorkflowTemplate /> },
      { path: 'marketplace-settings', element: <MarketplaceSettings /> },
      { path: '', element: <Navigate to="orgs" /> },
    ],
  },
]

const accountRoutes = [
  { path: '/sign-in', element: <SignIn hideOptions={!enableRegister} /> },
  ...(enableRegister ? [{ path: '/register', element: <Register /> }] : []),
  { path: '/register-purchase/:purchaseId', element: <FunnelRegister /> },
  { path: '/reset-password', element: <ResetPassword /> },
  { path: '/new-password/:oobCode', element: <CreateNewPassword /> },
  { path: '/invitation-login', element: <InvitationLogin /> },
  { path: '/invite-register/:activityId', element: <InviteRegister /> },
  { path: '', element: <Navigate to="/sign-in" /> },
]

const routesWithHeader = [
  ...utilityRoutes,
  ...(enableMarketplace
    ? [
        {
          path: '/marketplace',
          element: (
            <ProtectedRoute blockedRoles={[]}>
              <Marketplace />
            </ProtectedRoute>
          ),
        },
        {
          path: '/marketplace/templates/:templateId',
          element: (
            <ProtectedRoute blockedRoles={[]}>
              <WorkflowTemplate type="marketplace" />
            </ProtectedRoute>
          ),
        },
      ]
    : []),
  {
    path: '/dashboard/*',
    element: (
      <ProtectedRoute blockedRoles={[]}>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: '/welcome',
    element: <AppEmptyState />,
  },
]

const routesWithoutHeader = [
  ...accountRoutes,
  {
    path: '/activity/:linkId/*',
    element: <HeadlessActivity />,
  },
  {
    path: '/guest/:userId/:activityId/:editToken',
    element: (
      <div className="content-wrapper headless">
        <Activity headless />
      </div>
    ),
    children: activityChildren,
  },
  {
    path: '/activity-register-success/:editToken',
    element: <ActivityRegisterSuccess />,
  },
  { path: '/activity/:linkId/*', element: <HeadlessActivity /> },
  {
    path: '/guest/:userId/:activityId/:editToken',
    element: (
      <div className="content-wrapper headless">
        <Activity headless />
      </div>
    ),
    children: activityChildren,
  },
  {
    path: '/activity-register-success/:editToken',
    element: <ActivityRegisterSuccess />,
  },
]

export const useGetRoutes = () => {
  return [
    {
      element: <FullPageLayout />,
      children: routesWithoutHeader,
    },
    {
      path: '/:orgId',
      element: (
        <ProtectedRoute blockedRoles={[]}>
          <WorkspaceLayout />
        </ProtectedRoute>
      ),
      children: workspaceRoutes,
    },
    {
      element: (
        <ProtectedRoute blockedRoles={[]}>
          <ContentLayout />
        </ProtectedRoute>
      ),
      children: routesWithHeader,
    },
  ]
}

function ContentLayout() {
  return (
    <div className="content-wrapper">
      <div className="media4:px-6 media4:pb-6 bg-active-50 w-full flex flex-col">
        <Header showWorkspaceNav={false} />
        <div className="bg-white shadow-lg rounded-t-xl media4:rounded-lg flex-1 overflow-auto">
          <div className="right-container">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

function WorkspaceLayout() {
  return (
    <div className="content-wrapper">
      <div className="media4:px-6 media4:pb-6 bg-active-50 w-full flex flex-col">
        <Header />
        <div className="bg-white shadow-lg rounded-t-xl media4:rounded-lg overflow-hidden flex-1">
          <div className="right-container relative">
            {/*<WorkspaceNav />*/}
            <div className="overflow-auto h-full mb-10 media2:mb-0">
              <Outlet />
            </div>
            <div className="fixed bottom-0 w-full z-10">
              <MobileWorkspaceNav />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function FullPageLayout() {
  return (
    <div className="content-wrapper">
      <div className="bg-white shadow-lg flex-1 overflow-auto">
        <div className="right-container">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
