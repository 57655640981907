import { FirebaseStorageError } from '@firebase/storage-types'
import { useAppState } from '@src/app.state'
import { db, storage } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { toastService } from '@utils/toast.service'
import firebase from 'firebase/compat'
import { addDoc, collection } from 'firebase/firestore'
import {
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ulid } from 'ulid'
import UploadTaskSnapshot = firebase.storage.UploadTaskSnapshot

export type UploadOptions = {
  user: { id: string }
  orgId: string
  uploadToPath?: string
  fileUploadPath?: string
  file?: File
  suspendUpload?: boolean
  onSuccess?: (uploadedFiles: any[]) => void
}

type FileDetails = {
  name: string
  url: string
  type: string
  path: string
  uploadedDate: string
  userId: string
  orgId: string | null
  uploadedToType: string
  uploadToPath: string
  fileId?: string
}

export function useFileUpload({
  onSingleFileSuccess,
  uploadToType,
}: {
  onSingleFileSuccess?: (details: FileDetails) => void
  uploadToType: string
}) {
  const [uploading, setUploading] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const [filesData, setFilesData] = useState<File[]>([])
  const [filePreviews, setFilePreviews] = useState<string[]>([])
  const { orgId } = useParams<{ orgId?: string }>()

  function setFiles(files: FileList) {
    if (isEmpty(files)) {
      setFilesData([])
      setFilePreviews([])
      return
    }
    ;[...files].forEach(readAndPreview)
  }

  function readAndPreview(file: File) {
    const fileReader = new FileReader()
    fileReader.onloadend = function (e) {
      setFilePreviews(prev => [...prev, e.target.result as string])
      setFilesData(prev => [...prev, file])
    }
    fileReader.readAsDataURL(file)
  }

  async function onFileUploadSuccess(
    fileData: File,
    fileRef: any,
    options: UploadOptions,
  ): Promise<FileDetails> {
    try {
      const metadata = await getMetadata(fileRef)
      const url = await getDownloadURL(fileRef)
      const uploadFileDetails: FileDetails = {
        name: fileData.name,
        type: fileData.type,
        url,
        path: metadata.fullPath,
        uploadedDate: getTodayDate().toISO(),
        userId: options?.user?.id || useAppState.getState().user.id,
        orgId: orgId || options?.orgId || null,
        uploadedToType: uploadToType,
        uploadToPath: options?.uploadToPath || '',
      }
      const dbFileRef = await addDoc(
        collection(db, dbNames.files),
        uploadFileDetails,
      )
      uploadFileDetails.fileId = dbFileRef.id
      onSingleFileSuccess?.(uploadFileDetails)
      return uploadFileDetails
    } catch (err) {
      console.error('Error in onFileUploadSuccess:', err)
      toastService.error('Error uploading file. Please try again')
      throw err
    }
  }

  function createUploadTask(file: File, options: UploadOptions) {
    const fileRef = ref(storage, `files/${ulid()}/${file.name}`)
    return {
      task: uploadBytesResumable(fileRef, file),
      fileRef,
      fileData: file,
      options,
    }
  }

  async function executeUploadTask({
    task,
    fileRef,
    fileData,
    options,
  }: {
    task: any
    fileRef: any
    fileData: File
    options: UploadOptions
  }): Promise<FileDetails> {
    return new Promise((resolve, reject) => {
      task.on(
        'state_changed',
        (snapshot: UploadTaskSnapshot) => {
          const progressPercentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          setProgress(progressPercentage)
        },
        (err: FirebaseStorageError) => reject(err),
        () => resolve(onFileUploadSuccess(fileData, fileRef, options)),
      )
    })
  }

  async function doUpload(options: UploadOptions): Promise<FileDetails[]> {
    setUploading(true)
    setProgress(0)
    try {
      const filesToUpload = options?.file ? [options.file] : filesData
      const uploadedFiles: FileDetails[] = []
      for (const file of filesToUpload) {
        const { task, fileRef, fileData } = createUploadTask(file, options)
        const uploadedFile = await executeUploadTask({
          task,
          fileRef,
          fileData,
          options,
        })
        uploadedFiles.push(uploadedFile)
      }
      toastService.info(`Uploaded ${uploadedFiles.length} files`)
      options?.onSuccess?.(uploadedFiles)
      setUploading(false)
      return uploadedFiles
    } catch (err) {
      console.error('Error in doUpload:', err)
      setUploading(false)
      throw err
    }
  }

  return {
    setFiles,
    filesData,
    doUpload,
    uploading,
    progress,
    filePreviews,
  }
}
