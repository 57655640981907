import { Card } from '@components/card.component'
import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { deleteDoc, doc } from 'firebase/firestore'
import React from 'react'

type Props = {
  notification: {
    orgName: string
    id: string
  }
}

export function DeactivatedFromOrgNotificationItem({ notification }: Props) {
  const { orgName } = notification

  return (
    <Card className="decoration-0 p-4 flex justify-between mb-2">
      <div className="flex justify-between">
        <div>
          <div className="flex items-center mb-4 text-2xl font-semibold">
            <Icon
              className="mr-4"
              name="info"
              size={20}
            />
            You have been removed from{' '}
            <span className="font-semibold text-primary text-3xl mx-2">
              {orgName}
            </span>
          </div>
        </div>
        <ActionIcon
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            const d = doc(db, dbNames.notifications, notification.id)
            deleteDoc(d)
          }}
          icon="times"
          size="sm"
        />
      </div>
    </Card>
  )
}
