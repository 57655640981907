import { useGetRoutes } from '@src/navigation/use-get-routes.hook'
import React, { ForwardedRef } from 'react'
import {
  Link,
  matchRoutes,
  useLocation,
  useResolvedPath,
} from 'react-router-dom'

interface Props {
  to: string
  exact?: boolean
  children:
    | React.ReactNode
    | (({ isActive }: { isActive: boolean }) => React.ReactNode)
  className?: string
  activeClassName?: string
  inactiveClassName?: string
  state?: object
  onClick?: () => void
  disabled?: boolean
}

export const NavLink = React.forwardRef(
  (
    {
      to,
      exact = false,
      children,
      className = '',
      activeClassName = '',
      inactiveClassName = '',
      disabled,
      state = {},
      ...linkProps
    }: Props,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) => {
    const routes = useGetRoutes()
    const location = useLocation()
    const resolvedPath = useResolvedPath(to)
    const matches = matchRoutes(routes, location)

    let isActive
    if (exact) {
      isActive = location.pathname === resolvedPath.pathname
    } else {
      isActive = matches.some(match =>
        match.pathname.includes(resolvedPath.pathname),
      )
    }

    const allClassNames = [
      className,
      isActive ? activeClassName : inactiveClassName,
    ]
      .filter(Boolean)
      .join(' ')

    return (
      <Link
        to={disabled ? '#' : to + location.search}
        className={allClassNames}
        state={{ ...state }} //For some reason this has to be destructured to work
        ref={ref}
        {...linkProps}
      >
        {typeof children === 'function' ? children({ isActive }) : children}
      </Link>
    )
  },
)
