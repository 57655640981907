import { useLoad } from '@hooks/use-load.hook'
import { db } from '@src/firebase-app'
import { getTodayDate } from '@utils/helpers'
import {
  collection,
  documentId,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import {
  chunk,
  flatten,
  isEmpty,
  orderBy as lodashOrderBy,
  uniqBy,
} from 'lodash'

export const useActivitiesLoad = (
  selectedOrg,
  canAssignWorkflows,
  selectedTab,
  menteeUsers,
) => {
  const activitiesCollection = collection(db, 'activities')
  const createQuery = constraints => query(activitiesCollection, ...constraints)
  const fetchActivities = q =>
    getDocs(q).then(snap =>
      snap.docs.map(doc => ({ id: doc.id, ...doc.data() })),
    )

  const loadActivities = async () => {
    if (!selectedOrg) return []

    if (canAssignWorkflows) {
      const queries =
        selectedTab === 'active'
          ? [
              createQuery([
                orderBy('endDate.date'),
                where('orgId', '==', selectedOrg.id),
                where('completedDate', '==', null),
                where('endDate.date', '>=', getTodayDate().toISO()),
              ]),
            ]
          : [
              createQuery([
                where('orgId', '==', selectedOrg.id),
                where('completedDate', '!=', null),
              ]),
              createQuery([
                where('orgId', '==', selectedOrg.id),
                where('endDate.date', '<', getTodayDate().toISO()),
              ]),
            ]

      const results = await Promise.all(queries.map(fetchActivities))
        .then(flatten)
        .then(res => uniqBy(res, 'id'))
      return selectedTab === 'active'
        ? results
        : lodashOrderBy(results, 'endDate.date', 'desc')
    } else {
      if (isEmpty(menteeUsers)) return []

      const activityIds = menteeUsers.map(u => u.activityId)
      const chunks = chunk(activityIds, 10)
      const promises = chunks.map(chunk =>
        fetchActivities(createQuery([where(documentId(), 'in', chunk)])),
      )
      return flatten(await Promise.all(promises))
    }
  }

  return useLoad([], loadActivities, [
    selectedTab,
    selectedOrg?.id,
    menteeUsers,
  ])
}
