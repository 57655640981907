import { Badge } from '@components/badge.component'
import { Icon } from '@components/icon.component'
import { Logo } from '@components/logo.component'
import { Button } from '@components/mantine/button.component'
import { NotificationsDropdown } from '@components/navigation/notifications-dropdown.component'
import { WorkspaceNav } from '@components/navigation/workspace-nav.component'
import { AddOrgModal } from '@components/orgs/add-org-modal.component'
import { Dropdown } from '@components/radix/dropdown'
import { signOut as authSignOut } from '@firebase/auth'
import { useAppState } from '@src/app.state'
import { auth, db } from '@src/firebase-app'
import { NavLink } from '@src/navigation/nav-link.component'
import { dbNames } from '@utils/constants'
import { getTodayDate, getUserFullName } from '@utils/helpers'
import { modalService } from '@utils/modal.service'
import { toastService } from '@utils/toast.service'
import { doc, getDoc } from 'firebase/firestore'
import ls from 'local-storage'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

export function Header({ showWorkspaceNav = true }) {
  const user = useAppState(state => state.user)
  const orgs = useAppState(state => state.orgs)
  const appLoading = useAppState(state => state.appLoading)

  const fullName = getUserFullName(user)
  const [firstName, lastName] = fullName.split(' ')
  const params = useParams<{ orgId: string }>()
  const navigate = useNavigate()
  const location = useLocation()
  const emailVerified = auth.currentUser && auth.currentUser.emailVerified
  const enableMarketplace =
    process.env.MARKETPLACE == 'true' || ls('iact-marketplace-feature')
  const selectedOrg = useMemo(
    () => orgs.find(org => org.id === params.orgId),
    [orgs, params.orgId],
  )

  useEffect(() => {
    const selectedOrg = orgs.find(org => org.id === params.orgId)
    if (appLoading) return

    getPaidTier(selectedOrg).then(isFreeTier => {
      useAppState.setState({ isFreeTier })
    })
  }, [appLoading, params.orgId])

  // useEffect(() => {
  //   if (orgs.length === 0 && !appLoading) {
  //     navigate('/welcome')
  //   }
  // }, [orgs.length, appLoading])

  async function getPaidTier(selectedOrg: Organization) {
    let isFreeTier = true
    let _selectedOrg = selectedOrg
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    if (!_selectedOrg && params.orgId) {
      // const existingActivities = await getDocs(
      //   query(
      //     collection(db, dbNames.activityUsers),
      //     where('orgId', '==', params.orgId),
      //     where('userId', '==', user.id),
      //   ),
      // )
      // if (existingActivities.empty) {
      //   navigate('/dashboard')
      //   return
      // }

      _selectedOrg = (
        await getDoc(doc(db, dbNames.organizations, params.orgId))
      ).data() as Organization
    }

    if (_selectedOrg?.paidThroughDate) {
      const convertedDate = DateTime.fromISO(
        _selectedOrg.paidThroughDate.date,
        { zone: _selectedOrg?.paidThroughDate.timeZone },
      )
        .setZone(timeZone)
        .plus({ days: 1 }) //Paid period ends at the end of the set day
      const today = getTodayDate()
      isFreeTier = today > convertedDate
    }

    return isFreeTier
  }

  function openAddOrgModal() {
    modalService.render(AddOrgModal).then(newOrg => {
      navigate(`/${newOrg.id}`)
    })
  }

  function signOut() {
    authSignOut(auth)
      .then(() => {
        const subscribers = useAppState.getState().subscribers
        Object.values(subscribers).forEach(subscriber => subscriber())
        useAppState.setState({
          isAuthenticated: false,
          user: null,
          orgs: [],
          subscribers: {},
        })
        navigate('/sign-in')
      })
      .catch(err => {
        console.log(err)
        toastService.error(err.message)
      })
  }

  function getNavigationTitle() {
    if (location.pathname.includes('dashboard')) {
      return 'Dashboard'
    } else if (location.pathname.includes('marketplace')) {
      return 'Marketplace'
    } else if (location.pathname.includes('notifications')) {
      return 'Notifications'
    } else if (selectedOrg) {
      return selectedOrg?.name
    } else {
      return 'Select an option'
    }
  }

  function getNaviationIcon() {
    if (location.pathname === '/dashboard') {
      return (
        <Icon
          name="gauge-high"
          size={20}
          className="shrink-0"
        />
      )
    } else if (location.pathname === '/marketplace') {
      return (
        <Icon
          name="globe"
          size={20}
          className="shrink-0"
        />
      )
    } else if (location.pathname === '/notifications') {
      return (
        <Icon
          name="bell"
          size={20}
          className="shrink-0"
        />
      )
    } else {
      return (
        <div
          style={{ backgroundColor: selectedOrg?.themeColor }}
          className="h-8 w-8 rounded-full bg-grey-100 shrink-0"
        />
      )
    }
  }

  return (
    <div className="media4:px-0 h-[6rem] flex items-center p-4 media4:pb-4 justify-between gap-4 relative">
      <div className="flex items-center">
        {process.env.ENVIRONMENT !== 'production' ? (
          <button
            type="button"
            onClick={() =>
              useAppState.setState(({ showToolsOverlay }) => ({
                showToolsOverlay: !showToolsOverlay,
              }))
            }
          >
            <Logo />
          </button>
        ) : (
          <Logo />
        )}
      </div>
      {showWorkspaceNav && (
        <div className="flex gap-4 items-center justify-center absolute left-1/2 -translate-x-1/2">
          <WorkspaceNav />
        </div>
      )}

      <div className="flex flex-auto justify-end gap-3">
        {(user.isSuperAdmin || orgs.length > 1) && (
          <Dropdown>
            <Dropdown.Trigger className="h-[4rem] flex-1 py-2 px-4 max-w-[24rem] rounded-full flex justify-between border border-grey-100 items-center bg-white select-none hover:bg-grey-25 transition-colors">
              {
                <div className="flex gap-4 items-center">
                  {getNaviationIcon()}
                  <div className="truncate text-2xl font-semibold">
                    {getNavigationTitle()}
                  </div>
                </div>
              }
              <Icon
                name="caret-down"
                size={14}
                className="ml-4"
              />
            </Dropdown.Trigger>
            <Dropdown.Content align="center">
              <div className="w-[30rem] rounded-md shadow-lg bg-grey-25 flex flex-col overflow-hidden p-5 gap-8 max-h-[50rem] top-5">
                {(enableMarketplace || orgs.length > 1) && (
                  <div className="flex flex-col gap-5 shrink-0">
                    {orgs.length > 1 && (
                      <Dropdown.ListItem className="bg-white shadow-md rounded-md p-4 flex-1">
                        <NavLink
                          to="/dashboard"
                          activeClassName="outline outline-2 outline-primary focus-visible:outline-2"
                          inactiveClassName="text-grey-600 focus-visible:outline-none"
                          className="flex gap-4 focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary"
                        >
                          <Icon
                            className="shrink-0"
                            name="gauge-high"
                            size={20}
                          />
                          <span className="text-2xl font-semibold">
                            Dashboard
                          </span>
                        </NavLink>
                      </Dropdown.ListItem>
                    )}
                    {enableMarketplace && (
                      <Dropdown.ListItem className="bg-white shadow-md rounded-md h-[8rem] p-4 flex-1">
                        <NavLink
                          to="/marketplace"
                          activeClassName="outline outline-2 outline-primary focus-visible:outline-2"
                          inactiveClassName="text-grey-600 focus-visible:outline-none"
                          className="flex gap-4 focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary"
                        >
                          <Icon
                            className="shrink-0"
                            name="globe"
                            size={20}
                          />
                          <span className="text-2xl font-semibold">
                            Marketplace
                          </span>
                        </NavLink>
                      </Dropdown.ListItem>
                    )}
                    <Dropdown.ListItem className="bg-white shadow-md rounded-md h-[8rem] p-4 flex-1">
                      <NavLink
                        to="/notifications"
                        activeClassName="outline outline-2 outline-primary focus-visible:outline-2"
                        inactiveClassName="text-grey-600 focus-visible:outline-none"
                        className="flex gap-4 focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary"
                      >
                        <Icon
                          className="shrink-0"
                          name="bell"
                          size={20}
                        />
                        <span className="text-2xl font-semibold">
                          Notifications
                        </span>
                      </NavLink>
                    </Dropdown.ListItem>
                  </div>
                )}

                <div>
                  <div className="mb-2">Workspaces</div>
                  <div className="flex flex-col gap-4 overflow-auto no-scrollbar p-4 -m-4">
                    {orgs.map(org => (
                      <Dropdown.ListItem key={org.id}>
                        <NavLink
                          to={`/${org.id}`}
                          activeClassName="outline outline-2 outline-primary focus-visible:outline-2"
                          inactiveClassName="text-grey-600 focus-visible:outline-none"
                          className="flex items-center justify-between p-4 bg-white shadow-sm rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary"
                        >
                          <div className="flex gap-4 items-center">
                            <div
                              style={{ backgroundColor: org.themeColor }}
                              className="h-8 w-8 rounded-full shrink-0"
                            />
                            <div className="truncate text-2xl font-semibold">
                              {org.name}
                            </div>
                          </div>
                        </NavLink>
                      </Dropdown.ListItem>
                    ))}
                  </div>
                </div>

                {user.isSuperAdmin && (
                  <div className="flex justify-center">
                    <Dropdown.ListItem>
                      <Button
                        onClick={openAddOrgModal}
                        leftSection={<Icon name="plus" />}
                        variant="secondary"
                        className="w-full"
                      >
                        Create Workspace
                      </Button>
                    </Dropdown.ListItem>
                  </div>
                )}
              </div>
            </Dropdown.Content>
          </Dropdown>
        )}

        <NotificationsDropdown />

        <Dropdown>
          <Dropdown.Trigger className="h-[4rem] bg-white text-primary w-[4rem] border border-grey-100 rounded-full flex items-center justify-center focus:outline-none focus:bg-grey-50 select-none">
            {firstName && lastName && (
              <span className="text-2xl font-semibold">
                {firstName?.[0] + lastName?.[0]}
              </span>
            )}
          </Dropdown.Trigger>
          <Dropdown.Content align="end">
            <div className="bg-white rounded-md shadow-md flex flex-col overflow-hidden">
              <Dropdown.ListItem>
                <Link
                  to="/profile"
                  className="flex items-center gap-4 p-4 focus-visible:outline-none hover:bg-grey-25 text-grey-600 text-2xl font-semibold"
                >
                  {!emailVerified ? (
                    <Badge
                      showBadge={!emailVerified}
                      className="dropdown-badge"
                    />
                  ) : (
                    <Icon name="user" />
                  )}
                  <div>Profile</div>
                </Link>
              </Dropdown.ListItem>
              <Dropdown.Separator />
              <Dropdown.ListItem>
                <a
                  className="flex items-center gap-4 p-4 focus-visible:outline-none hover:bg-grey-25 text-grey-600 text-2xl font-semibold"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfbiBjhD8vwzjRp9CLOtxa-E26rdyd2zECSEwPuc65rec_OGw/viewform?usp=sf_link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Report a bug
                </a>
              </Dropdown.ListItem>
              <Dropdown.ListItem>
                <a
                  className="flex items-center gap-4 p-4 focus-visible:outline-none hover:bg-grey-25 text-grey-600 text-2xl font-semibold"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfbiBjhD8vwzjRp9CLOtxa-E26rdyd2zECSEwPuc65rec_OGw/viewform?usp=sf_link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Give feedback
                </a>
              </Dropdown.ListItem>
              <Dropdown.Separator />
              {user.isSuperAdmin && (
                <>
                  <Dropdown.ListItem>
                    <Link
                      to="/super-settings"
                      className="flex items-center gap-4 p-4 focus-visible:outline-none hover:bg-grey-25 text-grey-600 text-2xl font-semibold"
                    >
                      <Icon name="stars" />
                      Super Settings
                    </Link>
                  </Dropdown.ListItem>
                  <Dropdown.Separator />
                </>
              )}
              <Dropdown.ListItem>
                <button
                  className="flex items-center gap-4 p-4 focus-visible:outline-none hover:outline-none hover:bg-grey-25 text-2xl text-danger font-semibold"
                  onClick={signOut}
                >
                  <Icon name="sign-out" />
                  <div className="">Sign Out</div>
                </button>
              </Dropdown.ListItem>
            </div>
          </Dropdown.Content>
        </Dropdown>
      </div>
    </div>
  )
}
