import { omitBy, isNil } from 'lodash'
import { addDoc, collection } from 'firebase/firestore'
import wretch from 'wretch'

import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'

class SendUserAnalytics {
  events = {}

  registerEvent(newEvent, customId) {
    let id = customId
    if (!id) {
      id = Math.random().toString(36).substring(2)
    }
    this.events[id] = newEvent
    return id
  }

  updateEvent(id, newEventValue) {
    if (this.events[id]) {
      this.events[id] = {
        ...this.events[id],
        ...newEventValue,
      }
    }
  }

  getEvents(ids) {
    if (!ids) return this.events
    return ids.map(id => this.events[id]).filter(Boolean)
  }

  clearEvents() {
    this.events = {}
  }

  async sendEvents(newEvents) {
    if (newEvents) {
      newEvents.forEach(event => this.registerEvent(event))
    }
    const isDevelopment = process.env.NODE_ENV === 'development'
    try {
      await wretch(`${process.env.USER_ANALYTICS_URL}/challenge-response`).post(
        Object.values(this.events),
      )
      this.clearEvents()
    } catch (err) {
      if (!isDevelopment) {
        await Promise.all(
          Object.values(this.events).map(event => {
            return addDoc(collection(db, dbNames.analyticsErrors), {
              ...omitBy(event, isNil),
              errorMessage: err.message,
              errorCode: err.request.status,
            })
          }),
        )
      } else {
        console.warn(
          `You don't have a local copy of the analytics SQL database running. Analytics won't be saved, but the responses in the workflow will still save to Firestore. This only applies to local development.`,
        )
      }
      this.clearEvents()
    }
  }
}

export default new SendUserAnalytics()
