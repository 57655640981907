import React, { useEffect, useState } from 'react'

import { WorkflowLeaderboard } from '@src/pages/workflow/settings/challenge-items/workflow-leaderboard.component'
import { getIterationsCompletedCount } from '@src/pages/workflow/settings/analytics.resource'
import { toastService } from '@utils/toast.service'

export function WorkflowLeaderboardItemIterationCount({
  workflowChallenge,
  isAdmin,
  onEdit,
  onDelete,
  participantsCount,
}) {
  const [responses, setResponses] = useState([])

  useEffect(() => {
    getIterationsCompletedCount(workflowChallenge)
      .then(setResponses)
      .catch(err => {
        console.log(err)
        toastService.error(
          'There was an error retrieving challenge data. Please try again later.',
        )
      })
  }, [])

  return (
    <WorkflowLeaderboard
      workflowChallenge={workflowChallenge}
      responses={responses}
    >
      <WorkflowLeaderboard.Header
        canEdit={isAdmin}
        onEdit={onEdit}
        onDelete={onDelete}
        participantsCount={participantsCount}
      />
      <WorkflowLeaderboard.Body displayValue={value => `${value}`} />
    </WorkflowLeaderboard>
  )
}
