import { a, k, useCss } from 'kremling'
import React from 'react'

type RadioProps = {
  id: string
  name: string
  value: string
  label?: string
  checked?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
}

export function Radio({
  label = '',
  className = '',
  ...radioProps
}: RadioProps) {
  const { id } = radioProps
  const scope = useCss(css)
  return (
    <div
      {...scope}
      className={a('radio__container').m(className, !!className)}
    >
      <label
        htmlFor={id}
        className="radio-label"
      >
        <input
          className="radio-input"
          type="radio"
          id={id}
          {...radioProps}
        />
        <span className="custom-radio" />
        {label}
      </label>
    </div>
  )
}

const css = k`
  .radio__container {
    position: relative;
    height: 17px;
  }

  .radio-input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .custom-radio {
    cursor: pointer;
    width: 17px;
    height: 17px;
    border: 2px solid $color-grey-400;
    border-radius: 50%;
    display: inline-block;
    position: relative;
  }

  /* inner circle */
  .custom-radio::after {
    content: '';
    width: 8px;
    height: 8px;
    background: #fff;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  .radio-input:checked + span {
    border: 2px solid $color-primary;
    background: $color-primary;
  }

  .radio-input:checked + span:after {
    opacity: 1;
  }

  .radio-input:focus + span {
    outline: solid 2px $color-grey-300;
  }
`
