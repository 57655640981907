import { k, useCss } from 'kremling'
import React from 'react'

import { TextInput } from '@components/mantine/text-input.component'
import { RichText } from '@components/rich-text/rich-text.component'
import { useAppState } from '@src/app.state'
import { useParams } from 'react-router-dom'

export function FieldDescription({ field, onChange }) {
  const scope = useCss(css)
  const { orgId } = useParams()

  function updateFieldValue(value) {
    const updatedField = {
      ...field,
      value,
    }
    onChange(updatedField)
  }

  function updateFieldLabel(value) {
    if (value === field.label) return
    const updatedField = {
      ...field,
      label: value,
    }
    onChange(updatedField, true)
  }

  return (
    <div {...scope}>
      <div className="form-group">
        <TextInput
          label="Description title"
          defaultValue={field.label}
          onChange={updateFieldLabel}
          placeholder="Type here..."
          autoFocus
        />
      </div>
      <div className="form-group">
        <label htmlFor="text-field-desc">
          Provide some descriptive text for your user to read
        </label>
        <RichText
          id="text-field-desc"
          onChange={updateFieldValue}
          initialValue={field ? field.value : null}
          placeholder="Type here..."
          uploadOptions={{
            user: useAppState.getState().user,
            orgId,
          }}
        />
      </div>
    </div>
  )
}

FieldDescription.propTypes = {}

const css = k`

`
