import { k, useCss } from 'kremling'
import React, { useEffect } from 'react'

import { Card } from '@components/card.component'
import { Dropdown } from '@components/dropdown.component'
import { Icon } from '@components/icon.component'
import { InputField } from '@components/input'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { Button } from '@components/mantine/button.component'
import { Pill } from '@components/pill/pill.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db, functions } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { toastService } from '@utils/toast.service'
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

export function ManageEmails() {
  const user = useAppState(state => state.user)
  const navigate = useNavigate()
  const [params] = useSearchParams()

  const [emails, , emailsOpts] = useLoad(
    [],
    () => {
      if (!user.id) return Promise.resolve([])
      return getDocs(
        collection(db, dbNames.users, user.id, 'managedEmails'),
      ).then(e => e.docs.map(d => ({ id: d.id, ...d.data() })))
    },
    [user.id],
  ) as [ManagedEmail[], () => void, { reload: () => void }]

  useEffect(() => {
    const token = params.get('verify-email-token')
    if (token) {
      navigate('/profile', { replace: true })
      const destroyToast = toastService.info('Verifying Email...', {
        duration: 20000,
      })

      const verifyManagedEmail = httpsCallable(
        functions,
        'allFunctions-verifyManagedEmail',
      )
      verifyManagedEmail({ token }).then(({ data }: Record<string, any>) => {
        destroyToast()
        if (data.error) {
          toastService.error(data.message)
        } else if (data.success) {
          toastService.info(`Success!`, {
            description: `Email "${data.email}" has been verified.`,
          })
          emailsOpts.reload()
        }
      })
    }
  }, [])

  const handleAddEmail = ({ email }: FieldValues) => {
    sendVerificationEmail(email, false)
  }

  const sendVerificationEmail = async (email: string, resend: boolean) => {
    const destroyToast = toastService.info(
      `${resend ? 'Resending' : 'Sending'} verification Email...`,
      {
        duration: 20000,
      },
    )

    const sendManagedEmailVerification = httpsCallable(
      functions,
      'allFunctions-sendManagedEmailVerification',
    )
    const { data }: Record<string, any> = await sendManagedEmailVerification({
      email,
      user,
      resend,
    })

    destroyToast()
    if (data.error) {
      toastService.error('Error', { description: data.message })
    } else if (data.success) {
      toastService.info('Email verification sent successfully')
      emailsOpts.reload()
    }
  }

  const removeEmail = async (emailId: string) => {
    await deleteDoc(doc(db, dbNames.users, user.id, 'managedEmails', emailId))
    toastService.info('Successfully removed email')
    emailsOpts.reload()
  }

  const { handleSubmit, control } = useForm({
    defaultValues: { email: '' },
    mode: 'onChange',
  })

  const scope = useCss(css)
  return (
    <Card
      {...scope}
      className="manage-emails"
    >
      <h3>Manage Emails</h3>
      <form
        className="add-email"
        onSubmit={e => {
          handleSubmit(handleAddEmail)(e)
        }}
        noValidate
      >
        <div className="add-email__input">
          <InputField
            placeholder="Email"
            control={control}
            fieldName="email"
            type="email"
          />
        </div>
        <Button
          type="submit"
          variant="primary"
        >
          Add email
        </Button>
      </form>
      <ul className="email-list">
        {emails.map(email => (
          <li key={email.id}>
            <div>{email.email}</div>
            <div className="email-list__actions">
              {email.verified ? (
                <div className="email-valid">
                  <Icon
                    name="check"
                    size={14}
                  />
                </div>
              ) : (
                <Pill pillType="warning">Email sent</Pill>
              )}
              <Dropdown
                position="bottom-left"
                renderTrigger={({ toggle }: { toggle: () => void }) => (
                  <ActionIcon
                    onClick={toggle}
                    variant="tertiary"
                    icon="ellipsis-v"
                    size="sm"
                  />
                )}
                renderContent={() => (
                  <div className="select-list">
                    {!email.verified && (
                      <button
                        onClick={() => sendVerificationEmail(email.email, true)}
                      >
                        Resend validation email
                      </button>
                    )}
                    <button onClick={() => removeEmail(email.id)}>
                      Remove
                    </button>
                  </div>
                )}
              />
            </div>
          </li>
        ))}
      </ul>
    </Card>
  )
}

const css = k`
  .manage-emails {
    padding: 2.4rem;
    margin-bottom: 2.4rem;
  }

  .add-email {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.6rem;

    button {
      flex-shrink: 0;
    }
  }

  .add-email__input {
    flex-grow: 1;
    margin-right: 1.6rem;
  }

  .email-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      border-bottom: solid .1rem $color-grey-100;
      padding: .8rem;

      > div:first-child {
        flex-grow: 1;
      }
    }

    > li:last-child {
      border: none;
    }
  }

  .email-list__actions {
    display: flex;
    align-items: center;
    gap: .8rem;
  }

  .email-valid {
    height: 2.1rem;
    width: 2.1rem;
    background-color: $color-success;
    border-radius: 100vh;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`
