import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { noop } from 'lodash'

import { db, functions } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { toastService } from '@utils/toast.service'

export const invitationActions = {
  org: {
    async accept(invitation) {
      const { recipientId, orgRole, id, inviterOrgId } = invitation

      const existingProfile = await getDoc(
        doc(db, dbNames.organizationProfiles, `${inviterOrgId}_${recipientId}`),
      )

      if (existingProfile.exists() && existingProfile.get('deactivated')) {
        await updateDoc(
          doc(
            db,
            dbNames.organizationProfiles,
            `${inviterOrgId}_${recipientId}`,
          ),
          {
            deactivated: false,
            role: orgRole,
          },
        )

        await deleteDoc(doc(db, dbNames.invitations, id))
      } else {
        await setDoc(
          doc(
            db,
            dbNames.organizationProfiles,
            `${inviterOrgId}_${recipientId}`,
          ),
          {
            orgId: inviterOrgId,
            userId: recipientId,
            mentorWorkflowIds: [],
            role: orgRole,
            createdDate: getTodayDate().toISO(),
          },
        )

        await updateDoc(doc(db, dbNames.users, recipientId), {
          [`orgRoles.${inviterOrgId}`]: orgRole,
          orgOrderIds: arrayUnion(inviterOrgId),
        })

        await deleteDoc(doc(db, dbNames.invitations, id))
      }

      return { inviterOrgId }
    },
    async decline(invitation) {
      await updateDoc(doc(db, dbNames.invitations, invitation.id), {
        status: 'declined',
      })
    },
  },
  linkInvite: {
    async accept(invitation, user) {
      const processLinkInvitation = httpsCallable(
        functions,
        'allFunctions-processLinkInvitation',
      )
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const firstName = user.firstName
      const lastName = user.lastName
      await processLinkInvitation({
        invitationData: invitation,
        today: getTodayDate().toISO(),
        linkId: invitation.linkId,
        userId: user.id,
        userEmail: user.email,
        orgRoles: user.orgRoles,
        userFirstName: firstName,
        userLastName: lastName,
        userTimeZone: timeZone,
      })
    },
    decline: noop,
  },
  peerMentor: {
    async accept(invitation, user) {
      const inviterId = invitation.invitationEvents.at(-1).inviterId
      try {
        const activity = await getDoc(
          doc(db, dbNames.activities, invitation.itemId),
        )
        await setDoc(
          doc(
            db,
            dbNames.activityMentors,
            `${invitation.itemId}_${invitation.recipientId}`,
          ),
          {
            activityId: invitation.itemId,
            activityEndDate: activity.get('endDate'),
            inviterId,
            userId: invitation.recipientId,
            activityOrgId: invitation.inviterOrgId,
            mentorOrgId: invitation.recipientOrgId,
            deactivated: false,
          },
        )

        await updateDoc(
          doc(
            db,
            dbNames.organizationProfiles,
            `${invitation.recipientOrgId}_${invitation.recipientId}`,
          ),
          {
            mentorWorkflowIds: arrayUnion(invitation.itemId),
          },
        )

        const createdDate = getTodayDate().toISO()
        const messageRoomSnap = await addDoc(
          collection(db, dbNames.messageRooms),
          {
            activityId: invitation.itemId,
            createdDate,
            name: '',
            userIds: [inviterId, invitation.recipientId],
          },
        )

        await setDoc(
          doc(
            db,
            dbNames.messageRoomUsers,
            `${messageRoomSnap.id}_${invitation.recipientId}`,
          ),
          {
            roomId: messageRoomSnap.id,
            createdDate,
            userEmail: user.email,
            userFirstName: user.firstName,
            userLastName: user.lastName,
            userId: invitation.recipientId,
            activityId: invitation.itemId,
            deactivated: false,
          },
        )

        const inviter = await getDoc(doc(db, dbNames.users, inviterId))
        await setDoc(
          doc(
            db,
            dbNames.messageRoomUsers,
            `${messageRoomSnap.id}_${inviterId}`,
          ),
          {
            roomId: messageRoomSnap.id,
            createdDate,
            userEmail: inviter.get('email'),
            userFirstName: inviter.get('firstName'),
            userLastName: inviter.get('lastName'),
            userId: inviterId,
            activityId: invitation.itemId,
            deactivated: false,
          },
        )

        await deleteDoc(doc(db, dbNames.invitations, invitation.id))
      } catch (err) {
        console.log(err)
        toastService.error(
          'An error occurred while accepting. Please try again later.',
        )
      }
    },
    async decline(invitation) {
      try {
        await updateDoc(doc(db, dbNames.invitations, invitation.id), {
          status: 'declined',
        })

        await updateDoc(
          doc(
            db,
            dbNames.organizationProfiles,
            `${invitation.recipientOrgId}_${invitation.recipientId}`,
          ),
          {
            mentorWorkflowIds: arrayRemove(invitation.itemId),
          },
        )
      } catch (err) {
        console.log(err)
        toastService.error(
          'An error occurred while declining. Please try again later.',
        )
      }
    },
  },
}
