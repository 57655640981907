import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

type AppState = {
  appLoading: boolean
  orgs: Organization[]
  subscribers: { [key: string]: () => void }
  invitations: any[]
  notifications: Notification[]
  oldNotifications: Notification[]
  user: any
  isFreeTier: boolean
  systemDate?: string
  isAuthenticated?: boolean
  showToolsOverlay?: boolean
}

export const useAppState = create<AppState>()(
  subscribeWithSelector(() => ({
    appLoading: true,
    orgs: [],
    subscribers: {},
    invitations: [],
    notifications: [],
    oldNotifications: [],
    user: null,
    isFreeTier: true,
    isAuthenticated: false,
    systemDate: '',
    showToolsOverlay: false,
  })),
)
