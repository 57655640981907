import { doc, updateDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { k, useCss } from 'kremling'
import { trim } from 'lodash'
import React, { useEffect, useState } from 'react'

import { Card } from '@components/card.component'
import { Icon } from '@components/icon.component'
import { InputField } from '@components/input'
import { Button } from '@components/mantine/button.component'
import { useAppState } from '@src/app.state'
import { auth, db, functions } from '@src/firebase-app'
import { ManageEmails } from '@src/pages/account/profile/manage-emails.component'
import { dbNames } from '@utils/constants'
import { getUserFullName } from '@utils/helpers'
import { toastService } from '@utils/toast.service'
import phone from 'phone'
import { useForm } from 'react-hook-form'
import { EditAccount } from './edit-account-card.component'

export function Profile() {
  const scope = useCss(css)
  const user = useAppState(state => state.user)
  const [resendingEmailVefification, setResendingEmailVefification] =
    useState(false)
  const currentAuthUser = auth.currentUser
  const userRef = doc(db, dbNames.users, user.id)

  const nameForm = useForm({
    mode: 'onTouched',
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
    },
  })

  const phoneForm = useForm({
    mode: 'onChange',
    defaultValues: {
      phoneNumber: user.phone,
    },
  })

  useEffect(() => {
    if (!user.firstName) return
    nameForm.setValue('firstName', user.firstName, { shouldDirty: false })
    nameForm.setValue('lastName', user.lastName, { shouldDirty: false })
  }, [user])

  const handleUpdate = async updateValue => {
    try {
      useAppState.setState({ user: { ...user, ...updateValue } })
      await updateDoc(userRef, updateValue)
    } catch (err) {
      console.error(err)
      toastService.error(
        'Unable to save profile information at this time. Please try again.',
      )
    }
  }
  const handleUpdateUserName = async ({ firstName, lastName }) => {
    if (!firstName || !lastName) return
    try {
      // await updateUserName({ firstName, lastName, userId: user.id })
      await updateDoc(userRef, { firstName, lastName })
      nameForm.reset({}, { keepValues: true })
    } catch (err) {
      console.log(err)
    }
  }

  if (!currentAuthUser) return null
  const { emailVerified, providerData } = currentAuthUser
  const usesPassword = providerData.some(
    provider => provider.providerId === 'password',
  )

  async function updatePhone({ phoneNumber }) {
    await handleUpdate({ phone: phoneNumber })
    phoneForm.reset({}, { keepValues: true })
  }

  async function sendEmailVerification() {
    setResendingEmailVefification(true)
    try {
      const emailVerification = httpsCallable(
        functions,
        'userFunctions-emailVerification',
      )
      await emailVerification(currentAuthUser.email)
      setResendingEmailVefification(false)
      toastService.info('Verification email sent.')
    } catch (err) {
      setResendingEmailVefification(false)
      toastService.error(
        'There was an error when sending the verification email.',
      )
    }
  }

  return (
    <div
      {...scope}
      className="--xs iact-container mt-5 pt-8"
    >
      <div className="pt-8">
        <Card className="overflow-hidden mb-6">
          {!emailVerified && (
            <div className="notification-banner">
              <div>
                <span className="banner-emphasis">
                  Email verification required:
                </span>
                A verification email has been sent to your email inbox.
              </div>
              <Button
                variant="primary"
                onClick={sendEmailVerification}
                loading={resendingEmailVefification}
              >
                Resend verification email
              </Button>
            </div>
          )}
          <div className="p-6">
            <div className="flex items-center mb-9 justify-between">
              <div className="flex items-center">
                <Icon
                  name="user-circle"
                  size={40}
                />
                <h3 className="edit-user__name">
                  {getUserFullName({
                    firstName: user.firstName,
                    lastName: user.lastName,
                  })}
                </h3>
              </div>
            </div>
            <form onSubmit={nameForm.handleSubmit(handleUpdateUserName)}>
              <div className="row break-1">
                <div className="col-6">
                  <div className="form-group">
                    <InputField
                      fieldName="firstName"
                      control={nameForm.control}
                      label="First name"
                      placeholder="Type here..."
                      required
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <InputField
                      fieldName="lastName"
                      control={nameForm.control}
                      label="Last name"
                      placeholder="Type here..."
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row break-1">
                <div className="col-6">
                  <Button
                    type="submit"
                    variant="primary"
                    loading={nameForm.formState.isSubmitting}
                    disabled={
                      !(
                        nameForm.formState.isValid && nameForm.formState.isDirty
                      )
                    }
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Card>
        <Card className="overflow-hidden mb-6 p-6">
          <form onSubmit={phoneForm.handleSubmit(updatePhone)}>
            <div className="row break-1">
              <div className="col-6">
                <div className="form-group">
                  <InputField
                    fieldName="phoneNumber"
                    control={phoneForm.control}
                    label="Phone number"
                    placeholder="Type here..."
                    type="tel"
                    rules={{
                      validate: value => {
                        const phoneValidation = phone(trim(value))
                        return phoneValidation.isValid || 'Invalid phone number'
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row break-1">
              <div className="col-6">
                <Button
                  type="submit"
                  variant="primary"
                  loading={phoneForm.formState.isSubmitting}
                  disabled={
                    !(
                      phoneForm.formState.isValid && phoneForm.formState.isDirty
                    )
                  }
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </Card>
        <ManageEmails />
        {usesPassword && <EditAccount handleUpdate={handleUpdate} />}
      </div>
    </div>
  )
}

Profile.propTypes = {}

const css = k`
  .notification-banner {
    background: $color-danger;
    color: #fff;
    padding: 1.5rem;
    display: flex;

    .banner-emphasis {
      font-weight: 600;
      margin-right: .4rem;
    }
  }

  .edit-user__header {
    display: flex;
    align-items: center;
    margin-bottom: 3.2rem;
  }

  .edit-user__name {
    font-size: 1.8rem;
    font-weight: 500;
    margin-left: 1.6rem;
  }

  .card-footer {
    border-top: solid .1rem $color-grey-100;
    padding: 1.6rem;
    text-align: right;

    > * {
      margin-left: .8rem;
    }
  }

  .group-loader {
    margin: 0 auto;
  }

  .group-list {
    padding: .8rem;
    width: 100%;
  }

  .group-list__header {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1.6rem;
  }

  .group-list__item {
    width: 100%;
    min-height: 5.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .8rem 1.6rem;
    border: solid 1px $color-grey-100;
    border-radius: $base-border-radius;
  }
`
