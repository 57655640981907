import { Well } from '@components/well.component'
import { useLoad } from '@hooks/use-load.hook'
import { k, useCss } from 'kremling'
import React from 'react' // useMemo
import wretch from 'wretch'
// import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
// import {db} from "@src/firebase-app";
// import {dbNames} from "@utils/constants";
// import {groupBy, isEmpty} from "lodash";
// import { BarRatio } from "@components/bar-ratio.component";
import { BasicTable } from '@components/basic-table.component'
import { RadialBarChart } from '@components/charts/radial-bar-chart.component'
import { Icon } from '@components/icon.component'
import { useMediaQuery } from '@hooks/use-media-query.hook'
import { ParticipantsTable } from '@src/pages/workflow/settings/participants-table.component'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { normalizePercentage } from '@utils/helpers'
import { isEmpty } from 'lodash'
import { useOutletContext } from 'react-router-dom'

type OutletProps = {
  activity: Activity
  participants: ActivityUser[]
}

type ActivityStat = {
  activity_id: string
  user_id: string
  first_name: string
  last_name: string
  last_edit_date: string
  iteration_completed_percentage: string
  activity_completed_percentage: string
  section_id: string
  status: string
}

export function ActivityProgress() {
  const { activity, participants } = useOutletContext<OutletProps>()
  const scope = useCss(css)
  const isMobile = useMediaQuery('media5Width', 'max-width')

  const [stats] = useLoad(
    [],
    () => {
      return wretch(
        `${process.env.USER_ANALYTICS_URL}/api/activity/${activity.id}/${activity.orgId}/last-edit-date`,
      )
        .get()
        .json(json => json)
    },
    [activity.id],
  ) as [ActivityStat[]]

  // const [activitySections] = useLoad([], () => {
  //   const q = query(
  //     collection(db, dbNames.activitySections),
  //     orderBy('name'),
  //     where('activityId', '==', activity.id),
  //   )
  //   return getDocs(q).then(q => q.docs.map(g => ({...g.data(), id: g.id})))
  // }, []);

  // const groupedBySectionId = useMemo(() => {
  //   if (isEmpty(stats)) return {};
  //   return groupBy(stats, 'section_id');
  // }, [stats]) as Record<string, ActivityStat[]>

  const percentageWhoNeedHelp =
    (stats.filter(({ status }: { status: string }) => status === 'inactive')
      .length /
      participants.length) *
    100
  const percentageActive =
    (stats.filter(({ status }: { status: string }) => status === 'active')
      .length /
      participants.length) *
    100
  const averageComplete = stats.length
    ? stats.reduce(
        (acc, { activity_completed_percentage }) =>
          acc + Number(activity_completed_percentage),
        0,
      ) / participants.length
    : 0

  const chartData = isEmpty(stats)
    ? []
    : [
        {
          label: 'Participants in need of help',
          value: normalizePercentage(percentageWhoNeedHelp),
          color: '#D10E9B',
        },
        {
          label: 'Active participants',
          value: normalizePercentage(percentageActive),
          color: '#6729B6',
        },
        {
          label: 'Average workflow progress',
          value: normalizePercentage(averageComplete),
          color: '#2947B6',
        },
      ]

  const columnHelper = createColumnHelper<any>()

  const legendColumns: ColumnDef<any, any>[] = [
    columnHelper.accessor('label', {
      cell: info => (
        <div>
          <span
            style={{
              display: 'inline-block',
              background: info.row.original.color,
              height: 12,
              width: 12,
              borderRadius: 2,
            }}
          />
          <span className="ml-2">{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor('value', {
      cell: info => (
        <div>
          <span style={{ color: info.row.original.color }}>
            {info.getValue()}
          </span>
          %
        </div>
      ),
    }),
    // columnHelper.accessor('info', {
    //   cell: () => (
    //     <Icon name="info"/>
    //   )
    // }),
  ]

  const legendMobileColumns: ColumnDef<any, any>[] = [
    columnHelper.accessor('label', {
      cell: info => (
        <div>
          <span
            style={{
              display: 'inline-block',
              background: info.row.original.color,
              height: 12,
              width: 12,
              borderRadius: 2,
            }}
          />
          <span className="ml-2">{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor('value', {
      cell: info => (
        <div>
          <span style={{ color: info.row.original.color }}>
            {info.getValue()}
          </span>
          %
        </div>
      ),
    }),
  ]

  return (
    <div
      {...scope}
      className="pt-8"
    >
      <div className="top-stats">
        <div className="title">Progress Overview</div>
        <div className="stats-container">
          <Well className="stats-well">
            <div className="top-container">
              <div>
                <Icon name="users" />
                <div className="big-stat">
                  {participants.filter(p => !p.deactivated).length}
                </div>
                <div className="mt-5">Total participants</div>
              </div>
              <div className="chart media-hide-3">
                <RadialBarChart
                  size={150}
                  data={chartData}
                />
              </div>
            </div>
            <div className="chart-inline chart-container">
              <div className="chart media-show-3">
                <RadialBarChart
                  size={150}
                  data={chartData}
                />
              </div>
              <div className="chart-legend">
                <BasicTable
                  data={chartData}
                  columns={isMobile ? legendMobileColumns : legendColumns}
                  hideHeader
                  classes={{
                    dataCellClass: 'legend-data-cell',
                    rowClass: 'legend-row',
                    tableClass: 'legend-table',
                  }}
                />
              </div>
            </div>
          </Well>
        </div>
      </div>
      {/*<div className="bottom-stats">*/}
      {/*  <div className="title">Participation details</div>*/}
      {/*  <div className="bar-charts">*/}
      {/*    {*/}
      {/*      activitySections.map((activitySection: {name: string; id: string; sectionId: string;}) => (*/}
      {/*        <BarRatio*/}
      {/*          className="mr-20 last-mr-0"*/}
      {/*          key={activitySection.id}*/}
      {/*          name={activitySection.name}*/}
      {/*          data={[*/}
      {/*            {*/}
      {/*              tooltipContent: (*/}
      {/*                <div>*/}
      {/*                  <div style={{fontWeight: 700}}>Active</div>*/}
      {/*                  <div>*/}
      {/*                    {*/}
      {/*                      groupedBySectionId[activitySection.sectionId]*/}
      {/*                        ? groupedBySectionId[activitySection.sectionId]*/}
      {/*                          .filter(({status}) => status === 'active')*/}
      {/*                          .map(({first_name, last_name, user_id}) => (*/}
      {/*                            <div key={user_id}>{first_name} {last_name}</div>*/}
      {/*                          ))*/}
      {/*                        : []*/}
      {/*                    }*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              ),*/}
      {/*              count: groupedBySectionId[activitySection.sectionId]*/}
      {/*                ? groupedBySectionId[activitySection.sectionId]*/}
      {/*                  .filter(({status}) => status === 'active')*/}
      {/*                  .length*/}
      {/*                : 0,*/}
      {/*              color: '#2252C5'*/}
      {/*            },*/}
      {/*            {*/}
      {/*              tooltipContent: (*/}
      {/*                <div>*/}
      {/*                  <div>Inactive</div>*/}
      {/*                  <div>*/}
      {/*                    {*/}
      {/*                      groupedBySectionId[activitySection.sectionId]*/}
      {/*                        ? groupedBySectionId[activitySection.sectionId]*/}
      {/*                          .filter(({status}) => status === 'inactive')*/}
      {/*                          .map(({first_name, last_name, user_id}) => (*/}
      {/*                            <div key={user_id}>{first_name} {last_name}</div>*/}
      {/*                          ))*/}
      {/*                        : []*/}
      {/*                    }*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              ),*/}
      {/*              count: groupedBySectionId[activitySection.sectionId]*/}
      {/*                ? groupedBySectionId[activitySection.sectionId]*/}
      {/*                  .filter(({status}) => status === 'inactive')*/}
      {/*                  .length*/}
      {/*                : 0,*/}
      {/*              color: '#ef6579'*/}
      {/*            }*/}
      {/*          ]}*/}
      {/*          total={participants.length}*/}
      {/*        />*/}
      {/*      ))*/}
      {/*    }*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="participants-table__wrapper">
        <ParticipantsTable
          activity={activity}
          data={participants
            .filter(p => !p.deactivated)
            .map(({ userId, userEmail, userFirstName, userLastName }) => {
              const userStat = stats.find(({ user_id }) => user_id === userId)
              if (userStat) {
                return {
                  ...userStat,
                  email: userEmail,
                }
              } else {
                return {
                  email: userEmail,
                  first_name: userFirstName,
                  last_name: userLastName,
                  status: 'not-started',
                  activity_id: activity.id,
                  user_id: userId,
                  last_edit_date: null,
                  activity_completed_percentage: 0,
                }
              }
            })}
        />
      </div>
    </div>
  )
}

const css = k`
  .top-stats {
    margin-bottom: 12px;
  }

  .bottom-stats {
    margin-bottom: 20px;
  }

  .title {
    font-weight: 500;
    font-size: 3.2rem;
    margin-bottom: 12px;
  }

  .stats-container {
    display: flex;
    flex-direction: column;
  }

  .stats-well {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    margin-bottom: 1.2rem;
  }

  .chart-inline {
    display: flex;
    align-items: center;
  }

  .top-container {
    display: flex;
    justify-content: space-between;
    flex: 1 1 0;
    width: 100%;
  }

  .chart-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .chart-legend {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
  }

  .legend-table {
    width: 100%;
  }

  .legend-data-cell {
    padding: 1.2rem .4rem;
  }

  .legend-row:not(:last-child) {
    border-bottom: solid 1px $color-grey-100;
  }

  @media (min-width: $media3-width) {
    .top-stats {
      margin-bottom: 100px;
    }

    .top-container {
      width: auto;
    }

    .chart {
      margin-right: 3rem;
    }

    .stats-well {
      flex-direction: row;
    }

    .chart-container {
      flex-direction: row;
      margin-left: 6rem;
      flex: 4;
    }


    .stats-well {
      margin-bottom: 0;
    }
  }

  .stat-fraction {
    font-size: 2.4rem;
    line-height: normal;
  }

  .big-stat {
    color: $color-primary;
    font-size: 3.2rem;
    font-weight: 500;
  }

  .stat-footer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .filters {
    margin-bottom: 12px;
  }

  .bar-charts {
    display: flex;
    justify-content: center;
  }

`
