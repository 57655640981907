import * as RadixRadioGroup from '@radix-ui/react-radio-group'
import React from 'react'

type RadioGroupProps = {
  children: React.ReactNode
  defaultValue?: string
  value: string
  onValueChange: (value: string) => void
  onBlur?: () => void
  disabled?: boolean
}

type RadioItemProps = {
  value: string
  label?: string | React.ReactNode
  id?: string
  disabled?: boolean
}

export const RadioGroup = ({
  children,
  defaultValue,
  value,
  disabled = false,
  onValueChange,
  onBlur,
}: RadioGroupProps) => (
  <RadixRadioGroup.Root
    disabled={disabled}
    defaultValue={defaultValue}
    aria-label="View density"
    value={value}
    onValueChange={onValueChange}
    onBlur={onBlur}
  >
    {children}
  </RadixRadioGroup.Root>
)

const RadioItem = ({ id, value, label, disabled }: RadioItemProps) => (
  <div className="flex items-center gap-2">
    <RadixRadioGroup.Item
      disabled={disabled}
      id={id || value}
      value={value}
      className="flex-shrink-0 w-8 h-8 rounded-full ring-2 ring-grey-100 shadow-md focus-visible:ring-2 focus-visible:ring-primary focus-visible:border-transparent focus:outline-none transition-colors"
    >
      <RadixRadioGroup.Indicator className="flex items-center bg-primary justify-center w-full h-full rounded-full relative after:content-[''] after:block after:w-3 after:h-3 after:rounded-full after:bg-white" />
    </RadixRadioGroup.Item>
    {label && <label htmlFor={id || value}>{label}</label>}
  </div>
)

RadioGroup.Item = RadioItem
