import React from 'react'
import { useCss, k } from 'kremling'
import { Card } from '@components/card.component'
import { Logo } from '@components/logo.component'
import { ResetPasswordForm } from '@src/pages/account/auth/reset-password-form.component'
import { Link } from 'react-router-dom'

export function ResetPassword() {
  const styles = useCss(css)

  return (
    <div
      {...styles}
      className="card-wrapper"
    >
      <Card className="card-container">
        <Logo className="mb-2 mt-5" />
        <ResetPasswordForm />
        <div className="mt-4 flex justify-center">
          <Link to="/sign-in">Sign in</Link>
        </div>
      </Card>
    </div>
  )
}

const css = k`
  .card-container {
    padding: 2.4rem;
    width: 40rem;
  }

  .card-wrapper {
    background: $color-grey-25;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
