import React, { useEffect, useMemo, useRef } from 'react'

import { ActionIcon } from '@components/mantine/action-icon.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Select } from '@components/select.component'
import { Tooltip } from '@components/tooltip.component'
import { useAppState } from '@src/app.state'
import { validateEmail } from '@utils/validate-email'
import { getOrgRoles } from './user.utils'

export function AddUserInputRow({
  invite,
  updateInvite,
  isFreeTier,
  isOnlyRow,
  removeInvite,
  addNewInviteRow,
  index,
  orgId,
}) {
  const user = useAppState(state => state.user)
  const isValidEmail = useMemo(
    () => validateEmail(invite.email),
    [invite.email],
  )
  const roles = getOrgRoles(user.orgRoles[orgId])
  const inputRef = useRef(null)

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  function handleUpdate(email, orgRole) {
    const newInvite = { email, orgRole }
    updateInvite(newInvite, index)
  }

  const hasWarning = !isValidEmail && invite.email.length > 0

  return (
    <div
      style={{ display: 'flex', alignItems: 'flex-end' }}
      className="row break-1"
    >
      <div className="col-6">
        <div className={`form-group ${hasWarning ? 'has-warning' : ''}`}>
          <label>
            Email
            {hasWarning && <span className="ml-1">(Invalid email)</span>}
          </label>
          <TextInput
            ref={inputRef}
            type="email"
            value={invite.email}
            onChange={value => handleUpdate(value, invite.orgRole)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                addNewInviteRow()
              }
            }}
          />
        </div>
      </div>
      <div className={isOnlyRow ? 'col-6' : 'col-5'}>
        <div className="form-group">
          <label>Role</label>
          <Tooltip
            interactive
            disabled={!isFreeTier}
            content={
              <div>
                <p>Adjusting user roles is a paid feature.</p>
                <p>
                  For more information about upgrading click the link below.
                </p>
                <a
                  className="btn --primary mt-3"
                  href="https://iact.com/go-pro/"
                  target="_blank"
                  rel="noreferrer"
                >
                  iact.com
                </a>
              </div>
            }
            placement="bottom"
            maxWidth={200}
          >
            <Select
              isDisabled={isFreeTier}
              id="org-role"
              options={roles}
              onChange={({ value }) => handleUpdate(invite.email, value)}
              value={roles.find(({ value }) => value === invite.orgRole)}
            />
          </Tooltip>
        </div>
      </div>
      {!isOnlyRow && (
        <div className="form-group">
          <ActionIcon
            size="sm"
            icon="times"
            onClick={() => removeInvite(index)}
          />
        </div>
      )}
    </div>
  )
}
