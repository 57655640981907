import { useAppState } from '@src/app.state'
import { getTodayDate, normalizePercentage } from '@utils/helpers'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

export function useWorkflowProgress(activity = {}, completedPercentage) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const systemDate = useAppState(state => state.systemDate)
  const today = useMemo(() => getTodayDate(), [systemDate])

  const convertedStartDate = useMemo(
    () =>
      DateTime.fromISO(activity.startDate?.date, {
        zone: activity.startDate?.timeZone,
      }).setZone(timeZone),
    [activity],
  )

  const convertedEndDate = useMemo(
    () =>
      DateTime.fromISO(activity.endDate?.date, {
        zone: activity.endDate?.timeZone,
      }).setZone(timeZone),
    [activity],
  )

  function getStatus(progress, dateProgress) {
    if (progress === 100) {
      return 'completed'
    }

    if (
      (dateProgress && dateProgress > progress) ||
      (today &&
        convertedStartDate &&
        convertedEndDate &&
        today.toISO() > convertedStartDate.toISO() &&
        today.toISO() > convertedEndDate.toISO())
    ) {
      return 'overdue'
    }
    return 'inprogress'
  }

  const { days: timeTotal } = convertedEndDate.diff(convertedStartDate, 'days')
  const { days: timeLeft } = convertedEndDate.diff(today.startOf('day'), 'days')
  const dateProgress = normalizePercentage(100 - (timeLeft * 100) / timeTotal)

  return {
    startDate: convertedStartDate,
    endDate: convertedEndDate,
    dateProgress,
    progressStatus: getStatus(completedPercentage, dateProgress),
  }
}
