import { Badge } from '@components/badge.component'
import { EmptyState } from '@components/empty-state.component'
import { Icon } from '@components/icon.component'
import { Loader } from '@components/loader.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { Button } from '@components/mantine/button.component'
import { SimpleEditor } from '@components/rich-text/simple-editor/simple-editor.component'
import { SelectSingle } from '@components/select-single.component'
import { $generateHtmlFromNodes } from '@lexical/html'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { MessageItem } from '@src/pages/messaging/message-item.component'
import { useMessagingState } from '@src/pages/messaging/messaging-store'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { a, k, useCss } from 'kremling'
import { CLEAR_EDITOR_COMMAND } from 'lexical'
import { isEmpty, isEqual, sortBy, trim, uniq, uniqBy } from 'lodash'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { renderRoomTitle } from './messaging.utils'

// const batchLimit = 10
export function ChatMessages({
  className = '',
  selectedRoom,
  onSend,
  onCancelStartRoom,
  newMessages,
  participants = [],
  activity,
  toggleRooms,
  mobileRoomsOpen,
  adminRooms,
  onCreateAdminRoom,
}) {
  const scope = useCss(css)
  const addingNewRoom = useMessagingState(state => state.addingNewRoom)
  const messageRoomUsers = useMessagingState(state => state.messageRoomUsers)
  const newRoomSelectedUsers = useMessagingState(
    state => state.newRoomSelectedUsers,
  )

  const [room, setRoom] = useState(selectedRoom)
  const [messages, setMessages] = useState([])
  const [messageText, setMessageText] = useState('')
  const messagesSubscriptionRef = useRef(null)
  const messagesRef = useRef(null)
  const user = useAppState(state => state.user)
  const { orgId } = useParams()
  const isAdmin = ['owner', 'manager', 'mentor'].includes(user?.orgRoles[orgId])
  const notifications = useAppState(state => state.notifications)
  const [allowAutoScroll, setAllowAutoScroll] = useState(true)
  const [creatingRoom, setCreatingRoom] = useState(false)
  const editorRef = useRef(null)

  const availableParticipants = useMemo(() => {
    if (!addingNewRoom) return []

    const userIdsInAdminRooms = adminRooms.reduce((accum, room) => {
      return uniq([...accum, ...room.userIds])
    }, [])

    const filtered = participants.filter(
      ({ userId }) =>
        userId !== user.id &&
        userId !== 'admin' &&
        !userIdsInAdminRooms.includes(userId),
    )
    return uniqBy(filtered, 'userId')
  }, [addingNewRoom, participants, user.id, adminRooms])

  useEffect(() => {
    if (!room) return
    const roomUsersQuery = query(
      collection(db, dbNames.messageRoomUsers),
      where('roomId', '==', room?.id || ''),
    )
    return onSnapshot(roomUsersQuery, snap => {
      const _messageRoomUsers = snap.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }))
      useMessagingState.setState({ messageRoomUsers: _messageRoomUsers })
    })
  }, [room?.id])

  useEffect(() => {
    clearNotifications()
  }, [selectedRoom])

  useEffect(() => {
    if (!room) return
    return () => {
      if (messagesSubscriptionRef.current) {
        messagesSubscriptionRef.current()
      }
    }
  }, [])

  useEffect(() => {
    if (addingNewRoom) {
      setRoom(null)
    } else {
      setRoom(selectedRoom)
    }
    setAllowAutoScroll(true)
    setMessages([])

    return () => useMessagingState.setState({ newRoomSelectedUsers: [] })
  }, [addingNewRoom, selectedRoom])

  const usersMap = useMemo(() => {
    if (isEmpty(messageRoomUsers)) return {}
    return messageRoomUsers.reduce((accum, user) => {
      const { userId } = user
      return { ...accum, [userId]: user }
    }, {})
  }, [messageRoomUsers])

  // const [messagesCount] = useLoad(0, () => {
  //   if(!room) return Promise.resolve(0)
  //   const countQuery = query(
  //     collection(db, dbNames.messageRooms, room.id, dbNames.messages),
  //   )
  //   return getCountFromServer(countQuery)
  //     .then(snap => snap.data().count)
  // }, [room?.id]);

  useLayoutEffect(() => {
    if (!messagesRef.current || !allowAutoScroll) return
    messagesRef.current.scrollTop = messagesRef.current?.scrollHeight
  }, [messages, allowAutoScroll, room])

  useEffect(() => {
    if (!room) return
    if (messagesSubscriptionRef.current) {
      messagesSubscriptionRef.current()
    }
    const q = query(
      collection(db, dbNames.messageRooms, room.id, dbNames.messages),
      orderBy('createdDateTs', 'desc'),
      // limit(batchLimit)
    )
    messagesSubscriptionRef.current = onSnapshot(q, snap => {
      const _messages = snap.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      const sorted = sortBy(_messages, 'createdDateTs')
      if (messagesRef.current && allowAutoScroll) {
        messagesRef.current.scrollTop = messagesRef.current?.scrollHeight
      }
      setMessages(sorted)
    })
    return messagesSubscriptionRef.current
  }, [room?.id])

  // function getNextBatch() {
  //   if(isEmpty(messages)) return
  //   const q = query(
  //     collection(db, dbNames.messageRooms, selectedRoom.id, dbNames.messages),
  //     orderBy('createdDateTs', 'desc'),
  //     startAfter(messages[0].createdDateTs),
  //     limit(batchLimit),
  //   )
  //   getDocs(q).then((snapshot) => {
  //     const messagesBatch = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}))
  //     const sorted = sortBy(messagesBatch, 'createdDateTs')
  //     setMessages(state => uniqBy([...sorted, ...state], ({id}) => id))
  //   })
  // }

  const sendMessage = async (text, users) => {
    if (!text) return
    const me = {
      userId: user.id,
      userFirstName: user.firstName,
      userLastName: user.lastName,
      userEmail: user.email,
    }

    if (useMessagingState.getState().addingNewRoom) {
      setCreatingRoom(true)

      await onCreateAdminRoom(
        useMessagingState.getState().newRoomSelectedUsers[0],
        text,
      )

      setCreatingRoom(false)
      useMessagingState.setState({
        newRoomSelectedUsers: [],
        addingNewRoom: false,
      })
      return
    }

    const userIsParticipant = room?.userIds.includes(user.id)
    if (room?.userIds.includes('admin') && isAdmin) {
      useMessagingState.setState({ newRoomSelectedUsers: [] })
      onSend(text, users)
      onCancelStartRoom(null)
      return
    } else if (!userIsParticipant && room?.id === activity.id) {
      await addDoc(collection(db, dbNames.messageRoomUsers), {
        ...me,
        roomId: room.id,
        createdDate: getTodayDate().toISO(),
        activityId: activity.id,
        deactivated: false,
      })

      await updateDoc(doc(db, dbNames.messageRooms, room.id), {
        userIds: arrayUnion(user.id),
      })

      onSend(text, [...users, me])
      onCancelStartRoom(null)
      return
    }

    useMessagingState.setState({ newRoomSelectedUsers: [] })
    onSend(text, users)
    onCancelStartRoom(null)
  }

  async function clearNotifications() {
    if (!selectedRoom) return
    const notificationId = `${user.id}_${selectedRoom.id}`
    if (notifications.some(({ id }) => id === notificationId)) {
      await updateDoc(doc(db, dbNames.notifications, notificationId), {
        viewed: true,
        dirty: true,
      })
    }
  }

  // function handleScroll(e) {
  //   const atBottom = e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight
  //   const closeToTop = e.target.scrollTop <= 50
  //
  //   if(!atBottom && allowAutoScroll === true) {
  //     setAllowAutoScroll(false)
  //   }
  //   if(closeToTop) {
  //     if(messagesCount === messages.length) return
  //     setAllowAutoScroll(false)
  //     // getNextBatch()
  //   } else if(atBottom) {
  //     setAllowAutoScroll(true)
  //   }
  // }

  function checkForMessageRoom(users) {
    if (isEmpty(users)) {
      setRoom(selectedRoom)
      setMessages([])
      return
    }
    const userIds = users.map(({ userId }) => userId)
    const q = query(
      collection(db, dbNames.messageRooms),
      where('activityId', '==', activity.id),
      where('userIds', 'array-contains-any', userIds),
    )
    getDocs(q)
      .then(snapshot => {
        if (snapshot.empty) return []
        return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      })
      .then(matchRooms => {
        if (!isEmpty(matchRooms)) {
          //filter out any results that don't have current user
          const filtered = matchRooms.filter(
            doc => doc.userIds.includes(user.id) && doc.id !== activity.id,
          )
          if (isEmpty(filtered)) return

          let match
          filtered.forEach(doc => {
            const sorted = sortBy(doc.userIds)
            const sortedSelected = sortBy([...userIds, user.id])
            if (isEqual(sorted, sortedSelected)) {
              match = doc
            } else {
              setMessages([])
              setRoom(null)
            }
          })

          if (match) {
            setRoom(match)
          }
        }
      })
  }

  const handleSubmit = useCallback(() => {
    const editor = editorRef.current
    const editorState = editor.getEditorState()
    editorState.read(() => {
      const message = $generateHtmlFromNodes(editorRef.current)
      sendMessage(message, useMessagingState.getState().messageRoomUsers)
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
    })
  }, [editorRef.current, sendMessage])

  if (creatingRoom) {
    return (
      <div
        className="loader__container"
        {...scope}
      >
        <Loader />
      </div>
    )
  }

  function shouldDisplayTextInput() {
    if (addingNewRoom && !isEmpty(newRoomSelectedUsers)) return true
    if (!room) return false
    return !(activity.id === room.id && !isAdmin)
  }

  const isAnnouncements = room?.id === activity.id

  const roomParticipants = participants.filter(
    ({ roomId }) => roomId === room?.id,
  )
  return (
    <div
      {...scope}
      className={a('chat-messages__wrapper').m(className, !!className)}
    >
      {mobileRoomsOpen && (
        <div
          onClick={toggleRooms}
          className="room-list__overlay"
        />
      )}
      {addingNewRoom ? (
        <div className="chat-messages__header">
          <SelectSingle
            //@ts-expect-error - not worth fixing now
            triggerIsBlock
            contentWidth="block"
            placeholder="Select Assignee"
            className="mt-3"
            data={availableParticipants}
            transformData={item => {
              return {
                name: `${item.userFirstName} ${item.userLastName}`,
                id: item.userId,
              }
            }}
            onChange={selected => {
              useMessagingState.setState({ newRoomSelectedUsers: [selected] })
              checkForMessageRoom([selected])
            }}
            value={newRoomSelectedUsers[0]}
            fixedContent
          />
          <div className="ml-2">
            <Button
              onClick={() => {
                useMessagingState.setState({ newRoomSelectedUsers: [] })
                onCancelStartRoom()
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="chat-messages__header">
            <ActionIcon
              size="sm"
              className="media-hide-3"
              onClick={toggleRooms}
              icon="bars"
            />

            <div className="chat__title">
              {room?.name === 'Announcements' ? (
                <div className="room__title flex items-center gap-4 flex-ellipsis">
                  <Icon name="megaphone" /> Announcements
                </div>
              ) : (
                <div className="room__title flex-ellipsis">
                  {room?.name ||
                    renderRoomTitle(room, roomParticipants, user.id, isAdmin)}
                </div>
              )}
              {!isEmpty(newMessages) && (
                <Badge
                  className="new-messages__badge"
                  count={newMessages.length}
                />
              )}
            </div>
            {/*<div className="chat__actions">*/}
            {/*  <Dropdown*/}
            {/*    position="bottom-left"*/}
            {/*    renderTrigger={({toggle}) => (*/}
            {/*      <Button icon="ellipsis-v" onClick={toggle}/>*/}
            {/*    )}*/}
            {/*    renderContent={({close}) => (*/}
            {/*      <div className="select-list">*/}
            {/*        <button onClick={close}>Block</button>*/}
            {/*        <button onClick={close}>Mute</button>*/}
            {/*      </div>*/}
            {/*    )}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        </>
      )}
      <div
        className="chat-messages__body"
        ref={messagesRef}
        // onScroll={handleScroll}
      >
        {isEmpty(messages) && isAnnouncements && (
          <EmptyState
            title="No messages"
            subtitle="Check here for announcements and updates."
          />
        )}
        {isEmpty(messages) && !isAnnouncements && (
          <EmptyState
            title="Share ideas to improve our app or reach out for help with the course content."
            subtitle="We respond within 24 hours."
          />
        )}
        {messages.map(message => (
          <MessageItem
            key={message.id}
            message={message}
            user={usersMap[message.userId]}
            isAnnouncements={isAnnouncements}
          />
        ))}
      </div>
      <div
        className={a('chat-messages__footer').m(
          'hidden',
          !shouldDisplayTextInput(),
        )}
      >
        <form
          className="space-x-2"
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <SimpleEditor
            onChange={newText => setMessageText(newText)}
            onSubmit={text => {
              sendMessage(text, useMessagingState.getState().messageRoomUsers)
            }}
            ref={editorRef}
            placeholder="Type here..."
          />
          <Button
            className="ml-2"
            type="submit"
            variant="primary"
            onClick={e => {
              e.preventDefault()
              handleSubmit()
            }}
            disabled={!trim(messageText)}
          >
            Send
          </Button>
        </form>
      </div>
    </div>
  )
}

const css = k`
  .chat-messages__wrapper {
    border-radius: $base-border-radius;
    background: $color-grey-25;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .chat-messages__header {
    border-bottom: solid 1px $color-grey-100;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .chat__title {
    font-size: 1.6rem;
    font-weight: 500;
    color: $color-primary;
    display: flex;
    align-items: center;
  }

  .new-messages__badge {
    position: relative !important;
    margin-left: 12px;
  }

  .chat-messages__body {
    height: 100%;
    padding: 12px;
    padding-bottom: 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    flex: 1 1 1px;
  }

  .chat-messages__footer {
    padding: 12px;
    form {
      display: flex;
    }
  }

  .chat__input {
    width: 100%;
    margin-right: 8px;
  }

  .users__list {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .search-user__item {
    all: unset;
    background: $color-grey-50;
    box-sizing: border-box;
    cursor: pointer;
    padding: 12px;
    transition: background .2s ease;

    &.selected {
      background: $color-active-100;
    }
  }

  .search-user__item:hover {
    background: $color-grey-100;
  }

  .form-input-textarea {
    padding: 0.8rem;
    max-height: 10rem;
    overflow-y: auto;
    position: relative;
    z-index: 0;
    cursor: text;
    height: auto;
    line-height: 2rem;
  }

  .loader__container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .room-list__overlay {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    backdrop-filter: blur(10px);
    z-index: 5;
  }
`
