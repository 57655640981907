import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { k, useCss } from 'kremling'
import { isEmpty, sortBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { UserCard } from '@components/cards/user-card.component'
import { UserInvitationCard } from '@components/cards/user-invitation-card.component'
import { EmptyState } from '@components/empty-state.component'
import { LayoutHeader } from '@components/layout.component'
import { Loader } from '@components/loader.component'
import { Button } from '@components/mantine/button.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Tooltip } from '@components/tooltip.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { AddUserModal } from '@src/pages/admin/users/add-user-modal.component'
import { searchTypesense } from '@typesense-config'
import { dbNames } from '@utils/constants'
import { modalService } from '@utils/modal.service'

export function Users() {
  const scope = useCss(css)
  const orgs = useAppState(state => state.orgs)
  const isFreeTier = useAppState(state => state.isFreeTier)
  const [searchVal, setSearchVal] = useState('')
  const [userInvitations, setUserInvitations] = useState([])
  const { orgId } = useParams()
  const selectedOrg = useMemo(() => orgs.find(org => org.id === orgId), [orgs])
  const pendingInvitations = useMemo(
    () => userInvitations.filter(invitation => invitation.status === 'pending'),
    [userInvitations],
  )

  const [users, setUsers, usersOpts] = useLoad(
    [],
    () => {
      if (isEmpty(orgs)) return new Promise(resolve => resolve([]))

      const q = query(
        collection(db, dbNames.users),
        orderBy(`orgRoles.${orgId}`),
      )
      return getDocs(q)
        .then(q => q.docs.map(u => ({ ...u.data(), id: u.id })))
        .then(users => sortBy(users, ['lastName']))
    },
    [orgs],
  )

  const userLimitReached =
    //user limit counts only users other than current user
    users.length - 1 + pendingInvitations.length >= selectedOrg?.userLimit

  function handleSearch(text) {
    let searchParameters = {
      q: text,
      query_by: 'lastName,firstName,email',
      query_by_weights: '2,3,1',
      filter_by: `orgOrderIds:=${orgId}`,
    }
    searchTypesense('users', searchParameters).then(({ hits }) => {
      setUsers(hits.map(hit => hit.document))
    })
    setSearchVal(text)
  }

  useEffect(() => {
    if (isEmpty(orgs)) return
    const q = query(
      collection(db, dbNames.invitations),
      where('inviterOrgId', '==', orgId),
      where('type', '==', 'org'),
    )
    return onSnapshot(q, querySnapshot => {
      setUserInvitations(
        querySnapshot.docs.map(ref => ({ ...ref.data(), id: ref.id })),
      )
    })
  }, [orgs])

  function addUser() {
    modalService
      .render(AddUserModal, {
        orgId,
        isFreeTier,
        currentUsers: [...users, ...pendingInvitations],
      })
      .then(usersOpts.reload)
  }

  function renderUsers() {
    if (users.length) {
      return users.map(user => {
        return (
          <UserCard
            key={user.id}
            user={user}
          />
        )
      })
    } else {
      if (searchVal) {
        return (
          <EmptyState
            title="No search results"
            action={addUser}
            actionText="Add User"
          />
        )
      } else {
        return (
          <EmptyState
            title="No Users"
            subtitle="Let's add some"
            action={addUser}
            actionText="Add User"
          />
        )
      }
    }
  }

  if (usersOpts.loading || isEmpty(orgs))
    return (
      <div className="pt-8">
        <Loader />
      </div>
    )

  return (
    <div {...scope}>
      <LayoutHeader
        className="pt-8"
        title="Users"
        search={
          <TextInput
            defaultValue={searchVal}
            onChange={handleSearch}
            placeholder="Search Users..."
            icon="search"
            onCancel={() => {
              usersOpts.reload()
            }}
            allowClear
          />
        }
        actions={
          <Tooltip
            content={
              <div>
                <p>User limit has been reached for the Free Tier.</p>
                <p>
                  For more information about upgrading click the link below.
                </p>
                <a
                  className="btn --primary mt-3"
                  href="https://iact.com/go-pro/"
                  target="_blank"
                  rel="noreferrer"
                >
                  iact.com
                </a>
              </div>
            }
            disabled={!(userLimitReached && isFreeTier)}
            interactive
            maxWidth={200}
          >
            <Button
              disabled={userLimitReached && isFreeTier}
              variant="secondary"
              onClick={addUser}
            >
              Invite users
            </Button>
          </Tooltip>
        }
      />
      {!isEmpty(userInvitations) && (
        <div className="invitations-container">
          <div className="invitations-title">Invitations</div>
          {userInvitations.map(invitation => (
            <div
              key={invitation.id}
              className="user-invitation"
            >
              <UserInvitationCard invitation={invitation} />
            </div>
          ))}
        </div>
      )}
      {renderUsers()}
    </div>
  )
}

const css = k`
  .invitations-container {
    padding: 12px;
    background: $color-grey-75;
    border-radius: $base-border-radius;
    margin-bottom: 2rem;

    .invitations-title {
      font-size: 2rem;
      font-weight: 400;
      margin-bottom: .8rem;
    }
  }

  .user-invitation:not(:last-child) {
    margin-bottom: .8rem;
  }
`
