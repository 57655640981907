import { ActionIcon } from '@components/mantine/action-icon.component'
import { Button } from '@components/mantine/button.component'
import { Checkbox } from '@components/mantine/checkbox.component'
import { Radio } from '@components/mantine/radio.component'
import { TextInput } from '@components/mantine/text-input.component'
import { SimpleEditor } from '@components/rich-text/simple-editor/simple-editor.component'
import { a, k, useCss } from 'kremling'
import { isObject } from 'lodash'
import React, { useEffect, useState } from 'react'

type ChoiceItemListProps = {
  list: unknown[]
  selectedItems: string[]
  title?: string
  onSelect: (item: unknown) => void
  getSelectedMatchValue?: (item: any) => any
  getIdentifier?: (item: any) => any
  onAddChoice?: (value: string) => any
  className?: string
  onEditChoice?: (value: string) => any
  addChoiceActionText?: string
  type?: 'radio' | 'checkbox'
}

export function ChoiceItemList({
  list,
  onSelect,
  title,
  selectedItems,
  getSelectedMatchValue,
  getIdentifier,
  onAddChoice,
  onEditChoice,
  className = '',
  type = 'checkbox',
  addChoiceActionText = 'Add choice',
}: ChoiceItemListProps) {
  const scope = useCss(css)
  const [choices, setChoices] = useState<unknown[]>(list)
  const [newChoiceValue, setNewChoiceValue] = useState<string>('')
  const [addingChoice, setAddingChoice] = useState<boolean>(false)

  let selectedList: string[] = []
  if (type === 'checkbox') {
    selectedList = selectedItems
  }

  if (type === 'radio') {
    selectedList = [selectedItems[0]]
  }

  useEffect(() => {
    if (!list) return
    setChoices(list)
  }, [list])

  function addChoice() {
    onAddChoice && onAddChoice(newChoiceValue)
    setChoices(prevState => [...prevState, newChoiceValue])
    setNewChoiceValue('')
    setAddingChoice(false)
  }

  return (
    <div
      {...scope}
      className={a('list__container').m(className, !!className)}
    >
      {title && <div className="list__title">{title}</div>}
      {choices.map(
        (item: string | { description: string; title: string }, index) => {
          let listItem = item
          let title = ''
          if (isObject(item)) {
            listItem = item.description
            title = item.title
          }

          const id = getIdentifier ? getIdentifier(item) : listItem
          const value = getSelectedMatchValue
            ? getSelectedMatchValue(item)
            : listItem
          const selected = getIdentifier
            ? selectedList.map(getIdentifier)
            : selectedList

          return (
            <ChoiceItem
              title={title}
              key={index}
              text={value}
              selected={selected.includes(id)}
              onSelect={() => onSelect(item)}
              onEdit={onEditChoice}
              index={index}
              type={type}
            />
          )
        },
      )}
      {addingChoice && (
        <div className="form-group mt-3">
          <TextInput
            defaultValue={newChoiceValue}
            onChange={setNewChoiceValue}
            placeholder="New choice"
            autoFocus
          />
        </div>
      )}
      {addingChoice ? (
        <div className="new-choice__actions">
          <Button
            onClick={addChoice}
            variant="primary"
            className="mr-2"
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setNewChoiceValue('')
              setAddingChoice(false)
            }}
            variant="secondary"
          >
            Cancel
          </Button>
        </div>
      ) : (
        onAddChoice && (
          <Button
            className="mt-3"
            onClick={() => setAddingChoice(true)}
            variant="secondary"
          >
            {addChoiceActionText}
          </Button>
        )
      )}
    </div>
  )
}

function ChoiceItem({
  text,
  selected,
  onSelect,
  onEdit,
  index,
  type,
  title,
}: {
  text: string
  selected: boolean
  onSelect: () => void
  onEdit: (value: string, text: string, index: number) => void
  index: number
  type: 'radio' | 'checkbox'
  title?: string
}) {
  const scope = useCss(css)
  const [editing, setEditing] = useState(false)
  const [editingValue, setEditingValue] = useState('')

  if (editing) {
    return (
      <div className="editing-item__container">
        <SimpleEditor
          onChange={(newText: string) => setEditingValue(newText)}
          initialValue={text}
        />
        <Button
          variant="primary"
          onClick={() => {
            setEditing(false)
            setEditingValue('')
            onEdit(editingValue, text, index)
          }}
          className="ml-2"
        >
          Save
        </Button>
        <Button
          className="ml-2"
          onClick={() => {
            setEditing(false)
            setEditingValue('')
          }}
        >
          Cancel
        </Button>
      </div>
    )
  }

  return (
    <div className={a('list-item__container').m('selected', selected)}>
      <button
        onClick={onSelect}
        {...scope}
        className="list-item__btn flex item-center"
      >
        {type === 'radio' && (
          <Radio
            readOnly
            className="mr-4"
            checked={selected}
            aria-labelledby={text}
          />
        )}
        {type === 'checkbox' && (
          <Checkbox
            readOnly
            className="mr-4"
            checked={selected}
            aria-labelledby={text}
          />
        )}
        <div>
          {title && <div className="text-xl font-semibold">{title}</div>}
          <div>{text}</div>
        </div>
      </button>
      {onEdit && (
        <ActionIcon
          size="sm"
          icon="pencil"
          onClick={() => {
            setEditing(true)
            setEditingValue(text)
          }}
        />
      )}
    </div>
  )
}

const css = k`
  .list__title {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .editing-item__container,
  .list-item__container {
    display: flex;
    width: 100%;
    padding: 8px;
    margin-bottom: 4px;
    border-radius: 4px;
    transition: background .2s ease;
    cursor: pointer;
    border: solid 1px $color-grey-100;
  }

  .list-item__btn {
    flex: 1;
    align-items: center;
    display: flex;
    background: transparent;
    border: none;
    outline: none;
    text-align: left;
  }

  .list-item__container:hover {
    background: $color-active-100;
  }

  .list-item__container.selected {
    background: $color-grey-200;
  }

  .edit__input {
    flex: 1;
  }
`
