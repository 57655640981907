import { doc, updateDoc } from 'firebase/firestore'
import { k, useCss } from 'kremling'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { LayoutHeader } from '@components/layout.component'
import { Loader } from '@components/loader.component'
import { Button } from '@components/mantine/button.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Pill } from '@components/pill/pill.component'
import { Well } from '@components/well.component'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { uniqueIdentifierFromDate } from '@utils/helpers'
import { DateTime } from 'luxon'
import { Controller, useForm } from 'react-hook-form'

export function OrgSettings() {
  const orgs = useAppState(state => state.orgs)
  const isFreeTier = useAppState(state => state.isFreeTier)
  const { orgId } = useParams()
  const selectedOrg = orgs.find(org => org.id === orgId)
  const [saving, setSaving] = useState(false)
  const scoped = useCss(css)
  const uniqueOrgId = useMemo(
    () => uniqueIdentifierFromDate(selectedOrg?.createdDate),
    [selectedOrg],
  )

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
    getValues,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: { orgName: selectedOrg?.name || '' },
  })

  useEffect(() => {
    if (isEmpty(orgs)) return
    setValue('orgName', selectedOrg?.name || '', {
      shouldDirty: false,
    })
  }, [orgs, selectedOrg])

  function saveEdits() {
    setSaving(true)
    const orgRef = doc(db, dbNames.organizations, selectedOrg.id)
    updateDoc(orgRef, {
      name: getValues('orgName'),
    }).then(() => {
      setSaving(false)
    })
  }

  if (!selectedOrg) {
    return (
      <div className="pt-8">
        <Loader />
      </div>
    )
  }

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const convertedDate = DateTime.fromISO(selectedOrg?.paidThroughDate?.date, {
    zone: selectedOrg?.paidThroughDate?.timezone,
  }).setZone(timeZone)

  return (
    <div {...scoped}>
      <div>
        <div>
          <LayoutHeader
            className="pt-8"
            title={
              <div>
                <div className="title">
                  Settings for {selectedOrg.name}
                  <span className="small-text ml-12">#{uniqueOrgId}</span>
                </div>
              </div>
            }
          />
        </div>
        <div className="mb-8">
          <Well className="p-4">
            <div className="mb-8">
              {isFreeTier ? (
                <Pill>Free tier</Pill>
              ) : (
                <Pill pillType="primary">
                  Subscription ends on{' '}
                  {DateTime.fromISO(convertedDate).toFormat('M/d/yy')}
                </Pill>
              )}
            </div>
            {isFreeTier
              ? 'This is a free workspace. For more information about our paid plans click'
              : 'This is a paid workspace. For more information about your subscription click'}
            <a
              href="www.iact.com"
              className="ml-2"
            >
              here
            </a>
          </Well>
        </div>
        <form onSubmit={handleSubmit(saveEdits)}>
          <div>
            <Controller
              control={control}
              name="orgName"
              rules={{
                required: 'Organization name is required',
                validate: value => {
                  const trimmedValue = value.trim()
                  if (trimmedValue === '') {
                    return `Name cannot be empty`
                  } else if (trimmedValue === selectedOrg.name) {
                    return false
                  }
                  return true
                },
              }}
              render={({ field }) => {
                return (
                  <TextInput
                    {...field}
                    error={errors.orgName?.message}
                    autoComplete="off"
                    required
                    withAsterisk={false}
                    label="Edit organization name"
                  />
                )
              }}
            />
          </div>
          <div className="mb-5">
            <Button
              type="submit"
              variant="primary"
              disabled={!isValid}
              className="mt-5"
              loading={saving}
            >
              Save changes
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

const css = k`
  .small-text {
    font-size: 1.4rem;
    color: $color-grey-300;
  }
`
