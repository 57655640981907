// import { DialogEntry } from '@components/dialog/dialog.service'
import { Loader } from '@components/loader.component'
import { userIsGuest } from '@components/select/common/utils'
import { TestingTools } from '@components/testing-tools.component'
import { signOut as authSignOut } from '@firebase/auth'
import { useAppState } from '@src/app.state'
import { auth, functions } from '@src/firebase-app'
import { initAuth } from '@src/index'
import { useGetRoutes } from '@src/navigation/use-get-routes.hook'
import { ProfileInfoModal } from '@src/pages/account/profile-info-modal.component'
import { ModalEntry, modalService } from '@utils/modal.service'
import { ToastEntry, toastService } from '@utils/toast.service'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { httpsCallable } from 'firebase/functions'
import React, { useEffect, useRef } from 'react'
import {
  useLocation,
  useNavigate,
  useRoutes,
  useSearchParams,
} from 'react-router-dom'

export function Root() {
  const user = useAppState(state => state.user)
  const showToolsOverlay = useAppState(state => state.showToolsOverlay)
  const appLoading = useAppState(state => state.appLoading)

  const location = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const modalRendered = useRef(false)

  function signOut() {
    authSignOut(auth)
      .then(() => {
        const subscribers = useAppState.getState().subscribers
        Object.values(subscribers).forEach(subscriber => subscriber())
        useAppState.setState({
          isAuthenticated: false,
          user: null,
          orgs: [],
          subscribers: {},
        })
        navigate('/sign-in')
      })
      .catch(err => toastService.error(err.message))
  }

  useEffect(() => {
    const token = searchParams.get('token')
    if (token) {
      // appState.set({appLoading: true})
      httpsCallable(
        functions,
        'allFunctions-jwtAuthVerification',
      )({ token })
        .then(({ data }) => {
          if (data.userId === user?.id) signOut()
          signInWithCustomToken(getAuth(), data.customToken).then(
            ({ user }) => {
              initAuth(user)
              // appState.set({user, selectedOrg: null, appLoading: false})
            },
          )
        })
        .catch(() => {
          useAppState.setState({ authToken: null })
          navigate('/sign-in')
        })
    }
  }, [searchParams.get('token')])

  useEffect(() => {
    if (!user || appLoading || modalRendered.current) return
    if (userIsGuest(user)) return
    if (user.isFirstLogin && !location.pathname.includes('invite')) {
      modalRendered.current = true
      modalService
        .render(ProfileInfoModal, { user })
        .then(({ newUser = {}, path }) => {
          modalRendered.current = false
          const updatedUser = { ...user, ...newUser }
          useAppState.setState({ user: updatedUser })
          if (path) navigate(path)
        })
    }
  }, [user, appLoading, location.pathname])

  const routes = useGetRoutes()
  const element = useRoutes(routes)

  return (
    <>
      {element}
      <ModalEntry />
      {/*<DialogEntry />*/}
      <ToastEntry />
      {/*{userIsGuest(user) && <GuestAccountStatus user={user} />}*/}
      {/*<MessagingEntry />*/}
      {process.env.ENVIRONMENT !== 'production' && showToolsOverlay && (
        <TestingTools />
      )}
      {appLoading && (
        <Loader
          overlay
          size="lg"
          loadingText="Please wait while we access your account."
        />
      )}
    </>
  )
}
