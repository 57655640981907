import React from 'react'
import { a } from 'kremling'

import { ItemBase } from './item-base'

export function ItemClear({ isSelected, item, onChange }) {
  const onClick = () => {
    onChange(item)
  }

  return (
    <ItemBase
      id={item.id}
      isSelected={isSelected}
      className={a('item-clear').m('item-clear--selected', isSelected)}
      onClick={onClick}
    >
      <div className="item-clear-content">{item.name}</div>
    </ItemBase>
  )
}
