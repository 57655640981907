import '@mantine/core/styles.css'
import { onAuthStateChanged } from 'firebase/auth'
import {
  collection,
  doc,
  documentId,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { isEmpty, remove } from 'lodash'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import './style.css'

import { userIsGuest } from '@components/select/common/utils'
import { MantineProvider } from '@mantine/core'
import { auth, db, functions } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { theme } from '@utils/mantine/mantine-theme'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useAppState } from './app.state'
import { Root } from './root.component'
import './styles/main.scss'

const container = document.querySelector('#app')
const root = createRoot(container)
const queryClient = new QueryClient()
root.render(
  <QueryClientProvider client={queryClient}>
    <MantineProvider theme={theme}>
      <Router>
        <Root />
      </Router>
    </MantineProvider>
  </QueryClientProvider>,
)

const urlParams = new URLSearchParams(window.location.search)
const token = urlParams.get('token')
useAppState.setState({ authToken: token })

export async function initAuth(user) {
  let state = { isAuthenticated: false }

  if (user) {
    const usersRef = doc(db, dbNames.users, user.uid)
    const userData = await getDoc(usersRef)
    const subscribers = useAppState.getState().subscribers
    const newSubscribers = {}
    if (userData.exists() && userIsGuest(userData.data())) {
      return useAppState.setState({
        isAuthenticated: true,
        user: { id: user.uid, ...userData.data() },
      })
    }

    if (!userData.exists() && !userIsGuest(user)) {
      const createUser = httpsCallable(functions, 'userFunctions-createUser')
      await createUser({
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
      })
    }

    if (subscribers.usersSubscription) {
      subscribers.usersSubscription()
      delete subscribers.usersSubscription
    }

    newSubscribers.usersSubscription = onSnapshot(usersRef, userSnapshot => {
      const stateUser = useAppState.getState().user || {}
      if (userSnapshot.exists()) {
        const newState = {}
        newState.user = { ...stateUser, ...userSnapshot.data() }
        useAppState.setState(newState)
      }
    })

    state.subscribers = {
      ...useAppState.getState().subscribers,
      ...newSubscribers,
    }
    state.user = {
      id: user.uid,
      email: user.email,
      metadata: user.metadata,
      displayName: user.displayName,
      isAnonymous: user.isAnonymous,
    }
    state.isAuthenticated = true
    useAppState.setState({ authToken: null })
  } else {
    state.appLoading = false
  }
  useAppState.setState(state)
}

useAppState.subscribe(
  state => state.authToken,
  ({ state }) => {
    const { authToken } = state
    if (!authToken) {
      onAuthStateChanged(auth, initAuth)
    }
  },
)

if (!token) {
  onAuthStateChanged(auth, initAuth)
}

useAppState.subscribe(
  state => state.user,
  state => {
    if (!state) return
    const { id, orgRoles, orgOrderIds } = state
    const subscribers = useAppState.getState().subscribers || {}
    const newSubscribers = {}
    if (!isEmpty(orgRoles)) {
      const filteredKeys = Object.keys(orgRoles).filter(
        key => orgRoles[key] !== 'deactivated',
      )

      const orgQuery = query(
        collection(db, dbNames.organizations),
        where(documentId(), 'in', filteredKeys),
      )

      if (subscribers.orgSubscription) {
        subscribers.orgSubscription()
        delete subscribers.orgSubscription
      }

      if (!subscribers.orgSubscription) {
        newSubscribers.orgSubscription = onSnapshot(
          orgQuery,
          async querySnapshot => {
            const orgs = querySnapshot.docs.map(org => ({
              ...org.data(),
              id: org.id,
            }))
            if (orgs.length !== filteredKeys.length) return // When this happens, it's because the org data hasn't been updated in time.
            return useAppState.setState({
              appLoading: false,
              orgs: orgOrderIds
                .map(orgId => remove(orgs, org => org.id === orgId)[0])
                .filter(Boolean),
            })
          },
        )
      }
    }

    const invitationQuery = query(
      collection(db, dbNames.invitations),
      where('recipientId', '==', id),
      where('status', '==', 'pending'),
      orderBy('timestamp', 'desc'),
    )

    if (subscribers.invitationSubscription) {
      subscribers.invitationSubscription()
      delete subscribers.invitationSubscription
    }

    newSubscribers.invitationSubscription = onSnapshot(
      invitationQuery,
      querySnapshot => {
        const invitations = querySnapshot.docs.map(invitationRef => ({
          ...invitationRef.data(),
          id: invitationRef.id,
        }))
        useAppState.setState({ invitations })
      },
    )

    const notificationsQuery = query(
      collection(db, dbNames.notifications),
      where('recipientId', '==', id),
      orderBy('timestamp', 'desc'),
    )

    if (subscribers.notificationsSubscription) {
      subscribers.notificationsSubscription()
      delete subscribers.notificationsSubscription
    }

    if (!subscribers.notificationsSubscription) {
      newSubscribers.notificationsSubscription = onSnapshot(
        notificationsQuery,
        querySnapshot => {
          const notifications = querySnapshot.docs.map(notificationsRef => ({
            ...notificationsRef.data(),
            id: notificationsRef.id,
          }))
          useAppState.setState({ notifications })
        },
      )
    }

    useAppState.setState(({ subscribers }) => ({
      subscribers: { ...subscribers, ...newSubscribers },
    }))
  },
)

// For assistance debugging deployments
const BUILD_VERSION = process.env.BUILD_VERSION
const BUILD_DATE = process.env.BUILD_DATE

let buildDate = BUILD_DATE ? new Date(BUILD_DATE) : undefined
if (buildDate) {
  document.documentElement.dataset.buildDate = buildDate
}

if (BUILD_VERSION) {
  document.documentElement.dataset.buildVersion = BUILD_VERSION
}
