import React, { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { LayoutHeader } from '@components/layout.component'
import { Button } from '@components/mantine/button.component'
import { Tabs } from '@components/tabs.component'
import { functions } from '@src/firebase-app'
import { httpsCallable } from 'firebase/functions'

export function SuperSettings() {
  const location = useLocation()
  const [migrating, setMigrating] = useState(false)

  if (location.pathname.includes('/t/')) {
    return <Outlet />
  }

  return (
    <div className="iact-container --lg">
      <LayoutHeader
        title="Super Settings"
        className="pt-8"
      />
      <Tabs className="mb-6">
        <Tabs.Link to="orgs">Workspaces</Tabs.Link>
        <Tabs.Link to="library">Library</Tabs.Link>
        <Tabs.Link to="marketplace-settings">Marketplace Settings</Tabs.Link>
        {/*<Tabs.Link to="supers">Super Users</Tabs.Link>*/}
      </Tabs>
      <Outlet />
      <Button
        style={{ position: 'absolute', bottom: 0, right: 0 }}
        loader={migrating}
        onClick={async () => {
          setMigrating(true)
          const dataMigration = httpsCallable(
            functions,
            'utility-dataMigration',
          )
          await dataMigration()
          setMigrating(false)
        }}
      >
        Migrate
      </Button>
    </div>
  )
}

SuperSettings.propTypes = {}
