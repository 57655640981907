import React from 'react'
import { useCss, k } from 'kremling'
import {
  collection,
  doc,
  getCountFromServer,
  getDoc,
  query,
  where,
} from 'firebase/firestore'

import { Icon } from '@components/icon.component'
import { getUserFullName } from '@utils/helpers'
import { useLoad } from '@hooks/use-load.hook'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { Pill } from '@components/pill/pill.component'
import { WorkflowProgressBar } from '@components/workflow-progress-bar.component'
import { Loader } from '@components/loader.component'
import { isEmpty } from 'lodash'

export function MentoringInviteBody({ inviteUser, org, activityId }) {
  const scoped = useCss(css)

  const [activity] = useLoad(
    {},
    () => {
      const d = doc(db, dbNames.activities, activityId)
      return getDoc(d).then(snap => ({ id: snap.id, ...snap.data() }))
    },
    [activityId],
  )

  const [activityUser] = useLoad(
    {},
    () => {
      const d = doc(db, dbNames.activityUsers, `${activityId}_${inviteUser.id}`)
      return getDoc(d).then(snap => ({ id: snap.id, ...snap.data() }))
    },
    [activityId, inviteUser.id],
  )

  const [sectionsCount] = useLoad(
    null,
    () => {
      const q = query(
        collection(db, dbNames.activitySections),
        where('activityId', '==', activityId),
      )
      return getCountFromServer(q).then(snap => snap.data().count)
    },
    [activityId],
  )

  const orgName = org.id === inviteUser.id ? '' : org.name

  return (
    <div
      {...scoped}
      className="invitation-details__body"
    >
      <div className="user__title">
        <div className="user-avatar">
          <Icon
            name="user"
            size={10}
          />
        </div>{' '}
        {getUserFullName(inviteUser)}
      </div>
      <div className="mt-5">Has invited you to mentor their workflow</div>
      <div className="description__container">
        {isEmpty(activity) ? (
          <Loader />
        ) : (
          <>
            <div className="top-section__container">
              <div className="title__container">
                <div>
                  <div className="user-avatar">
                    <Icon
                      name="user"
                      size={10}
                    />
                  </div>
                </div>
                <div>
                  <div className="workflow-title">{activity.name}</div>
                  <div>
                    {getUserFullName(inviteUser)} {orgName}
                  </div>
                </div>
              </div>
              <div>
                <Pill>
                  {sectionsCount} section{sectionsCount === 1 ? '' : 's'}
                </Pill>
              </div>
            </div>
            <div className="bottom-section__container">
              <WorkflowProgressBar
                activity={activity}
                activityUser={activityUser}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const css = k`
  .top-section__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  .workflow-title {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .title__container {
    display: flex;
    align-items: center;
  }

  .bottom-section__container {
    width: 100%;
  }
`
