import { Button } from '@components/mantine/button.component'
import { Modal } from '@components/modal.component'
import { Checkbox } from '@components/radix/checkbox'
import { Rating } from '@components/rating.component'
import { SimpleEditor } from '@components/rich-text/simple-editor/simple-editor.component'
import { Loader } from '@mantine/core'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { doc, updateDoc } from 'firebase/firestore'
import React, { useEffect } from 'react'
import wretch from 'wretch'

export function FeedbackModal({
  onResolve,
  userId,
  activityId,
  section,
  isLast,
}: {
  onResolve: () => void
  userId: string
  activityId: string
  section: ActivitySection
  isLast: boolean
}) {
  const [value, setValue] = React.useState(0)
  const [description, setDescription] = React.useState('')
  const [loadingSummary, setLoadingSummary] = React.useState(false)
  const [summary, setSummary] = React.useState<{
    summary: string
    topics: string[]
  }>(null)

  useEffect(() => {
    const messages = [
      {
        role: 'system',
        content: `
        I would like you to provide a list of things learned from the data that is provided.
        Do not include any other text or conversational content other than the list. The response should be in valid JSON.
        Disregard any text that contains practical instructions to the user. The content of your response should be contextual to content learned.
        Don't describe the structure of the content, only reference the section title, field label, and field description.
        Don't include any other fields that are not in the example below
        Example:
        {
          "topics": ["Healthy eating", "Exercise", "Prioritize sleep"]
        }
        `,
      },
      { role: 'user', content: JSON.stringify(section.fields) },
    ]

    setLoadingSummary(true)

    wretch(`${process.env.REST_URL_PREFIX}/allFunctions-sendPrompt`)
      .post({ messages })
      .json(json => json)
      .then(
        res => {
          const lastMessage = res.at(-1).content
          setSummary(JSON.parse(lastMessage))
          setLoadingSummary(false)
        },
        err => {
          console.log(err)
        },
      )
  }, [])

  function onSubmit() {
    if (value || description) {
      wretch(
        `${process.env.USER_ANALYTICS_URL}/api/activity/log-rating/${activityId}/${userId}/${section.id}`,
      ).post({
        rating: value,
        description,
      })
    }
    onResolve()
  }

  return (
    <>
      <Modal.Header>Well done!</Modal.Header>
      <Modal.Body>
        <div className="space-y-8">
          <div className="space-y-8">
            <div className="p-4 bg-gradient-to-r from-primary to-stats-two rounded-lg space-y-2 text-white relative min-h-20">
              {loadingSummary ? (
                <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center">
                  <Loader color="white" />
                </div>
              ) : (
                <>
                  <p className="text-2xl font-semibold">What you learned:</p>
                  <ul className="list-disc list-outside ml-10 mb-0">
                    {summary?.topics.map(topic => (
                      <li
                        className="text-2xl"
                        key={topic}
                      >
                        {topic}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            {isLast && (
              <p className="text-3xl font-semibold">
                Great job on finishing this course! We hope you enjoyed it.
              </p>
            )}
          </div>
          <p className="text-2xl font-semibold">
            We hope you're enjoying the experience so far. We value your
            feedback.
          </p>
          <div>
            {isLast ? (
              <label htmlFor="activity-rating">
                Please rate your experience with this course
              </label>
            ) : (
              <label htmlFor="activity-rating">
                Please rate this course so far
              </label>
            )}
            <Rating
              value={value}
              onChange={setValue}
              id="activity-rating"
            />
          </div>
          <div>
            <label>
              Please share any thoughts or suggestions to help us improve your
              learning journey
            </label>
            <SimpleEditor
              id="activity-feedback"
              onChange={setDescription}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex items-center justify-between">
          <div>
            {!isLast && (
              <Checkbox
                onCheckedChange={value => {
                  const d = doc(
                    db,
                    dbNames.activityUsers,
                    `${activityId}_${userId}`,
                  )
                  updateDoc(d, {
                    noFeedbackUntilEnd: value,
                  })
                }}
                label="Ask me at the end of the course"
              />
            )}
          </div>
          <Button
            variant="primary"
            onClick={onSubmit}
          >
            Close
          </Button>
        </div>
      </Modal.Footer>
    </>
  )
}
