import { Card } from '@components/card.component'
import { Loader } from '@components/loader.component'
import { Logo } from '@components/logo.component'
import { Button } from '@components/mantine/button.component'
import { userIsGuest } from '@components/select/common/utils'
import { useAppState } from '@src/app.state'
import { auth, db, functions } from '@src/firebase-app'
import { WorkflowHeadless } from '@src/pages/workflow/headless/workflow-headless.component'
import { dbNames } from '@utils/constants'
import { onAuthStateChanged, signInWithCustomToken } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { k, useCss } from 'kremling'
import { isNil } from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useState } from 'react'
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { create } from 'zustand'

type VerificationData = {
  status: PageStatus
  activityId: string
  orgId?: string
  userId?: string
  accessStatus: string
  name?: string
  startDate?: string
  endDate?: string
  authToken?: string
  user?: User
}

type PageStatus =
  | 'valid'
  | 'invalid'
  | 'expired'
  | 'notStarted'
  | 'error'
  | 'registered'
  | 'participant'
  | 'guest-participant'
  | null

type HeadlessState = {
  loading: boolean
  isAuthenticated: boolean
  authCompleted: boolean
  user: User
  status: PageStatus
}

export const useHeadlessState = create<HeadlessState>(() => ({
  loading: true,
  isAuthenticated: false,
  authCompleted: false,
  user: null,
  status: null,
}))

export function HeadlessActivity() {
  const scope = useCss(css)
  const { linkId } = useParams()
  const [searchParams] = useSearchParams()
  const { search } = useLocation()

  const loading = useHeadlessState(s => s.loading)
  const isAuthenticated = useHeadlessState(s => s.isAuthenticated)
  const authCompleted = useHeadlessState(s => s.authCompleted)
  const user = useHeadlessState(s => s.user)
  const status = useHeadlessState(s => s.status)

  const [validating, setValidating] = useState(true)
  const [verificationData, setVerificationData] =
    useState<VerificationData>(null)
  const displayLoader = useMemo(
    () =>
      (!isAuthenticated && validating) || isNil(verificationData) || loading,
    [isAuthenticated, validating, verificationData, loading],
  )

  const navigate = useNavigate()
  const token = searchParams.get('editToken')

  useEffect(() => {
    if (!authCompleted || status === 'registered') return

    httpsCallable(
      functions,
      'allFunctions-validateActivityLink',
    )({
      token,
      userId: user?.id,
      linkId,
    }).then(({ data }: { data: VerificationData }) => {
      const _user = user || data.user
      const userHasAccount = !userIsGuest(_user)
      const userIsParticipant = data.status === 'participant'

      if (userHasAccount && userIsParticipant) {
        navigate(`/${data.orgId}/home/${_user.id}/${data.activityId}`)
      }

      setVerificationData(data)

      if (!isAuthenticated && token) {
        signInWithCustomToken(auth, data.authToken).then(() => {
          useAppState.setState({
            isAuthenticated: true,
            user: _user,
          })
        })
      }

      if (
        userIsGuest(_user) &&
        (data.status === 'participant' || data.status === 'valid')
      ) {
        useHeadlessState.setState({
          loading: false,
          status: 'guest-participant',
        })
      } else {
        useHeadlessState.setState({ loading: false, status: data.status })
      }
      setValidating(false)
    })
  }, [authCompleted, user])

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        const d = doc(db, dbNames.users, user.uid)
        getDoc(d).then(doc => {
          if (doc.exists()) {
            useHeadlessState.setState({
              user: { ...doc.data(), id: doc.id } as User,
            })
          }
        })
        useHeadlessState.setState({
          isAuthenticated: true,
          loading: false,
          authCompleted: true,
        })
      } else {
        useHeadlessState.setState({
          isAuthenticated: false,
          loading: false,
          authCompleted: true,
        })
      }
    })
  }, [])

  if (displayLoader) {
    return (
      <div className="content-wrapper">
        <Loader
          size="lg"
          overlay
        />
      </div>
    )
  }

  switch (status) {
    case 'invalid':
      return (
        <div
          {...scope}
          className="status-wrapper"
        >
          <Card className="notice-card">
            <div className="mt-5">
              <Logo />
            </div>
            <h1 className="notice-title mt-5">Invalid link</h1>
            <div>This link has either expired or is not correct.</div>
            <div>Please try a different link.</div>
          </Card>
        </div>
      )
    case 'expired':
      return (
        <div
          {...scope}
          className="status-wrapper"
        >
          <Card className="notice-card">
            <div className="mt-5">
              <Logo />
            </div>
            <h1 className="notice-title">
              Access to <b>{verificationData.name}</b> ended{' '}
              {DateTime.fromISO(verificationData.endDate).toRelative()}
            </h1>
          </Card>
        </div>
      )
    case 'valid':
      return token ? (
        <Navigate
          to={`/guest/${verificationData.userId}/${verificationData.activityId}/${token}${search}`}
        />
      ) : (
        <div
          {...scope}
          className="content-wrapper headless"
        >
          <div className="fields__container">
            <WorkflowHeadless
              user={user}
              activityId={verificationData.activityId}
            />
          </div>
        </div>
      )
    case 'notStarted':
      return (
        <div
          {...scope}
          className="status-wrapper"
        >
          <Card className="notice-card">
            <div className="mt-5">
              <Logo />
            </div>
            <h1 className="notice-title">
              {verificationData.name} starts{' '}
              {DateTime.fromISO(verificationData.startDate).toRelative()}
            </h1>
          </Card>
        </div>
      )
    case 'guest-participant':
      return (
        <Navigate
          to={`/guest/${verificationData.userId}/${verificationData.activityId}/${token}${search}`}
        />
      )
    case 'error':
    default:
      return (
        <div
          {...scope}
          className="status-wrapper"
        >
          <Card className="notice-card">
            <div className="mt-5">
              <Logo />
            </div>
            <h1 className="notice-title mt-5">Error</h1>
            <div>Sorry there was an error validating this link.</div>
            <div>Please try again later.</div>
          </Card>
        </div>
      )
  }
}

export function ActivityRegisterSuccess() {
  const { state } = useLocation()
  const navigate = useNavigate()
  const { editToken } = useParams()

  useEffect(() => {
    useAppState.setState({ appLoading: false })
  }, [])

  return (
    <div className="iact-container">
      <div className="flex justify-center items-center h-[100dvh]">
        <Card className="w-[40rem] p-5 !flex flex-col justify-center items-center">
          <div className="mt-5 mb-5">
            <Logo />
          </div>
          <h1 className="text-2xl font-semibold">Registration successful!</h1>
          <div className="mb-1">
            Check your email for an edit link to be able to continue
            participating.
          </div>
          <div>
            <Button
              variant="secondary"
              onClick={() =>
                navigate(
                  `/guest/${state.userId}/${state.activityId}/${editToken}`,
                )
              }
            >
              Go to activity
            </Button>
          </div>
        </Card>
      </div>
    </div>
  )
}

const css = k`
  .header {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid $color-grey-100;
    background: linear-gradient(94.42deg, #3151BE 24.59%, #BE51B2 111.8%);
  }

  .fields__container {
    width: 100%;
    height: 100dvh;
  }

  .status-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh
  }

  .notice-card {
    width: 40rem;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .notice-title {
    margin-bottom: 0;
    font-size: 1.8rem;
    font-weight: 500;
  }
`
