import React from 'react'
import { useCss, k, a } from 'kremling'

import { Pill } from '@components/pill/pill.component'
import { Icon } from '@components/icon.component'
import { SelectSingle } from '@components/select-single.component'

export function PillSelect(props) {
  const scope = useCss(css)

  return (
    <div {...scope}>
      <SelectSingle
        {...props}
        contentWidth="sm"
        contentHeight="auto"
        renderTrigger={({ value: selectedValue, toggle }) => (
          <button
            className={a('button__outer')}
            onClick={toggle}
          >
            <Pill>
              {selectedValue.name}
              <Icon
                className="ml-1"
                name="caret-down"
                size={14}
              />
            </Pill>
          </button>
        )}
      />
    </div>
  )
}

const css = k`
  .button__outer {
    all: unset;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`
