import React, { useEffect, useState } from 'react'

import { convertMsToTime } from '@components/duration-input.component'
import { getChallengeResponsesForChallenge } from '@src/pages/workflow/settings/analytics.resource'
import { toastService } from '@utils/toast.service'
import { WorkflowLeaderboard } from './workflow-leaderboard.component'

export function WorkflowLeaderboardItemDuration({
  isAdmin,
  workflowChallenge,
  onEdit,
  onDelete,
  participantsCount,
}) {
  const [responses, setResponses] = useState([])

  useEffect(() => {
    getChallengeResponsesForChallenge(workflowChallenge)
      .then(setResponses)
      .catch(err => {
        console.log(err)
        toastService.error(
          'There was an error retrieving challenge data. Please try again later.',
        )
      })
  }, [])

  function displayValue({ value }) {
    return convertMsToTime(value).join(':')
  }

  function displayGoal(value) {
    return convertMsToTime(value).join(':')
  }

  return (
    <WorkflowLeaderboard
      workflowChallenge={workflowChallenge}
      processValue={({ value }) => ({ value: Number(value) })}
      responses={responses}
      displayGoal={displayGoal}
      displayValue={displayValue}
    >
      <WorkflowLeaderboard.Header
        participantsCount={participantsCount}
        canEdit={isAdmin}
        onEdit={onEdit}
        onDelete={onDelete}
      />
      <WorkflowLeaderboard.Body />
    </WorkflowLeaderboard>
  )
}
