import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { k, useCss } from 'kremling'
import React, { useEffect, useMemo, useState } from 'react'

import { Icon } from '@components/icon.component'
import { Button } from '@components/mantine/button.component'
import { Modal } from '@components/modal.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { MentoringInviteBody } from '@src/pages/dashboard/mentoring-invite-body.component'
import { WorkspaceInviteBody } from '@src/pages/dashboard/workspace-invite-body.component'
import { dbNames } from '@utils/constants'
import { toastService } from '@utils/toast.service'
import { useNavigate } from 'react-router-dom'
import { invitationActions } from './invitation-actions'

export function InvitationDetailsModal({ invitation, org, onResolve }) {
  const scoped = useCss(css)
  const [accepting, setAccepting] = useState(false)
  const [declining, setDeclining] = useState(false)
  const user = useAppState(state => state.user)
  const navigate = useNavigate()

  useEffect(() => {
    if (!invitation.dirty) {
      updateDoc(doc(db, dbNames.invitations, invitation.id), { dirty: true })
    }
  }, [invitation])

  const [inviteUser] = useLoad(
    {},
    () => {
      const inviterId = invitation.invitationEvents.at(-1).inviterId
      return getDoc(doc(db, dbNames.users, inviterId)).then(snap => ({
        ...snap.data(),
        id: snap.id,
      }))
    },
    [],
  )

  function onAccept() {
    setAccepting(true)
    invitationActions[invitation.type]
      .accept(invitation, user)
      .then(({ inviterOrgId }) => {
        if (invitation.type === 'org') {
          navigate(`/${inviterOrgId}`)
        }
        onResolve()
      })
      .catch(err => {
        console.log(err)
        toastService.error('An error occurred while accepting this invitation.')
        setAccepting(false)
      })
  }

  function onDecline() {
    setDeclining(true)
    invitationActions[invitation.type]
      .decline(invitation)
      .then(() => {
        onResolve()
      })
      .catch(() => {
        toastService.error('An error occurred while declining this invitation.')
        setDeclining(false)
      })
  }

  const title = useMemo(() => {
    if (invitation.type === 'org') {
      return 'New Workspace Invite'
    } else if (invitation.type === 'peerMentor') {
      return 'New Mentoring Invite'
    }
  }, [invitation.type])

  return (
    <div {...scoped}>
      <Modal.Header>
        <div className="title-square">
          <Icon
            name="envelope"
            size={16}
          />
        </div>
        {title}
      </Modal.Header>
      {invitation.type === 'org' && (
        <WorkspaceInviteBody
          org={org}
          inviteUser={inviteUser}
        />
      )}
      {invitation.type === 'peerMentor' && (
        <MentoringInviteBody
          org={org}
          inviteUser={inviteUser}
          activityId={invitation.itemId}
        />
      )}
      {invitation.type === 'linkInvite' && <div>Link invite</div>}
      <Modal.Footer>
        <div className="actions__container">
          <Button
            variant="tertiary"
            onClick={onResolve}
          >
            Decide later
          </Button>
          <div>
            <Button
              variant="secondary"
              className="mr-2"
              onClick={onDecline}
              disabled={accepting || declining}
              loading={declining}
            >
              Decline
            </Button>
            <Button
              variant="primary"
              onClick={onAccept}
              disabled={accepting || declining}
              loading={accepting}
            >
              Accept invite
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </div>
  )
}

const css = k`
  .title-square {
    background: $color-primary;
    color: white;
    border-radius: $base-border-radius;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  .invitation-details__body {
    background: #EEF8FF;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .actions__container {
    display: flex;
    justify-content: space-between;
  }

  .user__title {
    font-size: 1.4rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .user-avatar {
    background: $color-grey-100;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-grey-25;
  }

  .description__container {
    background: #fff;
    padding: 16px;
    border-radius: $base-border-radius;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
