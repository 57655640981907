import React from 'react'
import { SettingsList } from './settings-list.component'
import { useShareSettings } from './use-share-settings.hook'

interface ShareOptionsProps {
  node?: ShareSetting[]
  path?: string[]
  selectedValue: InviteAccesType
  onChange: (value: InviteAccesType) => void
}

const ShareSettings: React.FC<ShareOptionsProps> = ({
  selectedValue,
  onChange,
}) => {
  const { settings, updateSelectedOption } = useShareSettings(
    selectedValue,
    onChange,
  )

  return (
    <SettingsList
      settings={settings}
      updateSetting={updateSelectedOption}
    />
  )
}

export default ShareSettings
