import React from 'react'
import { useCss, k } from 'kremling'

export function LegalReference() {
  const scoped = useCss(css)

  return (
    <div
      {...scoped}
      className="legal-container"
    >
      By continuing, you agree to our{' '}
      <a
        href="https://iact.com/legal/"
        target="_blank"
        rel="noreferrer"
      >
        terms of service and privacy policy
      </a>
      .
    </div>
  )
}

const css = k`
  .legal-container {
    margin-top: 1.2rem;
    font-size: 1.2rem;
    max-width: 19rem;
    text-align: center;
  }
`
