import { Rating as MantineRating } from '@mantine/core'
import React from 'react'

export function Rating({
  value,
  onChange,
  size = 50,
  id = 'rating',
}: {
  value: number
  onChange: (value: number) => void
  id?: string
  size?: number
}) {
  return (
    <div>
      <MantineRating
        value={value}
        onChange={onChange}
        size={size}
        id={id}
      />
    </div>
  )
}
