import { chunk } from 'lodash'
import wretch from 'wretch'
import {
  generateActivitiesPrompt,
  generateEmpathyChallengesPrompt,
  generateEmpathyInsightsPrompt,
  generateExperienceIdeasPrompt,
  generateMessageHistory,
  generateTouchpointsPrompt,
} from './assistant-prompts'
import { arrayToBulletedList } from './assistant.helpers'

const url = `${process.env.REST_URL_PREFIX}/allFunctions-sendPrompt`

export async function getResearchIdeas(context) {
  const { outcomeDescription, audienceDemographics, audienceState } =
    context.fields

  let messages = [
    ...generateMessageHistory(context),
    { role: 'user', content: generateEmpathyInsightsPrompt(context) },
  ]

  const [insights, rawInsights] = await wretch(url)
    .post({ messages })
    .json(json => json)
    .then(
      res => {
        const lastMessage = res.at(-1).content
        const { insights } = JSON.parse(lastMessage)
        messages = res
        return [insights, lastMessage]
      },
      err => {
        console.log(err)
      },
    )

  messages = [
    ...messages,
    { role: 'user', content: generateEmpathyChallengesPrompt(context) },
  ]

  const [challenges, rawChallenges] = await wretch(url)
    .post({ messages })
    .json(json => json)
    .then(
      res => {
        const lastMessage = res.at(-1).content
        const { challenges } = JSON.parse(lastMessage)
        messages = res
        return [challenges, lastMessage]
      },
      err => {
        console.log(err)
      },
    )

  return {
    audienceDemographics,
    outcomeDescription,
    audienceState,
    ideas: {
      insights,
      challenges,
    },
    rawChallenges,
    rawInsights,
  }
}

export async function getExperienceIdeas(context) {
  const messages = [
    ...generateMessageHistory(context),
    { role: 'user', content: generateExperienceIdeasPrompt(context) },
  ]

  return wretch(`${process.env.REST_URL_PREFIX}/allFunctions-sendPrompt`)
    .post({ messages })
    .json(json => json)
    .then(
      res => {
        const lastMessage = res.at(-1).content
        const { experiences } = JSON.parse(lastMessage)
        return {
          experienceIdeas: experiences,
          rawExperienceIdea: lastMessage,
        }
      },
      err => {
        console.log(err)
      },
    )
}

export async function loadMoreExperienceIdeas(context) {
  const promptContent = `
    In addition to this list:
    ${arrayToBulletedList(context.lists.experienceIdeas)}

    Provide an additional 5 experiences within an array assigned to a property called 'experiences' that will help the user move to the desired state.
    Remember to consider the insights and challenges listed before.
  `

  const messages = [
    ...generateMessageHistory(context),
    { role: 'user', content: promptContent },
  ]

  return wretch(url)
    .post({ messages })
    .json(json => json)
    .then(
      res => {
        const lastMessage = res.at(-1).content
        const { experiences } = JSON.parse(lastMessage)
        return {
          experienceIdeas: experiences,
          rawExperienceIdea: lastMessage,
        }
      },
      err => {
        console.log(err)
      },
    )
}

export async function getSections(context) {
  const messages = [
    ...generateMessageHistory(context),
    { role: 'user', content: generateTouchpointsPrompt(context) },
  ]

  return wretch(url)
    .post({ messages })
    .json(json => json)
    .then(
      res => {
        const lastMessage = res.at(-1).content
        const { sections } = JSON.parse(lastMessage)
        return {
          sections,
          rawSections: lastMessage,
        }
      },
      err => {
        console.log(err)
      },
    )
}

export async function getActivities(context) {
  const chunks = chunk(context.selectedItems.sections, 1)

  const promises = []
  chunks.forEach(sections => {
    const messages = [
      ...generateMessageHistory(context),
      {
        role: 'user',
        content: generateActivitiesPrompt({ selectedItems: { sections } }),
      },
    ]

    promises.push(activitiesPromise(messages))
  })

  const results = await Promise.all(promises)
  return results.reduce(
    (acc, result) => {
      return {
        activities: [...acc.activities, result.activities],
        rawActivities: [...acc.rawActivities, result.rawActivities],
      }
    },
    { activities: [], rawActivities: [] },
  )
}

function activitiesPromise(messages) {
  return wretch(url)
    .post({ messages })
    .json(json => json)
    .then(res => {
      const lastMessage = res.at(-1).content
      const activities = JSON.parse(lastMessage)
      return {
        activities,
        rawActivities: lastMessage,
      }
    })
    .catch(err => {
      console.log(err)
    })
}
