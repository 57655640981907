import React, { useRef, useEffect } from 'react'
import * as d3 from 'd3'
import { isEmpty } from 'lodash'

type RadialBarChartProps = {
  data: any[]
  size?: number
}

export function RadialBarChart({ data, size = 200 }: RadialBarChartProps) {
  const chartRef = useRef()

  useEffect(() => {
    if (isEmpty(data)) return

    const BASE_SIZE = 200
    const scaleSize = size / BASE_SIZE
    const duration = 500
    const width = size,
      height = size

    const getRadiusData = (i: number) => {
      const arcThickness = 10 * scaleSize
      const totalRadiusSpace = size / 2 - arcThickness // Entire space available for arcs
      const eachRadiusSpace = totalRadiusSpace / data.length // Divide available space by the number of data points
      const innerRadius = i * eachRadiusSpace + 22 * scaleSize
      const outerRadius = (i + 1) * eachRadiusSpace + 8 * scaleSize

      return { innerRadius, outerRadius }
    }

    const svg = d3
      .select(chartRef.current)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2},${height / 2})`)

    // Background arcs
    data.forEach((d, i) => {
      const { innerRadius, outerRadius } = getRadiusData(i)

      const backgroundArcGenerator = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .cornerRadius(30 * scaleSize)
        .startAngle(0)
        .endAngle(2 * Math.PI)

      svg
        .append('path')
        .attr('d', backgroundArcGenerator)
        .attr('fill', '#D9D9D9')
    })

    // Animated arcs
    const scale = d3
      .scaleLinear()
      .domain([0, 100])
      .range([0, 2 * Math.PI])
      .clamp(true)

    const paths = svg
      .selectAll('.ring')
      .data(data)
      .join('path')
      .attr('fill', d => d.color)

    paths
      .attr('d', (d, i) => {
        const { innerRadius, outerRadius } = getRadiusData(i)
        const arcGenerator = d3
          .arc()
          .innerRadius(innerRadius)
          .outerRadius(outerRadius)
          .cornerRadius(30 * scaleSize)
          .startAngle(0)
          .endAngle(0)
        return arcGenerator(d)
      })
      .classed('ring', true)
      .transition()
      .duration(duration)
      .ease(d3.easeQuadInOut)
      .delay((d, i) => (i * duration) / 7)
      .attrTween('d', (d, i) => {
        const { innerRadius, outerRadius } = getRadiusData(i)
        const arcGenerator = d3
          .arc()
          .innerRadius(innerRadius)
          .outerRadius(outerRadius)
          .cornerRadius(30 * scaleSize)
          .startAngle(0)
        const interpolate = d3.interpolate(0, scale(d.value))
        return (t: number): string => {
          arcGenerator.endAngle(interpolate(t))
          return arcGenerator(d)
        }
      })
  }, [data, size])

  return <svg ref={chartRef}></svg>
}
