import {
  collection,
  getCountFromServer,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { k, useCss } from 'kremling'
import * as ls from 'local-storage'
import { isEmpty, partition } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { InvitationCard } from '@components/cards/invitation-card.component'
import { UpcomingActivityCard } from '@components/cards/upcoming-activity-card.component'
import { EmptyState } from '@components/empty-state.component'
import { Icon } from '@components/icon.component'
import { Button } from '@components/mantine/button.component'
import { AddOrgModal } from '@components/orgs/add-org-modal.component'
import { Pill } from '@components/pill/pill.component'
import { Dropdown } from '@components/radix/dropdown'
import { Well } from '@components/well.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { MentoringStatusCard } from '@src/pages/dashboard/mentoring-status-dashbaord-card.component'
import { WorkflowDashboardCard } from '@src/pages/dashboard/workflow-dashboard-card.component'
import { SelfAddWorkflowModal } from '@src/pages/workflow/self-add-workflow-modal.component'
import { dbNames } from '@utils/constants'
import { collections } from '@utils/firebase.utils'
import { getTodayDate } from '@utils/helpers'
import { modalService } from '@utils/modal.service'
import { toastService } from '@utils/toast.service'

const emptyStateMinHeight = 15
const enableMarketplace =
  process.env.MARKETPLACE == 'true' ||
  ls.get('iact-marketplace-feature') == true

export const Dashboard = () => {
  const scope = useCss(css)
  const user = useAppState(state => state.user)
  const orgs = useAppState(state => state.orgs)
  const invitations = useAppState(state => state.invitations)
  const systemDate = useAppState(state => state.systemDate)
  const [selectedOrgId, setSelectedOrgId] = useState('all')
  const [mentoringActivities, setMentoringActivities] = useState([])
  const [totalMentoredWorkflows, setTotalMentoredWorkflows] = useState(null)
  const today = useMemo(() => getTodayDate(), [systemDate])

  const [
    [upcomingActivities, activeActivities],
    _setActivities,
    activitiesOpts,
  ] = useLoad(
    [[], []],
    async () => {
      if (!user) return Promise.resolve([[], []])
      let q = query(
        collections.activities,
        orderBy('endDate.date'),
        where('userIds', 'array-contains', user.id),
        where('endDate.date', '>=', today.toISO()),
        where('completedDate', '==', null),
        limit(5),
      )

      if (selectedOrgId !== 'all') {
        q = query(
          collections.activities,
          orderBy('endDate.date'),
          where('userIds', 'array-contains', user.id),
          where('orgId', '==', selectedOrgId),
          where('endDate.date', '>=', today.toISO()),
          where('completedDate', '==', null),
          limit(5),
        )
      }

      return getDocs(q)
        .then(snap => snap.docs.map(doc => ({ ...doc.data(), id: doc.id })))
        .then(all =>
          partition(all, activity => activity.startDate.date >= today.toISO()),
        )
    },
    [selectedOrgId],
  )

  const [totalActiveActivities] = useLoad(
    [[], []],
    async () => {
      if (!user) return Promise.resolve([[], []])
      let q = query(
        collection(db, dbNames.activities),
        orderBy('endDate.date'),
        where('userIds', 'array-contains', user.id),
        where('endDate.date', '>=', today.toISO()),
      )

      if (selectedOrgId !== 'all') {
        q = query(
          collections.activities,
          orderBy('endDate.date'),
          where('userIds', 'array-contains', user.id),
          where('orgId', '==', selectedOrgId),
          where('endDate.date', '<', today.toISO()),
        )
      }
      return getCountFromServer(q).then(snap => snap.data().count)
    },
    [selectedOrgId],
  )

  useEffect(() => {
    let activityMentorsQuery = query(
      collection(db, dbNames.activityMentors),
      where('userId', '==', user.id),
      limit(5),
    )

    if (selectedOrgId !== 'all') {
      activityMentorsQuery = query(
        collection(db, dbNames.activityMentors),
        where('userId', '==', user.id),
        where('mentorOrgId', '==', selectedOrgId),
        where('deactivated', '!=', true),
        where('activityEndDate.date', '>=', today.toISO()),
        limit(5),
      )
    }

    getCountFromServer(activityMentorsQuery).then(workflowsCount => {
      setTotalMentoredWorkflows(workflowsCount.data().count)
    })

    return onSnapshot(activityMentorsQuery, async snap => {
      if (snap.empty) {
        return setMentoringActivities([])
      }

      const _mentoringActivities = snap.docs.map(mq => ({
        ...mq.data(),
        id: mq.id,
      }))
      setMentoringActivities(_mentoringActivities)
    })
  }, [selectedOrgId])

  function openAddOrgModal() {
    modalService.render(AddOrgModal).then(() => {
      toastService.info('Successfully created workspace')
    })
  }

  async function openStartWorkflowModal() {
    modalService.render(SelfAddWorkflowModal).then(() => {
      activitiesOpts.reload()
      toastService.info('Successfully created workflow')
    })
  }

  return (
    <div
      {...scope}
      className="--lg dashboard-wrapper iact-container"
    >
      <div className="flex items-center justify-between">
        <div className="title">Dashboard</div>
        <div>
          {/*<Dropdown*/}
          {/*  position="bottom-left"*/}
          {/*  contentWidth="sm"*/}
          {/*  renderTrigger={({*/}
          {/*    toggle,*/}
          {/*  }: {*/}
          {/*    toggle: MouseEventHandler<HTMLButtonElement>*/}
          {/*  }) => (*/}
          {/*    <ActionIcon*/}
          {/*      size="sm"*/}
          {/*      variant="secondary"*/}
          {/*      onClick={toggle}*/}
          {/*      icon="plus"*/}
          {/*    />*/}
          {/*  )}*/}
          {/*  renderContent={({ close }: { close: () => void }) => (*/}
          {/*    <div className="select-list">*/}
          {/*      <button*/}
          {/*        onClick={() => {*/}
          {/*          openAddOrgModal()*/}
          {/*          close()*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        Create workspace*/}
          {/*      </button>*/}
          {/*      <button*/}
          {/*        onClick={() => {*/}
          {/*          openStartWorkflowModal()*/}
          {/*          close()*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        Start workflow*/}
          {/*      </button>*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*/>*/}
          <Dropdown>
            <Dropdown.Trigger>
              <Button
                variant="secondary"
                onClick={() => openAddOrgModal()}
              >
                Create workspace
              </Button>
            </Dropdown.Trigger>
            <Dropdown.Content>
              <Button
                onClick={() => {
                  openStartWorkflowModal()
                }}
              >
                Start workflow
              </Button>
            </Dropdown.Content>
          </Dropdown>
        </div>
      </div>
      <div className="mb-6 flex">
        <div className="filters__list scrollbar-none">
          <Button
            className="mr-2"
            variant={selectedOrgId === 'all' ? 'primary' : 'secondary'}
            onClick={() => setSelectedOrgId('all')}
          >
            All
          </Button>
          {orgs.map(org => (
            <Button
              className="mr-2"
              key={org.id}
              onClick={() => setSelectedOrgId(org.id)}
              variant={selectedOrgId === org.id ? 'primary' : 'secondary'}
            >
              {org.name}
            </Button>
          ))}
        </div>
      </div>
      <div className="tiles__container">
        <div className="left-tiles__container">
          <div className="media-hide-5">
            <InvitationsWell
              invitations={invitations}
              collapsable
            />
          </div>
          <Well className="mb-4">
            <Well.Header>
              <div className="well-header-split">
                <div className="well-title">
                  <Icon
                    className="mr-4"
                    name="square-list"
                  />
                  <span>My Workflows</span>
                </div>
                {totalActiveActivities > 5 && (
                  <div>
                    <Pill>{totalActiveActivities} total</Pill>
                  </div>
                )}
              </div>
            </Well.Header>
            <Well.Body>
              {isEmpty(activeActivities) ? (
                <EmptyState
                  height={emptyStateMinHeight}
                  title="No active workflows"
                  subtitle={
                    enableMarketplace ? (
                      <>
                        Check out the <Link to="/marketplace">Marketplace</Link>{' '}
                        to find your next challenge!
                      </>
                    ) : null
                  }
                />
              ) : (
                <div className="space-y-4">
                  {(activeActivities as Activity[]).map(activeActivity => (
                    <WorkflowDashboardCard
                      key={activeActivity.id}
                      activity={activeActivity}
                    />
                  ))}
                </div>
              )}
            </Well.Body>
          </Well>
          <Well className="mb-4">
            <Well.Header>
              <div className="well-header-split">
                <div className="well-title">
                  <Icon
                    className="mr-4"
                    name="square-list"
                  />
                  <span>Mentoring</span>
                </div>
                {totalMentoredWorkflows > 5 && (
                  <div>
                    <Pill>{totalMentoredWorkflows} total</Pill>
                  </div>
                )}
              </div>
            </Well.Header>
            <Well.Body>
              {isEmpty(mentoringActivities) ? (
                <EmptyState
                  title="No mentored workflows"
                  height={emptyStateMinHeight}
                />
              ) : (
                (mentoringActivities as ActivityMentor[]).map(
                  mentoringActivity => (
                    <MentoringStatusCard
                      key={mentoringActivity.id}
                      mentorActivity={mentoringActivity}
                    />
                  ),
                )
              )}
            </Well.Body>
          </Well>
          <div className="media-hide-5">
            {!isEmpty(upcomingActivities) && (
              <UpcomingActivityWell
                upcomingActivities={upcomingActivities}
                userId={user.id}
              />
            )}
          </div>
        </div>
        <div className="right-tiles__container media-show-5">
          {/*<InvitationsWell invitations={invitations} />*/}
          {!isEmpty(upcomingActivities) && (
            <UpcomingActivityWell
              upcomingActivities={upcomingActivities}
              userId={user.id}
            />
          )}
        </div>
      </div>
    </div>
  )
}

function UpcomingActivityWell({
  upcomingActivities,
  userId,
}: {
  upcomingActivities: Activity[]
  userId: string
}) {
  return (
    <Well>
      <Well.Header>
        <div className="well-title">
          <Icon
            className="mr-4"
            name="calendar-alt"
          />
          <span>Upcoming Workflows</span>
        </div>
      </Well.Header>
      <Well.Body>
        {isEmpty(upcomingActivities) ? (
          <EmptyState
            height={emptyStateMinHeight}
            title="No mentored workflows"
          />
        ) : (
          upcomingActivities.map(upcomingActivity => {
            return (
              <UpcomingActivityCard
                key={upcomingActivity.id}
                href={`/${upcomingActivity.orgId}/home/${userId}/${upcomingActivity.id}`}
                upcomingActivity={upcomingActivity}
              />
            )
          })
        )}
      </Well.Body>
    </Well>
  )
}

function InvitationsWell({
  invitations,
  collapsable,
}: {
  invitations: any[]
  collapsable?: any
}) {
  const newInvitationsCount = invitations.filter(
    invitation => !invitation.viewed,
  ).length
  return (
    <Well
      className="mb-4"
      collapsable={collapsable}
      defaultCollapseState={true}
    >
      <Well.Header>
        <div className="well-header-split">
          <div className="well-title">
            <Icon
              className="mr-4"
              name="envelope"
            />
            <span>Invitations</span>
          </div>
          <Pill pillType={newInvitationsCount > 0 ? 'danger' : ''}>
            {newInvitationsCount} new
          </Pill>
        </div>
      </Well.Header>
      <Well.Body>
        {isEmpty(invitations) ? (
          <EmptyState
            title="No invitations"
            height={emptyStateMinHeight}
          />
        ) : (
          invitations.map(invitation => (
            <InvitationCard
              key={invitation.id}
              invitation={invitation}
            />
          ))
        )}
      </Well.Body>
    </Well>
  )
}

const css = k`
  .dashboard-wrapper {
    padding-top: 32px;
    margin-bottom: 24px;
  }

  .title {
    margin-bottom: 2rem;
    font-size: 2.4rem;
    font-weight: 500;
  }

  .filters__list {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    padding: 4px;
    margin: -4px;
    margin-right: 16px;

    // -ms-overflow-style: none;  /* Internet Explorer 10+ */
    // scrollbar-width: none;  /* Firefox */
  }

  // .filters__list::-webkit-scrollbar {
  //   display: none;  /* Safari and Chrome */
  // }

  .tiles__container {
    display: flex;
    width: 100%;

    .left-tiles__container {
      flex: 4;
      min-width: 0;
    }

    .right-tiles__container {
      flex: 2;
      margin-left: 16px;
      max-width: 320px;
    }
  }

  .well-title {
    color: $color-primary;
    display: flex;
    align-items: center;
  }

  .well-header-split {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`
