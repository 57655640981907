import { UserCredential, createUserWithEmailAndPassword } from 'firebase/auth'
import { k, useCss } from 'kremling'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'

import { Card } from '@components/card.component'
import { InputField } from '@components/input'
import { Logo } from '@components/logo.component'
import { Button } from '@components/mantine/button.component'
import { TextDivider } from '@components/text-divider.component'
import { useLoad } from '@hooks/use-load.hook'
import { auth, db, functions } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { toastService } from '@utils/toast.service'
import { doc, getDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { OauthList } from '../auth/oauth/oauth-list.component'
import { LegalReference } from '../legal-reference.component'

export function FunnelRegister() {
  const scope = useCss(css)
  const [warning, setWarning] = useState('')
  const [registering, setRegistering] = useState(false)
  const { purchaseId } = useParams()
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid },
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      verifyPassword: '',
    },
  })

  const [purchase, _setPurchase, purchaseOpts] = useLoad(
    {},
    async () => {
      const d = doc(db, dbNames.purchases, purchaseId)

      return getDoc(d)
        .then(snap => {
          if (!snap.exists()) {
            return {}
          }
          return { ...snap.data(), id: snap.id }
        })
        .then((purchase: any) => {
          setValue('email', purchase.email)
          return purchase
        })
    },
    [purchaseId],
  )

  async function handleCreateAccount({
    email,
    password,
  }: {
    email: string
    password: string
  }) {
    setRegistering(true)
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      )

      await httpsCallable(
        functions,
        'userFunctions-createUser',
      )({
        uid: user.uid,
        email: user.email,
      })

      await httpsCallable(
        functions,
        'activityFunctions-funnelRegister',
      )({
        purchase,
        user: {
          uid: user.uid,
          displayName: user.displayName,
        },
      })
      navigate(`/${purchase.orgId}`)
    } catch (err) {
      console.log(err)
      setRegistering(false)
      if (err.code === 'auth/email-already-in-use') {
        setWarning('This email is already in use')
      } else {
        toastService.error(
          'An error occurred when registering your email and password. Please try again',
        )
      }
    }
  }

  const handleGoogleAuth = async ({ user }: UserCredential) => {
    setRegistering(false)
    await httpsCallable(
      functions,
      'userFunctions-createUser',
    )({
      uid: user.uid,
      email: user.email,
    })

    await httpsCallable(
      functions,
      'activityFunctions-funnelRegister',
    )({
      purchase,
      user: {
        uid: user.uid,
        displayName: user.displayName,
      },
    })
    navigate(`/${purchase.orgId}`)
  }

  if (!purchaseOpts.loading && !purchase.id) {
    return <Navigate to="/sign-in" />
  }

  return (
    <div
      className="register-wrapper"
      {...scope}
    >
      <Card
        className="register-card"
        loader={registering}
      >
        <Logo className="mb-6" />
        <h3 className="text-2xl font-bold">Create Account</h3>
        <form onSubmit={handleSubmit(handleCreateAccount)}>
          <div className="form-group">
            <InputField
              label="Email"
              placeholder="Enter your email address"
              control={control}
              fieldName="email"
              type="email"
              required
            />
          </div>
          <div className="form-group">
            <InputField
              label="Password (at least 6 characters)"
              control={control}
              placeholder="Choose a password"
              fieldName="password"
              type="password"
              minLength={6}
              required
            />
          </div>
          <div className="form-group">
            <InputField
              label="Verify password"
              control={control}
              placeholder="Re-enter your password"
              fieldName="verifyPassword"
              type="password"
              required
              rules={{
                validate: v =>
                  v === watch('password') || 'Value must match password',
              }}
            />
          </div>
          <Button
            type="submit"
            variant="primary"
            disabled={!isValid}
            fullWidth
          >
            Create account
          </Button>
          {warning && <div className="has-warning mt-4">{warning}</div>}
        </form>

        <TextDivider className="mb-8 mt-8">OR</TextDivider>
        <OauthList
          registerType="purchase"
          handleClick={() => setRegistering(true)}
          onComplete={handleGoogleAuth}
        />
        <div className="other-options">
          <div>Already have an account?</div>
          <Link to="/sign-in">Sign in</Link>
        </div>
      </Card>
      <LegalReference />
    </div>
  )
}

const css = k`
  .register-wrapper {
    background: $color-grey-25;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .register-card {
    width: 40rem;
    padding: 3rem;
  }

  .verify-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .register-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .other-options {
    text-align: center;
    margin-top: 1.6rem;
  }
`
