import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { useCss, k, a } from 'kremling'
import { AnimatePresence, motion } from 'framer-motion'

import { Icon } from './icon.component'
import { Loader } from '@components/loader.component'

const WellContext = createContext<{
  collapsable?: boolean
  collapsed?: boolean
  setCollapsed?: Dispatch<SetStateAction<boolean>>
}>({})

type WellProps = {
  children?: ReactNode
  className?: string
  loader?: boolean
  collapsable?: boolean
  defaultCollapseState?: boolean
}

export function Well(props: WellProps) {
  const {
    children,
    className,
    loader,
    collapsable,
    defaultCollapseState = false,
  } = props
  const scoped = useCss(css)
  const [collapsed, setCollapsed] = useState(defaultCollapseState)

  return (
    <WellContext.Provider value={{ collapsable, collapsed, setCollapsed }}>
      <div
        {...scoped}
        className={`${className || ''} well__container`}
      >
        {loader ? <Loader size="md" /> : children}
      </div>
    </WellContext.Provider>
  )
}

type WellHeaderProps = {
  children?: ReactNode
  icon?: string
  renderActions?: () => ReactNode
}

Well.Header = ({ children, icon, renderActions }: WellHeaderProps) => {
  const { collapsable, collapsed, setCollapsed } = useContext(WellContext)
  const expandIcon = collapsed ? 'caret-down' : 'caret-up'
  return collapsable ? (
    <button
      className="collapse__header"
      onClick={() => setCollapsed(state => !state)}
    >
      <Well.Title icon={icon}>{children}</Well.Title>
      <Icon
        className="ml-2"
        name={expandIcon}
      />
    </button>
  ) : (
    <div className="well__header">
      <Well.Title icon={icon}>{children}</Well.Title>
      {renderActions && <div className="well__actions">{renderActions()}</div>}
    </div>
  )
}

type WellTitleProps = {
  children?: ReactNode
  icon?: string
  className?: string
}

Well.Title = ({ children, icon, className }: WellTitleProps) => (
  <div className={a('well__title').a(className)}>
    {icon && (
      <div className="well__icon">
        <Icon name={icon} />
      </div>
    )}
    {children}
  </div>
)

type WellBodyProps = {
  children?: ReactNode
  loader?: boolean
}

Well.Body = ({ children, loader }: WellBodyProps) => {
  const { collapsable, collapsed } = useContext(WellContext)
  return collapsable ? (
    <AnimatePresence>
      {!collapsed && (
        <motion.div
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.2, ease: 'easeOut' }}
        >
          <div className="well__body">
            {loader ? <Loader size="md" /> : children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  ) : (
    <div className="well__body">{loader ? <Loader size="md" /> : children}</div>
  )
}

Well.Footer = ({ children }: { children?: ReactNode }) => (
  <div className="well__footer">{children}</div>
)

const css = k`
  .well__container {
    background: $color-grey-25;
    border-radius: $base-border-radius;
    box-shadow: $box-shadow-1;
  }

  .collapse__header {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 1.6rem;
    position: relative;
    width: 100%;
  }

  .well__header {
    display: flex;
    align-items: center;
    padding: 1.6rem;
    position: relative;

  }

  .well__title {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 500;
  }

  .well__icon {
    margin-right: 1.6rem;
    color: $color-grey-400;
  }

  .well__body {
    padding: 0 1.6rem;
    padding-bottom: 1.6rem;
  }

  .well__footer {
    padding: 16px;
    padding-top: 0;
    display: flex;
    justify-content: center;
  }
`
