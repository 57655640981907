import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import ls from 'local-storage'
import React from 'react'
import { useParams } from 'react-router-dom'

import { LibraryTemplateCard } from '@components/cards/library-template-card.component'
import { EmptyState } from '@components/empty-state.component'
import { Loader } from '@components/loader.component'
import { useLoad } from '@hooks/use-load.hook'
import { db } from '@src/firebase-app'
import { AdoptedTemplatesList } from '@src/pages/library/workspace/lists/adopted-templates-list.component'
import { dbNames } from '@utils/constants'
import { toastService } from '@utils/toast.service'
import { Link } from 'react-router-dom'

const enableMarketplace =
  process.env.MARKETPLACE == 'true' ||
  ls.get('iact-marketplace-feature') == true

export function PublishedTemplatesList({ searchVal }) {
  const { orgId } = useParams()

  const [templates, _setTemplates, templatesOpts] = useLoad(
    [],
    () => {
      const q = query(
        collection(db, dbNames.publishedTemplates),
        orderBy('name'),
        where('orgId', '==', orgId),
        where('publishedLocations', 'array-contains', 'workspace'),
      )
      return getDocs(q).then(q => q.docs.map(d => ({ ...d.data(), id: d.id })))
    },
    [],
  )

  async function unpublishTemplate(template) {
    try {
      const publishedDoc = doc(db, dbNames.publishedTemplates, template.id)
      await updateDoc(publishedDoc, {
        publishedLocations: [],
      })

      const templateDoc = doc(
        db,
        dbNames.workflowTemplates,
        template.publishedFromTemplateId,
      )
      await updateDoc(templateDoc, {
        lastPublishedDate: null,
      })

      templatesOpts.reload()
    } catch (e) {
      toastService.error('An error occurred when unpublishing')
    }
  }

  function renderTemplates() {
    const filteredTemplates = templates.filter(template => {
      return searchVal
        ? template.name.toLowerCase().includes(searchVal.toLowerCase())
        : true
    })

    if (filteredTemplates.length) {
      return filteredTemplates.map(template => (
        <LibraryTemplateCard
          unpublishTemplate={unpublishTemplate}
          template={template}
          key={template.id}
        />
      ))
    } else {
      if (searchVal) {
        return (
          <EmptyState
            title="No search results"
            subtitle={`"${searchVal}" yielded no results.`}
          />
        )
      } else {
        return (
          <EmptyState
            title="No published templates"
            subtitle={
              <div>
                To publish, select or create a template
                <Link
                  className="btn --secondary ml-2 mr-2"
                  to={`/${orgId}/library/templates`}
                >
                  Here
                </Link>
                and click "Publish template"
              </div>
            }
          />
        )
      }
    }
  }

  if (templatesOpts.loading)
    return (
      <div className="pt-8">
        <Loader />
      </div>
    )

  return (
    <div>
      {enableMarketplace && (
        <AdoptedTemplatesList
          className="mt-5"
          searchVal={searchVal}
        />
      )}
      <div>{renderTemplates()}</div>
    </div>
  )
}
