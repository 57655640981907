import { Icon } from '@components/icon.component'
import { WorkflowCheckbox } from '@src/pages/workflow/client/fields/workflow-checkbox.component'
import { WorkflowDescription } from '@src/pages/workflow/client/fields/workflow-description.component'
import { WorkflowTextInput } from '@src/pages/workflow/client/fields/workflow-text-input.component'
import {
  FIELD_CONSTANTS,
  nonCompletableFields,
} from '@src/pages/workflow/fields.helper'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo } from 'react'
import { Control } from 'react-hook-form'
import { WorkflowDate } from '../fields/workflow-date.component'
import { WorkflowFileUpload } from '../fields/workflow-file-upload.component'
import { WorkflowLink } from '../fields/workflow-link.component'
import { WorkflowRadio } from '../fields/workflow-radio.component'
import { WorkflowSelect } from '../fields/workflow-select.component'
import { WorkflowYoutube } from '../fields/workflow-youtube.component'

type Props = {
  field: Field
  section: ActivitySection
  control: Control
  iteration: SectionIteration
  readOnly?: boolean
  allFieldsRequired?: boolean
  hasValue: boolean
  suspendUpload?: boolean
  uploadRef?: React.RefObject<HTMLInputElement>
}

export function Field({
  field,
  section,
  control,
  iteration,
  readOnly,
  hasValue,
  suspendUpload,
  uploadRef,
}: Props) {
  const notEditable = nonCompletableFields.includes(field.type)
  const isRequired = useMemo(() => {
    if (section.constraints?.requiredMode === 'all') return true
    return !!section.constraints?.required?.includes(field.id)
  }, [section.constraints, field.id])
  const {
    TEXT,
    DESCRIPTION,
    CHECKBOX,
    RADIO,
    YOUTUBE,
    DATE,
    LINK,
    FILE_UPLOAD,
    SELECT,
  } = FIELD_CONSTANTS

  function renderField() {
    switch (field.type) {
      case TEXT:
        return (
          <WorkflowTextInput
            field={field}
            readOnly={readOnly}
            isRequired={isRequired}
            section={section}
            control={control}
          />
        )
      case DESCRIPTION:
        return (
          <WorkflowDescription
            field={field}
            section={section}
          />
        )
      case CHECKBOX:
        return (
          <WorkflowCheckbox
            field={field}
            readOnly={readOnly}
            isRequired={isRequired}
            section={section}
            control={control}
          />
        )
      case RADIO:
        return (
          <WorkflowRadio
            field={field}
            readOnly={readOnly}
            isRequired={isRequired}
            section={section}
            control={control}
          />
        )
      case YOUTUBE:
        return (
          <WorkflowYoutube
            field={field}
            isRequired={isRequired}
            section={section}
            control={control}
            iterationId={iteration?.id}
            readOnly={readOnly}
          />
        )
      case DATE:
        return (
          <WorkflowDate
            field={field}
            readOnly={readOnly}
            isRequired={isRequired}
            section={section}
            control={control}
          />
        )
      case LINK:
        if (!field.link) return
        return (
          <WorkflowLink
            field={field}
            isRequired={isRequired}
            control={control}
          />
        )
      case FILE_UPLOAD:
        return (
          <WorkflowFileUpload
            field={field}
            iterationId={iteration?.id}
            readOnly={readOnly}
            section={section}
            isRequired={isRequired}
            suspendUpload={suspendUpload}
            control={control}
            uploadRef={uploadRef}
          />
        )
      case SELECT:
        return (
          <WorkflowSelect
            field={field}
            readOnly={readOnly}
            isRequired={isRequired}
            section={section}
            control={control}
          />
        )
    }
  }

  return (
    <div className="relative pt-10 px-4 pb-4 overflow-hidden">
      {isRequired && !notEditable && (
        <AnimatePresence>
          {!hasValue ? (
            <motion.div
              key="asterisk"
              initial={{ y: '-100%' }}
              animate={{ y: 0 }}
              exit={{ y: '-100%' }}
              transition={{
                duration: 0.2,
                ease: 'backInOut',
              }}
              className="absolute -top-0 right-10"
            >
              <div className="h-0 w-0 border-b-[.5rem] border-b-transparent border-r-[1rem] border-t-[2rem] border-l-[1rem] border-danger" />
              <Icon
                className="absolute left-1/2 -translate-x-1/2 top-2 text-white"
                name="asterisk"
                size={12}
              />
            </motion.div>
          ) : (
            <motion.div
              key="checkmark"
              initial={{ y: '-100%' }}
              animate={{ y: 0 }}
              exit={{ y: '-100%' }}
              transition={{
                duration: 0.2,
                ease: 'backInOut',
              }}
              className="absolute -top-0 right-10"
            >
              <div className="h-0 w-0 border-b-[.5rem] border-b-transparent border-r-[1rem] border-t-[2rem] border-l-[1rem] border-primary" />
              <Icon
                className="absolute left-1/2 -translate-x-1/2 top-2 text-white"
                name="check"
                size={12}
              />
            </motion.div>
          )}
        </AnimatePresence>
      )}
      {renderField()}
    </div>
  )
}
