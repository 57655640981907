import { TEXT_TYPE_CONSTANTS } from '@src/pages/workflow/fields.helper'
import { WorkflowLeaderboardItemDistance } from '@src/pages/workflow/settings/challenge-items/workflow-leaderboard-item-distance.component'
import { WorkflowLeaderboardItemDuration } from '@src/pages/workflow/settings/challenge-items/workflow-leaderboard-item-duration.component'
import { WorkflowLeaderboardItemGeneric } from '@src/pages/workflow/settings/challenge-items/workflow-leaderboard-item-generic.component'
import { WorkflowLeaderboardItemIterationCount } from '@src/pages/workflow/settings/challenge-items/workflow-leaderboard-item-iteration-count.component'
import { WorkflowLeaderboardItemPercentage } from '@src/pages/workflow/settings/challenge-items/workflow-leaderboard-item-percentage.component'
import React from 'react'

type Props = {
  activityChallenge: WorkflowChallenge
  participantsCount: number
  openChallengeEditor: (challenge: WorkflowChallenge) => void
  deleteChallenge: (challengeId: string) => void
  isAdmin: boolean
}

export function ActivityChallenge({
  activityChallenge,
  participantsCount,
  deleteChallenge,
  openChallengeEditor,
  isAdmin,
}: Props) {
  const commonProps = {
    workflowChallenge: activityChallenge,
    onDelete: deleteChallenge,
    onEdit: openChallengeEditor,
    isAdmin: isAdmin,
    participantsCount,
  }

  if (activityChallenge.field) {
    if (activityChallenge.field.textType === TEXT_TYPE_CONSTANTS.DURATION) {
      return (
        <WorkflowLeaderboardItemDuration
          key={activityChallenge.id}
          {...commonProps}
        />
      )
    } else if (
      activityChallenge.field.textType === TEXT_TYPE_CONSTANTS.DISTANCE
    ) {
      return (
        <WorkflowLeaderboardItemDistance
          key={activityChallenge.id}
          {...commonProps}
        />
      )
    } else if (
      activityChallenge.field.textType === TEXT_TYPE_CONSTANTS.PERCENTAGE
    ) {
      return (
        <WorkflowLeaderboardItemPercentage
          key={activityChallenge.id}
          {...commonProps}
        />
      )
    } else {
      return (
        <WorkflowLeaderboardItemGeneric
          key={activityChallenge.id}
          {...commonProps}
        />
      )
    }
  } else {
    return (
      <WorkflowLeaderboardItemIterationCount
        key={activityChallenge.id}
        {...commonProps}
      />
    )
  }
}
