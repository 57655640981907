import { Card } from '@components/card.component'
import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { m } from 'kremling'
import React, { useEffect, useState } from 'react'
import {
  FIELD_CONSTANTS,
  TEXT_TYPE_CONSTANTS,
  fieldsList,
} from '../fields.helper'
import { FieldCheckbox } from './fields/field-checkbox.component'
import { FieldDate } from './fields/field-date.component'
import { FieldDescription } from './fields/field-description.component'
import { FieldLink } from './fields/field-link.component'
import { FieldRadio } from './fields/field-radio.component'
import { FieldSelect } from './fields/field-select.component'
import { FieldText } from './fields/field-text.component'
import { FieldYoutube } from './fields/field-youtube.component'
import { FileUpload } from './fields/file-upload.component'

interface FieldProps {
  field: any // Replace 'any' with the appropriate type for your field
  isNew: boolean
  removeField: (id: string) => void
  updateField: (field: any) => void // Replace 'any' with the appropriate type
  updateRequiredToggle: () => void
  isRequired: boolean
}

export const Field: React.FC<FieldProps> = ({
  field,
  isNew,
  removeField,
  updateField,
  updateRequiredToggle,
  isRequired,
}) => {
  const [expanded, setExpanded] = useState(isNew)
  const fieldType = fieldsList.find(f => f.name === field.type)
  const icon = fieldType ? fieldType.icon : ''

  const toggleExpanded = () => {
    setExpanded(prevState => !prevState)
  }

  const renderExpandedContent = () => {
    const {
      TEXT,
      DESCRIPTION,
      CHECKBOX,
      RADIO,
      YOUTUBE,
      DATE,
      LINK,
      FILE_UPLOAD,
      SELECT,
    } = FIELD_CONSTANTS

    switch (field.type) {
      case TEXT:
        return (
          <FieldText
            field={field}
            onChange={updateField}
            updateRequiredToggle={updateRequiredToggle}
            isRequired={isRequired}
          />
        )
      case DESCRIPTION:
        return (
          <FieldDescription
            field={field}
            onChange={updateField}
          />
        )
      case CHECKBOX:
        return (
          <FieldCheckbox
            field={field}
            onChange={updateField}
            updateRequiredToggle={updateRequiredToggle}
            isRequired={isRequired}
          />
        )
      case RADIO:
        return (
          <FieldRadio
            field={field}
            onChange={updateField}
            updateRequiredToggle={updateRequiredToggle}
            isRequired={isRequired}
          />
        )
      case YOUTUBE:
        return (
          <FieldYoutube
            field={field}
            onChange={updateField}
            updateRequiredToggle={updateRequiredToggle}
            isRequired={isRequired}
          />
        )
      case DATE:
        return (
          <FieldDate
            field={field}
            onChange={updateField}
            updateRequiredToggle={updateRequiredToggle}
            isRequired={isRequired}
          />
        )
      case LINK:
        return (
          <FieldLink
            field={field}
            onChange={updateField}
            updateRequiredToggle={updateRequiredToggle}
            isRequired={isRequired}
          />
        )
      case FILE_UPLOAD:
        return (
          <FileUpload
            field={field}
            onChange={updateField}
            updateRequiredToggle={updateRequiredToggle}
            isRequired={isRequired}
          />
        )
      case SELECT:
        return (
          <FieldSelect
            field={field}
            onChange={updateField}
            updateRequiredToggle={updateRequiredToggle}
            isRequired={isRequired}
          />
        )
    }
  }

  const handleRemoveField = (e: React.MouseEvent) => {
    e.stopPropagation()
    removeField(field.id)
  }

  useEffect(() => {
    setExpanded(isNew)
  }, [isNew])

  const getTextTypeIcon = () => {
    switch (field.textType) {
      case TEXT_TYPE_CONSTANTS.SHORT:
        return 'input-text'
      case TEXT_TYPE_CONSTANTS.LONG:
        return 'align-center'
      case TEXT_TYPE_CONSTANTS.DURATION:
        return 'stopwatch'
      case TEXT_TYPE_CONSTANTS.NUMBER:
        return 'hashtag'
      case TEXT_TYPE_CONSTANTS.DISTANCE:
        return 'ruler'
      case TEXT_TYPE_CONSTANTS.PERCENTAGE:
        return 'percent'
      default:
        return ''
    }
  }

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: field.id,
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  })

  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition: transition || '',
    position: isDragging ? ('relative' as const) : ('static' as const),
    zIndex: isDragging ? 1 : undefined,
  }

  return (
    <div
      className="mb-4 cursor-default"
      ref={setNodeRef}
      style={style}
      role="button"
      tabIndex={0}
    >
      <Card level={isDragging ? '2' : '1'}>
        <div className={m('expanded', expanded)}>
          <div
            {...attributes}
            {...listeners}
            className="flex items-center cursor-pointer p-4 justify-between"
            onClick={() => toggleExpanded()}
          >
            <div className="flex items-center gap-3">
              <Icon name="grip-dots-vertical" />
              <Icon
                name={icon}
                size={16}
              />
              {field.type === FIELD_CONSTANTS.TEXT && (
                <div className="rounded-full w-[2.6rem] h-[2.6rem] flex items-center justify-center bg-grey-100">
                  <Icon
                    name={getTextTypeIcon()}
                    size={14}
                  />
                </div>
              )}
              {isRequired && (
                <div className="p-2 rounded-full bg-danger text-white">
                  <Icon
                    name="asterisk"
                    size={12}
                  />
                </div>
              )}
              <div className="select-none ellipsis mr-2">{field.label}</div>
            </div>
            <div className="flex items-center gap-2">
              <ActionIcon
                onClick={handleRemoveField}
                size="sm"
                icon="trash"
              />
              <Icon name={expanded ? 'caret-up' : 'caret-down'} />
            </div>
          </div>
          {expanded && (
            <div className="px-4 pb-4">{renderExpandedContent()}</div>
          )}
        </div>
      </Card>
    </div>
  )
}
