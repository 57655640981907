import { Card } from '@components/card.component'
import { Icon } from '@components/icon.component'
import { Button } from '@components/mantine/button.component'
import { useLoad } from '@hooks/use-load.hook'
import {
  useFirestoreDocument,
  useFirestoreQuery,
  useFirestoreQueryData,
} from '@react-query-firebase/firestore'
import { useAppState } from '@src/app.state'
import { db, functions } from '@src/firebase-app'
import { useMessagingState } from '@src/pages/messaging/messaging-store'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { toastService } from '@utils/toast.service'
import {
  Timestamp,
  addDoc,
  arrayUnion,
  collection,
  doc,
  documentId,
  getDocs,
  query,
  runTransaction,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { AnimatePresence, motion } from 'framer-motion'
import { k, useCss } from 'kremling'
import { orderBy } from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { ChatMessages } from '../chat-messages.component'
import { ChatRooms } from '../chat-rooms.component'

export function WorkflowChatContainer({
  activity: propActivity,
  toggleSections,
}) {
  const { activity: contextActivity } = useOutletContext()
  const scoped = useCss(css)
  const user = useAppState(state => state.user)
  const notifications = useAppState(state => state.notifications)
  const { orgId } = useParams()
  const selectedRoom = useMessagingState(state => state.selectedRoom)
  const [showRooms, setShowRooms] = useState(false)
  const isAdmin = ['owner', 'manager', 'mentor'].includes(user?.orgRoles[orgId])
  const activity = propActivity || contextActivity
  const addingNewRoom = useMessagingState(state => state.addingNewRoom)

  const { data: allParticipantsRoom, isLoading: allParticipantsLoading } =
    useFirestoreDocument(
      ['allParticipants', activity.id],
      doc(db, dbNames.messageRooms, activity.id),
      {
        subscribe: true,
      },
      {
        select: doc => {
          return { ...doc.data(), id: doc.id }
        },
      },
    )

  const { data: userRooms = [] } = useFirestoreQuery(
    ['myRooms', activity.id],
    query(
      collection(db, dbNames.messageRooms),
      where('activityId', '==', activity.id),
      where('userIds', 'array-contains', user.id),
      where(documentId(), '!=', activity.id),
    ),
    {
      subscribe: true,
    },
    {
      enabled: !isAdmin,
      select: snap => {
        return snap.docs.map(doc => ({ ...doc.data(), id: doc.id }))
      },
    },
  )

  const { data: adminRooms = [] } = useFirestoreQuery(
    ['adminRooms', activity.id, user.id],
    query(
      collection(db, dbNames.messageRooms),
      where('activityId', '==', activity.id),
      where('userIds', 'array-contains', 'admin'),
    ),
    {
      subscribe: true,
    },
    {
      enabled: isAdmin,
      select: snap => {
        if (!isAdmin) return [] //Not sure why, but in some cases the 'enabled: isAdmin' option doesn't work and this fires for non-admins.
        return snap.docs.map(doc => ({ ...doc.data(), id: doc.id }))
      },
    },
  )

  const sortedRooms = useMemo(() => {
    return orderBy(
      [...adminRooms, ...userRooms],
      room => {
        if (!room?.latestMessage) return 0
        return DateTime.fromISO(room.latestMessage.createdDate).toMillis()
      },
      ['desc'],
    )
  }, [adminRooms, userRooms])

  const [mentorParticipants] = useLoad(
    [],
    () => {
      if (!activity.id) return Promise.resolve([])
      const q = query(
        collection(db, dbNames.activityMentors),
        where('activityId', '==', activity.id),
        where('userId', '==', user.id),
      )
      return getDocs(q).then(q => q.docs.map(g => ({ ...g.data(), id: g.id })))
    },
    [activity.id, isAdmin, user.id],
  )

  const [isParticipant] = useLoad(
    false,
    async () => {
      if (!activity.id) return Promise.resolve([])
      const q = query(
        collection(db, dbNames.activityUsers),
        where('activityId', '==', activity.id),
        where('userId', '==', user.id),
      )
      const activityUser = await getDocs(q)
      return !activityUser.empty
    },
    [activity.id, isAdmin, user.id],
  )

  const isInvitedMentor =
    mentorParticipants.length > 0 && !isAdmin && !isParticipant

  useEffect(() => {
    const addParticipant = async () => {
      if (!activity.id || !selectedRoom || isParticipant) return
      if (isAdmin && !selectedRoom.userIds.includes('admin')) {
        await joinRoom()
      }
    }
    addParticipant()
  }, [activity.id, selectedRoom, isParticipant])

  const ref = activity.id
    ? query(
        collection(db, 'messageRoomUsers'),
        where('activityId', '==', activity.id),
      )
    : null

  const { data: participants } = useFirestoreQueryData(
    ['participants', activity.id],
    ref,
    {
      subscribe: true,
    },
    {
      enabled: !!activity.id,
    },
  )

  async function onSend(text, recipients) {
    const today = getTodayDate()
    const createdDate = today.toISO()
    try {
      await addDoc(
        collection(
          db,
          dbNames.messageRooms,
          useMessagingState.getState().selectedRoom.id,
          dbNames.messages,
        ),
        {
          text,
          createdDate,
          createdDateTs: today.toMillis(),
          roomId: useMessagingState.getState().selectedRoom.id,
          userId: user.id,
        },
      )

      await updateDoc(
        doc(
          db,
          dbNames.messageRooms,
          useMessagingState.getState().selectedRoom.id,
        ),
        {
          latestMessage: {
            text,
            createdDate,
          },
        },
      )

      const sendNotifications = httpsCallable(
        functions,
        'allFunctions-sendNotifications',
      )
      const notificationPayload = {
        recipients: recipients.filter(recipient => !recipient.deactivated),
        type: 'newWorkflowMessage',
        room: useMessagingState.getState().selectedRoom,
        today: createdDate,
        sender: user,
        messageText: text,
        activityId: activity.id,
        activityName: activity.name,
        activityOrgId: activity.orgId,
        timestamp: Timestamp.now(),
        viewed: false,
        dirty: false,
        orgId,
      }

      await sendNotifications(notificationPayload)
    } catch (err) {
      console.log(err)
      toastService.error(
        'There was an error sending your message. Please try again',
      )
    }
  }

  function onStartRoom() {
    useMessagingState.setState({ addingNewRoom: true, selectedRoom: null })
  }

  async function onCreateRoom(newUsers, messageText) {
    const createdDate = getTodayDate().toISO()
    const newUserIds = newUsers.map(({ userId }) => userId)
    const newRoom = {
      userIds: newUserIds,
      createdDate,
      activityId: activity.id,
      workflowName: activity.name,
      name: '',
      latestMessage: {
        text: messageText,
        createdDate,
      },
    }

    onCancelStartRoom()

    //create message room
    const messageRoomSnap = await addDoc(
      collection(db, dbNames.messageRooms),
      newRoom,
    )

    //create message room users
    for await (const user of newUsers) {
      await setDoc(
        doc(
          db,
          dbNames.messageRoomUsers,
          `${messageRoomSnap.id}_${user.userId}`,
        ),
        {
          roomId: messageRoomSnap.id,
          userId: user.userId,
          userFirstName: user.userFirstName,
          userLastName: user.userLastName,
          userEmail: user.userEmail,
          activityId: activity.id,
          createdDate: getTodayDate().toISO(),
          deactivated: false,
        },
      )
    }

    const room = { ...newRoom, id: messageRoomSnap.id }
    useMessagingState.setState({ selectedRoom: room })
    await onSend(
      messageText,
      newUsers.map(({ userId, userFirstName, userLastName }) => ({
        id: userId,
        firstName: userFirstName,
        lastName: userLastName,
      })),
    )
  }

  async function onCreateAdminRoom(newUser, text = '') {
    const today = getTodayDate()

    try {
      await runTransaction(db, async transaction => {
        // Create the room
        const roomRef = doc(collection(db, dbNames.messageRooms))
        const roomData = {
          userIds: [newUser.userId, 'admin'],
          createdDate: today.toISO(),
          activityId: activity.id,
          workflowName: activity.name,
          name: '',
        }
        transaction.set(roomRef, roomData)

        useMessagingState.setState({
          selectedRoom: { id: roomRef.id, ...roomData },
        })

        // Set user details in messageRoomUsers
        const userRoomRef = doc(
          db,
          dbNames.messageRoomUsers,
          `${roomRef.id}_${newUser.userId}`,
        )
        const userData = {
          roomId: roomRef.id,
          activityId: activity.id,
          userId: newUser.userId,
          userFirstName: newUser.userFirstName,
          userLastName: newUser.userLastName,
          userEmail: newUser.userEmail,
        }
        transaction.set(userRoomRef, userData)

        // Set admin details in messageRoomUsers
        const adminRoomRef = doc(
          db,
          dbNames.messageRoomUsers,
          `${roomRef.id}_admin`,
        )
        const adminData = {
          roomId: roomRef.id,
          activityId: activity.id,
          userId: 'admin',
          userFirstName: 'Admin',
          userLastName: '',
          userEmail: '',
          userDisplayName: 'Admin',
        }
        transaction.set(adminRoomRef, adminData)
      })

      if (text) {
        await onSend(text, [newUser, { userId: 'admin' }])
      }
    } catch (error) {
      console.error('Error creating admin room:', error)
      toastService.error('There was an error creating the room')
    }
  }

  function onCancelStartRoom(newRoomIndex) {
    useMessagingState.setState({ addingNewRoom: false })
    if (Number(newRoomIndex)) {
      useMessagingState.setState({ selectedRoom: sortedRooms.at(newRoomIndex) })
    }
  }

  async function joinRoom() {
    try {
      await setDoc(
        doc(db, dbNames.messageRoomUsers, `${selectedRoom.id}_${user.id}`),
        {
          roomId: selectedRoom.id,
          userId: user.id,
          userFirstName: user.firstName,
          userLastName: user.lastName,
          userEmail: user.email,
          createdDate: getTodayDate().toISO(),
          deactivated: false,
        },
      )

      await updateDoc(doc(db, dbNames.messageRooms, selectedRoom.id), {
        userIds: arrayUnion(user.id),
      })
    } catch (err) {
      console.log(err)
      toastService.error('There was an error joining the room')
    }
  }

  function toggleRooms() {
    setShowRooms(state => !state)
  }

  function selectRoom(room) {
    setShowRooms(false)
    useMessagingState.setState({ selectedRoom: room })
  }

  const availableParticipants = isInvitedMentor
    ? participants.filter(({ userId }) =>
        mentorParticipants.some(({ inviterId }) => inviterId === userId),
      )
    : participants

  const chatRooms = (
    <ChatRooms
      selectedRoom={addingNewRoom ? null : selectedRoom || sortedRooms[0]}
      rooms={[allParticipantsRoom, ...sortedRooms]}
      hideHeader={!isParticipant && !isAdmin}
      onSelect={selectRoom}
      onStartRoom={onStartRoom}
      participants={availableParticipants}
      toggleRooms={toggleRooms}
      addingNewRoom={addingNewRoom}
      onCreateAdminRoom={onCreateAdminRoom}
    />
  )

  if (allParticipantsLoading) return null

  return (
    <div
      style={{ height: '100%' }}
      className="section-container pt-8"
    >
      {toggleSections && (
        <div className="section-header media-hide-2">
          <Button
            variant="secondary"
            onClick={toggleSections}
            className="media-hide-3"
          >
            <Icon
              name="list-radio"
              size={14}
            />
            <span>Sections</span>
          </Button>
        </div>
      )}
      <div
        {...scoped}
        className="chat__wrapper"
      >
        <Card className="chat__container">
          <AnimatePresence initial={false}>
            {showRooms && (
              <motion.div
                className="mobile-chat__left"
                initial={{ x: -260 }}
                animate={{ x: 0 }}
                exit={{ x: -260 }}
                transition={{ duration: 0.2, ease: 'easeOut' }}
              >
                {chatRooms}
              </motion.div>
            )}
          </AnimatePresence>
          <div className="chat__left media-show-3">{chatRooms}</div>
          <div className="chat__right">
            <ChatMessages
              selectedRoom={
                addingNewRoom ? null : selectedRoom || sortedRooms[0]
              }
              onSend={onSend}
              onCancelStartRoom={onCancelStartRoom}
              newMessages={notifications.filter(
                ({ type, roomId }) =>
                  type === 'newWorkflowMessage' && roomId === selectedRoom?.id,
              )}
              onCreateRoom={onCreateRoom}
              participants={availableParticipants}
              activity={activity}
              toggleRooms={toggleRooms}
              mobileRoomsOpen={showRooms}
              addingNewRoom={addingNewRoom}
              adminRooms={adminRooms}
              onCreateAdminRoom={onCreateAdminRoom}
            />
          </div>
        </Card>
      </div>
    </div>
  )
}

const css = k`
  .chat__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    // padding-bottom: 20px;
    // height: calc(100dvh - 190px);
  }

  .chat__container {
    background: #fff;
    margin: 0 auto;
    flex-grow: 0;
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .join-overlay {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    backdrop-filter: blur(10px);
    z-index: 10;
    border-radius: $base-border-radius;
  }

  .mobile-chat__left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    background: #fff;
    padding: 1.2rem 0;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
  }

  .chat__left {
    flex: 1;
  }

  .chat__right {
    flex: 2;
    position: relative;
  }

  @media (min-width: $media3-width) {
    // .chat__wrapper {
    //   padding: 1.6rem;
    // }

    .chat__container {
      padding: 1.6rem;
      max-height: 850px;
      height: 640px;
    }

    .chat__left {
      position: static;
      margin-right: 1.6rem;
    }
  }
`
