import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from 'firebase/firestore'
import { k, useCss } from 'kremling'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Card } from '@components/card.component'
import { EmptyState } from '@components/empty-state.component'
import { Icon } from '@components/icon.component'
import { Loader } from '@components/loader.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { Button } from '@components/mantine/button.component'
import { TextInput } from '@components/mantine/text-input.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { modalService } from '@utils/modal.service'
import { AddGroupModal } from './add-group-modal.component'

export function EditGroup() {
  const scope = useCss(css)
  const navigate = useNavigate()
  const [usersLoader, setUsersLoader] = useState(true)
  const [searchVal, setSearchVal] = useState('')
  const [groupUsers, setGroupUsers] = useState([])
  const me = useAppState(state => state.user)
  const params = useParams()

  const [group, _setGroup, groupOpts] = useLoad(
    null,
    () => {
      return getDoc(doc(db, dbNames.groups, params.groupId)).then(d => ({
        ...d.data(),
        id: d.id,
      }))
    },
    [],
  )

  const [users, _setUsers, usersOpts] = useLoad(
    [],
    () => {
      const q = query(
        collection(db, dbNames.users),
        orderBy(`orgRoles.${params.orgId}`),
      )
      return getDocs(q).then(q => q.docs.map(u => ({ ...u.data(), id: u.id })))
    },
    [],
  )

  function openEditModal() {
    modalService
      .render(AddGroupModal, {
        users,
        adminId: me.id,
        group,
        groupUsers,
        orgId: params.orgId,
      })
      .then(groupOpts.reload)
  }

  useEffect(() => {
    setUsersLoader(true)
    if (group?.userIds) {
      Promise.all(group.userIds.map(id => getDoc(doc(db, dbNames.users, id))))
        .then(qArr =>
          qArr.map(q => ({
            ...q.data(),
            id: q.id,
          })),
        )
        .then(users => {
          setUsersLoader(false)
          setGroupUsers(users)
        })
    }
  }, [group])

  function back() {
    navigate(-1)
  }

  function renderGroupUsers() {
    const filteredGroups = groupUsers.filter(user =>
      `${user.firstName} ${user.lastName}`
        .toLowerCase()
        .includes(searchVal.toLowerCase()),
    )

    if (filteredGroups.length) {
      return filteredGroups.map(user => (
        <div
          className="user-item"
          key={user.id}
        >
          <div>
            {user.firstName} {user.lastName}
          </div>
        </div>
      ))
    } else if (searchVal) {
      return <EmptyState title="No search results." />
    } else {
      return (
        <EmptyState
          title="No users in this group."
          actionText="Edit Group"
          action={openEditModal}
        />
      )
    }
  }

  return (
    <div
      {...scope}
      className="iact-container --xs pt-8"
    >
      <div className="pt-8">
        {groupOpts.loading || usersLoader || usersOpts.loading ? (
          <Loader />
        ) : (
          <>
            <div className="edit-group__actions">
              <ActionIcon
                size="sm"
                icon="angle-left"
                onClick={back}
              >
                Back
              </ActionIcon>
            </div>
            <Card>
              <div className="edit-group">
                <div className="edit-group__header">
                  <div className="header__left">
                    <Icon
                      name="users"
                      size={40}
                    />
                    <div className="edit-group__name">{group?.name}</div>
                  </div>
                  <div className="header__right">
                    <Button
                      variant="secondary"
                      onClick={openEditModal}
                    >
                      Edit Group
                    </Button>
                  </div>
                </div>
                <div>
                  {groupUsers.length > 0 && (
                    <TextInput
                      placeholder="Search Users in Group..."
                      className="mb-3"
                      icon="search"
                      onChange={setSearchVal}
                      value={searchVal}
                    />
                  )}
                  {renderGroupUsers()}
                </div>
              </div>
            </Card>
          </>
        )}
      </div>
    </div>
  )
}

EditGroup.propTypes = {}

const css = k`
  .edit-group {
    padding: 2.4rem;
    min-height: 22.8rem;
  }
  .edit-group__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.6rem;
  }
  .edit-group__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3.2rem;
  }

  .header__left {
    display: flex;
    align-items: center;
  }

  .edit-group__name {
    font-size: 1.8rem;
    font-weight: 500;
    margin-left: 1.6rem;
  }

  .card-footer {
    border-top: solid .1rem $color-grey-100;
    padding: 1.6rem;
    text-align: right;

    > * {
      margin-left: .8rem;
    }
  }

  .user-item {
    border: solid .1rem $color-grey-100;
    border-radius: $base-border-radius;
    margin-bottom: .8rem;
    height: 4.8rem;
    padding: 0 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
