import React from 'react'
import { useCss, k } from 'kremling'
// import { getUserFullName } from '@utils/helpers';
// import { useLoad } from '@hooks/use-load.hook';
// import { Loader } from '@components/loader.component';
// import { Card } from '@components/card.component';
// import { Button } from '@components/button.component';
// import { modalService } from '@utils/modal.service';
// import { AddUserModal } from '../organization/users/add-user-modal.component';
// import { db } from '@src/firebase-app';
// import { Dropdown } from '@components/dropdown.component';
// import { useAppState } from '@src/app.state';
// import { dbNames } from '@utils/constants';
// import { auth } from '@src/firebase-app';
// import {toastService} from '@utils/toast.service';

export function SuperSettingsSupers() {
  const scope = useCss(css)

  return (
    <div {...scope}>
      Nothing here
      {/*<div className="mb-16">*/}
      {/*  <div className="flex">*/}
      {/*    <Button btnType="secondary" onClick={addAdmin}>*/}
      {/*      Create Super Admin*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<Card>*/}
      {/*  {users.map(user => (*/}
      {/*    <Card.Item  className="user-wrapper" key={user.email} onClick={() => {*/}
      {/*      if (user.userId === me.id) {*/}
      {/*        history.push('/profile');*/}
      {/*      }*/}
      {/*    }}>*/}
      {/*      <div>*/}
      {/*        <div className="user-name">*/}
      {/*          {getUserFullName(user)}*/}
      {/*        </div>*/}
      {/*        <div>*/}
      {/*          {user.email}*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      {user.userId !== me.id && (*/}
      {/*        <Dropdown*/}
      {/*          position="bottom-left"*/}
      {/*          cover*/}
      {/*          renderTrigger={({ toggle }) => (*/}
      {/*            <Button icon="ellipsis-v" onClick={toggle} />*/}
      {/*          )}*/}
      {/*          renderContent={() => (*/}
      {/*            <div className="select-list">*/}
      {/*              /!*<button>Edit User</button>*!/*/}
      {/*              /!*<div className="divider" />*!/*/}
      {/*              /!*<button>Deactivate User</button>*!/*/}
      {/*              /!*<div className="divider" />*!/*/}
      {/*              <button onClick={() => resendActivationEmail(user)}>Resend activate email</button>*/}
      {/*            </div>*/}
      {/*          )}*/}
      {/*        />*/}
      {/*      )}*/}
      {/*    </Card.Item>*/}
      {/*  ))}*/}
      {/*</Card>*/}
    </div>
  )
}

SuperSettingsSupers.propTypes = {}

const css = k`
  .flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .user-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .user-name {
    font-size: 1.6rem;
    font-weight: 500;
  }
  .card-item {
    padding: .8rem;
  }
`
