import { Icon } from '@components/icon.component'
import { Loader } from '@components/loader.component'
import { NavLink } from '@src/navigation/nav-link.component'
import { a } from 'kremling'
import React, {
  AnchorHTMLAttributes,
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
} from 'react'
import { NavLinkProps } from 'react-router-dom'

type DivProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

export type CardProps = (DivProps | NavLinkProps) & {
  children?: ReactNode
  level?: string
  onClick?: () => void
  href?: string
  loader?: boolean
  className?: string
  linkState?: Record<string, unknown>
  selected?: boolean
  to?: string
}

export function Card({
  children,
  level = '',
  onClick = null,
  href = null,
  loader = false,
  className = '',
  linkState = {},
  selected = false,
  to,
  ...rest
}: CardProps) {
  const clickable = onClick || href
  let Tag: any = 'div'
  let props: Record<string, any> = {}

  if (href) {
    Tag = NavLink
    props.to = href
    props.state = linkState
  } else if (clickable) {
    Tag = 'div'
    props.onClick = onClick
    props.tabIndex = 0
  }

  return (
    <Tag
      {...props}
      onClick={onClick}
      tabIndex={clickable ? 0 : -1}
      className={a('card')
        .a(className)
        .m(`card-level-${level}`, level)
        .m('card-level-1', !clickable && !level)
        .m('card--clickable', clickable)
        .m('card--active', selected)
        .toString()}
      {...rest}
    >
      {loader ? <Loader /> : children}
    </Tag>
  )
}

interface CardHeaderProps {
  children?: ReactNode
  className?: string
  icon?: string
}

Card.Header = ({ children, icon = '' }: CardHeaderProps) => {
  return (
    <div className="card__header">
      <div className="card-header__inner">
        {icon && (
          <Icon
            name={icon}
            className="mr-3"
          />
        )}
        {children}
      </div>
    </div>
  )
}

interface CardItemProps extends AnchorHTMLAttributes<HTMLDivElement> {
  children?: ReactNode
  className?: string
  onClick?: () => void
  href?: string
}

Card.Item = ({
  children,
  className,
  onClick,
  href,
  ...rest
}: CardItemProps) => {
  const clickable = onClick || href
  let Tag: any = 'div',
    props: Record<string, any> = {}

  if (href) {
    Tag = NavLink
    props.to = href
  } else if (clickable) {
    Tag = 'div'
    props.onClick = onClick
    props.tabIndex = 0
  }

  return (
    <Tag
      className={a('card-item')
        .a(className)
        .m('card-item--clickable', clickable)
        .toString()}
      {...props}
      {...rest}
    >
      {children}
    </Tag>
  )
}
