import { k, useCss } from 'kremling'
import React, { useState } from 'react'

import { TextInput } from '@components/mantine/text-input.component'
import { Switch } from '@components/radix/switch'

export function FieldLink({
  field,
  onChange,
  isRequired,
  updateRequiredToggle,
}) {
  const scope = useCss(css)
  const [linkIsValid, setLinkIsValid] = useState(true)

  function updateFieldLabel(value) {
    if (value === field.label) return
    const updatedField = {
      ...field,
      label: value,
    }
    onChange(updatedField, true)
  }

  function updateFieldLink(value) {
    if (isValidURL(value)) {
      setLinkIsValid(true)
      const updatedField = {
        ...field,
        link: value,
      }
      onChange(updatedField, true)
    } else {
      setLinkIsValid(false)
    }
  }

  function isValidURL(str) {
    const pattern = new RegExp(
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?',
      'i',
    ) // query string
    return !!pattern.test(str)
  }

  return (
    <div
      {...scope}
      className="space-y-6"
    >
      <div className="flex items-center">
        <Switch
          id={`required-switch-${field.id}`}
          onChange={value => updateRequiredToggle(value, field.id)}
          className="mr-2"
          value={isRequired}
        />
        <label htmlFor={`required-switch-${field.id}`}>Required</label>
      </div>
      <div className="row break-1">
        <div className="col-12">
          <div className="form-group">
            <TextInput
              defaultValue={field.label}
              label="Name the field"
              placeholder="e.g. iAct Website"
              onChange={updateFieldLabel}
              autoFocus
            />
          </div>
        </div>
      </div>
      <div className="row break-1">
        <div className="col-12">
          <div className="form-group">
            <TextInput
              label="Provide a link"
              placeholder="e.g. https://iact.com"
              onChange={updateFieldLink}
            />
            {!linkIsValid && (
              <div className="has-error">
                Link must be a valid URL. Without a valid URL this field will
                not display for users.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

FieldLink.propTypes = {}

const css = k`
  .has-error {
    margin-top: .4rem;
    color: $color-danger;
  }
`
