import { Icon } from '@components/icon.component'
import { Loader } from '@mantine/core'
import * as DomPurify from 'dompurify'
import React, { useState } from 'react'
import wretch from 'wretch'
import { useAudioPlayer } from '../workflow-refactor/hooks/use-audio-player.hook'

type FieldHeaderContentProps = {
  field: Field
  isRequired?: boolean
  section: ActivitySection
  markCompleted?: boolean
  actions?: React.ReactNode
}

export function FieldHeaderContent({
  field,
  isRequired,
  markCompleted,
  actions,
}: FieldHeaderContentProps) {
  const [loadingAudio, setLoadingAudio] = useState(false)

  const { isPlaying, playAudio, pauseAudio, loadAudio, audioLoaded } =
    useAudioPlayer()

  function createMarkup() {
    return {
      __html: DomPurify.sanitize(field.description || field.value, {
        ADD_ATTR: ['target'],
      }),
    }
  }

  // function openEditModal() {
  //   modalService.render(EditFieldModal, {
  //     field,
  //     section,
  //     orgId: activity?.orgId,
  //   })
  // }

  const handleClick = async () => {
    const text = new DOMParser().parseFromString(
      field.description || field.value,
      'text/html',
    ).documentElement.textContent

    setLoadingAudio(true)
    const response = await wretch(
      `${process.env.REST_URL_PREFIX}/allFunctions-textToSpeech`,
    )
      .post({ text })
      .blob()

    const audioBlob = new Blob([response], { type: 'audio/mpeg' })
    const audioUrl = URL.createObjectURL(audioBlob)
    setLoadingAudio(false)
    await loadAudio(audioUrl)
  }

  function getAudioIcon() {
    if (!audioLoaded) return 'headphones'
    if (isPlaying) return 'pause'
    if (!isPlaying) return 'play'
  }

  function getAudioFunction() {
    if (audioLoaded) {
      if (isPlaying) return pauseAudio
      return playAudio
    }
    return handleClick
  }

  return (
    <div className="relative">
      <div className="flex justify-between items-center w-full mb-1">
        <label
          className="field-label text-2xl font-semibold flex items-center mb-2"
          htmlFor={`${field.id}-rich-text`}
        >
          {field.label && (
            <>
              {field.label}
              {isRequired && <span className="aria-invisible"> Required</span>}
              {markCompleted && (
                <Icon
                  className="ml-4 text-primary"
                  name="check-circle"
                />
              )}
            </>
          )}
        </label>
        <div className="flex items-center gap-2">
          {(field.description || field.value) && (
            <button
              type="button"
              className="w-[2.8rem] h-[2.8rem] flex items-center justify-center bg-white border-2 border-primary rounded-full shadow-sm"
              onClick={getAudioFunction()}
            >
              {loadingAudio ? (
                <Loader
                  size="sm"
                  color="gray"
                />
              ) : (
                <Icon
                  name={getAudioIcon()}
                  className="text-primary"
                  size={16}
                />
              )}
            </button>
          )}
          {actions}
          {/*{isAdmin && (*/}
          {/*  <ActionIcon*/}
          {/*    icon="pencil"*/}
          {/*    onClick={openEditModal}*/}
          {/*    size="sm"*/}
          {/*  />*/}
          {/*)}*/}
        </div>
      </div>
      {(field.description || field.value) && (
        <div>
          <div dangerouslySetInnerHTML={createMarkup()} />
        </div>
      )}
    </div>
  )
}
