import { array, bool, func, string } from 'prop-types'
import React, { forwardRef } from 'react'

import { defaultSearchFilter, filterAlpha } from './select/common/utils'
import { Select } from './select/select.component'

export const SelectMulti = forwardRef((props, ref) => {
  const {
    contentWidth,
    data,
    disabled,
    fixedContent,
    insertSearch,
    isGroupData,
    loading,
    onChange,
    onClose,
    onOpen,
    placeholder = 'Select',
    renderFooter,
    renderTrigger,
    renderValue,
    searchFilter,
    searchOnChange,
    searchValue,
    transformData,
    triggerIsBlock,
    value,
    defaultOpenState,
    onFocus,
    onBlur,
  } = props
  return (
    <Select
      contentWidth={contentWidth}
      data={data}
      disabled={disabled}
      fixedContent={fixedContent}
      insertSearch={insertSearch}
      isGroupData={isGroupData}
      loading={loading}
      onChange={onChange}
      onClose={onClose}
      onOpen={onOpen}
      renderFooter={renderFooter || null}
      renderGroup={Select.Group}
      renderItem={Select.ItemMulti}
      renderTrigger={
        renderTrigger ||
        (triggerProps => (
          <Select.TriggerMulti
            {...triggerProps}
            ref={ref}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            renderValue={renderValue}
          />
        ))
      }
      searchFilter={defaultSearchFilter(searchFilter, searchValue)}
      searchOnChange={searchOnChange}
      searchValue={searchValue}
      showResultsInContent
      transformData={transformData}
      triggerIsBlock={triggerIsBlock}
      value={value}
      defaultOpenState={defaultOpenState}
    />
  )
})

SelectMulti.propTypes = {
  data: array.isRequired,
  filter: func,
  insertSearch: bool,
  isGroupData: bool,
  loading: bool,
  onChange: func.isRequired,
  onClose: func,
  onOpen: func,
  placeholder: string,
  renderFooter: Select.propTypes.renderFooter,
  renderTrigger: Select.propTypes.renderTrigger,
  renderValue: func,
  searchOnChange: func,
  searchValue: string,
  transformData: func,
  triggerIsBlock: bool,
  value: array.isRequired,
  defaultOpenState: bool,
}

SelectMulti.filterAlpha = filterAlpha
