import { Icon } from '@components/icon.component'
import { Radio } from '@components/radio.component'
import { Checkbox } from '@components/radix/checkbox'
import { isEmpty } from 'lodash'
import React from 'react'

type Props = {
  settings: ShareSetting[]
  updateSetting: (path: string[], value: boolean) => void
  path?: string[]
}

export function SettingsList({ settings, updateSetting, path = [] }: Props) {
  return (
    <div className="flex flex-col gap-4">
      {settings.map(setting => {
        return (
          <div
            key={setting.id}
            className="flex flex-col gap-2"
          >
            {setting.options.map(option => {
              return (
                <div
                  key={option.value}
                  style={{ padding: path.length === 0 ? '.8rem' : '' }}
                  className="flex flex-col gap-2 rounded-md data-[selected=true]:bg-active-75"
                  data-selected={option.selected}
                >
                  {setting.type === 'radio' &&
                    (path.length > 1 ? (
                      <div className="flex items-center justify-between w-full">
                        <Radio
                          id={option.value}
                          value={option.value}
                          name={setting.id}
                          checked={option.selected}
                          onChange={e => {
                            updateSetting(
                              [...path, option.value],
                              e.target.id === option.value,
                            )
                          }}
                        />
                        <div className="flex items-center flex-1 ml-4">
                          <label
                            data-selected={option.selected}
                            className="flex items-center data-[selected=true]:text-primary data-[selected=true]:font-semibold"
                            htmlFor={option.value}
                          >
                            {option.label}
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center justify-between w-full">
                        <div className="flex items-center flex-1">
                          <label
                            data-selected={option.selected}
                            className="flex items-center data-[selected=true]:text-primary data-[selected=true]:font-semibold"
                            htmlFor={option.value}
                          >
                            <div className="p-2 rounded-md mr-2">
                              <Icon name={option.icon} />
                            </div>
                            {option.label}
                          </label>
                        </div>
                        <Radio
                          id={option.value}
                          value={option.value}
                          name={setting.id}
                          checked={option.selected}
                          onChange={e => {
                            updateSetting(
                              [...path, option.value],
                              e.target.id === option.value,
                            )
                          }}
                        />
                      </div>
                    ))}
                  {setting.type === 'checkbox' && (
                    <Checkbox
                      checked={option.selected}
                      onCheckedChange={(checked: boolean) => {
                        updateSetting([...path, option.value], checked)
                      }}
                      label={
                        <span
                          data-selected={option.selected}
                          className="data-[selected=true]:text-primary data-[selected=true]:font-semibold"
                        >
                          {option.label}
                        </span>
                      }
                    />
                  )}
                  {!isEmpty(option.settingGroups) && option.selected && (
                    <div className="pl-8">
                      <SettingsList
                        settings={option.settingGroups}
                        updateSetting={updateSetting}
                        path={[...path, option.value]}
                      />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
