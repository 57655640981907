import { k, useCss } from 'kremling'
import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Card } from '@components/card.component'
import { Logo } from '@components/logo.component'
import { TextDivider } from '@components/text-divider.component'
import { useAppState } from '@src/app.state'
import { SignInPassword } from '@src/pages/account/auth/sign-in-password.component'
import { OauthList } from './oauth/oauth-list.component'

export function SignIn({ hideOptions = false }) {
  const isAuthenticated = useAppState(state => state.isAuthenticated)
  const user = useAppState(state => state.user)
  const styles = useCss(css)
  const navigate = useNavigate()

  useEffect(() => {
    if (
      isAuthenticated &&
      (!user?.isGuest || user?.isAnonymous) &&
      user?.orgOrderIds
    ) {
      if (user?.orgOrderIds.length) {
        navigate(`/${user?.orgOrderIds[0]}`)
      } else {
        navigate('/welcome')
      }
    }
  }, [isAuthenticated, user])

  return (
    <div
      {...styles}
      className="wrapper"
    >
      <div className="w-420 hidden w-[42rem] shrink-0 flex-col items-center justify-center bg-primary p-16 text-white media3:flex">
        <Logo
          className="main-logo"
          light
        />
      </div>
      <div className="sign-in-wrapper">
        <Card className="sign-in-card card-level-2">
          <div className="block text-center media3:hidden">
            <Logo className="logo__mobile" />
          </div>
          <h3 className="text-2xl font-bold">Sign in here</h3>
          <SignInPassword />
          <TextDivider className="mb-8 mt-8">OR</TextDivider>
          <OauthList />
          {!hideOptions && (
            <div className="other-options">
              <div>Need an account?</div>
              <Link to="/register">Create Account</Link>
            </div>
          )}
        </Card>
      </div>
    </div>
  )
}

const css = k`
  .wrapper {
    display: flex;
    height: 100vh;
  }

  .welcome {
    padding: 4.2rem;
  }

  div.main-logo {
    width: 10rem !important;
  }

  div.logo__mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3.2rem;

    svg {
      width: 10rem;
    }
  }

  .sign-in-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .sign-in-card {
    padding: 3.2rem;
    width: 40rem;
  }

  .other-options {
    text-align: center;
    margin-top: 1.6rem;
  }

  .password-label {
    display: flex;
    justify-content: space-between;
  }

  .password-label > a {
    flex-shrink: 0;
  }
`
