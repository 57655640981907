export const dbNames = {
  users: 'users',
  workflowTemplates: 'workflowTemplates',
  templateSections: 'sections',
  groups: 'groups',
  activityMentors: 'activityMentors',
  pendingInvitations: 'pendingInvitations',
  userCreationRequests: 'userCreationRequests',
  files: 'files',
  organizations: 'organizations',
  organizationProfiles: 'organizationProfiles',
  invitations: 'invitations',
  publishedTemplates: 'publishedTemplates',
  marketplaceTemplates: 'marketplaceTemplates',
  adoptedMarketplaceTemplates: 'adoptedMarketplaceTemplates',
  curatedTemplates: 'curatedTemplates',
  activityChallenges: 'activityChallenges',
  analyticsErrors: 'analyticsErrors',
  scheduledTasks: 'scheduledTasks',
  messageRooms: 'messageRooms',
  messageRoomUsers: 'messageRoomUsers',
  messages: 'messages',
  notifications: 'notifications',
  activities: 'activities',
  activitySections: 'activitySections',
  progressSections: 'progressSections',
  sectionIterations: 'sectionIterations',
  activityUsers: 'activityUsers',
  tags: 'tags',
  assistantConversations: 'assistantConversations',
  purchases: 'purchases',
}
