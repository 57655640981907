import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { k, useCss } from 'kremling'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { GroupCard } from '@components/cards/group-card.component'
import { EmptyState } from '@components/empty-state.component'
import { LayoutHeader } from '@components/layout.component'
import { Loader } from '@components/loader.component'
import { Button } from '@components/mantine/button.component'
import { TextInput } from '@components/mantine/text-input.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { AddGroupModal } from '@src/pages/admin/groups/add-group-modal.component'
import { dbNames } from '@utils/constants'
import { modalService } from '@utils/modal.service'

export function Groups() {
  const scope = useCss(css)
  const me = useAppState(state => state.user)
  const [searchVal, setSearchVal] = useState('')
  const { orgId } = useParams()

  const [groups, _setGroups, groupOpts] = useLoad(
    [],
    () => {
      const q = query(
        collection(db, dbNames.groups),
        orderBy('name'),
        where('orgId', '==', orgId),
      )
      return getDocs(q).then(q => q.docs.map(g => ({ ...g.data(), id: g.id })))
    },
    [],
  )

  function addGroup() {
    modalService
      .render(AddGroupModal, { adminId: me.id, orgId })
      .then(groupOpts.reload)
  }

  function renderGroups() {
    const filteredGroups = groups.filter(group => {
      return searchVal
        ? group.name.toLowerCase().includes(searchVal.toLowerCase())
        : true
    })

    if (filteredGroups.length) {
      return filteredGroups.map(group => {
        return (
          <GroupCard
            group={group}
            key={group.id}
          />
        )
      })
    } else if (searchVal) {
      return (
        <EmptyState
          title="No search results"
          action={addGroup}
          actionText="Add Group"
        />
      )
    } else {
      return (
        <EmptyState
          title="No Groups"
          subtitle="Let's add some"
          action={addGroup}
          actionText="Add Group"
        />
      )
    }
  }

  if (groupOpts.loading)
    return (
      <div className="pt-8">
        <Loader />
      </div>
    )

  return (
    <div {...scope}>
      <LayoutHeader
        className="pt-8"
        title="Groups"
        search={
          <TextInput
            placeholder="Search Groups..."
            icon="search"
            value={searchVal}
            onChange={setSearchVal}
            allowClear
          />
        }
        actions={
          <Button
            variant="secondary"
            onClick={addGroup}
          >
            Add Group
          </Button>
        }
      />
      {renderGroups()}
    </div>
  )
}

const css = k`
  .invitations-container {
    padding: 2rem;
    background: $color-grey-75;
    border-radius: $base-border-radius;
    margin-bottom: 2rem;

    .invitations-title {
      font-size: 2rem;
      font-weight: 400;
      margin-bottom: .8rem;
    }
  }

  .user-invitation:not(:last-child) {
    margin-bottom: .8rem;
  }
`
