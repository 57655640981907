import { DateTime, Duration } from 'luxon'
import { useEffect, useRef, useState } from 'react'
import { Timeout } from 'react-number-format/types/types'

// intervalTimer from this article https://blog.bitsrc.io/how-to-get-an-accurate-setinterval-in-javascript-ca7623d1d26a
function intervalTimer(callback: () => void, interval = 500): () => void {
  let counter = 1
  let timeoutId: Timeout
  const startTime = Date.now()

  function main() {
    const nowTime = Date.now()
    const nextTime = startTime + counter * interval
    timeoutId = setTimeout(main, interval - (nowTime - nextTime))

    counter += 1
    callback()
  }

  timeoutId = setTimeout(main, interval)

  return () => {
    clearTimeout(timeoutId)
  }
}

export function useCountdown(
  targetTime: DateTime,
  onFinish?: () => void,
): Duration | DateTime {
  const timerRef = useRef<() => void>(null)
  const calculateTimeLeft = (): Duration | DateTime => {
    const now: DateTime = DateTime.local()
    if (targetTime < now) return Duration.fromMillis(0)
    return targetTime.diff(now)
  }

  const [timeLeft, setTimeLeft] = useState<Duration | DateTime>(
    calculateTimeLeft(),
  )

  useEffect(() => {
    if (timerRef.current && timeLeft.valueOf() <= 0) {
      timerRef.current()
      timerRef.current = null
      onFinish?.()
    }
  }, [timeLeft])

  useEffect(() => {
    if (!targetTime) return
    timerRef.current = intervalTimer(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => {
      if (timerRef.current) {
        timerRef.current()
      }
    }
  }, [targetTime])

  return timeLeft
}
