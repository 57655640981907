import { useAppState } from '@src/app.state'
import { NavLink } from '@src/navigation/nav-link.component'
import { getPrimaryNavList } from '@src/navigation/nav-list'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

export function MobileWorkspaceNav() {
  const user = useAppState(state => state.user)
  const orgs = useAppState(state => state.orgs)
  const { orgId } = useParams()

  const selectedOrg = useMemo(
    () => orgs.find(org => org.id === orgId),
    [orgs, orgId],
  )

  const primaryNavList = getPrimaryNavList(selectedOrg, user)

  return (
    <div className="flex justify-center media4:hidden h-[5rem] border-t border-grey-100 bg-white">
      <div className="flex w-full">
        {orgId &&
          primaryNavList.map(({ path, label }) => (
            <NavLink
              key={label}
              className="h-full flex items-center justify-center flex-1 text-2xl font-semibold"
              activeClassName="bg-primary text-white"
              to={path}
            >
              {label}
            </NavLink>
          ))}
      </div>
    </div>
  )
}
