import { Button } from '@components/mantine/button.component'
import { Modal } from '@components/modal.component'
import { ChoiceItemList } from '@src/pages/workflow-template-list/template-generator/choice-item-list.component'
import React, { useState } from 'react'

type ModalProps = {
  onReject: () => void
  onResolve: (data: { issueDescription: string; issueItems: string[] }) => void
  list: string[]
}

export const AssistantReportModal = ({
  onReject,
  onResolve,
  list,
}: ModalProps) => {
  const [selectedReportDescription, setSelectedReportDescription] = useState('')
  const [selectedProblemItems, setSelectedProblemItems] = useState([])

  const reportOptions = [
    'There was an error in the responses I received',
    'There was a response that was inappropriate',
    "The responses aren't relevant enough",
    'Other',
  ]

  return (
    <Modal onClose={onReject}>
      <Modal.Header>Report an issue</Modal.Header>
      <Modal.Body style={{ maxHeight: 400 }}>
        {!selectedReportDescription && (
          <ChoiceItemList
            title="What issue are you experiencing?"
            type="radio"
            list={reportOptions}
            selectedItems={[selectedReportDescription]}
            onSelect={setSelectedReportDescription}
          />
        )}
        {!!selectedReportDescription && (
          <ChoiceItemList
            title="Which of these items contain the issue?"
            list={list}
            selectedItems={selectedProblemItems}
            onSelect={newItem =>
              setSelectedProblemItems(state => {
                if (state.includes(newItem)) {
                  return state.filter(item => item !== newItem)
                } else {
                  return [...state, newItem]
                }
              })
            }
          />
        )}
      </Modal.Body>
      <Modal.BalancedFooter>
        <div>
          {selectedReportDescription && (
            <Button onClick={() => setSelectedReportDescription('')}>
              Change reason
            </Button>
          )}
        </div>
        <div>
          <Button onClick={onReject}>Cancel</Button>
          <Button
            variant="primary"
            onClick={() =>
              onResolve({
                issueDescription: selectedReportDescription,
                issueItems: selectedProblemItems,
              })
            }
            disabled={
              !selectedReportDescription || !selectedProblemItems.length
            }
          >
            Send report
          </Button>
        </div>
      </Modal.BalancedFooter>
    </Modal>
  )
}
