import { a, k, useCss } from 'kremling'
import { DateTime } from 'luxon'
import React, { useMemo } from 'react'

import { CardListItem } from '@components/card-list-item.component'
import { Icon } from '@components/icon.component'
import { Pill } from '@components/pill/pill.component'
import { WorkflowProgressBar } from '@components/workflow-progress-bar.component'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { doc, getDoc } from 'firebase/firestore'

export function WorkflowProgressCard({
  activity,
  userId,
  subTitle = '',
  className = '',
  ...cardProps
}) {
  const scoped = useCss(css)
  const systemDate = useAppState(state => state.systemDate)
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const today = useMemo(() => getTodayDate(), [systemDate])

  const [activityUser] = useLoad(
    {},
    () => {
      const d = doc(db, dbNames.activityUsers, `${activity.id}_${userId}`)
      return getDoc(d).then(snap => ({ ...snap.data(), id: snap.id }))
    },
    [activity.id, userId],
  )

  const convertedStartDate = useMemo(
    () =>
      DateTime.fromISO(activity.startDate.date, {
        zone: activity.startDate.timeZone,
      }).setZone(timeZone),
    [],
  )
  const convertedEndDate = useMemo(
    () =>
      DateTime.fromISO(activity.endDate.date, {
        zone: activity.endDate.timeZone,
      }).setZone(timeZone),
    [],
  )

  const isBeforeStartDate =
    convertedStartDate.toUTC().toISO() > today.toUTC().toISO()
  const isPastEndDate =
    convertedEndDate.plus({ days: 1 }).toUTC().toISO() <= today.toUTC().toISO()

  return (
    <div
      {...scoped}
      className="space-y-2 w-full"
    >
      <CardListItem
        {...cardProps}
        className={a(className).a('workflow-card__inner')}
        cardTitle={
          activity.id ? (
            <span>
              {activity.name}
              <Pill
                pillType="tertiary"
                className="ml-2"
                width={24}
                height={24}
                centerContent
              >
                <Icon
                  name="flag-checkered"
                  size={12}
                />
              </Pill>
            </span>
          ) : (
            activity.name
          )
        }
        subTitle={subTitle}
        icon="ballot"
        renderRightContent={() => {
          if (isBeforeStartDate) {
            return `Begins ${convertedStartDate.toFormat('LLL dd')}`
          }

          if (isPastEndDate) {
            return (
              <div className="completed-container">
                <span>{convertedEndDate.toFormat('LLL dd')}</span>
                <Icon
                  className="ml-3"
                  name="check-circle-regular"
                  fill="#42c873"
                  size={16}
                />
              </div>
            )
          }

          return (
            <div className="client-workflow-progress media-show-1">
              <WorkflowProgressBar
                activity={activity}
                activityUser={activityUser}
              />
            </div>
          )
        }}
      />
    </div>
  )
}

const css = k`
  .workflow-card__inner {
    flex-basis: 100%;
  }

  .client-workflow-progress {
    display: flex;
    align-items: center;
    justify-content: stretch;
    min-width: 260px;
  }

  .progress-link {
    padding: 12px;
    color: $color-primary;
    margin-right: 8px;
    display: flex;
    align-items: center;
  }

  .completed-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`
