import { doc, increment, updateDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import React, { useEffect, useState } from 'react'

import { DatePicker } from '@components/date-picker.component'
import { Button } from '@components/mantine/button.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Modal } from '@components/modal.component'
import { Required } from '@components/required.component'
import { SelectSingle } from '@components/select-single.component'
import { useAppState } from '@src/app.state'
import { db, functions } from '@src/firebase-app'
import { buildRuleMeta, metas } from '@src/pages/workflow/workflow-utils'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'

export function StartMarketplaceWorkflowModal({
  onReject,
  onResolve,
  template,
}) {
  const [creating, setCreating] = useState(false)
  const user = useAppState(state => state.user)
  const orgs = useAppState(state => state.orgs)
  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  const [selectedName, setSelectedName] = useState(template.name)
  const [selectedOrg, setSelectedOrg] = useState(orgs[0])
  const [ruleMeta, setRuleMeta] = useState({})

  useEffect(() => {
    const { section, meta, endDate } = buildRuleMeta(
      template.sections,
      selectedStartDate,
    )
    if (selectedStartDate && section) {
      if (meta === metas.RECURRENCE_AND_REPEAT) {
        setSelectedEndDate(endDate)
      }
    }
    setRuleMeta({ section, meta })
  }, [selectedStartDate])

  async function build() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const startDate = selectedStartDate.toISO()
    const endDate = selectedEndDate.toISO()
    const data = {
      startDate: {
        date: startDate,
        timeZone,
      },
      endDate: {
        date: endDate,
        timeZone,
      },
      userTimeZone: timeZone,
      selectedWorkflowTemplate: template,
      ruleMeta,
      selectedName,
      orgId: selectedOrg.id,
      user,
      today: getTodayDate().toISO(),
    }
    setCreating(true)
    const createActivity = httpsCallable(
      functions,
      'activityFunctions-createActivity',
    )
    const newWorkflowId = await createActivity(data)
    await updateDoc(doc(db, dbNames.marketplaceTemplates, template.id), {
      adoptionsCount: increment(1),
    })
    onResolve({ goToOrgId: selectedOrg.id, newWorkflowId })
  }

  const endDateIsGenerated = ruleMeta?.meta === metas.RECURRENCE_AND_REPEAT

  return (
    <>
      <Modal.Header>Start a workflow</Modal.Header>
      <Modal.Body>
        <div className="row break-1">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="select-workspace">
                Select a workspace destination <Required />
              </label>
              <SelectSingle
                id="select-workspace"
                data={orgs}
                placeholder="Select workspace"
                triggerIsBlock
                onChange={setSelectedOrg}
                value={selectedOrg}
              />
            </div>
          </div>
        </div>
        <div className="row break-1">
          <div className="col-6">
            <div className="form-group">
              <label>
                Start date <Required />
              </label>
              <DatePicker
                placeholder="Select date"
                onChange={setSelectedStartDate}
                value={selectedStartDate}
                triggerIsBlock
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              {endDateIsGenerated ? (
                <label>Generated end date</label>
              ) : (
                <label>
                  End date <Required />
                </label>
              )}
              <DatePicker
                placeholder={
                  endDateIsGenerated ? 'Select a start date' : 'Select date'
                }
                onChange={setSelectedEndDate}
                value={selectedEndDate}
                triggerIsBlock
                disabled={!selectedStartDate || endDateIsGenerated}
                minDate={selectedStartDate?.plus({ days: 1 }).set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>
            Name <Required />
          </label>
          <TextInput
            value={selectedName}
            onChange={setSelectedName}
          />
        </div>
        {endDateIsGenerated && (
          <div className="form-group">
            <strong>Note:</strong> This template contains section rules that
            automatically determine the end date based off of the start date.
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="tertiary"
          onClick={onReject}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={build}
          loading={creating}
          disabled={!selectedStartDate || !selectedEndDate || !selectedName}
        >
          Start workflow
        </Button>
      </Modal.Footer>
    </>
  )
}
