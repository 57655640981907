import { InvitationCard } from '@components/cards/invitation-card.component'
import { EmptyState } from '@components/empty-state.component'
import { Button } from '@components/mantine/button.component'
import { Well } from '@components/well.component'
import { useFirestoreQuery } from '@react-query-firebase/firestore'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { ActivityJoinedNotificationItem } from '@src/pages/notifications/activity-joined-notification-item.component'
import { DeactivatedFromOrgNotificationItem } from '@src/pages/notifications/deactivated-from-org-notification-item.component'
import { ReactivatedToOrgNotificationItem } from '@src/pages/notifications/reactivated-to-org-notification-item.component'
import { dbNames } from '@utils/constants'
import {
  collection,
  deleteDoc,
  doc,
  limit,
  orderBy,
  query,
  where,
  writeBatch,
} from 'firebase/firestore'
import { k, useCss } from 'kremling'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { ActivityStartedNotificationItem } from './activity-started-notification-item.component'
import { NewIterationStartedNotificationItem } from './new-iteration-started-notification-item.component'
import { WorkflowMessageNotificationItem } from './worklfow-message-notification-item.component'

export function Notifications() {
  const scoped = useCss(css)
  const newNotifications = useAppState(state => state.notifications)
  const invitations = useAppState(state => state.invitations)
  const user = useAppState(state => state.user)

  useEffect(() => {
    useAppState.setState({ appLoading: false })
  }, [])

  useEffect(() => {
    if (isEmpty(newNotifications) && isEmpty(invitations)) return
    const batch = writeBatch(db)
    if (newNotifications.length > 0) {
      newNotifications.forEach(notification => {
        if (notification.viewed === false) {
          batch.update(doc(db, dbNames.notifications, notification.id), {
            viewed: true,
          })
        }
      })
    }

    const unseenInvitations = invitations.filter(
      invitation => !invitation.viewed,
    )
    if (unseenInvitations.length > 0) {
      unseenInvitations.forEach(invitation => {
        batch.update(doc(db, dbNames.invitations, invitation.id), {
          viewed: true,
        })
      })
    }
    batch.commit()
  }, [newNotifications, invitations])

  const oldNotificationsQuery = query(
    collection(db, dbNames.notifications),
    where('viewed', '==', true),
    where('recipientId', '==', user.id),
    orderBy('timestamp', 'desc'),
    limit(50),
  )

  const { data = [], isLoading } = useFirestoreQuery(
    'seenNotifications',
    oldNotificationsQuery,
    {
      subscribe: true,
    },
    {
      select: snapshot =>
        snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })),
    },
  )

  const allNotifications = useMemo(() => {
    if (isLoading) return []
    const combined = [...newNotifications, ...data]
    return combined.sort((a, b) => a.timestamp.toDate() - b.timestamp.toDate())
  }, [newNotifications, data, isLoading])

  const sortedInvitations = useMemo(() => {
    return invitations.sort(
      (a, b) => b.timestamp.toDate() - a.timestamp.toDate(),
    )
  }, [invitations])

  async function clearNotifications() {
    const deletePromises = []
    allNotifications.forEach(({ id }) => {
      deletePromises.push(deleteDoc(doc(db, dbNames.notifications, id)))
    })
    await Promise.all(deletePromises)
  }

  return (
    <div
      {...scoped}
      className="iact-container --md notifications__wrapper"
    >
      <div className="space-y-8">
        <h1 className="sr-only">Notifications</h1>
        {!isEmpty(invitations) && (
          <Well>
            <Well.Header icon="envelope">
              <Well.Title>Invitations</Well.Title>
            </Well.Header>
            <Well.Body>
              {sortedInvitations.map(invitation => (
                <InvitationCard
                  key={invitation.id}
                  invitation={invitation}
                />
              ))}
            </Well.Body>
          </Well>
        )}
        <div className="space-y-4">
          <div className="flex justify-end">
            <Button
              disabled={isEmpty(allNotifications)}
              onClick={() => clearNotifications()}
              variant="secondary"
            >
              Clear all
            </Button>
          </div>
          {isEmpty(allNotifications) ? (
            <EmptyState title="No new notifications" />
          ) : (
            allNotifications.map(notification => {
              if (notification.type === 'newWorkflowMessage') {
                return (
                  <WorkflowMessageNotificationItem
                    key={notification.id}
                    notification={notification}
                  />
                )
              } else if (notification.type === 'activityStarted') {
                return (
                  <ActivityStartedNotificationItem
                    key={notification.id}
                    notification={notification}
                  />
                )
              } else if (notification.type === 'newIterationStarted') {
                return (
                  <NewIterationStartedNotificationItem
                    key={notification.id}
                    notification={notification}
                  />
                )
              } else if (notification.type === 'deactivatedFromOrg') {
                return (
                  <DeactivatedFromOrgNotificationItem
                    key={notification.id}
                    notification={notification}
                  />
                )
              } else if (notification.type === 'reactivatedToOrg') {
                return (
                  <ReactivatedToOrgNotificationItem
                    key={notification.id}
                    notification={notification}
                  />
                )
              } else if (notification.type === 'activityJoined') {
                return (
                  <ActivityJoinedNotificationItem
                    key={notification.id}
                    notification={notification}
                  />
                )
              }
            })
          )}
        </div>
      </div>
    </div>
  )
}

const css = k`
  .notifications__wrapper {
    padding-top: 32px;
  }

  .title {
    margin-bottom: 2rem;
    font-size: 2.4rem;
    font-weight: 500;
  }
`
