import { Card } from '@components/card.component'
import { Icon } from '@components/icon.component'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { doc, updateDoc } from 'firebase/firestore'
import { k, useCss } from 'kremling'
import { uniqBy } from 'lodash'
import React from 'react'

export function WorkflowMessageNotificationItem({ notification }) {
  const scoped = useCss(css)
  const avatarUsers = uniqBy(notification.events, 'senderId')
  const { activityOrgId, activityId, activityName, recipientId } = notification
  const user = useAppState(state => state.user)
  const isAdmin = ['owner', 'manager'].includes(user.orgRoles[activityOrgId])

  return (
    <Card
      {...scoped}
      className={`notification__item ${notification.dirty ? '' : '!bg-active-25'}`}
      onClick={() => {
        if (!notification.dirty) {
          updateDoc(doc(db, dbNames.notifications, notification.id), {
            dirty: true,
          })
        }
      }}
      href={
        isAdmin
          ? `/${activityOrgId}/mentoring/${activityId}/settings/messages`
          : `/${activityOrgId}/home/${recipientId}/${activityId}/messaging`
      }
    >
      <div className="notification__info">
        <div className="notification__title">
          <Icon
            className="mr-2"
            name="comments"
            size={25}
          />
          <div className="text-emphasis">{activityName}</div>
        </div>
        <div className="notification__text">
          {notification.events.length} new message
          {notification.events.length === 1 ? '' : 's'}
        </div>
      </div>
      <div className="avatar-stack">
        {avatarUsers.slice(0, 3).map(event => (
          <div
            className="avatar-circle"
            key={event.senderId}
          >
            {event.senderFirstName[0]}
            {event.senderLastName[0]}
          </div>
        ))}
        {avatarUsers.length > 3 && (
          <div className="avatar-circle">+{avatarUsers.length - 3}</div>
        )}
      </div>
    </Card>
  )
}

const css = k`
  .notification__item {
    text-decoration: none;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .text-emphasis {
    font-weight: 500;
    color: $color-primary;
    font-size: 1.8rem;
  }

  .notification__title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  .notification__text {
    font-weight: 500;
  }

  .avatar-stack {
    display: flex;
  }

  .avatar-circle {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background: $color-grey-100;
    display: flex;
    line-height: 14px;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 3px 8px -3px #888888;
    margin-left: -13px;
    transition: margin-left .1s ease;
    border: solid 2px #fff;
  }

  .avatar-stack:hover > .avatar-circle {
    margin-left: 4px;
  }
`
