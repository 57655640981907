import { signInWithEmailAndPassword } from 'firebase/auth'
import { k, useCss } from 'kremling'
import React, { useState } from 'react'

import { InputField } from '@components/input'
import { Button } from '@components/mantine/button.component'
import { auth } from '@src/firebase-app'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

export function SignInPassword({
  onSignIn = () => {},
  handleClick = () => {},
  onForgot,
}) {
  const styles = useCss(css)
  const [warning, setWarning] = useState('')
  const [signingIn, setSigningIn] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: { email: '', password: '' },
  })

  function handleLogin({ email, password }) {
    setWarning('')
    setSigningIn(true)
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setSigningIn(false)
        onSignIn()
      })
      .catch(err => {
        if (
          err.code === 'auth/user-not-found' ||
          err.code === 'auth/wrong-password'
        ) {
          setSigningIn(false)
          setWarning('Your email and/or password do not match.')
        } else {
          setSigningIn(false)
          setWarning('There was an issue signing in. Try again.')
        }
      })
  }

  return (
    <div {...styles}>
      <form
        onSubmit={handleSubmit(handleLogin)}
        noValidate
      >
        <div className="row break-1">
          <div className="col-12">
            <div className="form-group">
              <InputField
                label="Email"
                control={control}
                fieldName="email"
                required
                type="email"
                placeholder="Enter your email address"
              />
            </div>
          </div>
        </div>
        <div className="row break-1">
          <div className="col-12">
            <div className="form-group">
              <InputField
                control={control}
                fieldName="password"
                type="password"
                placeholder="Enter your password"
                required
                minLength={6}
                label={
                  <div className="flex items-center justify-between">
                    <span>Password</span>
                    {onForgot ? (
                      <Button onClick={onForgot}>Forgot password?</Button>
                    ) : (
                      <Link to="/reset-password">Forgot password?</Link>
                    )}
                  </div>
                }
              />
            </div>
          </div>
        </div>
        {warning && <div className="has-warning mt-3">{warning}</div>}
        <Button
          type="submit"
          onClick={handleClick}
          variant="primary"
          loading={signingIn}
          disabled={!isValid}
          fullWidth
        >
          Sign in
        </Button>
      </form>
    </div>
  )
}

const css = k`
  .sign-in {
    padding: 3.2rem;
    max-width: 50rem;
    min-width: 40rem;
  }

  .password-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .password-label > a {
    flex-shrink: 0;
  }
`
