import { AnimatePresence, motion } from 'framer-motion'
import { Scoped, a, k } from 'kremling'
import { intersection, isEmpty, uniq } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { WorkflowProgressCardInner } from '@components/cards/workflow-progress-card-inner.component'
import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { SelectMulti } from '@components/select-multi.component'
import { useFirestoreQuery } from '@react-query-firebase/firestore'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { getDaysUntil } from '@utils/helpers'
import { collection, doc, query, updateDoc, where } from 'firebase/firestore'
import { DateTime } from 'luxon'

export function ActivityCard({ activity, orgProfile, userIds = [] }) {
  const [expand, setExpand] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState(
    orgProfile.activityFilters
      ? orgProfile.activityFilters[activity.id] || []
      : [],
  )
  const user = useAppState(state => state.user)
  const { orgId } = useParams()
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const convertedStartDate = useMemo(
    () =>
      DateTime.fromISO(activity.startDate.date, {
        zone: activity.startDate.timeZone,
      }).setZone(timeZone),
    [timeZone],
  )

  const activityUsersQuery = isEmpty(userIds)
    ? query(
        collection(db, dbNames.activityUsers),
        where('activityId', '==', activity.id),
        where('deactivated', '==', false),
      )
    : query(
        collection(db, dbNames.activityUsers),
        where('activityId', '==', activity.id),
        where('deactivated', '==', false),
        where('userId', 'in', userIds),
      )

  const { data: activityUsers = [], isLoading } = useFirestoreQuery(
    activity.id,
    activityUsersQuery,
    {},
    {
      enabled: expand,
      select: snapshot => snapshot.docs.map(d => ({ id: d.id, ...d.data() })),
    },
  )

  const daysUntilStart = useMemo(
    () => getDaysUntil(convertedStartDate),
    [convertedStartDate],
  )
  const tagFilters = useMemo(() => {
    const allFilters = activityUsers.reduce((accum, val) => {
      if (!val.userTags) return accum
      return [...accum, ...val.userTags]
    }, [])
    return uniq(allFilters)
  }, [activityUsers])
  const filteredActivityUsers = useMemo(() => {
    if (isEmpty(selectedFilters)) return activityUsers
    return activityUsers.filter(({ userTags }) => {
      if (isEmpty(userTags)) return false
      return (
        intersection(userTags, selectedFilters).length ===
        selectedFilters.length
      )
    })
  }, [selectedFilters, activityUsers])

  // async function loadActivityUsers() {
  //   let _activityUsers
  //
  //   if (isEmpty(userIds)) {
  //     const q = query(
  //       collection(db, dbNames.activityUsers),
  //       where('activityId', '==', activity.id),
  //       where('deactivated', '==', false),
  //     )
  //     _activityUsers = await getDocs(q).then(q =>
  //       q.docs.map(d => ({ id: d.id, ...d.data() })),
  //     )
  //   } else {
  //     const q = query(
  //       collection(db, dbNames.activityUsers),
  //       where('activityId', '==', activity.id),
  //       where('deactivated', '==', false),
  //       where('userId', 'in', userIds),
  //     )
  //     _activityUsers = await getDocs(q).then(q =>
  //       q.docs.map(d => ({ id: d.id, ...d.data() })),
  //     )
  //   }
  //
  //   setActivityUsers(
  //     orderBy(_activityUsers, ({ userFirstName, userLastName }) => {
  //       return [userLastName.toLowerCase(), userFirstName.toLowerCase()]
  //     }),
  //   )
  // }

  return (
    <Scoped css={css}>
      <div className="mb-4">
        <div
          className={a('card card--clickable overflow-hidden').m(
            'group-card--expand',
            expand,
          )}
        >
          <div className="instance">
            <Link
              style={{ color: 'inherit' }}
              to={`${activity.id}/settings`}
              className="flex-ellipsis hover:text-inherit flex items-center hover:no-underline"
            >
              <Icon
                className="instance-avatar"
                name="users"
              />
              <div className="instance-info">
                <div className="instance-workflow-name truncate">
                  {activity.name}
                </div>
                {daysUntilStart > 1 && (
                  <div>Begins in {daysUntilStart} days</div>
                )}
                {daysUntilStart === 1 && <div>Begins tomorrow</div>}
                {daysUntilStart === 0 && <div>Started today!</div>}
              </div>
            </Link>
            <div className="ml-2">
              <ActionIcon
                variant="secondary"
                onClick={() => {
                  setExpand(state => !state)
                }}
                icon={expand ? 'caret-up' : 'caret-down'}
                size="sm"
              />
            </div>
          </div>
          <AnimatePresence>
            {expand && !isLoading && (
              <motion.div
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: 'auto' },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{ duration: 0.2, ease: 'easeOut' }}
              >
                {!isEmpty(tagFilters) && (
                  <div className="filter__container">
                    <SelectMulti
                      data={tagFilters}
                      onChange={filters => {
                        setSelectedFilters(filters)
                        const d = doc(
                          db,
                          dbNames.organizationProfiles,
                          `${orgId}_${user.id}`,
                        )
                        updateDoc(d, {
                          activityFilters: {
                            ...orgProfile.activity,
                            [activity.id]: filters,
                          },
                        })
                      }}
                      value={selectedFilters}
                      transformData={filter => ({ id: filter, name: filter })}
                      triggerIsBlock
                      fixedContent
                      placeholder="Filter users by tag"
                    />
                  </div>
                )}
                {filteredActivityUsers.map(activityUser => (
                  <WorkflowProgressCardInner
                    key={activityUser.id}
                    activity={activity}
                    activityUser={activityUser}
                    href={`${activityUser.userId}/${activity.id}`}
                    inner
                  />
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </Scoped>
  )
}

const css = k`
  .group-card--expand {
    background-color: rgba($color-primary, .05);
  }

  .instance {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 1.6rem;
    border-bottom: solid .1rem $color-grey-100;
  }

  .instance-avatar.icon {
    margin-right: 1.6rem;
    flex-shrink: 0;
    height: 2.4rem;
    width: 2.4rem;
  }

  .instance-workflow-name {
    font-size: 1.6rem;
    font-weight: 600;
  }

  .instance-progress {
    padding-top: 1.6rem;
    font-size: 1.2rem;
    flex-grow: 1;
    display: flex;
  }

  .filter__container {
    padding: 8px 16px;
    background: #fff;
    border-bottom: solid 1px $color-grey-100;
  }

  @media (min-width: $media1-width) {
    .instance {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .instance-progress {
      justify-content: flex-end;
      padding: 0 1.6rem 0 0;
    }
  }
`
