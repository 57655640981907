import { Icon } from '@components/icon.component'
import * as RadixCheckbox from '@radix-ui/react-checkbox'
import React from 'react'
import { ulid } from 'ulid'

interface CheckboxProps extends RadixCheckbox.CheckboxProps {
  label?: string | React.ReactNode
  className?: string
}

export function Checkbox({
  label,
  className,
  ...checkboxProps
}: CheckboxProps) {
  const checkIdRef = React.useRef(null)

  if (!checkIdRef.current) {
    checkIdRef.current = ulid()
  }

  return (
    <div className={`flex items-center ${className}`}>
      <RadixCheckbox.Root
        {...checkboxProps}
        id={checkIdRef.current}
        className="flex items-center justify-center w-7 h-7 bg-white border border-grey-400 rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
      >
        <RadixCheckbox.Indicator>
          <Icon
            name="check"
            size={12}
          />
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
      <label
        className="ml-2 text-s"
        htmlFor={checkIdRef.current}
      >
        {label}
      </label>
    </div>
  )
}
