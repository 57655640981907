import React, { useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import { EmptyState } from '@components/empty-state.component'
import { LayoutHeader } from '@components/layout.component'
import { TextInput } from '@components/mantine/text-input.component'
import { WorkflowTemplate } from '@src/pages/workflow/template/workflow-template.component'
import { PublishedTemplatesList } from './lists/published-templates-list.component'

export function WorkspaceLibrary() {
  const [searchVal, setSearchVal] = useState('')
  const location = useLocation()
  const isTemplateScreen = location.pathname.includes('/t/')

  const routes = (
    <Routes>
      <Route
        path="t/:templateId"
        element={<WorkflowTemplate type="preview" />}
      />
    </Routes>
  )

  if (isTemplateScreen) {
    return routes
  }

  return (
    <div className="iact-container --sm">
      <LayoutHeader
        className="pt-8"
        title="Library"
        search={
          <TextInput
            value={searchVal}
            onChange={setSearchVal}
            placeholder="Search library..."
            icon="search"
            allowClear
          />
        }
      />
      <PublishedTemplatesList
        searchVal={searchVal}
        renderEmptyState={() => (
          <EmptyState
            title="No templates"
            subtitle="Speak to an administrator for your workspace for more information"
          />
        )}
      />
      {routes}
    </div>
  )
}
