import { a } from 'kremling'
import { func, string } from 'prop-types'
import React, { forwardRef } from 'react'

import { Icon } from '@components/icon.component'

const TriggerMultiInner = (props, ref) => {
  const {
    // CpSelect Props
    isDisabled,
    toggle,
    triggerIsBlock,
    value,
    // Props
    placeholder,
    renderValue,
    onFocus,
    onBlur,
  } = props

  let display = placeholder
  if (value.length) {
    if (renderValue) {
      display = renderValue(value)
    } else {
      if (value.length > 1) {
        display = `${value.length} Selected`
      } else {
        display = value[0].name
      }
    }
  }
  return (
    <button
      className={a('btn trigger-single')
        .m('trigger-single--placeholder', !value.length)
        .m('w-full block', triggerIsBlock)}
      onClick={e => {
        e.preventDefault()
        toggle()
      }}
      ref={ref}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={isDisabled}
    >
      {display}
      <Icon
        name="caret-down"
        size={14}
      />
    </button>
  )
}

export const TriggerMulti = forwardRef(TriggerMultiInner)

TriggerMulti.propTypes = {
  placeholder: string,
  renderValue: func,
}
