import { Card } from '@components/card.component'
import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { db } from '@src/firebase-app'
import { dbNames } from '@utils/constants'
import { deleteDoc, doc } from 'firebase/firestore'
import React from 'react'

type Props = {
  notification: {
    activityOrgId: string
    activityId: string
    activityName: string
    recipientId: string
    id: string
    sectionId: string
    iterationId: string
  }
}

export function NewIterationStartedNotificationItem({ notification }: Props) {
  const {
    activityOrgId,
    activityId,
    activityName,
    recipientId,
    sectionId,
    iterationId,
  } = notification
  return (
    <Card
      className="decoration-0 p-4 mb-2"
      href={`/${activityOrgId}/home/${recipientId}/${activityId}/${sectionId}/${iterationId}`}
    >
      <div className="flex justify-between">
        <div>
          <div className="flex items-center mb-4 text-2xl font-semibold">
            <Icon
              className="mr-4"
              name="alarm-clock"
              size={20}
            />
            A new iteration for your activity called{' '}
            <span className="font-semibold text-primary text-3xl mx-2">
              {activityName}
            </span>{' '}
            has started!
          </div>
        </div>
        <ActionIcon
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            const d = doc(db, dbNames.notifications, notification.id)
            deleteDoc(d)
          }}
          icon="times"
          size="sm"
        />
      </div>
    </Card>
  )
}
