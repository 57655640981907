import { Pill } from '@components/pill/pill.component'
import { NavLink } from '@src/navigation/nav-link.component'
import { CompletedBadge } from '@src/pages/workflow/client/workflow-refactor/completed-badge.component'
import { useActivityStore } from '@src/pages/workflow/client/workflow-refactor/state/use-activity-state'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

type Props = {
  section: ActivitySection
}

export function SectionListItem({ section }: Props) {
  const { iterationId, sectionId } = useParams()
  const progressSections = useActivityStore(state => state.progressSections)
  const progress = useMemo(() => {
    return (
      progressSections.find((p: ProgressSection) => p.sectionId === section?.id)
        ?.completedPercentage || 0
    )
  }, [progressSections, section])

  const isSelected = section.id === sectionId

  return (
    <NavLink
      activeClassName="text-primary font-bold hover:bg-active-50 hover:text-primary"
      className="px-6 relative h-16 flex items-center justify-between border-b border-grey-100 hover:bg-active-50 text-grey-700 hover:text-grey-500"
      onClick={() => {
        useActivityStore.setState({ navigationOpen: false })
      }}
      to={
        section.id === sectionId ? `${section.id}/${iterationId}` : section.id
      }
      key={section.id}
    >
      <div className="w-full">
        <div
          className="absolute left-0 bottom-0 top-0 bg-grey-50 transition-all"
          style={{
            width: `${progress}%`,
            backgroundColor: isSelected ? 'var(--color-active-150)' : '',
          }}
        />
        <div className="relative flex items-center justify-between">
          {isSelected ? (
            <Pill pillType="primary">
              {section.name ? section.name : 'Untitled section'}
            </Pill>
          ) : section.name ? (
            section.name
          ) : (
            'Untitled section'
          )}
        </div>
      </div>
      <CompletedBadge
        className="relative"
        showBadge={progress === 100}
      />
    </NavLink>
  )
}
