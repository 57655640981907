import { a } from 'kremling'
import React from 'react'

import { Dropdown } from '@components/dropdown.component'
import { Icon } from '@components/icon.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { TextInput } from '@components/mantine/text-input.component'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Controller, useForm } from 'react-hook-form'

export function SectionItem({
  section,
  index,
  selectSection,
  activeSectionId,
  handleDuplicateSection,
  handleDeleteSection,
  updateSection,
}) {
  const sectionHasFields = !!section.fields.length
  const isSelected = section.id === activeSectionId

  const { handleSubmit, control } = useForm({
    defaultValues: {
      sectionName: section.name,
    },
  })

  function updateSectionName({ sectionName }) {
    updateSection({ ...section, name: sectionName }, false)
  }

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
    id: section.id,
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    position: 'relative',
    zIndex: isDragging ? 1 : undefined,
  }

  return (
    <div
      style={style}
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      className={a('section-nav__item')
        .m('--active', isSelected)
        .m('--dragging', isDragging)}
      onClick={() => {
        selectSection(section.id)
      }}
    >
      {!isSelected || section.name ? (
        <>
          <div
            className={a('item-title__container flex-ellipsis')}
            title={section.name}
          >
            <Icon
              name="grip-dots-vertical"
              className="section-icon mr-2"
            />
            <span>{section.name || 'Untitled Section'}</span>
          </div>
          {sectionHasFields ? (
            <div className="section-item__info">
              <Dropdown
                position="bottom-left"
                renderTrigger={({ toggle }) => (
                  <ActionIcon
                    size="sm"
                    className="ml-1"
                    onClick={toggle}
                    icon="ellipsis-v"
                  />
                )}
                renderContent={({ close }) => (
                  <div className="select-list">
                    {sectionHasFields && (
                      <button
                        onClick={() => {
                          handleDuplicateSection(section, index)
                          close()
                        }}
                      >
                        Duplicate
                      </button>
                    )}
                    <button
                      onClick={() => {
                        handleDeleteSection(section.id)
                        close()
                      }}
                    >
                      Delete
                    </button>
                  </div>
                )}
              />
            </div>
          ) : (
            <div className="flex items-center mr-1">
              <div className="flex items-center text-xl text-grey-300 mr-1">
                Empty
                <Icon
                  size={15}
                  className="ml-1"
                  name="exclamation-circle"
                />
              </div>

              <ActionIcon
                size="sm"
                icon="trash"
                onClick={e => {
                  e.stopPropagation()
                  handleDeleteSection(section.id)
                }}
              />
            </div>
          )}
        </>
      ) : (
        <form
          className="flex items-center w-full gap-2"
          onSubmit={handleSubmit(updateSectionName)}
        >
          <Controller
            control={control}
            name="sectionName"
            render={({ field }) => (
              <TextInput
                {...field}
                className="flex-1"
                placeholder="Section name"
                autoFocus
              />
            )}
          />
          <div className="space-y-1">
            <ActionIcon
              type="submit"
              icon="check"
              size="sm"
            />
          </div>
        </form>
      )}
    </div>
    // <Draggable
    //   draggableId={section.id}
    //   index={index}
    //   key={section.id}
    // >
    //   {({ draggableProps, dragHandleProps, innerRef: ref }, provided) => (
    //     <div
    //       {...draggableProps}
    //       {...dragHandleProps}
    //       ref={ref}
    //       className={a('section-nav__item')
    //         .m('--active', section.id === activeSectionId)
    //         .m('--dragging', provided.isDragging)}
    //       onClick={() => {
    //         selectSection(section.id)
    //       }}
    //     >
    //       <div
    //         className={a('item-title__container flex-ellipsis')}
    //         title={section.name}
    //       >
    //         <Icon
    //           name="grip-dots-vertical"
    //           className="section-icon mr-2"
    //         />
    //         <span>{section.name || 'Untitled Section'}</span>
    //       </div>
    //       {sectionHasFields ? (
    //         <div className="section-item__info">
    //           <Dropdown
    //             position="bottom-left"
    //             renderTrigger={({ toggle }) => (
    //               <ActionIcon
    //                 size="sm"
    //                 className="ml-1"
    //                 onClick={toggle}
    //                 icon="ellipsis-v"
    //               />
    //             )}
    //             renderContent={({ close }) => (
    //               <div className="select-list">
    //                 {sectionHasFields && (
    //                   <button
    //                     onClick={() => {
    //                       handleDuplicateSection(section, index)
    //                       close()
    //                     }}
    //                   >
    //                     Duplicate
    //                   </button>
    //                 )}
    //                 <button
    //                   onClick={() => {
    //                     handleDeleteSection(section.id)
    //                     close()
    //                   }}
    //                 >
    //                   Delete
    //                 </button>
    //               </div>
    //             )}
    //           />
    //         </div>
    //       ) : (
    //         <div className="flex items-center mr-1">
    //           <div className="flex items-center text-xl text-grey-300 mr-1">
    //             Empty
    //             <Icon
    //               size={15}
    //               className="ml-1"
    //               name="exclamation-circle"
    //             />
    //           </div>
    //           <ActionIcon
    //             size="sm"
    //             icon="trash"
    //             onClick={e => {
    //               e.stopPropagation()
    //               handleDeleteSection(section.id)
    //             }}
    //           />
    //         </div>
    //       )}
    //     </div>
    //   )}
    // </Draggable>
  )
}
