import { k, useCss } from 'kremling'
import { func, object, string } from 'prop-types'
import React, { useEffect, useRef } from 'react'

import { FieldHeaderContent } from '@src/pages/workflow/client/fields/field-header-content.component'
import { useController } from 'react-hook-form'
import { getVideoIdFromURL } from '../../fields.helper'

export function WorkflowYoutube({
  field,
  isRequired,
  control,
  section,
  iterationId,
  readOnly,
}) {
  const scope = useCss(css)
  const player = useRef(null)

  const {
    field: { onChange, value, disabled },
  } = useController({
    disabled: readOnly,
    name: `${field.version}_${field.id}`,
    control,
    rules: {
      required: isRequired && 'This field is required',
    },
  })

  function onPlayerStateChanged(e) {
    const { ENDED } = YT.PlayerState
    if (e.data === ENDED && !disabled) {
      onChange(true)
    }
  }

  useEffect(() => {
    function loadVideo() {
      const videoId = getVideoIdFromURL(field.link)

      if (player.current && player.current.destroy) {
        player.current.destroy()
      }

      player.current = new YT.Player(`YT-${field.id}-${iterationId}`, {
        videoId: videoId,
        width: '100%',
        height: '100%',
        events: {
          onStateChange: onPlayerStateChanged,
        },
      })
    }

    if (!window.YT) {
      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

      window.onYouTubeIframeAPIReady = loadVideo
    } else {
      loadVideo()
    }

    return () => {
      if (player.current && player.current.destroy) {
        player.current.destroy()
      }
    }
  }, [iterationId, field.link])

  return (
    <div {...scope}>
      <FieldHeaderContent
        field={field}
        isRequired={isRequired}
        section={section}
        markCompleted={value}
      />
      <div className="youtube-iframe-parent mt-2">
        <div id={`YT-${field.id}-${iterationId}`} />
      </div>
    </div>
  )
}

WorkflowYoutube.propTypes = {
  field: object,
  onChange: func,
  value: string,
}

const css = k`
  .youtube-iframe-parent {
    position: relative;
    width: 100%;
    height: 200px;
  }

  @media (min-width: $media1-width) {
    .youtube-iframe-parent {
      height: 310px;
    }
  }

  @media (min-width: $media2-width) {
    .youtube-iframe-parent {
      height: 360px;
    }
  }

  .viewed-indicator {
    color: $color-primary;
  }
`
