import { arrayUnion, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import { a, m, useCss } from 'kremling'
import { cloneDeep, isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import React, { useEffect, useRef, useState } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ulid } from 'ulid'

import { EmptyState } from '@components/empty-state.component'
import { Icon } from '@components/icon.component'
import { Loader } from '@components/loader.component'
import { ActionIcon } from '@components/mantine/action-icon.component'
import { Button } from '@components/mantine/button.component'
import { Switch } from '@components/radix/switch'
import { SavingIndicator } from '@components/saving-indicator.component'
import { Tooltip } from '@components/tooltip.component'
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { useDebounce } from '@hooks/use-debounce.hook'
import { useLoad } from '@hooks/use-load.hook'
import { useAppState } from '@src/app.state'
import { db } from '@src/firebase-app'
import { StartMarketplaceWorkflowModal } from '@src/pages/workflow/start-marketplace-workflow-modal.component'
import { useActivityPublisher } from '@src/pages/workflow/template/use-activity-publisher.hook'
import { dbNames } from '@utils/constants'
import { getTodayDate } from '@utils/helpers'
import { modalService } from '@utils/modal.service'
import { toastService } from '@utils/toast.service'
import { SelfAddWorkflowModal } from '../self-add-workflow-modal.component'
import css from '../workflow.kremling.scss'
import { AdoptTemplateModal } from './adopt-template-modal.component'
import { EditSection } from './edit-section.component'
import { SectionItem } from './section-item.component'
import { TemplateSettingsModal } from './template-settings-modal.component'

function getCollection(type) {
  if (type === 'preview') return dbNames.publishedTemplates
  if (type === 'marketplace') return dbNames.marketplaceTemplates
  if (type === 'adopted-marketplace-preview')
    return dbNames.marketplaceTemplates
  return dbNames.workflowTemplates
}

export function WorkflowTemplate({ type = '' }) {
  const sectionListId = 'sections-list-dnd'
  const params = useParams()
  const { pathname } = useLocation()
  const isArchivedPage = pathname.includes('archived')
  const user = useAppState(state => state.user)
  const orgs = useAppState(state => state.orgs)
  const selectedOrg = orgs.find(org => org.id === params.orgId)
  const scope = useCss(css)
  const saveStateInterim = useRef({})
  const templateCollection = useRef(getCollection(type))
  const [saving, setSaving] = useState(false)
  const [activeSectionId, setActiveSectionId] = useState(null)
  const [showSections, setShowSections] = useState(true)
  const isMarketplace = type === 'marketplace'
  const [previewOnly, setPreviewOnly] = useState(
    type.includes('preview') || isMarketplace || isArchivedPage,
  )
  const [preview, setPreview] = useState(false)
  const navigate = useNavigate()

  const { setNodeRef } = useDroppable({
    id: sectionListId,
  })

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 15,
      },
    }),
  )

  const [template, setTemplate, templateOpts] = useLoad(
    null,
    () => {
      return getDoc(
        doc(db, templateCollection.current, params.templateId),
      ).then(doc => {
        const t = { ...doc.data(), id: doc.id }

        //Make the template a preview-only experience for non-super admins and for super admins who are not the authors of the template
        const userIsTemplateAuthor = t.authorId === user.id
        if (
          !user.isSuperAdmin &&
          !userIsTemplateAuthor &&
          !t?.orgId === params.orgId
        ) {
          setPreviewOnly(true)
        }
        return t
      })
    },
    [],
  )

  const templateVersion = template?.version || 0
  const sectionsRef = doc(
    db,
    `${dbNames.workflowTemplates}/${params.templateId}/sections/${templateVersion}`,
  )

  const [sections, setSections, sectionsOpts] = useLoad(
    [],
    () => {
      return getDoc(sectionsRef).then(snap => {
        if (snap.exists()) {
          const _sections = snap.data().sections
          if (_sections.length > 0) {
            setActiveSectionId(_sections[0].id)
          }
          return _sections
        }
        return []
      })
    },
    [templateVersion],
  )

  const [previousSections, _setPreviousSections, previousSectionsOpts] =
    useLoad(
      [],
      () => {
        if (templateVersion === 0) return Promise.resolve([])
        return getDoc(
          doc(
            db,
            `${dbNames.workflowTemplates}/${params.templateId}/sections/${templateVersion - 1}`,
          ),
        ).then(snap => {
          if (snap.exists()) {
            const _sections = snap.data().sections
            if (_sections.length > 0) {
              setActiveSectionId(_sections[0].id)
            }
            return _sections
          }
          return []
        })
      },
      [templateVersion],
    )

  // const [currentPublished, _setCurrentPublished, currentPublishedOpts] =
  //   useLoad(
  //     null,
  //     () => {
  //       if (isMarketplace) return Promise.resolve(null)
  //       if (pathname.includes('super-settings')) {
  //         const q = query(
  //           collection(db, dbNames.publishedTemplates),
  //           where('publishedFromTemplateId', '==', params.templateId),
  //           where('publishedLocations', 'array-contains', 'marketplace'),
  //         )
  //         return getDocs(q).then(query => {
  //           if (!query.empty) {
  //             const doc = query.docs[0]
  //             return { ...doc.data(), id: doc.id }
  //           }
  //           return null
  //         })
  //       }
  //       const q = query(
  //         collection(db, dbNames.publishedTemplates),
  //         where('publishedFromTemplateId', '==', params.templateId),
  //         where('publishedLocations', 'array-contains', 'workspace'),
  //       )
  //       return getDocs(q).then(query => {
  //         if (!query.empty) {
  //           const doc = query.docs[0]
  //           return { ...doc.data(), id: doc.id }
  //         }
  //         return null
  //       })
  //     },
  //     [],
  //   )

  useEffect(() => {
    if (!template) return
    if (!template.name) {
      editTemplate()
    }
  }, [template])

  const { publishActivity, publishing, noChangesMade } = useActivityPublisher({
    orgId: params.orgId,
    template,
    sections,
    previousSections,
    onCreateActivity,
    onPublishTemplate,
  })

  function onCreateActivity() {
    sectionsOpts.reload()
  }

  async function onPublishTemplate(newSections) {
    const newVersion = template.version + 1

    //Create a new document in the sections sub collection with the current sections (ID should be the incremented version)
    await setDoc(
      doc(
        db,
        `${dbNames.workflowTemplates}/${template.id}/sections/${newVersion}`,
      ),
      {
        sections: newSections || sections,
      },
    )

    //Increment the version of the template (should be one higher than the activities
    setTemplate({ ...template, version: newVersion })
    await updateDoc(doc(db, dbNames.workflowTemplates, template.id), {
      version: newVersion,
    })

    await previousSectionsOpts.reload()
  }

  function save() {
    setSaving(true)
    const newState = cloneDeep(saveStateInterim.current)
    saveStateInterim.current = {}
    updateDoc(doc(db, templateCollection.current, template.id), {
      ...newState,
      editorIds: arrayUnion(user.id),
      currentVersionEditorIds: arrayUnion(user.id),
      editedAfterPublish: !noChangesMade,
      lastEditedDate: getTodayDate().toISO(),
      lastEditedById: user.id,
    })
      .then(() => setSaving(false))
      .catch(e => toastService.error(e.message))
      .finally(() => {
        setSaving(false)
      })
  }

  const saveDebounced = useDebounce(save, 1000)

  async function updateTemplate(newState, debounce = true) {
    saveStateInterim.current = { ...saveStateInterim.current, ...newState }
    if (debounce) {
      saveDebounced()
    } else {
      await save()
    }
    setTemplate({ ...template, ...newState })
  }

  async function restoreTemplate() {
    try {
      const templateDoc = doc(db, dbNames.workflowTemplates, template.id)
      await updateDoc(templateDoc, {
        archived: false,
      })
      navigate(`/${params.orgId}/library/templates/t/${template.id}`)
      setPreviewOnly(false)
    } catch (err) {
      toastService.error('An error occurred while restoring this template')
    }
  }

  function editTemplate() {
    modalService.render(TemplateSettingsModal, { template }).then(updates => {
      updateTemplate(updates)
    })
  }

  async function addSection() {
    const id = ulid()

    const newSections = [
      ...sections,
      {
        id,
        name: '',
        constraints: {
          recurrence: null,
          repeat: 0,
          required: [],
          requiredMode: 'all',
          anyRequiredCount: 0,
        },
        fields: [],
      },
    ]

    setSaving(true)
    setSections(newSections)
    await setDoc(sectionsRef, {
      sections: newSections,
    })
    setSaving(false)
    selectSection(id)
  }

  function openClientWorkflowModal() {
    modalService
      .render(SelfAddWorkflowModal, { template, orgId: params.orgId })
      .then(({ newActivityId }) => {
        navigate(`/${selectedOrg.id}/home/${user.id}/${newActivityId}`)
      })
  }

  async function saveSections(newSections) {
    setSaving(true)
    await updateDoc(sectionsRef, {
      sections: newSections,
    })
    setSaving(false)
  }

  const saveSectionsDebounce = useDebounce(saveSections, 1000)

  async function updateSection(section, debounce) {
    const index = sections.findIndex(c => c.id === section.id)
    if (index === -1) return

    const _sections = [
      ...sections.slice(0, index),
      section,
      ...sections.slice(index + 1),
    ]

    if (debounce) {
      setSections(_sections)
      await saveSectionsDebounce(_sections)
    } else {
      setSections(_sections)
      await saveSections(_sections)
    }
  }

  function toggleSectionsMobile() {
    const mobileView = window.matchMedia('(max-width: 896px)').matches
    if (mobileView) {
      setShowSections(prevShowSections => !prevShowSections)
    }
  }

  function selectSection(sectionId) {
    setActiveSectionId(sectionId)
    toggleSectionsMobile()
  }

  async function deleteSection(sectionId) {
    if (!sectionId) return
    const newSectionsList = sections.filter(c => c.id !== sectionId)
    if (activeSectionId === sectionId) {
      setActiveSectionId(null)
      const deletedSectionIndex = sections.findIndex(c => c.id === sectionId)

      if (!isEmpty(newSectionsList)) {
        const prevSectionIndex = deletedSectionIndex - 1
        if (prevSectionIndex > -1) {
          selectSection(sections[prevSectionIndex].id)
        } else {
          selectSection(newSectionsList[0].id)
        }
      }
    }

    setSaving(true)

    setSections(newSectionsList)
    await updateDoc(sectionsRef, {
      sections: newSectionsList,
    })

    setSaving(false)
  }

  function onDragEnd({ active, over }) {
    if (active.id !== over.id) {
      const oldIndex = sections.findIndex(f => f.id === active.id)
      const newIndex = sections.findIndex(f => f.id === over.id)
      const _sections = arrayMove(sections, oldIndex, newIndex)

      setSections(_sections)
      saveSectionsDebounce(_sections)
    }
  }

  // async function createNewVersion(publishedLocations = ['workspace']) {
  //   try {
  //     const { id, ...rest } = template
  //     const today = getTodayDate().toISO()
  //     setSaving(true)
  //
  //     const publishTemplateRef = await addDoc(
  //       collection(db, dbNames.publishedTemplates),
  //       {
  //         ...rest,
  //         publishedFromTemplateId: id,
  //         publishedById: user.id,
  //         createdDate: today,
  //         publishedLocations,
  //       },
  //     )
  //
  //     saveStateInterim.current = {
  //       ...saveStateInterim.current,
  //       editorIds: arrayUnion(user.id),
  //       currentVersionEditorIds: [],
  //       lastPublishedDate: today,
  //       editedAfterPublish: false,
  //     }
  //
  //     await save()
  //
  //     setSaving(false)
  //
  //     return publishTemplateRef
  //   } catch (err) {
  //     toastService.error('A problem occurred while publishing')
  //   }
  // }

  // async function handlePublishTemplate() {
  //   if (user.isSuperAdmin && template.orgId === 'super') {
  //     modalService
  //       .render(PublishTemplateModal, { template, createNewVersion })
  //       .then(() => {
  //         toastService.info('Successfully published template to Marketplace')
  //         currentPublishedOpts.reload()
  //       })
  //   } else {
  //     setPublishingTemplate(true)
  //     await createNewVersion()
  //     toastService.info('Successfully published template')
  //     currentPublishedOpts.reload()
  //     setPublishingTemplate(false)
  //   }
  // }

  // async function handlePublishChanges() {
  //   if (user.isSuperAdmin && template.orgId === 'super') {
  //     try {
  //       await modalService.render(PublishTemplateModal, {
  //         template,
  //         currentPublished,
  //         createNewVersion,
  //       })
  //       toastService.info('Successfully published changes to Marketplace')
  //     } catch (err) {
  //       //fail silently
  //     }
  //   } else {
  //     setPublishingTemplate(true)
  //     await createNewVersion()
  //     await updateDoc(
  //       doc(db, dbNames.publishedTemplates, currentPublished.id),
  //       {
  //         publishedLocations: arrayRemove('workspace'),
  //       },
  //     )
  //     setPublishingTemplate(false)
  //     toastService.info('Successfully published changes')
  //   }
  // }

  function openAdoptTemplateModal() {
    modalService.render(AdoptTemplateModal, { template }).then(() => {})
  }

  function openStartWorkflowModal() {
    modalService
      .render(StartMarketplaceWorkflowModal, { template })
      .then(({ goToOrgId, newWorkflowId }) => {
        navigate(`/${goToOrgId}/home/${newWorkflowId}`)
      })
  }

  function renderSaveButtons() {
    if (isMarketplace) {
      return (
        <>
          <Button
            className="mr-12"
            onClick={openAdoptTemplateModal}
            variant="secondary"
          >
            Import to workspace
          </Button>
          <Button
            onClick={openStartWorkflowModal}
            variant="primary"
          >
            Start a workflow
          </Button>
        </>
      )
    }
    return (
      <Button
        variant="primary"
        onClick={openClientWorkflowModal}
        className="public-save-btn"
      >
        Get started!
      </Button>
    )
  }

  async function handleDuplicateSection(section, index) {
    const id = ulid()
    const nextIndex = index + 1
    const _section = window.structuredClone(section)
    const fields = _section.fields.map(({ _id, ...rest }) => ({
      ...rest,
      id: ulid(),
    }))
    const name = _section.name.includes('(copy)')
      ? _section.name
      : `${_section.name} (copy)`

    const sections = [
      ...template.sections.slice(0, nextIndex),
      {
        id,
        name,
        constraints: _section.constraints,
        fields,
      },
      ...template.sections.slice(nextIndex),
    ]

    selectSection(id)

    await updateTemplate({
      editorIds: arrayUnion(user.id),
      currentVersionEditorIds: arrayUnion(user.id),
      sections,
    })
  }

  function renderPublishButton() {
    if (template.version > 0) {
      return (
        <Tooltip
          disabled={!noChangesMade}
          placement="bottom"
          content="No changes made"
        >
          <Button
            variant="primary"
            onClick={publishActivity}
            className="media-show-3 mr-2"
            disabled={noChangesMade}
            loading={publishing}
          >
            Publish changes
          </Button>
          <ActionIcon
            size="sm"
            icon="up-from-bracket"
            variant="primary"
            onClick={publishActivity}
            className="media-hide-3 mr-2"
            disabled={noChangesMade}
            loading={publishing}
          />
        </Tooltip>
      )
    }

    return (
      <>
        <Button
          variant="primary"
          onClick={publishActivity}
          className="media-show-3 mr-2"
          disabled={isEmpty(sections)}
          loading={publishing}
        >
          Create course
        </Button>
        <ActionIcon
          icon="up-from-bracket"
          size="sm"
          variant="primary"
          onClick={publishActivity}
          className="media-hide-3 mr-2"
          disabled={isEmpty(template.sections)}
          loading={publishing}
        />
      </>
    )
  }

  if (templateOpts.loading)
    return (
      <div className="pt-8">
        <Loader />
      </div>
    )

  if (!template) return null

  if (!template.archived && isArchivedPage) {
    if (params.orgId === user.id) {
      return <Navigate to={`/${params.orgId}/library/t/${template.id}`} />
    } else {
      return (
        <Navigate to={`/${params.orgId}/library/templates/t/${template.id}`} />
      )
    }
  }

  const activeSection = sections.find(c => c.id === activeSectionId)
  return (
    <div
      {...scope}
      className="workflow-container"
    >
      {isMarketplace && (
        <div className="save-header workflow-header-border">
          {renderSaveButtons()}
        </div>
      )}
      <div
        className={a('workflow-header').m(
          'workflow-header-public',
          template.isPublic,
        )}
      >
        <div className="header-section">
          <div className="back-button__container">
            <Button
              className="back-button"
              onClick={() => navigate(-1)}
            >
              <Icon
                size={25}
                name="angle-left"
              />
            </Button>
          </div>
          <div>
            {!previewOnly && (
              <div className="workflow-header__descriptor media-show-4">
                Template
              </div>
            )}
            <div
              className={a('workflow-header__title').m(
                'preview-only',
                previewOnly,
              )}
              title={template.name}
            >
              {template.name} {isArchivedPage ? '(archived)' : ''}
            </div>
            <div className="workflow-header__subtitle">
              Edited{' '}
              {DateTime.fromISO(
                template.lastEditedDate || template.createdDate,
              ).toFormat('LLL d')}
            </div>
          </div>
        </div>
        {!isMarketplace && !isArchivedPage && (
          <div className="header-section header-section__right pr-4">
            {type.includes('preview') ? (
              renderSaveButtons()
            ) : (
              <>
                {renderPublishButton()}
                <SavingIndicator saving={saving} />
                <ActionIcon
                  className={m('public-cog', template.isPublic)}
                  icon="gear"
                  size="sm"
                  onClick={editTemplate}
                />
              </>
            )}
          </div>
        )}
        {isArchivedPage && (
          <div className="header-section header-section__right pr-4">
            <Button
              onClick={restoreTemplate}
              variant="secondary"
            >
              Restore template
            </Button>
          </div>
        )}
      </div>
      <div
        className={a('workflow-content').m(
          'workflow-content--fields',
          activeSectionId,
        )}
      >
        {showSections && (
          <div className="workflow-sections">
            <div className="workflow-nav__container">
              <div className="mb-4 space-y-4">
                {/*<div className="flex items-center justify-end gap-3">*/}
                {/*  <label htmlFor="sequential-switch">Sequential</label>*/}
                {/*  <Switch*/}
                {/*    id="sequential-switch"*/}
                {/*    onChange={value => {*/}
                {/*      if (!template.isSequential) {*/}
                {/*        const sectionsWithRecurrence = sections.filter(*/}
                {/*          section => !!section.constraints.recurrence,*/}
                {/*        )*/}

                {/*        const allSectionsHaveRecurrenceLimit =*/}
                {/*          sectionsWithRecurrence.every(*/}
                {/*            section => section.constraints.repeat > 0,*/}
                {/*          )*/}
                {/*        if (value && !allSectionsHaveRecurrenceLimit) {*/}
                {/*          toastService.error(*/}
                {/*            'All iterating sequential sections must have an occurrence limit',*/}
                {/*          )*/}
                {/*          return*/}
                {/*        }*/}
                {/*      }*/}
                {/*      updateTemplate({ isSequential: value })*/}
                {/*    }}*/}
                {/*    value={template.isSequential}*/}
                {/*  />*/}
                {/*</div>*/}
                <div className="flex items-center justify-end gap-3">
                  <label htmlFor="preview-switch">Preview</label>
                  <Switch
                    id="preview-switch"
                    onChange={setPreview}
                    value={preview}
                  />
                </div>
              </div>
              <div className="sections-list__container">
                <div className="sections-list__header">
                  <div className="item-title__container">
                    {template?.isSequential ? (
                      <Icon
                        name="arrow-progress"
                        className="mr-3"
                      />
                    ) : (
                      <Icon
                        className="mr-3"
                        name="list-radio"
                      />
                    )}
                    <span>Sections</span>
                  </div>
                </div>
                <div>
                  {!previewOnly ? (
                    <DndContext
                      collisionDetection={closestCenter}
                      onDragEnd={onDragEnd}
                      modifiers={[
                        restrictToVerticalAxis,
                        restrictToParentElement,
                      ]}
                      sensors={sensors}
                    >
                      <div
                        ref={setNodeRef}
                        className="section-item-container"
                      >
                        <SortableContext
                          strategy={verticalListSortingStrategy}
                          items={sections.map(c => c.id)}
                        >
                          {sections.map((section, index) => (
                            <SectionItem
                              key={section.id}
                              index={index}
                              updateSection={updateSection}
                              section={section}
                              selectSection={selectSection}
                              activeSectionId={activeSectionId}
                              handleDuplicateSection={handleDuplicateSection}
                              handleDeleteSection={deleteSection}
                            />
                          ))}
                        </SortableContext>
                      </div>
                    </DndContext>
                  ) : (
                    //Section list without drag and drop
                    sections.map(section => (
                      <div
                        key={section.id}
                        className={a('section-nav__item').m(
                          '--active',
                          section.id === activeSectionId,
                        )}
                        onClick={() => selectSection(section.id)}
                      >
                        {section.name || 'Untitled Section'}
                      </div>
                    ))
                  )}
                </div>
              </div>
              {!previewOnly && (
                <div className="p-4 text-center">
                  <Button
                    variant="secondary"
                    onClick={addSection}
                  >
                    + Add Section
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
        {!isEmpty(sections) && activeSectionId && (
          <EditSection
            updateSection={updateSection}
            onClose={toggleSectionsMobile}
            section={activeSection}
            onDelete={deleteSection}
            previewOnly={previewOnly}
            preview={preview}
            handleSetPreview={setPreview}
            template={template}
          />
        )}
        {isEmpty(sections) && (
          <EmptyState
            title="No sections have been created."
            subtitle="Create a section to begin."
            action={addSection}
            actionText="+ Add Section"
          />
        )}
      </div>
    </div>
  )
}

WorkflowTemplate.propTypes = {}
