import { k, useCss } from 'kremling'
import React from 'react'

import { InputField } from '@components/input'
import { Button } from '@components/mantine/button.component'
import { Modal } from '@components/modal.component'
import { useForm } from 'react-hook-form'

export function TemplateSettingsModal({ template, onResolve, onReject }) {
  const scope = useCss(css)

  const { handleSubmit, control, formState: {isDirty} } = useForm({
    defaultValues: {
      name: template.name,
    },
  })

  function save({ name }) {
    if (!name) return
    onResolve({
      name: name.trim(),
    })
  }

  return (
    <>
      <Modal.Header>Template Settings</Modal.Header>
      <form onSubmit={handleSubmit(save)}>
        <Modal.Body {...scope}>
          <div className="form-group">
            <InputField
              autoFocus
              control={control}
              fieldName="name"
              label="Name"
            />
          </div>
        </Modal.Body>
        <Modal.Footer {...scope}>
          <Button
            disabled={!isDirty}
            type="submit"
            variant="primary"
            onClick={save}
          >
            Save
          </Button>
          <Button
            variant="tertiary"
            onClick={onReject}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </form>
    </>
  )
}

TemplateSettingsModal.propTypes = {}

const css = k`

`
