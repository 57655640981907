import { k, useCss } from 'kremling'
import { func, object } from 'prop-types'
import React, { useState } from 'react'

import { Icon } from '@components/icon.component'
import { TextInput } from '@components/mantine/text-input.component'
import { Switch } from '@components/radix/switch'
import { RichText } from '@components/rich-text/rich-text.component'
import { Tooltip } from '@components/tooltip.component'
import { ChoiceBuilder } from './choice-builder.component'

export function FieldSelect({
  field,
  onChange,
  isRequired,
  updateRequiredToggle,
}) {
  const scope = useCss(css)
  const [multiselect, setMultiselect] = useState(field.multiselect)

  function updateFieldLabel(label) {
    if (label === field.label) return
    const updatedField = {
      ...field,
      label,
    }
    onChange(updatedField, true)
  }

  function updateFieldDescription(description) {
    const updatedField = {
      ...field,
      description,
    }
    onChange(updatedField)
  }

  function updateMultiselect(value) {
    setMultiselect(value)
    const updateField = {
      ...field,
      multiselect: value,
    }
    onChange(updateField, true)
  }

  function updateChoices(choices) {
    const updatedField = {
      ...field,
      choices,
    }
    onChange(updatedField, true)
  }

  return (
    <div
      {...scope}
      className="space-y-6"
    >
      <div className="flex items-center">
        <Switch
          id={`required-switch-${field.id}`}
          onChange={value => updateRequiredToggle(value, field.id)}
          className="mr-2"
          value={isRequired}
        />
        <label htmlFor={`required-switch-${field.id}`}>Required</label>
      </div>
      <div className="row break-1">
        <div className="col-10">
          <div className="form-group">
            <TextInput
              defaultValue={field.label}
              label="Name the field"
              placeholder="How was your mood today?"
              onChange={updateFieldLabel}
              autoFocus
            />
          </div>
        </div>
        <div className="col-2">
          <div className="form-group">
            <label
              className="toggle-label"
              htmlFor={`${field.id}-multiselect`}
            >
              Multiselect
              <Tooltip
                content="Toggled on means user can make multiple selections in dropdown"
                maxWidth={150}
                placement="right"
              >
                <Icon
                  name="info"
                  size={15}
                  className="ml-1"
                />
              </Tooltip>
            </label>
            <Switch
              id={`${field.id}-multiselect`}
              onChange={value => updateMultiselect(value)}
              value={multiselect}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="text-field-desc">Describe this field</label>
        <RichText
          id="text-field-desc"
          onChange={updateFieldDescription}
          initialValue={field ? field.description : null}
          placeholder="Type here..."
        />
      </div>
      <div className="form-group">
        <ChoiceBuilder
          field={field}
          onChange={updateChoices}
          title="Select list"
        />
      </div>
    </div>
  )
}

FieldSelect.propTypes = {
  field: object,
  onChange: func,
}

const css = k`
  .toggle-label {
    display: flex;
    align-items: center;
  }

`
